<template>
  <div>
    <!-- all media -->
    <div v-if="allowAllMedia" class="row">
      <div class="col-12">
        Send
        <input
          :id="'feed' + feedRecipientId + '-' + channelId + '-xfer-allmedia-0'"
          v-model="allMedia"
          type="radio"
          value=""
          class="allmedia-radio"
          @change="settingsChanged"
        />
        <label
          :for="'feed' + feedRecipientId + '-' + channelId + '-xfer-allmedia-0'"
        >
          {{ description }}
        </label>
        <input
          :id="'feed' + feedRecipientId + '-' + channelId + '-xfer-allmedia-1'"
          v-model="allMedia"
          type="radio"
          value="M"
          class="allmedia-radio"
          @change="settingsChanged"
        />
        <label
          :for="'feed' + feedRecipientId + '-' + channelId + '-xfer-allmedia-1'"
        >
          media files
        </label>
      </div>
    </div>
    <div v-else-if="description" class="row">
      <div class="col-12">Send {{ description }}</div>
    </div>
    <!-- method and address -->
    <div class="row">
      <div class="col-11">
        By
        <select
          v-model="method"
          size="1"
          class="method"
          @change="settingsChanged"
        >
          <option value="FTP">File Transfer</option>
          <option value="FTPC">File Transfer with Certificate</option>
          <option value="WEBFORM">Web Form</option>
          <option value="AMZS3">Amazon S3</option>
          <option v-if="allowEmail" value="EMAIL">Email</option>
          <option v-if="allowItmst" value="ITMST">
            iTunes Store Transporter
          </option>
          <option v-if="allowRef" value="REF">Reference</option>
          <option v-if="allowDataZip" value="DATAZIP">In Data ZIP</option>
        </select>
        <template v-if="method != 'REF' && method != 'DATAZIP'">
          &nbsp;to&nbsp;
          <input
            :id="'feed' + feedRecipientId + '-' + channelId + '-xfer-addr'"
            v-model="address"
            type="text"
            class="address"
            :pattern="addressValidation"
            @input="settingsChanged"
          />
        </template>
      </div>
      <div class="col-1 link-pad">
        <a
          v-if="method == 'FTP' || method == 'FTPC'"
          title="Click here to test this FTP connection"
          class="test-conn"
          @click="testFtpConnection"
        >
          <i class="fa fa-bolt fa-lg" />
        </a>
        <a
          href="https://docs.google.com/document/d/1vM0XOQtpHnbsBeVzIiH6aVG-7gKpN3wbhKuVPWmYzoE/preview#heading=h.nlqci0la9fgh"
          target="help"
          title="Click here for more information"
        >
          <i class="fa-regular fa-circle-question fa-lg" />
        </a>
      </div>
    </div>
    <template v-if="method != 'REF'">
      <!-- filename -->
      <div v-if="method != 'WEBFORM'" class="row">
        <div class="col-6 pr-0">
          Named:
          <input
            ref="filename"
            v-model="filename"
            type="text"
            :required="method == 'DATAZIP' || address != ''"
            :pattern="filenameValidation"
            class="filename"
            placeholder="Filename"
            @input="settingsChanged"
          />
        </div>
        <div class="col-6 pl-0 pt-1">
          <span class="filename-insert-label">insert: </span>
          <div class="filename-insert">
            <template v-for="(caption, token, index) in filenameTokensList">
              <a
                :key="index"
                href="#"
                :title="caption[1]"
                @click.prevent="inputInsertText('filename', token)"
              >
                {{ caption[0] }}
              </a>
              &nbsp;
            </template>
          </div>
        </div>
      </div>
      <!-- webform fields -->
      <div v-else class="row">
        <div class="col-6 pr-0">
          <span class="webform-label">Fields: </span>
          <textarea
            ref="webform"
            v-model="webform"
            :required="address != ''"
            rows="2"
            class="webform"
            @input="webformChanged"
          />
        </div>
        <div class="col-6 pl-0 pt-1">
          <span class="webform-label">insert: </span>
          <div class="webform-insert">
            <template v-for="(caption, token, index) in webformTokensList">
              <a
                :key="index"
                href="#"
                :title="caption[1]"
                @click.prevent="inputInsertText('webform', token)"
              >
                {{ caption[0] }}
              </a>
              &nbsp;
            </template>
          </div>
        </div>
      </div>
      <template v-if="method != 'DATAZIP'">
        <!-- zip -->
        <div v-if="method != 'ITMST'" class="row">
          <div class="col-12" :class="{ 'zip-col-pad': !zip }">
            <input
              :id="'feed' + feedRecipientId + '-' + channelId + '-xfer-zip'"
              v-model="zip"
              type="checkbox"
              class="zip"
              @change="settingsChanged"
            />
            <label
              :for="'feed' + feedRecipientId + '-' + channelId + '-xfer-zip'"
            >
              Store in a ZIP file
            </label>
            <template v-if="zip">
              <label>&nbsp;named:</label>
              <input
                ref="zipFilename"
                v-model="zipFilename"
                type="text"
                :required="address != ''"
                :pattern="filenameValidation"
                class="zip-filename"
                placeholder="ZIP filename"
                @input="settingsChanged"
              />
              insert:
              <template
                v-for="(caption, token, index) in zipFilenameTokensList"
              >
                <a
                  :key="index"
                  href="#"
                  :title="caption[1]"
                  @click.prevent="inputInsertText('zipFilename', token)"
                >
                  {{ caption[0] }}
                </a>
                &nbsp;
              </template>
            </template>
          </div>
        </div>
        <!-- zip-split -->
        <div v-if="allowZipSplit && method != 'ITMST'" class="row">
          <div class="col-12 zip-split-col">
            <input
              :id="
                'feed' + feedRecipientId + '-' + channelId + '-xfer-zip-split'
              "
              v-model="zipSplit"
              type="checkbox"
              :disabled="!zip"
              class="zip-split"
              @change="settingsChanged"
            />
            <label
              :for="
                'feed' + feedRecipientId + '-' + channelId + '-xfer-zip-split'
              "
            >
              Split records into individual files in the ZIP file
            </label>
          </div>
        </div>
        <!-- file-split -->
        <div v-if="allowFileSplit && method != 'ITMST'" class="row">
          <div class="col-12" :class="{ 'file-split-col-pad': !fileSplit }">
            <input
              :id="
                'feed' + feedRecipientId + '-' + channelId + '-xfer-file-split'
              "
              v-model="fileSplit"
              type="checkbox"
              :disabled="zip"
              class="file-split"
              @change="settingsChanged"
            />
            <label
              :for="
                'feed' + feedRecipientId + '-' + channelId + '-xfer-file-split'
              "
            >
              Split into multiple files
            </label>
            <template v-if="fileSplit">
              <label>&nbsp;each containing&nbsp;</label>
              <input
                v-model="fileSplitRecords"
                type="text"
                required
                pattern="[1-9][0-9]{0,8}"
                maxlength="9"
                :disabled="zip"
                class="file-split-records"
                @input="settingsChanged"
              />
              <label>&nbsp;records</label>
            </template>
          </div>
        </div>
        <!-- Amazon S3 region, access key and secret -->
        <div v-if="method == 'AMZS3'" class="row">
          <div class="col-11">
            AWS Region:
            <input
              v-model="awsRegion"
              type="text"
              :required="address != ''"
              pattern="(af|ap|ca|eu|me|sa|us)-(north|south|east|west|northeast|southeast|central)-[1-4]"
              class="aws-region"
              @input="settingsChanged"
            />
            Access key:
            <input
              v-model="username"
              type="text"
              :required="address != ''"
              class="aws-accesskey-secret"
              @input="settingsChanged"
            />
            Secret:
            <input
              v-model="password"
              type="text"
              :required="address != ''"
              class="aws-accesskey-secret"
              @input="settingsChanged"
            />
          </div>
          <div v-if="allowCredentialsCopy && credentialsXfer" class="col-1">
            <a
              title="Click here to copy the user credentials from the Data tab"
              class="copy-credentials"
              @click="copyUserCredentials"
            >
              <i class="fa fa-copy fa-lg" />
            </a>
          </div>
        </div>
        <!-- username and password -->
        <div v-else-if="method != 'EMAIL'" class="row">
          <div class="col-11">
            Username:
            <input
              v-model="username"
              type="text"
              :required="address != '' && method != 'WEBFORM'"
              class="username-password"
              @input="settingsChanged"
            />
            Password:
            <input
              v-model="password"
              type="text"
              :required="address != '' && method != 'WEBFORM'"
              class="username-password"
              @input="settingsChanged"
            />
          </div>
          <div v-if="allowCredentialsCopy && credentialsXfer" class="col-1">
            <a
              title="Click here to copy user credentials from the Data tab"
              class="copy-credentials"
              @click="copyUserCredentials"
            >
              <i class="fa fa-copy fa-lg" />
            </a>
          </div>
        </div>
        <!-- Private key and its password -->
        <div v-if="method == 'FTPC'" class="row">
          <div class="col-12">
            <span class="privatekey-label">Private Key:</span>
            <textarea
              v-model="userCertificate.privateKey"
              :required="address != ''"
              class="privatekey"
              placeholder="Private key"
              @input="settingsChanged"
            />
            <div class="privatekey-password">
              <span class="privatekey-label">Password:</span>
              <input
                v-model="userCertificate.privateKeyPassword"
                type="text"
                class="privatekey"
                placeholder="Private key password"
                @input="settingsChanged"
              />
            </div>
          </div>
        </div>
        <!-- Public key and host certificate MD5 -->
        <div v-if="method == 'FTPC'" class="row">
          <div class="col-12">
            <span class="privatekey-label">Public Key:</span>
            <textarea
              v-model="userCertificate.publicKey"
              class="privatekey"
              placeholder="Public key"
              @input="settingsChanged"
            />
            <div class="privatekey-password">
              <span class="privatekey-label">Host MD5:</span>
              <input
                v-model="userCertificate.hostPublicCertificateMD5"
                type="text"
                pattern="[0-9A-Fa-f]{32}"
                maxlength="32"
                class="privatekey"
                placeholder="Host public certificate MD5"
                @input="settingsChanged"
              />
            </div>
          </div>
        </div>
        <!-- FTP options -->
        <div v-if="method != 'EMAIL'" class="row">
          <div class="col-11">
            Options:
            <input
              v-model="options"
              type="text"
              pattern="\+?[a-z0-9\-]+(,[a-z0-9\-]+)*"
              class="options"
              @input="settingsChanged"
            />
            Default:
            <input
              type="text"
              :value="defaultXferOptions"
              readonly
              class="options"
              @input="settingsChanged"
            />
          </div>
          <div v-if="allowCredentialsCopy && credentialsXfer" class="col-1">
            <a
              title="Click here to copy the options from the Data tab"
              class="copy-credentials"
              @click="copyOptions"
            >
              <i class="fa fa-copy fa-lg" />
            </a>
          </div>
        </div>
        <!-- Email subject -->
        <div v-else class="row">
          <div class="col-12">
            Subject:
            <input
              v-model="emailSubject"
              type="text"
              class="email-subject"
              @input="settingsChanged"
            />
          </div>
        </div>
        <!-- ftp connection tester iframe -->
        <iframe
          src="about:blank"
          :srcdoc="ftpTesterFrameDoc"
          style="display: none"
        />
      </template>
    </template>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings transfer settings component
 */
export default {
  name: "XferSettings",

  props: {
    /**
     * Feed recipient id
     */
    feedRecipientId: {
      type: Number,
      required: true,
    },

    /**
     * Feed recipient name
     */
    feedRecipientName: {
      type: String,
      required: true,
    },

    /**
     * Channel id
     */
    channelId: {
      type: String,
      required: true,
    },

    /**
     * Description of the type of file being sent
     */
    description: {
      type: String,
      default: null,
    },

    /**
     * Transfer object
     */
    xfer: {
      type: Object,
      default: null,
    },

    /**
     * Default transfer options
     */
    defaultXferOptions: {
      type: String,
      default: "",
    },

    /**
     * Filename tokens
     */
    filenameTokens: {
      type: Object,
      default: null,
    },

    /**
     * Webform tokens
     */
    webformTokens: {
      type: Object,
      default: null,
    },

    /**
     * ZipFilename tokens
     */
    zipFilenameTokens: {
      type: Object,
      default: null,
    },

    /**
     * Allow All-Media option
     */
    allowAllMedia: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow Email method
     */
    allowEmail: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow ITMST method
     */
    allowItmst: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow Reference method
     */
    allowRef: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow DataZip method
     */
    allowDataZip: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow ZipSplit option
     */
    allowZipSplit: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow FileSplit option
     */
    allowFileSplit: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow CredentialsCopy option
     */
    allowCredentialsCopy: {
      type: Boolean,
      default: false,
    },

    /**
     * Credentials Xfer object - used when copying credentials
     */
    credentialsXfer: {
      type: Object,
      default: null,
    },
  },

  data: function () {
    let xfer = this.xfer ?? {};
    let dataObject = {
      method: xfer.method ?? "FTP",
      // eslint-disable-next-line prettier/prettier
      allMedia: (xfer.allMedia ?? false) ? "M" : "",
      zip: xfer.zip ?? false,
      zipSplit: xfer.zipSplit ?? false,
      fileSplit: xfer.fileSplit ? true : false,
      fileSplitRecords: xfer.fileSplit ? xfer.fileSplit.toString() : "",
      address: xfer.addr ?? "",
      filename: xfer.filename ?? "",
      webform: "",
      zipFilename: xfer.zipFilename ?? "",
      awsRegion: "",
      username: xfer.username ?? "",
      password: xfer.password ?? "",
      userCertificate: {
        privateKey: "",
        privateKeyPassword: "",
        publicKey: "",
        hostPublicCertificateMD5: "",
      },
      emailSubject: xfer.emailSubject ?? "",
      options: xfer.options ? xfer.options.replace(/ /g, "") : "",
      filenameTokensList: this.filenameTokens ?? {},
      webformTokensList: this.webformTokens ?? {},
      zipFilenameTokensList: this.zipFilenameTokens ?? {},
      ftpTesterFrameDoc: "",
    };

    if ("userCertificate" in xfer) {
      dataObject.userCertificate.privateKey =
        xfer.userCertificate.privateKey ?? "";
      dataObject.userCertificate.privateKeyPassword =
        xfer.userCertificate.privateKeyPassword ?? "";
      dataObject.userCertificate.publicKey =
        xfer.userCertificate.publicKey ?? "";
      dataObject.userCertificate.hostPublicCertificateMD5 =
        xfer.userCertificate.hostPublicCertificateMD5 ?? "";
    }

    if (dataObject.method == "FTP" && dataObject.userCertificate.privateKey) {
      dataObject.method = "FTPC";
    }

    if (dataObject.method == "WEBFORM" && xfer.webform) {
      let webformFields = [];
      for (const name in xfer.webform) {
        webformFields.push(name + "=" + xfer.webform[name]);
      }
      dataObject.webform = webformFields.join("\r\n");
    }

    if (dataObject.method == "AMZS3") {
      let sep = dataObject.username.indexOf("/");
      if (sep != -1) {
        dataObject.awsRegion = dataObject.username.substring(0, sep);
        dataObject.username = dataObject.username.substring(sep + 1);
      } else {
        dataObject.awsRegion = "";
      }
    }

    return dataObject;
  },

  computed: {
    addressValidation: function () {
      // note: in a browser and within a regex character class, forward slash, hyphen, left brace, right brace and pipe must be escaped
      switch (this.method) {
        case "FTP":
          return "(ftps?|ftpes|sftp|https?)://([A-Za-z0-9]([A-Za-z0-9\\-]*[A-Za-z0-9])?\\.)*[A-Za-z0-9]([A-Za-z0-9\\-]*[A-Za-z0-9])?(:[1-9][0-9]{0,4})?(/[!-~]*)?";
        case "FTPC":
          return "(ftps|ftpes|sftp|https)://([A-Za-z0-9]([A-Za-z0-9\\-]*[A-Za-z0-9])?\\.)*[A-Za-z0-9]([A-Za-z0-9\\-]*[A-Za-z0-9])?(:[1-9][0-9]{0,4})?(/[!-~]*)?";
        case "WEBFORM":
        case "AMZS3":
          return "https?://([A-Za-z0-9]([A-Za-z0-9\\-]*[A-Za-z0-9])?\\.)*[A-Za-z0-9]([A-Za-z0-9\\-]*[A-Za-z0-9])?(:[1-9][0-9]*)?(/.*)?";
        case "EMAIL":
          // reg exp pattern for an email address list
          // see SO question 429 for the structure of this pattern
          return "\\s*(([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-][A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~. \\t\\n\\r\\-]*|\"([^\"\\\\]|\\\\[!-~])*\")?\\s*<([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])>|([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\]))(\\s*,\\s*(([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-][A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~. \\t\\n\\r\\-]*|\"([^\"\\\\]|\\\\[!-~])*\")?\\s*<([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])>|([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])))*\\s*";
        default:
          return null;
      }
    },
    filenameValidation: function () {
      // "{opt-characters}" + REPEAT( ("$?{token}={opt-characters}:{opt-characters}$" OR "${token}$") + "{opt-characters}")
      return "[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*(\\$(\\?[A-Z0-9]+=[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*:[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*|[A-Z0-9]+)\\$[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*)*";
    },
    webformValidation: function () {
      // "{field}={opt-characters}" + REPEAT( ("$?{token}={opt-characters}:{opt-characters}$" OR "${token}$") + "{opt-characters}") + REPEAT( LF + ...)
      return "(!!)?[A-Za-z0-9_.:~\\-]+=[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*(\\$(\\?[A-Z0-9]+=[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*:[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*|[A-Z0-9]+)\\$[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*)*(\\r?\\n(!!)?[A-Za-z0-9_.:~\\-]+=[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*(\\$(\\?[A-Z0-9]+=[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*:[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*|[A-Z0-9]+)\\$[!#%&'\\(\\)+,.\\/0-9;=@A-Z\\[\\]^_`a-z\\{\\}~\\-]*)*)*";
    },
  },

  methods: {
    /**
     * Settings have changed
     */
    settingsChanged: function () {
      // check the fields are valid
      if (this.method != "REF") {
        if (this.method == "DATAZIP") {
          // method 'InDataZip': check filename
          // check filename
          let filenameCheck = new RegExp(
            "^" + this.filenameValidation + "$",
            ""
          );
          if (!filenameCheck.test(this.filename)) {
            this.$emit("data-errors");
            return;
          }
        } else if (this.address.length != 0) {
          // all methods except Ref and InDataZip and we have an address
          // check address
          let addressCheck = new RegExp("^" + this.addressValidation + "$", "");
          if (!addressCheck.test(this.address)) {
            this.$emit("data-errors");
            return;
          }

          // check webform/filename
          if (this.method == "WEBFORM") {
            let webformCheck = new RegExp(
              "^" + this.webformValidation + "$",
              ""
            );
            if (!webformCheck.test(this.webform)) {
              this.$emit("data-errors");
              return;
            }
          } else {
            let filenameCheck = new RegExp(
              "^" + this.filenameValidation + "$",
              ""
            );
            if (!filenameCheck.test(this.filename)) {
              this.$emit("data-errors");
              return;
            }
          }

          // check zip filename
          if (this.zip) {
            let zipFilenameCheck = new RegExp(
              "^" + this.filenameValidation + "$",
              ""
            );
            if (!zipFilenameCheck.test(this.zipFilename)) {
              this.$emit("data-errors");
              return;
            }
          }

          // check file-split records
          if (!this.zip && this.fileSplit) {
            if (this.fileSplitRecords.search(/[1-9][0-9]{0,8}/) == -1) {
              this.$emit("data-errors");
              return;
            }
          }

          // check AWS region
          if (this.method == "AMZS3") {
            if (
              this.awsRegion.search(
                /^(af|ap|ca|eu|me|sa|us)-(north|south|east|west|northeast|southeast|central)-[1-4]$/
              ) == -1
            ) {
              this.$emit("data-errors");
              return;
            }
          }

          // check username and password
          if (this.method != "EMAIL" && this.method != "WEBFORM") {
            if (this.username.length == 0 || this.password.length == 0) {
              this.$emit("data-errors");
              return;
            }
          }

          // check private key
          if (this.method == "FTPC") {
            // check private key
            if (this.userCertificate.privateKey.length == 0) {
              this.$emit("data-errors");
              return;
            }

            // check optional host public certificate MD5
            if (
              this.userCertificate.hostPublicCertificateMD5.search(
                /^([0-9A-Fa-f]{32})?$/
              ) == -1
            ) {
              this.$emit("data-errors");
              return;
            }
          }
        }
      }

      // copy existing settings
      let newXfer = {};
      let xfer = this.xfer ?? {};
      for (const prop in xfer) {
        if (typeof xfer[prop] == "object") {
          newXfer[prop] = {};
          for (const prop2 in xfer[prop]) {
            newXfer[prop][prop2] = xfer[prop][prop2];
          }
        } else {
          newXfer[prop] = xfer[prop];
        }
      }

      // store transfer settings
      newXfer.method = this.method == "FTPC" ? "FTP" : this.method;
      if (this.allowAllMedia) {
        newXfer.allMedia = this.allMedia == "M";
      }
      if (this.method != "REF") {
        if (this.method != "DATAZIP") {
          if (this.method != "ITMST") {
            newXfer.zip = this.zip;
            if (this.allowZipSplit && this.zip) {
              newXfer.zipSplit = this.zipSplit;
            }
            if (this.allowFileSplit && !this.zip) {
              if (this.fileSplit) {
                newXfer.fileSplit = parseInt(this.fileSplitRecords, 10);
              } else if (newXfer.fileSplit) {
                delete newXfer.fileSplit;
              }
            }
          }
          newXfer.addr = this.address;
        }
        if (this.method == "WEBFORM") {
          let webformFields = this.webform.replace(/\r\n/g, "\n").split("\n");
          newXfer.webform = {};
          for (const index in webformFields) {
            let webformField = webformFields[index];
            let sep = webformField.indexOf("=");
            if (sep != -1) {
              newXfer.webform[webformField.substring(0, sep)] =
                webformField.substring(sep + 1);
            } else {
              newXfer.webform[webformField] = "";
            }
          }
        } else {
          newXfer.filename = this.filename;
        }
        if (this.method != "DATAZIP") {
          if (this.zip) {
            newXfer.zipFilename = this.zipFilename;
          }
          if (this.method != "EMAIL") {
            if (this.method == "AMZS3") {
              newXfer.username = this.awsRegion + "/" + this.username;
            } else {
              newXfer.username = this.username;
            }
            newXfer.password = this.password;
          }
          if (this.method == "FTPC") {
            if (!("userCertificate" in newXfer)) {
              newXfer.userCertificate = {};
            }
            newXfer.userCertificate.privateKey =
              this.userCertificate.privateKey;
            newXfer.userCertificate.privateKeyPassword =
              this.userCertificate.privateKeyPassword;
            newXfer.userCertificate.publicKey = this.userCertificate.publicKey;
            newXfer.userCertificate.hostPublicCertificateMD5 =
              this.userCertificate.hostPublicCertificateMD5;
          }
          if (this.method == "EMAIL") {
            newXfer.emailSubject = this.emailSubject;
          } else {
            newXfer.options = this.options;
          }
        }
      }

      this.$emit("change", newXfer);
    },

    /**
     * Webform has changed
     */
    webformChanged: function () {
      let webformCheck = new RegExp("^" + this.webformValidation + "$", "");
      if (!webformCheck.test(this.webform)) {
        this.$refs.webform.setCustomValidity("Invalid content");
        this.$emit("data-errors");
        return;
      }

      this.$refs.webform.setCustomValidity("");
      this.settingsChanged();
    },

    /**
     * Insert text into an input control
     */
    inputInsertText: function (refAndProp, insertText) {
      let control = this.$refs[refAndProp];
      let selStart = control.selectionStart;
      let selEnd = control.selectionEnd;
      let newCursorPos = selStart + insertText.length;
      let newValue =
        this[refAndProp].substring(0, selStart) +
        insertText +
        this[refAndProp].substring(selEnd);
      this[refAndProp] = newValue;
      this.settingsChanged();
      this.$nextTick(() => {
        control.focus();
        control.setSelectionRange(newCursorPos, newCursorPos);
      });
    },

    /**
     * Test a ftp connection
     */
    testFtpConnection: function () {
      if (this.method != "FTP" && this.method != "FTPC") {
        this.$bvModal.msgBoxOk("Only FTP connections can be tested.");
        return;
      }
      if (this.address == "" || this.username == "" || this.password == "") {
        this.$bvModal.msgBoxOk(
          "Cannot test FTP connection as there is no FTP address, username or password."
        );
        return;
      }

      let winObj = window.open(
        "about:blank",
        "FtpTester",
        "width=640,height=420,directories=no,location=no,menubar=no,resizable=no,scrollbars=yes,status=no,toolbar=no"
      );
      if (winObj) winObj.focus();

      let ftpOptions = this.options;
      if (ftpOptions.length == 0 || ftpOptions.charAt(0) == "+") {
        let defaultOptions = this.defaultXferOptions
          ? this.defaultXferOptions.join(",")
          : "";
        if (ftpOptions.length != 0) {
          if (defaultOptions.length != 0) {
            ftpOptions = defaultOptions + "," + ftpOptions.substring(1);
          } else {
            ftpOptions = ftpOptions.substring(1);
          }
        } else {
          ftpOptions = defaultOptions;
        }
      }

      let ftpTesterFrameDoc =
        '<!DOCTYPE HTML><html><head></head><body onload="document.testerform.submit();">' +
        '<form action="/common/ftptester.php/' +
        this.$appId +
        '" method="post" target="FtpTester" name="testerform">' +
        '<input name="L" type="hidden" value="' +
        this.$logonId +
        '"><input name="DT" type="hidden" value="' +
        this.channelId +
        '"><input name="DR" type="hidden" value="' +
        this.htmlEscape(this.feedRecipientName) +
        '"><input name="F" type="hidden" value="' +
        this.htmlEscape(this.address) +
        '"><input name="U" type="hidden" value="' +
        this.htmlEscape(this.username) +
        '"><input name="P" type="hidden" value="' +
        this.htmlEscape(this.password) +
        '"><input name="O" type="hidden" value="' +
        this.htmlEscape(ftpOptions) +
        '">';
      if (this.method == "FTPC") {
        ftpTesterFrameDoc +=
          '<input name="CV" type="hidden" value="' +
          this.htmlEscape(this.userCertificate.privateKey) +
          '"><input name="CP" type="hidden" value="' +
          this.htmlEscape(this.userCertificate.privateKeyPassword) +
          '"><input name="CB" type="hidden" value="' +
          this.htmlEscape(this.userCertificate.publicKey) +
          '"><input name="CH" type="hidden" value="' +
          this.htmlEscape(this.userCertificate.hostPublicCertificateMD5) +
          '">';
      }
      ftpTesterFrameDoc += "</form><body></html>";
      this.ftpTesterFrameDoc = ftpTesterFrameDoc;
    },

    /**
     * Copy user credentials from the data tab
     */
    copyUserCredentials: function () {
      if (this.credentialsXfer.username) {
        this.awsRegion = this.credentialsXfer.awsRegion
          ? this.credentialsXfer.awsRegion
          : "";
        this.username = this.credentialsXfer.username;
        this.password = this.credentialsXfer.password;
      }

      if (this.credentialsXfer.userCertificate) {
        this.userCertificate.privateKey = this.credentialsXfer.userCertificate
          .privateKey
          ? this.credentialsXfer.userCertificate.privateKey
          : "";
        this.userCertificate.privateKeyPassword = this.credentialsXfer
          .userCertificate.privateKeyPassword
          ? this.credentialsXfer.userCertificate.privateKeyPassword
          : "";
        this.userCertificate.publicKey = this.credentialsXfer.userCertificate
          .publicKey
          ? this.credentialsXfer.userCertificate.publicKey
          : "";
        this.userCertificate.hostPublicCertificateMD5 = this.credentialsXfer
          .userCertificate.hostPublicCertificateMD5
          ? this.credentialsXfer.userCertificate.hostPublicCertificateMD5
          : "";
      }
    },

    /**
     * Copy options from the data tab
     */
    copyOptions: function () {
      if (this.credentialsXfer.options) {
        this.options = this.credentialsXfer.options;
      }
    },

    /**
     * HTML escape a string
     */
    htmlEscape: function (str) {
      return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
    },
  },
};
</script>

<style scoped>
input.allmedia-radio {
  margin-left: 1rem;
  margin-right: 0.25rem;
}
input.address {
  width: 72%;
}
input[type="text"]:invalid,
textarea:invalid {
  border: red solid 3px;
  padding-top: 0px;
  padding-bottom: 0px;
}
div.link-pad {
  padding-top: 0.5rem;
}
a.test-conn {
  margin-top: 5px;
  padding-right: 0.5rem;
}
input.filename {
  width: 86%;
}
span.filename-insert-label {
  vertical-align: top;
}
div.filename-insert {
  display: inline-block;
  width: 85%;
}
div.filename-insert a {
  display: inline-block;
}
span.webform-label {
  vertical-align: top;
}
textarea.webform {
  width: 90%;
}
div.webform-insert {
  display: inline-block;
  width: 85%;
}
div.zip-col-pad {
  margin-top: 5px;
}
input.zip,
input.zip-split,
input.file-split {
  margin-right: 5px;
}
input.zip-filename {
  width: 31%;
}
div.zip-split-col {
  padding-left: 25px;
}
div.file-split-col-pad {
  margin-top: 5px;
}
input.file-split-records {
  width: 8%;
}
input.aws-region {
  width: 10%;
}
input.aws-accesskey-secret {
  width: 27%;
}
input.username-password {
  width: 36%;
}
a.copy-credentials {
  margin-top: 5px;
  padding-right: 0.5rem;
}
span.privatekey-label {
  vertical-align: top;
  display: inline-block;
  margin-top: 0.25rem;
}
textarea.privatekey {
  margin-left: 5px;
  margin-right: 5px;
  height: 25px;
  width: 35%;
  resize: none;
}
textarea.privatekey:focus {
  height: 150px;
  width: 80%;
  white-space: pre;
}
div.privatekey-password {
  vertical-align: top;
  display: inline-block;
  margin-top: 0.25rem;
  width: 50%;
}
input.privatekey {
  width: 73%;
}
input.options {
  width: 36%;
}
input.email-subject {
  width: 82%;
}
</style>
