<template>
  <v-tour v-bind="$attrs" :callbacks="tourCallbacks"></v-tour>
</template>

<script>
/**
 * Extension of the v-tour component by Vue tours
 * https://pulsardev.github.io/vue-tour/
 *
 * This adds the option of a tour popup which will
 * allow the user to choose to not show the tour again
 * (saves to the user settings table via an ajax call)
 */

import { HTTP } from "../../http-common.js";

export default {
  name: "BooksonixTour",

  props: {
    autoStart: {
      type: Boolean,
      default: true,
    },
    /**
     * By default a tour or popup won't display to
     * users with the H/Headfast category. Use this
     * option to force this tour to always display.
     */
    displayToHeadfastUsers: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    disableTourUrl: function () {
      return (
        this.$baseUrl +
        "api/usersettings/write-setting/tours/disable-" +
        this.$attrs.name
      );
    },
    tourCallbacks: function () {
      let callbacks = {};
      if (this.$attrs.callbacks) {
        callbacks = {
          ...this.$attrs.callbacks,
        };
      } else {
        callbacks = {
          onSkip: () => this.disableTour(),
          onFinish: () => this.disableTour(),
        };
      }
      return callbacks;
    },
  },
  mounted: function () {
    if (this.autoStart) {
      if (!this.displayToHeadfastUsers && this.$userHasCategory("H")) {
        return;
      }
      HTTP.get(
        this.$baseUrl +
          "api/usersettings/read-setting/tours/disable-" +
          this.$attrs.name
      ).then((response) => {
        // all settings are saved as strings so read the false string
        if (!response.data.value || response.data.value == "false") {
          this.$tours[this.$attrs.name].start();
        }
      });
    }
  },

  methods: {
    /**
     * Disables the tour by saving the user setting
     */
    disableTour: async function () {
      // check whether the user would like to stop this tour displaying
      const confirm = await this.$bvModal.msgBoxConfirm(
        "Would you like to stop this tour from displaying again?",
        {
          title: "Confirm",
          okVariant: "success",
          okTitle: "Yes",
          cancelVariant: "light",
          cancelTitle: "No",
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        }
      );

      if (confirm) {
        let postData = new FormData();
        postData.append("value", true);
        HTTP.post(this.disableTourUrl, postData);
      }
    },
  },
};
</script>

<style>
.v-step {
  max-width: 420px !important;
}
.v-tour__target--highlighted,
.v-step--sticky {
  box-shadow: 0px 0px 0 9999px rgb(0 0 0 / 35%) !important;
  -webkit-box-shadow: 0px 0px 0 9999px rgb(0 0 0 / 35%) !important;
  padding: 5px;
  transition: none !important;
  opacity: 1 !important;
}
.v-step__content a {
  color: #62c5ff;
  text-decoration: none;
}
</style>
