var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"productCardBibliographic"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('a',{attrs:{"id":"bibliographic"}}),_c('h5',{staticClass:"d-inline-block"},[_vm._v("Bibliographic")]),(_vm.$permitted('biblio/edit') && _vm.allowEdit)?_c('edit-icon',{staticClass:"ml-2 d-print-none",attrs:{"link":_vm.$baseUrl +
        '/biblio-edit/edit-biblio/' +
        _vm.productId +
        (_vm.returnTo ? '?return=' + _vm.returnTo : '')}}):_vm._e(),_c('product-quick-jump-dropdown',{staticClass:"float-right",attrs:{"product-id":_vm.productId,"display":_vm.display,"has-custom-fields":_vm.hasCustomFields}})]},proxy:true}])},[(_vm.product)?_c('dl',{staticClass:"row"},[(
        (_vm.product.getLanguages && _vm.product.getLanguages.getText) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Languages of Text ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getLanguages && _vm.product.getLanguages.getText)?_vm._l((_vm.product.getLanguages.getText),function(text,index){return _c('div',{key:index,staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(text.getLanguageName)),(text.getCountryName)?_c('span',[_vm._v(" ("+_vm._s(text.getCountryName)+")")]):_vm._e(),(index < _vm.product.getLanguages.getText.length - 1)?_c('span',[_vm._v(",  ")]):_vm._e()])}):[_vm._v("—")]],2)]:_vm._e(),(
        (_vm.product.getLanguages &&
          _vm.product.getLanguages.getOriginalOfATranslatedText) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Original Text Languages ")]),_c('dd',{staticClass:"col-sm-9"},[(
            _vm.product.getLanguages &&
            _vm.product.getLanguages.getOriginalOfATranslatedText
          )?_vm._l((_vm.product.getLanguages
              .getOriginalOfATranslatedText),function(text,index){return _c('div',{key:index,staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(text.getLanguageName)),(text.getCountryName)?_c('span',[_vm._v(" ("+_vm._s(text.getCountryName)+")")]):_vm._e(),(
                index !=
                _vm.product.getLanguages.getOriginalOfATranslatedText.length - 1
              )?_c('span',[_vm._v(",  ")]):_vm._e()])}):[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getEditionTypesNames || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("Edition Types")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getEditionTypesNames)?_vm._l((_vm.product.getEditionTypesNames),function(edition,index){return _c('div',{key:index,staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(edition)),(index != _vm.product.getEditionTypesNames.length - 1)?_c('span',[_vm._v(";  ")]):_vm._e()])}):[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getEditionNumber || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("Edition Number")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getEditionNumber)?[_vm._v(_vm._s(_vm.product.getEditionNumber))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getEditionRevisionNumber || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Edition Revision Number ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getEditionRevisionNumber)?[_vm._v(_vm._s(_vm.product.getEditionRevisionNumber))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getEditionStatement || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Edition Statement ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getEditionStatement)?[_vm._v(_vm._s(_vm.product.getEditionStatement))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getNoEdition == true)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("No Edition")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v("Yes")])]:_vm._e(),(
        (_vm.product.getIllustrations &&
          _vm.product.getIllustrations.getType &&
          _vm.product.getIllustrations.getType.length > 0) ||
        (_vm.product.getIllustrations &&
          _vm.product.getIllustrations.getTotalCount > 0) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("Illustrations")]),_c('dd',{staticClass:"col-sm-9"},[(
            _vm.product.getIllustrations &&
            _vm.product.getIllustrations.getTotalCount > 0
          )?[_vm._v(_vm._s(_vm.product.getIllustrations.getTotalCount)+" Illustrations; ")]:_vm._e(),(
            _vm.product.getIllustrations.getType &&
            _vm.product.getIllustrations.getType.length > 0
          )?_vm._l((_vm.product.getIllustrations.getType),function(illustration,index){return _c('div',{key:index,staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(illustration.getValue)+" "+_vm._s(illustration.getTypeName)),(index != _vm.product.getIllustrations.getType.length - 1)?_c('span',[_vm._v("; ")]):_vm._e()])}):_vm._e(),(
            (!_vm.product.getIllustrations.getType ||
              _vm.product.getIllustrations.getType.length == 0) &&
            !_vm.product.getIllustrations.getTotalCount &&
            _vm.showAllFields
          )?[_vm._v("—")]:_vm._e()],2)]:_vm._e(),(
        (_vm.product.getIllustrations && _vm.product.getIllustrations.getNote) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Illustration Note ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getIllustrations && _vm.product.getIllustrations.getNote)?[_vm._v(_vm._s(_vm.product.getIllustrations.getNote))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getPublishingStatusName || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Publishing Status ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getPublishingStatusName)?[_vm._v(_vm._s(_vm.product.getPublishingStatusName))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getPublishingStatusNote || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Publishing Status Note ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getPublishingStatusNote)?[_vm._v(_vm._s(_vm.product.getPublishingStatusNote))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getPublishingDates || _vm.showAllFields)?[(_vm.product.getPublishingDates.getPublicationDate || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Publication Date ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getPublishingDates.getPublicationDate)?[_vm._v(_vm._s(_vm._f("parseDate")(_vm.product.getPublishingDates.getPublicationDate,_vm.dateOptions.format, _vm.dateOptions)))]:[_vm._v("—")]],2)]:_vm._e(),(
          _vm.product.getPublishingDates.getPublicAnnouncementDate ||
          _vm.showAllFields
        )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Public Announcement Date ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getPublishingDates.getPublicAnnouncementDate)?[_vm._v(_vm._s(_vm._f("parseDate")(_vm.product.getPublishingDates.getPublicAnnouncementDate,_vm.dateOptions.format, _vm.dateOptions)))]:[_vm._v("—")]],2)]:_vm._e(),(
          _vm.product.getPublishingDates.getTradeAnnouncementDate || _vm.showAllFields
        )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Trade Announcement Date ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getPublishingDates.getTradeAnnouncementDate)?[_vm._v(_vm._s(_vm._f("parseDate")(_vm.product.getPublishingDates.getTradeAnnouncementDate,_vm.dateOptions.format, _vm.dateOptions)))]:[_vm._v("—")]],2)]:_vm._e(),(
          _vm.product.getPublishingDates.getFirstPublishedDate || _vm.showAllFields
        )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" First Published Date ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getPublishingDates.getFirstPublishedDate)?[_vm._v(_vm._s(_vm._f("parseDate")(_vm.product.getPublishingDates.getFirstPublishedDate,_vm.dateOptions.format, _vm.dateOptions)))]:[_vm._v("—")]],2)]:_vm._e(),(
          _vm.product.getPublishingDates
            .getFirstPublishedInOriginalLanguageDate || _vm.showAllFields
        )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" First Published in Original Language Date ")]),_c('dd',{staticClass:"col-sm-9"},[(
              _vm.product.getPublishingDates
                .getFirstPublishedInOriginalLanguageDate
            )?[_vm._v(_vm._s(_vm._f("parseDate")(_vm.product.getPublishingDates .getFirstPublishedInOriginalLanguageDate,_vm.dateOptions.format, _vm.dateOptions)))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getPublishingDates.getLastReprintDate || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Last Reprint Date ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getPublishingDates.getLastReprintDate)?[_vm._v(_vm._s(_vm._f("parseDate")(_vm.product.getPublishingDates.getLastReprintDate,_vm.dateOptions.format, _vm.dateOptions)))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getPublishingDates.getLastReissueDate || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Last Reissue Date ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getPublishingDates.getLastReissueDate)?[_vm._v(_vm._s(_vm._f("parseDate")(_vm.product.getPublishingDates.getLastReissueDate,_vm.dateOptions.format, _vm.dateOptions)))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getPublishingDates.getOutOfPrintDate || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Out of Print Date ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getPublishingDates.getOutOfPrintDate)?[_vm._v(_vm._s(_vm._f("parseDate")(_vm.product.getPublishingDates.getOutOfPrintDate,_vm.dateOptions.format, _vm.dateOptions)))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getPublishingDates.getTransferDate || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Transfer Date ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getPublishingDates.getTransferDate)?[_vm._v(_vm._s(_vm._f("parseDate")(_vm.product.getPublishingDates.getTransferDate,_vm.dateOptions.format, _vm.dateOptions)))]:[_vm._v("—")]],2)]:_vm._e()]:_vm._e(),(
        (_vm.product.getCopyrights &&
          _vm.product.getCopyrights.get &&
          _vm.product.getCopyrights.get.length > 0) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Copyright Statements ")]),_c('dd',{staticClass:"col-sm-9"},[(
            _vm.product.getCopyrights &&
            _vm.product.getCopyrights.get &&
            _vm.product.getCopyrights.get.length > 0
          )?_vm._l((_vm.product.getCopyrights.get),function(copyright,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(copyright.getYears[0])),(copyright.getYears.length > 1)?[_vm._v("-"+_vm._s(copyright.getYears[copyright.getYears.length - 1]))]:_vm._e(),_vm._v(" - "+_vm._s(copyright.getCopyrightOwner)+" ")],2)}):[_vm._v("—")]],2)]:_vm._e(),(
        _vm.product.getNotificationTypeName ||
        _vm.product.getRecordSourceTypeName ||
        _vm.product.getRecordSourceName ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("Dissemination")]),_c('dd',{staticClass:"col-sm-9"}),(_vm.product.getNotificationTypeName || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Notification Type ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getNotificationTypeName)?[_vm._v(_vm._s(_vm.product.getNotificationTypeName))]:[_vm._v("—")]],2)]:_vm._e(),(_vm.product.getRecordSourceTypeName || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Record Source ")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getRecordSourceTypeName)?[_vm._v(" "+_vm._s(_vm.product.getRecordSourceTypeName)),(_vm.product.getRecordSourceName)?[_vm._v(" - "+_vm._s(_vm.product.getRecordSourceName))]:_vm._e()]:[_vm._v("—")]],2)]:_vm._e()]:_vm._e(),(
        (_vm.product.getProductWebsites &&
          _vm.product.getProductWebsites.getWebsites &&
          _vm.product.getProductWebsites.getWebsites.length > 0) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("Websites")]),_c('dd',{staticClass:"col-sm-9"},[(
            _vm.product.getProductWebsites &&
            _vm.product.getProductWebsites.getWebsites &&
            _vm.product.getProductWebsites.getWebsites.length > 0
          )?_vm._l((_vm.product.getProductWebsites.getWebsites),function(website,index){return _c('div',{key:index},[_c('a',{attrs:{"href":website.getLinkUrl,"target":"_blank"}},[_vm._v(_vm._s(website.getLinkUrl))]),(website.getRoleName)?[_vm._v(" ("+_vm._s(website.getRoleName)+")")]:_vm._e()],2)}):[_vm._v("—")]],2)]:_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }