<template>
  <b-card :sub-title="payee.DisplayName">
    <dl class="row">
      <dt class="col-sm-5 font-weight-bold text-secondary">Record Number</dt>
      <dd class="col-sm-7">
        {{ payee.RecordNumber | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-5 font-weight-bold text-secondary">
        Statement Currency
      </dt>
      <dd class="col-sm-7">
        {{ payee.PayeeStatementCurrency | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-5 font-weight-bold text-secondary">Minimum Payout</dt>
      <dd class="col-sm-7">
        {{ payee.PayeeMinPayout | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-5 font-weight-bold text-secondary">
        Minimum Payout in Statement Currency
      </dt>
      <dd class="col-sm-7">
        <template v-if="payee.PayeeMinPayoutInStatementCurrency == 'Y'"
          >Yes</template
        >
        <template v-if="payee.PayeeMinPayoutInStatementCurrency == 'N'"
          >No</template
        >
        <template v-else>
          {{ payee.PayeeMinPayoutInStatementCurrency | valueOrPlaceholder }}
        </template>
      </dd>
      <dt class="col-sm-5 font-weight-bold text-secondary">Accounting Code</dt>
      <dd class="col-sm-7">
        {{ payee.PayeeAccountingCode | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-5 font-weight-bold text-secondary">Split Share</dt>
      <dd class="col-sm-7">
        <template
          v-if="payee.PayeeSplitShare !== null && payee.PayeeSplitShare >= 0"
        >
          {{ payee.PayeeSplitShare.toFixed(2) }}%
        </template>
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-5 font-weight-bold text-secondary">VAT Percentage</dt>
      <dd class="col-sm-7">
        <template
          v-if="payee.PayeeTaxPercent !== null && payee.PayeeTaxPercent >= 0"
        >
          {{ payee.PayeeTaxPercent.toFixed(2) }}%
        </template>
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-5 font-weight-bold text-secondary">VAT Number</dt>
      <dd class="col-sm-7">
        {{ payee.PayeeVatNumber | valueOrPlaceholder }}
      </dd>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list of royalty payee data
 */

import ParseDate from "../../mixins/parseDate.js";
import DisplaySetting from "../../mixins/productDisplaySetting.js";

export default {
  name: "RoyaltiesPayeeDescriptiveListCard",

  mixins: [ParseDate, DisplaySetting],

  props: {
    /**
     * Payee data
     *
     * Object which contains the following
     * fields from the BooksonixContactFormatter:
     *  - RecordNumber
     *  - DisplayName
     *  - PayeeAccountingCode
     *  - PayeeStatementCurrency
     *  - PayeeMinPayout
     *  - PayeeTaxPercent
     *  - PayeeMinPayoutInStatementCurrency
     *  - PayeeVatNumber
     */
    payee: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style></style>
