/**
 * Global mixin to provide a method for
 * checking user permissions
 *
 * @author George Betts
 */

var UserPermissions = {
  methods: {
    /**
     * Checks whether a user is permitted
     *
     * @param {string|array} permission The name of the permission, or a list of permissions
     * @param {bool} requireAll Used when permission is an array, changes the behaviour
     *                          to require a user to have ALL provided permissions
     */
    $permitted(permissions, requireAll = false) {
      if (
        typeof permissions === "string" &&
        this.$userPermissions.includes(permissions)
      ) {
        return true;
      }
      let permissionsMet = [];
      if (Array.isArray(permissions)) {
        for (let perm of permissions) {
          if (this.$userPermissions.includes(perm)) {
            if (!requireAll) {
              return true;
            }
            permissionsMet.push(perm);
          }
        }
        if (permissionsMet.length == permissions.length) {
          return true;
        }
      }
      return false;
    },

    /**
     * Checks whether a feature is enabled in BooksoniX
     *
     * @param {string} feature The name of the feature
     * @returns
     */
    $featureEnabled(feature) {
      if (this.$enabledFeatures[feature]) {
        return true;
      }
      return false;
    },

    /**
     * Checks whether a user belongs to a
     * provided category
     *
     * @param {string} category
     */
    $userHasCategory(category) {
      if (this.$userCat === category) {
        return true;
      }
      return false;
    },
  },
};

export default UserPermissions;
