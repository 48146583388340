<template>
  <b-card
    class="info-box"
    no-body
    border-variant="secondary"
    header-border-variant="secondary"
  >
    <div class="p-1 text-center">
      <i class="fa-solid fa-circle-info mr-2"></i>
      <!-- @slot Content of the InfoBox -->
      <slot>
        <!-- eslint-disable vue/no-v-html -->
        <div class="d-inline-block" v-html="text"></div>
      </slot>
    </div>
  </b-card>
</template>

<script>
/**
 * Displays text in a bordered box with a help icon - used to display how to information
 * on a page
 */
export default {
  name: "InfoBox",

  props: {
    /**
     * Text to display in the InfoBox
     * HTML can be included
     */
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.info-box {
  border-style: dashed;
}
</style>
