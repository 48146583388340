<template>
  <well>
    <h3 class="font-weight-bold">FTP Credentials</h3>
    <template v-if="ftpCredentials !== null">
      <p class="mt-2">
        <!-- @slot Help text or description of the credentials -->
        <slot>The following login details can be used to access FTP</slot>
      </p>
      <b-form-group label="Username" label-for="ftp-username">
        <b-input-group>
          <b-form-input
            id="ftp-username"
            ref="ftp-username"
            v-model="ftpCredentials.id"
            readonly
            jest-ftp-username
          ></b-form-input>
          <b-input-group-append>
            <b-button
              id="btn-copy-username"
              v-b-tooltip.manual="'Copied'"
              variant="outline-primary"
              @click="
                $copyToClipboard(
                  'ftp-username',

                  'btn-copy-username',
                  ftpCredentials.id
                )
              "
              ><i class="fa-solid fa-copy"></i> Copy to Clipboard</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Password" label-for="ftp-password">
        <b-input-group>
          <b-form-input
            id="ftp-password"
            ref="ftp-password"
            v-model="ftpCredentials.pw"
            :type="showPassword ? 'text' : 'password'"
            readonly
            jest-ftp-password
          ></b-form-input>
          <b-input-group-append>
            <b-button
              id="btn-show-password"
              variant="outline-primary"
              @click="showPassword = !showPassword"
              ><template v-if="showPassword">Hide</template
              ><template v-else>Show</template></b-button
            >
            <b-button
              id="btn-copy-password"
              v-b-tooltip.manual="'Copied'"
              variant="outline-primary"
              @click="
                $copyToClipboard(
                  'ftp-password',
                  'btn-copy-password',
                  ftpCredentials.pw
                )
              "
              ><i class="fa-solid fa-copy"></i> Copy to Clipboard</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </template>
    <template v-else>
      <info-box class="mt-2">
        <slot name="no-credentials-text"
          >No FTP credentials have been created yet.</slot
        ></info-box
      >
    </template>
  </well>
</template>

<script>
/**
 * Displays a customers FTP details for a given
 * configuration
 */

import Well from "../ui/Well.vue";
import InfoBox from "../ui/InfoBox.vue";
import copyToClipboard from "../../mixins/copyToClipboard.js";

export default {
  name: "FtpDetails",

  components: {
    well: Well,
    "info-box": InfoBox,
  },

  mixins: [copyToClipboard],

  props: {
    /**
     * The FTP credentials to display to the user
     * Definition for the credentials object can be seen in the
     * comments for ConfigurationsTable::CF_APPFTP_DETAILS
     */
    ftpCredentials: {
      type: Object,
      default: null,
    },
  },

  data: function () {
    return {
      showPassword: false,
    };
  },
};
</script>

<style scoped>
.form-control[readonly] {
  background-color: #fff;
}
.btn-outline-primary {
  background-color: #fff;
}
</style>
