<template>
  <i class="fa" :class="sortClass"></i>
</template>

<script>
export default {
  name: "SortIcon",
  props: {
    sortDirection: {
      type: String,
      default: null,
    },
  },
  computed: {
    sortClass: function () {
      return {
        "fa-sort-up": this.sortDirection == "asc",
        "fa-sort-down": this.sortDirection != "asc",
      };
    },
  },
};
</script>

<style scoped></style>
