var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.workflowObject)?_c('div',[_c('div',[_vm._v(" This title is using the "),_c('strong',[_vm._v(_vm._s(_vm.workflowObject.getTemplate.getName))]),_vm._v(" workflow. ")]),(_vm.workflowObject.getOwners)?_c('div',{staticClass:"mt-3 row"},[_vm._m(0),_c('div',{staticClass:"col-sm-10"},_vm._l((_vm.workflowObject.getOwners),function(owner,index){return _c('span',{key:index},[_vm._v(_vm._s(owner)),(index != _vm.workflowObject.getOwners.length - 1)?[_vm._v(", ")]:_vm._e()],2)}),0)]):_vm._e(),_vm._m(1),_c('base-table',{staticClass:"mt-3",attrs:{"filters-enabled":false,"columns":_vm.columns,"initial-data":_vm.workflowTasks,"date-format":_vm.getDateFormatFromUserFormat(_vm.$userPreferences.dateFormat),"row-class":_vm.highlightOverdueTask},scopedSlots:_vm._u([{key:"completed",fn:function(ref){
var record = ref.record;
return [_c('b-form-checkbox',{attrs:{"disabled":record.completed && !_vm.$permitted('workflow/uncomplete')},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateChecked.apply(void 0, [ record.hidden.task_id ].concat( argsArray ))}},model:{value:(record.completed),callback:function ($$v) {_vm.$set(record, "completed", $$v)},expression:"record.completed"}})]}}],null,false,641406942)}),_c('div',{staticClass:"mt3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.$permitted('workflow/edit'))?_c('b-button',{attrs:{"id":"btn-edit-workflow","variant":"outline-primary","href":_vm.$baseUrl +
            'biblio-edit/edit-workflow/' +
            _vm.productId +
            (_vm.returnTo ? '?return=' + _vm.returnTo : '')}},[_vm._v("Edit Workfow")]):_vm._e(),(_vm.$permitted('workflow/edit'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-2",attrs:{"id":"btn-copy-workflow","title":"Click to copy this products workflow settings to all other marked records.","variant":"outline-primary"},on:{"click":_vm.copyWorkflow}},[_vm._v("Copy Workflow")]):_vm._e(),(_vm.$permitted('workflow/edit'))?_c('b-button',{staticClass:"ml-2",attrs:{"id":"btn-delete-workflow","variant":"outline-danger"},on:{"click":_vm.deleteWorkflow}},[_vm._v("Delete Workflow")]):_vm._e()],1),_c('div',{staticClass:"col float-right"},[(_vm.$permitted('workflow/edit'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"float-right",attrs:{"id":"btn-workflow-update-completed","title":"Click to save the completion status of the workflow tasks. Use the checkboxes to mark each task as completed.","variant":"outline-primary","disabled":!_vm.checkboxClicked},on:{"click":_vm.updateCompleted}},[_vm._v("Save Completion Status")]):_vm._e()],1)])])],1):_c('div',[_c('div',[_vm._v(" There is no workflow for this title"),(_vm.$permitted('workflow/edit'))?[_vm._v(", please click on Create Workflow to create one.")]:_vm._e()],2),_c('div',{staticClass:"mt-2"},[(_vm.$permitted('workflow/edit'))?_c('b-button',{attrs:{"variant":"outline-primary","href":_vm.$baseUrl +
        'biblio-edit/edit-workflow/' +
        _vm.productId +
        (_vm.returnTo ? '?return=' + _vm.returnTo : '')}},[_vm._v("Create Workfow")]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-2"},[_c('strong',[_vm._v("Workflow Owner(s)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1 mt-2"},[_c('p',[_vm._v(" Use the table below to update the completion status of workflow tasks on this product. Use the checkbox to change the completion of a task then save using the 'Save Completion Status' button. ")]),_c('p',[_vm._v(" Workflow settings on this product can be copied to all other marked records by clicking the 'Copy Workflow' button. ")])])}]

export { render, staticRenderFns }