<template>
  <b-card id="productCardImagesAndMedia" bg-variant="light">
    <template #header>
      <a id="images-and-media" />
      <h5 class="d-inline-block">Images &amp; Media</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-media/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <div class="row">
      <div class="col">
        <file-upload-box
          :class="{ emptyBox: fileList.length == 0 }"
          hover-text="Drop a file here to upload it to the product"
          @drop="showFileUploadPopup"
        >
          <div
            class="d-flex flex-wrap"
            :class="{
              'justify-content-start': fileList.length > 0,
              'justify-content-center': fileList.length == 0,
            }"
          >
            <div
              v-for="(media, index) in fileList"
              :key="index"
              class="m-2"
              style="height: 200px"
            >
              <product-media-img
                v-if="isImage(media)"
                style="min-width: 150px; height: 200px"
                thumbnail
                :url="urlSafeFilename(media.getLink, media.getLinkType)"
                :media-type="media.getType21Name"
                :file-size="
                  media.getLocalresource ? media.getLocalresource.getSize : null
                "
                :file-name="
                  media.getLocalresource
                    ? media.getLocalresource.getFilename
                    : null
                "
                :last-modified="
                  media.getLocalresource
                    ? media.getLocalresource.getLastModified
                    : null
                "
                :media-object="media"
                :share-media-modal-id="shareMediaModalId"
                :logon-id="logonId"
                :date-options="dateOptions"
                :disable-overlay="allowEdit == false"
                :show-tooltip="true"
                :img-height="200"
              ></product-media-img>
              <product-media-file-card
                v-else
                :file-size="
                  media.getLocalresource ? media.getLocalresource.getSize : null
                "
                :media-type="media.getType21Name"
                :file-name="
                  media.getLocalresource
                    ? media.getLocalresource.getFilename
                    : null
                "
                :last-modified="
                  media.getLocalresource
                    ? media.getLocalresource.getLastModified
                    : null
                "
                :media-object="media"
                :logon-id="logonId"
                :date-options="dateOptions"
              >
              </product-media-file-card>
            </div>
          </div>
        </file-upload-box>
      </div>
    </div>
    <div v-if="fileList.length > 0" class="row">
      <div class="col text-center">
        <b>Media Files Size:</b> {{ totalMediaSize }} KB<template
          v-if="countMediaFilesWithSize !== countTotalMediaFiles"
        >
          ({{ countMediaFilesWithSize }} of {{ countTotalMediaFiles }} media
          files)</template
        >
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <p class="mt-2">
          Drag and drop files into the Images &amp; Media box to upload them to
          the product
        </p>
      </div>
    </div>
    <product-file-upload-modal
      :id="modalId"
      :logon-id="logonId"
      :product-id="productId"
      :file-list="droppedFiles"
      :media-types="mediaTypes"
      :media-formats="mediaFormats"
      :user-id-number="userIdNumber"
      :product-media="fileList"
      @files-uploaded="refreshFileList"
    ></product-file-upload-modal>
  </b-card>
</template>

<script>
/**
 * Displays a grid list of product media thumbnails
 */

import ProductMediaImg from "./ProductMediaImg.vue";
import ProductMediaFileCard from "./ProductMediaFileCard.vue";
import FileUploadBox from "../utility/FileUploadBox.vue";
import ProductFileUploadModal from "./ProductFileUploadModal.vue";
import ParseDate from "../../mixins/parseDate.js";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "ProductImagesAndMedia",

  components: {
    "product-media-img": ProductMediaImg,
    "product-media-file-card": ProductMediaFileCard,
    "file-upload-box": FileUploadBox,
    "product-file-upload-modal": ProductFileUploadModal,
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [ParseDate, DisplaySetting],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * The current user logon ID
     *
     * This is required for requests to the media manager API
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * The logged in users ID Number
     *
     * This is required for requests to the media manager API
     */
    userIdNumber: {
      type: Number,
      required: true,
    },

    /**
     * List of product media
     */
    productMedia: {
      type: Array,
      default: () => [],
    },

    /**
     * The list of media types available for product media
     *
     * Use BooksonixProductHelper getResourceTypes21
     */
    mediaTypes: {
      type: Array,
      default: () => [],
    },

    /**
     * The list of media formats available for product media
     *
     * Use BooksonixProductHelper getResourceFormats21
     */
    mediaFormats: {
      type: Array,
      default: () => [],
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * The ID and Ref value of the share media modal
     */
    shareMediaModalId: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      droppedFiles: null,
      modalId: "productFileUploadmodal",
      fileList: this.productMedia,
    };
  },

  computed: {
    totalMediaSize: function () {
      let total = 0;
      if (this.fileList.length == 0) {
        return total;
      }
      for (let file of this.fileList) {
        total += file.getLocalresource ? file.getLocalresource.getSize : 0;
      }
      return (total / 1000).toFixed(0);
    },
    countTotalMediaFiles: function () {
      return this.fileList.length;
    },
    countMediaFilesWithSize: function () {
      let fileCount = 0;
      if (this.fileList.length == 0) {
        return fileCount;
      }
      for (let file of this.fileList) {
        if (file.getLocalresource && file.getLocalresource.getSize) {
          fileCount++;
        }
      }
      return fileCount;
    },
  },

  watch: {
    productMedia(value) {
      this.fileList = value;
    },
  },

  methods: {
    /**
     * Display the product file upload modal when a new set
     * of files is dropped into the file upload box
     */
    showFileUploadPopup: function (event) {
      if (event.dataTransfer.files) {
        this.droppedFiles = event.dataTransfer.files;
        this.$bvModal.show(this.modalId);
      }
    },

    /**
     * Re-draw the list of files when listening for when a
     * new file has been added
     */
    refreshFileList: function (fileList) {
      this.fileList = fileList;
    },

    /**
     * Converts filename media types to be URL safe
     */
    urlSafeFilename(url, linkType) {
      if (linkType == "06") {
        return encodeURI("/" + this.$appId + "/" + url);
      }
      return url;
    },

    /**
     * Checks if product media is of format
     * GIF, JPEG, PNG, TIFF or unknown
     *
     * Which will determine whether it displays as
     * an image
     */
    isImage: function (media) {
      if (!media.getFormat21) {
        if (
          media.getLink != null &&
          media.getLink.toLowerCase().match(/\.(jpeg|jpg|gif|png|tiff)$/) !=
            null
        ) {
          return true;
        }
        return false;
      }
      const imgTypes = ["02", "03", "05", "09"];
      if (imgTypes.includes(media.getFormat21)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.emptyBox {
  height: 80px;
  display: block;
}
</style>
