<template>
  <b-overlay
    :show="fileBeingDragged"
    rounded="sm"
    no-center
    style="height: 100%"
    ><template #overlay>
      <div
        style="height: 100%; width: 100%"
        class="d-flex"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
      >
        <div class="m-auto">{{ hoverText }}</div>
      </div>
    </template>
    <div
      style="height: 100%"
      v-bind="$attrs"
      :class="[
        {
          active: active,
          inactive: !active,
          highlightFileArea: fileBeingDragged && !active,
        },
        dragAreaClass,
      ]"
      @dragover.prevent="onDragOver"
      @dragleave.prevent="onDragLeave"
      @drop.prevent="handleDrop"
    >
      <slot
        ><div class="my-5">Drag and drop a file here to upload it</div></slot
      >
    </div>
  </b-overlay>
</template>

<script>
/**
 * Provides a drag and drop interface box for file
 * uploads.
 *
 * Use this component to 'surround' the content you wish
 * to add drag & drop to.
 *
 * This component does not handle file uploads, this should
 * be handled by the parent component by listening on the
 * 'drop' event
 */

export default {
  name: "FileUploadBox",

  props: {
    /**
     * Sets the text overlay describing the drop area
     */
    hoverText: {
      type: String,
      default: "Drag and drop a file here to upload it",
    },

    /**
     * Class to apply to the drag area inside the overlay
     */
    dragAreaClass: {
      type: String,
      default: null,
    },
  },

  data: () => {
    return {
      active: false,
      fileBeingDragged: false,
      inactiveTimeout: null,
      pageInactiveTimeout: null,
      pageDragCounter: 0,
    };
  },

  mounted: /* istanbul ignore next */ function () {
    if (this.$root.$refs.pageContainer) {
      this.$root.$refs.pageContainer.addEventListener(
        "dragenter",
        this.onPageDragOver
      );
      this.$root.$refs.pageContainer.addEventListener(
        "dragleave",
        this.onPageDragLeave
      );
    }
  },

  methods: {
    /**
     * Called when a drag action is over this component
     */
    onDragOver: function () {
      this.active = true;
      clearTimeout(this.inactiveTimeout);
      this.fileBeingDragged = false;
    },

    /**
     * Called when a drag action is begun on the web page
     */
    onPageDragOver: function () {
      if (!this.active) {
        this.fileBeingDragged = true;
        this.pageDragCounter += 1;
      }
    },

    /**
     * Called when a drag action leaves this component
     */
    onDragLeave: function () {
      this.inactiveTimeout = setTimeout(() => {
        this.active = false;
      }, 50);
    },

    /**
     * Called when a drag action stops on the web page
     */
    onPageDragLeave: function () {
      if (!this.active) {
        this.pageDragCounter -= 1;
      }
      if (this.pageDragCounter <= 0) {
        this.fileBeingDragged = false;
      }
    },

    /**
     * Called when a file is dropped onto this component
     */
    handleDrop: function (event) {
      this.inactiveTimeout = setTimeout(() => {
        this.active = false;
      }, 50);
      this.$emit("drop", event);
    },
  },
};
</script>

<style scoped>
.active {
  background-color: #dee2e6;
  border: 2px dashed rgb(31, 31, 31) !important;
}
/* .inactive {
  background-color: #f8f9fa;
  border: 2px solid #f8f9fa !important;
} */
.highlightFileArea {
  border: 2px solid #717171 !important;
  border-radius: 0.25rem;
}
</style>
