var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('well',[_c('h3',{staticClass:"font-weight-bold"},[_vm._v("FTP Credentials")]),(_vm.ftpCredentials !== null)?[_c('p',{staticClass:"mt-2"},[_vm._t("default",function(){return [_vm._v("The following login details can be used to access FTP")]})],2),_c('b-form-group',{attrs:{"label":"Username","label-for":"ftp-username"}},[_c('b-input-group',[_c('b-form-input',{ref:"ftp-username",attrs:{"id":"ftp-username","readonly":"","jest-ftp-username":""},model:{value:(_vm.ftpCredentials.id),callback:function ($$v) {_vm.$set(_vm.ftpCredentials, "id", $$v)},expression:"ftpCredentials.id"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.manual",value:('Copied'),expression:"'Copied'",modifiers:{"manual":true}}],attrs:{"id":"btn-copy-username","variant":"outline-primary"},on:{"click":function($event){return _vm.$copyToClipboard(
                'ftp-username',

                'btn-copy-username',
                _vm.ftpCredentials.id
              )}}},[_c('i',{staticClass:"fa-solid fa-copy"}),_vm._v(" Copy to Clipboard")])],1)],1)],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"ftp-password"}},[_c('b-input-group',[_c('b-form-input',{ref:"ftp-password",attrs:{"id":"ftp-password","type":_vm.showPassword ? 'text' : 'password',"readonly":"","jest-ftp-password":""},model:{value:(_vm.ftpCredentials.pw),callback:function ($$v) {_vm.$set(_vm.ftpCredentials, "pw", $$v)},expression:"ftpCredentials.pw"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"id":"btn-show-password","variant":"outline-primary"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[(_vm.showPassword)?[_vm._v("Hide")]:[_vm._v("Show")]],2),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.manual",value:('Copied'),expression:"'Copied'",modifiers:{"manual":true}}],attrs:{"id":"btn-copy-password","variant":"outline-primary"},on:{"click":function($event){return _vm.$copyToClipboard(
                'ftp-password',
                'btn-copy-password',
                _vm.ftpCredentials.pw
              )}}},[_c('i',{staticClass:"fa-solid fa-copy"}),_vm._v(" Copy to Clipboard")])],1)],1)],1)]:[_c('info-box',{staticClass:"mt-2"},[_vm._t("no-credentials-text",function(){return [_vm._v("No FTP credentials have been created yet.")]})],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }