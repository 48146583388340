var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"contacts_resultslist_line"},[_vm._l((_vm.columnData),function(column,columnNo){return [_c('td',{key:columnNo,class:typeof column == 'object' && 'imageType' in column
          ? 'contacts_resultslist_image'
          : _vm.columnInfo[columnNo].marker
          ? 'contacts_resultslist_mark'
          : null},[(_vm.columnInfo[columnNo].marker)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.recordMarked),expression:"recordMarked"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.recordMarked)?_vm._i(_vm.recordMarked,null)>-1:(_vm.recordMarked)},on:{"click":function($event){return _vm.$emit('markedChange', _vm.recordId, $event.target.checked)},"change":function($event){var $$a=_vm.recordMarked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.recordMarked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.recordMarked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.recordMarked=$$c}}}}):_vm._e(),(_vm.columnInfo[columnNo].dlink && _vm.recordId)?_c('a',{attrs:{"href":_vm.$baseUrl +
          'contacts/detailed/' +
          _vm.recordId +
          '/' +
          _vm.searchId +
          '/' +
          _vm.recordPos}},[(typeof column == 'object' && 'imageType' in column)?[(column.imageType == 'localImage')?_c('img',{staticClass:"contacts_resultslist_image",attrs:{"src":column.url + '?L=' + _vm.logonId + '&H50W50B',"onerror":"this.onerror=null;this.src='/common/images/noimage.png'"}}):_c('img',{staticClass:"contacts_resultslist_image",attrs:{"src":column.url,"onerror":"this.onerror=null;this.src='/common/images/noimage.png'"}})]:[_vm._v(" "+_vm._s(column)+" ")]],2):[(typeof column == 'object' && 'imageType' in column)?[(column.imageType == 'localImage')?_c('img',{staticClass:"contacts_resultslist_image",attrs:{"src":column.url + '?L=' + _vm.logonId + '&H50W50B',"onerror":"this.onerror=null;this.src='/common/images/noimage.png'"}}):_c('img',{staticClass:"contacts_resultslist_image",attrs:{"src":column.url,"onerror":"this.onerror=null;this.src='/common/images/noimage.png'"}})]:(_vm.columnInfo[columnNo].emailLinks)?[(column != '')?_c('a',{attrs:{"href":'mailto:' + column,"title":"Create an email for this contact's address(es)"}},[_c('img',{attrs:{"src":"/common/images/email.png","border":"0"}})]):_vm._e()]:(_vm.columnInfo[columnNo].notesIcon)?[(column == 'Y')?_c('img',{attrs:{"src":"/common/images/notes.png","border":"0","title":"Contact has notes"}}):_vm._e()]:[_vm._v(" "+_vm._s(column)+" ")]],(_vm.columnInfo[columnNo].tags)?_c('resultslist-tags',{attrs:{"tags":_vm.tags}}):_vm._e()],2)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }