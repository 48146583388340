<template>
  <transition name="fade">
    <div v-if="status" class="d-inline-block">
      <loading-spinner
        v-if="status == 'saving'"
        class="ml-2"
        :text="savingMessage"
        size="small"
        :inline="true"
      >
      </loading-spinner>
      <div v-else-if="status == 'saved'" class="d-inline-block">
        <i class="fa fa-check text-success"></i>
        <!-- @slot complete Slot for rendering the complete message -->
        <slot name="complete"> {{ completeMessage }}</slot>
      </div>
      <div v-else-if="status == 'error'" class="d-inline-block">
        <i class="fa fa-times text-danger"></i>
        <!-- @slot error Slot for rendering the complete message -->
        <slot name="error"> {{ errorMessage }}</slot>
      </div>
    </div>
  </transition>
</template>

<script>
/**
 * Displays a save status for forms to indicate when
 * a form has completed saving.
 *
 * The indicator will either display a saving indication
 * with a loading spinner while the form is saving.
 * Or a checkmark will be displayed with a complete message
 * once the save has been completed. This confirmation will
 * dissapear after a short time similar to a toast/flash
 * notification.
 *
 * The transition applies when showing/hiding the indicator but
 * not when switching between different indicators as this causes
 * the indicator to jump about because two indicators are visible
 * at the same time for a short period of time
 */
export default {
  name: "SaveIndicator",
  props: {
    /**
     * The current save status of the
     * form where this component is used
     *
     * @values saving,saved,error
     */
    status: {
      type: String,
      default: null,
    },

    /**
     * The message to display while saving
     * (When status = 'saving')
     */
    savingMessage: {
      type: String,
      default: "Saving...",
    },

    /**
     * The message to display once a save
     * has completed. (When status = 'saved')
     */
    completeMessage: {
      type: String,
      default: "Save successful",
    },

    /**
     * The message to display if a save
     * failed. (When status = 'error')
     */
    errorMessage: {
      type: String,
      default: "There were errors when trying to save",
    },
  },

  watch: {
    status(value) {
      if (value == "saved" || value == "error") {
        // close after 3 seconds
        setTimeout(this.clearStatus, 3000);
      }
    },
  },

  methods: {
    /**
     * Clears the save status
     */
    clearStatus() {
      /**
       * Status cleared event
       *
       * Parent component should set status prop to null
       * on this event, if you wish for 'saved' and 'error'
       * statuses to dissapear after a short time.
       */
      this.$emit("status-cleared");
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
