<template>
  <div class="card mb-3">
    <div class="card-header">
      <slot name="title">{{ title }}</slot>
    </div>
    <ul class="list-group list-group-flush">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
/**
 * Displays a list of items in a panel
 */

export default {
  name: "ListPanel",

  props: {
    /**
     * Title for the list panel
     */
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style></style>
