import Vue from "vue";

import ContactsDropdown from "./ContactsDropdown.vue";
import PreferencesModal from "./PreferencesModal.vue";
import UsersSelector from "./UsersSelector.vue";
import BooksonixTour from "./BooksonixTour.vue";
import ShowOnceModal from "./ShowOnceModal.vue";
import FileUploadBox from "./FileUploadBox.vue";

Vue.component("contacts-dropdown", ContactsDropdown);
Vue.component("preferences-modal", PreferencesModal);
Vue.component("users-selector", UsersSelector);
Vue.component("booksonix-tour", BooksonixTour);
Vue.component("show-once-modal", ShowOnceModal);
Vue.component("file-upload-box", FileUploadBox);
