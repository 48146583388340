<template>
  <div>
    <ui-breadcrumbs
      v-if="breadcrumbs.length > 0"
      :links="breadcrumbs"
    ></ui-breadcrumbs>
    <!-- @slot Slot used to display CakePHP Flash messages on this page -->
    <slot name="flash"></slot>
    <report :title="title" :description="description">
      <template v-slot:description>
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="description"></div>
        <!--eslint-enable-->
      </template>
      <base-table
        ref="reportTable"
        class="mt-4"
        :filters-enabled="true"
        :columns="columns"
        :data-source="$baseUrl + dataSource"
        :export-source="$baseUrl + dataSource + '?mode=download'"
        :filters-url="$baseUrl + 'api/report-filter/index/' + reportUrl"
        :filters-save-url="$baseUrl + 'api/report-filter/edit/' + reportUrl"
        :filters-delete-url="$baseUrl + 'api/report-filter/delete/' + reportUrl"
        :rows-per-page="15"
        :date-format="dateFormat"
        :dropdown-options="dropdownOptions"
      >
        <template v-slot:action-buttons>
          <!-- @slot Displays action buttons in the table -->
          <slot name="action-buttons"></slot>
        </template>
        <template v-slot:data-error>
          <p>An error has occurred while retrieving data for this report</p>
        </template>
      </base-table>
    </report>
  </div>
</template>

<script>
/**
 * Holds the application logic for a generic report page
 * Report information and data is passed in as props.
 */

import Report from "../../utility/Report/Report.vue";
import Breadcrumbs from "../../ui/Breadcrumbs.vue";

export default {
  name: "ReportPage",

  components: {
    report: Report,
    "ui-breadcrumbs": Breadcrumbs,
  },

  props: {
    /**
     * Format to be used for dates
     */
    dateFormat: {
      type: String,
      default: null,
    },
    /**
     * Title of the report
     */
    title: {
      type: String,
      default: null,
    },

    /**
     * Description of the report
     */
    description: {
      type: String,
      default: null,
    },

    /**
     * Breadcrumbs array for the breadcrumbs component
     *
     * See breadcrumb component for definition
     */
    breadcrumbs: {
      type: Array,
      default: () => [],
    },

    /**
     * Columns array for the table component
     *
     * See the table component for definition
     */
    columns: {
      type: Array,
      default: () => [],
    },

    /**
     * URL that returns data for the report
     */
    dataSource: {
      type: String,
      default: null,
    },

    /**
     * URL property of the report config
     */
    reportUrl: {
      type: String,
      default: null,
    },

    /**
     * Contains the option list for filters on dropdown columns
     * Only required when filters and backend pagination is enabled
     * Normally the dropdown options can be calculation automatically
     * but this is not possible with backend pagination, and therefore
     * they must be provided. Any dropdown columns without options provided
     * will instead work as a text column.
     *
     * Object keys should match the column key for each dropdown type column
     * and the value should be an array of options
     *
     * @example {type: ['A','B','C'], status: ['In Progress', 'Complete', 'Inactive']}
     */
    dropdownOptions: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style></style>
