<template>
  <popper
    trigger="clickToToggle"
    :options="{
      placement: 'bottom',
      modifiers: { offset: { offset: '0,10px' } },
    }"
  >
    <div class="popper">
      <h3 class="popover-header">Add a filter</h3>
      <div class="popover-body">
        <base-search-input id="filterSearch" v-model="search" class="mb-2" />
        <div class="list-group">
          <button
            v-for="(field, index) in filteredFields"
            :key="index"
            type="button"
            class="list-group-item"
            @click="fieldSelected(field)"
          >
            {{ field.text }}
            <span class="text-muted"> - {{ field.type | type }}</span>
          </button>
        </div>
      </div>
    </div>
    <base-button
      ref="btnAddFilter"
      slot="reference"
      variant="success"
      :disabled="buttonDisabled"
      ><i class="fa fa-plus" /> Add Filter</base-button
    >
  </popper>
</template>

<script>
/**
 * Provides a popup dialog for adding
 * a new filter field
 */

import Popper from "vue-popperjs";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "AddFilter",

  filters: {
    type: function (value) {
      switch (value) {
        case "number":
          return "Number";
        case "date":
          return "Date";
        case "dropdown":
          return "Dropdown List";
        default:
          return "Text";
      }
    },
  },

  components: {
    popper: Popper,
  },

  props: {
    /**
     * Fields array as defined in the DataFilters component
     */
    fields: {
      type: Array,
      default: () => [],
    },

    /**
     * Sets whether the button to open the popover should be disabled
     */
    disabled: Boolean,
  },

  data() {
    return {
      search: "",
      localFields: this.fields,
    };
  },

  computed: {
    filteredFields() {
      return this.localFields.filter((field) => {
        if (field.type != "none") {
          return field.text.toLowerCase().includes(this.search.toLowerCase());
        }
      });
    },

    buttonDisabled() {
      if (this.disabled || this.localFields.length == 0) {
        return true;
      }
      return false;
    },
  },

  methods: {
    fieldSelected(field) {
      /**
       * emit an event along with which field was chosen.
       * The field is cloned, otherwise the filters would be added as a
       * reference to this field object in the fields array (the template
       * field should not update when the field in the filters is changed)
       */
      this.$emit("field-selected", _cloneDeep(field));
      // close the popover
      this.$refs.btnAddFilter.$el.click();
    },
  },
};
</script>

<style scoped>
.popover-body {
  max-width: 250px;
}

.list-group {
  max-width: 250px;
  max-height: 250px;
  overflow-y: auto;
}
</style>
