<template>
  <b-overlay :show="hover && overlayDisabled == false" no-center>
    <template #overlay>
      <div
        id="product-media-img"
        v-b-popover.hover.top.html="tooltipContent"
        :disabled="!showTooltip"
        style="height: 100%; width: 100%"
        class="d-flex"
        @mouseleave.prevent="onMouseLeave"
      >
        <div v-if="!showTooltip" class="m-auto">
          <dl>
            <dt>
              <template v-if="resource.mediaType"
                >{{ resource.mediaType
                }}<template v-if="resource.internal"> (Internal)</template
                ><template v-if="resource.fileName">:</template></template
              >
            </dt>
            <dd>
              <template v-if="resource.fileName">{{
                resource.fileName
              }}</template>
            </dd>

            <template v-if="resource.fileSize">
              <dt>File Size:</dt>
              <dd>{{ roundedFileSize }} KB</dd>
            </template>
            <template v-if="resource.lastModified">
              <dt>Last Modified:</dt>
              <dd>
                {{
                  resource.lastModified
                    | parseDate(dateOptions.format, dateOptions)
                }}
              </dd>
            </template>
            <template v-if="resource.caption">
              <dt>Caption:</dt>
              <dd>{{ resource.caption }}</dd>
            </template>
            <template v-if="resource.credit">
              <dt>Credit:</dt>
              <dd>{{ resource.credit }}</dd>
            </template>
            <template v-if="resource.copyrightHolder">
              <dt>Copyright Holder:</dt>
              <dd>{{ resource.copyrightHolder }}</dd>
            </template>
            <template v-if="resource.alternateText">
              <dt>Alternate Text:</dt>
              <dd>{{ resource.alternateText }}</dd>
            </template>
          </dl>
        </div>
        <template v-if="!imgError">
          <b-button
            v-if="shareMediaModalId && !externalImage"
            class="email-icon-btn m-2"
            size="sm"
            variant="primary"
            @click="shareMedia"
          >
            <i class="fa fa-envelope"></i>
          </b-button>
          <b-button
            v-if="logonId && !externalImage"
            class="save-icon-btn m-2"
            size="sm"
            variant="primary"
            :href="imgUrl + '?L=' + logonId + '&D'"
            target="_blank"
            title="Click here to download this image"
          >
            <i class="fa-solid fa-save"></i>
          </b-button>
          <b-button
            v-if="logonId"
            class="open-icon-btn m-2"
            size="sm"
            variant="primary"
            :href="imgUrlWithParameters"
            target="_blank"
            title="Click here to view this image"
          >
            <i class="fa-solid fa-up-right-from-square"></i>
          </b-button>
        </template>
      </div>
    </template>
    <b-img
      :src="
        !imgError && imgUrlWithParameters
          ? imgUrlWithParameters
          : '/common/images/noimage.png'
      "
      v-bind="$attrs"
      fluid
      :alt="alt"
      :center="imgError"
      @error="imgError = true"
      @mouseover="onMouseOver"
    ></b-img>
    <b-badge
      v-if="resource.internal"
      class="ml-2 mb-2 img-internal-badge"
      variant="secondary"
      >Internal</b-badge
    >
  </b-overlay>
</template>

<script>
/**
 * Displays a responsive image of a product
 * media image. The img will scale to fit the
 * parent container but will be no larger than the
 * image size to prevent blurring.
 */

import { HTTP } from "../../http-common.js";
import ParseDate from "../../mixins/parseDate.js";

export default {
  name: "ProductMediaImg",

  mixins: [ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * The logon (session) ID of the current user
     */
    logonId: {
      type: String,
      default: null,
    },

    /**
     * The product media type to display
     * as defined in the MediaLinksComponent
     */
    type: {
      type: String,
      default: "IMG_FRONT",
    },

    /**
     * Options to be passed as a query paramter
     * to be used in the MediaLinksComponent->getProductMedia method
     *
     * Available options are defined in this method
     */
    options: {
      type: Object,
      default: null,
    },

    /**
     * The URL of the media img to display
     */
    url: {
      type: String,
      default: null,
    },

    /**
     * The Alt text to display for the image
     */
    alt: {
      type: String,
      default: "Product Media Image",
    },

    /**
     * The product resource media type
     *
     * Leave blank if only passing in the productId
     */
    mediaType: {
      type: String,
      default: null,
    },

    /**
     * The product resource file size
     *
     * Leave blank if only passing in the productId
     */
    fileSize: {
      type: [String, Number],
      default: null,
    },

    /**
     * The product resource last modified date string
     *
     * Format yyyy-mm-dd
     *
     * Leave blank if only passing in the productId
     */
    lastModified: {
      type: String,
      default: null,
    },

    /**
     * The product resource file name
     *
     * Leave blank if only passing in the productId
     */
    fileName: {
      type: String,
      default: null,
    },

    /**
     * Disables the overlay on hover
     * which displays detailed information
     * about the image.
     */
    disableOverlay: {
      type: Boolean,
      default: false,
    },

    /**
     * Media object of product getProductMedia
     */
    mediaObject: {
      type: Object,
      default: null,
    },

    /**
     * The ID and Ref value of the share media modal
     */
    shareMediaModalId: {
      type: String,
      default: null,
    },

    /**
     * Set to true to display overlay info in a tooltip
     * instead. Useful when displaying a small image which
     * can't fit the contents of the overlay.
     */
    showTooltip: {
      type: Boolean,
      default: false,
    },

    /**
     * Limits the image size based on height
     * Note this is the actual size of the loaded image
     * not the display height
     */
    imgHeight: {
      type: Number,
      default: null,
    },

    /**
     * Limits the image size based on width
     * Note this is the actual size of the loaded image
     * not the display width
     */
    imgWidth: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      imgUrl: this.url,
      hover: false,
      inactiveTimeout: null,
      resource: {
        mediaType: this.mediaType,
        fileSize: this.fileSize,
        lastModified: this.lastModified,
        fileName: this.fileName,
        caption: this.mediaObject ? this.mediaObject.getCaption : null,
        credit: this.mediaObject ? this.mediaObject.getCredit : null,
        copyrightHolder: this.mediaObject
          ? this.mediaObject.getCopyrightHolder
          : null,
        alternateText: this.mediaObject
          ? this.mediaObject.getAlternateText
          : null,
        internal: this.mediaObject ? this.mediaObject.getInternal : null,
      },
      tableId: null,
      format21: null,
      overlayDisabled: this.disableOverlay,
      randId: (Math.random() + 1).toString(36).substring(7),
      imgError: false,
    };
  },

  computed: {
    externalImage: function () {
      if (this.mediaObject && this.mediaObject.getLinkType != "06") {
        return true;
      } else if (this.mediaObject) {
        return false;
      }
      if (!this.resource.fileName) {
        return true;
      }
      return false;
    },
    imgUrlWithParameters: function () {
      if (!this.imgUrl) {
        return null;
      }
      if (this.imgUrl == "/common/images/noimage.png") {
        return this.imgUrl;
      }
      // parameters only get applied to files
      if (this.externalImage) {
        return this.imgUrl;
      }
      let parameters = [];
      if (this.logonId) {
        parameters.push({
          key: "L",
          value: this.logonId,
        });
      }
      if (this.imgHeight) {
        parameters.push({
          key: "H" + this.imgHeight,
          value: null,
        });
      } else if (this.imgWidth) {
        parameters.push({
          key: "W" + this.imgWidth,
          value: null,
        });
      }
      let url = this.imgUrl;
      parameters.forEach((param, index) => {
        url += index == 0 ? "?" : "&";
        url += param.key;
        if (param.value) {
          url += "=" + param.value;
        }
      });
      return url;
    },
    roundedFileSize: function () {
      if (!this.resource.fileSize) {
        return null;
      }
      return (this.resource.fileSize / 1000).toFixed(0);
    },
    tooltipId: function () {
      return "product-media-img" + this.randId;
    },
    tooltipContent: function () {
      let content = "";
      if (this.resource.fileSize) {
        content += "<b>File Size:</b> " + this.roundedFileSize + " KB<br>";
      }
      if (this.resource.lastModified) {
        content +=
          "<b>Last Modified:</b> " +
          this.$options.filters.parseDate(
            this.resource.lastModified,
            this.$userPreferences.dateFormat,
            this.dateOptions
          ) +
          "<br>";
      }
      if (this.resource.caption) {
        content += "<b>Caption:</b> " + this.resource.caption + "<br>";
      }
      if (this.resource.credit) {
        content += "<b>Credit:</b> " + this.resource.credit + "<br>";
      }
      if (this.resource.copyrightHolder) {
        content +=
          "<b>Copyright Holder:</b> " + this.resource.copyrightHolder + "<br>";
      }
      if (this.resource.alternateText) {
        content +=
          "<b>Alternate Text:</b> " + this.resource.alternateText + "<br>";
      }
      let title = "";
      if (this.resource.mediaType) {
        title += this.resource.mediaType;
        if (this.resource.internal) {
          title += " (Internal)";
        }
      }
      if (this.resource.fileName) {
        if (this.resource.mediaType) {
          title += ": ";
        }
        title += this.resource.fileName;
      }
      return {
        title: title,
        content: content,
      };
    },
  },

  watch: {
    disableOverlay: function (value) {
      this.overlayDisabled = value;
    },
  },

  mounted: function () {
    if (this.mediaObject) {
      this.tableId = this.mediaObject.getTableId;
      this.format21 = this.mediaObject.getFormat21;
    }
    if (!this.imgUrl) {
      this.getProductMedia()
        .then((response) => {
          if (!response.data || response.data.length == 0) {
            this.imgUrl = "/common/images/noimage.png";
            this.overlayDisabled = true;
            return;
          }
          for (let item in response.data) {
            if (response.data[item]["type"] == this.type) {
              this.imgUrl = response.data[item]["url"];
              this.tableId = response.data[item]["getTableId"];
              this.format21 = response.data[item]["getFormat21"];
              this.resource.mediaType = response.data[item]["getType21Name"];
              this.resource.fileSize =
                response.data[item]["getLocalresource"]["getSize"];
              this.resource.lastModified =
                response.data[item]["getLocalresource"]["getLastModified"];
              this.resource.fileName =
                response.data[item]["getLocalresource"]["getFilename"];
              this.resource.caption = response.data[item]["getCaption"];
              this.resource.credit = response.data[item]["getCredit"];
              this.resource.copyrightHolder =
                response.data[item]["getCopyrightHolder"];
              this.resource.alternateText =
                response.data[item]["getAlternateText"];
              break;
            } else {
              this.imgUrl = "/common/images/noimage.png";
            }
          }
        })
        .catch(() => {});
    }
  },

  methods: {
    /**
     * Retrieves the media link information
     * for the product
     */
    getProductMedia() {
      if (!this.productId) {
        return Promise.reject("Product ID is null");
      }
      return HTTP.get(this.$baseUrl + "api/media-links/" + this.productId, {
        params: {
          options: this.options,
        },
      });
    },

    /**
     * Called when a user starts hovering over the component
     */
    onMouseOver() {
      this.hover = true;
      clearTimeout(this.inactiveTimeout);
    },

    /**
     * Called when a user stops hovering over the component
     */
    onMouseLeave: function () {
      this.inactiveTimeout = setTimeout(() => {
        this.hover = false;
      }, 50);
    },

    shareMedia: function () {
      if (!this.shareMediaModalId) return false;
      if (!this.$root.$refs[this.shareMediaModalId]) return false;
      this.$root.$refs[this.shareMediaModalId].displayShareMediaModal(
        this.format21,
        this.$appId + "/" + this.resource.fileName
      );
    },
  },
};
</script>

<style scoped>
img {
  max-height: 100%;
  max-width: 100%;
}
.email-icon-btn {
  position: absolute;
  bottom: 0;
  right: 68px;
}
.save-icon-btn {
  position: absolute;
  bottom: 0;
  right: 34px;
}
.open-icon-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}
.img-internal-badge {
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
