<template>
  <b-card id="productCardMarketRepresentation">
    <template #header>
      <a id="market-representation" />
      <h5 class="d-inline-block">Market Representation</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-market/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <dl class="row">
      <template
        v-if="marketRepresentatives && marketRepresentatives.length > 0"
      >
        <template v-for="(representative, index) in marketRepresentatives">
          <dt
            :key="'representative-dt-' + index"
            class="col-sm-3 mt-2 font-weight-bold text-secondary"
          >
            Market Representation
          </dt>
          <dd :key="'representative-dd-' + index" class="col-sm-9 mt-2"></dd>
          <dt
            :key="'representative-dt2-' + index"
            class="col-sm-3 pl-5 font-weight-bold text-secondary"
          >
            Countries
          </dt>
          <dd :key="'representative-dd2-' + index" class="col-sm-9">
            <template
              v-if="
                !representative.getRightsCountries &&
                !representative.getNoRightsCountries
              "
              >Worldwide</template
            >
            <template v-if="representative.getRightsCountries"
              ><span
                v-for="(
                  country, countryIndex
                ) in representative.getRightsCountriesNames"
                :key="countryIndex"
                >{{ country
                }}<template
                  v-if="
                    countryIndex < representative.getRightsCountries.length - 1
                  "
                  >,
                </template></span
              ></template
            >
            <template v-if="representative.getNoRightsCountries"
              >Worldwide excluding
              <span
                v-for="(
                  country, countryIndex
                ) in representative.getNoRightsCountriesNames"
                :key="countryIndex"
                >{{ country
                }}<template
                  v-if="
                    countryIndex <
                    representative.getNoRightsCountriesNames.length - 1
                  "
                  >,
                </template></span
              ></template
            >
          </dd>
          <template v-if="representative.getName">
            <dt
              :key="'representative-dt3-' + index"
              class="col-sm-3 pl-5 font-weight-bold text-secondary"
            >
              {{
                representative.getTypeName
                  ? representative.getTypeName
                  : "Sales Agent/Local Publisher Name"
              }}
            </dt>
            <dd :key="'representative-dd3-' + index" class="col-sm-9">
              {{ representative.getName }}
            </dd>
          </template>
          <template v-if="representative.getStatusName">
            <dt
              :key="'representative-dt4-' + index"
              class="col-sm-3 pl-5 font-weight-bold text-secondary"
            >
              Local Publishing Status
            </dt>
            <dd :key="'representative-dd4-' + index" class="col-sm-9">
              {{ representative.getStatusName }}
            </dd>
          </template>
          <template v-if="representative.getPublicationDate">
            <dt
              :key="'representative-dt5-' + index"
              class="col-sm-3 pl-5 font-weight-bold text-secondary"
            >
              Local Publication Date
            </dt>
            <dd :key="'representative-dd5-' + index" class="col-sm-9">
              {{
                representative.getPublicationDate
                  | parseDate(dateOptions.format, dateOptions)
              }}
            </dd>
          </template>
        </template>
      </template>
      <template v-else-if="showAllFields">
        <dt class="col-sm-3 mt-2 font-weight-bold text-secondary">
          Market Representation
        </dt>
        <dd class="col-sm-9">—</dd>
      </template>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list
 * of product Market Representation
 */
import ParseDate from "../../mixins/parseDate.js";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "MarketRepresentation",

  components: {
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [ParseDate, DisplaySetting],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * Array containing a list of market
     * representatives. As returned by
     * BooksonixProduct::getMarketRepresentatives::get()
     */
    marketRepresentatives: {
      type: Array,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
