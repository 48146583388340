export default {
  methods: {
    /**
     * Copies the input to clipboard, and displays a confirmation
     *
     * @param {string} el The ID of input element which contains the text to be copied
     * @param {string} btnEl The ID of the button element which triggers the copy
     * @param {string} text The text to be copied to the clipboard
     */
    async $copyToClipboard(el, btnEl, text) {
      this.$refs[el].select();
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        // breifly show a 'copied' tooltip
        this.$root.$emit("bv::show::tooltip", btnEl);
        setTimeout(() => {
          this.$root.$emit("bv::hide::tooltip", btnEl);
        }, 1500);
      }
    },
  },
};
