<template>
  <b-card id="contactCardNotes">
    <template #header>
      <a id="notes" />
      <h5 class="d-inline-block">Notes</h5>
      <edit-icon
        v-if="$permitted('contacts/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/contacts/edit/' +
          contactId +
          '?current_tab=4' +
          (returnTo ? '&return=' + returnTo : '')
        "
      ></edit-icon>
      <contact-quick-jump-dropdown
        class="float-right m-0"
        :contact-id="contactId"
      ></contact-quick-jump-dropdown>
    </template>
    <dl v-if="contact" class="row">
      <template v-for="(note, index) in contact.Notes">
        <dt
          :key="'dt-note' + index"
          class="col-sm-3 font-weight-bold text-secondary"
        >
          Note
        </dt>
        <dd :key="'dd-note' + index" class="col-sm-9">
          <!-- prettier-ignore -->
          <div class="preserve-white-space">{{ note.Note }}</div>
          <div class="pl-4 font-italic">
            Last Updated On
            {{
              note.UpdatedOn
                | parseDate($userPreferences.dateFormat, dateOptions)
            }}
            by {{ note.UpdatedByUser }}
          </div>
        </dd>
      </template>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list of contact data
 */
import ContactQuickJumpDropdown from "./ContactQuickJumpDropdown.vue";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ParseDate from "../../mixins/parseDate.js";

export default {
  name: "ContactDescriptiveListNotes",

  components: {
    "contact-quick-jump-dropdown": ContactQuickJumpDropdown,
  },

  mixins: [DisplaySetting, ParseDate],

  props: {
    /**
     * The ID of the contact
     */
    contactId: {
      type: Number,
      default: null,
    },

    /**
     * Contact object
     */
    contact: {
      type: Object,
      default: () => {},
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Enable or disable edit links
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },
  },

  computed: {},
};
</script>

<style></style>
