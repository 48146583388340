var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bibliorelatedproductsedit"},[_c('div',{staticClass:"bibliorelatedproductsedit-add"},[_c('v-select',{attrs:{"label":"name","options":_vm.typesList,"clearable":false,"placeholder":"Select a related product type"},on:{"input":_vm.selectedType},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?_c('em',[_vm._v("No related product type found")]):_c('em',[_vm._v("Start typing to search for a related product type")])]}}]),model:{value:(_vm.typeVSelectOption),callback:function ($$v) {_vm.typeVSelectOption=$$v},expression:"typeVSelectOption"}})],1),_c('div',{staticClass:"bibliorelatedproductsedit-item-container"},[_vm._l((_vm.typesList),function(type,typeIndex){return [(type.products)?_c('div',{key:typeIndex,staticClass:"row bibliorelatedproductsedit-item"},[_c('div',{staticClass:"col-12"},[_c('hr'),_c('div',[_vm._v(_vm._s(type.name)+" Related Products")]),_c('biblio-identifiers-edit',{attrs:{"component-id":'rp' + typeIndex,"identifiers":type.products,"types":[
              {
                code: 'getGTIN13ISBN13',
                name: 'GTIN-13 & ISBN-13',
                lookup: '0315',
                pattern: '(978[0-9]{10}|979[1-9][0-9]{9})',
              },
              {
                code: 'getGTIN13',
                name: 'GTIN-13',
                lookup: '03',
                pattern: '[0-9]{13}',
              },
              {
                code: 'getISBN13',
                name: 'ISBN-13',
                lookup: '15',
                pattern: '(978[0-9]{10}|979[1-9][0-9]{9})',
              },
              {
                code: 'getISBN10',
                name: 'ISBN-10',
                lookup: '02',
                pattern: '[0-9]{9}[0-9Xx]',
              },
              {
                code: 'getUPC',
                name: 'UPC',
                lookup: '04',
                pattern: '[0-9]{12}',
              },
              {
                code: 'getLCCN',
                name: 'LCCN',
                lookup: '13',
                pattern: null,
              },
              {
                code: 'getDOI',
                name: 'DOI',
                lookup: '06',
                pattern: '10\.[0-9]{4,9}/[-._;()/:A-Za-z0-9]+',
              },
              {
                code: 'getASIN',
                name: 'ASIN',
                lookup: '01ASIN',
                pattern: '[0-9A-Z]{10}',
              } ],"multiple":true,"extra-identifier-properties":{ getRelatedType: type.code },"lookup-url":_vm.$baseUrl + 'biblio-edit/find-products/@T?q=@S'},on:{"changed":function (identifiers) {
                _vm.changedIdentifiers(typeIndex, identifiers);
              }}})],1)]):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }