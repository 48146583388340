/**
 * Mixin to provide easy access to Date formatting
 *
 * Using the parseDate method will format a date string
 * from format YYYY-MM-DD HH:mm:ss to the users chosen
 * date format
 *
 * Useage:
 * parseDate is a filter so it can be used when binding
 * date strings to HTML elements, for example in a table:
 * <tr>
 *  <td>{{ date | parseDate($userPreferences.dateFormat) }}</td>
 * </tr>
 *
 * A date format can be passed to the method to overwrite
 * the default user setting. The global userPreferences
 * dateFormat must be passed in as it is inaccessible from
 * this mixin. The format determines the ORDER of year, month and day
 * only. Which by default will be converted to numeric values seperated
 * by slashes, options can be passed in the change this format.
 *
 * Options are as defined as:
 * {
 *  day: "dd",
 *  month: "MM",
 *  year: "yyyy",
 *  separator:"/"
 * }
 *
 * If instead you wish to override the formatting and format
 * using the options in .toFormat of the DateTime class then
 * use the override option.
 *
 * Available tokens for options: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 *
 * @author George Betts
 */

import { DateTime } from "luxon";

var ParseDate = {
  filters: {
    parseDate(value, format, options, override = false) {
      if (!value || !format) return "";
      if (!options) {
        options = { day: "dd", month: "MM", year: "yyyy", separator: "/" };
      }
      // check if the value string matches SQL date format
      let dateVal = DateTime.fromISO(value);
      if (!dateVal || dateVal.invalid) {
        return value;
      }
      // build format string
      let dateFormat = format;
      if (override !== true) {
        dateFormat = buildFormatString(format, options);
      }
      return dateVal.toFormat(dateFormat);
    },
  },
  props: {
    /**
     * Date options for the parseDate filter
     */
    dateOptions: {
      type: Object,
      default: function () {
        return {
          day: "dd",
          month: "MM",
          year: "yyyy",
          separator: "/",
          format: "dmy",
        };
      },
    },
  },
  computed: {
    userDateFormat: function () {
      let dateFormat = this.$userPreferences.dateFormat;
      return dateFormat;
    },
  },
  methods: {
    getDateFormatFromUserFormat(format, options) {
      if (!options) {
        options = { day: "dd", month: "MM", year: "yyyy", separator: "/" };
      }
      // build format string
      let dateFormat = buildFormatString(format, options);
      return dateFormat;
    },
    /**
     * Reactive version of parseDate filter
     */
    parseDate(value, format, options, override = false) {
      if (!value || !format) return "";
      if (!options) {
        options = { day: "dd", month: "MM", year: "yyyy", separator: "/" };
      }
      // check if the value string matches SQL date format
      let dateVal = DateTime.fromISO(value);
      if (!dateVal || dateVal.invalid) {
        return value;
      }
      // build format string
      let dateFormat = format;
      if (override !== true) {
        dateFormat = buildFormatString(format, options);
      }
      return dateVal.toFormat(dateFormat);
    },
  },
};

export default ParseDate;

function buildFormatString(format, options) {
  let dateFormat = "";
  switch (format) {
    case "mdy":
      dateFormat = options.month + options.separator + options.day;
      if (options.month == "MMMM" && options.separator == " ") {
        dateFormat += ",";
      }
      dateFormat += options.separator + options.year;
      break;
    case "ymd":
      dateFormat =
        options.year +
        options.separator +
        options.month +
        options.separator +
        options.day;
      break;
    default:
      dateFormat =
        options.day +
        options.separator +
        options.month +
        options.separator +
        options.year;
      break;
  }
  return dateFormat;
}
