<template>
  <b-card id="productCardFullText">
    <template #header>
      <a id="full-text" />
      <h5 class="d-inline-block">Full Text</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-fulltext/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <dl v-if="descriptions.length > 0" class="row">
      <template v-for="(description, index) in descriptions">
        <dt
          :key="'description-dt-' + index"
          class="col-sm-11 font-weight-bold text-secondary"
        >
          Full Text<template v-if="description.getInternal">
            (Internal)</template
          >
          – {{ description.getTypeName }} ({{
            description.getFormat == "02" ? "HTML" : "Text"
          }})
          <template v-if="description.getLanguage">
            – {{ description.getLanguageName }}
            <template v-if="description.getLanguageCountry">
              ({{ description.getLanguageCountryName }})
            </template>
          </template>
        </dt>
        <dd :key="'description-dd2-' + index" class="col-sm-1"></dd>
        <dt :key="'description-dt2-' + index" class="col-sm-1"></dt>
        <dd :key="'description-dd-' + index" class="col-sm-11">
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="description.getFormat == '02'"
            v-html="description.getText"
          ></div>
          <!--eslint-enable-->
          <!-- prettier-ignore -->
          <div v-else class="preserve-white-space">{{ description.getText }}</div>
          <div
            v-if="
              description.getAuthor ||
              description.getCorporateBody ||
              description.getSourceDescription ||
              description.getTitleOfSource ||
              description.getPublicationDate
            "
            class="font-italic text-right"
          >
            <template v-if="description.getAuthor">
              {{ description.getAuthor }},
            </template>
            <template v-if="description.getCorporateBody">
              {{ description.getCorporateBody }},
            </template>
            <template v-if="description.getSourceDescription">
              {{ description.getSourceDescription }},
            </template>
            <template v-if="description.getTitleOfSource">
              {{ description.getTitleOfSource }},
            </template>
            <template v-if="description.getPublicationDate">
              {{
                description.getPublicationDate
                  | parseDate(dateOptions.format, dateOptions)
              }}
            </template>
          </div>
        </dd>
      </template>
    </dl>
    <dl v-else-if="showAllFields" class="row">
      <dt class="col-sm-2 font-weight-bold text-secondary">Full Text</dt>
      <dd class="col-sm-10">—</dd>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list
 * of full text product descriptions
 */

import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ParseDate from "../../mixins/parseDate.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "FullTextDescriptions",

  components: {
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [DisplaySetting, ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },
    /**
     * List of descriptions
     *
     * Dump output from BooksonixProduct::getDescriptions()
     */
    descriptions: {
      type: Array,
      default: () => [],
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
