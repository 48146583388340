/**
 * Mixin to provide easy access to URL parsing methods
 *
 * @author George Betts
 */

var ParseUrl = {
  methods: {
    /**
     * Parses a website URL by identify whether http:// needs to
     * be prepended
     *
     * @param {string} url The URL to be parsed
     *
     * @returns {string}
     */
    parseWebsiteUrl(url) {
      return url.includes(":") ? url : "http://" + url;
    },
  },
};

export default ParseUrl;
