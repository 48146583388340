<template>
  <div
    id="searchformsavemodal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 ref="title" class="modal-title">Save Search Form</h4>
          <div class="close">
            <a
              :href="helpBaseUrl + '/help/search_save'"
              target="help"
              class="searchformsave_helpbtn"
              title="Help"
            >
              <i class="fa fa-question-circle"></i>
            </a>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="modal-body-inner">
            <div class="row searchformsave_header">
              <div class="col-5"><span>Name</span></div>
              <div class="col-7"><span>Description</span></div>
            </div>
            <div class="row searchformsave_newname">
              <div class="col-5">
                <input ref="nameCtrl" type="text" value="" />
              </div>
              <div class="col-7">
                <input ref="descrCtrl" type="text" value="" />
              </div>
            </div>
            <div class="row searchformsave_type">
              <div class="col-5">
                <span>Please select where this search should be saved:</span>
              </div>
              <div class="col-7">
                <input
                  id="saveTypeUser"
                  ref="typeUserCtrl"
                  name="type"
                  form="savesearchform"
                  type="radio"
                  value="user"
                />
                <label for="saveTypeUser">My Searches</label>
                <input
                  id="saveTypeShared"
                  ref="typeSharedCtrl"
                  name="type"
                  form="savesearchform"
                  type="radio"
                  value="shared"
                />
                <label for="saveTypeShared">Shared Searches</label>
              </div>
            </div>
            <div ref="savedSearchFormsList"></div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success"
            @click="saveSearchForm(false)"
          >
            OK
          </button>
          <button type="button" class="btn btn-danger" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Save search form modal
 */
import { HTTP } from "../../http-common.js";

export default {
  name: "SearchFormSaveModal",

  props: {
    /**
     * The search form name
     */
    formName: {
      type: String,
      required: true,
    },

    /**
     * The help base url
     */
    helpBaseUrl: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      htmlForm: null,
      savedSearchForms: null,
    };
  },

  methods: {
    /**
     * Show the save search form modal
     */
    showModal(htmlForm) {
      this.htmlForm = htmlForm;

      // reset the form fields
      this.$refs.nameCtrl.value = "";
      this.$refs.descrCtrl.value = "";
      this.$refs.typeUserCtrl.checked = true;

      // get the saved search forms
      HTTP.get(this.$baseUrl + "search/get-saved-search-forms").then(
        (response) => {
          this.displaySavedSearchForms(response.data);
        }
      );
    },

    /**
     * Display the saved search forms
     */
    displaySavedSearchForms(savedSearchForms) {
      this.savedSearchForms = savedSearchForms;

      // build the html
      let rowHtml =
        '<div class="row"><div class="col-5">' +
        '<i class="fa fa-fw %IC" title="%IT"></i>' +
        '<a class="btn btn-link savedsearchform_link" type="button" data-name="%N" data-descr="%D" data-type="%T">%N</button>' +
        '</div><div class="col-6">%D</div>' +
        '<div class="col-1">' +
        '<a href="#" class="%C" title="Rename" data-name="%N" data-descr="%D" data-type="%T"><i class="fa fa-edit"></i></a>&nbsp;' +
        '<a href="#" title="Delete" data-name="%N" data-type="%T" class="savedsearch_delete%C"><i class="fa fa-trash"></i></a>' +
        "</div></div>";
      let html = "";
      let savedSearches = [
        { type: "user", header: "My", list: savedSearchForms.user },
        { type: "shared", header: "Shared", list: savedSearchForms.shared },
      ];
      let classIcons = {
        product: "fa-book",
        contact: "fa-users",
      };
      let classTips = {
        product: "Bibliographic saved search",
        contact: "Contact saved search",
      };
      for (let i = 0; i < 2; i++) {
        if (savedSearches[i].list.length != 0) {
          html +=
            '<div class="row"><div class="col-12"><b>' +
            savedSearches[i].header +
            " Searches</b></div></div>";
          for (let j = 0; j < savedSearches[i].list.length; j++) {
            html += rowHtml
              .replace(/%N/g, this.escapeHtml(savedSearches[i].list[j].name))
              .replace(/%D/g, this.escapeHtml(savedSearches[i].list[j].descr))
              .replace(/%T/g, savedSearches[i].type)
              .replace(/%IC/g, classIcons[savedSearches[i].list[j].class] ?? "")
              .replace(/%IT/g, classTips[savedSearches[i].list[j].class] ?? "")
              .replace(
                /%C/g,
                savedSearches[i].list[j].mine ? "" : " btn-link disabled"
              );
          } // for
        } // for
      }
      this.$refs.savedSearchFormsList.innerHTML = html;

      // add click handlers to the buttons
      let buttons =
        this.$refs.savedSearchFormsList.getElementsByTagName("button");
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].addEventListener("click", this.selectName);
      } // for

      // add click handlers to the links
      let links = this.$refs.savedSearchFormsList.getElementsByTagName("a");
      for (let i = 0; i < links.length; i++) {
        switch (links[i].className) {
          case "":
            links[i].addEventListener("click", this.renameSavedSearch);
            break;
          case "savedsearch_delete":
            links[i].addEventListener("click", this.deleteSavedSearch);
            break;
        }
      } // for

      // show the modal
      window.$("#searchformsavemodal").modal("show");
    },

    /**
     * Select the name from a saved search format in the name and description edit boxes
     */
    selectName(event) {
      event.preventDefault();
      this.$refs.nameCtrl.value = event.target.dataset.name;
      this.$refs.descrCtrl.value = event.target.dataset.descr;
      if (event.target.dataset.type == "shared") {
        this.$refs.typeSharedCtrl.checked = true;
      } else {
        this.$refs.typeUserCtrl.checked = true;
      }
    },

    /**
     * Save the search form
     */
    saveSearchForm(overwrite) {
      // check we have a name
      if (this.$refs.nameCtrl.value.search(/\S/) == -1) {
        alert("You must enter a name");
        return;
      }

      // build POST data
      let postData = new URLSearchParams();
      postData.append("name", this.$refs.nameCtrl.value);
      postData.append("descr", this.$refs.descrCtrl.value);
      postData.append(
        "type",
        this.$refs.typeSharedCtrl.checked ? "shared" : "user"
      );
      if (overwrite) {
        postData.append("overwrite", "Y");
      }
      for (let i = 0; i < this.htmlForm.length; i++) {
        let elem = this.htmlForm[i];
        if (elem.name != "") {
          switch (elem.type) {
            case "button":
            case "submit":
              break;
            case "select-one":
              if (elem.selectedIndex >= 0) {
                postData.append(
                  elem.name,
                  elem.options[elem.selectedIndex].value
                );
              }
              break;
            case "checkbox":
            case "radio":
              if (elem.checked) {
                postData.append(elem.name, elem.value);
              }
              break;
            default:
              postData.append(elem.name, elem.value);
              break;
          } // switch
        }
      } // for

      // POST the request to the server
      let url = this.$baseUrl + "search/save-search-form/" + this.formName;
      HTTP.post(url, postData)
        .then(() => {
          this.hideModal();
        })
        .catch((error) => {
          if (
            error.response.status == 409 &&
            error.response.data == "CANNOT_OVERWRITE"
          ) {
            alert(
              "You cannot overwrite this shared saved search as you did not create it"
            );
          } else if (
            !overwrite &&
            error.response.status == 409 &&
            error.response.data == "CHECK_OVERWRITE"
          ) {
            if (
              window.confirm(
                "A saved search with this name already exists, are you sure you want to overwrite it?"
              )
            ) {
              this.saveSearchForm(true);
            }
          } else {
            alert("An error occurred while saving your search");
          }
        });
    },

    /**
     * Rename a saved saerch
     */
    renameSavedSearch(event) {
      event.preventDefault();
      this.hideModal();
      let source = event.target;
      if (source.tagName == "I") {
        source = source.parentElement;
      }
      this.$emit(
        "rename-saved-search",
        this.htmlForm,
        source.dataset.name,
        source.dataset.descr,
        source.dataset.type
      );
    },

    /**
     * Delete a saved search
     */
    deleteSavedSearch(event) {
      event.preventDefault();
      if (
        window.confirm("Are you sure you want to delete this saved search ?")
      ) {
        let source = event.target;
        if (source.tagName == "I") {
          source = source.parentElement;
        }
        let postData = new URLSearchParams();
        postData.append("name", source.dataset.name);
        postData.append("type", source.dataset.type);

        HTTP.post(this.$baseUrl + "search/delete-saved-search-form", postData)
          .then(() => {
            // get the saved search form
            HTTP.get(this.$baseUrl + "search/get-saved-search-forms").then(
              (response) => {
                this.displaySavedSearchForms(response.data);
              }
            );
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
                alert(
                  "You cannot delete this shared saved search as you did not create it"
                );
                break;
              case 404:
                alert("This saved search has already been deleted");
                break;
              default:
                alert("An error occurred while deleting your saved search");
                break;
            }
          });
      }
    },

    /**
     * Hide the save search form modal
     */
    hideModal() {
      // hide the modal
      window.$("#searchformsavemodal").modal("hide");
    },

    /**
     * Escape html
     */
    escapeHtml(html) {
      return html
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#39;");
    },
  },
};
</script>

<style>
.searchformsave_helpbtn {
  margin-right: 0.5em;
}
.searchformsave_header span {
  padding-left: 5px;
}
.searchformsave_newname {
  margin-bottom: 5px;
}
.searchformsave_newname input {
  width: 100%;
}
.searchformsave_type span {
  padding-left: 5px;
}
.searchformsave_type input[type="radio"] {
  margin-left: 5px;
}
.searchformsave_type label {
  padding-left: 0.5em;
  padding-right: 1em;
}
.savedsearch_delete {
  text-align: right;
  color: red;
}
a.btn.savedsearchform_link {
  padding: 0.25em 0.75rem;
}
</style>
