<template>
  <div class="row searchformtextline">
    <div
      class="col-1 searchformtextline_bool"
      :data-v-step="'biblio-search-operator' + lineNo"
    >
      <select
        v-if="lineNo != 1"
        v-model="currentBool"
        :name="'bool' + lineNo"
        size="1"
      >
        <option value="AND">and</option>
        <option value="OR">or</option>
        <option value="NOT">not</option>
        <option value="WITH">with</option>
        <option value="WITHNOT">with not</option>
      </select>
      <input v-else id="BoolCtrl1" name="bool1" type="hidden" value="" />
    </div>
    <div class="col-3 searchformtextline_field">
      <searchform-field-dropdown
        :id="'FldCtrl' + lineNo"
        v-model="currentField"
        :name="'field' + lineNo"
        :fields-list="fieldsList"
        :initial-field="currentField"
      >
      </searchform-field-dropdown>
    </div>
    <div class="col-1 searchformtextline_index">
      <button
        :id="'IndexBtn' + lineNo"
        type="button"
        class="btn btn-outline-primary"
        :disabled="!isIndexEnabled"
        :title="
          !isIndexEnabled ? 'There is no index available for this field' : false
        "
        :data-v-step="'biblio-search-index' + lineNo"
        @click="indexBtnClick"
      >
        Index
      </button>
    </div>
    <div class="col-7 searchformtextline_term">
      <input
        v-if="filter"
        :name="'type' + lineNo"
        type="hidden"
        :value="'text' + (filter ? '/f' : '')"
      />
      <input
        :id="'TermCtrl' + lineNo"
        ref="termCtrl"
        v-model="currentTerm"
        :name="'term' + lineNo"
        type="text"
        maxlength="1500"
        @keyup.enter="enterKeyPressed"
      />
    </div>
  </div>
</template>

<script>
/**
 * Display a search form text edit line
 *
 * The following form controls are output:
 *   'bool{lineNo}' (select/hidden), 'field{lineNo}' (select), 'type{lineNo}' = 'text/f' (hidden, optional), 'term{lineNo}' (text)
 */
import SearchFormFieldDropdown from "./SearchFormFieldDropdown.vue";

export default {
  name: "SearchFormTextLine",

  components: {
    "searchform-field-dropdown": SearchFormFieldDropdown,
  },

  props: {
    /**
     * The line number
     */
    lineNo: {
      type: Number,
      required: true,
    },

    /**
     * The fields list. An array of field objects and field group objects, field objects have the properties 'tag' (string),
     * 'name' (string) and 'index' (bool), and field group objects have the properties 'group' (string) and 'fields' (array)
     */
    fieldsList: {
      type: Array,
      required: true,
    },

    /**
     * The initial boolean
     */
    initialBool: {
      type: String,
      default: "AND",
    },

    /**
     * The initial field
     */
    initialField: {
      type: String,
      required: true,
    },

    /**
     * The initial term
     */
    initialTerm: {
      type: String,
      default: "",
    },

    /**
     * This is a filter line
     */
    filter: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      currentBool: this.initialBool,
      currentField: this.initialField,
      currentTerm: this.initialTerm,
    };
  },

  computed: {
    /*
     * Is the index enabled for the current field
     */
    isIndexEnabled: function () {
      let fieldInfo = this.findField(this.currentField);
      return fieldInfo ? fieldInfo.index : false;
    },
  },

  mounted() {
    // if the first line the focus the term edit box
    if (this.lineNo == 1) {
      this.$refs.termCtrl.focus();
    }
  },

  methods: {
    /**
     * Find the specified field in the fields list
     */
    findField(fieldTag) {
      for (const aField of this.fieldsList) {
        if (aField.group) {
          for (const aGroupField of aField.fields) {
            if (aGroupField.tag == fieldTag) {
              return aGroupField;
            }
          }
        } else {
          if (aField.tag == fieldTag) {
            return aField;
          }
        }
      }
      return null;
    },

    /**
     * Index button click event
     */
    indexBtnClick() {
      let fieldInfo = this.findField(this.currentField);
      if (fieldInfo && fieldInfo.index) {
        this.$emit("show-index", this, fieldInfo);
      }
    },

    /**
     * Enter key pressed event
     */
    enterKeyPressed() {
      this.$emit("enter-key-pressed", this.lineNo);
    },
  },
};
</script>

<style>
.searchformtextline_bool select {
  width: 130%; /* this may seem odd but it works */
  font-variant: small-caps;
}
.searchformtextline_field .searchformfielddropdown {
  width: 100%;
}
.searchformtextline_index {
  text-align: center;
  padding-bottom: 5px;
}
.searchformtextline_term input {
  width: 100%;
}
</style>
