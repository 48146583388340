<template>
  <i
    v-b-popover.hover="tooltipContent"
    :title="tooltipHeader"
    class="fa fa-question-circle text-primary"
  ></i>
</template>

<script>
/**
 * Component displays an info icon
 *
 * With the option of including a popover with help text
 *
 * Contains a default list of pre-made info-tips for easy re-use
 */
export default {
  name: "InfoIcon",

  props: {
    /**
     * The name of a pre-defined tip
     *
     * See the tips data property for a complete list
     */
    tip: {
      type: String,
      default: null,
    },
    /**
     * The title will display as the tooltips
     * header. If set this will overrite the
     * title from the pre-defined tip.
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * The text will display as the tooltips
     * content. If set this will overrite the
     * text from the pre-defined tip.
     */
    text: {
      type: [String, Object],
      default: null,
    },
  },

  data: function () {
    return {
      tips: {
        markedRecords: {
          title: "Marked Records",
          text: "Check the box to mark a record. Marked records can be used to perform certain actions such as tagging, exporting or generating marketing materials on a selection of records.",
        },
        markedRecordsContacts: {
          title: "Marked Records",
          text: "Check the box to mark a record. Marked records can be used to perform certain actions such as tagging or exporting on a selection of records.",
        },
        ftpCatalogueLastUpdated: {
          title: "Last Updated",
          text: "The last updated time of a catalogue displays the time when changes were last made to the catalogue file. This could be due to a change in the search results, a change to the catalogue configuration, or if changes were made to a product in the file. The file gets regenerated daily, or when (optionally) manually generated using the regenerate button. Note that the file won't be updated if there are no changes to be picked up, therefore the last updated time may not change on regeneration.",
        },
        contentItemItemNumber: {
          title: "Item Type Name and Item Number",
          text: {
            content:
              "<b>Item Type Name</b>: The generic name given to the type of section which the content item represents, e.g. Chapter, Part, Track.<br><br><b>Item Number</b>: The number which is given to the content item",
            html: true,
          },
        },
        contentItemPageRuns: {
          title: "Pages",
          text: "The pages range that this content item covers. A page range is the starting and ending page numbers of a contiguous sequence of pages separated by a hyphen (-), or it can be a single page number. It is also possible to list multiple page ranges separating them by commas.",
        },
        contentItemPageCount: {
          title: "Page Count",
          text: "The total number of pages that content item covers.",
        },
        contentItemTimeRuns: {
          title: "Times",
          text: {
            content:
              "The time range that this content item covers. A time range is the starting and ending time of contiguous sequence of audio or video with the star and end times separated by a hyphen (-). The start and end times need to be listed in the form <i>hours</i>:<i>minutes</i>:<i>seconds</i> or <i>minutes</i>:<i>seconds</i>. It is also possible to list multiple time ranges separating them by commas.",
            html: true,
          },
        },
        contentItemTimeDuration: {
          title: "Time Duration",
          text: {
            content:
              "The total duration of this content item's audio or video. This need to be listed in the form <i>hours</i>:<i>minutes</i>:<i>seconds</i> or <i>minutes</i>:<i>seconds</i>.",
            html: true,
          },
        },
        contentItemProductIdentifiers: {
          title: "Product Identifiers",
          text: "If this content item is available as a product in its own right, then list its product identifier(s) here.",
        },
      },
    };
  },

  computed: {
    tooltipHeader: function () {
      if (this.title !== null) {
        return this.title;
      }
      if (this.tip in this.tips) {
        return this.tips[this.tip].title;
      }
      return null;
    },
    tooltipContent: function () {
      if (this.text !== null) {
        return this.text;
      }
      if (this.tip in this.tips) {
        return this.tips[this.tip].text;
      }
      return null;
    },
  },
};
</script>

<style></style>
