<template>
  <div class="biblioidentifiersedit">
    <b-dropdown
      variant="none"
      text="Add"
      toggle-class="btn-outline-primary biblioidentifiersedit-add"
    >
      <b-dropdown-item
        v-for="type in types"
        :key="type.code"
        :disabled="typesInUse.indexOf(type.code) != -1"
        @click="addIdentifier(type.code)"
      >
        {{ type.name }}
      </b-dropdown-item>
    </b-dropdown>
    <div
      v-for="(identifier, identifierIndex) in currentIdentifiers"
      :key="identifierIndex"
      class="biblioidentifiersedit-item"
    >
      <template v-for="(type, typeIndex) in types">
        <template v-if="type.code == identifier.type">
          <template v-if="identifier.displayMode">
            <div
              :key="400 * identifierIndex + 4 * typeIndex"
              class="biblioidentifiersedit-item-display"
            >
              {{ type.name }}:
              <span @click="switchToEdit(identifierIndex)">
                {{ identifier.value }}
                —
                <em>{{ identifier.displayData.title }}</em>
              </span>
            </div>
            <a
              :key="400 * identifierIndex + 4 * typeIndex + 1"
              href="#"
              :title="'Edit this ' + type.name + ' identifier'"
              @click.stop="switchToEdit(identifierIndex)"
            >
              <i class="fa fa-edit"></i>
            </a>
          </template>
          <template v-else-if="lookupUrl">
            {{ type.name }}:
            <input
              :id="
                'biblioidentifiersedit-' +
                componentId +
                '-item-' +
                identifierIndex
              "
              :key="400 * identifierIndex + 4 * typeIndex"
              type="text"
              maxlength="50"
              :value="identifier.value"
              :pattern="type.pattern"
              :placeholder="type.name"
              :data-idtype="type.code"
              :data-idlookup="type.lookup ? type.lookup : type.code"
              @click.stop=""
              @focus="performLookup(identifierIndex, $event.target)"
              @input="performLookup(identifierIndex, $event.target)"
              @change="changedValue(identifierIndex, $event.target)"
            />
            <a
              v-if="identifier.displayData"
              :key="400 * identifierIndex + 4 * typeIndex + 1"
              href="#"
              :title="'Cancel editing this ' + type.name + ' identifier'"
              @click.stop="cancelEdit(identifierIndex)"
            >
              <i class="fa fa-xmark"></i>
            </a>
          </template>
          <template v-else>
            {{ type.name }}:
            <input
              :id="
                'biblioidentifiersedit-' +
                componentId +
                '-item-' +
                identifierIndex
              "
              :key="400 * identifierIndex + 4 * typeIndex"
              type="text"
              maxlength="50"
              :value="identifier.value"
              :pattern="type.pattern"
              :placeholder="type.name"
              @change="changedValue(identifierIndex, $event.target)"
            />
          </template>
          <a
            :key="400 * identifierIndex + 4 * typeIndex + 2"
            href="#"
            :title="'Remove this ' + type.name + ' identifier'"
            @click.stop="deleteIdentifier(identifierIndex)"
          >
            <i class="fa fa-trash"></i>
          </a>
          &emsp;
          <div
            v-if="lookupUrl"
            :id="
              'biblioidentifiersedit-' +
              componentId +
              '-item-' +
              identifierIndex +
              '-results'
            "
            :key="400 * identifierIndex + 4 * typeIndex + 3"
            class="biblioidentifiersedit-results"
            @click.stop.prevent="selectResult(identifierIndex, $event.target)"
          ></div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
/**
 * Bibliographic identifiers edit container
 *
 * Emits a 'changed' event including an array of identifier objects (excluding empty entries)
 */
import { HTTP } from "../../../http-common.js";

export default {
  name: "BiblioIdentifiersEdit",

  props: {
    /**
     * Component id
     */
    componentId: {
      type: String,
      default: "",
    },

    /**
     * Identifiers to edit
     * If multiple identifiers of the same type then an array of objects with each holding a single identifier
     * If only single identifiers of the same type then an object with each property being an identifier
     */
    identifiers: {
      type: [Array, Object],
      default: null,
    },

    /**
     * Identifier types
     * An array of objects each with the properties:
     *  code: string
     *  name: string
     *  lookup: string (optional)
     *  pattern: string (optional)
     */
    types: {
      type: Array,
      required: true,
    },

    /**
     * Are multiple identifers of the same type allowed
     */
    multiple: {
      type: Boolean,
      default: false,
    },

    /**
     * Can we use an ajax lookup function to find identifiers using what's typed;
     * '@T' in the url is replaced with the identifier's type (either the 'lookup'
     * or 'code' value from the types property) and '@S' is replaced with the
     * uri-encoded search term
     */
    lookupUrl: {
      type: String,
      default: null,
    },

    /**
     * Extra properties for each identifier
     */
    extraIdentifierProperties: {
      type: Object,
      default: null,
    },
  },

  data: function () {
    let typeCodes = [];
    for (let i = 0; i < this.types.length; i++) {
      typeCodes[i] = this.types[i].code;
    }

    let currentIdentifiers = [];
    let typesInUse = [];
    if (this.multiple) {
      let identifiers = this.identifiers ? this.identifiers : [];
      for (let i = 0; i < identifiers.length; i++) {
        for (let propName in identifiers[i]) {
          if (typeCodes.indexOf(propName) != -1) {
            if ("getProduct" in identifiers[i]) {
              if (identifiers[i].getProduct !== null) {
                let title =
                  identifiers[i].getProduct.getMainTitle +
                  " (" +
                  identifiers[i].getProduct.getFormatName +
                  ")";
                currentIdentifiers.push({
                  type: propName,
                  value: identifiers[i][propName],
                  displayMode: true,
                  displayData: {
                    value: identifiers[i][propName],
                    title: title,
                  },
                });
              } else {
                currentIdentifiers.push({
                  type: propName,
                  value: identifiers[i][propName],
                  displayMode: false,
                  displayData: null,
                });
              }
            } else {
              currentIdentifiers.push({
                type: propName,
                value: identifiers[i][propName],
                displayMode: false,
                displayData: null,
              });
            }
            break;
          }
        }
      }
    } else {
      let identifiers = this.identifiers ? this.identifiers : {};
      for (let propName in identifiers) {
        if (typeCodes.indexOf(propName) != -1 && identifiers[propName]) {
          currentIdentifiers.push({
            type: propName,
            value: identifiers[propName],
            displayMode: false,
            displayData: null,
          });
          typesInUse.push(propName);
        }
      }
    }
    return {
      typesInUse: typesInUse,
      currentIdentifiers: currentIdentifiers,
      currentResults: -1,
    };
  },

  mounted: function () {
    if (this.lookupUrl) {
      document.addEventListener("click", this.documentClick);
    }
  },

  destroyed: function () {
    if (this.lookupUrl) {
      document.removeEventListener("click", this.documentClick);
    }
  },

  methods: {
    /**
     * Add an identifier
     * Note: we don't notify of changes as we have added an identifier entry with no value
     */
    addIdentifier: function (type) {
      this.currentIdentifiers.push({
        type: type,
        value: "",
        displayMode: false,
        displayData: null,
      });
      if (!this.multiple) {
        this.typesInUse.push(type);
      }
      window.setTimeout(() => {
        document
          .getElementById(
            "biblioidentifiersedit-" +
              this.componentId +
              "-item-" +
              (this.currentIdentifiers.length - 1)
          )
          .focus();
      }, 50);
    },

    /**
     * Delete an identifier
     */
    deleteIdentifier: function (index) {
      if (!this.currentIdentifiers[index].value) {
        if (!this.multiple) {
          let typesInUseIndex = this.typesInUse.indexOf(
            this.currentIdentifiers[index].type
          );
          this.typesInUse.splice(typesInUseIndex, 1);
        }
        this.currentIdentifiers.splice(index, 1);
        this.notifyChanges();
      } else {
        this.$bvModal
          .msgBoxConfirm("Do you want to remove this identifier ?")
          .then((value) => {
            if (value) {
              if (!this.multiple) {
                let typesInUseIndex = this.typesInUse.indexOf(
                  this.currentIdentifiers[index].type
                );
                this.typesInUse.splice(typesInUseIndex, 1);
              }
              this.currentIdentifiers.splice(index, 1);
              this.notifyChanges();
            }
          });
      }
    },

    /**
     * The identifier value has been changed
     */
    changedValue: function (index, control) {
      this.currentIdentifiers[index].value = control.value;
      this.notifyChanges();
    },

    /**
     * notify of changed identifiers
     */
    notifyChanges: function () {
      // select identifier entries with a type and value
      if (this.multiple) {
        let identifiers = [];
        for (let i = 0; i < this.currentIdentifiers.length; i++) {
          if (
            this.currentIdentifiers[i].type != "" &&
            this.currentIdentifiers[i].value != ""
          ) {
            let identifier = { ...this.extraIdentifierProperties };
            identifier[this.currentIdentifiers[i].type] =
              this.currentIdentifiers[i].value;
            identifiers.push(identifier);
          }
        }
        this.$emit("changed", identifiers);
      } else {
        let identifiers = { ...this.extraIdentifierProperties };
        for (let i = 0; i < this.currentIdentifiers.length; i++) {
          if (
            this.currentIdentifiers[i].type != "" &&
            this.currentIdentifiers[i].value != ""
          ) {
            identifiers[this.currentIdentifiers[i].type] =
              this.currentIdentifiers[i].value;
          }
        }
        this.$emit("changed", identifiers);
      }
    },

    /**
     * Switch to edit mode
     */
    switchToEdit(index) {
      this.currentIdentifiers[index].displayMode = false;
      window.setTimeout(() => {
        let control = document.getElementById(
          "biblioidentifiersedit-" + this.componentId + "-item-" + index
        );
        control.focus();
        this.performLookup(index, control);
      }, 50);
    },

    /**
     * Cancel edit mode
     */
    cancelEdit(index) {
      if (this.currentIdentifiers[index].displayData) {
        this.currentIdentifiers[index].value =
          this.currentIdentifiers[index].displayData.value;
        this.currentIdentifiers[index].displayMode = true;
        if (this.currentResults == index) {
          this.hideResults(index);
        }
        this.notifyChanges();
      }
    },

    /**
     * Perform a lookup
     */
    performLookup: function (index, control) {
      if (this.lookupUrl) {
        if (control.value) {
          // check if the results div's contents are for the control's current value
          let resultsDiv = document.getElementById(
            "biblioidentifiersedit-" +
              this.componentId +
              "-item-" +
              index +
              "-results"
          );
          if (resultsDiv.dataset.query == control.value) {
            // hide the current results div (if different)
            if (this.currentResults != index) {
              this.hideResults(this.currentResults);
            }

            // show the current results div
            let containerDiv = control.parentElement.parentElement;
            let resultsDivLeft =
              control.offsetLeft - containerDiv.offsetLeft + 10;
            if (resultsDivLeft + 400 > containerDiv.offsetWidth) {
              resultsDivLeft = Math.max(
                containerDiv.offsetWidth - 400,
                containerDiv.offsetLeft
              );
            }
            resultsDiv.style.left = resultsDivLeft + "px";
            resultsDiv.style.display = "block";
            this.currentResults = index;

            // return
            return;
          }

          // perform the lookup
          let url = this.lookupUrl
            .replace(/@T/, control.dataset.idlookup)
            .replace(/@S/, encodeURIComponent(control.value));
          HTTP.get(url).then((response) => {
            // hide the current results div (if different)
            if (this.currentResults != index) {
              this.hideResults(this.currentResults);
            }

            // display results div
            if (response.data && response.data.length) {
              let resultsHtml = "";
              for (let i = 0; i < response.data.length; i++) {
                let productId = response.data[i].id
                  .replace(/&/g, "&amp;")
                  .replace(/</g, "&lt;")
                  .replace(/>/g, "&gt;")
                  .replace(/"/g, "&quot;");
                let productTitle = response.data[i].title
                  .replace(/&/g, "&amp;")
                  .replace(/</g, "&lt;")
                  .replace(/>/g, "&gt;")
                  .replace(/"/g, "&quot;");
                resultsHtml +=
                  '<a href="#" data-id="' +
                  productId +
                  '" data-title="' +
                  productTitle +
                  '">' +
                  productId +
                  " : " +
                  productTitle +
                  "</a>";
              }
              if ("x-moreproducts" in response.headers) {
                resultsHtml +=
                  '<div class="moreproducts"><i>More&hellip;</i></div>';
              }

              let containerDiv = control.parentElement.parentElement;
              let resultsDivLeft =
                control.offsetLeft - containerDiv.offsetLeft + 10;
              if (resultsDivLeft + 400 > containerDiv.offsetWidth) {
                resultsDivLeft = Math.max(
                  containerDiv.offsetWidth - 400,
                  containerDiv.offsetLeft
                );
              }
              resultsDiv.innerHTML = resultsHtml;
              resultsDiv.dataset.query = control.value;
              resultsDiv.style.left = resultsDivLeft + "px";
              resultsDiv.style.display = "block";
              this.currentResults = index;
            } else {
              // no results
              resultsDiv.style.display = "none";
              if (this.currentResults == index) {
                this.currentResults = -1;
              }
            }
          });
        } else {
          this.hideResults(index);
        }
      }
    },

    /**
     * Search an identifier from the lookup results
     */
    selectResult: function (index, control) {
      this.hideResults(index);
      let inputControl = document.getElementById(
        "biblioidentifiersedit-" + this.componentId + "-item-" + index
      );
      inputControl.value = control.dataset.id;
      this.currentIdentifiers[index].value = control.dataset.id;
      this.currentIdentifiers[index].displayData = {
        value: control.dataset.id,
        title: control.dataset.title,
      };
      this.currentIdentifiers[index].displayMode = true;
      this.notifyChanges();
    },

    /**
     * Hide a results div
     */
    hideResults: function (index) {
      if (index != -1) {
        let resultsDiv = document.getElementById(
          "biblioidentifiersedit-" +
            this.componentId +
            "-item-" +
            index +
            "-results"
        );
        if (resultsDiv) {
          resultsDiv.style.display = "none";
        }
        if (this.currentResults == index) {
          this.currentResults = -1;
        }
      }
    },

    /**
     * Document click handler - hide any open search results
     */
    documentClick: function () {
      this.hideResults(this.currentResults);
    },
  },
};
</script>

<style>
.biblioidentifiersedit {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
}
.biblioidentifiersedit-add {
  margin-right: 1.5rem;
  margin-bottom: 0.75rem;
}
.biblioidentifiersedit-item-display {
  display: inline-block;
  padding: 5px 0 7px 0;
  background-color: transparent;
}
.biblioidentifiersedit-item-display span {
  margin: -7px 0;
  padding: 7px 6px;
  border: 1px solid #a5acb2;
  border-radius: 0.25rem;
}
.biblioidentifiersedit-item-display span:hover {
  background-color: #e8e8e8;
}
.biblioidentifiersedit-item input {
  width: 150px;
}
.biblioidentifiersedit-item a {
  padding-left: 0.25rem;
}
.biblioidentifiersedit-item input[type="text"]:invalid {
  border: red solid 2px;
}
.biblioidentifiersedit-results {
  display: none;
  position: absolute;
  top: 4em;
  max-height: 20em;
  width: 400px;
  max-width: 400px;
  overflow-y: auto;
  border: 1px solid #aaa;
  box-shadow: 5px 5px 5px #aaa;
  margin-left: 5px;
  z-index: 999999;
}
.biblioidentifiersedit-results a,
.biblioidentifiersedit-results div.moreproducts {
  display: block;
  border-top: 1px solid #eee;
  padding: 0.2em 1em;
  background-color: white;
}
.biblioidentifiersedit-results a:first-child {
  padding-top: 0.5em;
}
</style>
