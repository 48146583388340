<template>
  <b-modal
    :id="name"
    v-bind="$attrs"
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    ok-only
    size="lg"
    @ok="disableModal"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
/**
 * Shows a modal to the user which once viewed once
 * will save to a usersetting to disable the modal from
 * showing again.
 */

import { HTTP } from "../../http-common.js";

export default {
  name: "ShowOnceModal",

  props: {
    name: {
      type: String,
      required: true,
    },

    /**
     * By default a tour or popup won't display to
     * users with the H/Headfast category. Use this
     * option to force this tour to always display.
     */
    displayToHeadfastUsers: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    disableUrl: function () {
      return (
        this.$baseUrl +
        "api/usersettings/write-setting/tours/disable-" +
        this.name
      );
    },
  },
  mounted: function () {
    if (!this.displayToHeadfastUsers && this.$userHasCategory("H")) {
      return;
    }
    HTTP.get(
      this.$baseUrl + "api/usersettings/read-setting/tours/disable-" + this.name
    ).then((response) => {
      // all settings are saved as strings so read the false string
      if (!response.data.value || response.data.value == "false") {
        this.$bvModal.show(this.name);
      }
    });
  },

  methods: {
    /**
     * Disables the modal by saving the user setting
     */
    disableModal: async function () {
      let postData = new FormData();
      postData.append("value", true);
      HTTP.post(this.disableUrl, postData);
    },
  },
};
</script>
