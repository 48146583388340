<template>
  <b-badge
    v-show="status"
    class="ml-1 product-summary-badge"
    :variant="productStatusVariant"
    ><slot>{{ productStatusText }}</slot></b-badge
  >
</template>

<script>
/**
 * Displays a colour coded badge which contains the product status
 *
 */
export default {
  name: "ProductStatusBadge",

  props: {
    status: {
      type: String,
      default: null,
    },
  },

  computed: {
    /**
     * Sets the badge variant based on the product
     * status
     */
    productStatusVariant: function () {
      switch (this.status) {
        case "A":
          return "success";
        case "P":
          return "warning";
        case "D":
          return "danger";
        default:
          return "secondary";
      }
    },
    /**
     * Sets the text in the status badge based
     * on the products status
     */
    productStatusText: function () {
      switch (this.status) {
        case "A":
          return "Approved";
        case "P":
          return "Provisional";
        case "D":
          return "Archived";
        default:
          return "-";
      }
    },
  },
};
</script>

<style></style>
