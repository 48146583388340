<template>
  <div class="d-flex justify-content-between">
    <v-select
      v-model="duration"
      class="date-range-select"
      :options="optionsDuration"
      :clearable="false"
      :initial-value="duration"
      :disabled="disabled"
      @input="onInput"
    ></v-select>
    <input
      v-if="duration != 'Between'"
      v-model="number"
      type="number"
      class="form-control input-number date-range-number mx-3"
      min="0"
      :disabled="disabled"
      @input="onInput"
    />
    <v-select
      v-if="duration != 'Between'"
      v-model="unit"
      class="date-range-select"
      :options="optionsTimeUnit"
      :clearable="false"
      :initial-value="unit"
      :disabled="disabled"
      @input="onInput"
    ></v-select>
    <v-datepicker
      v-if="duration == 'Between'"
      v-model="betweenStart"
      input-class="form-control"
      class="ml-2 date-range-date"
      :format="dateFormat"
      :disabled="disabled"
      @input="onDateStartInput"
    ></v-datepicker>
    <v-datepicker
      v-if="duration == 'Between'"
      v-model="betweenEnd"
      input-class="form-control"
      class="ml-2 date-range-date"
      :format="dateFormat"
      :disabled="disabled"
      @input="onDateEndInput"
    ></v-datepicker>
  </div>
</template>

<script>
/**
 * Date range picker component
 *
 * This component provides the inputs to allow a user to choose a date range,
 * either by using two specified dates or by a duration value such as 'within_last_6_weeks'
 * or 'within_next_5_days'
 *
 * The component can be passed this value string as a prop and it will fill in the
 * appropriate inputs.
 *
 * The component will also provide the 'Start' and 'End' dates based on the selected
 * date duration value.
 *
 * The component uses Luxon (https://moment.github.io/luxon/) to handle DateTime objects
 */

import { DateTime, Interval } from "luxon";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";

export default {
  name: "DateRangePicker",

  components: {
    "v-select": vSelect,
    "v-datepicker": Datepicker,
  },

  props: {
    /**
     * The value of the date range
     *
     * The value should be in a string format using the following template:
     * ['within_','within_next_','within_last_','between_'][number]['_days','_weeks','_months','_years']
     *
     * e.g. 'within_last_6_months', 'within_next_8_days'
     *
     * When using the 'between' keyword the format will const of two date strings in ISO 8601 seperated by _
     *
     * e.g. 'between_2021-01-01T00:00:00.00+00:00_2021-12-31T00:00:00.00+00:00'
     */
    value: {
      type: String,
      default: null,
    },

    /**
     * The JavaScript date format to be used for the dates chosen by the date picker
     *
     * If no dateFormat is provided then yyyy-MM-dd is assumed
     */
    dateFormat: {
      type: String,
      default: "yyyy-MM-dd",
    },

    /**
     * Sets whether the inputs should be disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      optionsDuration: [
        "Within the next...",
        "Within the last...",
        "Within",
        "Between",
      ],
      optionsTimeUnit: ["Days", "Weeks", "Months", "Years"],
      duration: "Within the last...",
      number: null,
      unit: "Days",
      betweenStart: null,
      betweenEnd: null,
    };
  },

  computed: {
    /**
     * Builds the string value
     */
    computedValue: function () {
      if (
        !this.duration ||
        ((!this.number || !this.unit) && this.duration != "Between") ||
        ((!this.start || !this.end) && this.duration == "Between")
      ) {
        return null;
      }
      let stringVal = "";
      switch (this.duration) {
        case "Within the next...":
          stringVal = "within_next_";
          break;
        case "Within the last...":
          stringVal = "within_last_";
          break;
        case "Within":
          stringVal = "within_";
          break;
        case "Between":
          stringVal = "between_";
          break;
      }
      if (this.duration != "Between") {
        stringVal += this.number;
        stringVal += "_" + this.unit.toLowerCase();
      } else {
        stringVal += this.start.toISO() + "_" + this.end.toISO();
      }
      return stringVal;
    },

    /**
     * Creates the interval object used by Luxon
     * for
     */
    intervalObject: function () {
      let intervalObject = {};
      if (!this.unit || !this.number) {
        return null;
      }
      intervalObject[this.unit.toLowerCase()] = this.number;
      return intervalObject;
    },

    /**
     * Calculates the start date of the selected range
     *
     * @returns {DateTime} (Luxon object)
     */
    start: {
      get: function () {
        if (!this.intervalObject && this.duration != "Between") {
          return null;
        }
        let now = DateTime.now();
        if (this.duration == "Within the next...") {
          return now;
        }
        if (this.duration == "Between") {
          if (!this.betweenStart) {
            return null;
          }
          // convert the start string to a date
          let newDate = new Date(this.betweenStart);
          return DateTime.fromJSDate(newDate);
        }
        return now.minus(this.intervalObject);
      },
      set: function (value) {
        this.betweenStart = value.toJSDate();
      },
    },

    /**
     * Converts the start DateTime object to a string
     * formatted as ISO 8601
     *
     * @returns {string}
     */
    startString: function () {
      return this.start.toString();
    },

    /**
     * Calculates the end date of the selected range
     *
     * @returns {DateTime} (Luxon object)
     */
    end: {
      get: function () {
        if (!this.intervalObject && this.duration != "Between") {
          return null;
        }
        let now = DateTime.now();
        if (this.duration == "Within the last...") {
          return now;
        }
        if (this.duration == "Between") {
          if (!this.betweenEnd) {
            return null;
          }
          // convert the start string to a date
          let newDate = new Date(this.betweenEnd);
          return DateTime.fromJSDate(newDate);
        }
        return now.plus(this.intervalObject);
      },
      set: function (value) {
        this.betweenEnd = value.toJSDate();
      },
    },

    /**
     * Converts the end DateTime object to a string
     * formatted as ISO 8601
     *
     * @returns {string}
     */
    endString: function () {
      return this.end.toString();
    },

    /**
     * Creates a Luxon Interval object using the
     * start and end DateTime
     */
    interval: function () {
      return Interval.fromDateTimes(this.start, this.end);
    },
  },

  /**
   * On mounted take the value and break it into
   * duration, number and unit
   */
  mounted: function () {
    if (!this.value) {
      return;
    }
    let valArray = this.value.split("_");
    switch (valArray[0]) {
      case "within":
        if (valArray[1] == "next") {
          this.duration = "Within the next...";
          this.number = valArray[2];
          this.unit = valArray[3];
        } else if (valArray[1] == "last") {
          this.duration = "Within the last...";
          this.number = valArray[2];
          this.unit = valArray[3];
        } else {
          this.duration = "Within";
          this.number = valArray[1];
          this.unit = valArray[2];
        }
        break;
      case "between":
        this.duration = "Between";
        this.start = DateTime.fromISO(valArray[1]);
        this.end = DateTime.fromISO(valArray[2]);
        break;
    }
  },

  methods: {
    /**
     * set the computed start value to use a DateTime object from a JS Date Object
     */
    onDateStartInput(value) {
      this.start = DateTime.fromJSDate(value).set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      this.onInput();
    },
    /**
     * set the computed end value to use a DateTime object from a JS Date Object
     */
    onDateEndInput(value) {
      this.end = DateTime.fromJSDate(value).set({
        hour: 23,
        minute: 59,
        second: 59,
      });
      this.onInput();
    },
    onInput() {
      if (this.start && this.end) {
        this.$emit("input-start", this.start);
        this.$emit("input-end", this.end);
        this.$emit("input", this.computedValue);
      }
    },
  },
};
</script>

<style scoped>
.date-range-select {
  width: 100%;
}
.date-range-number {
  width: 90px;
}
.date-range-date {
  width: 90%;
}

/deep/ .form-control[readonly] {
  background-color: #fff !important;
}
</style>
