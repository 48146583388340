<template>
  <div>
    <div>
      <base-table
        :filters-enabled="true"
        :columns="columns"
        :initial-data="disseminations"
        :rows-per-page="50"
        :date-format="dateOptions.format"
        :date-options="dateOptions"
      >
        <template v-slot:user="{ record }">
          <i v-if="record.user === 'BooksoniX'">BooksoniX</i>
          <template v-else>{{ record.user }}</template>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
/**
 * Displays a table of dissemination data for a product
 */

import ParseDate from "../../mixins/parseDate.js";

export default {
  name: "DisseminationsTable",

  mixins: [ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      required: true,
    },

    /**
     * List of disseminations containing recipients, date and sender
     * [{recipients: "Recipient 1, Recipient 2", date: "1970-01-01", sender: "Tester"}]
     */
    disseminations: {
      type: Array,
      default: null,
    },
  },

  data: function () {
    return {};
  },

  computed: {
    columns: function () {
      let cols = [
        { text: "Recipients", key: "recipients" },
        { text: "Date", key: "sent_on", type: "date" },
        { text: "Sender", key: "user" },
      ];
      return cols;
    },
  },
};
</script>

<style></style>
