<template>
  <div>
    <input v-if="filter" :name="'type' + lineNo" type="hidden" value="text/f" />
    <input :name="'bool' + lineNo" type="hidden" :value="bool" />
    <input :name="'field' + lineNo" type="hidden" :value="field" />
    <input :name="'term' + lineNo" type="hidden" :value="term" />
  </div>
</template>

<script>
/**
 * Display a search form hidden line
 *
 * The following form controls are output:
 *   'type{lineNo}' = 'text/f' (hidden, optional), 'bool{lineNo}' (hidden), 'field{lineNo}' (hidden), 'term{lineNo}' (hidden)
 */
export default {
  name: "SearchFormHiddenLine",

  props: {
    /**
     * The line number
     */
    lineNo: {
      type: Number,
      required: true,
    },

    /**
     * The boolean (fixed value)
     */
    bool: {
      type: String,
      default: "AND",
    },

    /**
     * The field (fixed value)
     */
    field: {
      type: String,
      required: true,
    },

    /**
     * The term (fixed value)
     */
    term: {
      type: Array,
      required: true,
    },

    /**
     * Is a filter line?
     */
    filter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
