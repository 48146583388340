<template>
  <b-card id="productCardAdditionalNotes">
    <template #header>
      <a id="additional-notes" />
      <h5 class="d-inline-block">Additional Notes</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-notes/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
      ></product-quick-jump-dropdown>
    </template>
    <dl v-if="haveNotes || showAllFields" class="row">
      <dt class="col-sm-12 font-weight-bold text-secondary">
        Additional Notes
      </dt>
      <dd class="col-sm-12">
        <div v-if="notes.get1 || showAllFields" class="row note-line">
          <div class="col-sm-2 font-weight-bold text-secondary pl-0 pr-0">
            {{ notes.getLabel1 | labelOrDefault }}
          </div>
          <!-- prettier-ignore -->
          <div class="col-sm-10 preserve-white-space">{{ notes.get1 | valueOrPlaceholder }}</div>
        </div>
        <div v-if="notes.get2 || showAllFields" class="row note-line">
          <div class="col-sm-2 font-weight-bold text-secondary pl-0 pr-0">
            {{ notes.getLabel2 | labelOrDefault }}
          </div>
          <!-- prettier-ignore -->
          <div class="col-sm-10 preserve-white-space">{{ notes.get2 | valueOrPlaceholder }}</div>
        </div>
        <div v-if="notes.get3 || showAllFields" class="row note-line">
          <div class="col-sm-2 font-weight-bold text-secondary pl-0 pr-0">
            {{ notes.getLabel3 | labelOrDefault }}
          </div>
          <!-- prettier-ignore -->
          <div class="col-sm-10 preserve-white-space">{{ notes.get3 | valueOrPlaceholder }}</div>
        </div>
        <div v-if="notes.get4 || showAllFields" class="row note-line">
          <div class="col-sm-2 font-weight-bold text-secondary pl-0 pr-0">
            {{ notes.getLabel4 | labelOrDefault }}
          </div>
          <!-- prettier-ignore -->
          <div class="col-sm-10 preserve-white-space">{{ notes.get4 | valueOrPlaceholder }}</div>
        </div>
        <div v-if="notes.get5 || showAllFields" class="row note-line">
          <div class="col-sm-2 font-weight-bold text-secondary pl-0 pr-0">
            {{ notes.getLabel5 | labelOrDefault }}
          </div>
          <!-- prettier-ignore -->
          <div class="col-sm-10 preserve-white-space">{{ notes.get5 | valueOrPlaceholder }}</div>
        </div>
      </dd>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays the additional notes fields for a product
 * in a descriptive list
 */
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "AdditionalNotes",

  components: {
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  filters: {
    labelOrDefault: function (label) {
      if (label) {
        return label;
      }
      return "Note";
    },
  },

  mixins: [DisplaySetting],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * The additional notes object as provided by
     * BooksonixProductCake:::getAdditionalNotes
     */
    notes: {
      type: Object,
      default: () => {
        return {};
      },
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    haveNotes: function () {
      if (
        this.notes.get1 ||
        this.notes.get2 ||
        this.notes.get3 ||
        this.notes.get4 ||
        this.notes.get5
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.note-line {
  margin-left: 1em;
  border-top: 1px solid #e0e0e0;
}
.note-line:first-child {
  border-top: 0px;
}
</style>
