<template>
  <div class="row searchformusersline">
    <div class="col-1">
      <input :name="'bool' + lineNo" type="hidden" :value="bool" />
      <slot name="bool"></slot>
    </div>
    <div class="col-4">
      <input :name="'field' + lineNo" type="hidden" :value="field" />
      <slot name="field"></slot>
    </div>
    <div class="col-7 searchformusersline_term">
      <input
        v-if="filter"
        :name="'type' + lineNo"
        type="hidden"
        :value="'text' + (filter ? '/f' : '')"
      />
      <input
        :id="'TermCtrl' + lineNo"
        ref="TermCtrl"
        v-model="currentTerm"
        :name="'term' + lineNo"
        type="text"
        maxlength="1500"
        @keyup.enter="enterKeyPressed"
      />
      <button type="button" class="btn btn-primary" @click="selectUsers">
        Select
      </button>
    </div>
    <users-selector
      ref="UsersSelector"
      :users="users"
      @users-changed="currentTerm = $event"
    ></users-selector>
  </div>
</template>

<script>
/**
 * Display a search form users edit line
 *
 * The following form controls are output:
 *   'bool{lineNo}' (hidden), 'field{lineNo}' (hidden), 'type{lineNo}' = 'text/f' (hidden, optional), 'term{lineNo}' (text)
 */
import UsersSelector from "../utility/UsersSelector.vue";

export default {
  name: "SearchFormUsersLine",

  components: {
    "users-selector": UsersSelector,
  },

  props: {
    /**
     * The line number
     */
    lineNo: {
      type: Number,
      required: true,
    },

    /**
     * The boolean (fixed value)
     */
    bool: {
      type: String,
      default: "AND",
    },

    /**
     * The field (fixed value)
     */
    field: {
      type: String,
      required: true,
    },

    /**
     * The initial term
     */
    initialTerm: {
      type: String,
      default: "",
    },

    /**
     * This is a filter line
     */
    filter: {
      type: Boolean,
      default: false,
    },

    /**
     * The users list. An array of user objects, user objects have the properties 'num' (integer),
     * 'id' (string) and 'name' (string)
     */
    users: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      currentTerm: this.initialTerm,
    };
  },

  methods: {
    /**
     * Select users
     */
    selectUsers() {
      this.$refs.UsersSelector.show(this.$refs.TermCtrl, true);
    },

    /**
     * Enter key pressed event
     */
    enterKeyPressed() {
      this.$emit("enter-key-pressed", this.lineNo);
    },
  },
};
</script>

<style>
.searchformusersline_term input {
  width: 85%;
}
.searchformusersline_term button {
  float: right;
}
</style>
