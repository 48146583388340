<template>
  <div ref="self">
    <div class="row bibliosubjectsedit-header">
      <div class="col-3">
        <label>
          {{ header }}
        </label>
        <button
          type="button"
          title="Add another subject"
          class="btn btn-outline-primary btn-sm bibliosubjectsedit-add"
          @click="addSubject"
        >
          <i class="fa fa-plus"></i>
          Add
        </button>
      </div>
    </div>
    <template v-for="(subject, subjectIndex) in currentSubjects">
      <div :key="2 * subjectIndex + 1" class="row bibliosubjectsedit-item">
        <div class="col-4">
          <template v-if="subjectIndex == 0">
            <label>Scheme <span class="note-star">*</span></label>
            <br />
          </template>
          <select
            :id="'bibliosubjectsedit-scheme-' + subjectIndex"
            size="1"
            class="bibliosubjectsedit-scheme"
            @change="selectedScheme(subjectIndex, $event.target)"
          >
            <option value="">(select scheme)</option>
            <template v-for="(schemeInfo, schemeIndex) in currentSchemes">
              <optgroup
                v-if="Array.isArray(schemeInfo)"
                :key="1000 * schemeIndex"
                :label="schemeInfo[0].groupName"
              >
                <option
                  v-for="(groupSchemeInfo, groupSchemeIndex) in schemeInfo"
                  :key="1000 * schemeIndex + groupSchemeIndex + 1"
                  :value="groupSchemeInfo.code"
                  :selected="subject.scheme == groupSchemeInfo.code"
                >
                  {{ groupSchemeInfo.name }}
                  {{ groupSchemeInfo.listSuffix }}
                </option>
              </optgroup>
              <option
                v-else
                :key="1000 * schemeIndex + 1"
                :value="schemeInfo.code"
                :selected="subject.scheme == schemeInfo.code"
              >
                {{ schemeInfo.name }} {{ schemeInfo.listSuffix }}
              </option>
            </template>
          </select>
        </div>
        <div class="col-1">
          <div
            :class="
              isTextSubject(subject.scheme)
                ? 'bibliosubjectsedit-textonly'
                : null
            "
          >
            <template v-if="subjectIndex == 0">
              <label>Code</label>
              <br />
            </template>
            <input
              :id="'bibliosubjectsedit-code-' + subjectIndex"
              type="text"
              maxlength="30"
              :value="subject.getCode"
              class="bibliosubjectsedit-code"
              @input="changedCode(subjectIndex, $event.target)"
            />
          </div>
        </div>
        <div class="col-6">
          <template v-if="subjectIndex == 0">
            <label>Description / Text</label>
            <br />
          </template>
          <input
            type="text"
            maxlength="30"
            :value="subject.getHeading"
            class="bibliosubjectsedit-heading"
            @input="changedHeading(subjectIndex, $event.target)"
          />
        </div>
        <div class="col-1">
          <template v-if="subjectIndex == 0">
            <label>Version</label>
            <br />
          </template>
          <input
            type="text"
            :value="subject.getVersion"
            class="bibliosubjectsedit-version"
            readonly="readonly"
          />
        </div>
      </div>
      <div :key="2 * subjectIndex + 2" class="row bibliosubjectsedit-btns">
        <div class="col-12">
          <a href="#" class="btn btn-link" @click="moveSubjectUp(subjectIndex)">
            Move Up
          </a>
          <a href="#" class="btn btn-link" @click="moveSubjectDn(subjectIndex)">
            Move Down
          </a>
          <a href="#" class="btn btn-link" @click="deleteSubject(subjectIndex)">
            Delete
          </a>
          <a href="#" class="btn btn-link" @click="subjectSelect(subjectIndex)">
            Select
          </a>
        </div>
      </div>
    </template>
    <biblio-subject-browser
      ref="browser"
      :index="index"
      :in-modal="true"
      @selected="
        (scheme, version, code, heading, param) => {
          subjectSelected(scheme, version, code, heading, param);
        }
      "
    />
  </div>
</template>

<script>
/**
 * Bibliographic subjects edit container to edit main or additional subjects
 *
 * Emits a 'changed' event including an array of subject objects (excluding empty entries)
 */
import BiblioSubjectBrowser from "./BiblioSubjectBrowser.vue";

export default {
  name: "BiblioSubjectsEdit",

  components: {
    "biblio-subject-browser": BiblioSubjectBrowser,
  },

  props: {
    /**
     * Header text
     */
    header: {
      type: String,
      required: true,
    },

    /**
     * Component index
     */
    index: {
      type: Number,
      default: 1,
    },

    /**
     * Are we editing main subjects
     * When editing main subjects we only allow one subject per scheme and we
     * excluding schemes which are not allowed as main subjects
     */
    mainSubjects: {
      type: Boolean,
      default: false,
    },

    /**
     * Subjects to edit
     */
    subjects: {
      type: Array,
      default: null,
    },

    /**
     * List of all subject schemes
     */
    schemes: {
      type: Array,
      required: true,
    },

    /**
     * Subject Browsers
     */
    browsers: {
      type: Array,
      required: true,
    },

    /**
     * Subject versions for BIC, BISAC and THEMA
     */
    versions: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    let subjects = this.subjects ? this.subjects : [];

    let dataObject = this.initSubjectSchemes(
      this.schemes,
      this.mainSubjects,
      subjects,
      this.browsers
    );

    let currentSubjects = [];
    for (let i = 0; i < subjects.length; i++) {
      let scheme = subjects[i].getScheme;
      if (subjects[i].getSchemeName) {
        scheme += subjects[i].getSchemeName;
      }
      currentSubjects.push({
        ...subjects[i],
        scheme: scheme,
      });
    }
    dataObject.currentSubjects = currentSubjects;

    return dataObject;
  },

  mounted: function () {
    for (let i = 0; i < this.browsers.length; i++) {
      if (this.browsers[i].scheme && this.browsers[i].jsCode) {
        if ("jsUrl" in this.browsers[i]) {
          let newScript = document.createElement("script");
          newScript.src = this.browsers[i].jsUrl;
          this.$refs.self.appendChild(newScript);
        }
      }
    }
  },

  methods: {
    /**
     * Initialise subject schemes
     */
    initSubjectSchemes: function (schemes, mainSubjects, subjects, browsers) {
      let dataObject = {
        currentSchemes: [],
        textSchemes: [],
        proprietarySchemes: [],
        appBrowsers: [],
        amazonAdvantageUK: false,
      };

      // build the list of subject schemes
      for (let i = 0; i < schemes.length; i++) {
        let schemeInfo = schemes[i];
        if (Array.isArray(schemeInfo)) {
          let groupSchemes = [];
          for (let j = 0; j < schemeInfo.length; j++) {
            let groupSchemeInfo = schemeInfo[j];
            if (!mainSubjects || groupSchemeInfo.useAsMain) {
              if (typeof groupSchemeInfo.code == "number") {
                groupSchemeInfo.code = groupSchemeInfo.code.toString();
              }
              if (groupSchemeInfo.codeName) {
                groupSchemeInfo.code += groupSchemeInfo.codeName;
              }
              groupSchemes.push(groupSchemeInfo);
              if (groupSchemeInfo.textOnly) {
                dataObject.textSchemes.push(groupSchemeInfo.code);
              }
              if (groupSchemeInfo.code.search(/^24./) != -1) {
                dataObject.proprietarySchemes.push(groupSchemeInfo.code);
              }
              if ("appSubjectBrowser" in groupSchemeInfo) {
                dataObject.appBrowsers.push(groupSchemeInfo);
              }
            }
          }
          if (groupSchemes.length != 0) {
            dataObject.currentSchemes.push(groupSchemes);
          }
        } else {
          if (!mainSubjects || schemeInfo.useAsMain) {
            if (typeof schemeInfo.code == "number") {
              schemeInfo.code = schemeInfo.code.toString();
            }
            if (schemeInfo.codeName) {
              schemeInfo.code += schemeInfo.codeName;
            }
            dataObject.currentSchemes.push(schemeInfo);
            if (schemeInfo.textOnly) {
              dataObject.textSchemes.push(schemeInfo.code);
            }
            if (schemeInfo.code.search(/^24./) != -1) {
              dataObject.proprietarySchemes.push(schemeInfo.code);
            } else if (schemeInfo.code == "XA") {
              dataObject.amazonAdvantageUK = true;
            }
            if ("appSubjectBrowser" in schemeInfo) {
              dataObject.appBrowsers.push(schemeInfo);
            }
          }
        }
      }

      // check for any proprietary subjects without a scheme entry
      for (let i = 0; i < subjects.length; i++) {
        if (subjects[i].getScheme == "24" && subjects[i].getSchemeName) {
          let scheme = "24" + subjects[i].getSchemeName;
          if (dataObject.proprietarySchemes.indexOf(scheme) == -1) {
            dataObject.currentSchemes.push({
              code: "24" + subjects[i].getSchemeName,
              codeName: subjects[i].getSchemeName,
              name: subjects[i].getSchemeName + " (Proprietary Scheme)",
              useAsMain: false,
              textOnly: false,
              listSuffix: null,
            });
            dataObject.proprietarySchemes.push(scheme);
          }
        }
      }

      // merge appBrowsers and browsers
      for (let i = 0; i < dataObject.appBrowsers.length; i++) {
        dataObject.appBrowsers[i].jsCode = null;
        for (let j = 0; j < browsers.length; j++) {
          if (
            browsers[j].scheme == dataObject.appBrowsers[i].code &&
            browsers[j].jsCode
          ) {
            dataObject.appBrowsers[i].jsCode = browsers[j].jsCode;
            break;
          }
        }
      }

      return dataObject;
    },

    /**
     * Add a subject
     * Note: we don't notify of changes as we have added a subject entry with no scheme selected
     */
    addSubject: function () {
      this.currentSubjects.push({
        getScheme: "",
        getSchemeName: null,
        getCode: "",
        getHeading: "",
        getVersion: null,
        scheme: "",
      });
    },

    /**
     * Move a subject up
     */
    moveSubjectUp: function (index) {
      if (index >= 1) {
        let subject = this.currentSubjects.splice(index, 1);
        this.currentSubjects.splice(index - 1, 0, subject[0]);
        this.notifyChanges();
      }
    },

    /**
     * Move a subject down
     */
    moveSubjectDn: function (index) {
      if (index < this.currentSubjects.length - 1) {
        let subject = this.currentSubjects.splice(index, 1);
        this.currentSubjects.splice(index + 1, 0, subject[0]);
        this.notifyChanges();
      }
    },

    /**
     * Delete a subject
     */
    deleteSubject: function (index) {
      this.currentSubjects.splice(index, 1);
      this.notifyChanges();
    },

    /**
     * Select a subject term
     */
    subjectSelect: function (index) {
      // scheme
      let scheme = this.currentSubjects[index].scheme;
      let code = this.currentSubjects[index].getCode;
      let version = this.currentSubjects[index].getVersion;

      // text scheme
      if (this.textSchemes.indexOf(scheme) != -1) {
        this.$bvModal.msgBoxOk(
          "This subject's scheme uses text values therefore item selection is not available"
        );
        return;
      }

      // BIC scheme
      if (scheme >= "12" && scheme <= "17") {
        if (version == "1" || version == "1.0" || version == "1.1") {
          version = "1.1";
        } else if (version == "2" || version == "2.0") {
          version = "2.0";
        } else if (version == "2.1") {
          version = "2.1";
        } else {
          version = this.versions.BIC.def
            ? this.versions.BIC.def
            : this.versions.BIC.cur;
        }
        if (scheme == "17" && version == "1.1") version = "2.0";

        this.$refs.browser.show(scheme, version, code, index);
        return;
      }
      if (scheme == "21") {
        this.$refs.browser.show("21", "", code, index);
        return;
      }

      // BISAC subject scheme
      if (scheme == "10") {
        let versionNum = parseInt(version, 10);
        if (!this.checkBisacVersion(versionNum)) {
          if (this.versions.BISAC.def) {
            version = this.versions.BISAC.def;
          } else {
            version = this.versions.BISAC.cur;
            for (let i = 0; i < this.currentSubjects.length; i++) {
              if (
                i != index &&
                this.currentSubjects[i].scheme == "10" &&
                this.currentSubjects[i].getCode != "" &&
                this.currentSubjects[i].getVersion != ""
              ) {
                if (
                  this.checkBisacVersion(
                    parseInt(this.currentSubjects[i].getVersion, 10)
                  )
                ) {
                  version = this.currentSubjects[i].getVersion;
                  break;
                }
              }
            }
          }
        }

        this.$refs.browser.show(scheme, version, code, index);
        return;
      }

      // BISAC geographical/merchandising scheme/NUR subject scheme/Hammicks Codes/STL Codes
      if (
        scheme == "11" ||
        scheme == "22" ||
        scheme == "32" ||
        scheme == "24Hammicks Legal" ||
        scheme == "24STL"
      ) {
        this.$refs.browser.show(scheme, "", code, index);
        return;
      }

      // Thema scheme
      if (scheme >= "93" && scheme <= "99") {
        let versionNum = parseFloat(version);
        if (!this.checkThemaVersion(versionNum)) {
          if (this.versions.THEMA.def) {
            version = this.versions.THEMA.def;
          } else {
            version = this.versions.THEMA.cur;
            for (let i = 0; i < this.currentSubjects.length; i++) {
              if (
                i != index &&
                this.currentSubjects[i].scheme == scheme &&
                this.currentSubjects[i].getCode != "" &&
                this.currentSubjects[i].getVersion != ""
              ) {
                if (
                  this.checkThemaVersion(
                    parseFloat(this.currentSubjects[i].getVersion)
                  )
                ) {
                  version = this.currentSubjects[i].getVersion;
                  break;
                }
              }
            }
          }
        }

        this.$refs.browser.show(scheme, version, code, index);
        return;
      }

      // Amazon Advantage UK internal subject category browse (if available)
      if (scheme == "XA" && this.amazonAdvantageUK) {
        this.$refs.browser.show(scheme, "", code, index);
        return;
      }

      // publisher's own/proprietary/internal schemes with subject browser
      for (let i = 0; i < this.appBrowsers.length; i++) {
        if (this.appBrowsers[i].code == scheme) {
          if (this.appBrowsers[i].jsCode) {
            let jsCode = this.appBrowsers[i].jsCode;
            let jsFunc = new Function(jsCode);
            jsFunc();
          } else {
            this.$refs.browser.show(scheme, "", code, index);
          }
          return;
        }
      }

      // no selector available
      this.$bvModal.msgBoxOk(
        "No item selection is available for this subject's scheme"
      );
    },

    /**
     * A subject has been selected
     */
    subjectSelected: function (scheme, version, code, text, index) {
      this.currentSubjects[index].getCode = code;
      this.currentSubjects[index].getHeading = text;
      this.currentSubjects[index].getVersion = version;
      this.notifyChanges();
    },

    /**
     * Is the specified subject a text-only subject
     */
    isTextSubject: function (scheme) {
      return this.textSchemes.indexOf(scheme) != -1;
    },

    /**
     * Check a BISAC version is valid
     */
    checkBisacVersion: function (versionNum) {
      if (!isNaN(versionNum)) {
        return (
          versionNum >= 2007 &&
          versionNum <= this.versions.BISAC.cur &&
          Math.floor(versionNum) == versionNum
        );
      }
      return false;
    },

    /**
     * Check a THEMA version is valid
     */
    checkThemaVersion: function (versionNum) {
      if (!isNaN(versionNum)) {
        return (
          versionNum >= 1 &&
          versionNum <= this.versions.THEMA.cur &&
          Math.floor(10 * versionNum) == 10 * versionNum
        );
      }
      return false;
    },

    /**
     * A subject's scheme has been selected
     */
    selectedScheme: function (index, control) {
      let newScheme = control.value;
      this.currentSubjects[index].scheme = newScheme;
      this.currentSubjects[index].getScheme = newScheme.substr(0, 2);
      this.currentSubjects[index].getSchemeName =
        newScheme.length >= 3 ? newScheme.substr(2) : null;

      // hide code edit box if a text-only scheme
      let textOnlyScheme = false;
      for (let i = 0; i < this.currentSchemes.length; i++) {
        if (this.currentSchemes[i].code == newScheme) {
          textOnlyScheme = this.currentSchemes[i].textOnly;
          break;
        }
      }
      document.getElementById(
        "bibliosubjectsedit-code-" + index
      ).style.display = textOnlyScheme ? "none" : "";

      // if main subjects then check that this is the only entry with this scheme
      if (this.mainSubjects && newScheme != "") {
        for (let i = 0; i < this.currentSubjects.length; i++) {
          if (i !== index) {
            if (this.currentSubjects[i].scheme == newScheme) {
              // this scheme is invalid
              this.$bvModal
                .msgBoxOk(
                  "Only one main subject is allowed for a subject scheme."
                )
                .then(() => {
                  document.getElementById(
                    "bibliosubjectsedit-scheme-" + index
                  ).selectedIndex = 0;
                });
              return;
            }
          }
        }
      }

      // for proprietary scheme (without a name), ask for a name for this new custom scheme
      if (newScheme == "24") {
        this.newCustomScheme(index, control);
      }

      this.notifyChanges();
    },

    /**
     * Ask for a new custom scheme name
     */
    newCustomScheme: function (index, control) {
      // prompt for the scheme name, if cancelled then dropout deselecting proprietary subject scheme
      let schemeName;
      for (;;) {
        schemeName = prompt(
          "Please enter a name for your new proprietary subject scheme."
        );
        if (schemeName == null) {
          control.selectedIndex = 0;
          return;
        }
        if (schemeName.length != 0) break;

        this.$bvModal.msgBoxOk(
          "You must specify a name for your new proprietary subject scheme."
        );
      }

      // check if this name is already in use
      let scheme = "24" + schemeName;
      if (this.proprietarySchemes.indexOf(scheme) != -1) {
        for (let i = 0; i < control.options.length; i++) {
          if (control.options[i].value == scheme) {
            control.selectedIndex = i;
            return;
          }
        }
      }

      // add the new scheme to all additional subject scheme lists
      this.currentSchemes.push({
        code: scheme,
        codeName: schemeName,
        name: schemeName + " (Proprietary Scheme)",
        useAsMain: false,
        textOnly: false,
        listSuffix: null,
      });
      this.proprietarySchemes.push(scheme);

      // select the new scheme
      this.currentSubjects[index].scheme = scheme;
      this.currentSubjects[index].getScheme = "24";
      this.currentSubjects[index].getSchemeName = schemeName;
    },

    /**
     * A subject's code has been changed
     */
    changedCode: function (index, control) {
      this.currentSubjects[index].getCode = control.value;
      this.notifyChanges();
    },

    /**
     * A subject's heading has been changed
     */
    changedHeading: function (index, control) {
      this.currentSubjects[index].getHeading = control.value;
      this.notifyChanges();
    },

    /**
     * notify of changed subjects
     */
    notifyChanges: function () {
      // select subject entries with a scheme and code/heading
      let subjects = [];
      for (let i = 0; i < this.currentSubjects.length; i++) {
        if (this.currentSubjects[i].scheme != "") {
          let textOnly = this.isTextSubject(this.currentSubjects[i].scheme);
          if (
            (!textOnly && this.currentSubjects[i].getCode != "") ||
            this.currentSubjects[i].getHeading != ""
          ) {
            subjects.push(this.currentSubjects[i]);
          }
        }
      }
      this.$emit("changed", subjects);
    },
  },
};
</script>

<style>
.bibliosubjectsedit-header {
  margin-bottom: 0.5rem;
}
.bibliosubjectsedit-add {
  margin-left: 1rem;
}
.bibliosubjectsedit-scheme {
  width: 95%;
}
.bibliosubjectsedit-code {
  width: 88%;
}
.bibliosubjectsedit-textonly {
  display: none;
}
.bibliosubjectsedit-heading {
  width: 97%;
}
.bibliosubjectsedit-version {
  width: 85%;
  color: #606060;
}
.bibliosubjectsedit-btns {
  text-align: right;
}
</style>
