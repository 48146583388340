<template>
  <div class="row">
    <div class="col-sm-6 col-lg-3">
      <b-button
        class="my-1 w-100"
        variant="outline-primary"
        :href="$baseUrl + 'contacts/edit/0' + linkSuffix"
        >New Contact</b-button
      >
    </div>
    <div class="col-sm-6 col-lg-3">
      <b-button
        class="my-1 w-100"
        variant="outline-primary"
        :href="$baseUrl + 'contacts/edit/' + contactId + linkSuffix"
        >Edit Contact</b-button
      >
    </div>
    <div class="col-sm-6 col-lg-3">
      <b-button
        class="my-1 w-100"
        variant="outline-danger"
        @click.prevent="$emit('deleteClicked')"
        >Delete Contact</b-button
      >
    </div>
  </div>
</template>

<script>
/**
 * Displays a button grid for shortcut buttons
 * to edit the contact
 */
export default {
  name: "ContactEditButtons",
  props: {
    /**
     * The id of the contact
     */
    contactId: {
      type: [String, Number],
      required: true,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },
  },

  computed: {
    linkSuffix: function () {
      return this.returnTo ? "?return=" + this.returnTo : "";
    },
  },
};
</script>

<style></style>
