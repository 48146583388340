import { render, staticRenderFns } from "./ProductSummaryCard.vue?vue&type=template&id=0143842c&scoped=true&"
import script from "./ProductSummaryCard.vue?vue&type=script&lang=js&"
export * from "./ProductSummaryCard.vue?vue&type=script&lang=js&"
import style0 from "./ProductSummaryCard.vue?vue&type=style&index=0&id=0143842c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0143842c",
  null
  
)

export default component.exports