<template>
  <b-modal
    id="preferencesmodal"
    title="Preferences"
    centered
    ok-variant="success"
    cancel-variant="danger"
    @show="loadPrefs"
    @ok="savePrefs"
  >
    <div class="row">
      <div class="col-12">
        <b>Display</b>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Result&nbsp;list&nbsp;records</div>
      <div class="col-9">
        <input
          id="preferencesmodal_reslistrecs_1"
          name="resultlistrecords"
          form="preferencesform"
          type="radio"
          value="10"
        />
        <label for="preferencesmodal_reslistrecs_1">10</label>
        <input
          id="preferencesmodal_reslistrecs_2"
          name="resultlistrecords"
          form="preferencesform"
          type="radio"
          value="20"
        />
        <label for="preferencesmodal_reslistrecs_2">20</label>
        <input
          id="preferencesmodal_reslistrecs_3"
          name="resultlistrecords"
          form="preferencesform"
          type="radio"
          value="30"
        />
        <label for="preferencesmodal_reslistrecs_3">30</label>
        <input
          id="preferencesmodal_reslistrecs_4"
          name="resultlistrecords"
          form="preferencesform"
          type="radio"
          value="50"
        />
        <label for="preferencesmodal_reslistrecs_4">50</label>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Index terms</div>
      <div class="col-9">
        <input
          id="preferencesmodal_indexterms_1"
          name="indexterms"
          form="preferencesform"
          type="radio"
          value="10"
        />
        <label for="preferencesmodal_indexterms_1">10</label>
        <input
          id="preferencesmodal_indexterms_2"
          name="indexterms"
          form="preferencesform"
          type="radio"
          value="20"
        />
        <label for="preferencesmodal_indexterms_2">20</label>
        <input
          id="preferencesmodal_indexterms_3"
          name="indexterms"
          form="preferencesform"
          type="radio"
          value="30"
        />
        <label for="preferencesmodal_indexterms_3">30</label>
        <input
          id="preferencesmodal_indexterms_4"
          name="indexterms"
          form="preferencesform"
          type="radio"
          value="50"
        />
        <label for="preferencesmodal_indexterms_4">50</label>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Date format</div>
      <div class="col-9">
        <input
          id="preferencesmodal_dateformat_1"
          name="dateformat"
          form="preferencesform"
          type="radio"
          value="dmy"
        />
        <label for="preferencesmodal_dateformat_1">D-M-Y</label>
        <input
          id="preferencesmodal_dateformat_2"
          name="dateformat"
          form="preferencesform"
          type="radio"
          value="mdy"
        />
        <label for="preferencesmodal_dateformat_2">M-D-Y</label>
        <input
          id="preferencesmodal_dateformat_3"
          name="dateformat"
          form="preferencesform"
          type="radio"
          value="ymd"
        />
        <label for="preferencesmodal_dateformat_3">Y-M-D</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b>Other</b>
      </div>
    </div>
    <div ref="changepwdlink" class="row">
      <div class="col-12">
        <br />
        <button class="btn btn-outline-primary" @click="showChangePassword">
          Change Password
        </button>
      </div>
    </div>
    <div ref="changepwdctrls" style="display: none">
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label for="preferencesmodal_changepwd_curr">Current Password</label>
          <br />
          <input
            id="preferencesmodal_changepwd_curr"
            ref="changepwd_current"
            form="preferencesform"
            type="password"
            value=""
            style="width: 80%"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label for="preferencesmodal_changepwd_new1">New Password</label>
          <br />
          <input
            id="preferencesmodal_changepwd_new1"
            ref="changepwd_new1"
            form="preferencesform"
            type="password"
            value=""
            style="width: 80%"
            autocomplete="off"
            @input="newPasswordInput(1)"
          />
          <div
            id="newpassword_s"
            class="newpassword_s preferences"
            title="Password strength indication"
          >
            &#160;
          </div>
        </div>
        <div class="col-6">
          <label for="preferencesmodal_changepwd_new2">
            Confirm New Password
          </label>
          <br />
          <input
            id="preferencesmodal_changepwd_new2"
            ref="changepwd_new2"
            form="preferencesform"
            type="password"
            value=""
            style="width: 80%"
            autocomplete="off"
            @input="newPasswordInput(2)"
          />
          <img
            id="newpassword_m"
            src="/common/images/check2.png"
            class="newpassword_m"
            title="New and confirmed passwords match"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
/**
 * Preferences modal
 */
import { HTTP } from "../../http-common.js";

export default {
  name: "PreferencesModal",

  methods: {
    /**
     * Load preferences
     */
    loadPrefs() {
      // get the user's preferences
      let url = this.$baseUrl + "preferences/get";
      HTTP.get(url).then((response) => {
        // update the controls
        for (let i = 1; i <= 4; i++) {
          let ctrl = document.getElementById(
            "preferencesmodal_reslistrecs_" + i
          );
          ctrl.checked = ctrl.value == response.data.resultListRecords;
        }
        for (let i = 1; i <= 4; i++) {
          let ctrl = document.getElementById(
            "preferencesmodal_indexterms_" + i
          );
          ctrl.checked = ctrl.value == response.data.indexTerms;
        }
        for (let i = 1; i <= 3; i++) {
          let ctrl = document.getElementById(
            "preferencesmodal_dateformat_" + i
          );
          ctrl.checked = ctrl.value == response.data.dateFormat;
        }
      });
    },

    /**
     * Save preferences
     */
    savePrefs(event) {
      event.preventDefault();
      // build POST data
      let postData = new URLSearchParams();
      let newValues = {};
      for (let i = 1; i <= 4; i++) {
        let ctrl = document.getElementById("preferencesmodal_reslistrecs_" + i);
        if (ctrl.checked) {
          postData.append("resultlistrecords", ctrl.value);
          newValues.resultListRecords = parseInt(ctrl.value, 10);
          break;
        }
      }
      for (let i = 1; i <= 4; i++) {
        let ctrl = document.getElementById("preferencesmodal_indexterms_" + i);
        if (ctrl.checked) {
          postData.append("indexterms", ctrl.value);
          newValues.indexTerms = parseInt(ctrl.value, 10);
          break;
        }
      }
      for (let i = 1; i <= 3; i++) {
        let ctrl = document.getElementById("preferencesmodal_dateformat_" + i);
        if (ctrl.checked) {
          postData.append("dateformat", ctrl.value);
          newValues.dateFormat = ctrl.value;
          break;
        }
      }

      // check for a password change
      if (this.$refs.changepwdctrls.style.display == "block") {
        let oldPassword = this.$refs.changepwd_current.value;
        let newPassword1 = this.$refs.changepwd_new1.value;
        let newPassword2 = this.$refs.changepwd_new2.value;

        if (oldPassword.length == 0) {
          alert("You have not entered your current password");
          return;
        }
        if (newPassword1.length == 0) {
          alert("You have not entered a new password");
          return;
        }
        if (newPassword1 != newPassword2) {
          alert("The new and confirmed passwords do not match.");
          return;
        }

        postData.append("password", oldPassword);
        postData.append("newpassword", newPassword1);
      }

      // send data to the server
      HTTP.post(this.$baseUrl + "preferences/update", postData)
        .then(() => {
          this.$bvModal.hide("preferencesmodal");
          this.$emit("preferences-changed", newValues);
        })
        .catch((error) => {
          if (error.response.status == 409) {
            alert("You have not entered your password correctly");
          } else {
            alert("An error has occurred while saving your preferences");
          }
        });
    },

    /**
     * Show change password
     */
    showChangePassword() {
      // ensure that /common/newpassword.js is loaded (for NewPasswordChange2())
      if (typeof NewPasswordChange2 == "undefined") {
        let script = document.createElement("script");
        script.src =
          document.location.protocol +
          "//" +
          document.location.host +
          "/common/newpassword.js";
        document.documentElement.firstChild.appendChild(script);
      }

      // show the change password controls and hide the button
      this.$refs.changepwdlink.style.display = "none";
      this.$refs.changepwdctrls.style.display = "block";
    },

    /**
     * New password input event
     */
    newPasswordInput(which) {
      // call the NewPasswordChange2() function in /common/newpassword.js
      // eslint-disable-next-line no-undef
      NewPasswordChange2(
        this.$refs.changepwd_new1.value,
        this.$refs.changepwd_new2.value,
        which
      );
    },
  },
};
</script>

<style>
#preferencesmodal label {
  padding-left: 5px;
  padding-right: 20px;
}
</style>
