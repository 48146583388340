<template>
  <div>
    <contacts-navigationbar
      :search-id="searchId"
      :page="page"
      :pages="pages"
      :records="records"
      :page-size="pageSize"
      :format="format"
      page-label="Page"
      action="results-list"
    >
    </contacts-navigationbar>
    <contacts-menubar
      location="t"
      :search-form="searchForm"
      :has-search-form="hasSearchForm"
      :search-id="searchId"
      :page="page"
      :format="format"
      :marked-records-actions-enabled="hasMarkedRecords"
      :marked-records="markedRecords"
      @sortBtnClick="sortBtnClick"
      @markclick="markClick"
      @redisplayPage="redisplayPage"
    >
    </contacts-menubar>
    <div class="table-container">
      <table class="table table-striped table-sm contacts_resultslist mt-2">
        <resultslist-header
          :search-id="searchId"
          :all-records-marked="allMarked"
          :page="page"
          :pages="pages"
          :column-info="columnInfo"
          @markedChange="markedChange"
        >
        </resultslist-header>

        <tbody>
          <resultslist-line
            v-for="(contactData, rowNo) in contactsData"
            ref="contactLines"
            :key="contactData.id"
            :logon-id="logonId"
            :search-id="searchId"
            :record-pos="(page - 1) * pageSize + rowNo + 1"
            :record-id="contactData.id"
            :record-marked="marked[rowNo]"
            :column-info="columnInfo"
            :column-data="contactData.columns"
            :tags="contactData.tags"
            :search-form="searchForm"
            @markedChange="markedChange"
          >
          </resultslist-line>
        </tbody>
      </table>
    </div>
    <hr />
    <contacts-navigationbar
      :search-id="searchId"
      :page="page"
      :pages="pages"
      :records="records"
      :page-size="pageSize"
      :format="format"
      page-label="Page"
      action="results-list"
    >
    </contacts-navigationbar>
    <contacts-menubar
      location="b"
      :search-form="searchForm"
      :has-search-form="hasSearchForm"
      :search-id="searchId"
      :page="page"
      :format="format"
      :marked-records-actions-enabled="hasMarkedRecords"
      :marked-records="markedRecords"
      @sortBtnClick="sortBtnClick"
      @markclick="markClick"
      @redisplayPage="redisplayPage"
    >
    </contacts-menubar>
    <sort-results-modal
      ref="sortModal"
      :search-id="searchId"
      :page="page"
      :format="format"
    >
    </sort-results-modal>
  </div>
</template>

<script>
/**
 * Display the Contacts Results List page
 */
import ResultsListHeader from "../../contacts/ResultsListHeader.vue";
import ResultsListLine from "../../contacts/ResultsListLine.vue";
import NavigationBar from "../../contacts/NavigationBar.vue";
import MenuBar from "../../contacts/MenuBar.vue";
import SortResultsModal from "../../searchform/SortResultsModal.vue";
import { MarkedRecords } from "../../../MarkedRecords.js";

export default {
  name: "ContactsResultsListPage",

  components: {
    "resultslist-header": ResultsListHeader,
    "resultslist-line": ResultsListLine,
    "contacts-navigationbar": NavigationBar,
    "contacts-menubar": MenuBar,
    "sort-results-modal": SortResultsModal,
  },

  props: {
    /**
     * Logon id
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * Search form name
     */
    searchForm: {
      type: String,
      required: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      required: true,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      required: true,
    },

    /**
     * Page size
     */
    pageSize: {
      type: Number,
      required: true,
    },

    /**
     * Number of pages
     */
    pages: {
      type: Number,
      required: true,
    },

    /**
     * Number of records
     */
    records: {
      type: Number,
      required: true,
    },

    /**
     * Current format
     */
    format: {
      type: String,
      default: "",
    },

    /**
     * Column information (each entry in an object with the properties 'caption', 'width', 'sort' (optional),
     * 'dlink' (bool, optional), 'tags' (bool, optional), 'marker' (bool, optional))
     */
    columnInfo: {
      type: Array,
      required: true,
    },

    /**
     * Contacts data (each entry in an object with the properties 'id', 'columns', 'class')
     */
    contactsData: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    let dataObj = {};
    dataObj.markedRecords = new MarkedRecords(
      this.$baseUrl,
      this.searchId,
      this.records
    );
    dataObj.marked = [];
    let markedCount = 0;
    for (let i = 0; i < this.contactsData.length; i++) {
      dataObj.marked[i] = dataObj.markedRecords.isMarked(
        this.contactsData[i].id
      );
      if (dataObj.marked[i]) markedCount++;
    } // for
    dataObj.allMarked = markedCount == this.contactsData.length;
    dataObj.hasMarkedRecords = dataObj.markedRecords.getNumMarked() != 0;
    return dataObj;
  },

  computed: {
    hasSearchForm: function () {
      return this.searchFormType !== "quick";
    },
  },

  methods: {
    /**
     * A marked record check box has been clicked
     * @param int|null contactId  The id of the record whose marked tick box was clicked;
     *                            null if the header row marked tick box was clicked
     */
    markedChange: function (contactId, checked) {
      if (contactId) {
        // mark/unmark the specified record
        this.markedRecords.setMarked(contactId, checked);
      } else {
        // mark/unmark all records on the page
        for (let i = 0; i < this.contactsData.length; i++) {
          this.markedRecords.setMarked(this.contactsData[i].id, checked);
        } // for
      }
      this.updateMarkState(false);
    },

    /**
     * The sort menu item has been clicked
     */
    sortBtnClick(event) {
      event.preventDefault();
      this.$refs.sortModal.showModal();
    },

    /**
     * A mark menu item has been clicked
     */
    markClick(event, action) {
      event.preventDefault();
      switch (action) {
        case "all":
          this.markedRecords.markAll(() => {
            this.updateMarkState(true);
          });
          break;
        case "none":
          this.markedRecords.clearAll(() => {
            this.updateMarkState(true);
          });
          break;
        case "reverse":
          this.markedRecords.invertAll(() => {
            this.updateMarkState(true);
          });
          break;
      }
    },

    /**
     * Update the mark state held in local data
     */
    updateMarkState(updateLineControls) {
      let markedCount = 0;
      for (let i = 0; i < this.contactsData.length; i++) {
        this.marked[i] = this.markedRecords.isMarked(this.contactsData[i].id);
        if (this.marked[i]) markedCount++;
      } // for
      this.allMarked = markedCount == this.contactsData.length;
      this.hasMarkedRecords = this.markedRecords.getNumMarked() != 0;

      if (updateLineControls) {
        for (let i = 0; i < this.contactsData.length; i++) {
          this.$refs.contactLines[i].recordMarked = this.markedRecords.isMarked(
            this.$refs.contactLines[i].recordId
          );
        } // for
      }
    },

    /**
     * Redisplay the current page
     */
    redisplayPage() {
      location.href =
        this.$baseUrl +
        "contacts/results-list/" +
        this.searchId +
        "/" +
        this.page +
        (this.format != "" ? "/" + this.format : "");
    },
  },
};
</script>

<style>
.table-container {
  overflow-x: auto;
}
</style>
