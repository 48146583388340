import Vue from "vue";
import $ from "jquery";
import jquery from "jquery";
import VueTour from "vue-tour";
import "bootstrap";
import { BootstrapVue } from "bootstrap-vue";
import _get from "lodash/get";

Vue.use(BootstrapVue);
Vue.use(VueTour);

// import all pages and global components
import "./components/base";
import "./components/pages";
import "./components/searchform";
import "./components/icons";
import "./components/utility";
import "./components/apiToken";
import "./sass/app.scss";
import "./assets/popper.css";
import UserPermissions from "./mixins/userPermissions.js";

Vue.mixin(UserPermissions);

/**
 * To use jQuery functions (or plain JS functions that exist outside of Vue) this prototype method
 * must be used.
 *
 * When binding an event handler such as on a @input or @click event Vue won't have access to the
 * jQuery method, so $jqueryFunction should be used as the event handler instead, with the callback
 * parameter being the name of the jQuery method.
 *
 * Vue's $event data as the second parameter.
 *
 * Examples:
 * <vue-component @input="$jqueryFunction('jqueryFunctionName', $event)"></vue-component>
 *
 * @param {String} callback The name of the method in the window object that should be called
 * @param {Object} event $event data passed by Vue
 * @param {Array} options Any additional data that needs to be sent to the jQuery method
 * @returns
 */
Vue.prototype.$jqueryFunction = function (callback, event, options) {
  if (_get(window, callback) && typeof _get(window, callback) === "function") {
    return _get(window, callback)(event ? event : null, options);
  }
  return false;
};

/**
 * Allow jQuery to be called externally
 */
window.jQuery = jquery;
window.$ = jquery;

/**
 * Prevent jQuery document ready from running until the Vue app
 * has mounted. Otherwise the mounting of Vue will remove all
 * jQuery event listeners.
 */
$.holdReady(true);

window.onload = function () {
  // set globals
  Vue.prototype.$appId = $("#appId").val();
  Vue.prototype.$baseUrl = "/" + Vue.prototype.$appId + "/application/";
  Vue.prototype.$userPermissions = JSON.parse(
    $("#vueUserPermissions").val() ?? "null"
  );
  Vue.prototype.$enabledFeatures = JSON.parse(
    $("#vueEnabledFeatures").val() ?? "null"
  );
  Vue.prototype.$userCat = $("#vueUserCat").val();
  Vue.prototype.$userPreferences = {
    dateFormat: $("#vueDateFormat").val(),
  };
  Vue.prototype.$logonId = $("#vueLogonId").val();
  Vue.prototype.$user = JSON.parse($("#vueUser").val() ?? "null");

  /**
   * Vue instance is created and mounted to #vueapp
   * This is in default.ctp (and other layout files)
   * and acts as the app template for Vue
   */
  var vueApp = new Vue({
    mounted() {
      // allow jQuery document ready to run
      $.holdReady(false);
    },
  }).$mount("#vueapp");
  window.vueApp = vueApp;
  /**
   * Vue is added to the window object so it is global.
   * This is so $refs can be used to allow Vue to communicate
   * and share variables with traditional JS in BooksoniX such
   * as jQuery.
   */

  // initialise jQuery / Bootstrap tooltips & popovers
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover({
    html: true,
  });
};
