<template>
  <component
    :is="href != null ? 'a' : 'li'"
    :href="href"
    class="list-group-item list-group-item-action"
  >
    <slot>{{ text }}</slot
    ><i v-if="icon" :class="'fa-' + icon" class="fa"></i>
  </component>
</template>

<script>
/**
 * List item in a panel
 */
export default {
  name: "ListPanelItem",

  props: {
    /**
     * Text that will be displayed in the list panel
     */
    text: {
      type: String,
      default: null,
    },

    /**
     * Name of the font awesome icon to display in the item
     *
     * Name should not include the 'fa-' prefix
     */
    icon: {
      type: String,
      default: null,
    },

    /**
     * Destination of href when item is used as a link
     *
     * If href is set then the onClick prop will not have
     * any effect.
     */
    href: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.fa {
  float: right;
}
</style>
