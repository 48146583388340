<template>
  <tr class="contacts_resultslist_line">
    <template v-for="(column, columnNo) in columnData">
      <td
        :key="columnNo"
        :class="
          typeof column == 'object' && 'imageType' in column
            ? 'contacts_resultslist_image'
            : columnInfo[columnNo].marker
            ? 'contacts_resultslist_mark'
            : null
        "
      >
        <input
          v-if="columnInfo[columnNo].marker"
          v-model="recordMarked"
          type="checkbox"
          @click="$emit('markedChange', recordId, $event.target.checked)"
        />
        <a
          v-if="columnInfo[columnNo].dlink && recordId"
          :href="
            $baseUrl +
            'contacts/detailed/' +
            recordId +
            '/' +
            searchId +
            '/' +
            recordPos
          "
        >
          <template v-if="typeof column == 'object' && 'imageType' in column">
            <img
              v-if="column.imageType == 'localImage'"
              :src="column.url + '?L=' + logonId + '&H50W50B'"
              class="contacts_resultslist_image"
              onerror="this.onerror=null;this.src='/common/images/noimage.png'"
            />
            <img
              v-else
              :src="column.url"
              class="contacts_resultslist_image"
              onerror="this.onerror=null;this.src='/common/images/noimage.png'"
            />
          </template>
          <template v-else>
            {{ column }}
          </template>
        </a>
        <template v-else>
          <template v-if="typeof column == 'object' && 'imageType' in column">
            <img
              v-if="column.imageType == 'localImage'"
              :src="column.url + '?L=' + logonId + '&H50W50B'"
              class="contacts_resultslist_image"
              onerror="this.onerror=null;this.src='/common/images/noimage.png'"
            />
            <img
              v-else
              :src="column.url"
              class="contacts_resultslist_image"
              onerror="this.onerror=null;this.src='/common/images/noimage.png'"
            />
          </template>
          <template v-else-if="columnInfo[columnNo].emailLinks">
            <a
              v-if="column != ''"
              :href="'mailto:' + column"
              title="Create an email for this contact's address(es)"
            >
              <img src="/common/images/email.png" border="0" />
            </a>
          </template>
          <template v-else-if="columnInfo[columnNo].notesIcon">
            <img
              v-if="column == 'Y'"
              src="/common/images/notes.png"
              border="0"
              title="Contact has notes"
            />
          </template>
          <template v-else>
            {{ column }}
          </template>
        </template>
        <resultslist-tags v-if="columnInfo[columnNo].tags" :tags="tags">
        </resultslist-tags>
      </td>
    </template>
  </tr>
</template>

<script>
/**
 * Display the Contacts Results List Line
 */
import ResultsListTags from "./ResultsListTags.vue";

export default {
  name: "ResultsListLine",

  components: {
    "resultslist-tags": ResultsListTags,
  },

  props: {
    /**
     * Logon id
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      required: true,
    },

    /**
     * Record position (in results list)
     */
    recordPos: {
      type: Number,
      required: true,
    },

    /**
     * Record Id
     */
    recordId: {
      type: Number,
      required: true,
    },

    /**
     * Record is marked
     */
    recordMarked: {
      type: Boolean,
      default: false,
    },

    /**
     * Column information (each entry in an object with the properties 'caption', 'width', 'sort' (optional),
     * 'dlink' (bool, optional), 'tags' (bool, optional), 'marker' (bool, optional))
     */
    columnInfo: {
      type: Array,
      required: true,
    },

    /**
     * Column data
     */
    columnData: {
      type: Array,
      required: true,
    },

    /**
     * Tags data
     */
    tags: {
      type: Array,
      default: () => [],
    },
    /**
     * Search form name
     */
    searchForm: {
      type: String,
      default: "contacts",
    },
  },
};
</script>

<style>
tr.contacts_resultslist_line td {
  padding: 0 0.25em;
}
tr.contacts_resultslist_line td.contacts_resultslist_mark {
  padding-top: 1px;
}
td.contacts_resultslist_image {
  text-align: center;
}
img.contacts_resultslist_image {
  border: 0;
  height: 50px;
  margin: 5px;
}
img.contacts_resultslist_image_pdf {
  border: 0;
  height: 32px;
  width: 32px;
  margin: 5px;
}
</style>
