<template>
  <ui-well>
    <div v-if="filterCount > 0">
      <div class="row">
        <div v-if="!!errors.filter" class="col-12">
          <base-help-text class="mt-3 is-invalid">{{
            errors.filter
          }}</base-help-text>
        </div>
        <div
          v-for="(field, index) in filters"
          :key="index"
          class="col-12"
          :class="{
            'col-md-4 col-lg-3': field.type != 'date',
            'col-md-6': field.type == 'date',
          }"
        >
          <div class="form-group">
            <label>{{ field.text }}</label>
            <icon-btn
              icon="times"
              colour="info"
              class="float-right"
              @click="removeFilter(index)"
            ></icon-btn>
            <input
              v-if="!field.type || field.type == 'text'"
              :id="'filter-' + field.text + '-' + index"
              v-model="field.search"
              type="text"
              class="form-control"
              :disabled="field.blankOnly"
              :placeholder="'Search ' + field.text"
              @input="onInput($event, field)"
            />
            <div v-if="field.type == 'dropdown'">
              <base-select
                v-model="field.search"
                :options="dropdownOptions[field.key]"
                :placeholder="'Pick a ' + field.text"
                :disabled="field.blankOnly"
                :multiple="true"
                :clearable="false"
                @input="filterUpdated(field)"
              >
              </base-select>
            </div>
            <div v-if="field.type == 'number'">
              <form class="form-inline">
                <div class="form-group" style="width: 47%">
                  <label
                    class="sr-only"
                    :for="'filter-' + field.text + '-from' + '-' + index"
                    >{{ field.text }} From</label
                  >
                  <input
                    :id="'filter-' + field.text + '-from' + '-' + index"
                    v-model="field.from"
                    type="number"
                    placeholder="From"
                    :disabled="field.blankOnly"
                    class="form-control input-number"
                    @input="onInputFrom($event, field)"
                  />
                </div>
                -
                <div class="form-group" style="width: 47%">
                  <label
                    class="sr-only"
                    :for="'filter-' + field.text + '-to' + '-' + index"
                    >{{ field.text }} To</label
                  >
                  <input
                    :id="'filter-' + field.text + '-to' + '-' + index"
                    v-model="field.to"
                    type="number"
                    class="form-control input-number"
                    placeholder="To"
                    :disabled="field.blankOnly"
                    @input="onInputTo($event, field)"
                  />
                </div>
              </form>
            </div>
            <div v-if="field.type == 'date'">
              <date-range-picker
                v-model="field.value"
                :date-format="dateFormat"
                :disabled="field.blankOnly"
                @input-start="field.start = arguments[0]"
                @input-end="field.end = arguments[0]"
                @input="filterUpdated(field)"
              ></date-range-picker>
            </div>
            <form class="form-inline">
              <div v-if="field.type != 'dropdown'" class="mt-1">
                <label
                  ><input
                    v-model="field.blankOnly"
                    type="checkbox"
                    class="mr-1"
                    @change="filterUpdated(field)"
                  />
                  <template v-if="field.type == 'number'">Empty</template
                  ><template v-else>Blank</template> values only
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div class="row">
      <div class="col-12">
        <add-filter
          :fields="enabledFields"
          :disabled="inputsDisabled"
          @field-selected="addFilter"
        ></add-filter>
        <save-filter
          v-if="filterSaveUrl"
          :name="activeFilterName"
          :disabled="inputsDisabled || filterCount == 0"
          :has-existing-filter="
            activeFilter != null &&
            'report_filter_id' in activeFilter &&
            activeFilter.report_filter_id != null
          "
          :saved-filters="savedFilters"
          :active-filter="activeFilter"
          class="ml-2"
          @save-clicked="saveFilters"
          @name-updated="nameUpdated"
          @global-updated="globalUpdated"
        ></save-filter>
        <base-button
          v-if="activeFilter && filterSaveUrl"
          class="ml-2"
          variant="danger"
          @click="deleteFilter"
        >
          Delete Saved Filters
        </base-button>
        <base-button
          v-if="filterCount > 0"
          :disabled="inputsDisabled"
          class="ml-2"
          @click="clearFilters"
        >
          Clear Filters
        </base-button>
        <load-filter
          v-if="filterListUrl"
          class="ml-2"
          :disabled="inputsDisabled || savedFilters.length == 0"
          :saved-filters="savedFilters"
          @filter-selected="activeFilterChanged"
        ></load-filter>
        <save-indicator
          class="ml-2"
          error-message="Failed to save filter"
          :status="saveState"
          @status-cleared="saveState = null"
        ></save-indicator>
      </div>
      <div v-if="filterSaveUrl" class="col-12">
        <base-help-text v-show="notice" class="mt-3 is-invalid">{{
          notice
        }}</base-help-text>
      </div>
      <div v-if="applyingFilters" class="col-12">
        <loading-spinner
          class="mt-3"
          text="Applying Filters..."
        ></loading-spinner>
      </div>
    </div>
  </ui-well>
</template>

<script>
/**
 * Renders a list of saveable filters
 * when provided with a list of filterable
 * fields.
 */

import { HTTP } from "../../../http-common.js";
import Well from "../../ui/Well.vue";
import AddFilter from "./AddFilter.vue";
import SaveFilter from "./SaveFilter.vue";
import LoadFilter from "./LoadFilter.vue";
import _uniq from "lodash/uniq";
import _debounce from "lodash/debounce";
import _compact from "lodash/compact";
import _cloneDeep from "lodash/cloneDeep";
import SaveIndicator from "../../ui/SaveIndicator.vue";
import DateRangePicker from "../../utility/DateRangePicker.vue";
import { DateTime } from "luxon";

export default {
  name: "DataFilters",

  components: {
    "add-filter": AddFilter,
    "save-filter": SaveFilter,
    "load-filter": LoadFilter,
    "ui-well": Well,
    "save-indicator": SaveIndicator,
    "date-range-picker": DateRangePicker,
  },

  props: {
    /**
     * The fields which can be filtered.
     * A filter for each field can be added to
     * the filter list.
     *
     * The fields array should be an
     * array of objects, with each object containing the documented properties.
     *
     * @property {String} text The text which will display as the filterable field
     * @property {String} key The data key for the field. Used in the parent component
     *                        to determine which data should be filtered
     * @property {String} [type] Data type of the column, 'String', 'Number', 'Dropdown', 'Disabled' or 'Date', default 'String'
     *                           Setting the type to disabled with not allow filters for that column
     *
     * @example [{text: 'Name', key: 'user_name'}, {text: 'DoB', key: 'user_dob', type: Date}]
     */
    fields: {
      type: Array,
      default: () => [],
    },

    /**
     * Data array as defined in the table component
     * Used to calculate unique values to fill dropdowns
     */
    data: {
      type: Array,
      default: () => [],
    },

    /**
     * URL that returns a list of saved filters
     */
    filterListUrl: {
      type: String,
      default: null,
    },

    /**
     * URL that saves a filter
     */
    filterSaveUrl: {
      type: String,
      default: null,
    },

    /**
     * URL that deletes a filter
     */
    filterDeleteUrl: {
      type: String,
      default: null,
    },

    /**
     * Date format to be used for date filters
     */
    dateFormat: {
      type: String,
      default: "yyyy-MM-dd",
    },

    /**
     * Flag to indicate whether filters are curently applying
     */
    applyingFilters: {
      type: Boolean,
      default: false,
    },

    /**
     * Dropdown options which are passed in from the
     * base table. Used in circumstances where the options
     * are provided by the table due to it using backend pagination.
     *
     * Any options set in this prop will overrite the calculated
     * options in this component
     */
    backendDropdownOptions: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      filters: [],
      savedFilters: [],
      activeFilter: null,
      name: "",
      global: false,
      errors: {
        name: "",
        filter: "",
      },
      notice: "",
      saveState: null,
    };
  },

  computed: {
    /**
     * Filters out disabled fields
     */
    enabledFields: function () {
      let fields = [];
      for (let f of this.fields) {
        if (f.type != "disabled") {
          fields.push(f);
        }
      }
      return fields;
    },
    /**
     * Calculates the unique values for each dropdown
     * field
     */
    dropdownOptions: function () {
      /**
       * Build the fields array containing a key for each
       * dropdown key
       */
      let dropdownFields = {};
      if (!this.enabledFields) {
        return dropdownFields;
      }
      for (let field of this.enabledFields) {
        if (field.type == "dropdown") {
          dropdownFields[field.key] = [];
        }
      }
      /**
       * Fill in the values for each dropdown key
       */
      if (!this.data) {
        return dropdownFields;
      }
      for (let dropdownItem in dropdownFields) {
        dropdownFields[dropdownItem].push("None");
        if (dropdownItem in this.backendDropdownOptions) {
          for (let backendDropdownOption of this.backendDropdownOptions[
            dropdownItem
          ]) {
            dropdownFields[dropdownItem].push(backendDropdownOption);
          }
        }
      }
      for (let item of this.data) {
        for (let dropdownItem in dropdownFields) {
          if (dropdownItem in this.backendDropdownOptions) {
            break;
          }
          dropdownFields[dropdownItem].push(item[dropdownItem]);
        }
      }
      /**
       * Ensure each key contains unique values only
       */
      for (let dropdownItem in dropdownFields) {
        dropdownFields[dropdownItem] = _uniq(
          _compact(dropdownFields[dropdownItem])
        );
      }
      return dropdownFields;
    },

    filterCount: function () {
      return this.filters.length;
    },

    savedFilterCount: function () {
      return this.savedFilters.length;
    },

    inputsDisabled: function () {
      if (this.saveState == "saving") {
        return true;
      }
      return false;
    },

    activeFilterName: function () {
      if (!this.activeFilter) {
        return "";
      }
      return this.activeFilter.name;
    },
  },

  watch: {
    /**
     * Watch the filter input value for validation
     *
     * Removes errors when the filters are updated
     */
    filters(value) {
      if (value && this.errors.filter) {
        this.errors.filter = "";
      }
    },
  },

  mounted: function () {
    if (this.filterListUrl) {
      this.getSavedFilters().then((response) => {
        this.savedFilters = response.data;
      });
    }
  },

  methods: {
    /**
     * Triggers on field-selected
     * Adds the selected field to the filters list
     */
    addFilter(field) {
      // add a unique index to the field
      field.index = (Math.random() + 1).toString(36).substring(7);
      this.filters.push(field);
    },

    onInput: _debounce(function (event, field) {
      field.search = event.target.value;
      this.filterUpdated();
    }, 500),

    onInputFrom: _debounce(function (event, field) {
      field.from = event.target.value;
      this.filterUpdated();
    }, 500),

    onInputTo: _debounce(function (event, field) {
      field.to = event.target.value;
      this.filterUpdated();
    }, 500),

    /**
     * Triggers on filter field input
     */
    filterUpdated() {
      /**
       * Filters updated event
       */
      if (this.filterCount != 0) {
        this.notice =
          "Unsaved changes to filter - These filters must be saved if they are to be used when exporting or sharing.";
      } else {
        this.notice = "";
      }
      this.$emit(
        "filters-updated",
        this.filters,
        this.activeFilter != null ? this.activeFilter.report_filter_id : null
      );
    },

    /**
     * Removes an item from the filters array
     *
     * @param {String | Number} index The index of the filter to remove
     *
     * @returns {bool}
     */
    removeFilter(index) {
      let res = this.filters.splice(index, 1);
      if (res.length == 1) {
        this.$emit(
          "filters-updated",
          this.filters,
          this.activeFilter != null ? this.activeFilter.report_filter_id : null
        );
        if (this.filterCount == 0) {
          this.notice = "";
        }
        return true;
      }
      return false;
    },

    /**
     * Saves the current set of filters
     */
    saveFilters(saveAs) {
      // validate filter data
      if (!this.name) {
        this.errors.name = "You must enter a name to save your filters.";
        this.saveState = "error";
        return false;
      }
      if (this.filterCount === 0) {
        this.errors.filter =
          "There must be at least one filter applied to save your filters.";
        this.saveState = "error";
        return false;
      }
      this.notice = "";
      this.saveState = "saving";
      if (saveAs && this.activeFilter) {
        this.activeFilter = null;
      }
      let postData = new FormData();
      postData.append("name", this.name);
      postData.append("global", this.global);
      postData.append("filter", JSON.stringify(this.filters));
      postData.append("activeFilter", JSON.stringify(this.activeFilter));
      HTTP.post(this.filterSaveUrl, postData)
        .then((response) => {
          this.saveState = "saved";
          let filterId = response.data.data;
          // re-retrieve the list of available filters
          this.getSavedFilters().then((response) => {
            this.savedFilters = response.data;
            // switch to this filter
            for (let filter of this.savedFilters) {
              if (filter.name == this.name) {
                this.activeFilter = filter;
                this.activeFilter.report_filter_id = filterId;
                this.$emit(
                  "filters-updated",
                  this.filters,
                  this.activeFilter != null
                    ? this.activeFilter.report_filter_id
                    : null
                );
              }
            }
            return true;
          });
        })
        .catch((error) => {
          if (error.response.data.data) {
            let errors = error.response.data.data;
            // loop each error from the response and add to the errors object
            for (let error in errors) {
              this.errors[error] = "";
              for (let msg in errors[error]) {
                this.errors[error] += errors[error][msg];
              }
            }
          }
          this.saveState = "error";
          return false;
        });
    },

    /**
     * Deletes a saved filter
     */
    async deleteFilter() {
      try {
        this.saveState = "saving";
        let postData = new FormData();
        postData.append("activeFilter", JSON.stringify(this.activeFilter));
        await HTTP.post(this.filterDeleteUrl, postData);
        this.saveState = "saved";
        // re-retrieve the list of available filters
        const resSavedFilters = await this.getSavedFilters();
        this.savedFilters = resSavedFilters.data;
        this.clearFilters();
      } catch (error) {
        this.saveState = "error";
      }
    },

    /**
     * Clears the current filter selection
     */
    clearFilters() {
      this.activeFilter = null;
      this.filters = [];
      this.name = "";
      this.$emit(
        "filters-updated",
        this.filters,
        this.activeFilter != null ? this.activeFilter.report_filter_id : null
      );
    },

    /**
     * Retrieves the users saved filters for the report
     */
    getSavedFilters() {
      return new Promise((resolve, reject) => {
        if (!this.filterListUrl) {
          reject("No URL provided for filter list");
          return;
        }
        // get the data using ajax
        HTTP.get(this.filterListUrl).then((response) => {
          resolve(response.data);
        });
        return;
      });
    },

    activeFilterChanged(value) {
      this.activeFilter = _cloneDeep(value);
      if (!this.activeFilter) {
        this.filters = [];
        this.name = "";
      } else {
        // when a filter is loaded any date strings need to be converted to objects
        for (let field of this.activeFilter.filter) {
          if (field.type == "date") {
            if (field.start && typeof field.start === "string") {
              field.start = DateTime.fromISO(field.start);
            }
            if (field.end && typeof field.end === "string") {
              field.end = DateTime.fromISO(field.end);
            }
          }
        }
        this.filters = this.activeFilter.filter;
        this.name = this.activeFilter.name;
      }
      /**
       * Filters updated event
       */
      this.$emit(
        "filters-updated",
        this.filters,
        this.activeFilter != null ? this.activeFilter.report_filter_id : null
      );
      return;
    },

    /**
     * Is trigged when the name in the save
     * filter component is updated
     */
    nameUpdated(value) {
      this.name = value;
    },

    /**
     * Is trigged when the global value in the
     * save filter component is updated.
     */
    globalUpdated(value) {
      this.global = value;
    },
  },
};
</script>

<style scoped>
.input-number {
  width: 100% !important;
}

.fa-times {
  font-size: 13px;
}

.form-group {
  margin-bottom: 0px;
}

.form-group label {
  padding: 0px;
  font-weight: 400;
}
</style>
