<template>
  <list-panel v-if="links.length > 0" :title="text">
    <list-panel-item
      v-for="(report, index) in links"
      :key="index"
      :icon="report.icon ? report.icon : 'file-o'"
      :href="baseUrl + 'reports/' + report.url"
    >
      {{ report.name }}
    </list-panel-item>
  </list-panel>
</template>

<script>
/**
 * Renders a list of links to report pages for a provided category
 */

import { HTTP } from "../../../http-common.js";
import ListPanel from "../../ui/list-panel/ListPanel.vue";
import ListPanelItem from "../../ui/list-panel/ListPanelItem.vue";

export default {
  name: "ReportLinks",
  components: {
    "list-panel": ListPanel,
    "list-panel-item": ListPanelItem,
  },
  props: {
    /**
     * The base URL of the app
     */
    baseUrl: {
      type: String,
      default: null,
    },

    /**
     * The category of reports that will be linked
     */
    category: {
      type: String,
      default: null,
    },

    /**
     * The text to display as the title of the list
     */
    text: {
      type: String,
      default: "Reports",
    },
  },
  data() {
    return {
      links: [],
    };
  },
  mounted: function () {
    this.getData().then((response) => {
      let items = [];
      for (const item of response.data) {
        if (item.category == this.category) {
          items.push(item);
        }
      }
      this.links = items;
    });
  },
  methods: {
    getData() {
      return new Promise((resolve) => {
        // get the data using ajax
        HTTP.get(this.baseUrl + "api/reports").then((response) => {
          resolve(response.data);
        });
      });
    },
  },
};
</script>

<style></style>
