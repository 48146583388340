var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"productCardTerritorialRights"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('a',{attrs:{"id":"territorial-rights"}}),_c('h5',{staticClass:"d-inline-block"},[_vm._v("Territorial Rights")]),(_vm.$permitted('biblio/edit') && _vm.allowEdit)?_c('edit-icon',{staticClass:"ml-2 d-print-none",attrs:{"link":_vm.$baseUrl +
        '/biblio-edit/edit-rights/' +
        _vm.productId +
        (_vm.returnTo ? '?return=' + _vm.returnTo : '')}}):_vm._e(),_c('product-quick-jump-dropdown',{staticClass:"float-right",attrs:{"product-id":_vm.productId,"display":_vm.display,"has-custom-fields":_vm.hasCustomFields}})]},proxy:true}])},[_c('dl',{staticClass:"row"},[(
        (_vm.product.getPublisherRights && _vm.showPublisherTerritorialRights) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Publisher territorial rights ")]),_c('dd',{staticClass:"col-sm-9"}),(_vm.showExclusiveRights || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Exclusive ")]),(_vm.showExclusiveRights)?_c('dd',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.exclusiveRightsText)+" ")]):_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e(),(_vm.showNonExclusiveRights || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Non-exclusive ")]),(_vm.showNonExclusiveRights)?_c('dd',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.nonExclusiveRightsText)+" ")]):_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e(),(_vm.showNoRights || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" No rights for ")]),(_vm.showNoRights)?_c('dd',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.noRightsText))]):_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e()]:_vm._e(),(_vm.product.getPublisherRightsStatement || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Rights statement ")]),_c('dd',{staticClass:"col-sm-9 preserve-white-space"},[_vm._v(_vm._s(_vm._f("valueOrPlaceholder")(_vm.product.getPublisherRightsStatement)))])]:_vm._e(),(
        (_vm.product.getSalesRestrictions.get &&
          _vm.product.getSalesRestrictions.get.length > 0) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Sales Restrictions ")]),(
          _vm.product.getSalesRestrictions.get &&
          _vm.product.getSalesRestrictions.get.length > 0
        )?_c('dd',{staticClass:"col-sm-9"},_vm._l((_vm.product.getSalesRestrictions.get),function(restriction,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(restriction.getTypeName)+" "),(restriction.getOutletName)?[_vm._v(" : "+_vm._s(restriction.getOutletName))]:_vm._e(),(restriction.getDetail)?[_vm._v(" : "+_vm._s(restriction.getDetail))]:_vm._e()],2)}),0):_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e(),(_vm.product.getSuppliers.get && _vm.product.getSuppliers.get.length > 0)?[_vm._l((_vm.product.getSuppliers.get),function(supplier,index){return [_c('dt',{key:'suppplier-dt-' + index,staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Supplier ")]),_c('dd',{key:'suppplier-dd-' + index,staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(supplier.getName)+" "),(supplier.getRoleName)?[_vm._v("("+_vm._s(supplier.getRoleName)+")")]:_vm._e()],2),(supplier.getRightsModeStr != 'none' || _vm.showAllFields)?[_c('dt',{key:'suppplier-dt3-' + index,staticClass:"col-sm-3 pl-4 font-weight-bold text-secondary"},[_vm._v(" Supplier territorial rights ")]),_c('dd',{key:'suppplier-dd3-' + index,staticClass:"col-sm-9"}),_c('dt',{key:'suppplier-dt4-' + index,staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Rights for ")]),_c('dd',{key:'suppplier-dd4-' + index,staticClass:"col-sm-9"},[(supplier.getRightsCountriesNames)?_vm._l((supplier.getRightsCountriesNames),function(country,countryIndex){return _c('span',{key:countryIndex},[_vm._v(" "+_vm._s(country)+" "),(
                    countryIndex !=
                    supplier.getRightsCountriesNames.length - 1
                  )?[_vm._v(" , ")]:_vm._e()],2)}):(supplier.getRightsModeStr == 'world')?[_vm._v(" Worldwide ")]:(supplier.getRightsModeStr == 'world_exc_ctry')?[_vm._v(" Rest of World ")]:[_vm._v("—")]],2),(
              supplier.getNoRightsCountriesNames ||
              supplier.getRightsModeStr == 'ctry_exc_row' ||
              _vm.showAllFields
            )?[_c('dt',{key:'suppplier-dt5-' + index,staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" No rights for ")]),_c('dd',{key:'suppplier-dd5-' + index,staticClass:"col-sm-9"},[(supplier.getNoRightsCountriesNames)?_vm._l((supplier.getNoRightsCountriesNames),function(country,countryIndex){return _c('span',{key:countryIndex},[_vm._v(" "+_vm._s(country)+" "),(
                      countryIndex !=
                      supplier.getNoRightsCountriesNames.length - 1
                    )?[_vm._v(" , ")]:_vm._e()],2)}):(supplier.getRightsModeStr == 'ctry_exc_row')?[_vm._v(" Rest of World ")]:[_vm._v("—")]],2)]:_vm._e()]:_vm._e()]})]:(_vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("Supplier")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }