<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text"><i class="fa fa-search"></i></span>
    </div>
    <input
      class="form-control"
      type="text"
      :value="value"
      placeholder="Search..."
      @input="searchUpdated($event)"
    />
  </div>
</template>

<script>
/**
 * Search input
 */
export default {
  name: "BaseSearchInput",

  props: {
    /**
     * The value of the search input
     */
    value: {
      type: String,
      default: null,
    },
  },

  methods: {
    /**
     * Triggers on search input
     */
    searchUpdated: function (event) {
      /**
       * Triggers on search input
       *
       * @property {String} value The new value of the search input
       */
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style scoped>
input {
  margin: 0px;
}
</style>
