<template>
  <div v-if="inline" class="loading-container d-inline-block">
    <i class="fa fa-spinner fa-pulse" :class="[sizeClass]"></i>
    {{ text }}
  </div>
  <div v-else class="loading-container text-center">
    {{ text }}
    <br />
    <i class="fa fa-spinner fa-pulse" :class="[sizeClass]"></i>
  </div>
</template>

<script>
/**
 * Displays a loading spinner
 */
export default {
  name: "LoadingSpinner",
  props: {
    /**
     * The text that will display with
     * the spinner e.g.  'Loading...'
     */
    text: {
      type: String,
      default: null,
    },

    /**
     * Sets whether the loading spinner should be inline
     */
    inline: {
      type: Boolean,
      default: false,
    },

    /**
     * The size of the loading icon
     *
     * @values small,medium,large
     */
    size: {
      type: String,
      default: "medium",
    },
  },
  computed: {
    sizeClass: function () {
      switch (this.size) {
        case "small":
          return "";
        case "large":
          return "fa-4x";
        case "medium":
        default:
          return "fa-2x";
      }
    },
  },
};
</script>

<style></style>
