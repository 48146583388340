<template>
  <div
    id="UsersSelector"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Select Users</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                ref="UsersLink"
                class="nav-link active"
                href="#UsersSelector_UsersTab"
                aria-controls="UsersSelector_UsersTab"
                role="tab"
                data-toggle="tab"
              >
                Users
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                ref="ContactsLink"
                class="nav-link"
                href="#UsersSelector_ContactsTab"
                aria-controls="UsersSelector_ContactsTab"
                role="tab"
                data-toggle="tab"
              >
                Contacts
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              id="UsersSelector_UsersTab"
              ref="UsersTab"
              role="tabpanel"
              class="tab-pane active"
            >
              <p><i>Click on a user to select them</i></p>
              <div
                v-for="user in users"
                :id="'UsersSelector_User' + user.num"
                :key="user.num"
                :data-userid="user.id"
              >
                <a href="#" @click.prevent="selectUser(user.id, user.num)">
                  {{ user.name }}
                </a>
                <i class="fa fa-check"></i>
              </div>
              <br style="clear: both" />
            </div>
            <div
              id="UsersSelector_ContactsTab"
              ref="ContactsTab"
              role="tabpanel"
              class="tab-pane"
            >
              <users-selector-contacts-dropdown
                ref="ContactsList"
                :base-url="$baseUrl"
                placeholder="Select a contact..."
                @selected="selectUser"
              >
              </users-selector-contacts-dropdown>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-dismiss="modal">
            Finished
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Display the users/contacts selector
 */
import UsersSelectorContactsDropdown from "./UsersSelectorContactsDropdown.vue";

export default {
  name: "UsersSelector",

  components: {
    "users-selector-contacts-dropdown": UsersSelectorContactsDropdown,
  },

  props: {
    /**
     * The users list. An array of user objects, user objects have the properties 'num' (integer),
     * 'id' (string) and 'name' (string)
     */
    users: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      control: null,
    };
  },

  methods: {
    /**
     * Show the users selector
     */
    show(aControl, bShowContacts) {
      this.control = aControl;
      this.$refs.UsersLink.className = "nav-link active";
      this.$refs.ContactsLink.className = "nav-link";
      this.$refs.ContactsLink.style.display = bShowContacts ? "" : "none";
      this.$refs.UsersTab.className = "tab-pane active";
      this.$refs.ContactsTab.className = "tab-pane";
      if (bShowContacts) {
        this.$refs.ContactsList.clearSelected();
      }
      window.$("#UsersSelector_UsersTab i.fa").hide(0);
      let selectedUsers = aControl.value.replace(/ *, */g, ",").split(",");
      window.$("#UsersSelector_UsersTab div").each((index, element) => {
        if (selectedUsers.indexOf(element.dataset.userid) != -1) {
          window.$("#" + element.id + " i.fa").show(0);
        }
      });
      window.$("#UsersSelector").modal("show");
    },

    /**
     * Select a user
     */
    selectUser(aUserId, aUserNum = null) {
      if (
        aUserNum &&
        window.$("#UsersSelector_User" + aUserNum + " i.fa").is(":visible")
      ) {
        this.unselectUser(aUserId, aUserNum);
        return;
      }

      let controlValue = this.control.value;
      if (controlValue != "") {
        controlValue += ", " + aUserId;
      } else {
        controlValue = aUserId;
      }
      this.control.value = controlValue;
      this.$emit("users-changed", controlValue);
      if (aUserNum) {
        window.$("#UsersSelector_User" + aUserNum + " i.fa").show(0);
      }
    },

    /**
     * Unselect a user
     */
    unselectUser(aUserId, aUserNum = null) {
      let controlValue = this.control.value;
      let selectedUsers = controlValue.replace(/ *, */g, ",").split(",");
      if (selectedUsers.indexOf(aUserId) != -1) {
        selectedUsers.splice(selectedUsers.indexOf(aUserId), 1);
        controlValue = selectedUsers.join(", ");
        this.control.value = controlValue;
        this.$emit("users-changed", controlValue);
        if (aUserNum) {
          window.$("#UsersSelector_User" + aUserNum + " i.fa").hide(0);
        }
      }
    },
  },
};
</script>

<style>
#UsersSelector_UsersTab,
#UsersSelector_ContactsTab {
  width: 100%;
}
#UsersSelector_UsersTab div {
  float: left;
  width: 25%;
  padding: 2px 0px;
}
#UsersSelector_UsersTab i.fa-check {
  color: green;
  display: none;
}

.searchformtextline_field .searchformfielddropdown {
  width: 100%;
}
.searchformtextline_index {
  text-align: center;
  padding-bottom: 5px;
}
.searchformtextline_term input {
  width: 100%;
}
</style>
