<template>
  <div>
    <!-- requirements -->
    <div class="row">
      <div class="col-12">
        <label for="application-options-requirements">
          Dissemination requirement changes that should be applied to all feeds.
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <input
          id="application-options-requirements"
          v-model="currentRequirements"
          type="text"
          class="requirements-edit"
          pattern="([+\-]([C-FRc-fr][A-Za-z0-9][0-9])+)*"
          @input="dataModified"
        />
      </div>
    </div>
    <hr />
    <!-- return conditions -->
    <div class="row">
      <div class="col-11">
        Default returns conditions to be applied to all feeds.
      </div>
      <div class="col-1">
        <button
          id="returnscondition-add"
          class="btn btn-primary btn-sm"
          @click="addReturnsCondition"
        >
          Add
        </button>
      </div>
    </div>
    <div
      v-for="(localReturnsCondition, index) in currentReturnsConditions"
      :key="index"
      class="row returnscondition"
    >
      <div class="col-3 mt-2">
        <select
          :id="'returnscondition-t' + index"
          v-model="localReturnsCondition.typeValue"
          size="1"
          @change="dataModified"
        >
          <option value="">(none)</option>
          <template v-for="(rcType, rcTypeIndex) in returnConditionTypes">
            <optgroup
              v-if="rcType.values"
              :key="rcTypeIndex"
              :label="rcType.name"
            >
              <option
                v-for="(rctName, rctCode, rctIndex) in rcType.values"
                :key="rctIndex"
                :value="rcType.code + rctCode"
              >
                {{ rctName }}
              </option>
            </optgroup>
          </template>
        </select>
      </div>
      <div class="col-1 mt-2 pr-1 returnsconditions-label">
        <label>Supplier:</label>
      </div>
      <accepted-items-auth-dyn-list
        :key="index"
        item-type="supplier"
        :input-id="'returnscondition-s' + index"
        :item="localReturnsCondition.supplier"
        :multiple="false"
        all-items-url="customisation/dissemination-settings/suppliers"
        :allow-except="false"
        row-class="col-5 mt-2 pl-1"
        selector-class=""
        @change="
          (supplierId) => {
            localReturnsCondition.supplier = supplierId;
            dataModified();
          }
        "
      />
      <div class="col-2 mt-2"></div>
      <div class="col-1 mt-2">
        <button
          class="btn btn-primary btn-sm"
          @click="deleteReturnsCondition(index)"
        >
          Delete
        </button>
      </div>
    </div>
    <hr />
    <!-- features -->
    <div class="row">
      <div class="col-12">
        <input
          id="application-options-feature-ls"
          v-model="currentLightningSourceBooktype"
          type="checkbox"
          @input="dataModified"
        />
        <label for="application-options-feature-ls">
          Enable Lightning Source Booktype field
        </label>
      </div>
    </div>
    <hr />
    <!-- buttons -->
    <div class="row">
      <div class="col-7 mt-2">
        <button
          id="application-options-save"
          class="btn"
          :class="{
            'btn-success': modified,
            'btn-secondary': !modified,
            disabled: readOnly || !modified,
          }"
          @click="saveChanges"
        >
          Save
        </button>
        <button
          class="btn btn-padleft"
          :class="{
            'btn-danger': modified,
            'btn-secondary': !modified,
            disabled: readOnly || !modified,
          }"
          @click="discardChanges"
        >
          Discard
        </button>
        <button
          v-if="automaticFeedsSuspended"
          class="btn btn-padleft4 btn-warning"
          :class="{ disabled: readOnly }"
          @click="resumeFeeds"
        >
          Resume all automatic feeds
        </button>
        <button
          v-else
          class="btn btn-padleft4 btn-warning"
          :class="{ disabled: readOnly }"
          @click="suspendFeeds"
        >
          Suspend all automatic feeds
        </button>
        <button
          class="btn btn-padleft4 btn-primary"
          :class="{ disabled: readOnly }"
          title="Make all your automatic delta feeds full feeds for the next time they run"
          @click="makeAllFeedsFull"
        >
          Make all feeds full feeds
        </button>
      </div>
      <div class="col-4 mt-2">
        <save-indicator
          :status="saveStatus"
          complete-message="Saved changes"
          @status-cleared="saveStatus = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings application options component
 */
import SaveIndicator from "../../ui/SaveIndicator.vue";
import AcceptedItemsAuthDynList from "./AcceptedItemsAuthDynList.vue";
import { HTTP } from "../../../http-common.js";

export default {
  name: "DisseminationSettingsApplicationOptions",

  components: {
    "save-indicator": SaveIndicator,
    "accepted-items-auth-dyn-list": AcceptedItemsAuthDynList,
  },

  props: {
    /**
     * List of all return condition types
     */
    returnConditionTypes: {
      type: Array,
      required: true,
    },

    /**
     * Application options
     */
    applicationOptions: {
      type: Object,
      default: null,
    },

    /**
     * Read-only
     */
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    let applicationOptions = this.applicationOptions ?? {};

    // setup internal copy of return conditions
    let currentReturnsConditions = [];
    if (applicationOptions.defaultReturnsConditions) {
      for (const returnsCondition of applicationOptions.defaultReturnsConditions) {
        let localReturnsCondition = {
          typeValue: returnsCondition.type + returnsCondition.value,
          supplier: returnsCondition.supplier
            ? returnsCondition.supplier
            : null,
        };
        currentReturnsConditions.push(localReturnsCondition);
      }
    }

    return {
      currentRequirements: applicationOptions.requirements ?? "",
      lastSavedRequirements: applicationOptions.requirements ?? "",
      currentReturnsConditions: currentReturnsConditions,
      lastSavedReturnsConditions: this.cloneReturnsConditions(
        currentReturnsConditions
      ),
      currentLightningSourceBooktype:
        applicationOptions.lightningSourceBooktype ?? false,
      lastSavedLightningSourceBooktype:
        applicationOptions.lightningSourceBooktype ?? false,
      automaticFeedsSuspended:
        applicationOptions.automaticFeedsSuspended ?? false,
      modified: false,
      saveStatus: null,
    };
  },

  methods: {
    /**
     * Data has been modified
     */
    dataModified: function () {
      this.modified = true;
      this.$emit("change");
    },

    /**
     * Add a returns condition
     */
    addReturnsCondition: function () {
      this.currentReturnsConditions.push({
        typeValue: "",
        supplier: null,
      });
      this.dataModified();
    },

    /**
     * Delete a returns condition
     */
    deleteReturnsCondition: function (index) {
      this.currentReturnsConditions.splice(index, 1);
      this.dataModified();
    },

    /**
     * Save changes
     */
    saveChanges: function () {
      if (this.readOnly || !this.modified) {
        return false;
      }

      // check if the settings are valid
      this.currentRequirements = this.currentRequirements.toUpperCase();
      if (
        this.currentRequirements.search(/^([+\\-]([C-FR][A-Z0-9][0-9])+)*$/) ==
        -1
      ) {
        this.$bvModal.msgBoxOk(
          "The dissemination requirement changes line is invalid"
        );
        return;
      }

      // save the changes
      let newApplicationOptions = {};
      if (this.currentRequirements.length != 0) {
        newApplicationOptions.requirements = this.currentRequirements;
      }
      if (this.currentReturnsConditions.length != 0) {
        // build new global options returns conditions
        let newReturnsConditions = [];
        for (const localReturnsCondition of this.currentReturnsConditions) {
          if (localReturnsCondition.typeValue.length != 0) {
            let newReturnsCondition = {
              type: localReturnsCondition.typeValue.substring(0, 2),
              value: localReturnsCondition.typeValue.substring(2),
            };
            if (localReturnsCondition.supplier) {
              newReturnsCondition.supplier = localReturnsCondition.supplier;
            }
            newReturnsConditions.push(newReturnsCondition);
          }
        }
        if (newReturnsConditions.length != 0) {
          newApplicationOptions.defaultReturnsConditions = newReturnsConditions;
        }
      }

      let url = this.$baseUrl + "customisation/dissemination-settings";
      let postData = new URLSearchParams();
      postData.append(
        "applicationOptions",
        JSON.stringify(newApplicationOptions)
      );
      postData.append(
        "lightningSourceBooktype",
        this.currentLightningSourceBooktype ? "Y" : "N"
      );

      this.saveStatus = "saving";
      HTTP.post(url, postData)
        .then(() => {
          // changes have been saved
          this.lastSavedRequirements = this.currentRequirements;
          this.lastSavedReturnsConditions = this.cloneReturnsConditions(
            this.currentReturnsConditions
          );
          this.lastSavedLightningSourceBooktype =
            this.currentLightningSourceBooktype;
          this.modified = false;
          this.saveStatus = "saved";
          this.$emit("save");
          this.$emit(
            "automatic-feeds-suspended",
            this.currentAllFeedsSuspended
          );
        })
        .catch(() => {
          this.saveStatus = null;
          this.$bvModal.msgBoxOk(
            "An error has occurred while saving your application options"
          );
        });
    },

    /**
     * Discard changes
     */
    discardChanges: function () {
      if (this.modified) {
        this.currentRequirements = this.lastSavedRequirements;
        this.currentReturnsConditions = this.cloneReturnsConditions(
          this.lastSavedReturnsConditions
        );
        this.currentLightningSourceBooktype =
          this.lastSavedLightningSourceBooktype;
        this.modified = false;
        this.$emit("discard");
        this.$emit("all-feeds-suspended", this.currentAllFeedsSuspended);
      }
    },

    /**
     * Suspend all automatic feeds
     */
    suspendFeeds: function () {
      if (!this.readOnly && !this.automaticFeedsSuspended) {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to temporarily suspend all your automatic feeds?"
          )
          .then((sure) => {
            if (sure) {
              let url = this.$baseUrl + "customisation/dissemination-settings";
              let postData = new URLSearchParams("automaticFeedsSuspended=Y");

              this.saveStatus = "saving";
              HTTP.post(url, postData)
                .then(() => {
                  this.automaticFeedsSuspended = true;
                  this.saveStatus = "saved";
                  this.$emit("automatic-feeds-suspended", true);
                })
                .catch(() => {
                  this.saveStatus = null;
                  this.$bvModal.msgBoxOk(
                    "An error has occurred while suspending your automatic feeds"
                  );
                });
            }
          });
      }
    },

    /**
     * Resume all automatic feeds
     */
    resumeFeeds: function () {
      if (!this.readOnly && this.automaticFeedsSuspended) {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to resume all your automatic feeds?"
          )
          .then((sure) => {
            if (sure) {
              let url = this.$baseUrl + "customisation/dissemination-settings";
              let postData = new URLSearchParams("automaticFeedsSuspended=N");

              this.saveStatus = "saving";
              HTTP.post(url, postData)
                .then(() => {
                  this.automaticFeedsSuspended = false;
                  this.saveStatus = "saved";
                  this.$emit("automatic-feeds-suspended", false);
                })
                .catch(() => {
                  this.saveStatus = null;
                  this.$bvModal.msgBoxOk(
                    "An error has occurred while resuming your automatic feeds"
                  );
                });
            }
          });
      }
    },

    /**
     * Make all feeds full feeds
     */
    makeAllFeedsFull: function () {
      if (!this.readOnly) {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to make all your automatic delta feeds full feeds for next time they run?"
          )
          .then((sure) => {
            if (sure) {
              let url = this.$baseUrl + "customisation/dissemination-settings";
              let postData = new URLSearchParams("makeAllFeedsFull=Y");

              this.saveStatus = "saving";
              HTTP.post(url, postData)
                .then(() => {
                  this.saveStatus = "saved";
                })
                .catch(() => {
                  this.saveStatus = null;
                  this.$bvModal.msgBoxOk(
                    "An error has occurred while making all your automatic delta feeds full feeds for next time they run"
                  );
                });
            }
          });
      }
    },

    /**
     * Clone returns conditions (returns conditions is an array of objects)
     */
    cloneReturnsConditions: function (returnsConditions) {
      let newReturnsConditions = [];
      for (const returnsCondition of returnsConditions) {
        newReturnsConditions.push({ ...returnsCondition });
      }
      return newReturnsConditions;
    },
  },
};
</script>

<style scoped>
input.requirements-edit {
  width: 98%;
  text-transform: uppercase;
}
input.requirements-edit:invalid {
  border: red solid 3px;
}
.returnscondition {
  margin: 2px 4px;
  border: silver solid 1px;
  border-radius: 4px;
  padding: 4px 4px;
}
.returnscondition select {
  width: 98%;
}
.returnsconditions-label {
  padding-top: 5px;
  text-align: right;
}
input[type="checkbox"] + label {
  padding-left: 0.5rem;
}
label.allfeedssuspended {
  font-weight: bold;
}
.btn-padleft {
  margin-left: 1rem;
}
.btn-padleft4 {
  margin-left: 4rem;
}
</style>
