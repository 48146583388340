var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"productCardImagesAndMedia","bg-variant":"light"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('a',{attrs:{"id":"images-and-media"}}),_c('h5',{staticClass:"d-inline-block"},[_vm._v("Images & Media")]),(_vm.$permitted('biblio/edit') && _vm.allowEdit)?_c('edit-icon',{staticClass:"ml-2 d-print-none",attrs:{"link":_vm.$baseUrl +
        '/biblio-edit/edit-media/' +
        _vm.productId +
        (_vm.returnTo ? '?return=' + _vm.returnTo : '')}}):_vm._e(),_c('product-quick-jump-dropdown',{staticClass:"float-right",attrs:{"product-id":_vm.productId,"display":_vm.display,"has-custom-fields":_vm.hasCustomFields}})]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('file-upload-box',{class:{ emptyBox: _vm.fileList.length == 0 },attrs:{"hover-text":"Drop a file here to upload it to the product"},on:{"drop":_vm.showFileUploadPopup}},[_c('div',{staticClass:"d-flex flex-wrap",class:{
            'justify-content-start': _vm.fileList.length > 0,
            'justify-content-center': _vm.fileList.length == 0,
          }},_vm._l((_vm.fileList),function(media,index){return _c('div',{key:index,staticClass:"m-2",staticStyle:{"height":"200px"}},[(_vm.isImage(media))?_c('product-media-img',{staticStyle:{"min-width":"150px","height":"200px"},attrs:{"thumbnail":"","url":_vm.urlSafeFilename(media.getLink, media.getLinkType),"media-type":media.getType21Name,"file-size":media.getLocalresource ? media.getLocalresource.getSize : null,"file-name":media.getLocalresource
                  ? media.getLocalresource.getFilename
                  : null,"last-modified":media.getLocalresource
                  ? media.getLocalresource.getLastModified
                  : null,"media-object":media,"share-media-modal-id":_vm.shareMediaModalId,"logon-id":_vm.logonId,"date-options":_vm.dateOptions,"disable-overlay":_vm.allowEdit == false,"show-tooltip":true,"img-height":200}}):_c('product-media-file-card',{attrs:{"file-size":media.getLocalresource ? media.getLocalresource.getSize : null,"media-type":media.getType21Name,"file-name":media.getLocalresource
                  ? media.getLocalresource.getFilename
                  : null,"last-modified":media.getLocalresource
                  ? media.getLocalresource.getLastModified
                  : null,"media-object":media,"logon-id":_vm.logonId,"date-options":_vm.dateOptions}})],1)}),0)])],1)]),(_vm.fileList.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('b',[_vm._v("Media Files Size:")]),_vm._v(" "+_vm._s(_vm.totalMediaSize)+" KB"),(_vm.countMediaFilesWithSize !== _vm.countTotalMediaFiles)?[_vm._v(" ("+_vm._s(_vm.countMediaFilesWithSize)+" of "+_vm._s(_vm.countTotalMediaFiles)+" media files)")]:_vm._e()],2)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('p',{staticClass:"mt-2"},[_vm._v(" Drag and drop files into the Images & Media box to upload them to the product ")])])]),_c('product-file-upload-modal',{attrs:{"id":_vm.modalId,"logon-id":_vm.logonId,"product-id":_vm.productId,"file-list":_vm.droppedFiles,"media-types":_vm.mediaTypes,"media-formats":_vm.mediaFormats,"user-id-number":_vm.userIdNumber,"product-media":_vm.fileList},on:{"files-uploaded":_vm.refreshFileList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }