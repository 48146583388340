<template>
  <div>
    <div class="row">
      <div class="col-12">
        When BooksoniX disseminates records to a 3rd party, an email for you
        with details will be
      </div>
    </div>
    <div class="row">
      <div class="col-2 mt-3">Sent to:</div>
      <div class="col-10 mt-2">
        <input
          id="clientnotify-to"
          v-model="to"
          type="text"
          :pattern="emailListPattern"
          placeholder="Client notification emails are sent to these adddress(es)"
          @input="dataModified"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-2 mt-2">Copied to:</div>
      <div class="col-10">
        <input
          id="clientnotify-cc"
          v-model="cc"
          type="text"
          :pattern="emailListPattern"
          placeholder="Client notification emails are copied to these adddress(es)"
          @input="dataModified"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">With the following options:</div>
    </div>
    <div class="row">
      <div class="col-12 mt-1 col-pad-10">
        <input
          id="clientnotify-grouped"
          v-model="grouped"
          type="checkbox"
          @change="dataModified"
        />
        <label for="clientnotify-grouped">Group records by error/warning</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-pad-10">
        <input
          id="clientnotify-exclude"
          v-model="exclude"
          type="checkbox"
          @change="dataModified"
        />
        <label for="clientnotify-exclude">
          Exclude records without errors or warnings
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2 col-pad-10">
        <input
          id="clientnotify-csv"
          v-model="csv"
          type="checkbox"
          @change="dataModified"
        />
        <label for="clientnotify-csv">
          Send details in a CSV file attached to the email
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <button
          id="clientnotify-save"
          class="btn"
          :class="{
            'btn-success': modified,
            'btn-secondary': !modified,
            disabled: readOnly || !modified,
          }"
          @click="saveChanges"
        >
          Save
        </button>
        <button
          class="btn btn-padleft"
          :class="{
            'btn-danger': modified,
            'btn-secondary': !modified,
            disabled: !modified,
          }"
          @click="discardChanges"
        >
          Discard
        </button>
      </div>
      <div class="col-5">
        <save-indicator
          :status="saveStatus"
          complete-message="Saved changes"
          @status-cleared="saveStatus = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings client notification component
 */
import SaveIndicator from "../../ui/SaveIndicator.vue";
import { HTTP } from "../../../http-common.js";

export default {
  name: "DisseminationSettingsClientNotify",

  components: {
    "save-indicator": SaveIndicator,
  },

  props: {
    /**
     * Client notification settings
     */
    clientNotify: {
      type: Object,
      default: null,
    },

    /**
     * Read-only
     */
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    let clientNotify = this.clientNotify ?? {};
    let fmt = clientNotify.format ?? 1;
    return {
      lastSavedClientNotify: clientNotify,
      to: clientNotify.to ? clientNotify.to.join(", ") : "",
      cc: clientNotify.cc ? clientNotify.cc.join(", ") : "",
      grouped: fmt == 2 || fmt == 4,
      exclude: fmt == 3 || fmt == 4,
      csv: clientNotify.emailCsv ?? false,
      modified: false,
      saveStatus: null,
    };
  },

  computed: {
    emailListPattern: function () {
      // reg exp pattern for an email address list
      // see SO question 429 for the structure of this pattern
      // note: in a browser and within a regex character class, forward slash, hyphen, left brace, right brace and pipe must be escaped
      return "\\s*(([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-][A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~. \\t\\n\\r\\-]*|\"([^\"\\\\]|\\\\[!-~])*\")?\\s*<([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])>|([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\]))(\\s*,\\s*(([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-][A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~. \\t\\n\\r\\-]*|\"([^\"\\\\]|\\\\[!-~])*\")?\\s*<([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])>|([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])))*\\s*";
    },
    emailAddrPattern: function () {
      // reg exp pattern for a single email address which is used when extracting email addresses
      // see SO question 429 for the structure of this pattern
      // note: in a browser and within a regex character class, forward slash, hyphen, left brace, right brace and pipe must be escaped
      return "\\s*(([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-][A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~. \\t\\n\\r\\-]*|\"([^\"\\\\]|\\\\[!-~])*\")?\\s*<([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])>|([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\]))\\s*";
    },
  },

  methods: {
    /**
     * Data has been modified
     */
    dataModified: function () {
      this.modified = true;
      this.$emit("change");
    },

    /**
     * Save changes
     */
    saveChanges: function () {
      if (this.readOnly || !this.modified) {
        return;
      }

      // check if the settings are valid
      let listPattern = new RegExp("^(" + this.emailListPattern + ")?$", "g");

      if (this.to.search(listPattern) == -1) {
        this.$bvModal.msgBoxOk(
          "The sent to recipients list is invalid, please correct"
        );
        return;
      }

      if (this.cc.search(listPattern) == -1) {
        this.$bvModal.msgBoxOk(
          "The copied to recipients list is invalid, please correct"
        );
        return;
      }

      // save the changes
      let addrPattern = new RegExp(this.emailAddrPattern, "g");
      let addr;

      let toList = [];
      while ((addr = addrPattern.exec(this.to)) !== null) {
        toList.push(addr[1]);
      }

      let ccList = [];
      addrPattern.lastIndex = 0;
      while ((addr = addrPattern.exec(this.cc)) !== null) {
        ccList.push(addr[1]);
      }

      let newClientNotify = {};
      if (toList.length != 0) {
        newClientNotify.to = toList;
      }
      if (ccList.length != 0) {
        newClientNotify.cc = ccList;
      }
      newClientNotify.format =
        1 + (this.grouped ? 1 : 0) + (this.exclude ? 2 : 0);
      newClientNotify.emailCsv = this.csv;

      let url = this.$baseUrl + "customisation/dissemination-settings";
      let postData = new URLSearchParams();
      postData.append("clientNotify", JSON.stringify(newClientNotify));

      this.saveStatus = "saving";
      HTTP.post(url, postData)
        .then(() => {
          // changes have been saved
          this.lastSavedClientNotify = newClientNotify;
          this.modified = false;
          this.saveStatus = "saved";
          this.$emit("save");
        })
        .catch(() => {
          this.saveStatus = null;
          this.$bvModal.msgBoxOk(
            "An error has occurred while saving your notification settings"
          );
        });
    },

    /**
     * Discard changes
     */
    discardChanges: function () {
      if (this.modified) {
        let fmt = this.lastSavedClientNotify.format ?? 1;
        this.to = this.lastSavedClientNotify.to
          ? this.lastSavedClientNotify.to.join(", ")
          : "";
        this.cc = this.lastSavedClientNotify.cc
          ? this.lastSavedClientNotify.cc.join(", ")
          : "";
        this.grouped = fmt == 2 || fmt == 4;
        this.exclude = fmt == 3 || fmt == 4;
        this.csv = this.lastSavedClientNotify.emailCsv ?? false;
        this.modified = false;
        this.$emit("discard");
      }
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  width: 100%;
}
input[type="text"]:invalid {
  border: red solid 3px;
}
input[type="checkbox"] {
  margin-right: 0.5rem;
}
.col-pad-10 {
  padding-left: 10pt;
}
.btn-padleft {
  margin-left: 1rem;
}
</style>
