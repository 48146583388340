<template>
  <div>
    <div class="row bibliodescriptionsedit-header">
      <div class="col-3">
        <label>
          {{ header }}
        </label>
        <button
          type="button"
          title="Add another description"
          class="btn btn-outline-primary btn-sm bibliodescriptionsedit-add"
          @click="addDescription"
        >
          <i class="fa fa-plus"></i>
          Add
        </button>
      </div>
    </div>
    <template v-for="(description, descriptionIndex) in currentDescriptions">
      <div :key="2 * descriptionIndex + 1" class="bibliodescriptionsedit-item">
        <hr />
        <div class="row">
          <div class="col-4">
            <select
              :id="'bibliodescriptionsedit-type-' + descriptionIndex"
              size="1"
              class="bibliodescriptionsedit-type"
              :required="description.getText != ''"
              @change="selectedType(descriptionIndex, $event.target)"
            >
              <option value="">(unknown)</option>
              <template v-for="(typeInfo, typeIndex) in types">
                <optgroup
                  v-if="Array.isArray(typeInfo)"
                  :key="1000 * typeIndex"
                  :label="typeInfo[0].groupName"
                >
                  <option
                    v-for="(groupTypeInfo, groupTypeIndex) in typeInfo"
                    :key="1000 * typeIndex + groupTypeIndex + 1"
                    :value="groupTypeInfo.code"
                    :selected="description.type == groupTypeInfo.code"
                  >
                    {{ groupTypeInfo.name }}
                    {{ groupTypeInfo.listSuffix }}
                  </option>
                </optgroup>
                <option
                  v-else
                  :key="1000 * typeIndex + 1"
                  :value="typeInfo.code"
                  :selected="description.type == typeInfo.code"
                >
                  {{ typeInfo.name }} {{ typeInfo.listSuffix }}
                </option>
              </template>
            </select>
          </div>
          <div class="col-1">
            <select
              size="1"
              class="bibliodescriptionsedit-format"
              @change="selectedFormat(descriptionIndex, $event.target)"
            >
              <option value="06" :selected="description.getFormat != '02'">
                Text
              </option>
              <option value="02" :selected="description.getFormat == '02'">
                HTML
              </option>
            </select>
          </div>
          <div class="col-3">
            <select
              size="1"
              class="bibliodescriptionsedit-language"
              @change="selectedLanguage(descriptionIndex, $event.target)"
            >
              <option value="">(unspecified)</option>
              <option
                v-for="languageInfo in languages"
                :key="languageInfo.code"
                :value="languageInfo.code"
                :selected="languageInfo.code == description.language"
              >
                {{ languageInfo.name }}
              </option>
            </select>
          </div>
          <div class="col-2">
            <input
              :id="'bibliodescriptionsedit-internal-' + descriptionIndex"
              type="checkbox"
              value="I"
              :checked="
                description.getInternal ||
                description.getType21.charAt(0) == 'X'
              "
              :disabled="description.getType21.charAt(0) == 'X'"
              class="bibliodescriptionsedit-internal"
              @click="changedInternal(descriptionIndex, $event.target)"
            />
            <label
              :for="'bibliodescriptionsedit-internal-' + descriptionIndex"
              class="bibliodescriptionsedit-internal"
            >
              Internal
              <span
                class="note-star"
                title="This field is internal and will not be disseminated"
              >
                **
              </span>
            </label>
          </div>
          <div class="col-2">
            {{ getDescriptionLength(descriptionIndex) }} characters
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="bibliodescriptionsedit-text">
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="description.getFormat == '02'"
                v-html="description.getText"
              ></div>
              <!--eslint-enable-->
              <!-- prettier-ignore -->
              <div v-else class="preserve-white-space">{{ description.getText }}</div>
              <div
                v-if="
                  description.getAuthor ||
                  description.getCorporateBody ||
                  description.getSourceDescription ||
                  description.getTitleOfSource ||
                  description.getPublicationDate
                "
                class="font-italic text-right"
              >
                <template v-if="description.getAuthor">
                  {{ description.getAuthor }},
                </template>
                <template v-if="description.getCorporateBody">
                  {{ description.getCorporateBody }},
                </template>
                <template v-if="description.getSourceDescription">
                  {{ description.getSourceDescription }},
                </template>
                <template v-if="description.getTitleOfSource">
                  {{ description.getTitleOfSource }},
                </template>
                <template v-if="description.getPublicationDate">
                  {{
                    description.getPublicationDate.$date
                      | parseDate(dateOptions.format, dateOptions)
                  }}
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :key="2 * descriptionIndex + 2"
        class="row bibliodescriptionsedit-btns"
      >
        <div class="col-12">
          <a
            href="#"
            class="btn btn-link"
            @click="editDescription(descriptionIndex)"
          >
            Edit
          </a>
          <a
            href="#"
            class="btn btn-link"
            @click="moveDescriptionUp(descriptionIndex)"
          >
            Move Up
          </a>
          <a
            href="#"
            class="btn btn-link"
            @click="moveDescriptionDn(descriptionIndex)"
          >
            Move Down
          </a>
          <a
            href="#"
            class="btn btn-link"
            @click="deleteDescription(descriptionIndex)"
          >
            Delete
          </a>
        </div>
      </div>
    </template>
    <b-modal
      id="bibliodescriptionsedit-editor"
      size="xl"
      no-close-on-backdrop
      static
    >
      <template #modal-title>Edit</template>
      <template #modal-footer>
        <div class="mr-auto">
          <a ref="ToggleBtn" href="#" @click="editDescriptionToggle">
            Switch to Text
          </a>
        </div>
        <button
          type="button"
          class="btn btn-success"
          @click="editDescriptionSave"
        >
          OK
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="editDescriptionClose"
        >
          Cancel
        </button>
      </template>
      <div class="row">
        <div class="col-12">
          <div
            ref="EditorHtmlContainer"
            class="bibliodescriptionsedit-editor-html"
          >
            <div id="bibliodescriptionsedit-editor-html" ref="EditorHtml"></div>
          </div>
          <div
            ref="EditorTextContainer"
            class="bibliodescriptionsedit-editor-text"
          >
            <textarea
              ref="EditorText"
              class="bibliodescriptionsedit-editor-text"
              wrap="virtual"
            ></textarea>
          </div>
        </div>
      </div>
      <div ref="EditorSource1" class="px-3 mt-2" style="display: none">
        <div class="row">
          <div class="col-2">
            <label for="bibliodescriptionsedit-editor-author">Author</label>
            <br />
            <input
              id="bibliodescriptionsedit-editor-author"
              ref="AuthorEdit"
              type="text"
              maxlength="300"
              value=""
            />
          </div>
          <div class="col-2">
            <label for="bibliodescriptionsedit-editor-corpbody">
              Corporate
            </label>
            <br />
            <input
              id="bibliodescriptionsedit-editor-corpbody"
              ref="CorpBodyEdit"
              type="text"
              maxlength="300"
              value=""
            />
          </div>
          <div class="col-3">
            <label for="bibliodescriptionsedit-editor-srcdesc">
              Source Description
            </label>
            <br />
            <input
              id="bibliodescriptionsedit-editor-srcdesc"
              ref="SrcDescEdit"
              type="text"
              maxlength="300"
              value=""
            />
          </div>
          <div class="col-3">
            <label for="bibliodescriptionsedit-editor-srctitle">
              Title of Source
            </label>
            <br />
            <input
              id="bibliodescriptionsedit-editor-srctitle"
              ref="SrcTitleEdit"
              type="text"
              maxlength="300"
              value=""
            />
          </div>
          <div class="col-2">
            <label for="bibliodescriptionsedit-editor-pubdate">
              Publication Date
            </label>
            <br />
            <v-datepicker
              id="bibliodescriptionsedit-editor-pubdate"
              v-model="editorPubDate"
              :format="editorDatePickerFormat"
              :monday-first="$userPreferences.dateFormat == 'dmy'"
              :clear-button="true"
              :placeholder="editorDatePickerFormat.toLowerCase()"
            />
          </div>
        </div>
      </div>
      <div ref="EditorSource2" class="px-3 mt-1" style="display: none">
        <div class="row">
          <div class="col-12">
            Please note that the Title of Source field is for specifying the
            name of the source from where this text originates, it must
            <b>not</b> be an Internet address/URL.
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/**
 * Bibliographic descriptions edit container to edit descriptions
 *
 * Emits a 'changed' event including an array of description objects (excluding empty entries)
 */
import ParseDate from "../../../mixins/parseDate.js";
import Datepicker from "vuejs-datepicker";
import { DateTime } from "luxon";

export default {
  name: "BiblioDescriptionsEdit",

  components: {
    "v-datepicker": Datepicker,
  },

  mixins: [ParseDate],

  props: {
    /**
     * Header text
     */
    header: {
      type: String,
      required: true,
    },

    /**
     * Descriptions to edit
     */
    descriptions: {
      type: Array,
      default: null,
    },

    /**
     * List of all description types
     */
    types: {
      type: Array,
      required: true,
    },

    /**
     * List of all languages
     */
    languages: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    let descriptions = this.descriptions ? this.descriptions : [];
    let currentDescriptions = [];
    for (let i = 0; i < descriptions.length; i++) {
      let type = descriptions[i].getType21;
      if (descriptions[i].getDisseminationRecipient) {
        type += ":" + descriptions[i].getDisseminationRecipient;
      }
      let language = descriptions[i].getLanguage;
      if (descriptions[i].getLanguageCountry) {
        language += "-" + descriptions[i].getLanguageCountry;
      }
      currentDescriptions.push({
        ...descriptions[i],
        type: type,
        language: language,
      });
    }

    return {
      currentDescriptions: currentDescriptions,
      htmlEditor: null,
      editState: null,
      editorPubDate: null,
    };
  },

  computed: {
    /**
     * Get the format for the date picker
     */
    editorDatePickerFormat: function () {
      switch (this.$userPreferences.dateFormat) {
        case "ymd":
          return "yyyy/MM/dd";
        case "mdy":
          return "MM/dd/yyyy";
        default:
          return "dd/MM/yyyy";
      }
    },
  },

  mounted() {
    if (!window._quillJsLoaded) {
      let quillScript = document.createElement("script");
      quillScript.setAttribute("src", "/common/quilljs/quill.min.js");
      quillScript.onload = function () {
        // load quill_extended.js after quill.min.js has loaded otherwise we get an error
        let quillExtendedScript = document.createElement("script");
        quillExtendedScript.setAttribute(
          "src",
          "/common/quilljs/quill_extended.js"
        );
        document.head.appendChild(quillExtendedScript);
      };
      document.head.appendChild(quillScript);

      let quillStyle = document.createElement("link");
      quillStyle.setAttribute("rel", "stylesheet");
      quillStyle.setAttribute("href", "/common/quilljs/quill.snow.css");
      quillStyle.setAttribute("type", "text/css");
      document.head.appendChild(quillStyle);

      window._quillJsLoaded = true;
    }
  },

  methods: {
    /**
     * Get a description's length
     */
    getDescriptionLength: function (index) {
      let format = this.currentDescriptions[index].getFormat;
      if (format == "02" || format == "05") {
        return this.currentDescriptions[index].getText.replace(
          /&(?:amp;)?(#[0-9]+|#x[0-9a-f]+|[a-z0-9]+);/gi,
          "."
        ).length;
      }

      return this.currentDescriptions[index].getText.length;
    },

    /**
     * Add a description
     * Note: we don't notify of changes as we have added a description entry with no type selected or text entered
     */
    addDescription: function () {
      this.currentDescriptions.push({
        getType21: "",
        getFormat: "06",
        getLanguage: null,
        getLanguageCountry: null,
        getInternal: false,
        getDisseminationRecipient: null,
        getText: "",
        getAuthor: null,
        getCorporateBody: null,
        getSourceDescription: null,
        getTitleOfSource: null,
        getPublicationDate: null,
        type: "",
        language: null,
      });
    },

    /**
     * Edit a description
     */
    editDescription: function (index) {
      let format = this.currentDescriptions[index].getFormat;
      if (format != "06" && format != "02") {
        this.$bvModal.msgBoxOk(
          "The format of this text item must be selected before it can be edited"
        );
        return;
      }

      let title = "Edit";
      let type = this.currentDescriptions[index].type;
      for (let i = 0; i < this.types.length; i++) {
        if (Array.isArray(this.types[i])) {
          for (let j = 0; j < this.types[i].length; j++) {
            if (type == this.types[i][j].code) {
              title = "Edit " + this.types[i][j].name;
              i = this.types.length; // also exit outer loop
              break;
            }
          }
        } else {
          if (type == this.types[i].code) {
            title = "Edit " + this.types[i].name;
            break;
          }
        }
      }

      if (title.indexOf(" *") != -1) {
        title = title.substring(0, title.indexOf(" *"));
      } else if (title.indexOf(" \u2020") != -1) {
        title = title.substring(0, title.indexOf(" \u2020"));
      } else if (title.indexOf(" (max ") != -1) {
        title = title.substring(0, title.indexOf(" (max "));
      }
      document.querySelector(
        "#bibliodescriptionsedit-editor h5.modal-title"
      ).innerHTML = title.replace(/&/g, "&amp;").replace(/</g, "&lt;");

      if (!this.htmlEditor) {
        // eslint-disable-next-line no-undef
        this.htmlEditor = new QuillEx("#bibliodescriptionsedit-editor-html", {
          modules: {
            toolbar: [
              [
                "bold",
                "italic",
                "underline",
                "strike",
                {
                  script: "sub",
                },
                {
                  script: "super",
                },
              ],
              [
                {
                  indent: "-1",
                },
                {
                  indent: "+1",
                },
                {
                  align: [],
                },
              ],
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
                "blockquote",
                "hr",
              ],
              ["link"],
              ["clean"],
            ],
          },
          theme: "snow",
        });
        this.htmlEditor.setToolbarTips([
          ["bold", "Bold"],
          ["italic", "Italic"],
          ["underline", "Underline"],
          ["strike", "Strikethrough"],
          ["script:sub", "Subscript"],
          ["script:super", "Superscript"],
          ["indent:-1", "Decrease indent"],
          ["indent:+1", "Increase indent"],
          ["align", "Alignment"],
          ["list:ordered", "Numbered list"],
          ["list:bullet", "Bullet list"],
          ["blockquote", "Quote text"],
          ["hr", "Horizontal line"],
          ["link", "Link"],
          ["clean", "Clear formatting"],
        ]);
      }

      if (format == "02") {
        this.htmlEditor.setHtml(this.currentDescriptions[index].getText);
        this.$refs.EditorHtmlContainer.style.display = "block";
        this.$refs.EditorTextContainer.style.display = "none";
        this.$refs.ToggleBtn.innerHTML = "Switch to Text";
      } else {
        this.$refs.EditorText.value = this.currentDescriptions[index].getText;
        this.$refs.EditorHtmlContainer.style.display = "none";
        this.$refs.EditorTextContainer.style.display = "block";
        this.$refs.ToggleBtn.innerHTML = "Switch to HTML";
      }

      if (
        (type >= "05" && type <= "10") ||
        type == "13" ||
        type == "30" ||
        type == "35" ||
        type == "49"
      ) {
        this.$refs.AuthorEdit.value = this.currentDescriptions[index].getAuthor;
        this.$refs.CorpBodyEdit.value =
          this.currentDescriptions[index].getCorporateBody;
        this.$refs.SrcDescEdit.value =
          this.currentDescriptions[index].getSourceDescription;
        this.$refs.SrcTitleEdit.value =
          this.currentDescriptions[index].getTitleOfSource;
        this.editorPubDate = this.currentDescriptions[index].getPublicationDate
          ? this.currentDescriptions[index].getPublicationDate.$date
          : null;
        this.$refs.EditorSource1.style.display = "block";
        this.$refs.EditorSource2.style.display = "block";
      } else {
        this.$refs.EditorSource1.style.display = "none";
        this.$refs.EditorSource2.style.display = "none";
      }

      this.editState = [index, type, format];
      this.$bvModal.show("bibliodescriptionsedit-editor");
    },

    /**
     * edit description toggle between HTML and text
     */
    editDescriptionToggle: function () {
      if (this.editState[2] == "02") {
        this.$refs.EditorText.value = this.htmlEditor.getHtml();
        this.$refs.EditorHtmlContainer.style.display = "none";
        this.$refs.EditorTextContainer.style.display = "block";
        this.$refs.ToggleBtn.innerHTML = "Switch to HTML";
        this.editState[2] = "06";
      } else {
        this.htmlEditor.setHtml(this.$refs.EditorText.value);
        this.$refs.EditorHtmlContainer.style.display = "block";
        this.$refs.EditorTextContainer.style.display = "none";
        this.$refs.ToggleBtn.innerHTML = "Switch to Text";
        this.editState[2] = "02";
      }
    },

    /**
     * Save an edited description
     */
    editDescriptionSave: function () {
      let index = this.editState[0];
      let type = this.editState[1];
      let format = this.editState[2];

      if (format == "02") {
        this.currentDescriptions[index].getText = this.htmlEditor.getHtml();
      } else {
        this.currentDescriptions[index].getText = this.$refs.EditorText.value;
      }
      this.currentDescriptions[index].getFormat = format;

      if (
        (type >= "05" && type <= "10") ||
        type == "13" ||
        type == "30" ||
        type == "35" ||
        type == "49"
      ) {
        this.currentDescriptions[index].getAuthor = this.$refs.AuthorEdit.value;
        this.currentDescriptions[index].getCorporateBody =
          this.$refs.CorpBodyEdit.value;
        this.currentDescriptions[index].getSourceDescription =
          this.$refs.SrcDescEdit.value;
        this.currentDescriptions[index].getTitleOfSource =
          this.$refs.SrcTitleEdit.value;
        if (this.editorPubDate) {
          let dt = DateTime.fromJSDate(this.editorPubDate);
          this.currentDescriptions[index].getPublicationDate = dt.toFormat(
            this.editorDatePickerFormat
          );
        } else {
          this.currentDescriptions[index].getPublicationDate = null;
        }
      } else {
        this.currentDescriptions[index].getAuthor = null;
        this.currentDescriptions[index].getCorporateBody = null;
        this.currentDescriptions[index].getTitleOfSource = null;
        this.currentDescriptions[index].getPublicationDate = null;
      }

      this.editDescriptionClose();
      this.notifyChanges();
    },

    /**
     * Close editing a description
     */
    editDescriptionClose: function () {
      this.editState = null;
      this.editorPubDate = null;
      this.$bvModal.hide("bibliodescriptionsedit-editor");
    },

    /**
     * Move a description up
     */
    moveDescriptionUp: function (index) {
      if (index >= 1) {
        let description = this.currentDescriptions.splice(index, 1);
        this.currentDescriptions.splice(index - 1, 0, description[0]);
        this.notifyChanges();
      }
    },

    /**
     * Move a description down
     */
    moveDescriptionDn: function (index) {
      if (index < this.currentDescriptions.length - 1) {
        let description = this.currentDescriptions.splice(index, 1);
        this.currentDescriptions.splice(index + 1, 0, description[0]);
        this.notifyChanges();
      }
    },

    /**
     * Delete a description
     */
    deleteDescription: function (index) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure that you want to delete this description ?"
        )
        .then((result) => {
          if (result) {
            this.currentDescriptions.splice(index, 1);
            this.notifyChanges();
          }
        });
    },

    /**
     * A type has been selected
     */
    selectedType: function (index, control) {
      let type = control.options[control.selectedIndex].value;
      this.currentDescriptions[index].type = type;
      this.currentDescriptions[index].getType21 = type.substr(0, 2);
      if (type.length >= 4) {
        this.currentDescriptions[index].getDisseminationRecipient =
          type.substr(3);
      } else {
        this.currentDescriptions[index].getDisseminationRecipient = null;
      }
      this.notifyChanges();
    },

    /**
     * A format has been selected
     */
    selectedFormat: function (index, control) {
      this.currentDescriptions[index].getFormat =
        control.options[control.selectedIndex].value;
      this.notifyChanges();
    },

    /**
     * A language has been selected
     */
    selectedLanguage: function (index, control) {
      this.currentDescriptions[index].getLanguage =
        control.options[control.selectedIndex].value;
      this.notifyChanges();
    },

    /**
     * The internal state has been changed
     */
    changedInternal: function (index, control) {
      this.currentDescriptions[index].getInternal = control.checked
        ? control.value
        : "";
      this.notifyChanges();
    },

    /**
     * notify of changed descriptions
     */
    notifyChanges: function () {
      // select descriptions entries with a type and text
      let descriptions = [];
      for (let i = 0; i < this.currentDescriptions.length; i++) {
        if (
          this.currentDescriptions[i].getType21 != "" &&
          this.currentDescriptions[i].getText != ""
        ) {
          descriptions.push(this.currentDescriptions[i]);
        }
      }
      this.$emit("changed", descriptions);
    },
  },
};
</script>

<style>
.bibliodescriptionsedit-header {
  margin-bottom: 0.5rem;
}
.bibliodescriptionsedit-add {
  margin-left: 1rem;
}
.bibliodescriptionsedit-item hr {
  margin: 2px 0px;
}
.bibliodescriptionsedit-type,
.bibliodescriptionsedit-format,
.bibliodescriptionsedit-language {
  width: 100%;
}
label.bibliodescriptionsedit-internal {
  padding-left: 0.5rem;
}
.bibliodescriptionsedit-btns {
  text-align: right;
}

.bibliodescriptionsedit-text {
  background-color: white;
  border: 1px solid #a5acb2;
  padding: 2px 4px;
  border-radius: 5px;
  margin: 2px;
  height: 70px;
  overflow: auto;
}
.bibliodescriptionsedit-editor-html {
  display: none;
}
#bibliodescriptionsedit-editor-html {
  height: 300px;
}
#bibliodescriptionsedit-editor-text-container {
  padding: 0px 5px 0px 5px;
}
.bibliodescriptionsedit-editor-text {
  padding: 0px 5px 0px 5px;
}
.bibliodescriptionsedit-editor-text textarea {
  width: 100%;
  margin: 0px;
  height: 369px;
  resize: none;
}
.ql-editor p {
  margin: 0 0 10px;
}
#bibliodescriptionsedit-editor-author,
#bibliodescriptionsedit-editor-corpbody,
#bibliodescriptionsedit-editor-srcdesc,
#bibliodescriptionsedit-editor-srctitle {
  width: 100%;
}

#bibliodescriptionsedit-editor-pubdate {
  width: 70%;
}
</style>
