<template>
  <i v-if="disabled" :class="'fa-' + icon" class="fa info tableButton"></i>
  <a
    v-else
    :href="link"
    class="fa tableButton"
    :class="['fa-' + icon, colour]"
    :target="target"
    @click.prevent="handleClick()"
  ></a>
</template>

<script>
/**
 * Small button displaying a delete icon that
 * links to a URL.
 */
export default {
  name: "IconBtn",
  props: {
    /**
     * The URL destination the button will link to.
     */
    link: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
      default: "question",
    },

    /**
     * Disables the button
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * If a message is provided then a confirmation alert will display with the message
     * before the click action is called
     */
    msg: {
      type: String,
      default: "",
    },

    /**
     * Sets the target property of the link
     */
    target: {
      type: String,
      default: "_self",
    },

    /**
     * The colour of the icon
     *
     * @values blue,red,green,info
     */
    colour: {
      type: String,
      default: "blue",
    },
  },

  methods: {
    /**
     * Opens the btn link
     */
    open: function () {
      if (!this.msg) {
        window.open(this.link, this.target);
      } else {
        if (confirm(this.msg)) {
          window.open(this.link, this.target);
        }
      }
    },

    /**
     * Handles the click event
     */
    handleClick() {
      if (this.link) {
        this.open();
      }
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
td i,
td a {
  border: 1px solid transparent;
}
a {
  cursor: pointer;
}
</style>
