<template>
  <div class="quicksearchtags" data-v-step="biblio-search-tags">
    <div class="quicksearchtags_managebtn">
      <a :href="manageTagsUrl" class="btn btn-primary">Manage Tags</a>
    </div>
    <base-header>Quick search by tag</base-header>
    <div class="row">
      <div class="col-12 quicksearchtags_tags">
        <quick-search-tag v-for="tag in tags" :key="tag.id" :tag="tag" />
      </div>
    </div>
    <br />
    <template v-if="filters.indexOf('product-status') != -1">
      <search-form-tickbox-line
        :key="1"
        :line-no="1"
        :options="[
          { value: 'A', label: 'Approved records' },
          { value: 'P', label: 'Provisional records' },
          { value: 'D', label: 'Archived records' },
        ]"
        field="RECSTAT"
        :term="statusLine ? statusLine.term : ['A', 'P']"
        default-term="A/P"
        :filter="true"
      >
        Include the following records:
      </search-form-tickbox-line>
      <search-form-tickbox-line
        :key="2"
        :line-no="2"
        :options="[
          { value: 'P', label: 'Public records' },
          { value: 'W', label: 'Own website records' },
          { value: 'I', label: 'Internal records' },
        ]"
        field="RECVIS"
        :term="visibilityLine ? visibilityLine.term : ['P', 'W', 'I']"
        default-term="P/W/I"
        :filter="true"
      >
      </search-form-tickbox-line>
    </template>
    <div class="row quicksearchtags_footerline">
      <div class="col-12 quicksearchtags_footerline_note">
        Please note that you can also search by Tag using the search boxes
        above.
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Display a quick search by tag form
 *
 * The following form controls are output:
 *   tickboxes: 'type{lineNo}' = 'tickbox:{count}[/f]' (hidden), 'bool{lineNo}' (hidden), 'field{lineNo}' (hidden), 'defterm{lineNo}' (hidden), 'term{lineNo}.{n}' (checkbox)
 */
import QuickSearchTag from "./QuickSearchTag.vue";
import SearchFormTickboxLine from "./SearchFormTickboxLine.vue";

export default {
  name: "QuickSearchTags",

  components: {
    "quick-search-tag": QuickSearchTag,
    "search-form-tickbox-line": SearchFormTickboxLine,
  },

  props: {
    /**
     * The tags list. An array of objects which have the properties 'id' (integer), 'name' (string), 'level' (string),
     *   'colour' (integer), 'bkcolour' (integer|null)
     */
    tags: {
      type: Array,
      required: true,
    },

    /**
     * The field
     */
    field: {
      type: String,
      required: true,
    },

    /**
     * The required search filters
     */
    filters: {
      type: String,
      default: "",
    },

    /**
     * The manage tags page url
     */
    manageTagsUrl: {
      type: String,
      required: true,
    },

    /**
     * The search form lines, each line is an object with the properties bool, field, term and type
     */
    lines: {
      type: Array,
      default: null,
    },
  },

  computed: {
    statusLine: function () {
      let statusLine = null;
      if (!this.lines) {
        return statusLine;
      }
      statusLine = this.lines.find((line) => {
        return line.field === "RECSTAT";
      });
      return statusLine ?? null;
    },
    visibilityLine: function () {
      let visibilityLine = null;
      if (!this.lines) {
        return visibilityLine;
      }
      visibilityLine = this.lines.find((line) => {
        return line.field === "RECVIS";
      });
      return visibilityLine ?? null;
    },
  },
};
</script>

<style>
.quicksearchtags {
  position: relative;
  left: -8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  overflow: hidden;
}
.quicksearchtags_managebtn {
  float: right;
  width: 10%;
  text-align: right;
}
.quicksearchtags_footerline_note {
  color: grey;
}
</style>
