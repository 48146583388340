<template>
  <div>
    <ul class="contacts_resultslist_menubar">
      <li v-if="searchId && contactId">
        <a
          :href="$baseUrl + 'search/unlink/' + searchId + '/' + page"
          class="btn btn-link"
        >
          <i class="fa fa-arrow-left"></i>
          Back
        </a>
      </li>
      <li v-else-if="searchId">
        <a :href="$baseUrl + 'search/unlink/' + searchId" class="btn btn-link">
          <i class="fa fa-arrow-left"></i>
          Back
        </a>
      </li>
      <li v-if="searchId && hasSearchForm">
        <a :href="$baseUrl + 'search/form/' + searchId" class="btn btn-link">
          <i class="fa fa-magnifying-glass"></i>
          Search Form
        </a>
      </li>
      <li v-else-if="searchForm">
        <a :href="$baseUrl + 'search/form/' + searchForm" class="btn btn-link">
          <i class="fa fa-magnifying-glass"></i>
          Search Form
        </a>
      </li>
      <li v-if="searchId && contactId">
        <a
          :href="$baseUrl + 'contacts/results-list/' + searchId + '/1'"
          class="btn btn-link"
        >
          <i class="fa fa-list-ul"></i>
          Results List
        </a>
      </li>
      <template v-if="!contactDeleted">
        <li v-if="searchId">
          <a
            class="btn btn-link"
            href="#"
            @click="$emit('sortBtnClick', $event)"
          >
            <i class="fa fa-sort"></i>
            Sort
          </a>
        </li>
        <li>
          <a
            class="btn btn-link"
            :class="!markedRecordsActionsEnabled ? 'disabled' : null"
            @click="tagBtnClicked($event)"
          >
            <i class="fa fa-tag"></i>
            Tag
          </a>
        </li>
        <li v-if="searchId">
          <a
            class="btn btn-link dropdown-toggle"
            href="#"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-check-double"></i>
            Mark
          </a>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              href="#"
              @click="$emit('markclick', $event, 'all')"
            >
              All
            </a>
            <a
              class="dropdown-item"
              href="#"
              @click="$emit('markclick', $event, 'none')"
            >
              None
            </a>
            <a
              class="dropdown-item"
              href="#"
              @click="$emit('markclick', $event, 'reverse')"
            >
              Reverse
            </a>
          </div>
        </li>

        <li v-if="searchId">
          <a class="btn btn-link" @click="exportClicked()">Export</a>
        </li>

        <b-dropdown
          v-if="contactId && searchId"
          text="Output"
          variant="none"
          toggle-class="btn-link"
        >
          <b-dropdown-item
            :href="$baseUrl + 'contacts/output/' + searchId + '/' + page"
            target="_blank"
            >View PDF</b-dropdown-item
          >
          <b-dropdown-item href="#" @click="outputEmailClicked"
            >Email PDF</b-dropdown-item
          >
        </b-dropdown>

        <li v-if="contactId">
          <a
            class="btn btn-link"
            :href="
              $baseUrl +
              'contacts/edit/' +
              contactId +
              '?return=/contacts/detailed/' +
              contactId +
              '/' +
              searchId +
              '/' +
              page +
              (format != '' ? '/' + format : '')
            "
          >
            Edit
          </a>
        </li>

        <!-- hidden link to refresh the page -->
        <li v-if="searchId && page">
          <a
            ref="refreshLink"
            :href="
              $baseUrl +
              'contacts/' +
              (contactId ? 'detailed/' + contactId : 'results-list') +
              '/' +
              searchId +
              '/' +
              page +
              (format != '' ? '/' + format : '')
            "
            class="contacts_resultslist_hiddenlink"
          >
          </a>
        </li>
      </template>
    </ul>
    <edit-tags-modal
      :id="tagModalId"
      :ref="tagModalId"
      :record-ids="markedRecordIds"
      :marked="!contactId"
      record-type="contact"
      @tagsApplied="onTagsApplied"
      @tagCreated="onTagCreated"
    ></edit-tags-modal>
    <export-modal
      :id="exportModalId"
      :ref="exportModalId"
      :search-id="searchId"
      :position-type="contactId ? '' : 'p'"
      :position="page"
      :display-format="format"
      type="contacts"
    ></export-modal>
    <email-detailed-record-pdf-modal
      v-if="contactId"
      :id="emailDetailedRecordPdfModalId"
      :ref="emailDetailedRecordPdfModalId"
      :search-id="searchId"
      :position="page"
      type="contact"
    ></email-detailed-record-pdf-modal>
  </div>
</template>

<script>
/**
 * Display the Contacts Menubar line
 */
import EditTagsModal from "../pages/modals/EditTagsModal.vue";
import EmailDetailedRecordPdfModal from "../pages/modals/EmailDetailedRecordPdfModal.vue";
import ExportModal from "../pages/modals/ExportModal.vue";

export default {
  name: "MenuBar",

  components: {
    "edit-tags-modal": EditTagsModal,
    "export-modal": ExportModal,
    "email-detailed-record-pdf-modal": EmailDetailedRecordPdfModal,
  },

  props: {
    /**
     * A unique location identifier for this menubar
     */
    location: {
      type: String,
      required: true,
    },

    /**
     * Search form name
     */
    searchForm: {
      type: String,
      default: null,
    },

    /**
     * Has the search a search form?
     */
    hasSearchForm: {
      type: Boolean,
      default: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Unique ID / Record Number for a contact
     *
     * Passing a contactId will enforce actions such as tagging
     * This is used for detailed display.
     */
    contactId: {
      type: Number,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * Display format
     */
    format: {
      type: String,
      default: "",
    },

    /**
     * Actions which require there to be marked records
     * can be disabled using this flag
     */
    markedRecordsActionsEnabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Marked Records object
     */
    markedRecords: {
      type: Object,
      default: null,
    },

    /**
     * As an alternative to updating parent component
     * with events, a page refresh can be triggered.
     *
     * Set to false to prevent this.
     */
    usePageRefresh: {
      type: Boolean,
      default: true,
    },

    /**
     * Display setting for tab content
     * @values all, full, brief
     */
    display: {
      type: String,
      default: null,
    },

    /**
     * Used when viewing a detailed display of a contact
     * Hides menu options if the contact is deleted
     */
    contactDeleted: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      tagModalId: "edit-tags-modal-" + this.location,
      exportModalId: "export-modal-" + this.location,
      emailDetailedRecordPdfModalId:
        "email-detailed-record-pdf-modal-" + this.location,
    };
  },

  computed: {
    markedRecordIds: function () {
      if (this.contactId) {
        return [this.contactId];
      }
      if (this.markedRecords == null) {
        return null;
      }
      return this.markedRecords.getAll();
    },
  },

  mounted: function () {
    /**
     * Make the edit tag modal a global ref
     */
    this.$root.$refs[this.tagModalId] = this.$refs[this.tagModalId];
  },

  methods: {
    /**
     * Change status
     */
    changeStatus: function (type, which) {
      // setup the modal to ask the user
      let whichDesc =
        which == "m" ? " marked" : which == "u" ? " unmarked" : "";
      if (type == "delete") {
        this.$refs.modalTitle.innerHTML = "Delete" + whichDesc + " records";
        if (this.contactId !== null) {
          this.$refs.modalMessage.innerHTML =
            "Are you sure that you want to permanently delete this record?<br><br>" +
            "Please note that once a record has been permanently deleted, it cannot be restored.";
        } else {
          this.$refs.modalMessage.innerHTML =
            "Are you sure that you want to permanently delete all the" +
            whichDesc +
            " records in your search results?<br>" +
            "Any records which have been restored will be archived instead of permanently deleted.<br><br>" +
            "Please note that once a record has been permanently deleted, it cannot be restored.";
        }
        this.$refs.modalMessage.className = "statuschangemodal-message";
      } else {
        this.$refs.modalTitle.innerHTML =
          type.substring(0, 1).toUpperCase() +
          type.substring(1) +
          whichDesc +
          " records";
        this.$refs.modalMessage.innerHTML =
          "Are you sure that you want to " +
          type +
          (this.contactId !== null
            ? " this record?"
            : " all the" + whichDesc + " records in your search results?");
        this.$refs.modalMessage.className = "statuschangemodal-message";
      }

      this.$refs.modalCloseBtn.disabled = false;
      this.$refs.modalOkBtn.disabled = false;
      this.$refs.modalCancelBtn.disabled = false;
      window.$("#statuschangemodal_" + this.location).modal("show");

      // remember what we are doing
      this.statusChangeType = type;
      this.statusChangeWhich = which;
    },

    /**
     * Displays the tag modal when the tag action
     * is clicked
     */
    tagBtnClicked: function (event) {
      // show the tag modal
      this.$bvModal.show(this.tagModalId);
      this.$emit("tagsBtnClick", event);
    },

    /**
     * Triggers when tags have been saved in the
     * tag modal, and will refresh the data
     */
    onTagsApplied: function (tagsToRemove, tagsToAdd) {
      if (this.usePageRefresh) {
        this.$refs.refreshLink.click();
      }
      /**
       * Tags applied event
       *
       * @event
       *
       * @property {array} tagsToRemove List of tagIds to remove from the product
       * @property {array} tagsToAdd List of tagIds to add to the product
       */
      this.$emit("tagsApplied", tagsToRemove, tagsToAdd);
    },

    /**
     * Triggers when a new tag is created within
     * the tag modal
     */
    onTagCreated: function (id, name, level) {
      /**
       * Tags created event
       *
       * @event
       *
       * @property {number} id The ID of the created tag
       * @property {string} name The name of the tag
       * @property {string} level The level of the tag 'USER' or 'GLOBAL'
       */
      this.$emit("tagCreated", id, name, level);
    },

    /**
     * Displays the export modal
     */
    exportClicked: function () {
      // show the export modal
      this.$emit("exportClicked");
      this.$refs[this.exportModalId].showModal(true);
    },

    /**
     * Displays the modal for sending
     * a contact as an output
     */
    outputEmailClicked: function () {
      this.$emit("outputEmailClicked");
      this.$refs[this.emailDetailedRecordPdfModalId].displayModal();
    },
  },
};
</script>

<style scoped>
ul.contacts_resultslist_menubar {
  margin: 2px 0px;
  padding-left: 5px;
}
ul.contacts_resultslist_menubar > li {
  display: inline;
  list-style-type: none;
  padding-right: 5px;
}
a.contacts_resultslist_hiddenlink {
  visibility: hidden;
}
</style>
