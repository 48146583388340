<template>
  <b-card :title="product.MainTitle">
    <dl class="row">
      <dt class="col-sm-4 font-weight-bold text-secondary">Record Number</dt>
      <dd class="col-sm-8">
        {{ product.RecordNumber | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">ISBN</dt>
      <dd class="col-sm-8">
        <template v-if="product['ISBN-13']">
          {{ product["ISBN-13"] }}
        </template>
        <template v-else-if="product.EAN">
          {{ product["EAN"] }}
        </template>
        <template v-else-if="product.ASIN">
          {{ product["ASIN"] }}
        </template>
        <template v-else>-</template>
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Format</dt>
      <dd class="col-sm-8">
        {{ product.Format | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Out of Royalties</dt>
      <dd class="col-sm-8">
        <template v-if="outOfRoyalties">Yes</template>
        <template v-else>No</template>
      </dd>
    </dl>
    <div v-if="payees" class="row">
      <div
        v-for="(payee, index) in payees"
        :key="index"
        class="col-12 col-md-6"
      >
        <royalties-payee-descriptive-list-card
          :payee="payee"
        ></royalties-payee-descriptive-list-card>
      </div>
    </div>
    <div v-if="channels" class="row">
      <div v-for="(channel, index) in channels" :key="index" class="col-12">
        <royalties-channel-descriptive-list-card
          :channel="channel"
        ></royalties-channel-descriptive-list-card>
      </div>
    </div>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list of product royalty data
 */

import DisplaySetting from "../../mixins/productDisplaySetting.js";
import RoyaltiesPayeeDescriptiveListCard from "./RoyaltiesPayeeDescriptiveListCard.vue";
import RoyaltiesChannelDescriptiveListCard from "./RoyaltiesChannelDescriptiveListCard.vue";

export default {
  name: "RoyaltiesProductDescriptiveListCard",

  components: {
    "royalties-payee-descriptive-list-card": RoyaltiesPayeeDescriptiveListCard,
    "royalties-channel-descriptive-list-card":
      RoyaltiesChannelDescriptiveListCard,
  },

  mixins: [DisplaySetting],

  props: {
    /**
     * Product data
     *
     * Object which contains the following
     * fields from the BooksonixProductFormatter:
     *  - RecordNumber
     *  - MainTitle
     *  - Format
     *  - ISBN-13
     *  - EAN
     *  - ASIN
     *  - PublisherUniqueID
     */
    product: {
      type: Object,
      required: true,
    },

    /**
     * Whether the product is out of royalties
     */
    outOfRoyalties: {
      type: Boolean,
      default: false,
    },

    /**
     * List of royalty payees  which is an array of
     * formatted contacts, each element should have the following fields from
     * the BooksonixContactFormatter:
     *  - RecordNumber
     *  - DisplayName
     *  - PayeeAccountingCode
     *  - PayeeStatementCurrency
     *  - PayeeMinPayout
     *  - PayeeTaxPercent
     *  - PayeeMinPayoutInStatementCurrency
     *  - PayeeVatNumber
     */
    payees: {
      type: Array,
      default: null,
    },

    /**
     * Array of royalty channels for the product
     */
    channels: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style></style>
