<template>
  <div class="contacts_resultslist_tags">
    <template v-for="(tag, tagNo) in tags">
      <div
        :key="tagNo"
        :class="getClass(tag)"
        :style="getStyle(tag)"
        :title="tag.name"
      >
        <i class="fa fa-tag"></i>
      </div>
    </template>
  </div>
</template>

<script>
/**
 * Display the Contacts Results List Tags segment
 */
export default {
  name: "ResultsListTags",

  props: {
    /**
     * Tags data
     */
    tags: {
      type: Array,
      required: true,
    },
  },

  methods: {
    /*
     * Get the class attribute for a tag's DIV
     * note: class name 'tags_item' is defined in sass/app.scss
     */
    getClass: function (tag) {
      return "tags_item" + (tag.colour ? " tags_colour" + tag.colour : "");
    },

    /*
     * Get the style attribute for a tag's DIV, this will contains literal colour values so it cannot be a class
     */
    getStyle: function (tag) {
      if (tag.textColour && tag.backColour) {
        return (
          "color:" +
          tag.textColour +
          "; background-color:" +
          tag.backColour +
          ";"
        );
      }
      return null;
    },
  },
};
</script>

<style>
div.contacts_resultslist_tags {
  float: right;
  margin: 0px 5px;
  overflow: hidden;
}
div.contacts_resultslist_tags div.tags_item {
  float: left;
  padding: 2px;
  margin-right: 2px;
  margin-bottom: 5px;
}
</style>
