<template>
  <b-modal :id="id" size="lg" :static="true" @shown="resetForm">
    <template #modal-title> <h5>Upload media files to product</h5> </template>
    <div v-if="loading">
      <loading-spinner text="Loading..."></loading-spinner>
    </div>
    <div v-else>
      <div v-if="pageStatus == 'form'">
        <b-form id="mediaFiles" @submit.stop.prevent="onSubmit">
          <b-form-row v-for="(file, index) in formData.files" :key="index">
            <div class="col-sm-12">
              <h5 class="d-inline-block">
                {{ file.file.name }}
                <b-badge v-if="file.exists" variant="danger"
                  >A file with this name already exists and will be
                  overwritten</b-badge
                >
              </h5>
            </div>
            <div v-if="mediaTypes" class="col">
              <label class="mr-sm-2" for="type">Type</label>
              <b-form-select
                id="type"
                v-model="file.mediaType"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="typeList"
                required
              ></b-form-select>
            </div>
            <div class="col">
              <label class="mr-sm-2" for="link">Link</label>
              <b-form-select
                id="link"
                v-model="file.linkType"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="[
                  { value: '01', text: 'URL' },
                  { value: '06', text: 'filename' },
                ]"
                required
              ></b-form-select>
            </div>
            <div v-if="mediaFormats" class="col">
              <label class="mr-sm-2" for="format">Format</label>
              <b-form-select
                id="format"
                v-model="file.mediaFormat"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="formatList"
                required
              ></b-form-select>
            </div>
            <div class="col">
              <label class="mr-sm-2" for="format">Folder</label>
              <b-form-select
                id="format"
                v-model="file.folder"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="folderList"
                @input="
                  checkFileExists(file.file.name, file.folder).then(
                    (response) => {
                      file.exists = response;
                    }
                  )
                "
              ></b-form-select>
            </div>
            <div class="col">
              <label class="mr-sm-2" for="internal"></label>
              <b-form-checkbox
                id="internal"
                v-model="file.internal"
                class="mb-2 mt-3 ml-4 mr-sm-2 mb-sm-0"
                >Internal</b-form-checkbox
              >
            </div>
            <div v-if="index != formData.files.length - 1" class="col-sm-12">
              <hr />
            </div>
          </b-form-row>
        </b-form>
      </div>
      <div v-if="pageStatus == 'complete'">
        <div class="text-center">
          Files have successfully been uploaded to the media manager and added
          to this product
        </div>
      </div>
      <div v-if="pageStatus == 'error'">
        <div class="text-center">
          Unfortunately an error occured trying to upload the media files.
          Please close this windoe and try again.
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div v-if="pageStatus == 'form'">
        <button
          type="button"
          class="btn btn-default"
          @click="$bvModal.hide(id)"
        >
          Cancel
        </button>
        <button type="submit" form="mediaFiles" class="btn ml-2 btn-success">
          Confirm
        </button>
      </div>
      <div v-if="pageStatus == 'complete' || pageStatus == 'error'">
        <button
          type="button"
          class="btn btn-success"
          @click="$bvModal.hide(id)"
        >
          Okay
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
/**
 * Displays a popup modal that provides a form for simultaneously uploading files
 * to the media manager and a product
 */

import { HTTP } from "../../http-common.js";

export default {
  name: "ProductFileUploadModal",

  props: {
    /**
     * The ID of the modal. This must be unique to each
     * instance of the modal. So that this.$bvModal.show('id')
     * can be used.
     */
    id: {
      type: String,
      required: true,
    },

    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * The FileList to be displayed in the modal
     */
    fileList: {
      type: FileList,
      default: () => {},
    },

    /**
     * The list of available types Onix resource type21's
     */
    types: {
      type: Array,
      default: () => [],
    },

    /**
     * The list of media types available for product media
     *
     * Use BooksonixProductHelper getResourceTypes21
     */
    mediaTypes: {
      type: Array,
      required: true,
    },

    /**
     * The list of media formats available for product media
     *
     * Use BooksonixProductHelper getResourceFormats21
     */
    mediaFormats: {
      type: Array,
      required: true,
    },

    /**
     * The current user logon ID
     *
     * This is required for requests to the media manager API
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * The logged in users ID Number
     *
     * This is required for requests to the media manager API
     */
    userIdNumber: {
      type: Number,
      required: true,
    },

    /**
     * List of product media
     */
    productMedia: {
      type: Array,
      default: () => [],
    },
  },

  data: () => {
    return {
      formData: {
        files: [],
      },
      loading: false,
      folders: [],
      pageStatus: "form",
    };
  },

  computed: {
    formatList: function () {
      let formatList = this.mediaFormats.map((value) => {
        return {
          value: value.code,
          text: value.name,
        };
      });
      formatList.unshift({ value: null, text: "Select a format" });
      return formatList;
    },

    typeList: function () {
      let typeList = this.mediaTypes.map((value) => {
        return {
          value: value.code,
          text: value.name,
        };
      });
      typeList.unshift({ value: null, text: "Select a type" });
      return typeList;
    },

    /**
     * Iterate through the folders and create a dropdown item for each one
     *
     * Each folder can have it's own subfolders
     */
    folderList: function () {
      let folderList = [];
      for (let folder of this.folders.folders) {
        let path = folder.name;
        folderList.push({ value: folder.fileId, text: path });
        if (folder.folders.length > 0) {
          folderList.push(...this.getSubfolderList(path, folder.folders));
        }
      }
      folderList.unshift({ value: null, text: "Select a folder" });
      return folderList;
    },
  },

  watch: {
    /**
     * Reset the form data when the fileList changes
     */
    async fileList(files) {
      this.formData.files = [];
      for (let fileKey in Array.from(files)) {
        let file = {
          mediaType: null,
          linkType: "06",
          // calculate the format based on the filename extension
          mediaFormat: this.getFormatFromFilename(files[fileKey].name),
          folder: null,
          internal: false,
          exists: await this.checkFileExists(files[fileKey].name),
          file: files[fileKey],
        };
        this.formData.files.push(file);
      }
    },
  },

  mounted: function () {
    this.getFolderList().then((response) => {
      this.folders = response.data;
      this.loading = false;
    });
  },

  methods: {
    /**
     * Handles submitting the modal form
     *
     * Chains the neccesary ajax requests
     *  - Uploading the files to the media manager
     *  - Saving the files to the product media files
     */
    onSubmit: async function () {
      // first all files need to be uploaded to the media manager
      this.loading = true;
      for (let file of this.formData.files) {
        try {
          let response = await HTTP.put(
            // build the URL
            "/scripts/BsnxMediaFiles/upload/" +
              this.$appId +
              "/" +
              (file.folder != null ? file.folder : "0") +
              "/" +
              file.file.name,
            file.file,
            {
              // add params
              params: { M: file.file.lastModified, U: this.userIdNumber },

              // add headers
              headers: {
                "x-booksonix-sessionid": this.logonId,
                "Content-Type": file.file.type,
              },
            }
          );

          /**
           * On a successful response update the form data with the new fileId
           */
          file.fileId = response.fileId;
        } catch (error) {
          /**
           * On an unsuccessful response set the fileId to null so that it won't
           * try and save to the product
           */
          file.fileId = null;
        }
      }

      // files are uploaded to the media manager - they now need to be saved to the product
      try {
        // build the form data required by the biblio edit media
        let editMediaFormData = new FormData();
        editMediaFormData.append("A", this.$appId);
        editMediaFormData.append("L", this.logonId);
        // build form data for existing media
        let fileIndex = 1;
        for (const file of this.productMedia) {
          editMediaFormData.append("FLXMT" + fileIndex, file.getType21);
          editMediaFormData.append("FLXMF" + fileIndex, file.getFormat21);
          editMediaFormData.append("FLXLT" + fileIndex, file.getLinkType);
          editMediaFormData.append("FLXLV" + fileIndex, file.getLink);
          if (file.getInternal) {
            editMediaFormData.append("FLXMI" + fileIndex, "I");
          }
          fileIndex++;
        }

        // build form data for the new files
        for (const file of this.formData.files) {
          editMediaFormData.append("FLXMT" + fileIndex, file.mediaType);
          editMediaFormData.append("FLXMF" + fileIndex, file.mediaFormat);
          editMediaFormData.append("FLXLT" + fileIndex, file.linkType);
          if (file.folder) {
            let folderName = this.folderList.find(
              (folder) => folder.value == file.folder
            ).text;
            editMediaFormData.append(
              "FLXLV" + fileIndex,
              folderName + "/" + file.file.name
            );
          } else {
            editMediaFormData.append("FLXLV" + fileIndex, file.file.name);
          }
          if (file.internal) {
            editMediaFormData.append("FLXMI" + fileIndex, "I");
          }
          fileIndex++;
        }

        const response = await HTTP.post(
          this.$baseUrl + "biblio-edit/edit-media/" + this.productId,
          editMediaFormData
        );

        // stop displaying the loading icon
        this.loading = false;
        this.pageStatus = "complete";

        /**
         * Files uploaded event
         *
         * Triggers when files have finished uploading and are saved
         * to the product.
         *
         * @property {object} formData Contains the new file information
         * * @property {object} mediaFiles The response of media files returned from biblio/edit-media
         */
        this.$emit("files-uploaded", response.data);
      } catch (error) {
        return false;
      }
      return true;
    },

    /**
     * Checks whether a file already exists in the media manager
     *
     * @param {string} filename Name of the file to be checked
     */
    checkFileExists: async function (filename, folderId = null) {
      if (folderId == null) {
        folderId = "0";
      }
      try {
        const response = await HTTP.get(
          "/scripts/BsnxMediaFiles/info/" +
            this.$appId +
            "/" +
            folderId +
            "/" +
            filename,
          {
            params: { L: this.logonId },
            headers: {
              "x-booksonix-sessionid": this.logonId,
            },
          }
        );
        if (response.status == 404) {
          return false;
        }
        return true;
      } catch (error) {
        this.status = "error";
        return false;
      }
    },

    /**
     * Calculates the file format code based on the
     * files extension
     *
     * @param {String} filename The name of the file
     */
    getFormatFromFilename: function (filename) {
      if (!filename.includes(".")) {
        return null;
      }
      let ext = filename.split(".").pop();
      // check if the extension is in the media list
      for (let format of this.mediaFormats) {
        if (format.extensions && format.extensions.includes(ext)) {
          return format.code;
        }
      }
      return null;
    },

    /**
     * Retrieve the list of folders via ajax from the media manager API
     */
    getFolderList: function () {
      return HTTP.get("/scripts/BsnxMediaFiles/folders/" + this.$appId, {
        headers: {
          "x-booksonix-sessionid": this.logonId,
        },
      });
    },

    /**
     * Function to be called recursively to create a list of folders
     * as every folder can have it's own subfolders
     *
     * @param {string} path The folder path that the subfolders are in
     * @param {array} folders The folder list to loop through
     */
    getSubfolderList: function (path, folders) {
      let folderList = [];
      for (let folder of folders) {
        path = path + "/" + folder.name;
        folderList.push({ value: folder.fileId, text: path });
        if (folder.folders.length > 0) {
          folderList.push(...this.getSubfolderList(path, folder.folders));
        }
      }
      return folderList;
    },

    resetForm: function () {
      this.pageStatus = "form";
      this.loading = false;
    },
  },
};
</script>

<style></style>
