<template>
  <div>
    <quick-search-tag
      v-for="tag in tagList"
      :key="tag.id"
      v-b-tooltip.hover
      class="mr-2 mb-2"
      :tag="tag"
      :search-id="searchId"
      :position="position"
      :record-type="recordType"
      element="a"
      title="Click to view all active records with this tag"
    ></quick-search-tag>
    <icon-btn
      v-if="tagList && tagList.length > 0 && allowEdit"
      icon="edit"
      @click="displayTagModal()"
    ></icon-btn>
    <edit-tags-modal
      :id="modalId"
      :ref="modalId"
      :record-ids="[recordId]"
      :record-type="recordType"
      @tagsApplied="onTagsApplied"
      @tagCreated="onTagCreated"
    ></edit-tags-modal>
  </div>
</template>

<script>
/**
 * Displays a list of tags assigned to a record
 *
 * Each tag displays as a quick search so all
 * records can be viewed with the same tag when clicked.
 *
 * This component also provides an edit option for the tags
 * on a record
 *
 * A record can be a booksonix product or contact
 */

import QuickSearchTag from "../searchform/QuickSearchTag.vue";
import EditTagsModal from "../pages/modals/EditTagsModal.vue";

export default {
  name: "AssignedTags",

  components: {
    "quick-search-tag": QuickSearchTag,
    "edit-tags-modal": EditTagsModal,
  },

  props: {
    /**
     * The ID of the record
     */
    recordId: {
      type: Number,
      default: null,
    },

    /**
     * The list of tags for the record
     *
     */
    tags: {
      type: Array,
      default: null,
    },

    /**
     * Complete list of tags available to
     * be assigned to the record
     */
    allTags: {
      type: Array,
      default: null,
    },

    /**
     * Set to false to disable the edit option
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Position / Page number
     */
    position: {
      type: Number,
      default: null,
    },

    /**
     * The type of record for which the tags are displaying
     *
     * @options product, contact
     */
    recordType: {
      type: String,
      default: "product",
    },
  },

  data: function () {
    return {
      modalId: "tagsEditModal",
      localTags: this.tags ? this.tags : [],
      localAllTags: this.allTags,
    };
  },

  computed: {
    tagList: function () {
      if (!this.localTags) {
        return [];
      }
      let formattedTags = [];
      for (let tag of this.localTags) {
        formattedTags.push({
          id: tag.getId,
          name: tag.getName,
          level: tag.getType,
          colour: tag.getTextColour ?? tag.getColour ?? null,
          bkcolour: tag.getBackgroundColour ?? null,
        });
      }
      return formattedTags;
    },
  },

  mounted: function () {
    /**
     * Make the edit tag modal a global ref
     */
    this.$root.$refs[this.modalId] = this.$refs[this.modalId];
  },

  methods: {
    /**
     * Displays the modal
     */
    displayTagModal: function () {
      this.$bvModal.show(this.modalId);
    },

    /**
     * Called when a tag is created, adds the tag
     * to the taglist
     */
    onTagCreated: function (id, name, level) {
      this.localAllTags.push({
        getId: id,
        getName: name,
        getType: level,
      });
    },

    /**
     * Called when tags are saved, updates
     * the current tags being displayed
     */
    onTagsApplied: function (tagsToRemove, tagsToAdd) {
      // remove tags
      this.localTags = this.localTags.filter(
        (tag) =>
          !tagsToRemove.find((tagToRemove) => {
            return tag.getId === parseInt(tagToRemove);
          })
      );
      // add new tags
      for (let tagId of tagsToAdd) {
        var foundTag = this.localAllTags.find((tag) => {
          return tag.getId === parseInt(tagId);
        });
        if (foundTag) {
          this.localTags.push(foundTag);
        }
      }
    },
  },
};
</script>

<style></style>
