<template>
  <b-card id="productCardTitleAndIdentifiers" style="height: 100%">
    <template #header>
      <a id="title-and-identifiers" />
      <h5 class="d-inline-block">Title &amp; Identifiers</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit && display !== 'print'"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-title/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
        data-v-step="biblio-detail-edit"
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right m-0"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <div v-if="!product" class="row">
      <div class="col">
        No Title &amp; Identifiers information for this product
      </div>
    </div>
    <dl v-if="product" class="row">
      <template v-if="product.getRecordNumber != null">
        <dt class="col-sm-4 font-weight-bold text-secondary">Record Number</dt>
        <dd class="col-sm-8">{{ product.getRecordNumber }}</dd>
      </template>
      <template
        v-if="
          product.getProductStatus.getProductVisibility != null || showAllFields
        "
      >
        <dt class="col-sm-4 font-weight-bold text-secondary">
          Record Visibility
        </dt>
        <dd class="col-sm-8">
          {{ productVisibilityText | valueOrPlaceholder }}
        </dd>
      </template>
      <template v-if="product.getPublisherUniqueID != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">
          Publisher Unique ID
        </dt>
        <dd class="col-sm-8">
          {{ product.getPublisherUniqueID | valueOrPlaceholder }}
        </dd>
      </template>
      <template v-if="product.getProductStatus.getProductStatus == 'T'">
        <dt class="col-sm-4 font-weight-bold text-secondary">Template Name</dt>
        <dd class="col-sm-8">
          {{ product.getProductStatus.getTemplateName }}
        </dd>
      </template>
      <template v-if="product.getMainTitle != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">Title</dt>
        <dd class="col-sm-8">
          {{ product.getMainTitle | valueOrPlaceholder }}
        </dd>
      </template>
      <template v-if="product.getMainTitleSubtitle != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">Subtitle</dt>
        <dd class="col-sm-8">
          {{ product.getMainTitleSubtitle | valueOrPlaceholder }}
        </dd>
      </template>
      <template v-if="product.getAbbreviatedTitle != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">
          Abbreviated Title
        </dt>
        <dd class="col-sm-8">
          {{ product.getAbbreviatedTitle | valueOrPlaceholder }}
        </dd>
      </template>
      <template v-if="product.getDistributorsTitle != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">
          Distributor's Title
        </dt>
        <dd class="col-sm-8">
          {{ product.getDistributorsTitle | valueOrPlaceholder }}
        </dd>
      </template>
      <template v-if="product.getPreviousTitle != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">Previous Title</dt>
        <dd class="col-sm-8">
          {{ product.getPreviousTitle | valueOrPlaceholder }}
        </dd>
      </template>
      <template
        v-if="product.getTitleInOriginalLanguage != null || showAllFields"
      >
        <dt class="col-sm-4 font-weight-bold text-secondary">
          Title in Original Language
        </dt>
        <dd class="col-sm-8">
          {{ product.getTitleInOriginalLanguage | valueOrPlaceholder }}
        </dd>
      </template>
      <template v-if="product.getGTIN13 != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">
          EAN/JAN (GTIN-13)
        </dt>
        <dd class="col-sm-8">
          {{ product.getGTIN13 | valueOrPlaceholder }}
          <barcode-link
            v-if="product.getGTIN13"
            id="barcode-ean"
            type="EAN"
            :value="product.getGTIN13"
            :logon-id="logonId"
            :prices="barcodePrices"
          ></barcode-link>
        </dd>
      </template>
      <template v-if="product.getISBN13 != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">ISBN-13</dt>
        <dd class="col-sm-8">
          <div>
            {{ product.getISBN13 | valueOrPlaceholder }}
            <barcode-link
              v-if="product.getISBN13"
              id="barcode-isbn13"
              type="ISBN"
              :value="product.getISBN13Hyphenated"
              :logon-id="logonId"
              :prices="barcodePrices"
            ></barcode-link>
          </div>
          <div v-if="product.getISBN13Hyphenated">
            ({{ product.getISBN13Hyphenated }})
          </div>
        </dd>
      </template>
      <template v-if="product.getISBN10 != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">ISBN-10</dt>
        <dd class="col-sm-8">
          <div>
            {{ product.getISBN10 | valueOrPlaceholder }}
            <barcode-link
              v-if="product.getISBN10"
              id="barcode-isbn10"
              type="ISBN"
              :value="product.getISBN10Hyphenated"
              :logon-id="logonId"
              :prices="barcodePrices"
            ></barcode-link>
          </div>
          <div v-if="product.getISBN10Hyphenated">
            ({{ product.getISBN10Hyphenated }})
          </div>
        </dd>
      </template>
      <template v-if="product.getUPC != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">UPC</dt>
        <dd class="col-sm-8">
          {{ product.getUPC | valueOrPlaceholder }}
          <barcode-link
            v-if="product.getUPC"
            id="barcode-upc"
            type="UPC"
            :value="product.getUPC"
            :logon-id="logonId"
            :prices="barcodePrices"
          ></barcode-link>
        </dd>
      </template>
      <template v-if="product.getLCCN != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">LCCN</dt>
        <dd class="col-sm-8">{{ product.getLCCN | valueOrPlaceholder }}</dd>
      </template>
      <template v-if="product.getDOI != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">DOI</dt>
        <dd class="col-sm-8">{{ product.getDOI | valueOrPlaceholder }}</dd>
      </template>
      <template v-if="product.getISSN != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">ISSN</dt>
        <dd class="col-sm-8">{{ product.getISSN | valueOrPlaceholder }}</dd>
      </template>
      <template v-if="product.getASIN != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">ASIN</dt>
        <dd class="col-sm-8">{{ product.getASIN | valueOrPlaceholder }}</dd>
      </template>
      <template
        v-if="product.getAppleProductIdentifier != null || showAllFields"
      >
        <dt class="col-sm-4 font-weight-bold text-secondary">
          Apple Product Identifier
        </dt>
        <dd class="col-sm-8">
          {{ product.getAppleProductIdentifier | valueOrPlaceholder }}
        </dd>
      </template>
      <template
        v-if="
          (product.hasOwnProperty('getAllTextualWorkIdentifiers') &&
            Object.keys(product.getAllTextualWorkIdentifiers).length > 0) ||
          showAllFields
        "
      >
        <dt class="col-sm-4 font-weight-bold text-secondary">
          Textual Work Identifier
        </dt>
        <template
          v-if="
            !(
              product.hasOwnProperty('getAllTextualWorkIdentifiers') &&
              Object.keys(product.getAllTextualWorkIdentifiers).length > 0
            )
          "
          ><dd class="col-sm-8">—</dd></template
        >
        <template v-else>
          <dd class="col-sm-8"></dd>
          <template
            v-for="(twi, key, index) in product.getAllTextualWorkIdentifiers"
            class="row"
          >
            <dt :key="'twi-1-' + index" class="col-sm-4 pl-5 text-secondary">
              {{ twi.twiTypeName }}
            </dt>
            <dd :key="'twi-2-' + index" class="col-sm-8">
              <a
                :href="
                  $baseUrl +
                  'search/link/biblio/' +
                  searchId +
                  '/' +
                  page +
                  '/biblio?twid=' +
                  twi.twi
                "
                >{{ twi.twi }}</a
              >
              <barcode-link
                v-if="twi.twiHyphenated"
                id="barcode-twi"
                class="pl-2"
                type="ISBN"
                :value="twi.twiHyphenated"
                :logon-id="logonId"
                :prices="barcodePrices"
              ></barcode-link>
            </dd>
          </template>
        </template>
      </template>
      <template
        v-if="
          (product.hasOwnProperty('getBarcodes') &&
            product.getBarcodes.get.length > 0) ||
          showAllFields
        "
      >
        <dt class="col-sm-4 font-weight-bold text-secondary">Barcode</dt>
        <template
          v-if="
            !(
              product.hasOwnProperty('getBarcodes') &&
              product.getBarcodes.get.length > 0
            )
          "
        >
          <dd class="col-sm-8">—</dd></template
        >
        <template v-else>
          <dd class="col-sm-8"></dd>
          <template v-for="barcode in product.getBarcodes.get" class="row">
            <dt
              :key="'barcode-1-' + barcode['getTableId']"
              class="col-sm-4 pl-5 text-secondary"
            ></dt>
            <dd :key="'barcode-2-' + barcode['getTableId']" class="col-sm-8">
              {{ barcode.getName }}
            </dd>
          </template>
        </template>
      </template>
      <template
        v-if="
          (product.hasOwnProperty('getSeries') &&
            product.getSeries.get.length > 0) ||
          showAllFields
        "
      >
        <template
          v-if="
            !(
              product.hasOwnProperty('getSeries') &&
              product.getSeries.get.length > 0
            )
          "
        >
          <dt class="col-sm-4 font-weight-bold text-secondary">Series</dt>
          <dd class="col-sm-8">—</dd>
        </template>
        <template v-else>
          <template v-for="series in product.getSeries.get">
            <dt
              :key="'series-0-' + series.getTableId"
              class="col-sm-4 text-secondary"
            >
              Series
            </dt>
            <dd :key="'series-dd-0-' + series.getTableId" class="col-sm-8"></dd>
            <template v-if="series.getTitle != null">
              <dt
                :key="'series-1-' + series.getTableSequence"
                class="col-sm-4 pl-5 text-secondary"
              >
                Series Title
              </dt>
              <dd :key="'series-2-' + series.getTableSequence" class="col-sm-8">
                {{ series.getTitle }}
              </dd>
            </template>
            <template
              v-if="
                series.getAllSeriesIdentifiers &&
                Object.keys(series.getAllSeriesIdentifiers).length > 0
              "
            >
              <dt
                :key="'series-identifier' + series.getTableId"
                class="col-sm-4 pl-5 text-secondary"
              >
                Series Identifier
              </dt>
              <dd
                :key="'series-identifier-dd' + series.getTableId"
                class="col-sm-8"
              >
                <div
                  v-for="(
                    identifier, idIndex
                  ) in series.getAllSeriesIdentifiers"
                  :key="'seriesIdentifier-' + idIndex"
                >
                  {{ identifier.typeName }} :
                  {{ identifier.value }}
                </div>
              </dd>
            </template>
            <template v-if="series.getNumberInSeries != null">
              <dt
                :key="'series-3-' + series.getTableSequence"
                class="col-sm-4 pl-5 text-secondary"
              >
                Number in Series
              </dt>
              <dd :key="'series-4-' + series.getTableSequence" class="col-sm-8">
                {{ series.getNumberInSeries }}
              </dd>
            </template>
            <template v-if="series.getYearOfAnnual != null">
              <dt
                :key="'series-5-' + series.getTableSequence"
                class="col-sm-4 pl-5 text-secondary"
              >
                Year of Annual
              </dt>
              <dd :key="'series-6-' + series.getTableSequence" class="col-sm-8">
                {{ series.getYearOfAnnual }}
              </dd>
            </template>
          </template>
        </template>
      </template>
      <template
        v-if="
          (product.hasOwnProperty('getSets') &&
            product.getSets.get.length > 0) ||
          showAllFields
        "
      >
        <template
          v-if="
            !(
              product.hasOwnProperty('getSets') &&
              product.getSets.get.length > 0
            )
          "
        >
          <dt class="col-sm-4 font-weight-bold text-secondary">Sets</dt>
          <dd class="col-sm-8">—</dd>
        </template>
        <template v-else>
          <template v-for="set in product.getSets.get">
            <dt
              :key="'set-0-' + set.getTableId"
              class="col-sm-4 text-secondary"
            >
              Set
            </dt>
            <dd :key="'set-dd-0-' + set.getTableId" class="col-sm-8"></dd>
            <template v-if="set.getTitle != null">
              <dt
                :key="'set-1-' + set.getTableId"
                class="col-sm-4 pl-5 text-secondary"
              >
                Set Title
              </dt>
              <dd :key="'set-2-' + set.getTableId" class="col-sm-8">
                {{ set.getTitle }}
              </dd>
            </template>
            <template v-if="set.getSubsetNumber != null">
              <dt
                :key="'set-3-' + set.getTableId"
                class="col-sm-4 pl-5 text-secondary"
              >
                Subset Number
              </dt>
              <dd :key="'set-4-' + set.getTableId" class="col-sm-8">
                {{ set.getSubsetNumber }}
              </dd>
            </template>
            <template v-if="set.getSubsetTitle != null">
              <dt
                :key="'set-5-' + set.getTableId"
                class="col-sm-4 pl-5 text-secondary"
              >
                Subset Title
              </dt>
              <dd :key="'set-6-' + set.getTableId" class="col-sm-8">
                {{ set.getSubsetTitle }}
              </dd>
            </template>
            <template v-if="set.getItemNumber != null">
              <dt
                :key="'set-7-' + set.getTableId"
                class="col-sm-4 pl-5 text-secondary"
              >
                Item Number
              </dt>
              <dd :key="'set-8-' + set.getTableId" class="col-sm-8">
                {{ set.getItemNumber }}
              </dd>
            </template>
            <template v-if="set.getItemTitle != null">
              <dt
                :key="'set-9-' + set.getTableId"
                class="col-sm-4 pl-5 text-secondary"
              >
                Item Title
              </dt>
              <dd :key="'set-10-' + set.getTableId" class="col-sm-8">
                {{ set.getItemTitle }}
              </dd>
            </template>
            <template
              v-if="
                set.getAllProductIdentifiers &&
                Object.keys(set.getAllProductIdentifiers).length > 0
              "
            >
              <dt
                :key="'set-identifier' + set.getTableId"
                class="col-sm-4 pl-5 text-secondary"
              >
                Identifier of the Set record
              </dt>
              <dd :key="'set-identifier-dd' + set.getTableId" class="col-sm-8">
                <div
                  v-for="(identifier, idIndex) in set.getAllProductIdentifiers"
                  :key="'setIdentifier-' + idIndex"
                >
                  {{ identifier.typeName }} :
                  <a
                    :href="
                      $baseUrl +
                      'search/link/biblio/' +
                      searchId +
                      '/' +
                      page +
                      '/biblio?ALLID=' +
                      identifier.value
                    "
                    target="_blank"
                    >{{ identifier.value }}</a
                  >
                  <barcode-link
                    v-if="identifier.value"
                    :id="'barcode-set-' + set.getTableId + '-' + idIndex"
                    class="pl-2"
                    type="ISBN"
                    :value="identifier.value"
                    :logon-id="logonId"
                    :prices="barcodePrices"
                  ></barcode-link>
                </div>
              </dd>
            </template>
          </template>
        </template>
      </template>
      <template
        v-if="
          (product.hasOwnProperty('getPublishers') &&
            product.getPublishers.get.length > 0) ||
          showAllFields
        "
      >
        <template
          v-if="
            !(
              product.hasOwnProperty('getPublishers') &&
              product.getPublishers.get.length > 0
            )
          "
        >
          <dt class="col-sm-4 font-weight-bold text-secondary">Publisher</dt>
          <dd class="col-sm-8">—</dd>
        </template>
        <template v-else>
          <template v-for="publisher in product.getPublishers.get">
            <dt
              :key="'publisher-1-' + publisher.getTableId"
              class="col-sm-4 font-weight-bold text-secondary"
            >
              Publisher
            </dt>
            <dd :key="'publisher-2-' + publisher.getTableId" class="col-sm-8">
              {{ publisher.getName }} ({{ publisher.getRoleName }})
              <template
                v-if="
                  publisher.getWebsites &&
                  publisher.getWebsites.length > 0 &&
                  publisher.getWebsites[0].getLinkUrl !== null
                "
              >
                :
                <a
                  :href="parseWebsiteUrl(publisher.getWebsites[0].getLinkUrl)"
                  target="_blank"
                  >{{ publisher.getWebsites[0].getLinkUrl }}</a
                ></template
              >
            </dd>
          </template>
        </template>
      </template>
      <template
        v-if="
          (product.hasOwnProperty('getImprints') &&
            product.getImprints.get.length > 0) ||
          showAllFields
        "
      >
        <template
          v-if="
            !(
              product.hasOwnProperty('getImprints') &&
              product.getImprints.get.length > 0
            )
          "
        >
          <dt class="col-sm-4 font-weight-bold text-secondary">Imprint</dt>
          <dd class="col-sm-8">—</dd>
        </template>
        <template v-else>
          <template v-for="imprint in product.getImprints.get">
            <dt
              :key="'imprint-1-' + imprint.getTableId"
              class="col-sm-4 font-weight-bold text-secondary"
            >
              Imprint
            </dt>
            <dd :key="'imprint-2-' + imprint.getTableId" class="col-sm-8">
              {{ imprint.getName }}
            </dd>
          </template>
        </template>
      </template>
      <template
        v-if="product.getPublicationCountryName != null || showAllFields"
      >
        <dt class="col-sm-4 font-weight-bold text-secondary">
          Publication Country
        </dt>
        <dd class="col-sm-8">
          {{ product.getPublicationCountryName | valueOrPlaceholder }}
        </dd>
      </template>
      <template v-if="product.getPublicationTown != null || showAllFields">
        <dt class="col-sm-4 font-weight-bold text-secondary">
          Publication City/Town
        </dt>
        <dd class="col-sm-8">
          {{ product.getPublicationTown | valueOrPlaceholder }}
        </dd>
      </template>
    </dl>
    <custom-fields
      v-if="product.getCustomFields && product.getCustomFields.getDefinition"
      :custom-fields="product.getCustomFields.getDefinition"
      form="title"
      label-width="4"
    ></custom-fields>
  </b-card>
</template>

<script>
/**
 * Displays a card contaning the title and identifier information
 * for a provided product
 */
import BarcodeLink from "./BarcodeLink.vue";
import CustomFields from "./CustomFields.vue";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";
import ParseUrl from "../../mixins/parseUrl.js";

export default {
  name: "TitleAndIdentifiers",

  components: {
    "barcode-link": BarcodeLink,
    "custom-fields": CustomFields,
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [DisplaySetting, ParseUrl],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * The current user logon ID
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * Product data
     *
     * The data passed in must be an assosiative array (object in JS) of product data.
     * The keys of the array must match those defined in BooksonixProduct.php
     * Use the dump method to get an appropriate aray of product data
     */
    product: {
      type: Object,
      default: () => {},
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    /**
     * Sets the text in the visibility badge based
     * on the products visibility
     */
    productVisibilityText: function () {
      switch (this.product.getProductStatus.getProductVisibility) {
        case "P":
          return "Public";
        case "I":
          return "Internal";
        case "W":
          return "Own Website";
        default:
          return "-";
      }
    },

    /**
     * Formats the prices for the barcodes
     */
    barcodePrices: function () {
      if (!this.product.getSuppliers || !this.product.getSuppliers.get) {
        return null;
      }
      const primarySupplier = this.product.getSuppliers.get.find((supplier) => {
        return supplier.getPosition == 0;
      });
      if (!primarySupplier || !primarySupplier.getPrices) {
        return null;
      }
      return primarySupplier.getPrices.map((price) => {
        return { currency: price.getCurrency, amount: price.getTotalAmount };
      });
    },
  },
};
</script>

<style></style>
