<template>
  <select
    :id="id"
    :name="name"
    class="searchformfielddropdown"
    size="1"
    @change="$emit('change')"
    @input="onInput"
  >
    <template v-for="aField in fieldsList">
      <optgroup v-if="aField.group" :key="aField.group" :label="aField.group">
        <option
          v-for="aGroupField in aField.fields"
          :key="aGroupField.tag"
          :value="aGroupField.tag"
          :selected="aGroupField.tag == currentField"
        >
          {{ aGroupField.name }}
        </option>
      </optgroup>
      <option
        v-else
        :key="aField.tag"
        :value="aField.tag"
        :selected="aField.tag == currentField"
      >
        {{ aField.name }}
      </option>
    </template>
  </select>
</template>

<script>
/**
 * Display a search form field dropdown
 */
export default {
  name: "SearchFormFieldDropdown",

  props: {
    /**
     * The dropdown control's id
     */
    id: {
      type: String,
      required: true,
    },

    /**
     * The dropdown control's name
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * The fields list. An array of field objects and field group objects, field objects have the properties 'tag' (string),
     * 'name' (string) and 'index' (bool), and field group objects have the properties 'group' (string) and 'fields' (array)
     */
    fieldsList: {
      type: Array,
      required: true,
    },

    /**
     * The initial field
     */
    initialField: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      currentField: this.initialField,
    };
  },
  methods: {
    onInput: function (event) {
      this.currentField =
        event.target.selectedIndex >= 0
          ? event.target.options[event.target.selectedIndex].value
          : "";
      this.$emit("input", this.currentField);
    },
  },
};
</script>

<style></style>
