var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-2 product-summary-card",attrs:{"no-body":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col product-card-img mt-2"},[_c('product-media-img',{staticClass:"m-auto",staticStyle:{"height":"130px"},attrs:{"thumbnail":"","product-id":_vm.product.getRecordNumber,"type":"IMG_FRONT","disable-overlay":true,"date-options":_vm.dateOptions,"img-height":130}})],1),_c('div',{staticClass:"col pl-0 pr-2"},[_c('div',{staticClass:"row h-50"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"mt-2 mb-0 product-summary-title"},[_c('a',{attrs:{"href":_vm.productLink}},[_vm._v(_vm._s(_vm._f("truncate")(_vm.product.getMainTitle,120)))]),(
                _vm.product.getProductStatus &&
                _vm.product.getProductStatus.getProductStatus
              )?_c('product-status-badge',{attrs:{"status":_vm.product.getProductStatus.getProductStatus}}):_vm._e(),(
                _vm.product.getProductStatus &&
                _vm.product.getProductStatus.getProductVisibility
              )?_c('product-visibility-badge',{staticClass:"ml-1 product-summary-badge",attrs:{"visibility":_vm.product.getProductStatus.getProductVisibility}}):_vm._e(),(_vm.sameTwi)?_c('b-badge',{staticClass:"ml-1 product-summary-badge",attrs:{"variant":"secondary"}},[_vm._v("Same TWI")]):_vm._e()],1),_c('div',{staticClass:"text-muted pt-1 font-weight-bold related-type-text d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.product.getRelatedTypeName)+" ")])])]),_c('div',{staticClass:"row h-50 align-items-end"},[_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"mr-sm-2 text-muted"},[_vm._v(_vm._s(_vm.displayedIdentifier !== null ? _vm.displayedIdentifier.typeName : "ISBN-13"))]),_c('div',{staticClass:"font-weight-bold text-nowrap"},[_vm._v(" "+_vm._s(_vm.displayedIdentifier !== null ? _vm.displayedIdentifier.value : _vm.product.getISBN13 || "-")+" ")])]),_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"mr-sm-2 text-muted"},[_vm._v("Format")]),_c('div',{staticClass:"font-weight-bold text-truncate"},[_vm._v(" "+_vm._s(_vm.productFormatText || "-")+" ")])]),_c('div',{staticClass:"col-sm-4"},[_c('label',{staticClass:"mr-sm-2 text-muted"},[_vm._v("Published")]),_c('div',{staticClass:"font-weight-bold text-truncate"},[(
                _vm.product.getPublishingDates &&
                _vm.product.getPublishingDates.getPublicationDate
              )?[_vm._v(" "+_vm._s(_vm._f("parseDate")(_vm.product.getPublishingDates.getPublicationDate,_vm.dateOptions.format, _vm.cardDateOptions))+" ")]:_c('span',[_vm._v("-")])],2)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }