s
<template>
  <div v-if="foreignRights.length > 0">
    <div class="mb-3">
      <foreign-rights-actions
        :edit-link="editLink"
        @deleteClicked="deleteRights"
        @copyClicked="copyRights"
      ></foreign-rights-actions>
      <hr />
    </div>
    <div v-for="(foreignRight, index) in foreignRights" :key="index">
      <dl class="row">
        <dt class="col-3 font-weight-bold text-secondary">Foreign Publisher</dt>
        <dd class="col-9">
          {{ foreignRight.getName | valueOrPlaceholder }}
        </dd>
        <dt class="col-3 pl-5 font-weight-bold text-secondary">Contact Name</dt>
        <dd class="col-9">
          {{ foreignRight.getContactName | valueOrPlaceholder }}
        </dd>
        <dt class="col-3 pl-5 font-weight-bold text-secondary">Address</dt>
        <dd v-if="foreignRight.getAddress" class="col-9">
          <div class="text-whitespace">{{ foreignRight.getAddress }}</div>
        </dd>
        <dd v-else class="col-9">-</dd>
        <dt class="col-3 font-weight-bold text-secondary">
          Countries (exclusive)
        </dt>
        <dd v-if="getExclusiveRights(foreignRight).length > 0" class="col-9">
          <span
            v-for="(right, rightIndex) in getExclusiveRights(foreignRight)"
            :key="rightIndex"
          >
            {{ right.getCountryName
            }}<template v-if="right.getLanguageName">
              ({{ right.getLanguageName }})</template
            ><template
              v-if="rightIndex != getExclusiveRights(foreignRight).length - 1"
              >,
            </template>
          </span>
        </dd>
        <dd v-else class="col-9">-</dd>
        <dt class="col-3 font-weight-bold text-secondary">
          Countries (non-exclusive)
        </dt>
        <dd v-if="getNonExclusiveRights(foreignRight).length > 0" class="col-9">
          <span
            v-for="(right, rightIndex) in getNonExclusiveRights(foreignRight)"
            :key="rightIndex"
          >
            {{ right.getCountryName
            }}<template v-if="right.getLanguageName">
              ({{ right.getLanguageName }})</template
            ><template
              v-if="
                rightIndex != getNonExclusiveRights(foreignRight).length - 1
              "
              >,
            </template>
          </span>
        </dd>
        <dd v-else class="col-9">-</dd>
        <dt class="col-3 font-weight-bold text-secondary">Included Rights</dt>
        <dd class="col-9">
          <span
            v-for="(
              includedRight, includedIndex
            ) in foreignRight.getIncludedRights"
            :key="includedIndex"
          >
            {{ includedRight
            }}<template
              v-if="includedIndex != foreignRight.getIncludedRights.length - 1"
              >,
            </template>
          </span>
          <template v-if="foreignRight.getIncludedRights.length == 0"
            >-</template
          >
        </dd>
        <dt class="col-3 font-weight-bold text-secondary">Subsidiary Rights</dt>
        <dd class="col-9">
          {{ foreignRight.getSubsidiaryRights | valueOrPlaceholder }}
        </dd>
        <dt class="col-3 font-weight-bold text-secondary">
          Advance on signing
        </dt>
        <dd v-if="foreignRight.getContractAdvanceCurrencyName" class="col-9">
          {{ foreignRight.getContractAdvanceCurrencyName }} [{{
            foreignRight.getContractAdvanceCurrency
          }}] {{ foreignRight.getContractAdvance.toFixed(2) }}
        </dd>
        <dd v-else class="col-9">-</dd>
        <dt class="col-3 pl-5 font-weight-bold text-secondary">Received</dt>
        <dd v-if="foreignRight.getContractAdvanceReceivedDate" class="col-9">
          {{
            foreignRight.getContractAdvanceReceivedDate
              | parseDate(dateOptions.format, dateOptions)
          }}
        </dd>
        <dd v-else class="col-9">-</dd>
        <dt class="col-3 font-weight-bold text-secondary">
          Advance on publication
        </dt>
        <dd v-if="foreignRight.getPublicationAdvanceCurrencyName" class="col-9">
          {{ foreignRight.getPublicationAdvanceCurrencyName }} [{{
            foreignRight.getPublicationAdvanceCurrency
          }}] {{ foreignRight.getPublicationAdvance.toFixed(2) }}
        </dd>
        <dd v-else class="col-9">-</dd>
        <dt class="col-3 pl-5 font-weight-bold text-secondary">Received</dt>
        <dd v-if="foreignRight.getPublicationAdvanceReceivedDate" class="col-9">
          {{
            foreignRight.getPublicationAdvanceReceivedDate
              | parseDate(dateOptions.format, dateOptions)
          }}
        </dd>
        <dd v-else class="col-9">-</dd>
        <dt class="col-3 font-weight-bold text-secondary">
          Contract Agreed RRP
        </dt>
        <dd class="col-9">
          <template v-if="foreignRight.getContractRRP">
            {{ foreignRight.getContractRRPCurrencyName }}
            {{ foreignRight.getContractRRP.toFixed(2) }}
          </template>
          <template v-else>-</template>
        </dd>
        <dt class="col-3 font-weight-bold text-secondary">Contract Fees</dt>
        <dd class="col-9">
          <template v-if="foreignRight.getContractFees">
            {{ foreignRight.getContractFeesCurrencyName }}
            {{ foreignRight.getContractFees.toFixed(2) }}
          </template>
          <template v-else>-</template>
        </dd>
        <template v-for="(royalty, royaltyIndex) in foreignRight.getRoyalties">
          <dt
            :key="'dt-' + royaltyIndex"
            class="col-3 font-weight-bold text-secondary"
          >
            Royalty {{ index + 1 }}
          </dt>
          <dd :key="'dd-' + royaltyIndex" class="col-9">
            {{ royalty.getPercent.toFixed(2) }}%
            <template v-if="royalty.getBasis == 'N'">(Net)</template
            ><template v-if="royalty.getBasis == 'R'">(RRP)</template>
          </dd>
        </template>
        <dt class="col-3 font-weight-bold text-secondary">Print Run</dt>
        <dd class="col-9">
          {{ foreignRight.getPrintRun | valueOrPlaceholder }}
        </dd>
        <dt class="col-3 font-weight-bold text-secondary">
          Contract Signed Date
        </dt>
        <dd class="col-9">
          <template v-if="foreignRight.getContractStartDate">
            {{
              foreignRight.getContractStartDate
                | parseDate(dateOptions.format, dateOptions)
            }}
          </template>
          <template v-else>-</template>
        </dd>
        <dt class="col-3 font-weight-bold text-secondary">Publication Date</dt>
        <dd class="col-9">
          <template v-if="foreignRight.getPublicationDate">
            {{
              foreignRight.getPublicationDate
                | parseDate(dateOptions.format, dateOptions)
            }}
          </template>
          <template v-else>-</template>
        </dd>
        <dt class="col-3 font-weight-bold text-secondary">Contract Status</dt>
        <dd class="col-9">
          {{ foreignRight.getContractStatus | valueOrPlaceholder }}
        </dd>
        <dt class="col-3 font-weight-bold text-secondary">Contract Type</dt>
        <dd class="col-9">
          {{ foreignRight.getContractType | valueOrPlaceholder }}
        </dd>
        <dt class="col-3 font-weight-bold text-secondary">Notes</dt>
        <dd class="col-9">
          {{ foreignRight.getNotes | valueOrPlaceholder }}
        </dd>
        <template v-for="(task, taskIndex) in foreignRight.getTasks">
          <dt
            :key="'dt-1-' + taskIndex"
            class="col-3 font-weight-bold text-secondary"
          >
            Task
          </dt>
          <dd
            :key="'dd-1-' + taskIndex"
            class="col-9"
            :class="{ 'text-danger': !task.getCompleted }"
          >
            {{ task.getName }}
          </dd>
          <dt
            :key="'dt-2-' + taskIndex"
            class="col-3 pl-5 font-weight-bold text-secondary"
          >
            Due/Notify
          </dt>
          <dd
            :key="'dd-2-' + taskIndex"
            class="col-9"
            :class="{ 'text-danger': !task.getCompleted }"
          >
            {{ task.getDueDate | parseDate(dateOptions.format, dateOptions)
            }}<template v-if="task.getNotify && task.getNotify.getUserId">
              - {{ task.getNotify.getUserId }}</template
            >
          </dd>
          <dt
            :key="'dt-3-' + taskIndex"
            class="col-3 pl-5 font-weight-bold text-secondary"
          >
            Completed
          </dt>
          <dd
            :key="'dd-3-' + taskIndex"
            class="col-9"
            :class="{ 'text-danger': !task.getCompleted }"
          >
            <template v-if="task.getCompleted">
              {{
                task.getCompletedOn
                  | parseDate(dateOptions.format, dateOptions)
              }}<template
                v-if="task.getCompletedBy && task.getCompletedBy.getUserId"
              >
                by {{ task.getCompletedBy.getUserId }}</template
              >
            </template>
            <template v-else>-</template>
          </dd>
        </template>
        <dt class="col-3 font-weight-bold text-secondary">Created</dt>
        <dd class="col-9">
          {{
            foreignRight.getCreatedOn
              | parseDate(dateOptions.format, dateOptions)
          }}<template
            v-if="
              foreignRight.getCreatedBy && foreignRight.getCreatedBy.getUserId
            "
          >
            by {{ foreignRight.getCreatedBy.getUserId }}</template
          >
        </dd>
        <dt class="col-3 font-weight-bold text-secondary">Updated</dt>
        <dd class="col-9">
          {{
            foreignRight.getUpdatedOn
              | parseDate(dateOptions.format, dateOptions)
          }}<template
            v-if="
              foreignRight.getUpdatedBy && foreignRight.getUpdatedBy.getUserId
            "
          >
            by {{ foreignRight.getUpdatedBy.getUserId }}</template
          >
        </dd>
      </dl>
      <hr v-if="index != foreignRights.length - 1" />
    </div>
    <div class="mt3">
      <foreign-rights-actions
        :edit-link="editLink"
        @deleteClicked="deleteRights"
        @copyClicked="copyRights"
      ></foreign-rights-actions>
    </div>
  </div>
  <div v-else>
    <div>
      There are no foreign rights for this title<template
        v-if="$permitted('foreignrights/edit')"
        >, please click on Create Rights to create some.</template
      >
    </div>
    <div class="mt-2">
      <b-button
        v-if="$permitted('foreignrights/edit')"
        variant="outline-primary"
        :href="editLink"
        >Create Rights</b-button
      >
    </div>
  </div>
</template>

<script>
/**
 * Displays a detailed list of information for foreign rights
 *
 * Included as a tab on the biblio detailed display
 */
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ParseDate from "../../mixins/parseDate.js";
import ForeignRightsActions from "./ForeignRightsActions.vue";
import { HTTP } from "../../http-common.js";

export default {
  name: "ForeignRights",

  components: { "foreign-rights-actions": ForeignRightsActions },

  mixins: [DisplaySetting, ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * Array of Booksonix\BooksonixProductForeignRightsPublisher objects
     * as returned from BooksoniXProduct::getForeignRights::get
     */
    foreignRights: {
      type: Array,
      default: () => [],
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Name of the search form where the search
     * was performed
     */
    searchForm: {
      type: String,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * Return location for forms
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Marked Records object
     */
    markedRecords: {
      type: Object,
      default: null,
    },
  },

  computed: {
    editLink: function () {
      return (
        this.$baseUrl +
        "foreignrights/edit/" +
        this.productId +
        "/*" +
        (this.returnTo ? "?return=" + this.returnTo : "")
      );
    },
    markedRecordIds: function () {
      if (this.markedRecords == null) {
        return null;
      }
      return this.markedRecords.getAll();
    },
  },

  methods: {
    /**
     * Copies foreign rights to marked records
     */
    copyRights: async function () {
      if (this.markedRecordIds.length == 0) {
        this.$bvModal.msgBoxOk(
          "There are no marked records to copy foreign rights data to.",
          {
            title: "No marked records",
            okVariant: "success",
          }
        );
        return false;
      }
      if (
        confirm(
          "Are you sure you want to copy the foreign rights data to all marked records overwriting any existing foreign rights data?"
        ) == true
      ) {
        try {
          let postData = new URLSearchParams();
          postData.append("recordIds", this.markedRecordIds);
          const response = await HTTP.post(
            this.$baseUrl + "foreignrights/copy/" + this.productId,
            postData
          );
          if (response) {
            this.$bvModal.msgBoxOk(
              "Foreign rights data has been copied to all marked records.",
              {
                title: "Confirmation",
                okVariant: "success",
              }
            );
          }
        } catch (error) {
          this.$bvModal.msgBoxOk(
            "There was an error copying the foreign rights.",
            {
              title: "Error",
              okVariant: "danger",
            }
          );
        }
      }
    },

    /**
     * Handled deletion of a products foreign rights data
     */
    deleteRights: async function () {
      if (
        confirm("Are you sure you want to delete the foreign rights data?") ==
        true
      ) {
        try {
          const response = await HTTP.delete(
            this.$baseUrl + "foreignrights/delete/" + this.productId
          );
          if (response.data == "OK") {
            this.$bvModal.msgBoxOk("Foreign rights deleted", {
              title: "Confirmation",
              okVariant: "success",
            });
            /**
             * Deletion event
             */
            this.$emit("foreignrightsDeleted");
          }
        } catch (error) {
          this.$bvModal.msgBoxOk(
            "There was an error deleting the foreign rights",
            {
              title: "Error",
              okVariant: "danger",
            }
          );
        }
      }
    },

    /**
     * Filters rights by exclusive for the given right
     */
    getExclusiveRights: function (foreignRight) {
      if (!foreignRight.getRights) {
        return [];
      }
      let rights = [];
      for (let right of foreignRight.getRights) {
        if (right.getExclusive == true) {
          rights.push(right);
        }
      }
      return rights;
    },

    /**
     * Filters rights by non-exclusive for the given right
     */
    getNonExclusiveRights: function (foreignRight) {
      if (!foreignRight.getRights) {
        return [];
      }
      let rights = [];
      for (let right of foreignRight.getRights) {
        if (right.getExclusive == false) {
          rights.push(right);
        }
      }
      return rights;
    },
  },
};
</script>

<style scoped>
.text-whitespace {
  white-space: pre-wrap;
}
</style>
