<template>
  <div>
    <div class="dropdown">
      <button
        id="exportButtonsDropdown"
        class="btn btn-default dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        Export
        <span class="caret"></span>
      </button>
      <ul
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="exportButtonsDropdown"
      >
        <a :href="urlExcel" class="dropdown-item" download
          ><i class="fa fa-file-excel-o"></i> XLSX</a
        >
        <a :href="urlCsv" class="dropdown-item" download
          ><i class="fa fa-table"></i> CSV</a
        >
        <a :href="urlPdf" class="dropdown-item" download
          ><i class="fa fa-file-pdf-o"></i> PDF</a
        >
      </ul>
    </div>
  </div>
</template>

<script>
/**
 * Buttons for exporting data from the table component
 */

export default {
  name: "ExportButtons",

  props: {
    /**
     * URL which downloads a file. The URL must support
     * a 'format' query parameter.
     */
    url: {
      type: String,
      default: null,
    },
  },

  computed: {
    urlExcel: function () {
      return this.url + (this.url.includes("?") ? "&" : "?") + "format=xlsx";
    },
    urlCsv: function () {
      return this.url + (this.url.includes("?") ? "&" : "?") + "format=csv";
    },
    urlPdf: function () {
      return this.url + (this.url.includes("?") ? "&" : "?") + "format=pdf";
    },
  },
};
</script>

<style></style>
