<template>
  <b-modal
    id="ContentItemEditModal"
    size="xl"
    no-close-on-backdrop
    static
    lazy
    @shown="textualWorkIdTypeChange"
    @ok="saveForm"
  >
    <template #modal-header>
      <h5 class="modal-title">Edit Content Item</h5>
      <div class="close">
        <button class="close" type="button" @click="closeModal()">
          &times;
        </button>
        <a :href="helpUrl" target="help" class="close">
          <i class="far fa-question-circle"></i>
        </a>
      </div>
    </template>
    <template #modal-ok>Save</template>
    <form v-if="contentItem" ref="form">
      <b-tabs v-model="activeTab">
        <b-tab title="Title">
          <div class="row contentitemeditmodal-row">
            <div class="col-2 pl-1">
              <label for="ContentItemEditModal-ComponentTypeName">
                Item Type &amp; Number
              </label>
              &nbsp;
              <info-icon tip="contentItemItemNumber" />
              <br />
              <input
                id="ContentItemEditModal-ComponentTypeName"
                v-model="contentItem.getComponentTypeName"
                type="text"
                class="contentitemeditmodal-ctype"
                placeholder="Item type"
                max-length="20"
              />
              <input
                id="ContentItemEditModal-ComponentNumber"
                v-model="contentItem.getComponentNumber"
                type="text"
                class="contentitemeditmodal-cnum"
                placeholder="Item No."
                max-length="20"
              />
            </div>
            <div class="col-6 pl-1">
              <label for="ContentItemEditModal-MainTitle">Title</label>
              <br />
              <input
                id="ContentItemEditModal-MainTitle"
                v-model="contentItem.getMainTitle"
                type="text"
                class="contentitemeditmodal-maintitle"
                placeholder="Title"
                max-length="300"
              />
            </div>
            <div class="col-1 pl-1">
              <label for="ContentItemEditModal-ItemType">Item Type</label>
              <br />
              <select
                v-if="editType == 'av'"
                id="ContentItemEditModal-ItemType"
                v-model="contentItem.getAVItemType"
                size="1"
                class="contentitemeditmodal-itemtype"
              >
                <option
                  v-for="(itemTypeText, itemTypeValue) in itemTypes"
                  :key="itemTypeValue"
                  :value="itemTypeValue"
                >
                  {{ itemTypeText }}
                </option>
              </select>
              <select
                v-else
                id="ContentItemEditModal-ItemType"
                v-model="contentItem.getTextItemType"
                size="1"
                class="contentitemeditmodal-itemtype"
              >
                <option
                  v-for="(itemTypeText, itemTypeValue) in itemTypes"
                  :key="itemTypeValue"
                  :value="itemTypeValue"
                >
                  {{ itemTypeText }}
                </option>
              </select>
            </div>
            <div class="col-2 pl-4">
              <template v-if="editType == 'av'">
                <label for="TimeRuns">Times</label>
                &nbsp;
                <info-icon tip="contentItemTimeRuns" />
                <br />
                <input
                  id="ContentItemEditModal-Pages"
                  v-model="contentItem.getTimeRuns"
                  type="text"
                  placeholder="Times"
                  class="contentitemeditmodal-pages"
                />
              </template>
              <template v-else>
                <label for="PageRuns">Pages</label>
                &nbsp;
                <info-icon tip="contentItemPageRuns" />
                <br />
                <input
                  id="ContentItemEditModal-Pages"
                  v-model="contentItem.getPageRuns"
                  type="text"
                  placeholder="Pages"
                  class="contentitemeditmodal-pages"
                />
              </template>
            </div>
            <template v-if="editType == 'av'">
              <div class="col-1 pl-0 pr-2">
                <label for="TimeDuration">Duration</label>
                &nbsp;
                <info-icon tip="contentItemTimeDuration" />
                <br />
                <input
                  id="ContentItemEditModal-PageCount"
                  v-model="contentItem.getTimeDuration"
                  type="text"
                  pattern="([0-9]?[0-9]?[0-9]:)?([0-5]?[0-9]:)?[0-5]?[0-9]"
                  class="contentitemeditmodal-pagecount"
                  placeholder="Duration"
                  max-length="9"
                />
              </div>
            </template>
            <template v-else>
              <div class="col-1 pl-0 pr-1">
                <label for="ContentItemEditModal-PageCount">Page Count</label>
                <info-icon tip="contentItemPageCount" />
                <br />
                <input
                  id="ContentItemEditModal-PageCount"
                  v-model="contentItem.getPageCount"
                  type="text"
                  pattern="[1-9][0-9]*"
                  class="contentitemeditmodal-pagecount"
                  placeholder="Page Count"
                  max-length="6"
                />
              </div>
            </template>
          </div>
          <div class="row contentitemeditmodal-row">
            <div class="col-2"></div>
            <div class="col-6 pl-1">
              <label for="ContentItemEditModal-MainTitleSubtitle">
                Subtitle
              </label>
              <br />
              <input
                id="ContentItemEditModal-MainTitleSubtitle"
                v-model="contentItem.getMainTitleSubtitle"
                type="text"
                class="contentitemeditmodal-maintitle"
                placeholder="Subtitle"
                max-length="300"
              />
            </div>
          </div>
          <div class="row contentitemeditmodal-row">
            <div class="col-9 pl-1">
              <label>Product Identifiers</label>
              &nbsp;
              <info-icon tip="contentItemProductIdentifiers" />
              <br />
              <biblio-identifiers-edit
                :identifiers="contentItem"
                :types="[
                  {
                    code: 'getGTIN13',
                    name: 'GTIN-13',
                    pattern: '[0-9]{13}',
                  },
                  {
                    code: 'getISBN13',
                    name: 'ISBN-13',
                    pattern: '(978[0-9]{10}|979[1-9][0-9]{9})',
                  },
                  {
                    code: 'getDOI',
                    name: 'DOI',
                    pattern: '10\\.[0-9]{4,9}/[\\-._;\\(\\)\\/:A-Za-z0-9]+',
                  },
                ]"
                @changed="
                  (identifiers) => {
                    for (idType in identifiers) {
                      contentItem[idType] = identifiers[idType];
                    }
                  }
                "
              />
            </div>
            <div class="col-3 pl-1">
              <label for="ContentItemEditModal-TextualWorkIdValue">
                Textual Work Identifier
              </label>
              <br />
              <select
                ref="TextualWorkIdType"
                v-model="contentItem.getTextualWorkIdentifierType"
                size="1"
                class="contentitemeditmodal-textworkid-type"
                @change="textualWorkIdTypeChange"
              >
                <option value="15">ISBN-13</option>
                <option value="39">ISCC †³</option>
                <option value="11">ISTC</option>
                <option value="18">ISRC</option>
                <option value="01:ASIN">ASIN</option>
                <option value="06">DOI</option>
                <option value="32">GLIMIR</option>
                <option value="33">OWI</option>
              </select>
              <input
                id="ContentItemEditModal-TextualWorkIdValue"
                ref="TextualWorkIdValue"
                v-model="contentItem.getTextualWorkIdentifierValue"
                type="text"
                class="contentitemeditmodal-textworkid-value"
              />
            </div>
          </div>
        </b-tab>
        <b-tab title="Languages">
          <div class="row contentitemeditmodal-row">
            <div class="col-4">
              <input
                id="ContentItemEditModal-UseProductLanguages0"
                type="radio"
                name="UseProductLanguages"
                :checked="!contentItem.getUseProductLanguages"
                @change="contentItem.getUseProductLanguages = false"
              />
              <label for="ContentItemEditModal-UseProductLanguages0">
                Specify languages in this content item
              </label>
            </div>
            <div class="col-4">
              <input
                id="ContentItemEditModal-UseProductLanguages1"
                type="radio"
                name="UseProductLanguages"
                :checked="contentItem.getUseProductLanguages"
                @change="contentItem.getUseProductLanguages = true"
              />
              <label for="ContentItemEditModal-UseProductLanguages1">
                Use the product's languages in this content item
              </label>
            </div>
            <div class="col-4">
              <info-icon
                title="Use product's languages in this content item"
                :text="{
                  content:
                    'If the option <i>Use the product\'s languages in this content item</i> is selected then the product\'s languages are included with this content item when the product is disseminated or exported.<br><br>If the option <i>Specify languages in this content item</i> is selected then the specified languages are included with this content item when the product is disseminated or exported',
                  html: true,
                }"
              />
            </div>
          </div>
          <template v-if="!contentItem.getUseProductLanguages">
            <hr />
            <div class="contentitemeditmodal-scroll">
              <biblio-languages-edit
                header="Languages of Text"
                role="01"
                :languages="contentItem.getLanguages.getText"
                :all-languages="allLanguages"
                :all-countries="allCountries"
                @changed="
                  (languages) => {
                    contentItem.getLanguages.getText = languages;
                  }
                "
              />
              <biblio-languages-edit
                header="Original Languages of Text"
                role="02"
                :languages="
                  contentItem.getLanguages.getOriginalOfATranslatedText
                "
                :all-languages="allLanguages"
                :all-countries="allCountries"
                @changed="
                  (languages) => {
                    contentItem.getLanguages.getOriginalOfATranslatedText =
                      languages;
                  }
                "
              />
            </div>
          </template>
        </b-tab>
        <b-tab title="Contributors">
          <div class="row contentitemeditmodal-row">
            <div class="col-4">
              <input
                id="ContentItemEditModal-UseProductContributors0"
                type="radio"
                name="UseProductContributors"
                :checked="!contentItem.getUseProductContributors"
                @change="contentItem.getUseProductContributors = false"
              />
              <label for="ContentItemEditModal-UseProductContributors0">
                Specify contributors in this content item
              </label>
            </div>
            <div class="col-4">
              <input
                id="ContentItemEditModal-UseProductContributors1"
                type="radio"
                name="UseProductContributors"
                :checked="contentItem.getUseProductContributors"
                @change="contentItem.getUseProductContributors = true"
              />
              <label for="ContentItemEditModal-UseProductContributors1">
                Use the product's contributors in this content item
              </label>
            </div>
            <div class="col-4">
              <info-icon
                title="Use product's contributors in this content item"
                :text="{
                  content:
                    'If the option <i>Use the product\'s contributors in this content item</i> is selected then the product\'s contributors are included with this content item when the product is disseminated or exported.<br><br>If the option <i>Specify contributors in this content item</i> is selected then the specified contributors are included with this content item when the product is disseminated or exported',
                  html: true,
                }"
              />
            </div>
          </div>
          <template v-if="!contentItem.getUseProductContributors">
            <hr />
            <div class="contentitemeditmodal-scroll">
              <biblio-contributors-edit
                :contributors="contentItem.getContributors.get"
                :no-contributors="contentItem.getContributors.getNoContributors"
                :roles="contributorRoles"
                :languages="allLanguages"
                :countries="allCountries"
                :unnamed-types="contributorUnnamedTypes"
                @changed="
                  (contributors) => {
                    if (contributors === false) {
                      contentItem.getContributors.get = [];
                      contentItem.getContributors.getNoContributors = true;
                    } else {
                      contentItem.getContributors.get = contributors;
                      contentItem.getContributors.getNoContributors = false;
                    }
                  }
                "
              >
              </biblio-contributors-edit>
              <hr />
              <div class="row">
                <div class="col-12">Contributor Statement</div>
              </div>
              <div class="row">
                <div class="col-12">
                  This statement can be used to describe the authorship of this
                  content item in the style that you require. This should be
                  used when the standard description, which is constructed by
                  concatenating individual contributors, does not meet your
                  requirements. You must still enter the details of each
                  individual contributor in the Contributors section above.
                  <br />
                  <textarea
                    v-model="contentItem.getContributors.getStatement"
                    rows="2"
                    maxlength="65535"
                    style="width: 98%"
                    wrap="virtual"
                  ></textarea>
                </div>
              </div>
            </div>
          </template>
        </b-tab>
        <b-tab title="Subjects">
          <div class="row contentitemeditmodal-row">
            <div class="col-4">
              <input
                id="ContentItemEditModal-UseProductSubjects0"
                type="radio"
                name="UseProductSubjects"
                :checked="!contentItem.getUseProductSubjects"
                @change="contentItem.getUseProductSubjects = false"
              />
              <label for="ContentItemEditModal-UseProductSubjects0">
                Specify subjects in this content item
              </label>
            </div>
            <div class="col-4">
              <input
                id="ContentItemEditModal-UseProductSubjects1"
                type="radio"
                name="UseProductSubjects"
                :checked="contentItem.getUseProductSubjects"
                @change="contentItem.getUseProductSubjects = true"
              />
              <label for="ContentItemEditModal-UseProductSubjects1">
                Use the product's subjects in this content item
              </label>
            </div>
            <div class="col-4">
              <info-icon
                title="Use product's subjects in this content item"
                :text="{
                  content:
                    'If the option <i>Use the product\'s subjects in this content item</i> is selected then the product\'s subjects are included with this content item when the product is disseminated or exported.<br><br>If the option <i>Specify subjects in this content item</i> is selected then the specified subjects are included with this content item when the product is disseminated or exported',
                  html: true,
                }"
              />
            </div>
          </div>
          <template v-if="!contentItem.getUseProductSubjects">
            <hr />
            <div class="contentitemeditmodal-scroll">
              <biblio-subjects-edit
                :index="1"
                header="Main Subjects"
                :main-subjects="true"
                :subjects="contentItem.getSubjects.getMainSubjects"
                :schemes="subjectSchemes"
                :browsers="subjectBrowsers"
                :versions="subjectVersions"
                @changed="
                  (subjects) => {
                    contentItem.getSubjects.getMainSubjects = subjects;
                  }
                "
              />
              <hr />
              <biblio-subjects-edit
                :index="2"
                header="Additional Subjects"
                :main-subjects="false"
                :subjects="contentItem.getSubjects.getAdditionalSubjects"
                :schemes="subjectSchemes"
                :browsers="subjectBrowsers"
                :versions="subjectVersions"
                @changed="
                  (subjects) => {
                    contentItem.getSubjects.getAdditionalSubjects = subjects;
                  }
                "
              />
            </div>
          </template>
        </b-tab>
        <b-tab title="Full Text">
          <div class="row contentitemeditmodal-row">
            <div class="col-4">
              <input
                id="ContentItemEditModal-UseProductDescriptions0"
                type="radio"
                name="UseProductDescriptions"
                :checked="!contentItem.getUseProductDescriptions"
                @change="contentItem.getUseProductDescriptions = false"
              />
              <label for="ContentItemEditModal-UseProductDescriptions0">
                Specify full text in this content item
              </label>
            </div>
            <div class="col-4">
              <input
                id="ContentItemEditModal-UseProductDescriptions1"
                type="radio"
                name="UseProductDescriptions"
                :checked="contentItem.getUseProductDescriptions"
                @change="contentItem.getUseProductDescriptions = true"
              />
              <label for="ContentItemEditModal-UseProductDescriptions1">
                Use the product's full text in this content item
              </label>
            </div>
            <div class="col-4">
              <info-icon
                title="Use product's full text in this content item"
                :text="{
                  content:
                    'If the option <i>Use the product\'s full text in this content item</i> is selected then the product\'s full text are included with this content item when the product is disseminated or exported.<br><br>If the option <i>Specify full text in this content item</i> is selected then the specified full text are included with this content item when the product is disseminated or exported',
                  html: true,
                }"
              />
            </div>
          </div>
          <template v-if="!contentItem.getUseProductDescriptions">
            <hr />
            <div class="contentitemeditmodal-scroll">
              <biblio-descriptions-edit
                header="Full Text"
                :descriptions="contentItem.getDescriptions.get"
                :types="descriptionTypes"
                :languages="allLanguages"
                @changed="
                  (descriptions) => {
                    contentItem.getDescriptions.get = descriptions;
                  }
                "
              />
            </div>
          </template>
        </b-tab>
        <b-tab title="Image/Media files">
          <div class="row contentitemeditmodal-row">
            <div class="col-4">
              <input
                id="ContentItemEditModal-UseProductResources0"
                type="radio"
                name="UseProductResources"
                :checked="!contentItem.getUseProductResources"
                @change="contentItem.getUseProductResources = false"
              />
              <label for="ContentItemEditModal-UseProductResources0">
                Specify image/media files in this content item
              </label>
            </div>
            <div class="col-5">
              <input
                id="ContentItemEditModal-UseProductResources1"
                type="radio"
                name="UseProductResources"
                :checked="contentItem.getUseProductResources"
                @change="contentItem.getUseProductResources = true"
              />
              <label for="ContentItemEditModal-UseProductResources1">
                Use the product's image/media files in this content item
              </label>
            </div>
            <div class="col-3">
              <info-icon
                title="Use product's image/media files in this content item"
                :text="{
                  content:
                    'If the option <i>Use the product\'s image/media files in this content item</i> is selected then the product\'s image/media files are included with this content item when the product is disseminated or exported.<br><br>If the option <i>Specify image/media files in this content item</i> is selected then the specified image/media files are included with this content item when the product is disseminated or exported',
                  html: true,
                }"
              />
            </div>
          </div>
          <template v-if="!contentItem.getUseProductResources">
            <hr />
            <div class="contentitemeditmodal-scroll">
              <biblio-resources-edit
                header="Image/Media files"
                :resources="contentItem.getResources.get"
                :types="mediaTypes"
                :formats="mediaFormats"
                @changed="
                  (resources) => {
                    contentItem.getResources.get = resources;
                  }
                "
              />
            </div>
          </template>
        </b-tab>
        <b-tab title="Related Products">
          <div class="contentitemeditmodal-scroll">
            <biblio-related-products-edit
              :related-products="contentItem.getRelatedProducts.getAll"
              :types="relatedTypes"
              @changed="
                (relatedProducts) => {
                  contentItem.getRelatedProducts.getAll = relatedProducts;
                }
              "
            />
          </div>
        </b-tab>
      </b-tabs>
    </form>
  </b-modal>
</template>

<script>
/**
 * Displays a modal that will provide an edit form for a records tags.
 */
import BiblioIdentifiersEdit from "./BiblioIdentifiersEdit.vue";
import BiblioLanguagesEdit from "./BiblioLanguagesEdit.vue";
import BiblioContributorsEdit from "./BiblioContributorsEdit.vue";
import BiblioSubjectsEdit from "./BiblioSubjectsEdit.vue";
import BiblioDescriptionsEdit from "./BiblioDescriptionsEdit.vue";
import BiblioResourcesEdit from "./BiblioResourcesEdit.vue";
import BiblioRelatedProductsEdit from "./BiblioRelatedProductsEdit.vue";
import InfoIcon from "../../icons/InfoIcon.vue";
import { HTTP } from "../../../http-common.js";

export default {
  name: "ContentItemEditModal",

  components: {
    "biblio-identifiers-edit": BiblioIdentifiersEdit,
    "biblio-languages-edit": BiblioLanguagesEdit,
    "biblio-contributors-edit": BiblioContributorsEdit,
    "biblio-subjects-edit": BiblioSubjectsEdit,
    "biblio-descriptions-edit": BiblioDescriptionsEdit,
    "biblio-resources-edit": BiblioResourcesEdit,
    "biblio-related-products-edit": BiblioRelatedProductsEdit,
    "info-icon": InfoIcon,
  },

  props: {
    /**
     * The product id
     */
    productId: {
      type: Number,
      required: true,
    },

    /**
     * The edit type (text or av)
     */
    editType: {
      type: String,
      default: "text",
    },

    /**
     * The items types
     * An object whose properties are the type codes and their values are the type names
     */
    itemTypes: {
      type: Object,
      default: null,
    },

    /**
     * The list of all languages
     */
    allLanguages: {
      type: Array,
      default: null,
    },

    /**
     * The list of all countries grouped by continent and optionally with regions
     */
    allCountries: {
      type: Array,
      default: null,
    },

    /**
     * The grouped list of contributor roles
     */
    contributorRoles: {
      type: Array,
      required: true,
    },

    /**
     * The list of contributor unnamed types
     */
    contributorUnnamedTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all subject schemes
     */
    subjectSchemes: {
      type: Array,
      required: true,
    },

    /**
     * Subject Browsers
     */
    subjectBrowsers: {
      type: Array,
      required: true,
    },

    /**
     * Subject versions for BIC, BISAC and THEMA
     */
    subjectVersions: {
      type: Object,
      required: true,
    },

    /**
     * List of all description types
     */
    descriptionTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all media types
     */
    mediaTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all media formats
     */
    mediaFormats: {
      type: Array,
      required: true,
    },

    /**
     * List of all related product types
     */
    relatedTypes: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      sequenceNumber: null,
      contentItem: null,
      activeTab: 0,
    };
  },

  computed: {
    /**
     * Get the help url
     */
    helpUrl: function () {
      return (
        (location.host.search(/^(www\.)?booksonix\.(com|co\.uk|uk|net)$/) != -1
          ? "https://www.booksonix.com"
          : "") + "/help/update_edit_contentitems"
      );
    },
  },

  methods: {
    /**
     * Show the modal
     */
    showModal: function (sequenceNumber) {
      let url =
        this.$baseUrl +
        "biblio-edit/edit-contentitems/" +
        this.productId +
        "/" +
        sequenceNumber;

      HTTP.get(url)
        .then((response) => {
          this.sequenceNumber = sequenceNumber;
          this.contentItem = response.data;

          this.contentItem.getTextualWorkIdentifierType = "15";
          this.contentItem.getTextualWorkIdentifierValue = "";
          for (let idType in this.contentItem.getAllTextualWorkIdentifiers) {
            this.contentItem.getTextualWorkIdentifierType = idType;
            this.contentItem.getTextualWorkIdentifierValue =
              this.contentItem.getAllTextualWorkIdentifiers[idType];
            break;
          }

          this.$bvModal.show("ContentItemEditModal");
          this.activeTab = 0;
        })
        .catch(() => {
          this.$bvModal.msgBoxOk(
            "An error has occurred while retrieving your content item"
          );
        });
    },

    /**
     * Close the modal
     */
    closeModal: function () {
      this.$bvModal.hide("ContentItemEditModal");
    },

    /**
     * Textual work identifier type change
     */
    textualWorkIdTypeChange: function () {
      let control = this.$refs.TextualWorkIdType;
      let idType = control.options[control.selectedIndex].value;
      switch (idType) {
        case "15":
          this.$refs.TextualWorkIdValue.pattern =
            "(978[0-9]{10}|979[1-9][0-9]{9})";
          this.$refs.TextualWorkIdValue.placeholder = "ISBN-13";
          break;
        case "39":
          this.$refs.TextualWorkIdValue.pattern = "[A-Za-z0-9]{52,}";
          this.$refs.TextualWorkIdValue.placeholder = "ISCC";
          break;
        case "11":
          this.$refs.TextualWorkIdValue.pattern = "[0-9A-Fa-f]{16}";
          this.$refs.TextualWorkIdValue.placeholder = "ISTC";
          break;
        case "18":
          this.$refs.TextualWorkIdValue.pattern = "[A-Z]{2}[A-Z0-9]{3}[0-9]{7}";
          this.$refs.TextualWorkIdValue.placeholder = "ISRC";
          break;
        case "01:ASIN":
          this.$refs.TextualWorkIdValue.pattern = "[A-Z0-9]{10}";
          this.$refs.TextualWorkIdValue.placeholder = "ASIN";
          break;
        case "06":
          this.$refs.TextualWorkIdValue.pattern =
            "10\\.[0-9]{4,9}/[-._;()/:A-Za-z0-9]+";
          this.$refs.TextualWorkIdValue.placeholder = "DOI";
          break;
        case "32":
          this.$refs.TextualWorkIdValue.pattern = "";
          this.$refs.TextualWorkIdValue.placeholder = "GLIMIR";
          break;
        case "33":
          this.$refs.TextualWorkIdValue.pattern = "";
          this.$refs.TextualWorkIdValue.placeholder = "OWI";
          break;
      }
    },

    /**
     * Save the form
     */
    saveForm: function (bvModalEvent) {
      bvModalEvent.preventDefault();

      // check validity
      if (!this.$refs.form.checkValidity()) {
        let message =
          "You must correct any errors before you can save your changes<br><br>There are errors in the following fields:";
        let showTab = 0;
        for (let i = 0; i < this.$refs.form.length; i++) {
          if (!this.$refs.form.elements[i].checkValidity()) {
            let controlId = this.$refs.form.elements[i].id;
            if (controlId == "ContentItemEditModal-PageCount") {
              message +=
                "<br>&bull; " +
                (this.editType == "av" ? "Duration" : "Page Count");
              if (!showTab) showTab = 1;
            } else if (
              controlId.search(/^biblioidentifiersedit--item-/) != -1
            ) {
              message += "<br>&bull; Product Identifiers";
              if (!showTab) showTab = 1;
            } else if (controlId == "ContentItemEditModal-TextualWorkIdValue") {
              message += "<br>&bull; Textual Work Identifier";
              if (!showTab) showTab = 1;
            } else if (
              controlId.search(/^bibliodescriptionsedit-type-/) != -1
            ) {
              message += "<br>&bull; Full Text type";
              if (!showTab) showTab = 5;
            } else if (controlId.search(/^biblioresourcesedit-type-/) != -1) {
              message += "<br>&bull; Image/Media file type";
              if (!showTab) showTab = 6;
            } else if (controlId.search(/^biblioresourcesedit-link-/) != -1) {
              message += "<br>&bull; Image/Media file link";
              if (!showTab) showTab = 6;
            } else if (
              controlId.search(/^biblioidentifiersedit-rp[0-9]+-item-/) != -1
            ) {
              message += "<br>&bull; Related Product Identifier";
              if (!showTab) showTab = 7;
            }
          }
        }

        if (showTab) {
          this.activeTab = showTab - 1;
        }
        let msgVNode = this.$createElement("div", {
          domProps: { innerHTML: message },
        });
        this.$bvModal.msgBoxOk([msgVNode]);
        return;
      }

      // save the changes
      let url =
        this.$baseUrl +
        "biblio-edit/edit-contentitems/" +
        this.productId +
        "/" +
        this.sequenceNumber;

      let postData = new URLSearchParams();
      postData.append("_datefmt", this.$userPreferences.dateFormat);
      let fields = [
        "ComponentTypeName",
        "ComponentNumber",
        "MainTitle",
        "AVItemType",
        "TextItemType",
        "TimeRuns",
        "PageRuns",
        "TimeDuration",
        "PageCount",
        "MainTitleSubtitle",
        "GTIN13",
        "ISBN13",
        "DOI",
        "TextualWorkIdentifierType",
        "TextualWorkIdentifierValue",
        "UseProductLanguages",
        "UseProductContributors",
        "UseProductSubjects",
        "UseProductDescriptions",
        "UseProductResources",
        "?Languages Languages.Text",
        "?Languages Languages.OriginalOfATranslatedText",
        "?Contributors Contributors.",
        "?Contributors Contributors.NoContributors",
        "?Contributors Contributors.Statement",
        "?Subjects Subjects.MainSubjects",
        "?Subjects Subjects.AdditionalSubjects",
        "?Descriptions Descriptions.",
        "?Resources Resources.",
        "RelatedProducts.All",
      ];
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        if (field[0] == "?") {
          let fieldSep = field.indexOf(" ");
          if (
            this.contentItem["getUseProduct" + field.substring(1, fieldSep)] ==
            false
          ) {
            field = field.substring(fieldSep + 1);
          } else {
            field = null;
          }
        }
        if (field !== null) {
          let fieldParts = field.split(".");
          let fieldData = this.contentItem;
          for (let j = 0; j < fieldParts.length; j++) {
            fieldData = fieldData["get" + fieldParts[j]];
          }

          if (fieldData !== undefined) {
            if (fieldData === true) {
              fieldData = "1";
            } else if (fieldData === false) {
              fieldData = "0";
            } else if (fieldData === null) {
              fieldData = "";
            } else if (typeof fieldData == "object") {
              fieldData = JSON.stringify(fieldData);
            }
            postData.append(field, fieldData);
          }
        }
      }

      HTTP.post(url, postData)
        .then(() => {
          this.$emit("changed", this.contentItem);
          this.$bvModal.hide("ContentItemEditModal");
        })
        .catch(() => {
          this.$bvModal.msgBoxOk(
            "An error has occurred while saving the changes to your content item"
          );
        });
    },
  },
};
</script>

<style scoped>
.contentitemeditmodal-row label {
  margin: 0.5rem 0 0 0.5rem;
}
.contentitemeditmodal-ctype {
  width: 55%;
}
.contentitemeditmodal-cnum {
  width: 40%;
}
.contentitemeditmodal-itemtype {
  width: 130%;
}
.contentitemeditmodal-maintitle,
.contentitemeditmodal-pages,
.contentitemeditmodal-pagecount {
  width: 100%;
}
.contentitemeditmodal-textworkid-type {
  width: 35%;
}
.contentitemeditmodal-textworkid-value {
  width: 55%;
}
.contentitemeditmodal-scroll {
  padding: 0 10px 2px 0;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}
#ContentItemEditModal input:invalid,
#ContentItemEditModal select:invalid {
  border: red solid 2px;
}
</style>
