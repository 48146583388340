<template>
  <div>
    <div class="row bibliocontributorsedit-header">
      <div class="col-3">
        <label>Contributors</label>
        <button
          type="button"
          title="Add another contributor"
          class="btn btn-outline-primary btn-sm bibliocontributorsedit-add"
          @click="addContributor"
        >
          <i class="fa fa-plus"></i>
          Add
        </button>
      </div>
    </div>
    <div v-if="currentContributors.length == 0" class="row">
      <div class="col-12 bibliocontributorsedit-item-none">
        <input
          id="bibliocontributorsedit-none-check"
          type="checkbox"
          value="N"
          :checked="currentNoContributors"
          @click="changedNoContributors($event.target)"
        />
        <label for="bibliocontributorsedit-none-check">
          &nbsp;This title explicitly has no contributors.
        </label>
      </div>
    </div>
    <template v-for="(contributor, contributorIndex) in currentContributors">
      <div :key="2 * contributorIndex + 1" class="bibliocontributorsedit-item">
        <div class="row">
          <div class="col-3">
            {{ formatRole(contributor) }}
          </div>
          <div class="col-9">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="formatName(contributor)"></div>
            <!--eslint-enable-->
          </div>
        </div>
        <div v-if="formatAffiliation(contributor)" class="row">
          <div class="col-3"></div>
          <div class="col-9">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="formatAffiliation(contributor)"></div>
            <!--eslint-enable-->
          </div>
        </div>
        <div v-if="contributor.getBiographicalNote" class="row">
          <div class="col-3"></div>
          <div class="col-9">
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="contributor.getBiographicalNoteFormat == '02'"
              v-html="contributor.getBiographicalNote"
            ></div>
            <!--eslint-enable-->
            <!-- prettier-ignore -->
            <div v-else class="preserve-white-space">{{ contributor.getBiographicalNote }}</div>
          </div>
        </div>
        <div
          v-if="contributor.getWebsites && contributor.getWebsites.length"
          class="row"
        >
          <div class="col-3"></div>
          <div class="col-9">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="formatWebsite(contributor.getWebsites[0])"></div>
            <!--eslint-enable-->
          </div>
        </div>
      </div>
      <div
        :key="2 * contributorIndex + 2"
        class="row bibliocontributorsedit-btns"
      >
        <div class="col-12">
          <a
            href="#"
            class="btn btn-link"
            @click="editContributor(contributorIndex)"
          >
            Edit
          </a>
          <a
            href="#"
            class="btn btn-link"
            @click="moveContributorUp(contributorIndex)"
          >
            Move Up
          </a>
          <a
            href="#"
            class="btn btn-link"
            @click="moveContributorDn(contributorIndex)"
          >
            Move Down
          </a>
          <a
            href="#"
            class="btn btn-link"
            @click="deleteContributor(contributorIndex)"
          >
            Delete
          </a>
        </div>
      </div>
    </template>
    <b-modal
      id="bibliocontributorsedit-editor"
      size="xl"
      no-close-on-backdrop
      static
      @shown="editorVisible"
    >
      <template #modal-title>New Contributor</template>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-success"
          @click="saveContributor(true)"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="saveContributor(false)"
        >
          Cancel
        </button>
      </template>
      <form ref="form">
        <div class="row">
          <div class="col-3" style="padding-top: 6px">
            <label>Type</label>
          </div>
          <div class="col-1">
            <input
              id="bibliocontributorsedit-editor-type-p"
              name="CT"
              type="radio"
              value="P"
              @click="changeType('P', true)"
            />
            <label for="bibliocontributorsedit-editor-type-p">
              &nbsp;Person
            </label>
          </div>
          <div class="col-2">
            <input
              id="bibliocontributorsedit-editor-type-c"
              name="CT"
              type="radio"
              value="C"
              @click="changeType('C', true)"
            />
            <label for="bibliocontributorsedit-editor-type-c">
              &nbsp;Corporate Body
            </label>
          </div>
          <div class="col-4">
            <input
              id="bibliocontributorsedit-editor-type-u"
              name="CT"
              type="radio"
              value="U"
              @click="changeType('U', true)"
            />
            <label for="bibliocontributorsedit-editor-type-u">
              &nbsp;Unnamed:
            </label>
            <select
              id="bibliocontributorsedit-editor-type-ut"
              ref="UTctrl"
              size="1"
              style="width: 75%"
              disabled
            >
              <option
                v-for="unnamedType in unnamedTypes"
                :key="unnamedType.code"
                :value="unnamedType.code"
              >
                {{ unnamedType.name }}
              </option>
            </select>
          </div>
          <div class="col-2" style="text-align: right; padding-top: 6px">
            <span
              ref="ContactIDlabel"
              style="color: #606060; padding-right: 15px"
            ></span>
          </div>
        </div>
        <input ref="IDctrl" type="hidden" value="" />
        <div ref="RIdiv" style="display: none">
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-role">
                Role <span class="note-star">*</span>
              </label>
            </div>
            <div class="col-9">
              <select
                id="bibliocontributorsedit-editor-role"
                ref="RCctrl"
                size="1"
                style="width: 60%"
                @change="changeRole($event.target)"
              >
                <option value=""></option>
                <template v-for="(roleInfo, roleIndex) in roles">
                  <optgroup
                    v-if="Array.isArray(roleInfo)"
                    :key="1000 * roleIndex"
                    :label="roleInfo[0].groupName"
                  >
                    <option
                      v-for="(groupRoleInfo, groupRoleIndex) in roleInfo"
                      :key="1000 * roleIndex + groupRoleIndex + 1"
                      :value="groupRoleInfo.code"
                    >
                      {{ groupRoleInfo.name }}
                      {{ groupRoleInfo.listSuffix }}
                    </option>
                  </optgroup>
                  <option
                    v-else
                    :key="1000 * roleIndex + 1"
                    :value="roleInfo.code"
                  >
                    {{ roleInfo.name }} {{ roleInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div ref="TransLangDiv" style="display: none">
            <div class="row">
              <div class="col-3">
                <label for="bibliocontributorsedit-editor-transf">
                  Translated from
                </label>
              </div>
              <div class="col-9">
                <select
                  id="bibliocontributorsedit-editor-transf"
                  ref="TFctrl"
                  size="1"
                  style="width: 60%"
                >
                  <option value=""></option>
                  <option
                    v-for="languageInfo in languages"
                    :key="languageInfo.code"
                    :value="languageInfo.code"
                  >
                    {{ languageInfo.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="bibliocontributorsedit-editor-transt">
                  Translated to
                  <span
                    class="note-star2"
                    title="This field can only be disseminated in ONIX 3.0 format"
                  >
                    &dagger;3
                  </span>
                </label>
              </div>
              <div class="col-9">
                <select
                  id="bibliocontributorsedit-editor-transt"
                  ref="TTctrl"
                  size="1"
                  style="width: 60%"
                >
                  <option value=""></option>
                  <option
                    v-for="languageInfo in languages"
                    :key="languageInfo.code"
                    :value="languageInfo.code"
                  >
                    {{ languageInfo.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-internal">
                Internal
                <span
                  class="note-star"
                  title="This field is internal and will not be disseminated"
                >
                  **
                </span>
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-internal"
                ref="INctrl"
                type="checkbox"
                value="I"
              />
            </div>
          </div>
        </div>
        <div ref="PSdiv" style="display: none">
          <div class="row">
            <div class="col-3">
              <label>Name</label>
            </div>
            <div class="col-6">
              <contacts-dropdown
                ref="contactsDropdownP"
                :base-url="$baseUrl"
                placeholder="Select a contact..."
                @selected="
                  (data) => {
                    contactSelectedData(data);
                  }
                "
                @create="createContact"
              />
            </div>
          </div>
        </div>
        <div ref="CSdiv" style="display: none">
          <div class="row">
            <div class="col-3">
              <label>Name</label>
            </div>
            <div class="col-6">
              <contacts-dropdown
                ref="contactsDropdownC"
                :base-url="$baseUrl"
                placeholder="Select a contact..."
                :corporate="true"
                @selected="
                  (data) => {
                    contactSelectedData(data);
                  }
                "
                @create="createContact"
              />
            </div>
          </div>
        </div>
        <div ref="PNdiv" style="display: none">
          <div class="row">
            <div class="col-3">
              <label>Name</label>
            </div>
            <div class="col-9">
              <input
                ref="PNctrl"
                type="text"
                maxlength="200"
                style="width: 60%"
                value=""
              />
              <a href="#" class="contributor-more" @click="showDetails">
                Show details
              </a>
              <a
                href="#"
                class="contact-edit"
                style="padding-left: 10px"
                title="Edit this contact"
                @click="editContact"
              >
                Edit
              </a>
              <a
                href="#"
                class="contact-edit"
                style="padding-left: 10px"
                title="Update from contact"
                @click="updateFromContact"
              >
                Update
              </a>
            </div>
          </div>
        </div>
        <div ref="PN2div" style="display: none">
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-tp">
                Title (before name)
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-tp"
                ref="TPctrl"
                type="text"
                maxlength="100"
                style="width: 30%"
                value=""
              />
              <span class="contributor-hint">(e.g. <i>Professor</i>)</span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-fn">
                First Name(s)
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-fn"
                ref="FNctrl"
                type="text"
                maxlength="100"
                style="width: 60%"
                value=""
              />
              <span class="contributor-hint">(e.g. <i>James J.</i>)</span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-sp">
                Prefix to Surname
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-sp"
                ref="SPctrl"
                type="text"
                maxlength="100"
                style="width: 30%"
                value=""
              />
              <span class="contributor-hint">
                (e.g. <i>van</i> in <i>Ludwig van Beethoven</i>)
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-sn">
                Surname <span class="note-star">*</span>
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-sn"
                ref="SNctrl"
                type="text"
                maxlength="200"
                style="width: 60%"
                value=""
              />
              <span class="contributor-hint">(e.g. <i>Beethoven</i>)</span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-ss">
                Suffix to Surname
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-ss"
                ref="SSctrl"
                type="text"
                maxlength="100"
                style="width: 30%"
                value=""
              />
              <span class="contributor-hint">(e.g. <i>Jr</i>)</span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-ts">
                Title (after name)
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-ts"
                ref="TSctrl"
                type="text"
                maxlength="100"
                style="width: 30%"
                value=""
              />
              <span class="contributor-hint">
                (e.g. <i>Duke of Edinburgh</i>)
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-qh">
                Qualifications &amp; Honours
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-"
                ref="QHctrl"
                type="text"
                maxlength="100"
                style="width: 60%"
                value=""
              />
              <span class="contributor-hint">(e.g. <i>CBE FRS</i>)</span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-ni">
                ISNI (name identifier)
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-ni"
                ref="NIctrl"
                type="text"
                maxlength="20"
                style="width: 60%"
                value=""
              />
              <a
                href="http://isni.oclc.nl/"
                target="_blank"
                class="navigation"
                style="margin-left: 2px"
              >
                Find
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-oi">
                ORCID (name identifier)
                <span
                  class="note-star2"
                  title="This field can only be disseminated in ONIX 3.0 format"
                >
                  &dagger;3
                </span>
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-oi"
                ref="OIctrl"
                type="text"
                maxlength="20"
                style="width: 60%"
                value=""
              />
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-pp">
                Professional Position
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-pp"
                ref="PPctrl"
                type="text"
                maxlength="200"
                style="width: 60%"
                value=""
              />
              <span class="contributor-hint">
                (e.g. <i>Professor of Oceanography</i>)
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-pa">
                Professional Affiliation
              </label>
            </div>
            <div class="col-9">
              <input
                id="bibliocontributorsedit-editor-pa"
                ref="PActrl"
                type="text"
                maxlength="300"
                style="width: 60%"
                value=""
              />
              <span class="contributor-hint">
                (e.g. <i>Universidad de La Laguna</i>)
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Dates</label>
            </div>
            <div class="col-4">
              <label for="bibliocontributorsedit-editor-db">Born</label>
              <div ref="DBctrldiv" style="width: 39%; display: inline-block">
                <v-datepicker
                  id="bibliocontributorsedit-editor-db"
                  ref="DBctrl"
                  v-model="editorDateBorn"
                  :format="editorDatePickerFormat"
                  :monday-first="$userPreferences.dateFormat == 'dmy'"
                  :placeholder="editorDatePickerFormat.toLowerCase()"
                  style="width: 100%"
                />
              </div>
              <input
                ref="DBDctrl"
                type="text"
                :placeholder="editorDatePickerFormat.toLowerCase()"
                style="width: 38%; display: none"
                disabled
                value=""
              />
              <input
                ref="DBYctrl"
                type="text"
                maxlength="7"
                placeholder="yyyy"
                style="width: 38%; display: none"
                value=""
              />
              <select
                ref="DBTctrl"
                size="1"
                style="width: 40%"
                @change="changeDateType('DB')"
              >
                <option value="D">Full Date</option>
                <option value="Y">Month/Year</option>
              </select>
            </div>
            <div class="col-4">
              <label for="bibliocontributorsedit-editor-dd">Died</label>
              <div ref="DDctrldiv" style="width: 39%; display: inline-block">
                <v-datepicker
                  id="bibliocontributorsedit-editor-dd"
                  ref="DDctrl"
                  v-model="editorDateDied"
                  :format="editorDatePickerFormat"
                  :monday-first="$userPreferences.dateFormat == 'dmy'"
                  :placeholder="editorDatePickerFormat.toLowerCase()"
                  style="width: 100%"
                />
              </div>
              <input
                ref="DDDctrl"
                type="text"
                :placeholder="editorDatePickerFormat.toLowerCase()"
                style="width: 38%; display: none"
                disabled
                value=""
              />
              <input
                ref="DDYctrl"
                type="text"
                maxlength="7"
                placeholder="yyyy"
                style="width: 38%; display: none"
                value=""
              />
              <select
                ref="DDTctrl"
                size="1"
                style="width: 40%"
                @change="changeDateType('DD')"
              >
                <option value="D">Full Date</option>
                <option value="Y">Month/Year</option>
              </select>
            </div>
          </div>
        </div>
        <div ref="CNdiv" style="display: none">
          <div class="row">
            <div class="col-3">
              <label>Name</label>
            </div>
            <div class="col-9">
              <input
                ref="CNctrl"
                type="text"
                maxlength="200"
                style="width: 60%"
                value=""
              />
              <span class="contributor-hint">
                (e.g. <i>Good Housekeeping Institute</i>)
              </span>
              <a href="#" class="contributor-more" @click="showDetails">
                Show details
              </a>
              <a
                href="#"
                class="contact-edit"
                style="padding-left: 10px"
                title="Edit this contact"
                @click="editContact"
              >
                Edit
              </a>
              <a
                href="#"
                class="contact-edit"
                style="padding-left: 10px"
                title="Update from contact"
                @click="updateFromContact"
              >
                Update
              </a>
            </div>
          </div>
        </div>
        <div ref="PPdiv" style="display: none">
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-na">Nationality</label>
            </div>
            <div class="col-9">
              <select
                id="bibliocontributorsedit-editor-na"
                ref="NActrl"
                size="1"
                style="width: 60%"
              >
                <option value=""></option>
                <template v-for="(countryInfo, countryIndex) in countries">
                  <optgroup
                    v-if="Array.isArray(countryInfo.name)"
                    :key="1000 * countryIndex"
                    :label="countryInfo.code"
                  >
                    <template
                      v-for="(
                        groupCountryInfo, groupCountryIndex
                      ) in countryInfo.name"
                    >
                      <option
                        v-if="groupCountryInfo.code.length == 2"
                        :key="1000 * countryIndex + groupCountryIndex + 1"
                        :value="groupCountryInfo.code"
                      >
                        {{ groupCountryInfo.name }}
                        {{ groupCountryInfo.listSuffix }}
                      </option>
                    </template>
                  </optgroup>
                  <option
                    v-else-if="countryInfo.code.length == 2"
                    :key="1000 * countryIndex + 1"
                    :value="countryInfo.code"
                  >
                    {{ countryInfo.name }} {{ countryInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>
                Places
                <span
                  class="note-star2"
                  title="This field can only be disseminated in ONIX 3.0 format"
                  >&dagger;3</span
                >
              </label>
            </div>
            <div class="col-3">
              <label>Town / City</label>
            </div>
            <div class="col-6">
              <label>Country / Region</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label
                for="bibliocontributorsedit-editor-pbt"
                style="padding-left: 10pt"
              >
                Born in
              </label>
            </div>
            <div class="col-3">
              <input
                id="bibliocontributorsedit-editor-pbt"
                ref="PBTctrl"
                type="text"
                maxlength="100"
                style="width: 90%"
                value=""
              />
            </div>
            <div class="col-6">
              <select ref="PBCctrl" size="1" style="width: 90%">
                <option value=""></option>
                <template v-for="(countryInfo, countryIndex) in countries">
                  <optgroup
                    v-if="Array.isArray(countryInfo.name)"
                    :key="1000 * countryIndex"
                    :label="countryInfo.code"
                  >
                    <option
                      v-for="(
                        groupCountryInfo, groupCountryIndex
                      ) in countryInfo.name"
                      :key="1000 * countryIndex + groupCountryIndex + 1"
                      :value="groupCountryInfo.code"
                    >
                      {{ groupCountryInfo.code.length >= 3 ? "&emsp;" : "" }}
                      {{ groupCountryInfo.name }}
                      {{ groupCountryInfo.listSuffix }}
                    </option>
                  </optgroup>
                  <option
                    v-else
                    :key="1000 * countryIndex + 1"
                    :value="countryInfo.code"
                  >
                    {{ countryInfo.code.length >= 3 ? "&emsp;" : "" }}
                    {{ countryInfo.name }} {{ countryInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label
                for="bibliocontributorsedit-editor-pdt"
                style="padding-left: 10pt"
              >
                Died in
              </label>
            </div>
            <div class="col-3">
              <input
                id="bibliocontributorsedit-editor-pdt"
                ref="PDTctrl"
                type="text"
                maxlength="100"
                style="width: 90%"
                value=""
              />
            </div>
            <div class="col-6">
              <select ref="PDCctrl" size="1" style="width: 90%">
                <option value=""></option>
                <template v-for="(countryInfo, countryIndex) in countries">
                  <optgroup
                    v-if="Array.isArray(countryInfo.name)"
                    :key="1000 * countryIndex"
                    :label="countryInfo.code"
                  >
                    <option
                      v-for="(
                        groupCountryInfo, groupCountryIndex
                      ) in countryInfo.name"
                      :key="1000 * countryIndex + groupCountryIndex + 1"
                      :value="groupCountryInfo.code"
                    >
                      {{ groupCountryInfo.code.length >= 3 ? "&emsp;" : "" }}
                      {{ groupCountryInfo.name }}
                      {{ groupCountryInfo.listSuffix }}
                    </option>
                  </optgroup>
                  <option
                    v-else
                    :key="1000 * countryIndex + 1"
                    :value="countryInfo.code"
                  >
                    {{ countryInfo.code.length >= 3 ? "&emsp;" : "" }}
                    {{ countryInfo.name }} {{ countryInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label
                for="bibliocontributorsedit-editor-prt"
                style="padding-left: 10pt"
              >
                Currently resides in
              </label>
            </div>
            <div class="col-3">
              <input
                id="bibliocontributorsedit-editor-prt"
                ref="PRTctrl"
                type="text"
                maxlength="100"
                style="width: 90%"
                value=""
              />
            </div>
            <div class="col-6">
              <select ref="PRCctrl" size="1" style="width: 90%">
                <option value=""></option>
                <template v-for="(countryInfo, countryIndex) in countries">
                  <optgroup
                    v-if="Array.isArray(countryInfo.name)"
                    :key="1000 * countryIndex"
                    :label="countryInfo.code"
                  >
                    <option
                      v-for="(
                        groupCountryInfo, groupCountryIndex
                      ) in countryInfo.name"
                      :key="1000 * countryIndex + groupCountryIndex + 1"
                      :value="groupCountryInfo.code"
                    >
                      {{ groupCountryInfo.code.length >= 3 ? "&emsp;" : "" }}
                      {{ groupCountryInfo.name }}
                      {{ groupCountryInfo.listSuffix }}
                    </option>
                  </optgroup>
                  <option
                    v-else
                    :key="1000 * countryIndex + 1"
                    :value="countryInfo.code"
                  >
                    {{ countryInfo.code.length >= 3 ? "&emsp;" : "" }}
                    {{ countryInfo.name }} {{ countryInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label
                for="bibliocontributorsedit-editor-ppt"
                style="padding-left: 10pt"
              >
                Formerly resided in
              </label>
            </div>
            <div class="col-3">
              <input
                id="bibliocontributorsedit-editor-ppt"
                ref="PPTctrl"
                type="text"
                maxlength="100"
                style="width: 90%"
                value=""
              />
            </div>
            <div class="col-6">
              <select ref="PPCctrl" size="1" style="width: 90%">
                <option value=""></option>
                <template v-for="(countryInfo, countryIndex) in countries">
                  <optgroup
                    v-if="Array.isArray(countryInfo.name)"
                    :key="1000 * countryIndex"
                    :label="countryInfo.code"
                  >
                    <option
                      v-for="(
                        groupCountryInfo, groupCountryIndex
                      ) in countryInfo.name"
                      :key="1000 * countryIndex + groupCountryIndex + 1"
                      :value="groupCountryInfo.code"
                    >
                      {{ groupCountryInfo.code.length >= 3 ? "&emsp;" : "" }}
                      {{ groupCountryInfo.name }}
                      {{ groupCountryInfo.listSuffix }}
                    </option>
                  </optgroup>
                  <option
                    v-else
                    :key="1000 * countryIndex + 1"
                    :value="countryInfo.code"
                  >
                    {{ countryInfo.code.length >= 3 ? "&emsp;" : "" }}
                    {{ countryInfo.name }} {{ countryInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label
                for="bibliocontributorsedit-editor-pet"
                style="padding-left: 10pt"
              >
                Educated in
              </label>
            </div>
            <div class="col-3">
              <input
                id="bibliocontributorsedit-editor-pet"
                ref="PETctrl"
                type="text"
                maxlength="100"
                style="width: 90%"
                value=""
              />
            </div>
            <div class="col-6">
              <select ref="PECctrl" size="1" style="width: 90%">
                <option value=""></option>
                <template v-for="(countryInfo, countryIndex) in countries">
                  <optgroup
                    v-if="Array.isArray(countryInfo.name)"
                    :key="1000 * countryIndex"
                    :label="countryInfo.code"
                  >
                    <option
                      v-for="(
                        groupCountryInfo, groupCountryIndex
                      ) in countryInfo.name"
                      :key="1000 * countryIndex + groupCountryIndex + 1"
                      :value="groupCountryInfo.code"
                    >
                      {{ groupCountryInfo.code.length >= 3 ? "&emsp;" : "" }}
                      {{ groupCountryInfo.name }}
                      {{ groupCountryInfo.listSuffix }}
                    </option>
                  </optgroup>
                  <option
                    v-else
                    :key="1000 * countryIndex + 1"
                    :value="countryInfo.code"
                  >
                    {{ countryInfo.code.length >= 3 ? "&emsp;" : "" }}
                    {{ countryInfo.name }} {{ countryInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label
                for="bibliocontributorsedit-editor-pwt"
                style="padding-left: 10pt"
              >
                Worked in
              </label>
            </div>
            <div class="col-3">
              <input
                id="bibliocontributorsedit-editor-pwt"
                ref="PWTctrl"
                type="text"
                maxlength="100"
                style="width: 90%"
                value=""
              />
            </div>
            <div class="col-6">
              <select ref="PWCctrl" size="1" style="width: 90%">
                <option value=""></option>
                <template v-for="(countryInfo, countryIndex) in countries">
                  <optgroup
                    v-if="Array.isArray(countryInfo.name)"
                    :key="1000 * countryIndex"
                    :label="countryInfo.code"
                  >
                    <option
                      v-for="(
                        groupCountryInfo, groupCountryIndex
                      ) in countryInfo.name"
                      :key="1000 * countryIndex + groupCountryIndex + 1"
                      :value="groupCountryInfo.code"
                    >
                      {{ groupCountryInfo.code.length >= 3 ? "&emsp;" : "" }}
                      {{ groupCountryInfo.name }}
                      {{ groupCountryInfo.listSuffix }}
                    </option>
                  </optgroup>
                  <option
                    v-else
                    :key="1000 * countryIndex + 1"
                    :value="countryInfo.code"
                  >
                    {{ countryInfo.code.length >= 3 ? "&emsp;" : "" }}
                    {{ countryInfo.name }} {{ countryInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label
                for="bibliocontributorsedit-editor-pft"
                style="padding-left: 10pt"
              >
                Flourished in
              </label>
            </div>
            <div class="col-3">
              <input
                id="bibliocontributorsedit-editor-pft"
                ref="PFTctrl"
                type="text"
                maxlength="100"
                style="width: 90%"
                value=""
              />
            </div>
            <div class="col-6">
              <select ref="PFCctrl" size="1" style="width: 90%">
                <option value=""></option>
                <template v-for="(countryInfo, countryIndex) in countries">
                  <optgroup
                    v-if="Array.isArray(countryInfo.name)"
                    :key="1000 * countryIndex"
                    :label="countryInfo.code"
                  >
                    <option
                      v-for="(
                        groupCountryInfo, groupCountryIndex
                      ) in countryInfo.name"
                      :key="1000 * countryIndex + groupCountryIndex + 1"
                      :value="groupCountryInfo.code"
                    >
                      {{ groupCountryInfo.code.length >= 3 ? "&emsp;" : "" }}
                      {{ groupCountryInfo.name }}
                      {{ groupCountryInfo.listSuffix }}
                    </option>
                  </optgroup>
                  <option
                    v-else
                    :key="1000 * countryIndex + 1"
                    :value="countryInfo.code"
                  >
                    {{ countryInfo.code.length >= 3 ? "&emsp;" : "" }}
                    {{ countryInfo.name }} {{ countryInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
        </div>
        <div ref="CPdiv" style="display: none">
          <div class="row">
            <div class="col-3">
              <label>
                Places
                <span
                  class="note-star2"
                  title="This field can only be disseminated in ONIX 3.0 format"
                >
                  &dagger;3
                </span>
              </label>
            </div>
            <div class="col-3">
              <label>Town / City</label>
            </div>
            <div class="col-6">
              <label>Country / Region</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label
                for="bibliocontributorsedit-editor-pgt"
                style="padding-left: 10pt"
              >
                Registered in
              </label>
            </div>
            <div class="col-3">
              <input
                id="bibliocontributorsedit-editor-pgt"
                ref="PGTctrl"
                type="text"
                maxlength="100"
                style="width: 90%"
                value=""
              />
            </div>
            <div class="col-6">
              <select ref="PGCctrl" size="1" style="width: 90%">
                <option value=""></option>
                <template v-for="(countryInfo, countryIndex) in countries">
                  <optgroup
                    v-if="Array.isArray(countryInfo.name)"
                    :key="1000 * countryIndex"
                    :label="countryInfo.code"
                  >
                    <option
                      v-for="(
                        groupCountryInfo, groupCountryIndex
                      ) in countryInfo.name"
                      :key="1000 * countryIndex + groupCountryIndex + 1"
                      :value="groupCountryInfo.code"
                    >
                      {{ groupCountryInfo.code.length >= 3 ? "&emsp;" : "" }}
                      {{ groupCountryInfo.name }}
                      {{ groupCountryInfo.listSuffix }}
                    </option>
                  </optgroup>
                  <option
                    v-else
                    :key="1000 * countryIndex + 1"
                    :value="countryInfo.code"
                  >
                    {{ countryInfo.code.length >= 3 ? "&emsp;" : "" }}
                    {{ countryInfo.name }} {{ countryInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label
                for="bibliocontributorsedit-editor-pot"
                style="padding-left: 10pt"
              >
                Operating from
              </label>
            </div>
            <div class="col-3">
              <input
                id="bibliocontributorsedit-editor-pot"
                ref="POTctrl"
                type="text"
                maxlength="100"
                style="width: 90%"
                value=""
              />
            </div>
            <div class="col-6">
              <select ref="POCctrl" size="1" style="width: 90%">
                <option value=""></option>
                <template v-for="(countryInfo, countryIndex) in countries">
                  <optgroup
                    v-if="Array.isArray(countryInfo.name)"
                    :key="1000 * countryIndex"
                    :label="countryInfo.code"
                  >
                    <option
                      v-for="(
                        groupCountryInfo, groupCountryIndex
                      ) in countryInfo.name"
                      :key="1000 * countryIndex + groupCountryIndex + 1"
                      :value="groupCountryInfo.code"
                    >
                      {{ groupCountryInfo.code.length >= 3 ? "&emsp;" : "" }}
                      {{ groupCountryInfo.name }}
                      {{ groupCountryInfo.listSuffix }}
                    </option>
                  </optgroup>
                  <option
                    v-else
                    :key="1000 * countryIndex + 1"
                    :value="countryInfo.code"
                  >
                    {{ countryInfo.code.length >= 3 ? "&emsp;" : "" }}
                    {{ countryInfo.name }} {{ countryInfo.listSuffix }}
                  </option>
                </template>
              </select>
            </div>
          </div>
        </div>
        <div ref="BWdiv" style="display: none">
          <div class="row">
            <div class="col-3" style="vertical-align: top">
              <label>Biographical Note</label>
              <br />
              <div style="float: right; margin: 5px 5px 0 0">
                <a
                  id="bibliocontributorsedit-editor-biogtypelink"
                  href="#"
                  @click="toggleBiographyEditor"
                >
                  Switch to HTML
                </a>
              </div>
            </div>
            <div class="col-9">
              <textarea
                ref="BNctrl"
                rows="6"
                maxlength="65535"
                style="width: 94%"
                wrap="virtual"
              ></textarea>
              <div style="display: none; width: 96%; margin-bottom: 5px">
                <div
                  id="bibliocontributorsedit-editor-biogeditor"
                  ref="biogeditor"
                  style="height: 93px"
                ></div>
              </div>
              <input ref="BNFctrl" type="hidden" value="" />
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label for="bibliocontributorsedit-editor-wa">Website</label>
            </div>
            <div class="col-6">
              <input
                id="bibliocontributorsedit-editor-wa"
                ref="WActrl"
                type="text"
                maxlength="300"
                style="width: 95%"
                value=""
              />
            </div>
            <div class="col-3">
              <select ref="WTctrl" size="1" style="width: 87%">
                <option value=""></option>
                <option value="06">by Contributor</option>
                <option value="42">
                  by Contributor &ndash; Social Networking
                </option>
                <option value="07">by Publisher</option>
                <option value="08">by Other Publisher</option>
                <option value="09">by Third-party</option>
              </select>
            </div>
          </div>
        </div>
        <p class="bibliocontributorsedit-legend">
          <span class="note-star">*</span> indicates that this field may be
          required when disseminating data to 3rd parties.<br />
          <span class="note-star2">&dagger;3</span> indicates that this field
          can only be disseminated in ONIX 3 format.<br />
          <span class="note-star">**</span> indicates that this field is
          internal and will not be disseminated to 3rd parties.<br />
        </p>
      </form>
    </b-modal>
    <iframe
      ref="ContactEditFrame"
      style="
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
        z-index: 9999;
        display: none;
      "
    ></iframe>
  </div>
</template>

<script>
/**
 * Bibliographic contributors edit container to edit contributors
 *
 * Emits a 'changed' event including an array of contributors objects (excluding empty entries)
 * or false if explicitly no contributors
 */
import ParseDate from "../../../mixins/parseDate.js";
import ContactsDropdown from "../../utility/ContactsDropdown.vue";
import Datepicker from "vuejs-datepicker";
import { HTTP } from "../../../http-common.js";
import { DateTime } from "luxon";

export default {
  name: "BiblioContributorsEdit",

  components: {
    "contacts-dropdown": ContactsDropdown,
    "v-datepicker": Datepicker,
  },

  mixins: [ParseDate],

  props: {
    /**
     * Contributors to edit
     */
    contributors: {
      type: Array,
      default: null,
    },

    /**
     * No contributors flag
     */
    noContributors: {
      type: Boolean,
      default: false,
    },

    /**
     * The grouped list of contributor roles
     */
    roles: {
      type: Array,
      required: true,
    },

    /**
     * List of languages
     */
    languages: {
      type: Array,
      required: true,
    },

    /**
     * List of all countries
     */
    countries: {
      type: Array,
      required: true,
    },

    /**
     * The list of contributor unnamed types
     */
    unnamedTypes: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    let contributors = this.contributors ? this.contributors : [];
    let currentContributors = [];
    for (let i = 0; i < contributors.length; i++) {
      currentContributors.push({ ...contributors[i] });
    }

    let rolesList = {};
    for (let i = 0; i < this.roles.length; i++) {
      if (Array.isArray(this.roles[i])) {
        for (let j = 0; j < this.roles[i].length; j++) {
          rolesList[this.roles[i][j].code] = this.roles[i][j].name;
        }
      } else {
        rolesList[this.roles[i].code] = this.roles[i].name;
      }
    }

    let languagesList = {};
    for (let i = 0; i < this.languages.length; i++) {
      languagesList[this.languages[i].code] = this.languages[i].name;
    }

    let countriesList = {};
    for (let i = 0; i < this.countries.length; i++) {
      if (Array.isArray(this.countries[i].name)) {
        for (let j = 0; j < this.countries[i].name.length; j++) {
          countriesList[this.countries[i].name[j].code] =
            this.countries[i].name[j].name;
        }
      } else {
        countriesList[this.countries[i].code] = this.countries[i].name;
      }
    }

    let unnamedList = {};
    for (let i = 0; i < this.unnamedTypes.length; i++) {
      unnamedList[this.unnamedTypes[i].code.toString()] =
        this.unnamedTypes[i].name;
    }

    return {
      currentContributors: currentContributors,
      currentNoContributors: this.noContributors,
      rolesList: rolesList,
      languagesList: languagesList,
      countriesList: countriesList,
      unnamedList: unnamedList,
      biographyEditor: null,
      biographyContainerDiv: null,
      autoCompleteSetup: false,
      editDialog: {},
      editorDateBorn: null,
      editorDateDied: null,
    };
  },

  computed: {
    /**
     * Get the format for the date picker
     */
    editorDatePickerFormat: function () {
      switch (this.$userPreferences.dateFormat) {
        case "ymd":
          return "yyyy/MM/dd";
        case "mdy":
          return "MM/dd/yyyy";
        default:
          return "dd/MM/yyyy";
      }
    },
  },

  mounted() {
    if (!window._quillJsLoaded) {
      let quillScript = document.createElement("script");
      quillScript.setAttribute("src", "/common/quilljs/quill.min.js");
      quillScript.onload = function () {
        // load quill_extended.js after quill.min.js has loaded otherwise we get an error
        let quillExtendedScript = document.createElement("script");
        quillExtendedScript.setAttribute(
          "src",
          "/common/quilljs/quill_extended.js"
        );
        document.head.appendChild(quillExtendedScript);
      };
      document.head.appendChild(quillScript);

      let quillStyle = document.createElement("link");
      quillStyle.setAttribute("rel", "stylesheet");
      quillStyle.setAttribute("href", "/common/quilljs/quill.snow.css");
      quillStyle.setAttribute("type", "text/css");
      document.head.appendChild(quillStyle);

      window._quillJsLoaded = true;
    }
    if (!window._formsJsLoaded) {
      let formsScript = document.createElement("script");
      formsScript.setAttribute("src", "/common/forms.js");
      document.head.appendChild(formsScript);

      window._formsJsLoaded = true;
    }
  },

  methods: {
    /**
     * Format a contributor's role; returns text
     */
    formatRole: function (contributor) {
      let roleCode = contributor.getRole;
      let role = "";
      if (roleCode in this.rolesList) {
        role = this.rolesList[roleCode];

        if (contributor.getTranslatedFrom) {
          let translatedFrom =
            this.languagesList[contributor.getTranslatedFrom];
          if (contributor.getTranslated) {
            let translatedTo = this.languagesList[contributor.getTranslatedTo];
            role = role.replace(
              /(ranslated)/gi,
              "$1 from " + translatedFrom + " to " + translatedTo
            );
          } else {
            role = role.replace(/(ranslated)/gi, "$1 from " + translatedFrom);
          }
        } else if (contributor.getTranslatedTo) {
          let translatedTo = this.languagesList[contributor.getTranslatedTo];
          role = role.replace(/(ranslated)/gi, "$1 to " + translatedTo);
        }
      }

      return role;
    },

    /**
     * Format a contributor's name; returns htmls
     */
    formatName: function (contributor) {
      let html = "";
      if (contributor.getType == "P") {
        if (contributor.getTitlesBefore) {
          html += this.htmlEncode(contributor.getTitlesBefore) + " ";
        }
        if (contributor.getFirstNames) {
          html += this.htmlEncode(contributor.getFirstNames) + " ";
        }
        if (contributor.getSurnamePrefix) {
          html += this.htmlEncode(contributor.getSurnamePrefix) + " ";
        }
        if (contributor.getSurname) {
          html += this.htmlEncode(contributor.getSurname) + " ";
        } else {
          html += "&nbsp;";
        }
        if (contributor.getSurnameSuffix) {
          html += " " + this.htmlEncode(contributor.getSurnameSuffix);
        }
        if (contributor.getTitlesAfter) {
          html += ", " + this.htmlEncode(contributor.getTitlesAfter);
        }
        if (contributor.getQualificationsHonours) {
          html += ", " + this.htmlEncode(contributor.getQualificationsHonours);
        }

        if (contributor.getISNI) {
          html +=
            '&nbsp;&nbsp;&nbsp; <span style="color:#606060;" title="Name Identifier">(ISNI ' +
            this.htmlEncode(contributor.getISNI) +
            ")</span>";
        }
        if (contributor.getORCID) {
          html +=
            '&nbsp;&nbsp;&nbsp; <span style="color:#606060;" title="Name Identifier">(ORCID ' +
            this.htmlEncode(contributor.getORCID) +
            ")</span>";
        }
      } else if (contributor.getType == "C") {
        html += this.htmlEncode(contributor.getCorporateBody);
      } else {
        if (contributor.getUnnamedType in this.unnamedList) {
          html = this.unnamedList[contributor.getUnnamedType];
        } else {
          html = "(unnamed)";
        }
      }

      if (contributor.getType == "P" || contributor.getType == "C") {
        html += '&nbsp;&nbsp;&nbsp; <span style="color:#606060;" ';
        if (contributor.getContactId) {
          html +=
            'title="Contact Identifier">(id ' + contributor.getContactId + ")";
        } else {
          html += 'title="New Contact, Identifier unknown">(id unknown)';
        }
        html += "</span>";
      }

      if (contributor.getInternal) {
        html +=
          '&nbsp;&nbsp;&nbsp; <span style="color:#606060;">(Internal)</span>';
      }

      return html;
    },

    /**
     * Format a contributor's affiliation and places; returns htmls
     */
    formatAffiliation: function (contributor) {
      let html = "";

      if (contributor.getType == "P" || contributor.getType == "C") {
        if (
          contributor.getProfessionalAffiliations &&
          contributor.getProfessionalAffiliations.length
        ) {
          for (
            let i = 0;
            i < contributor.getProfessionalAffiliations.length;
            i++
          ) {
            let affiliation = contributor.getProfessionalAffiliations[i];
            html += "; ";
            if (affiliation.getPosition) {
              html += this.htmlEncode(affiliation.getPosition);
            }
            if (affiliation.getOrganisation) {
              if (affiliation.getPosition) {
                html += ", ";
              }
              html += this.htmlEncode(affiliation.getOrganisation);
            }
          }
        }

        if (contributor.getType == "P") {
          if (contributor.getDateOfBirth) {
            if (contributor.getDateOfBirth.$dateFields != 0) {
              html +=
                "; Born " +
                this.parseDate(
                  contributor.getDateOfBirth.$date,
                  "yyyy",
                  this.dateOptions,
                  true
                );
            } else {
              html +=
                "; Born " +
                this.parseDate(
                  contributor.getDateOfBirth.$date,
                  this.$userPreferences.dateFormat,
                  this.dateOptions
                );
            }
          }
          if (contributor.getDateOfDeath) {
            if (contributor.getDateOfDeath.$dateFields != 0) {
              html +=
                "; Born " +
                this.parseDate(
                  contributor.getDateOfDeath.$date,
                  "yyyy",
                  this.dateOptions,
                  true
                );
            } else {
              html +=
                "; Died " +
                this.parseDate(
                  contributor.getDateOfDeath.$date,
                  this.$userPreferences.dateFormat,
                  this.dateOptions
                );
            }
          }

          if (contributor.getNationalityCountry) {
            html +=
              "; National of " +
              this.htmlEncode(
                this.countriesList[contributor.getNationalityCountry]
              );
          }

          if (contributor.getPlaceBorn) {
            html += "; Born in " + this.formatPlace(contributor.getPlaceBorn);
          }
          if (contributor.getPlaceDied) {
            html += "; Died in " + this.formatPlace(contributor.getPlaceDied);
          }
          if (contributor.getPlaceResides) {
            html +=
              "; Currently resides in " +
              this.formatPlace(contributor.getPlaceResides);
          }
          if (contributor.getPlaceFormerlyResided) {
            html +=
              "; Formerly resided in " +
              this.formatPlace(contributor.getPlaceFormerlyResided);
          }
          if (contributor.getPlaceEducated) {
            html +=
              "; Educated in " + this.formatPlace(contributor.getPlaceEducated);
          }
          if (contributor.getPlaceWorked) {
            html +=
              "; Worked in " + this.formatPlace(contributor.getPlaceWorked);
          }
          if (contributor.getPlaceFlourished) {
            html +=
              "; Flourished in " +
              this.formatPlace(contributor.getPlaceFlourished);
          }
        } else {
          if (contributor.getPlaceRegisteredIn) {
            html +=
              "; Registered in " +
              this.formatPlace(contributor.getPlaceRegisteredIn);
          }
          if (contributor.getPlaceOperatingFrom) {
            html +=
              "; Operating from " +
              this.formatPlace(contributor.getPlaceOperatingFrom);
          }
        }
      }

      return html.length ? html.substring(2) : null;
    },

    /**
     * Format a contributor's place, returns as html
     */
    formatPlace: function (place) {
      let html = "";
      if (place.getTown) {
        html += this.htmlEncode(place.getTown) + ", ";
      }
      if (place.getRegion) {
        html += this.htmlEncode(this.countriesList[place.getRegion]);
      } else {
        html += this.htmlEncode(this.countriesList[place.getCountry]);
      }

      return html;
    },

    /**
     * Format a contributor's website, returns html
     */
    formatWebsite: function (website) {
      let html = "";

      if (website.getLinkUrl) {
        html +=
          '<a target="_blank" href="' +
          (website.getLinkUrl.indexOf(":") == -1 ? "http://" : "") +
          website.getLinkUrl +
          '">' +
          this.htmlEncode(website.getLinkUrl) +
          "</a>";

        if (website.getRole) {
          switch (website.getRole) {
            case "06":
              html += "&nbsp;&nbsp;(by Contributor)";
              break;
            case "42":
              html += "&nbsp;&nbsp;(by Contributor &ndash; Social Networking)";
              break;
            case "07":
              html += "&nbsp;&nbsp;(by Publisher)";
              break;
            case "08":
              html += "&nbsp;&nbsp;(by Other Publisher)";
              break;
            case "09":
              html += "&nbsp;&nbsp;(by Third-party)";
              break;
          }
        }
      }

      return html;
    },

    /**
     * The no-contributors flag has been changed
     */
    changedNoContributors: function (control) {
      this.currentNoContributors = control.checked ? control.value : "";
      this.notifyChanges();
    },

    /**
     * Add a contributor and edit it
     * Note: we don't notify of changes as we have added a contributor entry with no type selected or text entered
     */
    addContributor: function () {
      this.currentContributors.push({
        getRole: null,
        getType: "P",
        getTranslatedFrom: null,
        getTranslatedTo: null,
        getInternal: false,
        getTitlesBefore: null,
        getFirstNames: null,
        getSurnamePrefix: null,
        getSurname: null,
        getSurnameSuffix: null,
        getNamesAfter: null,
        getTitlesAfter: null,
        getQualificationsHonours: null,
        getISNI: null,
        getORCID: null,
        getProfessionalAffiliations: null,
        getDateOfBirth: null,
        getDateOfDeath: null,
        getNationalityCountry: null,
        getPlaceBorn: null,
        getPlaceDied: null,
        getPlaceResides: null,
        getPlaceFormerlyResided: null,
        getPlaceEducated: null,
        getPlaceWorked: null,
        getPlaceFlourished: null,
        getCorporateBody: null,
        getPlaceRegisteredIn: null,
        getPlaceOperatingFrom: null,
        getBiographicalNote: null,
        getBiographicalNoteFormat: "06",
        getContactId: null,
        getUnnamedType: "01",
        getWebsites: [],
      });
      this.editContributor(this.currentContributors.length - 1, true);
    },

    /**
     * Edit a contributor
     */
    editContributor: function (index, isNew = false) {
      let contributor = this.currentContributors[index];
      this.editDialog.index = index;
      this.editDialog.new = isNew; // we are adding a new contributor
      this.editDialog.selectContact = isNew; // we are in the select-contact phase
      let isNewContact = !isNew && !contributor.getContactId;
      this.editDialog.newContact = isNewContact; // we are creating a new contact
      this.editDialog.detailedView = isNewContact; // all the contributor's fields are visible

      // clear the person/corporate contact selector
      this.$refs.contactsDropdownP.selected = null;
      this.$refs.contactsDropdownC.selected = null;

      // set contributor's contact id (do before calling ChangeType())
      this.setDialogFieldValue("ID", !isNew ? contributor.getContactId : "");

      // set contributor's type
      this.setDialogFieldValue("CT", contributor.getType, isNew);
      this.changeType(contributor.getType, false);

      // set edit control values
      this.setDialogFieldValue("UT", contributor.getUnnamedType);
      this.setDialogFieldValue("RC", contributor.getRole);
      this.changeRole(this.$refs.RCctrl);
      this.setDialogFieldValue("TF", contributor.getTranslatedFrom);
      this.setDialogFieldValue("TT", contributor.getTranslatedTo);
      this.setDialogFieldValue("IN", contributor.getInternal ? "I" : "");

      if (contributor.getSurname) {
        let personName =
          (contributor.getFirstNames ? contributor.getFirstNames + " " : "") +
          (contributor.getSurnamePrefix
            ? contributor.getSurnamePrefix + " "
            : "") +
          contributor.getSurname +
          (contributor.getSurnameSuffix
            ? " " + contributor.getSurnameSuffix
            : "");
        this.setDialogFieldValue("PN", personName, isNewContact);
      } else {
        this.setDialogFieldValue("PN", "", isNewContact);
      }

      this.setDialogFieldValue("TP", contributor.getTitlesBefore, isNewContact);
      this.setDialogFieldValue("FN", contributor.getFirstNames, isNewContact);
      this.setDialogFieldValue(
        "SP",
        contributor.getSurnamePrefix,
        isNewContact
      );
      this.setDialogFieldValue("SN", contributor.getSurname, isNewContact);
      this.setDialogFieldValue(
        "SS",
        contributor.getSurnameSuffix,
        isNewContact
      );
      this.setDialogFieldValue("TS", contributor.getTitlesAfter, isNewContact);
      this.setDialogFieldValue(
        "QH",
        contributor.getQualificationsHonours,
        isNewContact
      );
      this.setDialogFieldValue("NI", contributor.getISNI, isNewContact);
      this.setDialogFieldValue("OI", contributor.getORCID, isNewContact);
      if (
        contributor.getProfessionalAffiliations &&
        contributor.getProfessionalAffiliations.length
      ) {
        this.setDialogFieldValue(
          "PP",
          contributor.getProfessionalAffiliations[0].getPosition,
          isNewContact
        );
        this.setDialogFieldValue(
          "PA",
          contributor.getProfessionalAffiliations[0].getOrganisation,
          isNewContact
        );
      } else {
        this.setDialogFieldValue("PP", "", isNewContact);
        this.setDialogFieldValue("PA", "", isNewContact);
      }
      if (contributor.getDateOfBirth) {
        if (contributor.getDateOfBirth.$dateFields != 0) {
          this.setDialogFieldValue("DBT", "Y", isNewContact);
        } else {
          this.setDialogFieldValue("DBT", "D", isNewContact);
        }
        this.setDialogFieldValue(
          "DBY",
          this.parseDate(
            contributor.getDateOfBirth.$date,
            "yyyy",
            this.dateOptions,
            true
          ),
          isNewContact
        );
        this.editorDateBorn = this.parseDate(
          contributor.getDateOfBirth.$date,
          this.$userPreferences.dateFormat,
          this.dateOptions
        );
        this.setDialogFieldValue("DBD", this.editorDateBorn, isNewContact);
      } else {
        this.setDialogFieldValue("DBT", "D", isNewContact);
        this.setDialogFieldValue("DBY", "", isNewContact);
        this.editorDateBorn = "";
        this.setDialogFieldValue("DBD", "", isNewContact);
      }
      this.changeDateType("DB", isNewContact);

      if (contributor.getDateOfDeath) {
        if (contributor.getDateOfDeath.$dateFields != 0) {
          this.setDialogFieldValue("DDT", "Y", isNewContact);
        } else {
          this.setDialogFieldValue("DDT", "D", isNewContact);
        }
        this.setDialogFieldValue(
          "DDY",
          this.parseDate(
            contributor.getDateOfDeath.$date,
            "yyyy",
            this.dateOptions,
            true
          ),
          isNewContact
        );
        this.editorDateDied = this.parseDate(
          contributor.getDateOfDeath.$date,
          this.$userPreferences.dateFormat,
          this.dateOptions
        );
        this.setDialogFieldValue("DDD", this.editorDateDied, isNewContact);
      } else {
        this.setDialogFieldValue("DDT", "D", isNewContact);
        this.setDialogFieldValue("DDY", "", isNewContact);
        this.editorDateDied = "";
        this.setDialogFieldValue("DDD", "", isNewContact);
      }
      this.changeDateType("DD", isNewContact);

      this.setDialogFieldValue(
        "NA",
        contributor.getNationalityCountry,
        isNewContact
      );
      if (contributor.getPlaceBorn) {
        this.setDialogFieldValue(
          "PBT",
          contributor.getPlaceBorn.getTown,
          isNewContact
        );
        this.setDialogFieldValue(
          "PBC",
          contributor.getPlaceBorn.getRegion
            ? contributor.getPlaceBorn.getRegion
            : contributor.getPlaceBorn.getCountry,
          isNewContact
        );
      } else {
        this.setDialogFieldValue("PBT", "", isNewContact);
        this.setDialogFieldValue("PBC", "", isNewContact);
      }

      if (contributor.getPlaceDied) {
        this.setDialogFieldValue(
          "PDT",
          contributor.getPlaceDied.getTown,
          isNewContact
        );
        this.setDialogFieldValue(
          "PDC",
          contributor.getPlaceDied.getRegion
            ? contributor.getPlaceDied.getRegion
            : contributor.getPlaceDied.getCountry,
          isNewContact
        );
      } else {
        this.setDialogFieldValue("PDT", "", isNewContact);
        this.setDialogFieldValue("PDC", "", isNewContact);
      }

      if (contributor.getPlaceResides) {
        this.setDialogFieldValue(
          "PRT",
          contributor.getPlaceResides.getTown,
          isNewContact
        );
        this.setDialogFieldValue(
          "PRC",
          contributor.getPlaceResides.getRegion
            ? contributor.getPlaceResides.getRegion
            : contributor.getPlaceResides.getCountry,
          isNewContact
        );
      } else {
        this.setDialogFieldValue("PRT", "", isNewContact);
        this.setDialogFieldValue("PRC", "", isNewContact);
      }

      if (contributor.getPlaceFormerlyResided) {
        this.setDialogFieldValue(
          "PPT",
          contributor.getPlaceFormerlyResided.getTown,
          isNewContact
        );
        this.setDialogFieldValue(
          "PPC",
          contributor.getPlaceFormerlyResided.getRegion
            ? contributor.getPlaceFormerlyResided.getRegion
            : contributor.getPlaceFormerlyResided.getCountry,
          isNewContact
        );
      } else {
        this.setDialogFieldValue("PPT", "", isNewContact);
        this.setDialogFieldValue("PPC", "", isNewContact);
      }

      if (contributor.getPlaceEducated) {
        this.setDialogFieldValue(
          "PET",
          contributor.getPlaceEducated.getTown,
          isNewContact
        );
        this.setDialogFieldValue(
          "PEC",
          contributor.getPlaceEducated.getRegion
            ? contributor.getPlaceEducated.getRegion
            : contributor.getPlaceEducated.getCountry,
          isNewContact
        );
      } else {
        this.setDialogFieldValue("PET", "", isNewContact);
        this.setDialogFieldValue("PEC", "", isNewContact);
      }

      if (contributor.getPlaceWorked) {
        this.setDialogFieldValue(
          "PWT",
          contributor.getPlaceWorked.getTown,
          isNewContact
        );
        this.setDialogFieldValue(
          "PWC",
          contributor.getPlaceWorked.getRegion
            ? contributor.getPlaceWorked.getRegion
            : contributor.getPlaceWorked.getCountry,
          isNewContact
        );
      } else {
        this.setDialogFieldValue("PWT", "", isNewContact);
        this.setDialogFieldValue("PWC", "", isNewContact);
      }

      if (contributor.getPlaceFlourished) {
        this.setDialogFieldValue(
          "PFT",
          contributor.getPlaceFlourished.getTown,
          isNewContact
        );
        this.setDialogFieldValue(
          "PFC",
          contributor.getPlaceFlourished.getRegion
            ? contributor.getPlaceFlourished.getRegion
            : contributor.getPlaceFlourished.getCountry,
          isNewContact
        );
      } else {
        this.setDialogFieldValue("PFT", "", isNewContact);
        this.setDialogFieldValue("PFC", "", isNewContact);
      }

      if (contributor.getPlaceRegisteredIn) {
        this.setDialogFieldValue(
          "PGT",
          contributor.getPlaceRegisteredIn.getTown,
          isNewContact
        );
        this.setDialogFieldValue(
          "PGC",
          contributor.getPlaceRegisteredIn.getRegion
            ? contributor.getPlaceRegisteredIn.getRegion
            : contributor.getPlaceRegisteredIn.getCountry,
          isNewContact
        );
      } else {
        this.setDialogFieldValue("PGT", "", isNewContact);
        this.setDialogFieldValue("PGC", "", isNewContact);
      }

      if (contributor.getPlaceOperatingFrom) {
        this.setDialogFieldValue(
          "POT",
          contributor.getPlaceOperatingFrom.getTown,
          isNewContact
        );
        this.setDialogFieldValue(
          "POC",
          contributor.getPlaceOperatingFrom.getRegion
            ? contributor.getPlaceOperatingFrom.getRegion
            : contributor.getPlaceOperatingFrom.getCountry,
          isNewContact
        );
      } else {
        this.setDialogFieldValue("POT", "", isNewContact);
        this.setDialogFieldValue("POC", "", isNewContact);
      }

      this.setDialogFieldValue(
        "CN",
        contributor.getCorporateBody,
        isNewContact
      );
      this.setDialogFieldValue("BN", contributor.getBiographicalNote);
      this.setDialogFieldValue("BNF", contributor.getBiographicalNoteFormat);
      if (contributor.getWebsites && contributor.getWebsites.length) {
        this.setDialogFieldValue("WA", contributor.getWebsites[0].getLinkUrl);
        this.setDialogFieldValue("WT", contributor.getWebsites[0].getRole);
      } else {
        this.setDialogFieldValue("WA", "");
        this.setDialogFieldValue("WT", "");
      }

      // biographical note editor
      if (!this.biographyEditor) {
        // eslint-disable-next-line no-undef
        this.biographyEditor = new QuillEx(
          "#bibliocontributorsedit-editor-biogeditor",
          {
            modules: {
              toolbar: [
                [
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  {
                    script: "sub",
                  },
                  {
                    script: "super",
                  },
                ],
                [
                  {
                    indent: "-1",
                  },
                  {
                    indent: "+1",
                  },
                  {
                    align: [],
                  },
                ],
                [
                  {
                    list: "ordered",
                  },
                  {
                    list: "bullet",
                  },
                  "blockquote",
                  "hr",
                ],
                ["link"],
                ["clean"],
              ],
            },
            theme: "snow",
          }
        );
        this.biographyEditor.setToolbarTips([
          ["bold", "Bold"],
          ["italic", "Italic"],
          ["underline", "Underline"],
          ["strike", "Strikethrough"],
          ["script:sub", "Subscript"],
          ["script:super", "Superscript"],
          ["indent:-1", "Decrease indent"],
          ["indent:+1", "Increase indent"],
          ["align", "Alignment"],
          ["list:ordered", "Numbered list"],
          ["list:bullet", "Bullet list"],
          ["blockquote", "Quote text"],
          ["link", "Link"],
          ["clean", "Clear formatting"],
        ]);
        this.biographyContainerDiv = this.$refs.biogeditor.parentElement;
      }
      this.initBiographyEditor();

      // show the dialog
      this.$bvModal.show("bibliocontributorsedit-editor");
    },

    /**
     * Editor is visible
     */
    editorVisible: function () {
      // set the modal's title
      document.querySelector(
        "#bibliocontributorsedit-editor h5.modal-title"
      ).innerHTML = this.editDialog.new
        ? "New Contributor"
        : "Edit Contributor";

      // hide the no-contributors checkbox
      this.showNoContributor();
    },

    /**
     *  Set a dialog field value
     */
    setDialogFieldValue: function (field, newValue, enabled = null) {
      let control = this.$refs.form[field]
        ? this.$refs.form[field]
        : this.$refs[field + "ctrl"];
      if (control) {
        if (!control.type && control.length && control[0].type == "radio") {
          for (let i = 0; i < control.length; i++) {
            if (control[i].value == newValue) {
              control[i].checked = true;
              break;
            }
          }
          if (enabled !== null) {
            for (let i = 0; i < control.length; i++) {
              control[i].disabled = !enabled;
            }
          }
        } else if (control.type == "select-one") {
          let listIndex = -1;
          for (let i = 0; i < control.length; i++) {
            if (control.options[i].value == newValue) {
              listIndex = i;
              break;
            }
          }
          control.selectedIndex = listIndex;
          if (enabled !== null) {
            control.disabled = !enabled;
          }
        } else if (control.type == "checkbox" || control.type == "radio") {
          control.checked = control.value == newValue;
          if (enabled !== null) {
            control.disabled = !enabled;
          }
        } else {
          control.value = newValue;
          if (enabled !== null) {
            control.disabled = !enabled;
          }
        }
      }
    },

    /**
     * show person/corporate body div
     */
    changeType: function (type, radioClicked) {
      // if a type radio has been clicked and we are adding a new contributor then revert to the select-contact stage
      if (radioClicked && this.editDialog.new) {
        this.editDialog.selectContact = true;
        this.$refs.contactsDropdownP.selected = null;
        this.$refs.contactsDropdownC.selected = null;
        this.setDialogFieldValue("ID", "");
      }

      // unnamed dropdown
      this.$refs.UTctrl.disabled = type != "U";

      // role/internal div
      this.$refs.RIdiv.style.display =
        type == "U" || !this.editDialog.selectContact ? "" : "none";

      // person select div
      this.$refs.PSdiv.style.display =
        type == "P" && this.editDialog.selectContact ? "" : "none";

      // corporate select div
      this.$refs.CSdiv.style.display =
        type == "C" && this.editDialog.selectContact ? "" : "none";

      // person name div
      this.$refs.PNdiv.style.display =
        type == "P" &&
        !this.editDialog.selectContact &&
        !this.editDialog.newContact
          ? ""
          : "none";

      // person names div
      this.$refs.PN2div.style.display =
        type == "P" &&
        !this.editDialog.selectContact &&
        this.editDialog.detailedView
          ? ""
          : "none";

      // corporate name div
      this.$refs.CNdiv.style.display =
        type == "C" && !this.editDialog.selectContact ? "" : "none";

      // person places div
      this.$refs.PPdiv.style.display =
        type == "P" &&
        !this.editDialog.selectContact &&
        this.editDialog.detailedView
          ? ""
          : "none";

      // corporate places div
      this.$refs.CPdiv.style.display =
        type == "C" &&
        !this.editDialog.selectContact &&
        this.editDialog.detailedView
          ? ""
          : "none";

      // biographical note/website div
      this.$refs.BWdiv.style.display =
        type != "U" && !this.editDialog.selectContact ? "" : "none";

      // contributor's contact id label
      if (type == "U" || this.editDialog.selectContact) {
        this.$refs.ContactIDlabel.innerHTML = "";
        this.$refs.ContactIDlabel.title = "";
      } else if (this.$refs.IDctrl.value != "") {
        this.$refs.ContactIDlabel.innerHTML =
          "(id " + this.$refs.IDctrl.value + ")";
        this.$refs.ContactIDlabel.title = "Contact Identifier";
      } else {
        this.$refs.ContactIDlabel.innerHTML = "(id unknown)";
        this.$refs.ContactIDlabel.title = "New Contact, Identifier unknown";
      }

      // show/hide hints, the more/less button and the edit contact button
      if (this.editDialog.newContact) {
        window.$(".contributor-hint").show(0);
        window.$(".contributor-more").hide(0);
        window.$(".contact-edit").hide(0);
      } else {
        window.$(".contributor-hint").hide(0);
        window.$(".contributor-more").show(0);
        window
          .$(".contributor-more")
          .html(this.editDialog.detailedView ? "Hide details" : "Show details");
        window.$(".contact-edit").show(0);
      }

      // if a type radio has been clicked then focus the contact selector/unnamed type dropdown
      if (radioClicked) {
        if (type == "U") {
          this.$refs.UTctrl.focus();
        }
      }

      // store the current type
      this.editDialog.type = type;
    },

    /**
     * Change date type
     */
    changeDateType: function (which, enabled = true) {
      let type = this.getDialogFieldValue(which + "T");
      if (type == "Y") {
        this.$refs[which + "ctrldiv"].style.display = "none";
        this.$refs[which + "Dctrl"].style.display = "none";
        this.$refs[which + "Yctrl"].style.display = "";
      } else {
        this.$refs[which + "ctrldiv"].style.display = enabled
          ? "inline-block"
          : "none";
        this.$refs[which + "Dctrl"].style.display = enabled ? "none" : "";
        this.$refs[which + "Yctrl"].style.display = "none";
      }
    },

    /**
     * Initialise biography editor
     */
    initBiographyEditor: function () {
      if (
        (this.$refs.BNFctrl.value == "02" || this.$refs.BNFctrl.value == "") &&
        (this.$refs.BNctrl.value == "" ||
          this.$refs.BNctrl.value.search(
            /<p>|<p class=|<br *\/?>|<span style=|<ul>|<ol>|<div (class|style)=|<font (face|size)=|<em>|<strong>|<b>|<i>|<a href=|&#?[A-Za-z0-9]+;/
          ) != -1)
      ) {
        this.biographyEditor.setHtml(this.$refs.BNctrl.value);
        this.$refs.BNctrl.style.display = "none";
        this.biographyContainerDiv.style.display = "";
        this.$refs.BNFctrl.value = "02";
        document.getElementById(
          "bibliocontributorsedit-editor-biogtypelink"
        ).innerHTML = "Switch to Text";
      } else {
        this.biographyContainerDiv.style.display = "none";
        this.$refs.BNctrl.style.display = "";
        this.$refs.BNFctrl.value = "06";
        document.getElementById(
          "bibliocontributorsedit-editor-biogtypelink"
        ).innerHTML = "Switch to HTML";
      }
    },

    /**
     * Show/hide details
     */
    showDetails: function () {
      this.editDialog.detailedView = !this.editDialog.detailedView;
      this.changeType(this.editDialog.type, false);
    },

    /**
     * Change role
     */
    changeRole: function (control) {
      let role =
        control.selectedIndex >= 0
          ? control.options[control.selectedIndex].value
          : "";
      let TransLangDiv = this.$refs.TransLangDiv;
      if (TransLangDiv) {
        TransLangDiv.style.display =
          role == "B06" || role == "B08" || role == "B10" ? "" : "none";
      }
    },

    /**
     * Selected contact's data
     */
    contactSelectedData: function (data) {
      // store contact's data
      this.setDialogFieldValue("ID", data.ID);
      if (this.editDialog.new && data.M != "update") {
        this.setDialogFieldValue("RC", data.RC);
        this.setDialogFieldValue("TF", "");
        this.setDialogFieldValue("TT", "");
        this.changeRole(data.RC);
      }
      this.setDialogFieldValue("PN", data.PN, false);
      this.setDialogFieldValue("TP", data.TP, false);
      this.setDialogFieldValue("FN", data.FN, false);
      this.setDialogFieldValue("SP", data.SP, false);
      this.setDialogFieldValue("SN", data.SN, false);
      this.setDialogFieldValue("SS", data.SS, false);
      this.setDialogFieldValue("TS", data.TS, false);
      this.setDialogFieldValue("QH", data.QH, false);
      this.setDialogFieldValue("NI", data.NI, false);
      this.setDialogFieldValue("OI", data.OI, false);
      this.setDialogFieldValue("PP", data.PP, false);
      this.setDialogFieldValue("PA", data.PA, false);
      if (data.DB.length == 6 || data.DB.length == 4) {
        this.setDialogFieldValue("DBT", "Y", false);
        this.setDialogFieldValue(
          "DBY",
          // eslint-disable-next-line no-undef
          DisplayDate(data.DB, this.$userPreferences.dateFormat),
          false
        );
        this.editorDateBorn = "";
        this.setDialogFieldValue("DBD", "");
      } else {
        this.setDialogFieldValue("DBT", "D", false);
        this.setDialogFieldValue("DBY", "", false);
        if (data.DB != "") {
          // eslint-disable-next-line no-undef
          this.editorDateBorn = DisplayDate(
            data.DB,
            this.$userPreferences.dateFormat
          );
        } else {
          this.editorDateBorn = "";
        }
        this.setDialogFieldValue("DBD", this.editorDateBorn);
      }
      this.changeDateType("DB", false);
      if (data.DD.length == 6 || data.DD.length == 4) {
        this.setDialogFieldValue("DDT", "Y", false);
        this.setDialogFieldValue(
          "DDY",
          // eslint-disable-next-line no-undef
          DisplayDate(data.DD, this.$userPreferences.dateFormat),
          false
        );
        this.editorDateDied = "";
        this.setDialogFieldValue("DDD", "");
      } else {
        this.setDialogFieldValue("DDT", "D", false);
        this.setDialogFieldValue("DDY", "", false);
        if (data.DD != "") {
          // eslint-disable-next-line no-undef
          this.editorDateDied = DisplayDate(
            data.DD,
            this.$userPreferences.dateFormat
          );
        } else {
          this.editorDateDied = "";
        }
        this.setDialogFieldValue("DDD", this.editorDateDied);
      }
      this.changeDateType("DD", false);
      this.setDialogFieldValue("NA", data.NA, false);
      this.setDialogFieldValue("PBT", data.PBT, false);
      this.setDialogFieldValue("PBC", data.PBC, false);
      this.setDialogFieldValue("PDT", data.PDT, false);
      this.setDialogFieldValue("PDC", data.PDC, false);
      this.setDialogFieldValue("PRT", data.PRT, false);
      this.setDialogFieldValue("PRC", data.PRC, false);
      this.setDialogFieldValue("PPT", data.PPT, false);
      this.setDialogFieldValue("PPC", data.PPC, false);
      this.setDialogFieldValue("PET", data.PET, false);
      this.setDialogFieldValue("PEC", data.PEC, false);
      this.setDialogFieldValue("PWT", data.PWT, false);
      this.setDialogFieldValue("PWC", data.PWC, false);
      this.setDialogFieldValue("PFT", data.PFT, false);
      this.setDialogFieldValue("PFC", data.PFC, false);
      this.setDialogFieldValue("PGT", data.PGT, false);
      this.setDialogFieldValue("PGC", data.PGC, false);
      this.setDialogFieldValue("POT", data.POT, false);
      this.setDialogFieldValue("POC", data.POC, false);
      this.setDialogFieldValue("CN", data.CN, false);
      this.setDialogFieldValue("BN", data.BN);
      this.setDialogFieldValue("BNF", data.BNF);
      this.setDialogFieldValue("WA", data.WA);
      this.setDialogFieldValue("WT", data.WT);

      this.initBiographyEditor();

      // if not in update mode then update the state flags as we have selected a contact
      if (data.M != "update") {
        this.editDialog.selectContact = false;
        this.editDialog.newContact = false;
        this.editDialog.detailedView = false;
        this.setDialogFieldValue(
          "CT",
          this.editDialog.type,
          this.editDialog.new
        );
        this.changeType(this.editDialog.type, false);
      }
    },

    /**
     * Create contact
     */
    createContact: function () {
      this.setDialogFieldValue("ID", "");
      this.setDialogFieldValue("PN", "", true);
      this.setDialogFieldValue("TP", "", true);
      this.setDialogFieldValue("FN", "", true);
      this.setDialogFieldValue("SP", "", true);
      this.setDialogFieldValue("SN", "", true);
      this.setDialogFieldValue("SS", "", true);
      this.setDialogFieldValue("TS", "", true);
      this.setDialogFieldValue("QH", "", true);
      this.setDialogFieldValue("NI", "", true);
      this.setDialogFieldValue("OI", "", true);
      this.setDialogFieldValue("PP", "", true);
      this.setDialogFieldValue("PA", "", true);
      this.setDialogFieldValue("DBT", "D", true);
      this.setDialogFieldValue("DBY", "", true);
      this.editorDateBorn = "";
      this.setDialogFieldValue("DBD", "", true);
      this.changeDateType("DB", true);
      this.setDialogFieldValue("DDT", "D", true);
      this.setDialogFieldValue("DDY", "", true);
      this.editorDateDied = "";
      this.setDialogFieldValue("DDD", "", true);
      this.changeDateType("DD", true);
      this.setDialogFieldValue("NA", "", true);
      this.setDialogFieldValue("PBT", "", true);
      this.setDialogFieldValue("PBC", "", true);
      this.setDialogFieldValue("PDT", "", true);
      this.setDialogFieldValue("PDC", "", true);
      this.setDialogFieldValue("PRT", "", true);
      this.setDialogFieldValue("PRC", "", true);
      this.setDialogFieldValue("PPT", "", true);
      this.setDialogFieldValue("PPC", "", true);
      this.setDialogFieldValue("PET", "", true);
      this.setDialogFieldValue("PEC", "", true);
      this.setDialogFieldValue("PWT", "", true);
      this.setDialogFieldValue("PWC", "", true);
      this.setDialogFieldValue("PFT", "", true);
      this.setDialogFieldValue("PFC", "", true);
      this.setDialogFieldValue("PGT", "", true);
      this.setDialogFieldValue("PGC", "", true);
      this.setDialogFieldValue("POT", "", true);
      this.setDialogFieldValue("POC", "", true);
      this.setDialogFieldValue("CN", "", true);
      this.setDialogFieldValue("BN", "");
      this.setDialogFieldValue("BNF", "");
      this.setDialogFieldValue("WA", "");
      this.setDialogFieldValue("WT", "");

      this.initBiographyEditor();

      // we have selected a contact
      this.editDialog.selectContact = false;
      this.editDialog.newContact = true;
      this.editDialog.detailedView = true;
      this.setDialogFieldValue("CT", this.editDialog.type, this.editDialog.new);
      this.changeType(this.editDialog.type, false);
    },

    /**
     * Close the contributors dialog
     */
    saveContributor: function (save) {
      if (save) {
        let dateBorn = "",
          dateBornType = 0,
          dateBornFmt = this.$userPreferences.dateFormat,
          dateDied = "",
          dateDiedType = 0,
          dateDiedFmt = this.$userPreferences.dateFormat;

        // get contributor type
        let contribType = this.getDialogFieldValue("CT");

        // check if we are selecting a contact
        if (this.editDialog.selectContact && contribType != "U") {
          this.$bvModal.msgBoxOk(
            "Please select a contact, or the option to create a new contact"
          );
          return;
        }

        // check for a contributor role
        let role = this.getDialogFieldValue("RC");
        if (role == "") {
          this.$bvModal.msgBoxOk("Please select a role for this contributor");
          return;
        }

        // person checks
        if (contribType == "P") {
          // check surname
          if (this.getDialogFieldValue("SN") == "") {
            this.$bvModal.msgBoxOk("No Surname has been specified");
            return;
          }

          // check ISNI and ORCID (CheckISNI() is in /common/forms.js)
          // eslint-disable-next-line no-undef
          if (!CheckISNI(this.getDialogFieldValue("NI"))) {
            this.$bvModal.msgBoxOk("ISNI (name identifier) is invalid");
            return;
          }
          // eslint-disable-next-line no-undef
          if (!CheckISNI(this.getDialogFieldValue("OI"))) {
            this.$bvModal.msgBoxOk("ORCID (name identifier) is invalid");
            return;
          }

          // check and decode date born (CheckDate() and DecodeDate() are in /common/forms.js)
          if (this.getDialogFieldValue("DBT") == "D") {
            if (typeof this.editorDateBorn == "object") {
              dateBorn = DateTime.fromJSDate(this.editorDateBorn).toFormat(
                "dd/MM/yyyy"
              );
              dateBornFmt = "dmy";
            } else {
              dateBorn = "";
            }
          } else {
            dateBorn = this.getDialogFieldValue("DBY");
          }
          // eslint-disable-next-line no-undef
          if (!CheckDate(dateBorn, dateBornFmt)) {
            this.$bvModal.msgBoxOk("Date born is invalid");
            return;
          }

          // eslint-disable-next-line no-undef
          dateBorn = DecodeDate(dateBorn, this.$userPreferences.dateFormat);
          dateBornType = 0;
          if (dateBorn.length == 8 && dateBorn.substring(6, 8) == "00") {
            dateBorn = dateBorn.substring(0, 6);
            dateBornType = 1;
          }
          if (dateBorn.length == 6 && dateBorn.substring(4, 6) == "00") {
            dateBorn = dateBorn.substring(0, 4);
            dateBornType = 2;
          }

          // check and decode date died (CheckDate() and DecodeDate() are in /common/forms.js)
          if (this.getDialogFieldValue("DDT") == "D") {
            if (typeof this.editorDiedBorn == "object") {
              dateDied = DateTime.fromJSDate(this.editorDiedBorn).toFormat(
                "dd/MM/yyyy"
              );
              dateDiedFmt = "dmy";
            } else {
              dateDied = "";
            }
          } else {
            dateDied = this.getDialogFieldValue("DDY");
          }
          // eslint-disable-next-line no-undef
          if (!CheckDate(dateDied, dateDiedFmt)) {
            this.$bvModal.msgBoxOk("Date died is invalid");
            return;
          }

          // eslint-disable-next-line no-undef
          dateDied = DecodeDate(dateDied, this.$userPreferences.dateFormat);
          dateDiedType = 0;
          if (dateDied.length == 8 && dateDied.substring(6, 8) == "00") {
            dateDied = dateDied.substring(0, 6);
            dateDiedType = 1;
          }
          if (dateDied.length == 6 && dateDied.substring(4, 6) == "00") {
            dateDied = dateDied.substring(0, 4);
            dateDiedType = 2;
          }

          // check places have a country when they have a town
          if (
            this.getDialogFieldValue("PBT") != "" &&
            this.getDialogFieldValue("PBC") == ""
          ) {
            this.$bvModal.msgBoxOk(
              "You have entered a Town/City for the place 'Born in' but you have not selected a Country/Region. You must either select a Country/Region, or remove the Town/City"
            );
            return;
          }
          if (
            this.getDialogFieldValue("PDT") != "" &&
            this.getDialogFieldValue("PDC") == ""
          ) {
            this.$bvModal.msgBoxOk(
              "You have entered a Town/City for the place 'Died in' but you have not selected a Country/Region. You must either select a Country/Region, or remove the Town/City"
            );
            return;
          }
          if (
            this.getDialogFieldValue("PRT") != "" &&
            this.getDialogFieldValue("PRC") == ""
          ) {
            this.$bvModal.msgBoxOk(
              "You have entered a Town/City for the place 'Currently resides in' but you have not selected a Country/Region. You must either select a Country/Region, or remove the Town/City"
            );
            return;
          }
          if (
            this.getDialogFieldValue("PPT") != "" &&
            this.getDialogFieldValue("PPC") == ""
          ) {
            this.$bvModal.msgBoxOk(
              "You have entered a Town/City for the place 'Formerly resided in' but you have not selected a Country/Region. You must either select a Country/Region, or remove the Town/City"
            );
            return;
          }
          if (
            this.getDialogFieldValue("PET") != "" &&
            this.getDialogFieldValue("PEC") == ""
          ) {
            this.$bvModal.msgBoxOk(
              "You have entered a Town/City for the place 'Educated in' but you have not selected a Country/Region. You must either select a Country/Region, or remove the Town/City"
            );
            return;
          }
          if (
            this.getDialogFieldValue("PWT") != "" &&
            this.getDialogFieldValue("PWC") == ""
          ) {
            this.$bvModal.msgBoxOk(
              "You have entered a Town/City for the place 'Worked in' but you have not selected a Country/Region. You must either select a Country/Region, or remove the Town/City"
            );
            return;
          }
          if (
            this.getDialogFieldValue("PFT") != "" &&
            this.getDialogFieldValue("PFC") == ""
          ) {
            this.$bvModal.msgBoxOk(
              "You have entered a Town/City for the place 'Flourished in' but you have not selected a Country/Region. You must either select a Country/Region, or remove the Town/City"
            );
            return;
          }
        } else if (contribType == "C") {
          // corporate body checks
          // check name
          if (this.getDialogFieldValue("CN") == "") {
            this.$bvModal.msgBoxOk("No Name has been specified");
            return;
          }

          // check places have a country when they have a town
          if (
            this.getDialogFieldValue("PGT") != "" &&
            this.getDialogFieldValue("PGC") == ""
          ) {
            this.$bvModal.msgBoxOk(
              "You have entered a Town/City for the place 'Registered in' but you have not selected a Country/Region. You must either select a Country/Region, or remove the Town/City"
            );
            return;
          }
          if (
            this.getDialogFieldValue("POT") != "" &&
            this.getDialogFieldValue("POC") == ""
          ) {
            this.$bvModal.msgBoxOk(
              "You have entered a Town/City for the place 'Operating from' but you have not selected a Country/Region. You must either select a Country/Region, or remove the Town/City"
            );
            return;
          }
        }

        // biographical note editor (if HTML then get content into form field)
        if (this.getDialogFieldValue("BNF") == "02") {
          this.saveBiographyEditor();
        }

        // update main form with edit control values
        let contributor = this.currentContributors[this.editDialog.index];
        contributor.getType = contribType;
        contributor.getUnnamedType = this.getDialogFieldValue("UT", true);
        contributor.getContactId = this.getDialogFieldValue("ID");
        contributor.getRole = role != "" ? role : null;
        contributor.getTranslatedFrom = this.getDialogFieldValue("TF", true);
        contributor.getTranslatedTo = this.getDialogFieldValue("TT", true);
        contributor.getInternal = this.getDialogFieldValue("IN") != "";
        contributor.getTitlesBefore = this.getDialogFieldValue("TP", true);
        contributor.getFirstNames = this.getDialogFieldValue("FN", true);
        contributor.getSurnamePrefix = this.getDialogFieldValue("SP", true);
        contributor.getSurname = this.getDialogFieldValue("SN", true);
        contributor.getSurnameSuffix = this.getDialogFieldValue("SS", true);
        contributor.getNamesAfter = null;
        contributor.getTitlesAfter = this.getDialogFieldValue("TS", true);
        contributor.getQualificationsHonours = this.getDialogFieldValue(
          "QH",
          true
        );
        contributor.getISNI = this.getDialogFieldValue("NI", true);
        contributor.getORCID = this.getDialogFieldValue("OI", true);

        let professionalPosition = this.getDialogFieldValue("PP", true);
        let professionalOrganisation = this.getDialogFieldValue("PA", true);
        if (professionalPosition || professionalOrganisation) {
          contributor.getProfessionalAffiliations = [
            {
              getPosition: professionalPosition,
              getOrganisation: professionalOrganisation,
            },
          ];
        } else {
          contributor.getProfessionalAffiliations = [];
        }

        if (dateBorn != "") {
          contributor.getDateOfBirth = {
            $date: dateBorn,
            $dateFields: dateBornType,
          };
        } else {
          contributor.getDateOfBirth = null;
        }
        if (dateDied != "") {
          contributor.getDateOfDeath = {
            $date: dateDied,
            $dateFields: dateDiedType,
          };
        } else {
          contributor.getDateOfDeath = null;
        }

        contributor.getNationalityCountry = this.getDialogFieldValue(
          "NA",
          true
        );

        let placeFields = [
          "PB",
          "PD",
          "PR",
          "PP",
          "PE",
          "PW",
          "PF",
          "PG",
          "PO",
        ];
        let placeProps = [
          "getPlaceBorn",
          "getPlaceDied",
          "getPlaceResides",
          "getPlaceFormerlyResided",
          "getPlaceEducated",
          "getPlaceWorked",
          "getPlaceFlourished",
          "getPlaceRegisteredIn",
          "getPlaceOperatingFrom",
        ];
        for (let i = 0; i < placeFields.length; i++) {
          let country = this.getDialogFieldValue(placeFields[i] + "C", true);
          let town = this.getDialogFieldValue(placeFields[i] + "T", true);
          if (country) {
            contributor[placeProps[i]] = {
              getCountry: country.substring(0, 2),
              getRegion: country.length >= 3 ? country : null,
              getTown: town,
            };
          } else {
            contributor[placeProps[i]] = null;
          }
        }

        contributor.getCorporateBody = this.getDialogFieldValue("CN", true);
        contributor.getBiographicalNote = this.getDialogFieldValue("BN", true);
        contributor.getBiographicalNoteFormat = this.getDialogFieldValue(
          "BNF",
          true
        );

        let websiteUrl = this.getDialogFieldValue("WA", true);
        if (websiteUrl) {
          contributor.getWebsites = [
            {
              getLinkUrl: websiteUrl,
              getRole: this.getDialogFieldValue("WT", true),
            },
          ];
        } else {
          contributor.getWebsites = [];
        }

        // notify changes
        this.notifyChanges();
      } else {
        // dialog cancelled
        if (this.editDialog.new) {
          // delete the contributor entry added when the user clicked on 'New Contributor',
          // do not prompt whether to delete or notify
          this.currentContributors.splice(this.editDialog.index, 1);
        }
      }

      // hide the dialog
      this.editDialog = {};
      this.$bvModal.hide("bibliocontributorsedit-editor");
    },

    /**
     * Get a dialog field value
     */
    getDialogFieldValue: function (field, nullIfEmpty = false) {
      let control = this.$refs.form[field]
        ? this.$refs.form[field]
        : this.$refs[field + "ctrl"];
      let value = "";
      if (control) {
        if (!control.type && control.length && control[0].type == "radio") {
          for (let i = 0; i < control.length; i++) {
            if (control[i].checked) {
              value = control[i].value;
              break;
            }
          }
        } else if (control.type == "select-one") {
          if (control.selectedIndex >= 0) {
            value = control.options[control.selectedIndex].value;
          }
        } else if (control.type == "checkbox" || control.type == "radio") {
          if (control.checked) {
            value = control.value;
          }
        } else {
          value = control.value;
        }
      }
      if (value.length != 0) {
        return value;
      }
      return nullIfEmpty ? null : "";
    },

    /**
     * Save biography editor
     */
    saveBiographyEditor: function () {
      if (this.$refs.BNFctrl.value == "02") {
        this.$refs.BNctrl.value = this.biographyEditor.getHtml();
      }
    },

    /**
     * Toggle biography editor
     */
    toggleBiographyEditor: function () {
      if (this.$refs.BNFctrl.value == "02") {
        this.$refs.BNctrl.value = this.biographyEditor.getHtml();
        this.biographyContainerDiv.style.display = "none";
        this.$refs.BNctrl.style.display = "";
        this.$refs.BNFctrl.value = "06";
        document.getElementById(
          "bibliocontributorsedit-editor-biogtypelink"
        ).innerHTML = "Switch to HTML";
      } else {
        this.biographyEditor.setHtml(this.$refs.BNctrl.value);
        this.$refs.BNctrl.style.display = "none";
        this.biographyContainerDiv.style.display = "";
        this.$refs.BNFctrl.value = "02";
        document.getElementById(
          "bibliocontributorsedit-editor-biogtypelink"
        ).innerHTML = "Switch to Text";
      }
    },

    /**
     * Edit contact
     */
    editContact: function () {
      let contactId = this.getDialogFieldValue("ID");
      if (contactId != "") {
        window.vueApp.$bsnxEditContactParent = this;
        let iframe = this.$refs.ContactEditFrame;
        iframe.src =
          this.$baseUrl + "contacts/edit/" + contactId + "/vue-iframe";
        iframe.style.display = "block";
      }
    },

    /**
     * Close edit contact
     */
    closeEditContact: function (update) {
      delete window.vueApp.$bsnxEditContactParent;
      this.$refs.ContactEditFrame.style.display = "none";
      if (update) {
        this.updateFromContact();
      }
    },

    /**
     * Update from contact
     */
    updateFromContact: function () {
      let contactId = this.getDialogFieldValue("ID");
      if (contactId != "") {
        let url =
          this.$baseUrl +
          "biblio-edit/contact-selector-info/" +
          contactId +
          "/update";

        HTTP.get(url).then((response) => {
          this.contactSelectedData(response.data);
        });
      }
    },

    /**
     * Move a contributor up
     */
    moveContributorUp: function (index) {
      if (index >= 1) {
        let contributor = this.currentContributors.splice(index, 1);
        this.currentContributors.splice(index - 1, 0, contributor[0]);
        this.notifyChanges();
      }
    },

    /**
     * Move a contributor down
     */
    moveContributorDn: function (index) {
      if (index < this.currentContributors.length - 1) {
        let contributor = this.currentContributors.splice(index, 1);
        this.currentContributors.splice(index + 1, 0, contributor[0]);
        this.notifyChanges();
      }
    },

    /**
     * Delete a contributor
     */
    deleteContributor: function (index) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure that you want to delete this contributor ?"
        )
        .then((result) => {
          if (result) {
            this.currentContributors.splice(index, 1);
            this.notifyChanges();
          }
        });
    },

    /**
     * show/hide the no-contributor check box
     */
    showNoContributor: function () {
      let NoContribObj = document.getElementById("NoContributors");
      if (NoContribObj) {
        NoContribObj.style.display =
          this.currentContributors.length != 0 ? "none" : "";
      }
    },

    /**
     * notify of changed contributors
     */
    notifyChanges: function () {
      if (this.currentContributors.length != 0) {
        let contributors = [];
        for (let i = 0; i < this.currentContributors.length; i++) {
          contributors.push(this.currentContributors[i]);
        }
        this.$emit("changed", contributors);
      } else if (this.currentNoContributors) {
        this.$emit("changed", false);
      } else {
        this.$emit("changed", []);
      }
    },

    /**
     * HTML encode a string
     */
    htmlEncode: function (str) {
      return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
    },
  },
};
</script>

<style>
.bibliocontributorsedit-header {
  margin-bottom: 0.5rem;
}
.bibliocontributorsedit-add {
  margin-left: 1rem;
}
.bibliocontributorsedit-item {
  padding: 4px 8px;
  background-color: white;
  border: 1px solid silver;
  border-radius: 5px;
}
.bibliocontributorsedit-btns {
  text-align: right;
}
#bibliocontributorsedit-editor-db,
#bibliocontributorsedit-editor-dd {
  width: 95%;
}
.bibliocontributorsedit-legend {
  margin-top: 2em;
}
</style>
