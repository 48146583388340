<template>
  <b-card id="productCardContributors">
    <template #header>
      <a id="contributors" />
      <h5 class="d-inline-block">Contributors</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-contrib/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <product-contributors
      :contributors="contributors"
      :contributors-statement="contributorsStatement"
      :return-to="returnTo"
    >
    </product-contributors>
  </b-card>
</template>

<script>
/**
 * Displays a product's contributor information card
 */
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductContributors from "./ProductContributors.vue";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "ProductContributorsCard",

  components: {
    "product-contributors": ProductContributors,
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [DisplaySetting],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * List of contributors
     */
    contributors: {
      type: Array,
      default: () => [],
    },

    /**
     * Contributor statement
     */
    contributorsStatement: {
      type: String,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
