<template>
  <div>
    <v-select
      v-model="selected"
      class="base-select"
      v-bind="$attrs"
      v-on="$listeners"
      @input="onInput"
    >
      <template
        v-for="(index, slotName) in $scopedSlots"
        v-slot:[slotName]="data"
      >
        <slot :name="slotName" v-bind="data"></slot>
      </template>
    </v-select>
    <input v-if="name" :id="id" type="hidden" :name="name" :value="selected" />
  </div>
</template>

<script>
/**
 * Base select / drop down list
 *
 * Uses Vue-Select for most functionality but this component
 * also applies a hidden form input so it can be used in
 * HTML forms.
 *
 * This component acts as a wrapper for v-select and all
 * props for v-select can be passed to this component.
 * Therefore see the Vue Select documentation for useage instructions:
 *
 * https://vue-select.org/guide
 *
 */

import vSelect from "vue-select";

export default {
  name: "BaseSelect",

  components: {
    "v-select": vSelect,
  },

  props: {
    /**
     * The id of the form input
     */
    id: {
      type: String,
      default: null,
    },

    /**
     * The name of the form input (used for form data)
     *
     * Must be included for the form input to be included
     */
    name: {
      type: String,
      default: null,
    },

    /**
     * The starting value of the form input
     * This will be updated when the select value changes
     */
    value: {
      type: [Number, String, Object, Array],
      default: null,
    },
  },

  data() {
    return {
      selected: this.value,
    };
  },

  watch: {
    value(value) {
      // update the selected property when the prop changes
      this.selected = value;
    },
  },

  methods: {
    /**
     * Triggers when the select input is updated
     */
    onInput: function (value) {
      /**
       * input event
       */
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
@use "vue-select/src/scss/vue-select.scss" with (
  $vs-component-bg: #fff
);

.vs__selected-options ::placeholder {
  color: #6c757d !important;
  opacity: 1;
}

.vs--single {
  &:not(.vs--open) .vs__selected + .vs__search {
    // force this to not use any space
    // we still need it to be rendered for the focus
    width: 0;
    padding: 0;
    margin: 0;
    border: none;
    height: 0;
  }

  .vs__selected-options {
    // do not allow growing
    width: 0;
  }

  .vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
}
</style>
