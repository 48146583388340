<template>
  <div>
    <div class="row bibliolanguagesedit-header">
      <div class="col-3">
        <label>
          {{ header }}
        </label>
        <button
          type="button"
          title="Add another language"
          class="btn btn-outline-primary btn-sm bibliolanguagesedit-add"
          @click="addLanguage"
        >
          <i class="fa fa-plus"></i>
          Add
        </button>
      </div>
    </div>
    <div class="row bibliolanguagesedit-items">
      <div
        v-for="(language, languageIndex) in currentLanguages"
        :key="languageIndex"
        class="col-6 bibliolanguagesedit-item"
      >
        <select
          size="1"
          class="bibliolanguagesedit-language"
          @change="selectedLanguage(languageIndex, $event.target)"
        >
          <option value="">(select language)</option>
          <option
            v-for="languageInfo in allLanguages"
            :key="languageInfo.code"
            :value="languageInfo.code"
            :selected="languageInfo.code == language.getLanguage"
          >
            {{ languageInfo.name }}
          </option>
        </select>
        <select
          size="1"
          class="bibliolanguagesedit-country"
          @change="selectedCountry(languageIndex, $event.target)"
        >
          <option value="">(optional national variant)</option>
          <optgroup
            v-for="continent in allCountries"
            :key="continent.code"
            :label="continent.code"
          >
            <template v-for="country in continent.name">
              <option
                v-if="country.code.length >= 4"
                :key="country.code"
                :value="country.code"
                :selected="country.code == language.getRegion"
              >
                &emsp;
                {{ country.name }}
              </option>
              <option
                v-else
                :key="country.code"
                :value="country.code"
                :selected="country.code == language.getCountry"
              >
                {{ country.name }}
              </option>
            </template>
          </optgroup>
        </select>
        <a
          href="#"
          title="Delete this language"
          class="bibliolanguagesedit-delete"
          @click="deleteLanguage(languageIndex)"
        >
          <i class="fa fa-trash"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Bibliographic languages edit container to edit the languages for a given role
 *
 * Emits a 'changed' event including an array of language objects (excluding empty entries)
 */
export default {
  name: "BiblioLanguagesEdit",

  props: {
    /**
     * Header text
     */
    header: {
      type: String,
      required: true,
    },

    /**
     * Languages role code
     */
    role: {
      type: String,
      required: true,
    },

    /**
     * Languages to edit
     */
    languages: {
      type: Array,
      default: null,
    },

    /**
     * List of all languages
     */
    allLanguages: {
      type: Array,
      required: true,
    },

    /**
     * List of all countries grouped by continent and optionally with regions
     */
    allCountries: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    let dataObject = {
      currentLanguages: this.languages ? this.languages : [],
    };
    if (dataObject.currentLanguages.length == 0) {
      dataObject.currentLanguages.push({
        getLanguage: "",
        getCountry: null,
        getRegion: null,
        getScript: null,
      });
    }
    return dataObject;
  },

  methods: {
    /**
     * Add a language
     * Note: we don't notify of changes as we have added a language entry with no language selected
     */
    addLanguage: function () {
      this.currentLanguages.push({
        getLanguage: "",
        getCountry: null,
        getRegion: null,
        getScript: null,
      });
    },

    /**
     * Delete a language
     */
    deleteLanguage: function (index) {
      this.currentLanguages.splice(index, 1);
      this.notifyChanges();
    },

    /**
     * A language has been selected
     */
    selectedLanguage: function (index, control) {
      this.currentLanguages[index].getLanguage =
        control.options[control.selectedIndex].value;
      this.notifyChanges();
    },

    /**
     * A country has been selected
     */
    selectedCountry: function (index, control) {
      let country = control.options[control.selectedIndex].value;
      this.currentLanguages[index].getCountry = country.substr(0, 2);
      this.currentLanguages[index].getRegion =
        country.length >= 3 ? country : null;
      this.notifyChanges();
    },

    /**
     * notify of changed languages
     */
    notifyChanges: function () {
      // select language entries with a language
      let languages = [];
      for (let i = 0; i < this.currentLanguages.length; i++) {
        if (this.currentLanguages[i].getLanguage != "") {
          languages.push(this.currentLanguages[i]);
        }
      }
      this.$emit("changed", languages);
    },
  },
};
</script>

<style>
.bibliolanguagesedit-header {
  margin-bottom: 0.5rem;
}
.bibliolanguagesedit-add {
  margin-left: 1rem;
}
.bibliolanguagesedit-items {
  margin-bottom: 1rem;
}
.bibliolanguagesedit-language {
  width: 45%;
}
.bibliolanguagesedit-country {
  width: 45%;
  margin-left: 1rem;
}
.bibliolanguagesedit-delete {
  padding-left: 1rem;
}
</style>
