var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"biblioidentifiersedit"},[_c('b-dropdown',{attrs:{"variant":"none","text":"Add","toggle-class":"btn-outline-primary biblioidentifiersedit-add"}},_vm._l((_vm.types),function(type){return _c('b-dropdown-item',{key:type.code,attrs:{"disabled":_vm.typesInUse.indexOf(type.code) != -1},on:{"click":function($event){return _vm.addIdentifier(type.code)}}},[_vm._v(" "+_vm._s(type.name)+" ")])}),1),_vm._l((_vm.currentIdentifiers),function(identifier,identifierIndex){return _c('div',{key:identifierIndex,staticClass:"biblioidentifiersedit-item"},[_vm._l((_vm.types),function(type,typeIndex){return [(type.code == identifier.type)?[(identifier.displayMode)?[_c('div',{key:400 * identifierIndex + 4 * typeIndex,staticClass:"biblioidentifiersedit-item-display"},[_vm._v(" "+_vm._s(type.name)+": "),_c('span',{on:{"click":function($event){return _vm.switchToEdit(identifierIndex)}}},[_vm._v(" "+_vm._s(identifier.value)+" — "),_c('em',[_vm._v(_vm._s(identifier.displayData.title))])])]),_c('a',{key:400 * identifierIndex + 4 * typeIndex + 1,attrs:{"href":"#","title":'Edit this ' + type.name + ' identifier'},on:{"click":function($event){$event.stopPropagation();return _vm.switchToEdit(identifierIndex)}}},[_c('i',{staticClass:"fa fa-edit"})])]:(_vm.lookupUrl)?[_vm._v(" "+_vm._s(type.name)+": "),_c('input',{key:400 * identifierIndex + 4 * typeIndex,attrs:{"id":'biblioidentifiersedit-' +
              _vm.componentId +
              '-item-' +
              identifierIndex,"type":"text","maxlength":"50","pattern":type.pattern,"placeholder":type.name,"data-idtype":type.code,"data-idlookup":type.lookup ? type.lookup : type.code},domProps:{"value":identifier.value},on:{"click":function($event){$event.stopPropagation();},"focus":function($event){return _vm.performLookup(identifierIndex, $event.target)},"input":function($event){return _vm.performLookup(identifierIndex, $event.target)},"change":function($event){return _vm.changedValue(identifierIndex, $event.target)}}}),(identifier.displayData)?_c('a',{key:400 * identifierIndex + 4 * typeIndex + 1,attrs:{"href":"#","title":'Cancel editing this ' + type.name + ' identifier'},on:{"click":function($event){$event.stopPropagation();return _vm.cancelEdit(identifierIndex)}}},[_c('i',{staticClass:"fa fa-xmark"})]):_vm._e()]:[_vm._v(" "+_vm._s(type.name)+": "),_c('input',{key:400 * identifierIndex + 4 * typeIndex,attrs:{"id":'biblioidentifiersedit-' +
              _vm.componentId +
              '-item-' +
              identifierIndex,"type":"text","maxlength":"50","pattern":type.pattern,"placeholder":type.name},domProps:{"value":identifier.value},on:{"change":function($event){return _vm.changedValue(identifierIndex, $event.target)}}})],_c('a',{key:400 * identifierIndex + 4 * typeIndex + 2,attrs:{"href":"#","title":'Remove this ' + type.name + ' identifier'},on:{"click":function($event){$event.stopPropagation();return _vm.deleteIdentifier(identifierIndex)}}},[_c('i',{staticClass:"fa fa-trash"})]),_vm._v("   "),(_vm.lookupUrl)?_c('div',{key:400 * identifierIndex + 4 * typeIndex + 3,staticClass:"biblioidentifiersedit-results",attrs:{"id":'biblioidentifiersedit-' +
            _vm.componentId +
            '-item-' +
            identifierIndex +
            '-results'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectResult(identifierIndex, $event.target)}}}):_vm._e()]:_vm._e()]})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }