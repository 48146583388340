<template>
  <div
    :class="editBoxClass"
    draggable
    :data-ci-key="currentContentItem.key"
    :data-ci-seq="currentContentItem.getSequenceNumber"
    :data-ci-lvl="currentContentItem.level"
    @dragstart="dragStart"
    @dragend="dragEnd"
    @dragenter.prevent="dragEnter"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="dragDrop"
  >
    <div class="col-2 pl-0">
      <span class="fa fa-grip-vertical"></span>
      <inline-edit
        ref="ComponentTypeNameCtrl"
        :value="currentContentItem.getComponentTypeName"
        display-no-value="Item type"
        edit-placeholder="Item type"
        :edit-max-length="20"
        :async-saving="true"
        class="contentitemeditbox-ctype"
        @editing="$emit('field-editing')"
        @save="(newValue) => fieldChanged('ComponentTypeName', newValue)"
      />
      <inline-edit
        ref="ComponentNumberCtrl"
        :value="currentContentItem.getComponentNumber"
        display-no-value="Item No"
        edit-placeholder="Item No"
        :edit-max-length="20"
        :async-saving="true"
        class="contentitemeditbox-cnum"
        @editing="$emit('field-editing')"
        @save="(newValue) => fieldChanged('ComponentNumber', newValue)"
      />
    </div>
    <div class="col-5 pl-0">
      <inline-edit
        ref="MainTitleCtrl"
        :value="currentContentItem.getMainTitle"
        display-no-value="Title"
        edit-placeholder="Title"
        :edit-max-length="300"
        :async-saving="true"
        class="contentitemeditbox-title"
        @editing="$emit('field-editing')"
        @save="(newValue) => fieldChanged('MainTitle', newValue)"
      />
      <inline-edit
        ref="MainTitleSubtitleCtrl"
        :value="currentContentItem.getMainTitleSubtitle"
        display-no-value="Subtitle"
        edit-placeholder="Subtitle"
        :edit-max-length="300"
        :async-saving="true"
        class="contentitemeditbox-subtitle"
        @editing="$emit('field-editing')"
        @save="(newValue) => fieldChanged('MainTitleSubtitle', newValue)"
      />
    </div>
    <div class="col-4 pl-0">
      <inline-edit
        v-if="editType == 'av'"
        ref="AVItemTypeCtrl"
        :value="currentContentItem.getAVItemType"
        :async-saving="true"
        class="contentitemeditbox-itemtype"
        edit-type="select-one"
        :edit-options="itemTypes"
        @editing="$emit('field-editing')"
        @save="(newValue) => fieldChanged('AVItemType', newValue)"
      />
      <inline-edit
        v-else
        ref="TextItemTypeCtrl"
        :value="currentContentItem.getTextItemType"
        :async-saving="true"
        class="contentitemeditbox-itemtype"
        edit-type="select-one"
        :edit-options="itemTypes"
        @editing="$emit('field-editing')"
        @save="(newValue) => fieldChanged('TextItemType', newValue)"
      />
      <inline-edit
        v-if="editType == 'av'"
        ref="TimeRunsCtrl"
        :value="currentContentItem.getTimeRuns"
        display-no-value="Times"
        edit-placeholder="Times"
        :async-saving="true"
        class="contentitemeditbox-times"
        @editing="$emit('field-editing')"
        @save="(newValue) => fieldChanged('TimeRuns', newValue)"
      />
      <inline-edit
        v-else
        ref="PageRunsCtrl"
        :value="currentContentItem.getPageRuns"
        display-no-value="Pages"
        edit-placeholder="Pages"
        :async-saving="true"
        class="contentitemeditbox-pages"
        @editing="$emit('field-editing')"
        @save="(newValue) => fieldChanged('PageRuns', newValue)"
      />
      <inline-edit
        v-if="editType == 'av'"
        ref="TimeDurationCtrl"
        :value="currentContentItem.getTimeDuration"
        display-no-value="Time Duration"
        edit-placeholder="Time Duration"
        :edit-max-length="9"
        :async-saving="true"
        class="contentitemeditbox-timeduration"
        edit-pattern="([0-9]?[0-9]?[0-9]:)?([0-5]?[0-9]:)?[0-5]?[0-9]"
        @editing="$emit('field-editing')"
        @save="(newValue) => fieldChanged('TimeDuration', newValue)"
      />
      <inline-edit
        v-else
        ref="PageCountCtrl"
        :value="currentContentItem.getPageCount"
        display-no-value="Page Count"
        edit-placeholder="Page Count"
        :edit-max-length="6"
        :async-saving="true"
        class="contentitemeditbox-pagecount"
        edit-pattern="[1-9][0-9]*"
        @editing="$emit('field-editing')"
        @save="(newValue) => fieldChanged('PageCount', newValue)"
      />
    </div>
    <div class="col-1 pl-0">
      <a
        href="#"
        class="contentitemeditbox-edititem"
        @click="$emit('edit-item', currentContentItem.getSequenceNumber)"
      >
        <i class="fa fa-pen-to-square"></i>
      </a>
      <a
        v-if="currentContentItem.level < 4"
        href="#"
        class="contentitemeditbox-additem"
        title="Create a new item below this item"
        @click="$emit('new-item', currentContentItem.getSequenceNumber)"
      >
        <i class="fa fa-plus"></i>
      </a>
      <a
        v-else
        class="contentitemeditbox-additem disabled"
        title="Cannot create a new item below this item"
      >
        <i class="fa fa-plus"></i>
      </a>
      <a
        href="#"
        class="contentitemeditbox-copyitem"
        title="Create a new item which is a copy of this item"
        @click="$emit('copy-item', currentContentItem.getSequenceNumber)"
      >
        <i class="fa fa-clone"></i>
      </a>
      <a
        href="#"
        class="contentitemeditbox-delitem"
        title="Delete this item and all items beneath it"
        @click="deleteBtnClick"
      >
        <i class="fa fa-trash"></i>
      </a>
    </div>
  </div>
</template>

<script>
/**
 * Edit a content item using a simple box control
 */
import InlineEdit from "./InlineEdit.vue";

export default {
  name: "ContentItemEditBox",

  components: {
    "inline-edit": InlineEdit,
  },

  props: {
    /**
     * The edit type (text or av)
     */
    editType: {
      type: String,
      required: true,
    },

    /**
     * The items types
     * An object whose properties are the type codes and their values are the type names
     */
    itemTypes: {
      type: Object,
      default: null,
    },

    /**
     * The content item
     */
    contentItem: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      currentContentItem: this.contentItem,
      selfX: 0,
      selfY: 0,
    };
  },

  computed: {
    /**
     * Edit box class
     */
    editBoxClass: function () {
      let className =
        "row contentitemeditbox level" + this.currentContentItem.level;
      if (!this.isValid) {
        className += " contentitemeditbox-invalid";
      }
      return className;
    },

    /**
     * Is this content item valid
     */
    isValid: function () {
      return (
        this.currentContentItem.getComponentTypeName ||
        this.currentContentItem.getMainTitle
      );
    },
  },

  methods: {
    /**
     * Delete button has been clicked
     */
    deleteBtnClick: function () {
      this.$bvModal
        .msgBoxConfirm("Delete this content item and all its children ?")
        .then((value) => {
          if (value) {
            this.$emit(
              "delete-item",
              this.currentContentItem.getSequenceNumber
            );
          }
        });
    },

    /**
     * A editable field has changed
     */
    fieldChanged: function (field, newValue) {
      if (newValue != this.contentItem["get" + field]) {
        // emit the field-changed event
        this.$emit(
          "field-changed",
          this,
          this.currentContentItem.getSequenceNumber,
          field,
          newValue
        );
      } else {
        // the field's value has not changed, we need to tell the inline-edit component
        // that the field has been saved even though we haven't done anything because
        // the inline-edit component is using asynchronus save mode
        this.fieldSaved(field, newValue);
      }
    },

    /**
     * A field has been saved to the database
     */
    fieldSaved: function (field, newValue) {
      this.$refs[field + "Ctrl"].saveComplete();
      this.contentItem["get" + field] = newValue;
    },

    /**
     * A field has not been saved to the database
     */
    fieldNotSaved: function (field) {
      this.$refs[field + "Ctrl"].cancelEdit();
    },

    /**
     * Update the content item
     */
    update: function (contentItem) {
      this.currentContentItem = contentItem;
    },

    /**
     * Drag start
     */
    dragStart: function (event) {
      event.dataTransfer.setData(
        "text",
        this.currentContentItem.getSequenceNumber
      );
      event.dataTransfer.effectAllowed = "move";
      this.$el.classList.add("dragging");
    },

    /**
     * Drag end
     */
    dragEnd: function () {
      this.$el.classList.remove("dragging");
    },

    /**
     * Update our position in client coordinates
     */
    updateSelfXY: function () {
      this.selfX = this.selfY = 0;
      let self = this.$el;
      while (self) {
        this.selfX += self.offsetLeft;
        this.selfY += self.offsetTop;
        self = self.offsetParent;
      } // while
    },

    /**
     * Can we move
     */
    canMove: function (action, event) {
      let target = this.currentContentItem.getSequenceNumber;
      let source = event.dataTransfer.getData("text");

      // cannot drop onto ourself or a desendant
      if (
        target.substring(0, source.length) == source &&
        (target.length == source.length || target.charAt(source.length) == ".")
      ) {
        return false;
      }

      // can drop
      return true;
    },

    /**
     * Drag enter element
     */
    dragEnter: function (event) {
      this.updateSelfXY();
      this.dragOver(event);
    },

    /**
     * Drag over element
     */
    dragOver: function (event) {
      let mouseX = event.clientX - this.selfX;
      let mouseY = event.clientY - this.selfY;
      if (
        this.currentContentItem.level < 4 &&
        mouseX >= this.$el.clientWidth - 100
      ) {
        if (this.canMove("child", event)) {
          event.dataTransfer.dropEffect = "move";
          this.$el.classList.add("dragover-right");
          this.$el.classList.remove("dragover-top", "dragover-bottom");
        } else {
          event.dataTransfer.dropEffect = "none";
        }
      } else if (mouseY < this.$el.clientHeight / 2) {
        if (this.canMove("before", event)) {
          event.dataTransfer.dropEffect = "move";
          this.$el.classList.add("dragover-top");
          this.$el.classList.remove("dragover-bottom", "dragover-right");
        } else {
          event.dataTransfer.dropEffect = "none";
        }
      } else {
        if (this.canMove("after", event)) {
          event.dataTransfer.dropEffect = "move";
          this.$el.classList.add("dragover-bottom");
          this.$el.classList.remove("dragover-top", "dragover-right");
        } else {
          event.dataTransfer.dropEffect = "none";
        }
      }
    },

    /**
     * Drag leave element
     */
    dragLeave: function () {
      this.$el.classList.remove(
        "dragover-top",
        "dragover-bottom",
        "dragover-right"
      );
    },

    /**
     * Drag drop onto element
     */
    dragDrop: function (event) {
      let mouseX = event.clientX - this.selfX;
      let mouseY = event.clientY - this.selfY;
      let source = event.dataTransfer.getData("text");
      let target = this.currentContentItem.getSequenceNumber;
      if (
        this.currentContentItem.level < 4 &&
        mouseX >= this.$el.clientWidth - 100
      ) {
        if (this.canMove("child", event)) {
          this.$emit("move-item", source, target, "child");
        }
      } else if (mouseY < this.$el.clientHeight / 2) {
        if (this.canMove("before", event)) {
          this.$emit("move-item", source, target, "before");
        }
      } else {
        if (this.canMove("after", event)) {
          this.$emit("move-item", source, target, "after");
        }
      }

      this.dragLeave();
    },
  },
};
</script>

<style>
.contentitemeditbox {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem 1px 0.5rem 1px;
}
.contentitemeditbox.contentitemeditbox-invalid {
  border: 2px solid red;
  padding-left: calc(0.5rem - 1px);
  padding-top: calc(0.5rem - 1px);
  padding-bottom: calc(0.5rem - 1px);
}
.contentitemeditbox.level1 {
  margin-left: 0rem;
  padding-right: 5rem;
}
.contentitemeditbox.level2 {
  margin-left: 1.5rem;
  padding-right: 3.5rem;
}
.contentitemeditbox.level3 {
  margin-left: 3rem;
  padding-right: 2rem;
}
.contentitemeditbox.level4 {
  margin-left: 4.5rem;
  padding-right: 0.5rem;
}
.contentitemeditbox.dragging {
  background: white;
  opacity: 0.6;
  border-color: dimgrey;
}
.contentitemeditbox.dragover-top {
  padding-top: calc(0.5rem - 1px);
  border-top: 2px solid #5cb85c;
}
.contentitemeditbox.dragover-bottom {
  padding-bottom: calc(0.5rem - 1px);
  border-bottom: 2px solid #5cb85c;
}
.contentitemeditbox.dragover-right {
  border-right: 2px solid #5cb85c;
}
.contentitemeditbox.dragover-right.level1 {
  padding-right: calc(5rem - 1px);
}
.contentitemeditbox.dragover-right.level2 {
  padding-right: calc(3.5rem - 1px);
}
.contentitemeditbox.dragover-right.level3 {
  padding-right: calc(2rem - 1px);
}
.contentitemeditbox.dragover-right.level4 {
  padding-right: calc(0.5rem - 1px);
}
.contentitemeditbox span.fa-grip-vertical {
  padding: 0.6rem 0.25rem 0 0;
  color: grey;
}
.contentitemeditbox-ctype {
  width: 6rem;
  vertical-align: top;
}
.contentitemeditbox-cnum {
  width: 4.75rem;
  vertical-align: top;
}
.contentitemeditbox-title {
  width: 18rem;
  vertical-align: top;
}
.contentitemeditbox-subtitle {
  width: 12rem;
  vertical-align: top;
}
.contentitemeditbox-pages,
.contentitemeditbox-times {
  width: 9rem;
  vertical-align: top;
}
.contentitemeditbox-pagecount {
  width: 7rem;
  vertical-align: top;
}
.contentitemeditbox-timeduration {
  width: 8rem;
  vertical-align: top;
}
.contentitemeditbox-edititem + .contentitemeditbox-additem,
.contentitemeditbox-additem + .contentitemeditbox-copyitem,
.contentitemeditbox-copyitem + .contentitemeditbox-delitem {
  padding-left: 5px;
}
.contentitemeditbox-additem.disabled {
  color: grey;
}
</style>
