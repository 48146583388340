<template>
  <div
    id="searchformloadmodal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 ref="title" class="modal-title">Load Search Form</h4>
          <div class="close">
            <a
              :href="helpBaseUrl + '/help/search_load'"
              target="help"
              class="loadsearchform_helpbtn"
              title="Help"
            >
              <i class="fa fa-question-circle"></i>
            </a>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div ref="savedSearchFormsList" class="modal-body-inner"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Load search form modal
 */
import { HTTP } from "../../http-common.js";

export default {
  name: "SearchFormLoadModal",

  props: {
    /**
     * The search form name
     */
    formName: {
      type: String,
      required: true,
    },

    /**
     * The help base url
     */
    helpBaseUrl: {
      type: String,
      default: "",
    },
  },

  methods: {
    /**
     * Show the load search form modal
     */
    showModal() {
      // get the saved search forms
      let url =
        this.$baseUrl + "search/get-saved-search-forms/" + this.formName;
      HTTP.get(url).then((response) => {
        this.displaySavedSearchForms(response.data);
      });
    },

    /**
     * Display the saved search forms
     */
    displaySavedSearchForms(savedSearchForms) {
      // load saved search url
      let loadUrl = this.$baseUrl + "search/form/" + this.formName + "?load=";

      // build the html
      let rowHtml =
        '<div class="row"><div class="col-5"><a class="btn btn-link loadsearchform_link" href="%L%T:%N">%N</a></div><div class="col-7">%D</div></div>';
      let html = "";
      let savedSearches = [
        { type: "user", header: "My", list: savedSearchForms.user },
        { type: "shared", header: "Shared", list: savedSearchForms.shared },
      ];
      for (let i = 0; i < 2; i++) {
        if (savedSearches[i].list.length != 0) {
          html +=
            '<div class="row"><div class="col-12"><b>' +
            savedSearches[i].header +
            " Searches</b></div></div>";
          for (let j = 0; j < savedSearches[i].list.length; j++) {
            html += rowHtml
              .replace(/%N/g, this.escapeHtml(savedSearches[i].list[j].name))
              .replace(/%D/g, this.escapeHtml(savedSearches[i].list[j].descr))
              .replace(/%T/g, savedSearches[i].type)
              .replace(/%L/g, loadUrl);
          } // for
        } // for
      }
      if (html.length == 0) {
        html = "You currently have no saved searches";
      }
      this.$refs.savedSearchFormsList.innerHTML = html;

      // show the modal
      window.$("#searchformloadmodal").modal("show");
    },

    /**
     * Escape html
     */
    escapeHtml(html) {
      return html
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#39;");
    },
  },
};
</script>

<style>
.loadsearchform_helpbtn {
  margin-right: 0.5em;
}
a.btn.loadsearchform_link {
  padding: 0.25em 0.75rem;
}
</style>
