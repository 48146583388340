var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"productCardMarketRepresentation"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('a',{attrs:{"id":"market-representation"}}),_c('h5',{staticClass:"d-inline-block"},[_vm._v("Market Representation")]),(_vm.$permitted('biblio/edit') && _vm.allowEdit)?_c('edit-icon',{staticClass:"ml-2 d-print-none",attrs:{"link":_vm.$baseUrl +
        '/biblio-edit/edit-market/' +
        _vm.productId +
        (_vm.returnTo ? '?return=' + _vm.returnTo : '')}}):_vm._e(),_c('product-quick-jump-dropdown',{staticClass:"float-right",attrs:{"product-id":_vm.productId,"display":_vm.display,"has-custom-fields":_vm.hasCustomFields}})]},proxy:true}])},[_c('dl',{staticClass:"row"},[(_vm.marketRepresentatives && _vm.marketRepresentatives.length > 0)?[_vm._l((_vm.marketRepresentatives),function(representative,index){return [_c('dt',{key:'representative-dt-' + index,staticClass:"col-sm-3 mt-2 font-weight-bold text-secondary"},[_vm._v(" Market Representation ")]),_c('dd',{key:'representative-dd-' + index,staticClass:"col-sm-9 mt-2"}),_c('dt',{key:'representative-dt2-' + index,staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Countries ")]),_c('dd',{key:'representative-dd2-' + index,staticClass:"col-sm-9"},[(
              !representative.getRightsCountries &&
              !representative.getNoRightsCountries
            )?[_vm._v("Worldwide")]:_vm._e(),(representative.getRightsCountries)?_vm._l((representative.getRightsCountriesNames),function(country,countryIndex){return _c('span',{key:countryIndex},[_vm._v(_vm._s(country)),(
                  countryIndex < representative.getRightsCountries.length - 1
                )?[_vm._v(", ")]:_vm._e()],2)}):_vm._e(),(representative.getNoRightsCountries)?[_vm._v("Worldwide excluding "),_vm._l((representative.getNoRightsCountriesNames),function(country,countryIndex){return _c('span',{key:countryIndex},[_vm._v(_vm._s(country)),(
                  countryIndex <
                  representative.getNoRightsCountriesNames.length - 1
                )?[_vm._v(", ")]:_vm._e()],2)})]:_vm._e()],2),(representative.getName)?[_c('dt',{key:'representative-dt3-' + index,staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" "+_vm._s(representative.getTypeName ? representative.getTypeName : "Sales Agent/Local Publisher Name")+" ")]),_c('dd',{key:'representative-dd3-' + index,staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(representative.getName)+" ")])]:_vm._e(),(representative.getStatusName)?[_c('dt',{key:'representative-dt4-' + index,staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Local Publishing Status ")]),_c('dd',{key:'representative-dd4-' + index,staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(representative.getStatusName)+" ")])]:_vm._e(),(representative.getPublicationDate)?[_c('dt',{key:'representative-dt5-' + index,staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Local Publication Date ")]),_c('dd',{key:'representative-dd5-' + index,staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm._f("parseDate")(representative.getPublicationDate,_vm.dateOptions.format, _vm.dateOptions))+" ")])]:_vm._e()]})]:(_vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 mt-2 font-weight-bold text-secondary"},[_vm._v(" Market Representation ")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }