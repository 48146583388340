<template>
  <div>
    <div class="row">
      <div class="col-6">
        <label>Recipient:</label>
        <v-select
          v-model="selectedRecipient"
          label="name"
          :options="listRecipients"
          :selectable="(option) => !option.group"
          placeholder="Start typing to search for a feed recipient"
          jest-feed-selector
          @input="recipientSelected"
        >
          <template #option="recipient">
            <div :class="recipientListOptionClass(recipient)">
              {{ recipient.name }}
            </div>
          </template>
          <template #selected-option="recipient">
            <div :class="recipientListOptionClass(recipient)">
              {{ recipient.name }}
            </div>
          </template>
          <template v-slot:no-options="{ searching }">
            <em v-if="searching">No feed recipient found</em>
            <em v-else>Start typing to search for a feed recipient</em>
          </template>
        </v-select>
      </div>
      <div class="col-3">
        <v-select
          v-model="listCatgory"
          :options="listCatgoryOptions"
          :searchable="false"
          :clearable="false"
          @input="rebuildRecipientsList"
        >
        </v-select>
      </div>
      <div v-if="canNavigate" class="col-2">
        <a href="#" class="btn btn-link" @click.prevent="navPrev">
          <i class="fa fa-chevron-left"></i>
        </a>
        <a href="#" class="btn btn-link" @click.prevent="navNext">
          <i class="fa fa-chevron-right"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <b-tabs
          v-if="editRecipients.length != 0"
          v-model="currentEditRecipient"
        >
          <b-tab
            v-for="recipient in editRecipients"
            :key="recipient.id"
            :jest-feed="recipient.id"
          >
            <template #title>
              <span
                :style="{
                  'font-style': recipient.fixed ? '' : 'italic',
                }"
                @dblclick.prevent="recipient.fixed = true"
              >
                <span v-if="recipient.name.length > 15" :title="recipient.name">
                  {{ recipient.name.substring(0, 15) }} &hellip;
                </span>
                <span v-else>
                  {{ recipient.name }}
                </span>
              </span>
              &emsp;
              <a
                href="#"
                class="tab-header-close-btn"
                @click.prevent="closeRecipient(recipient.id, true)"
              >
                &times;
              </a>
            </template>
            <dissemination-settings-feed
              :feed-recipient-id="recipient.id"
              :feed-recipient-name="recipient.name"
              :feed-recipient-group="recipient.groupName"
              :data-formats="dataFormats"
              :return-condition-types="returnConditionTypes"
              :languages="languages"
              :countries="countries"
              :formats="formats"
              :currencies="currencies"
              :price-types="priceTypes"
              :discount-schemes="discountSchemes"
              :global-tags="globalTags"
              :default-tab="recipient.currentTab"
              :read-only="readOnly"
              @name-change="
                (feedId, feedName) => {
                  feedNameChange(feedId, feedName);
                }
              "
              @change="
                recipient.fixed = true;
                recipient.modified = true;
                reportModified();
              "
              @save="
                recipient.modified = false;
                reportModified();
              "
              @close="closeRecipient(recipient.id, false)"
              @tab-change="
                (tabIndex) => {
                  recipient.currentTab = tabIndex;
                }
              "
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings feeds component
 */
import DisseminationSettingsFeed from "./Feed.vue";
import vSelect from "vue-select";
import { HTTP } from "../../../http-common.js";

export default {
  name: "DisseminationSettingsFeeds",

  components: {
    "dissemination-settings-feed": DisseminationSettingsFeed,
    "v-select": vSelect,
  },

  props: {
    /**
     * List of all feed recipient groups
     */
    recipientGroups: {
      type: Array,
      required: true,
    },

    /**
     * Data formats
     */
    dataFormats: {
      type: Object,
      default: null,
    },

    /**
     * List of all return condition types
     */
    returnConditionTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all languages
     */
    languages: {
      type: Array,
      required: true,
    },

    /**
     * List of all countries
     */
    countries: {
      type: Array,
      required: true,
    },

    /**
     * List of all formats
     */
    formats: {
      type: Array,
      required: true,
    },

    /**
     * List of all currencies
     */
    currencies: {
      type: Array,
      required: true,
    },

    /**
     * List of all price types
     */
    priceTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all discount schemes
     */
    discountSchemes: {
      type: Array,
      required: true,
    },

    /**
     * List of all global tags
     */
    globalTags: {
      type: Array,
      required: true,
    },

    /**
     * Read-only
     */
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    let listCatgoryOptions = [
      { value: "", label: "Listed feeds" },
      { value: "live", label: "Live feeds" },
      { value: "live-auto", label: "Live automatic feeds" },
      { value: "live-man", label: "Live manual-only feeds" },
      { value: "wait", label: "Waiting/testing feeds" },
      { value: "unlisted", label: "Unlisted feeds" },
    ];

    return {
      allRecipients: [],
      listRecipients: [],
      listCatgoryOptions: listCatgoryOptions,
      listCatgory: listCatgoryOptions[0],
      selectedRecipient: null,
      editRecipients: [],
      currentEditRecipient: 0,
    };
  },

  computed: {
    canNavigate: function () {
      if (this.editRecipients.length != 0) {
        return !this.editRecipients[this.currentEditRecipient].fixed;
      }
      return false;
    },
  },

  mounted: function () {
    this.getRecipients();
  },

  methods: {
    /**
     * Get recipients
     */
    getRecipients: function () {
      let url =
        this.$baseUrl + "customisation/dissemination-settings/recipients";

      HTTP.get(url)
        .then((response) => {
          let lastGroup = 0;
          let lastGroupName = null;
          for (const recipient of response.data) {
            // check for a change in group
            if (recipient.recipientgroup != lastGroup) {
              // find this group
              for (const recipientGroup of this.recipientGroups) {
                if (recipientGroup.id == recipient.recipientgroup) {
                  let newGroup = {
                    id: -recipientGroup.id,
                    group: true,
                    name: recipientGroup.name,
                  };
                  this.allRecipients.push(newGroup);
                  this.listRecipients.push(newGroup);
                  lastGroup = recipientGroup.id;
                  lastGroupName = recipientGroup.name;
                  break;
                }
              } // for
            }

            // add entry for this recipient
            let newRecipient = {
              id: recipient.id,
              group: false,
              name: recipient.name,
              groupName: lastGroupName,
              active: recipient.active,
              enabled: recipient.enabled,
              enabledReason: recipient.enabledReason,
              enabledAuto: recipient.enabledAuto,
            };
            if (recipient.active) {
              if (
                recipient.enabled &&
                recipient.enabledReason.search(/^live/) == 0
              ) {
                newRecipient.cat = recipient.enabledAuto
                  ? "live-auto"
                  : "live-man";
              } else if (
                recipient.enabledReason.search(
                  /^waitingdetails|testing|failingfeed$/
                ) == 0
              ) {
                newRecipient.cat = "wait";
              } else {
                newRecipient.cat = "";
              }
              this.allRecipients.push(newRecipient);
              this.listRecipients.push(newRecipient);
            } else {
              newRecipient.cat = "unlisted";
              this.allRecipients.push(newRecipient);
            }
          } // for

          this.$emit("recipients", this.allRecipients);
        })
        .catch(() => {
          this.$bvModal.msgBoxOk(
            "Unable to retrieve a list of dissemination data recipients"
          );
        });
    },

    /**
     * Recipients list option classes
     */
    recipientListOptionClass(recipient) {
      if (recipient.group) {
        return "recipient-list-groupitem";
      }

      let classes = "recipient-list-item";
      switch (recipient.cat) {
        case "":
          break;
        case "live-man":
          classes += " recipient-list-item-live";
          break;
        default:
          classes += " recipient-list-item-" + recipient.cat;
          break;
      }
      return classes;
    },

    /**
     * Rebuild recipients list based on the list filter type
     */
    rebuildRecipientsList: function () {
      // rebuild the listRecipients list with all recipients valid for the list category
      this.listRecipients = [];
      let groupToAdd = null;

      for (const recipient of this.allRecipients) {
        if (recipient.group) {
          groupToAdd = recipient;
        } else {
          let useRecipient = false;
          switch (this.listCatgory.value) {
            case "": // all feeds except unlisted
              useRecipient = recipient.cat != "unlisted";
              break;
            case "live":
              useRecipient =
                recipient.cat == "live-auto" || recipient.cat == "live-man";
              break;
            default:
              useRecipient = recipient.cat == this.listCatgory.value;
              break;
          } // switch
          if (useRecipient) {
            if (groupToAdd) {
              this.listRecipients.push(groupToAdd);
              groupToAdd = null;
            }
            this.listRecipients.push(recipient);
          }
        }
      } // for
      this.$emit("recipients", this.allRecipients);
    },

    /**
     * Recipient selected
     */
    recipientSelected: function (recipient, keepCurrentTab = false) {
      if (recipient) {
        for (let i = 0; i < this.editRecipients.length; i++) {
          if (this.editRecipients[i].id == recipient.id) {
            this.currentEditRecipient = i;
            this.selectedRecipient = null;
            return;
          }
        }

        if (
          this.currentEditRecipient < this.editRecipients.length &&
          !this.editRecipients[this.currentEditRecipient].fixed
        ) {
          let currentEditRecipient = this.currentEditRecipient;
          let currentTab = keepCurrentTab
            ? this.editRecipients[currentEditRecipient].currentTab
            : 0;
          this.editRecipients.splice(currentEditRecipient, 1, {
            ...recipient,
            fixed: false,
            modified: false,
            currentTab: currentTab,
          });
          setTimeout(() => {
            this.selectedRecipient = null;
            this.currentEditRecipient = currentEditRecipient;
          }, 100);
        } else {
          this.editRecipients.push({
            ...recipient,
            fixed: false,
            modified: false,
            currentTab: 0,
          });
          setTimeout(() => {
            this.selectedRecipient = null;
            this.currentEditRecipient = this.editRecipients.length - 1;
          }, 100);
        }
        this.reportModified();
      }
    },

    /**
     * Close recipient
     */
    closeRecipient: function (recipientId, prompt) {
      for (let i = 0; i < this.editRecipients.length; i++) {
        if (this.editRecipients[i].id == recipientId) {
          if (prompt && this.editRecipients[i].modified) {
            this.$bvModal
              .msgBoxConfirm(
                "This feed has unsaved changes. Are you sure you want to close it discarding all you changes?"
              )
              .then((which) => {
                if (which) {
                  this.closeRecipient(recipientId, false);
                }
              });
            break;
          }

          this.editRecipients.splice(i, 1);
          if (this.currentEditRecipient >= this.editRecipients.length) {
            this.currentEditRecipient = Math.max(
              this.editRecipients.length - 1,
              0
            );
          }
          break;
        }
      }
      this.reportModified();
    },

    /** recipient's name has changed */
    feedNameChange: function (feedId, feedName) {
      for (let i = 0; i < this.allRecipients.length; i++) {
        if (this.allRecipients[i].id == feedId) {
          this.allRecipients[i].name = feedName;
          break;
        }
      }
      for (let i = 0; i < this.editRecipients.length; i++) {
        if (this.editRecipients[i].id == feedId) {
          this.editRecipients[i].name = feedName;
          break;
        }
      }
      this.$emit("recipients", this.allRecipients);
    },

    /**
     * Report if there are any modified feeds
     */
    reportModified: function () {
      for (let i = 0; i < this.editRecipients.length; i++) {
        if (this.editRecipients[i].modified) {
          this.$emit("state-change", true);
          return;
        }
      } // for
      this.$emit("state-change", false);
    },

    /**
     * Navigate to previous feed
     */
    navPrev: function () {
      if (this.editRecipients.length != 0) {
        if (!this.editRecipients[this.currentEditRecipient].fixed) {
          // find the current recipient in the list
          let currentId = this.editRecipients[this.currentEditRecipient].id;
          for (let i = 0; i < this.listRecipients.length; i++) {
            if (this.listRecipients[i].id == currentId) {
              // select the previous non-group recipient
              let selIndex = i;
              for (;;) {
                if (selIndex > 0) {
                  selIndex--;
                } else {
                  selIndex = this.listRecipients.length - 1;
                }
                if (!this.listRecipients[selIndex].group) {
                  this.recipientSelected(this.listRecipients[selIndex], true);
                  break;
                }
              } // for
              break;
            }
          } // for
        }
      }
    },

    /**
     * Navigate to next feed
     */
    navNext: function () {
      if (this.editRecipients.length != 0) {
        if (!this.editRecipients[this.currentEditRecipient].fixed) {
          // find the current recipient in the list
          let currentId = this.editRecipients[this.currentEditRecipient].id;
          for (let i = 0; i < this.listRecipients.length; i++) {
            if (this.listRecipients[i].id == currentId) {
              // select the next non-group recipient
              let selIndex = i;
              for (;;) {
                if (selIndex < this.listRecipients.length - 1) {
                  selIndex++;
                } else {
                  selIndex = 0;
                }
                if (!this.listRecipients[selIndex].group) {
                  this.recipientSelected(this.listRecipients[selIndex], true);
                  break;
                }
              } // for
              break;
            }
          } // for
        }
      }
    },
  },
};
</script>

<style scoped>
.v-select {
  margin-left: 1rem;
  width: 85%;
  display: inline-block;
}
.recipient-list-groupitem {
  font-weight: bold;
  font-style: italic;
  color: #000;
}
.recipient-list-item {
  color: #808080;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
div.recipient-list-item {
  margin-left: 0.5rem;
}
.recipient-list-item-live {
  color: #000;
  background-color: #fffacd;
}
.recipient-list-item-live-auto {
  color: #000;
  background-color: #cbf7c7;
}
.recipient-list-item-wait {
  color: #000;
  background-color: #ffe4e1;
}
.recipient-list-item-unlisted {
  font-style: italic;
}
.vs__dropdown-option--highlight .recipient-list-item {
  color: #fff;
  background-color: #5897fb;
}
.vs__dropdown-option--highlight .recipient-list-item-live {
  color: #fffacd;
}
.vs__dropdown-option--highlight .recipient-list-item-live-auto {
  color: #cbf7c7;
}
.vs__dropdown-option--highlight .recipient-list-item-wait {
  color: #ffe4e1;
}
.vs__selected div.recipient-list-item {
  margin-left: 0px;
}
.btn-padleft {
  margin-left: 1rem;
}
.tab-header-close-btn {
  font-size: 125%;
  line-height: 100%;
}
</style>
