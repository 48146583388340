<template>
  <div>
    <div v-if="loadingDissemination == true">
      <loading-spinner
        text="Loading dissemination errors & warnings..."
      ></loading-spinner>
    </div>
    <div v-else>
      <b-card
        v-if="disseminationErrors && disseminationErrors.length > 0"
        border-variant="danger"
        header-border-variant="danger"
      >
        <h5 class="text-danger">
          This record has the following errors for the following data recipients
        </h5>
        <dl class="row">
          <template v-for="(recipient, index) in disseminationErrors">
            <dt
              :key="'error-dt-' + index"
              class="col-sm-4 font-weight-bold text-secondary"
            >
              {{ recipient.name }}
            </dt>
            <dd :key="'error-dd-' + index" class="col-sm-8">
              <div
                v-for="(error, errorIndex) in recipient.errors"
                :key="errorIndex"
              >
                {{ error }}
              </div>
            </dd>
          </template>
        </dl>
      </b-card>
      <b-card
        v-if="disseminationWarnings && disseminationWarnings.length > 0"
        border-variant="warning"
      >
        <h5 class="text-warning">
          This record has the following warnings for the following data
          recipients
        </h5>
        <dl class="row">
          <template v-for="(recipient, index) in disseminationWarnings">
            <dt
              :key="'warning-dt-' + index"
              class="col-sm-4 font-weight-bold text-secondary"
            >
              {{ recipient.name }}
            </dt>
            <dd :key="'warning-dd-' + index" class="col-sm-8">
              <div
                v-for="(warning, warningIndex) in recipient.warnings"
                :key="warningIndex"
              >
                {{ warning }}
              </div>
            </dd>
          </template>
        </dl>
      </b-card>
    </div>
    <div class="mt-2">
      <info-box>
        For BIC Basic and BISG accreditation, various checks are performed to
        ensure that data which is required by these standards has been
        specified. Additionally, records must have been sent to data recipients
        sufficiently in advance of publication. The dates below show the
        expected date for which the record should be recieved by BIC and BISG.
      </info-box>
      <dl class="row">
        <dt class="col-sm-2 font-weight-bold text-secondary">BIC Basic</dt>
        <dd class="col-sm-10"></dd>
        <dt class="col-sm-2 text-secondary">
          <span class="pl-2">Requirements</span>
        </dt>
        <dd class="col-sm-10">{{ bicAccreditationText }}</dd>
        <dt class="col-sm-2 text-secondary">
          <span class="pl-2">Expected Date</span>
        </dt>
        <dd class="col-sm-10">
          {{ bicSendBy | parseDate(dateOptions.format, dateOptions) }}
        </dd>

        <dt class="col-sm-2 font-weight-bold text-secondary">BISG</dt>
        <dd class="col-sm-10"></dd>
        <dt class="col-sm-2 text-secondary">
          <span class="pl-2">Requirements</span>
        </dt>
        <dd class="col-sm-10">{{ bisgAccreditationText }}</dd>
        <dt class="col-sm-2 text-secondary">
          <span class="pl-2">Expected Date</span>
        </dt>
        <dd class="col-sm-10">
          {{ bisgSendBy | parseDate(dateOptions.format, dateOptions) }}
        </dd>
      </dl>
      <div v-if="pubDate">
        <base-table
          class="mt-4"
          :filters-enabled="false"
          :columns="accreditationColumns"
          :initial-data="accreditationStats"
          :rows-per-page="50"
        >
          <template #no-data-display></template>
          <template v-slot:first_sent="{ record }">
            <template v-if="record.first_sent">{{
              record.first_sent | parseDate(dateOptions.format, dateOptions)
            }}</template
            ><template v-else>Never</template>
          </template>
          <template v-slot:bic="{ record }">
            <span
              :class="{
                'text-danger': !checkAccreditationDate(
                  record.first_sent,
                  'bic'
                ),
                'text-success': checkAccreditationDate(
                  record.first_sent,
                  'bic'
                ),
              }"
              >{{
                bicSendBy | parseDate(dateOptions.format, dateOptions)
              }}</span
            >
            <i
              v-if="checkAccreditationDate(record.first_sent, 'bic')"
              class="ml-2 text-success fa fa-check"
            ></i>
            <i v-else class="ml-2 text-danger fa fa-times"></i>
          </template>
          <template v-slot:bisg="{ record }">
            <span
              :class="{
                'text-danger': !checkAccreditationDate(
                  record.first_sent,
                  'bisg'
                ),
                'text-success': checkAccreditationDate(
                  record.first_sent,
                  'bisg'
                ),
              }"
              >{{
                bisgSendBy | parseDate(dateOptions.format, dateOptions)
              }}</span
            >
            <i
              v-if="checkAccreditationDate(record.first_sent, 'bisg')"
              class="ml-2 text-success fa fa-check"
            ></i>
            <i v-else class="ml-2 text-danger fa fa-times"></i>
          </template>
        </base-table>
      </div>
      <div v-else>
        <p>
          This record does not yet have a publication date so no details about
          whether it was sent sufficiently in advance of publication can be
          displayed. BIC Basic requires that this record is sent at least 16
          weeks prior to publication. BISG requires that this record is sent at
          least 180 days prior to publication.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays dissemination warnings and errors as well as a
 * list of recipients with their BIC and BISG accreditation info
 */
import { HTTP } from "../../http-common.js";
import xml2js from "xml2js";
import { DateTime, Duration } from "luxon";
import ParseDate from "../../mixins/parseDate.js";
import InfoBox from "../ui/InfoBox.vue";

export default {
  name: "BiblioDetailStatistics",

  components: {
    "info-box": InfoBox,
  },

  mixins: [ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      required: true,
    },

    /**
     * The current user logon ID
     *
     * This is required for requests to the media manager API
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * List of recipients with time that the product was
     * first sent to the recipient
     */
    accreditationStats: {
      type: Array,
      default: null,
    },

    /**
     * The products publish date
     * yyyy-mm-dd
     */
    pubDate: {
      type: String,
      default: null,
    },

    /**
     * Flag to set whether the data should load
     * when the component is mounted. This can be
     * disabled and the components load methods can
     * instead be called manually. This can be useful
     * if the component is used inside a tab, if the data
     * should not load until the tab is displayed.
     */
    loadOnMount: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      loadingDissemination: false,
      loadingAccreditation: false,
      disseminationData: null,
      accreditationData: null,
    };
  },

  computed: {
    accreditationColumns: function () {
      let cols = [
        { text: "Recipient", key: "recipient" },
        { text: "First sent", key: "first_sent", type: "date" },
      ];
      if (this.pubDate !== null) {
        cols = [
          ...cols,
          { text: "Expected by (BIC Basic)", key: "bic", type: "date" },
          { text: "Expected by (BISG)", key: "bisg", type: "date" },
        ];
      }
      return cols;
    },
    disseminationUrl: function () {
      return (
        "/scripts/" +
        this.$appId +
        "/extsupply/check/" +
        this.productId +
        "?L=" +
        this.logonId
      );
    },
    accreditationUrl: function () {
      return (
        "/scripts/" +
        this.$appId +
        "/extsupply/checkspecial/" +
        this.productId +
        "?L=" +
        this.logonId
      );
    },
    disseminationErrors: function () {
      let errors = [];
      if (
        !this.disseminationData ||
        !this.disseminationData.recordchecks.aggregator
      ) {
        return errors;
      }
      for (let recipient of this.disseminationData.recordchecks.aggregator) {
        if (recipient.error) {
          errors.push({ name: recipient.attr.name, errors: recipient.error });
        }
      }
      return errors;
    },
    disseminationWarnings: function () {
      let warnings = [];
      if (
        !this.disseminationData ||
        !this.disseminationData.recordchecks.aggregator
      ) {
        return warnings;
      }
      for (let recipient of this.disseminationData.recordchecks.aggregator) {
        if (recipient.warning) {
          warnings.push({
            name: recipient.attr.name,
            warnings: recipient.warning,
          });
        }
      }
      return warnings;
    },
    bicAccreditationText: function () {
      let errorText = "";
      if (
        !this.accreditationData ||
        !this.accreditationData.recordchecks.aggregator
      ) {
        return "-";
      }
      for (let recipient of this.accreditationData.recordchecks.aggregator) {
        if (recipient.attr.id == "bicbasic" && recipient.error) {
          for (const [i, error] of recipient.error.entries()) {
            errorText += error;
            if (i !== recipient.error.length - 1) {
              errorText += ", ";
            }
          }
        }
      }
      if (!errorText) {
        return "This record meets these requirements";
      }
      return errorText;
    },
    bisgAccreditationText: function () {
      let errorText = "";
      if (
        !this.accreditationData ||
        !this.accreditationData.recordchecks.aggregator
      ) {
        return "-";
      }
      for (let recipient of this.accreditationData.recordchecks.aggregator) {
        if (recipient.attr.id == "bisg" && recipient.error) {
          for (const [i, error] of recipient.error.entries()) {
            errorText += error;
            if (i !== recipient.error.length - 1) {
              errorText += ", ";
            }
          }
        }
      }
      if (!errorText) {
        return "This record meets these requirements";
      }
      return errorText;
    },
    bicSendBy: function () {
      if (this.pubDate == null) return null;
      let date = DateTime.fromISO(this.pubDate);
      let duration = Duration.fromObject({ weeks: 16 });
      return date.minus(duration).toISODate();
    },
    bisgSendBy: function () {
      if (this.pubDate == null) return null;
      let date = DateTime.fromISO(this.pubDate);
      let duration = Duration.fromObject({ days: 180 });
      return date.minus(duration).toISODate();
    },
  },

  mounted: function () {
    if (this.loadOnMount) {
      this.loadData();
    }
  },

  methods: {
    loadData: function () {
      // load the dissemination data if not already loaded
      if (this.disseminationData == null) {
        this.loadingDissemination = true;
        this.getStats(this.disseminationUrl).then((response) => {
          this.disseminationData = response;
          this.loadingDissemination = false;
        });
      }

      // load the accreditation data if not already loaded
      if (this.accreditationData == null) {
        this.loadingAccreditation = true;
        this.getStats(this.accreditationUrl).then((response) => {
          this.accreditationData = response;
          this.loadingAccreditation = false;
        });
      }
    },
    /**
     * Retrieves the dissemination errors and warnings and
     * converts the returned XML to an object
     */
    getStats: async function (url) {
      try {
        const xmlParser = new xml2js.Parser({ attrkey: "attr" });
        const response = await HTTP.get(url);
        let parsedXml = "";
        xmlParser.parseString(response.data, (err, result) => {
          parsedXml = result;
        });
        return parsedXml;
      } catch (error) {
        return error;
      }
    },

    /**
     * Checks to see if a provided date is before the
     * accreditation date
     *
     * @param {string} date An ISO formatted date string
     * @param {string} accreditor bic or bisg
     */
    checkAccreditationDate: function (date, accreditor) {
      let checkDate = DateTime.fromISO(date);
      let compareDate = DateTime.fromISO(
        accreditor == "bic" ? this.bicSendBy : this.bisgSendBy
      );
      if (checkDate <= compareDate) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style></style>
