<template>
  <b-card id="productCardBibliographic">
    <template #header>
      <a id="bibliographic" />
      <h5 class="d-inline-block">Bibliographic</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-biblio/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <dl v-if="product" class="row">
      <template
        v-if="
          (product.getLanguages && product.getLanguages.getText) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Languages of Text
        </dt>
        <dd class="col-sm-9">
          <template v-if="product.getLanguages && product.getLanguages.getText">
            <div
              v-for="(text, index) in product.getLanguages.getText"
              :key="index"
              class="d-inline-block"
            >
              {{ text.getLanguageName
              }}<span v-if="text.getCountryName">
                ({{ text.getCountryName }})</span
              >
              <span v-if="index < product.getLanguages.getText.length - 1"
                >,&nbsp;
              </span>
            </div>
          </template>
          <template v-else>—</template>
        </dd>
      </template>
      <template
        v-if="
          (product.getLanguages &&
            product.getLanguages.getOriginalOfATranslatedText) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Original Text Languages
        </dt>
        <dd class="col-sm-9">
          <template
            v-if="
              product.getLanguages &&
              product.getLanguages.getOriginalOfATranslatedText
            "
          >
            <div
              v-for="(text, index) in product.getLanguages
                .getOriginalOfATranslatedText"
              :key="index"
              class="d-inline-block"
            >
              {{ text.getLanguageName
              }}<span v-if="text.getCountryName">
                ({{ text.getCountryName }})</span
              >
              <span
                v-if="
                  index !=
                  product.getLanguages.getOriginalOfATranslatedText.length - 1
                "
                >,&nbsp;
              </span>
            </div>
          </template>
          <template v-else>—</template>
        </dd>
      </template>
      <template v-if="product.getEditionTypesNames || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">Edition Types</dt>
        <dd class="col-sm-9">
          <template v-if="product.getEditionTypesNames">
            <div
              v-for="(edition, index) in product.getEditionTypesNames"
              :key="index"
              class="d-inline-block"
            >
              {{ edition
              }}<span v-if="index != product.getEditionTypesNames.length - 1"
                >;&nbsp;
              </span>
            </div></template
          ><template v-else>—</template>
        </dd></template
      >
      <template v-if="product.getEditionNumber || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">Edition Number</dt>
        <dd class="col-sm-9">
          <template v-if="product.getEditionNumber">{{
            product.getEditionNumber
          }}</template
          ><template v-else>—</template>
        </dd></template
      >
      <template v-if="product.getEditionRevisionNumber || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Edition Revision Number
        </dt>
        <dd class="col-sm-9">
          <template v-if="product.getEditionRevisionNumber">{{
            product.getEditionRevisionNumber
          }}</template
          ><template v-else>—</template>
        </dd></template
      >
      <template v-if="product.getEditionStatement || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Edition Statement
        </dt>
        <dd class="col-sm-9">
          <template v-if="product.getEditionStatement">{{
            product.getEditionStatement
          }}</template
          ><template v-else>—</template>
        </dd></template
      >
      <template v-if="product.getNoEdition == true">
        <dt class="col-sm-3 font-weight-bold text-secondary">No Edition</dt>
        <dd class="col-sm-9">Yes</dd></template
      >
      <template
        v-if="
          (product.getIllustrations &&
            product.getIllustrations.getType &&
            product.getIllustrations.getType.length > 0) ||
          (product.getIllustrations &&
            product.getIllustrations.getTotalCount > 0) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">Illustrations</dt>
        <dd class="col-sm-9">
          <template
            v-if="
              product.getIllustrations &&
              product.getIllustrations.getTotalCount > 0
            "
            >{{
              product.getIllustrations.getTotalCount
            }}
            Illustrations;&nbsp;</template
          >
          <template
            v-if="
              product.getIllustrations.getType &&
              product.getIllustrations.getType.length > 0
            "
          >
            <div
              v-for="(illustration, index) in product.getIllustrations.getType"
              :key="index"
              class="d-inline-block"
            >
              {{ illustration.getValue }}
              {{ illustration.getTypeName
              }}<span
                v-if="index != product.getIllustrations.getType.length - 1"
                >;&nbsp;</span
              >
            </div></template
          >
          <template
            v-if="
              (!product.getIllustrations.getType ||
                product.getIllustrations.getType.length == 0) &&
              !product.getIllustrations.getTotalCount &&
              showAllFields
            "
            >—</template
          >
        </dd></template
      >
      <template
        v-if="
          (product.getIllustrations && product.getIllustrations.getNote) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Illustration Note
        </dt>
        <dd class="col-sm-9">
          <template
            v-if="product.getIllustrations && product.getIllustrations.getNote"
            >{{ product.getIllustrations.getNote }}</template
          ><template v-else>—</template>
        </dd></template
      >
      <template v-if="product.getPublishingStatusName || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Publishing Status
        </dt>
        <dd class="col-sm-9">
          <template v-if="product.getPublishingStatusName">{{
            product.getPublishingStatusName
          }}</template
          ><template v-else>—</template>
        </dd></template
      >
      <template v-if="product.getPublishingStatusNote || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Publishing Status Note
        </dt>
        <dd class="col-sm-9">
          <template v-if="product.getPublishingStatusNote">{{
            product.getPublishingStatusNote
          }}</template
          ><template v-else>—</template>
        </dd></template
      >
      <template v-if="product.getPublishingDates || showAllFields">
        <template
          v-if="product.getPublishingDates.getPublicationDate || showAllFields"
        >
          <dt class="col-sm-3 font-weight-bold text-secondary">
            Publication Date
          </dt>
          <dd class="col-sm-9">
            <template v-if="product.getPublishingDates.getPublicationDate">{{
              product.getPublishingDates.getPublicationDate
                | parseDate(dateOptions.format, dateOptions)
            }}</template>
            <template v-else>—</template>
          </dd>
        </template>

        <template
          v-if="
            product.getPublishingDates.getPublicAnnouncementDate ||
            showAllFields
          "
        >
          <dt class="col-sm-3 font-weight-bold text-secondary">
            Public Announcement Date
          </dt>
          <dd class="col-sm-9">
            <template
              v-if="product.getPublishingDates.getPublicAnnouncementDate"
              >{{
                product.getPublishingDates.getPublicAnnouncementDate
                  | parseDate(dateOptions.format, dateOptions)
              }}</template
            >
            <template v-else>—</template>
          </dd>
        </template>

        <template
          v-if="
            product.getPublishingDates.getTradeAnnouncementDate || showAllFields
          "
        >
          <dt class="col-sm-3 font-weight-bold text-secondary">
            Trade Announcement Date
          </dt>
          <dd class="col-sm-9">
            <template
              v-if="product.getPublishingDates.getTradeAnnouncementDate"
              >{{
                product.getPublishingDates.getTradeAnnouncementDate
                  | parseDate(dateOptions.format, dateOptions)
              }}</template
            >
            <template v-else>—</template>
          </dd>
        </template>

        <template
          v-if="
            product.getPublishingDates.getFirstPublishedDate || showAllFields
          "
        >
          <dt class="col-sm-3 font-weight-bold text-secondary">
            First Published Date
          </dt>
          <dd class="col-sm-9">
            <template v-if="product.getPublishingDates.getFirstPublishedDate">{{
              product.getPublishingDates.getFirstPublishedDate
                | parseDate(dateOptions.format, dateOptions)
            }}</template>
            <template v-else>—</template>
          </dd>
        </template>

        <template
          v-if="
            product.getPublishingDates
              .getFirstPublishedInOriginalLanguageDate || showAllFields
          "
        >
          <dt class="col-sm-3 font-weight-bold text-secondary">
            First Published in Original Language Date
          </dt>
          <dd class="col-sm-9">
            <template
              v-if="
                product.getPublishingDates
                  .getFirstPublishedInOriginalLanguageDate
              "
              >{{
                product.getPublishingDates
                  .getFirstPublishedInOriginalLanguageDate
                  | parseDate(dateOptions.format, dateOptions)
              }}</template
            >
            <template v-else>—</template>
          </dd>
        </template>

        <template
          v-if="product.getPublishingDates.getLastReprintDate || showAllFields"
        >
          <dt class="col-sm-3 font-weight-bold text-secondary">
            Last Reprint Date
          </dt>
          <dd class="col-sm-9">
            <template v-if="product.getPublishingDates.getLastReprintDate">{{
              product.getPublishingDates.getLastReprintDate
                | parseDate(dateOptions.format, dateOptions)
            }}</template>
            <template v-else>—</template>
          </dd>
        </template>

        <template
          v-if="product.getPublishingDates.getLastReissueDate || showAllFields"
        >
          <dt class="col-sm-3 font-weight-bold text-secondary">
            Last Reissue Date
          </dt>
          <dd class="col-sm-9">
            <template v-if="product.getPublishingDates.getLastReissueDate">{{
              product.getPublishingDates.getLastReissueDate
                | parseDate(dateOptions.format, dateOptions)
            }}</template>
            <template v-else>—</template>
          </dd>
        </template>

        <template
          v-if="product.getPublishingDates.getOutOfPrintDate || showAllFields"
        >
          <dt class="col-sm-3 font-weight-bold text-secondary">
            Out of Print Date
          </dt>
          <dd class="col-sm-9">
            <template v-if="product.getPublishingDates.getOutOfPrintDate">{{
              product.getPublishingDates.getOutOfPrintDate
                | parseDate(dateOptions.format, dateOptions)
            }}</template>
            <template v-else>—</template>
          </dd>
        </template>

        <template
          v-if="product.getPublishingDates.getTransferDate || showAllFields"
        >
          <dt class="col-sm-3 font-weight-bold text-secondary">
            Transfer Date
          </dt>
          <dd class="col-sm-9">
            <template v-if="product.getPublishingDates.getTransferDate">{{
              product.getPublishingDates.getTransferDate
                | parseDate(dateOptions.format, dateOptions)
            }}</template>
            <template v-else>—</template>
          </dd>
        </template>
      </template>
      <template
        v-if="
          (product.getCopyrights &&
            product.getCopyrights.get &&
            product.getCopyrights.get.length > 0) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Copyright Statements
        </dt>
        <dd class="col-sm-9">
          <template
            v-if="
              product.getCopyrights &&
              product.getCopyrights.get &&
              product.getCopyrights.get.length > 0
            "
          >
            <div
              v-for="(copyright, index) in product.getCopyrights.get"
              :key="index"
            >
              {{ copyright.getYears[0]
              }}<template v-if="copyright.getYears.length > 1"
                >-{{
                  copyright.getYears[copyright.getYears.length - 1]
                }}</template
              >
              - {{ copyright.getCopyrightOwner }}
            </div>
          </template>
          <template v-else>—</template>
        </dd>
      </template>
      <template
        v-if="
          product.getNotificationTypeName ||
          product.getRecordSourceTypeName ||
          product.getRecordSourceName ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">Dissemination</dt>
        <dd class="col-sm-9"></dd>
        <template v-if="product.getNotificationTypeName || showAllFields">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
            Notification Type
          </dt>
          <dd class="col-sm-9">
            <template v-if="product.getNotificationTypeName">{{
              product.getNotificationTypeName
            }}</template>
            <template v-else>—</template>
          </dd>
        </template>
        <template v-if="product.getRecordSourceTypeName || showAllFields">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
            Record Source
          </dt>
          <dd class="col-sm-9">
            <template v-if="product.getRecordSourceTypeName">
              {{ product.getRecordSourceTypeName
              }}<template v-if="product.getRecordSourceName">
                - {{ product.getRecordSourceName }}</template
              >
            </template>
            <template v-else>—</template>
          </dd>
        </template>
      </template>
      <template
        v-if="
          (product.getProductWebsites &&
            product.getProductWebsites.getWebsites &&
            product.getProductWebsites.getWebsites.length > 0) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">Websites</dt>
        <dd class="col-sm-9">
          <template
            v-if="
              product.getProductWebsites &&
              product.getProductWebsites.getWebsites &&
              product.getProductWebsites.getWebsites.length > 0
            "
          >
            <div
              v-for="(website, index) in product.getProductWebsites.getWebsites"
              :key="index"
            >
              <a :href="website.getLinkUrl" target="_blank">{{
                website.getLinkUrl
              }}</a
              ><template v-if="website.getRoleName">
                ({{ website.getRoleName }})</template
              >
            </div></template
          ><template v-else>—</template>
        </dd>
      </template>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list
 * of product bibliographic information
 */

import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ParseDate from "../../mixins/parseDate.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "ProductBibliographicInfo",

  components: {
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [DisplaySetting, ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * Product data
     *
     * The data passed in must be an assosiative array (object in JS) of product data.
     * The keys of the array must match those defined in BooksonixProduct.php
     * Use the dump method to get an appropriate aray of product data
     */
    product: {
      type: Object,
      default: () => {},
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
