<template>
  <div>
    <h6 ref="caption">
      <b>{{ caption }}</b>
    </h6>
    <br />
    <div ref="progressBarContainer" class="webconsole_progressbar">
      <p ref="progressBarCaption"></p>
      <div class="progress">
        <div
          ref="progressBar"
          class="progress-bar"
          role="progressbar"
          style="width: 0%"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
    <div
      ref="consoleData"
      class="webconsole_consolebox webconsole_consolebox_noborder"
      @click="consoleDataClick"
    >
      Starting...
    </div>
    <div ref="runTime" style="text-align: right">&nbsp;</div>

    <div ref="buttons">
      <a
        id="webconsole_closebtn"
        ref="closeBtn"
        href="#"
        class="btn btn-primary"
        :onclick="closeAction + '; return false'"
      >
        Close
      </a>
      <a
        id="webconsole_cancelbtn"
        ref="cancelBtn"
        href="#"
        class="btn btn-danger"
        @click="abortClick"
      >
        Abort
      </a>
      <a
        id="webconsole_downloadbtn"
        ref="downloadBtn"
        href="#"
        class="btn btn-primary"
      >
        Download
      </a>
    </div>
    <br />
  </div>
</template>

<script>
/**
 * Displays the output of a running BooksoniX module via BsnxWebConsole and AJAX
 */
import { HTTP } from "../../../http-common.js";

export default {
  name: "BooksonixWebConsole",

  props: {
    /**
     * The module's process id
     */
    processId: {
      type: String,
      required: true,
    },

    /**
     * The module's process start time
     */
    processStartTime: {
      type: String,
      required: true,
    },

    /**
     * Caption
     */
    caption: {
      type: String,
      required: true,
    },

    /**
     * Are we within a modal
     */
    modal: {
      type: String,
      default: null,
    },

    /**
     * Close action javascript
     */
    closeAction: {
      type: String,
      default: "window.close()",
    },
  },

  data() {
    return {
      haveSetCaption: false,
      refreshUrl: null,
      cancelUrl: null,
      notifyUrl: null,
      timer: null,
      progressBarVisible: false,
      consoleVisible: true,
      modalHeightAdjust: 0,
    };
  },

  mounted() {
    if (this.modal) {
      // calculate the height difference between document's height and the console data box's height
      this.modalHeightAdjust =
        document.body.scrollHeight - this.$refs.consoleData.clientHeight + 1;
      this.modalResize();
      parent.addEventListener("resize", this.modalResize);
    }

    let url =
      document.location.protocol +
      "//" +
      document.location.host +
      "/scripts/BsnxWebConsole/" +
      this.$appId +
      "?M=JSON&PI=" +
      this.processId +
      "&PS=" +
      this.processStartTime +
      "&L=" +
      this.$logonId;
    HTTP.get(url)
      .then((response) => {
        // check an old version of BsnxWebConsole which doesn't support JSON
        if (response.headers["content-type"].search(/^text\/html($|;)/i) == 0) {
          // show the modal's footer
          parent.$("footer.modal-footer").show();
          // redirect and display the whole page
          window.location.href = url;
        } else {
          // process the response
          this.processJsonResponse(response.data);
        }
      })
      .catch(() => {
        // an error has occurred
        this.showError();
      });
  },

  unmounted() {
    if (this.modal) {
      parent.removeEventListener("resize", this.modalResize);
    }
  },

  methods: {
    /**
     * Modal resize
     */
    modalResize() {
      let modalBodyHeight =
        parent.$("#" + this.modal + " .modal-body")[0].clientHeight -
        this.modalHeightAdjust -
        this.$refs.buttons.clientHeight;
      if (this.progressBarVisible) {
        modalBodyHeight -= this.$refs.progressBarContainer.clientHeight + 27;
      }
      this.$refs.consoleData.style.height =
        Math.max(modalBodyHeight, 20) + "px";
    },

    /**
     * Timer call
     */
    timerEvent() {
      this.timer = null;
      if (this.refreshUrl) {
        HTTP.get(this.refreshUrl)
          .then((response) => {
            if (response.status == 204) {
              // there was nothing to update from the last query, try again
              this.$refs.runTime.innerHTML = response.headers["x-wc-runtime"];
              this.timer = window.setTimeout(this.timerEvent, 2000);
            } else {
              // process the response
              this.processJsonResponse(response.data);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status == 304) {
              // there was nothing to update from the last query, try again
              this.$refs.runTime.innerHTML =
                error.response.headers["x-wc-runtime"];
              this.timer = window.setTimeout(this.timerEvent, 2000);
            } else {
              // an error has occurred
              this.showError();
            }
          });
      }
    },

    /**
     * process JSON response
     */
    processJsonResponse(data) {
      // the first time we receive a JSON response with appMode defined we update the caption
      if (!this.haveSetCaption && data.appMode) {
        this.haveSetCaption = true;
        this.setCaption(data);
      }

      // update according to the progress state
      if (data.state == "starting") {
        // module is starting
        this.refreshUrl = data.retryUrl;
        this.timer = window.setTimeout(this.timerEvent, 2000);
      } else if (data.state == "active") {
        // module is running
        this.updateConsole(data.consoleData, data.module, !!data.notifyUrl);
        this.updateRunTime(data.runTime);
        this.refreshUrl = data.refreshUrl;
        this.cancelUrl = data.cancelUrl;
        this.notifyUrl = data.notifyUrl;
        this.$refs.cancelBtn.style.display = data.cancelUrl ? "inline" : "";
        if (this.modal) {
          this.modalResize();
        }
        this.timer = window.setTimeout(this.timerEvent, 2000);
      } else if (data.state == "finished") {
        // module has finished
        if (data.resultsData) {
          this.updateConsoleHtml(data.resultsData);
        } else {
          this.updateConsole(data.consoleData, data.module, false);
        }
        this.$refs.runTime.innerHTML = "&nbsp;";
        this.$refs.closeBtn.style.display = "inline";
        this.$refs.cancelBtn.style.display = "";
        if (data.resultsUrl) {
          this.$refs.downloadBtn.href = data.resultsUrl;
          this.$refs.downloadBtn.innerHTML =
            data.module == "D" ? "Save Results" : "Download";
          this.$refs.downloadBtn.style.display = "inline";
          if (!data.resultsData) {
            // add an unload handler that will show the close button on the modal if the output is
            // displayed in the iframe; it won't be shown if the output is downloaded or is html
            // (which is displayed in the console box)
            window.$(window).on("unload", () => {
              parent.$("footer.modal-footer").show();
            });
            window.location.href = data.resultsUrl;
          }
        }
        this.refreshUrl = null;
        if (this.modal) {
          this.modalResize();
        }
      }
    },

    /**
     * Set caption from module data
     */
    setCaption: function (data) {
      if (data.module == "D") {
        switch (data.appMode) {
          case "I":
            this.$refs.caption.innerHTML =
              "Internal Dissemination of data for External Data Suppliers";
            break;
          case "T":
            this.$refs.caption.innerHTML =
              "Test Dissemination of data for External Data Suppliers";
            break;
          case "R":
            this.$refs.caption.innerHTML =
              "Dissemination Record Check of data for External Data Suppliers";
            break;
          default:
            this.$refs.caption.innerHTML =
              "Dissemination of data to External Data Suppliers";
            break;
        }
      } else if (data.module == "E") {
        switch (data.appSubMode) {
          case "S":
            this.$refs.caption.innerHTML = "Industry Standard Export";
            break;
          case "T":
            this.$refs.caption.innerHTML = "User Defined Export";
            break;
          case "X":
            this.$refs.caption.innerHTML = "Industry Standard XML Export";
            break;
          case "C":
            this.$refs.caption.innerHTML = "Contacts Export";
            break;
          case "M":
            this.$refs.caption.innerHTML = "Creating Marketing Material";
            break;
        }
      } else if (data.module == "I") {
        switch (data.appSubMode) {
          case "X":
            this.$refs.caption.innerHTML = "Import ONIX Data";
            break;
          default:
            this.$refs.caption.innerHTML = "Import Comma/Tab Delimited Data";
            break;
        }
      }
    },

    /**
     * Update console
     */
    updateConsole: function (consoleData, module, canNotify) {
      // set the console box border
      this.$refs.consoleData.className = "webconsole_consolebox";

      // check consle data for progress messages which enable the progress bar
      let progressBarState = { pc: null, caption: null };
      if (module == "E") {
        consoleData = this.updateConsoleExportProgress(
          consoleData,
          progressBarState
        );
      }

      // make sure the progress bar is visible
      if (progressBarState.pc !== null && !this.progressBarVisible) {
        this.$refs.progressBarContainer.style.display = "block";
        this.$refs.consoleData.style.visibility = "hidden";
        this.$refs.consoleData.style.height =
          this.$refs.consoleData.offsetHeight - 80 + "px";
        this.progressBarVisible = true;
        this.consoleVisible = false;
      }

      // if we have console data remaining then make sure the console is also visible,
      // and if we have no console data remaining then make sure the console is hidden
      if (this.progressBarVisible) {
        if (!this.consoleVisible && consoleData.length != 0) {
          this.$refs.consoleData.style.visibility = "";
          this.consoleVisible = true;
        } else if (this.consoleVisible && consoleData.length == 0) {
          this.$refs.consoleData.style.visibility = "hidden";
          this.consoleVisible = false;
        }
      }

      // update the progress bar
      if (progressBarState.pc !== null) {
        if (progressBarState.caption) {
          this.$refs.progressBarCaption.innerHTML = progressBarState.caption
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/\r?\n/g, "<br>");
        }

        if (progressBarState.pc != "UNK") {
          // we have a percentage progress
          this.$refs.progressBar.className = "progress-bar";
          this.$refs.progressBar.style.width = progressBarState.pc + "%";
          this.$refs.progressBar.ariaValueNow = progressBarState.pc;
          this.$refs.progressBar.innerHTML = progressBarState.pc + "%";
        } else {
          // we have an indeterminate progress
          this.$refs.progressBar.className =
            "progress-bar progress-bar-striped progress-bar-animated";
          this.$refs.progressBar.style.width = "100%";
          this.$refs.progressBar.ariaValueNow = "100";
          this.$refs.progressBar.innerHTML = "";
        }
      }

      // convert the console data to HTML
      let html = consoleData
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/\r?\n/g, "<br>");

      if (module == "D") {
        /* eslint-disable no-control-regex */
        // check for notify link markup
        if (canNotify) {
          let notifyRefs = html.match(/\x1B&lt;[0-9A-F]{3}/gi);
          if (notifyRefs) {
            notifyRefs.forEach((notifyRef) => {
              html = html.replace(
                notifyRef,
                '<a href="#" data-notify-link-id="' +
                  notifyRef.substr(notifyRef.length - 3) +
                  '">'
              );
            });
          }
          html = html.replace(/\x1B&gt;/g, "</a>");
        }

        // check for information markup
        html = html
          .replace(/\x1B{/g, '<span title="')
          .replace(/\x1B}/g, '">&hellip;</span>');
        /* eslint-enable no-control-regex */
      }

      // is the scrollbar at the bottom of the console box?
      let scrollbarAtBottom =
        this.$refs.consoleData.scrollTop +
          this.$refs.consoleData.clientHeight ==
        this.$refs.consoleData.scrollHeight;

      // update the console box
      this.$refs.consoleData.innerHTML = html;

      // if the scrollbar was at the bottom then keep it at the bottom
      if (scrollbarAtBottom) {
        this.$refs.consoleData.scrollTop =
          this.$refs.consoleData.scrollHeight -
          this.$refs.consoleData.clientHeight;
      }
    },

    /**
     * Update console check export output for progress
     */
    updateConsoleExportProgress: function (consoleData, progressBarState) {
      // split into lines
      let lines = consoleData.replace(/\r\n$/, "").split("\r\n");
      // get the last line
      let lastLine = lines.length >= 1 ? lines[lines.length - 1] : "";

      // check the messages starting with the last line and working up
      let noBorderMessage = null;

      if (
        lastLine.search(
          /(^The (export has finished|marketing material has been created) (and is being downloaded\.\.\.|and emailed|but there was nothing to export)| *Aborted!)$/
        ) == 0
      ) {
        progressBarState.pc = "100";
        noBorderMessage = lastLine;
        lines.splice(lines.length - 1, 1);
        lastLine = lines.length >= 1 ? lines[lines.length - 1] : "";
      }

      if (lastLine == "Emailed marketing material") {
        if (progressBarState.caption === null) {
          progressBarState.pc = "100";
          progressBarState.caption = lastLine;
        }
        lines.splice(lines.length - 1, 1);
        lastLine = lines.length >= 1 ? lines[lines.length - 1] : "";
      }

      if (lastLine == "Emailing marketing material...") {
        if (progressBarState.caption === null) {
          progressBarState.pc = "UNK";
          progressBarState.caption = lastLine;
        }
        lines.splice(lines.length - 1, 1);
        lastLine = lines.length >= 1 ? lines[lines.length - 1] : "";
      }

      if (lastLine == "Created marketing material") {
        if (progressBarState.caption === null) {
          progressBarState.pc = "100";
          progressBarState.caption = lastLine;
        }
        lines.splice(lines.length - 1, 1);
        lastLine = lines.length >= 1 ? lines[lines.length - 1] : "";
      }

      if (lastLine.search(/^Converted to ./) == 0) {
        if (progressBarState.caption === null) {
          progressBarState.pc = "100";
          progressBarState.caption = lastLine;
        }
        lines.splice(lines.length - 1, 1);
        lastLine = lines.length >= 1 ? lines[lines.length - 1] : "";
      }

      if (lastLine.search(/^Converting to .+\.\.\. *([0-9]+%)?$/) == 0) {
        if (progressBarState.caption === null) {
          let matches = lastLine.match(/\.\.\. *([0-9]+)%$/);
          if (matches) {
            progressBarState.pc = matches[1];
            progressBarState.caption = lastLine.substr(
              0,
              lastLine.length - matches[1].length - 1
            );
          } else {
            progressBarState.pc = "UNK";
            progressBarState.caption = lastLine;
          }
        }
        lines.splice(lines.length - 1, 1);
        lastLine = lines.length >= 1 ? lines[lines.length - 1] : "";
      }

      if (
        lastLine.search(
          /^(Sorted|Exported|Processed) ([0-9][0-9,]* of )?[1-9][0-9,]* records?(, creating marketing material\.\.\.)? *$/
        ) == 0
      ) {
        if (progressBarState.caption === null) {
          progressBarState.pc = "100";
          progressBarState.caption = lastLine;
        }
        lines.splice(lines.length - 1, 1);
        lastLine = lines.length >= 1 ? lines[lines.length - 1] : "";
      }

      if (
        lastLine == "Opening database..." ||
        lastLine == "Searching database..." ||
        lastLine.search(
          /^(Starting export of|Starting creation of the marketing material for|Sorting) [1-9][0-9,]* records?\.\.\. *$/
        ) == 0
      ) {
        if (progressBarState.caption === null) {
          progressBarState.pc = "0";
          progressBarState.caption = lastLine;
        }
        lines.splice(lines.length - 1, 1);
        lastLine = lines.length >= 1 ? lines[lines.length - 1] : "";
      }

      let matches = lastLine.match(
        /^(Reading|Sorting|Exporting|Processing) record ([0-9][0-9,]*) of ([1-9][0-9,]*)\.\.\. *$/
      );
      if (matches) {
        if (progressBarState.caption === null) {
          let currentRecords = parseInt(matches[2].replace(/,/g, ""), 10);
          let totalRecords = parseInt(matches[3].replace(/,/g, ""), 10);
          let percent = (100 * currentRecords) / totalRecords;
          progressBarState.pc = percent.toFixed(totalRecords >= 1000 ? 1 : 0);
          if (matches[3] != "1") {
            progressBarState.caption =
              matches[1] + " " + matches[3] + " records...";
          } else {
            progressBarState.caption = matches[1] + " 1 record...";
          }
        }
        lines.splice(lines.length - 1, 1);
      }

      // do we have a message we want to display without a border
      if (noBorderMessage !== null) {
        lines[lines.length] = noBorderMessage;
        if (lines.length == 1) {
          this.$refs.consoleData.className =
            "webconsole_consolebox webconsole_consolebox_noborder";
        }
      }

      // re-combine the lines back together and return
      return lines.join("\r\n");
    },

    /**
     * Update console with HTML (hide any progress bar and scroll to the top)
     */
    updateConsoleHtml: function (html) {
      if (this.progressBarVisible) {
        this.$refs.progressBarContainer.style.display = "";
        this.progressBarVisible = false;
      }
      if (!this.consoleVisible) {
        this.$refs.consoleData.style.height = this.pageHeight
          ? this.pageHeight + "px"
          : "";
        this.$refs.consoleData.style.visibility = "";
        this.consoleVisible = true;
      }
      this.$refs.consoleData.innerHTML = html;
      this.$refs.consoleData.scrollTop = 0;
    },

    /**
     * Update run time
     */
    updateRunTime: function (runTime) {
      if (runTime >= 3600) {
        let h = Math.floor(runTime / 3600);
        let m = Math.floor((runTime % 3600) / 60);
        let s = Math.floor(runTime % 60);
        this.$refs.runTime.innerHTML =
          h + ":" + (m <= 9 ? "0" : "") + m + ":" + (s <= 9 ? "0" : "") + s;
      } else {
        let m = Math.floor(runTime / 60);
        let s = Math.floor(runTime % 60);
        this.$refs.runTime.innerHTML = m + ":" + (s <= 9 ? "0" : "") + s;
      }
    },

    /**
     * Show error display
     */
    showError: function () {
      this.$refs.consoleData.className =
        "webconsole_consolebox webconsole_consolebox_noborder";
      this.updateConsoleHtml(
        "Unable to view " +
          this.$refs.caption.innerHTML +
          " progress<br><br>If this problem persists then please contact BooksoniX support"
      );
      this.$refs.caption.innerHTML = "Error";
      this.$refs.closeBtn.style.display = "inline";
      this.$refs.cancelBtn.style.display = "";
      if (this.modal) {
        this.modalResize();
      }
    },

    /**
     * Console data click
     */
    consoleDataClick: function (event) {
      if ("notifyLinkId" in event.target.dataset) {
        if (confirm("Skip the remaining images for this data recipient?")) {
          this.refreshUrl = this.notifyUrl.replace(
            /###/,
            event.target.dataset.notifyLinkId
          );
          if (this.timer) {
            window.clearTimeout(this.timer);
          }
          this.timer = window.setTimeout(this.timerEvent, 100);
        }
      }
    },

    /**
     * Abort click handler
     */
    abortClick: function () {
      if (confirm("Are you sure you want to abort?")) {
        this.refreshUrl = this.cancelUrl;
        if (this.timer) {
          window.clearTimeout(this.timer);
        }
        this.timer = window.setTimeout(this.timerEvent, 100);
      }
    },
  },
};
</script>

<style>
h6 {
  font-weight: bold;
}
div.webconsole_progressbar {
  width: 100%;
  margin-bottom: 2em;
  display: none;
}
div.webconsole_consolebox {
  overflow: auto;
  height: 250px;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
}
div.webconsole_consolebox.webconsole_consolebox_noborder {
  border: 0px;
  padding: 0px;
}
@media (min-height: 725px) {
  div.webconsole_consolebox {
    height: 330px;
  }
}
@media (min-height: 805px) {
  div.webconsole_consolebox {
    height: 410px;
  }
}
@media (min-height: 885px) {
  div.webconsole_consolebox {
    height: 490px;
  }
}
@media (min-height: 965px) {
  div.webconsole_consolebox {
    height: 570px;
  }
}
@media (min-height: 1045px) {
  div.webconsole_consolebox {
    height: 650px;
  }
}
@media (min-height: 1125px) {
  div.webconsole_consolebox {
    height: 730px;
  }
}
div.webconsole_consolebox td[valign="top"],
div.webconsole_consolebox th[valign="top"] {
  vertical-align: top;
}
div.webconsole_consolebox th[align="left"] {
  text-align: left;
}
#webconsole_closebtn,
#webconsole_cancelbtn {
  display: none;
}
#webconsole_downloadbtn {
  display: none;
  margin-left: 20px;
}
</style>
