<template>
  <base-table :columns="columns" :initial-data="rules" :search-enabled="false">
    <template v-slot:rule_type="{ record }">
      <template v-if="record.rule_type == 'D'">Discount</template>
      <template v-else-if="record.rule_type == 'U'">Units</template>
      <template v-else-if="record.rule_type == 'C'">Combi</template>
      <template v-else>{{ record.rule_type }}</template>
    </template>
    <template v-slot:discount_start="{ record }">
      <template
        v-if="record.discount_start !== null && record.discount_start >= 0"
        >{{ record.discount_start.toFixed(2) }}%</template
      >
    </template>
    <template v-slot:discount_end="{ record }">
      <template v-if="record.discount_end !== null && record.discount_end >= 0"
        >{{ record.discount_end.toFixed(2) }}%</template
      >
    </template>
    <template v-slot:units_start="{ record }">
      <template v-if="record.units_start !== null && record.units_start >= 0">{{
        record.units_start.toFixed(0)
      }}</template>
    </template>
    <template v-slot:units_end="{ record }">
      <template v-if="record.units_end !== null && record.units_end >= 0">{{
        record.units_end.toFixed(0)
      }}</template>
    </template>
    <template v-slot:rate_perc="{ record }">
      <template v-if="record.rate_perc !== null && record.rate_perc >= 0"
        >{{ record.rate_perc.toFixed(2) }}%</template
      >
    </template>
    <template v-slot:payment_type="{ record }">
      <template v-if="record.payment_type == 'G'">Gross</template>
      <template v-else-if="record.payment_type == 'N'">Net</template>
      <template v-else-if="record.payment_type == 'F'">Fixed</template>
      <template v-else>Unknown</template>
    </template>
  </base-table>
</template>

<script>
export default {
  name: "RoyaltiesChannelRulesTable",

  props: {
    /**
     * Array of royalty rules for the channel
     * Required Key and value information defined in
     * the columns data of this component
     */
    rules: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      columns: [
        {
          text: "Seq.num.",
          key: "seq_num",
        },
        {
          text: "Rule on",
          key: "rule_type",
        },
        {
          text: "Disc. from",
          key: "discount_start",
        },
        {
          text: "Disc. to",
          key: "discount_end",
        },
        {
          text: "Qty. from",
          key: "units_start",
        },
        {
          text: "Qty. to",
          key: "units_end",
        },
        {
          text: "Rate",
          key: "rate_perc",
        },
        {
          text: "Type",
          key: "payment_type",
        },
        {
          text: "Description",
          key: "descr",
        },
      ],
    };
  },
};
</script>

<style></style>
