<template>
  <b-card>
    <template #header>
      <h5 class="d-inline-block">Edit</h5>
    </template>
    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'biblio-edit/edit-title/' + productId + linkSuffix"
          >Title &amp; Identifiers</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'biblio-edit/edit-biblio/' + productId + linkSuffix"
          >Bibliographic</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'biblio-edit/edit-format/' + productId + linkSuffix"
          >Format</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="
            $baseUrl + 'biblio-edit/edit-contrib/' + productId + linkSuffix
          "
          >Contributors</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="
            $baseUrl + 'biblio-edit/edit-subject/' + productId + linkSuffix
          "
          >Subject &amp; Audience</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="
            $baseUrl + 'biblio-edit/edit-fulltext/' + productId + linkSuffix
          "
          >Full Text</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'biblio-edit/edit-media/' + productId + linkSuffix"
          >Image/Media Files</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="
            $baseUrl + 'biblio-edit/edit-contentitems/' + productId + linkSuffix
          "
          >Content Items</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'biblio-edit/edit-rights/' + productId + linkSuffix"
          >Territorial Rights</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'biblio-edit/edit-supply/' + productId + linkSuffix"
          >Suppliers &amp; Prices</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'biblio-edit/edit-market/' + productId + linkSuffix"
          >Market Representation</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'biblio-edit/edit-prizes/' + productId + linkSuffix"
          >Prizes &amp; Promotion</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="
            $baseUrl + 'biblio-edit/edit-related/' + productId + linkSuffix
          "
          >Related Products</b-button
        >
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'biblio-edit/edit-notes/' + productId + linkSuffix"
          >Additional Notes</b-button
        >
      </div>
      <div v-if="$permitted('workflow/view')" class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="
            $baseUrl + 'biblio-edit/edit-workflow/' + productId + linkSuffix
          "
          >Workflow</b-button
        >
      </div>
      <div v-if="$permitted('foreignrights/view')" class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'foreignrights/edit/' + productId + linkSuffix"
          >Foreign Rights</b-button
        >
      </div>
      <div v-if="customForm" class="col-sm-6 col-lg-3">
        <b-button
          class="my-1 w-100"
          variant="outline-primary"
          :href="$baseUrl + 'biblio-edit/edit-custom/' + productId + linkSuffix"
          >{{ customForm }}</b-button
        >
      </div>
    </div>
  </b-card>
</template>

<script>
/**
 * Displays a button grid for shortcut buttons
 * to product edit forms
 */
export default {
  name: "ProductEditButtons",
  props: {
    /**
     * The id of the product
     */
    productId: {
      type: [String, Number],
      required: true,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Custom form exists and its title
     */
    customForm: {
      type: String,
      default: null,
    },
  },

  computed: {
    linkSuffix: function () {
      return this.returnTo ? "?return=" + this.returnTo : "";
    },
  },
};
</script>

<style></style>
