<template>
  <input data-provide="datepicker" />
</template>

<script>
/**
 * Renders a date picker element
 *
 * Uses the same Datepicker as the inputPairDatePicker Cake element
 * The element setupDatePicker is required to be included on the
 * page
 */
export default {
  name: "BaseDatePicker",
};
</script>

<style></style>
