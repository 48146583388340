<template>
  <b-card id="productCardTerritorialRights">
    <template #header>
      <a id="territorial-rights" />
      <h5 class="d-inline-block">Territorial Rights</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-rights/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <dl class="row">
      <template
        v-if="
          (product.getPublisherRights && showPublisherTerritorialRights) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Publisher territorial rights
        </dt>
        <dd class="col-sm-9"></dd>
        <template v-if="showExclusiveRights || showAllFields">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
            Exclusive
          </dt>
          <dd v-if="showExclusiveRights" class="col-sm-9">
            {{ exclusiveRightsText }}
          </dd>
          <dd v-else class="col-sm-9">—</dd>
        </template>
        <template v-if="showNonExclusiveRights || showAllFields">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
            Non-exclusive
          </dt>
          <dd v-if="showNonExclusiveRights" class="col-sm-9">
            {{ nonExclusiveRightsText }}
          </dd>
          <dd v-else class="col-sm-9">—</dd>
        </template>
        <template v-if="showNoRights || showAllFields">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
            No rights for
          </dt>
          <dd v-if="showNoRights" class="col-sm-9">{{ noRightsText }}</dd>
          <dd v-else class="col-sm-9">—</dd>
        </template>
      </template>
      <template v-if="product.getPublisherRightsStatement || showAllFields">
        <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
          Rights statement
        </dt>
        <!-- prettier-ignore -->
        <dd class="col-sm-9 preserve-white-space">{{ product.getPublisherRightsStatement | valueOrPlaceholder }}</dd>
      </template>
      <template
        v-if="
          (product.getSalesRestrictions.get &&
            product.getSalesRestrictions.get.length > 0) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Sales Restrictions
        </dt>
        <dd
          v-if="
            product.getSalesRestrictions.get &&
            product.getSalesRestrictions.get.length > 0
          "
          class="col-sm-9"
        >
          <div
            v-for="(restriction, index) in product.getSalesRestrictions.get"
            :key="index"
          >
            {{ restriction.getTypeName }}
            <template v-if="restriction.getOutletName">
              : {{ restriction.getOutletName }}</template
            >
            <template v-if="restriction.getDetail">
              : {{ restriction.getDetail }}</template
            >
          </div>
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template
        v-if="product.getSuppliers.get && product.getSuppliers.get.length > 0"
      >
        <template v-for="(supplier, index) in product.getSuppliers.get">
          <dt
            :key="'suppplier-dt-' + index"
            class="col-sm-3 font-weight-bold text-secondary"
          >
            Supplier
          </dt>
          <dd :key="'suppplier-dd-' + index" class="col-sm-9">
            {{ supplier.getName }}
            <template v-if="supplier.getRoleName"
              >({{ supplier.getRoleName }})</template
            >
          </dd>
          <template v-if="supplier.getRightsModeStr != 'none' || showAllFields">
            <dt
              :key="'suppplier-dt3-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Supplier territorial rights
            </dt>
            <dd :key="'suppplier-dd3-' + index" class="col-sm-9"></dd>
            <dt
              :key="'suppplier-dt4-' + index"
              class="col-sm-3 pl-5 font-weight-bold text-secondary"
            >
              Rights for
            </dt>
            <dd :key="'suppplier-dd4-' + index" class="col-sm-9">
              <template v-if="supplier.getRightsCountriesNames">
                <span
                  v-for="(
                    country, countryIndex
                  ) in supplier.getRightsCountriesNames"
                  :key="countryIndex"
                >
                  {{ country }}
                  <template
                    v-if="
                      countryIndex !=
                      supplier.getRightsCountriesNames.length - 1
                    "
                  >
                    ,
                  </template>
                </span>
              </template>
              <template v-else-if="supplier.getRightsModeStr == 'world'">
                Worldwide
              </template>
              <template
                v-else-if="supplier.getRightsModeStr == 'world_exc_ctry'"
              >
                Rest of World
              </template>
              <template v-else>—</template>
            </dd>
            <template
              v-if="
                supplier.getNoRightsCountriesNames ||
                supplier.getRightsModeStr == 'ctry_exc_row' ||
                showAllFields
              "
            >
              <dt
                :key="'suppplier-dt5-' + index"
                class="col-sm-3 pl-5 font-weight-bold text-secondary"
              >
                No rights for
              </dt>
              <dd :key="'suppplier-dd5-' + index" class="col-sm-9">
                <template v-if="supplier.getNoRightsCountriesNames">
                  <span
                    v-for="(
                      country, countryIndex
                    ) in supplier.getNoRightsCountriesNames"
                    :key="countryIndex"
                  >
                    {{ country }}
                    <template
                      v-if="
                        countryIndex !=
                        supplier.getNoRightsCountriesNames.length - 1
                      "
                    >
                      ,
                    </template>
                  </span>
                </template>
                <template
                  v-else-if="supplier.getRightsModeStr == 'ctry_exc_row'"
                >
                  Rest of World
                </template>
                <template v-else>—</template>
              </dd>
            </template>
          </template>
        </template>
      </template>
      <template v-else-if="showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">Supplier</dt>
        <dd class="col-sm-9">—</dd>
      </template>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list
 * of information relating to a products territorial
 * rights
 */

import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "TerritorialRights",

  components: {
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [DisplaySetting],

  props: {
    /**
     * The id of the product
     */
    productId: {
      type: [Number, String],
      default: null,
    },

    /**
     * Product data
     *
     * The data passed in must be an assosiative array (object in JS) of product data.
     * The keys of the array must match those defined in BooksonixProduct.php
     * Use the dump method to get an appropriate aray of product data
     *
     * Required properties for this component:
     * - getPublisherRights
     * - getPublisherRightsStatement
     * - getSalesRestrictions
     * - getSuppliers
     */
    product: {
      type: Object,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    publisherRights: function () {
      return this.product.getPublisherRights;
    },
    hasRightsCountries: function () {
      if (
        this.publisherRights.getExclusiveRightsCountries ||
        this.publisherRights.getNonExclusiveRightsCountries ||
        this.publisherRights.getNoRightsCountries
      ) {
        return true;
      }
      return false;
    },
    restOfWorldText: function () {
      if (this.hasRightsCountries) {
        return "Rest of World";
      }
      return "Worldwide/Rest of World";
    },
    showPublisherTerritorialRights: function () {
      if (
        this.publisherRights.getExclusiveRightsType == 0 &&
        this.publisherRights.getNonExclusiveRightsType == 0 &&
        this.publisherRights.getNoRightsType == 0
      ) {
        return false;
      }
      return true;
    },
    exclusiveRightsText: function () {
      let text = "";
      if (this.publisherRights.getExclusiveRightsCountriesNames) {
        for (let [
          index,
          country,
        ] of this.publisherRights.getExclusiveRightsCountriesNames.entries()) {
          text += country;
          if (
            index !=
            this.publisherRights.getExclusiveRightsCountriesNames.length - 1
          ) {
            text += ", ";
          }
        }
      } else {
        text = this.restOfWorldText;
      }
      return text;
    },
    nonExclusiveRightsText: function () {
      let text = "";
      if (this.publisherRights.getNonExclusiveRightsCountriesNames) {
        for (let [
          index,
          country,
        ] of this.publisherRights.getNonExclusiveRightsCountriesNames.entries()) {
          text += country;
          if (
            index !=
            this.publisherRights.getNonExclusiveRightsCountriesNames.length - 1
          ) {
            text += ", ";
          }
        }
      } else {
        text = this.restOfWorldText;
      }
      return text;
    },
    noRightsText: function () {
      let text = "";
      if (this.publisherRights.getNoRightsCountriesNames) {
        for (let [
          index,
          country,
        ] of this.publisherRights.getNoRightsCountriesNames.entries()) {
          text += country;
          if (
            index !=
            this.publisherRights.getNoRightsCountriesNames.length - 1
          ) {
            text += ", ";
          }
        }
      } else {
        text = this.restOfWorldText;
      }
      return text;
    },
    showExclusiveRights: function () {
      if (
        this.publisherRights.getExclusiveRightsType == 1 ||
        this.publisherRights.getExclusiveRightsType == 3
      ) {
        return true;
      }
      return false;
    },
    showNonExclusiveRights: function () {
      if (
        this.publisherRights.getNonExclusiveRightsType == 1 ||
        this.publisherRights.getNonExclusiveRightsType == 3
      ) {
        return true;
      }
      return false;
    },
    showNoRights: function () {
      if (
        this.publisherRights.getNoRightsType == 1 ||
        this.publisherRights.getNoRightsType == 3
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style></style>
