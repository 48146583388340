<template>
  <b-modal :id="id" size="lg" static lazy hide-footer :title="title">
    <div v-if="loading">
      <loading-spinner
        text="Please wait while the PDF is generated"
      ></loading-spinner>
    </div>
    <div v-if="loading == false" class="px-5 pb-2">
      <b-form @submit.prevent="onSubmit">
        <b-form-group
          label="Email address:"
          label-for="input-email"
          description="Enter multiple emails by separating them with a comma."
        >
          <b-form-input
            id="input-email"
            v-model="email"
            placeholder="Enter email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Subject:" label-for="input-subject">
          <b-form-input
            id="input-subject"
            v-model="subject"
            placeholder="Enter Subject"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Message:" label-for="input-message">
          <b-form-textarea
            id="input-message"
            v-model="message"
            placeholder="Enter Message"
            rows="3"
          ></b-form-textarea>
        </b-form-group>

        <div class="mt-2">
          <b-button type="button" variant="light" @click="closeModal"
            >Cancel</b-button
          >
          <b-button class="ml-2" type="submit" variant="success"
            >Submit</b-button
          >
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
/**
 * Displays a modal that will provide a form to a PDF export of a biblio record or contact
 */

import { HTTP } from "../../../http-common.js";

export default {
  name: "EmailDetailedRecordPdfModal",

  props: {
    /**
     * The modals elements ID.
     *
     * This is required and must be unique if multiple
     * modals are used on a single page
     */
    id: {
      type: String,
      required: true,
    },

    searchId: {
      type: Number,
      default: null,
    },

    position: {
      type: Number,
      default: null,
    },

    /**
     * The type of record output being shared
     *
     * @options product, contact
     */
    type: {
      type: String,
      default: "product",
    },
  },

  data: function () {
    return {
      loading: false,
      subject: null,
      message: null,
      email: null,
    };
  },

  computed: {
    title: function () {
      if (this.type == "contact") {
        return "Email Contact PDF";
      }
      return "Email Biblio PDF";
    },
    controllerName: function () {
      if (this.type == "product") {
        return "biblio";
      } else {
        return "contacts";
      }
    },
    shareOutputUrl: function () {
      return (
        this.$baseUrl +
        this.controllerName +
        "/output/" +
        this.searchId +
        "/" +
        this.position +
        "/" +
        "email"
      );
    },
  },

  methods: {
    /**
     * Displays the share media modal
     *
     * @param {string} subject The default subject of the email form
     */
    displayModal: function (subject = null) {
      // reset the form
      this.resetForm();
      this.subject = subject;
      this.$bvModal.show(this.id);
    },

    /**
     * resets form data to default
     */
    resetForm: function () {
      this.subject = null;
      this.message = null;
      this.email = null;
    },

    /**
     * Closes the modal
     */
    closeModal: function () {
      this.$bvModal.hide(this.id);
    },

    /**
     * Handles submitting the form
     */
    onSubmit: async function () {
      let postData = new FormData();
      if (this.email) {
        postData.append("email", this.email);
      }
      if (this.subject) {
        postData.append("subject", this.subject);
      }
      if (this.message) {
        postData.append("message", this.message);
      }
      this.loading = true;
      try {
        await HTTP.post(
          this.$baseUrl +
            this.controllerName +
            "/output/" +
            this.searchId +
            "/" +
            this.position +
            "/" +
            "email",
          postData
        );
        this.closeModal();
        this.loading = false;
        this.$bvModal.msgBoxOk("Email sent", {
          title: "Confirmation",
          okVariant: "success",
        });
      } catch (Error) {
        this.loading = false;
        this.$bvModal.msgBoxOk("There was an error sending the email", {
          title: "Error",
          okVariant: "danger",
        });
      }
    },
  },
};
</script>

<style scoped></style>
