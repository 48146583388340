<template>
  <b-card id="contactCardRoyalties">
    <template #header>
      <a id="royalties" />
      <h5 class="d-inline-block">Royalties Payee Information</h5>
      <edit-icon
        v-if="$permitted('contacts/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/contacts/edit/' +
          contactId +
          '?current_tab=7' +
          (returnTo ? '&return=' + returnTo : '')
        "
      ></edit-icon>
      <contact-quick-jump-dropdown
        class="float-right m-0"
        :contact-id="contactId"
      ></contact-quick-jump-dropdown>
    </template>
    <dl v-if="contact" class="row">
      <dt class="col-sm-4 font-weight-bold text-secondary">
        Statement Currency
      </dt>
      <dd class="col-sm-8">
        {{ contact.PayeeStatementCurrency | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Minimum Payout</dt>
      <dd class="col-sm-8">
        {{ contact.PayeeMinPayout | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">
        Minimum Payout in Statement Currency
      </dt>
      <dd class="col-sm-8">
        <template v-if="contact.PayeeMinPayoutInStatementCurrency == 'Y'"
          >Yes</template
        >
        <template v-if="contact.PayeeMinPayoutInStatementCurrency == 'N'"
          >No</template
        >
        <template v-else>
          {{ contact.PayeeMinPayoutInStatementCurrency | valueOrPlaceholder }}
        </template>
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Accounting Code</dt>
      <dd class="col-sm-8">
        {{ contact.PayeeAccountingCode | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">VAT Percentage</dt>
      <dd class="col-sm-8">
        <template
          v-if="
            contact.PayeeTaxPercent !== null && contact.PayeeTaxPercent >= 0
          "
        >
          {{ contact.PayeeTaxPercent.toFixed(2) }}%
        </template>
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">VAT Number</dt>
      <dd class="col-sm-8">
        {{ contact.PayeeVatNumber | valueOrPlaceholder }}
      </dd>
    </dl>

    <!-- <div class="card card-body bg-light"> -->
    <div class="row">
      <div
        v-for="(royaltyProduct, index) in royaltiesData"
        :key="index"
        class="col-12"
      >
        <royalties-product-descriptive-list-card
          :product="royaltyProduct.product"
          :out-of-royalties="
            royaltyProduct.royalties_prod_contr &&
            royaltyProduct.royalties_prod_contr.out_of_royalties == 'Y'
          "
          :payees="royaltyProduct.payees"
          :channels="royaltyProduct.channels"
        ></royalties-product-descriptive-list-card>
      </div>
    </div>
    <!-- </div> -->
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list of contact data
 */
import RoyaltiesProductDescriptiveListCard from "../royalties/RoyaltiesProductDescriptiveListCard.vue";
import ContactQuickJumpDropdown from "./ContactQuickJumpDropdown.vue";
import DisplaySetting from "../../mixins/productDisplaySetting.js";

export default {
  name: "ContactDescriptiveListRoyalties",

  components: {
    "contact-quick-jump-dropdown": ContactQuickJumpDropdown,
    "royalties-product-descriptive-list-card":
      RoyaltiesProductDescriptiveListCard,
  },

  mixins: [DisplaySetting],

  props: {
    /**
     * The ID of the contact
     */
    contactId: {
      type: Number,
      default: null,
    },

    /**
     * Contact object
     */
    contact: {
      type: Object,
      default: () => {},
    },

    /**
     * Royalties data contains an array of products with royalty information
     * This data is retrieved from the class
     * RoyaltiesProdContrChannelTable::getAllRoyaltySettingsByContributor
     *
     * In addition product and contact (payee) information is formatted using
     * the product and contact formatter.
     *
     * Each royaltiesData element should have a 'product' key, which contains the following
     * fields from the BooksonixProductFormatter:
     *  - RecordNumber
     *  - MainTitle
     *  - Format
     *  - ISBN-13
     *  - EAN
     *  - ASIN
     *  - PublisherUniqueID
     *
     * Each royaltiesData element should have a 'payees' key which contains an array of
     * formatted contacts, each element should have the following fields from
     * the BooksonixContactFormatter:
     *  - RecordNumber
     *  - DisplayName
     *  - PayeeAccountingCode
     *  - PayeeStatementCurrency
     *  - PayeeMinPayout
     *  - PayeeTaxPercent
     *  - PayeeMinPayoutInStatementCurrency
     *  - PayeeVatNumber
     *
     * All formatter fields should use the option 'valuesArrayKey' => 'fieldName'
     */
    royaltiesData: {
      type: Array,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Enable or disable edit links
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style></style>
