var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"productCardPrizesAndPromotion"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('a',{attrs:{"id":"prizes-and-promotions"}}),_c('h5',{staticClass:"d-inline-block"},[_vm._v("Prizes & Promotion")]),(_vm.$permitted('biblio/edit') && _vm.allowEdit)?_c('edit-icon',{staticClass:"ml-2 d-print-none",attrs:{"link":_vm.$baseUrl +
        '/biblio-edit/edit-prizes/' +
        _vm.productId +
        (_vm.returnTo ? '?return=' + _vm.returnTo : '')}}):_vm._e(),_c('product-quick-jump-dropdown',{staticClass:"float-right",attrs:{"product-id":_vm.productId,"display":_vm.display,"has-custom-fields":_vm.hasCustomFields}})]},proxy:true}])},[_c('dl',{staticClass:"row"},[(_vm.prizes.length > 0)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Prizes / Awards ")]),_c('dd',{staticClass:"col-sm-9"},_vm._l((_vm.prizes),function(prize,index){return _c('div',{key:index,staticClass:"row mb-2"},[_c('div',{staticClass:"col"},[_c('div',[_vm._v(" "+_vm._s(prize.getName)+", "+_vm._s(prize.getYear)+", "+_vm._s(prize.getCountryName)+" - "+_vm._s(prize.getAchievementName)+" ")]),_c('div',{staticClass:"pl-1 preserve-white-space"},[_vm._v(_vm._s(prize.getJury))])]),_c('div',{staticClass:"col"},[(prize.getImage)?_c('div',{staticClass:"d-flex flex-wrap justify-content-start"},[_c('product-media-img',{staticStyle:{"min-width":"150px","height":"200px"},attrs:{"thumbnail":"","url":'/' + _vm.$appId + '/' + prize.getImage,"share-media-modal-id":_vm.shareMediaModalId,"logon-id":_vm.logonId,"date-options":_vm.dateOptions,"img-height":200}})],1):_vm._e()])])}),0)]:(_vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Prizes / Awards ")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e(),(
        _vm.promotion &&
        (_vm.promotion.getCampaignInformation ||
          _vm.promotion.getContactDetails ||
          _vm.promotion.getInitialPrintRun ||
          _vm.promotion.getCopiesSold)
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Promotional Information ")]),_c('dd',{staticClass:"col-sm-9"}),(_vm.promotion.getCampaignInformation)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Campaign Information ("+_vm._s(_vm.promotion.getCampaignInformationFormat == "02" ? "HTML" : "Text")+")"),(_vm.promotion.getCampaignInformationInternal)?[_vm._v(" (Internal)")]:_vm._e()],2),_c('dd',{staticClass:"col-sm-9"},[(_vm.promotion.getCampaignInformationFormat == '02')?[_c('div',{domProps:{"innerHTML":_vm._s(_vm.promotion.getCampaignInformation)}})]:[_vm._v(_vm._s(_vm.promotion.getCampaignInformation))]],2)]:_vm._e(),(_vm.promotion.getContactDetails)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Contact Details"),(_vm.promotion.getContactDetailsInternal)?[_vm._v(" (Internal)")]:_vm._e()],2),_c('dd',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.promotion.getContactDetails)+" ")])]:_vm._e(),(_vm.promotion.getInitialPrintRun)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Initial Print Run ")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.promotion.getInitialPrintRun)+" ")])]:_vm._e(),(_vm.promotion.getCopiesSold)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Copies Sold ")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.promotion.getCopiesSold)+" ")])]:_vm._e()]:(_vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Promotional Information ")]),_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }