<template>
  <div class="row searchformtickboxline">
    <div class="col-1">
      <input :name="'bool' + lineNo" type="hidden" :value="bool" />
      <slot name="bool"></slot>
    </div>
    <div class="col-4">
      <input :name="'field' + lineNo" type="hidden" :value="field" />
      <slot name="field"></slot>
    </div>
    <div
      class="col-7 searchformtickboxline_term"
      :data-v-step="'biblio-search-checkboxes' + lineNo"
    >
      <input
        :name="'type' + lineNo"
        type="hidden"
        :value="'tickbox:' + options.length + (filter ? '/f' : '')"
      />
      <input
        v-if="defaultTerm"
        :name="'defterm' + lineNo"
        type="hidden"
        :value="defaultTerm"
      />
      <template v-for="(option, optionIndex) in options">
        <input
          :id="'TermCtrl' + lineNo + '_' + (optionIndex + 1)"
          :key="lineNo + '.' + (optionIndex + 1) + 't'"
          :name="'term' + lineNo + '_' + (optionIndex + 1)"
          type="checkbox"
          :value="option.value"
          :checked="typeof term != 'string' && term.indexOf(option.value) != -1"
          :disabled="option.disabled"
        />
        <label
          :key="lineNo + '.' + (optionIndex + 1) + 'l'"
          :for="'TermCtrl' + lineNo + '_' + (optionIndex + 1)"
          :class="option.hsiInternal ? 'hsi-internal' : false"
        >
          {{ option.label }}
        </label>
      </template>
    </div>
  </div>
</template>

<script>
/**
 * Display a search form line of tickbox(es)
 *
 * The following form controls are output:
 *   'type{lineNo}' = 'bool{lineNo}' (hidden), 'field{lineNo}' (hidden), 'tickbox:{count}[/f]' (hidden), 'defterm{lineNo}' (hidden), 'term{lineNo}_{n}' (checkbox)
 */
export default {
  name: "SearchFormTickboxLine",

  props: {
    /**
     * The line number
     */
    lineNo: {
      type: Number,
      required: true,
    },

    /**
     * The tickbox options. An array of objects each with the properties 'value' (string), 'label' (string), 'disabled' (boolean, optional), 'hsiInternal' (boolean, optional)
     */
    options: {
      type: Array,
      required: true,
    },

    /**
     * The boolean (fixed value)
     */
    bool: {
      type: String,
      default: "AND",
    },

    /**
     * The field (fixed value)
     */
    field: {
      type: String,
      required: true,
    },

    /**
     * The term
     */
    term: {
      type: [String, Array],
      required: true,
    },

    /**
     * The default term to use if no tick boxes are ticked (fixed value)
     */
    defaultTerm: {
      type: String,
      default: null,
    },

    /**
     * Is a filter line?
     */
    filter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.searchformtickboxline .searchformtickboxline_term {
  padding-left: 22px;
}
.searchformtickboxline_term label {
  padding-left: 5px;
  padding-right: 10px;
}
.searchformtickboxline_term label.hsi-internal {
  color: blue;
}
</style>
