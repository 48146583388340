var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.deleted)?_c('b-card',{staticClass:"p-2 product-summary-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col card-icon text-center"},[_c('i',{staticClass:"fa fa-facebook-square fa-5x"})]),_c('div',{staticClass:"col pl-0 pr-2"},[_c('div',{staticClass:"row h-50"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_c('b-link',{attrs:{"href":_vm.$baseUrl +
                  'ftp-catalogue-feed/edit/' +
                  _vm.catalogue.mode +
                  '/' +
                  _vm.catalogue.id}},[_vm._v(_vm._s(_vm.catalogue.name))])],1),_c('div',[(
                  _vm.fileExists &&
                  _vm.internalCatalogue.hasOwnProperty('included_records')
                )?[_c('b-badge',{attrs:{"pill":"","variant":"light"}},[_vm._v(_vm._s(_vm.internalCatalogue.included_records)+" Record"),(_vm.internalCatalogue.included_records != 1)?[_vm._v("s")]:_vm._e()],2)]:_vm._e(),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_c('b-link',{attrs:{"disabled":!_vm.fileExists,"href":_vm.$baseUrl +
                  'ftp-catalogue-feed/edit/' +
                  _vm.catalogue.mode +
                  '/' +
                  _vm.catalogue.id}},[_vm._v("Edit Config")]),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_c('b-link',{attrs:{"disabled":!_vm.fileExists,"href":_vm.$baseUrl +
                  '/ftp-catalogue-feed/edit-search/' +
                  _vm.catalogue.mode +
                  '/' +
                  _vm.catalogue.id}},[_vm._v("Edit / View Search")]),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_c('b-link',{attrs:{"disabled":!_vm.fileExists,"href":_vm.$baseUrl +
                  '/ftp-catalogue-feed/download/' +
                  _vm.catalogue.mode +
                  '/' +
                  _vm.catalogue.id,"download":""}},[_vm._v("Download File")]),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_c('b-link',{attrs:{"href":"#","disabled":!_vm.fileExists,"jest-catalogue-generate":""},on:{"click":_vm.generateClicked}},[_vm._v("Regenerate File")]),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_c('b-link',{staticClass:"text-danger",attrs:{"href":"#","disabled":!_vm.fileExists,"jest-catalogue-delete":""},on:{"click":_vm.deleteClicked}},[_vm._v("Delete")]),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),(_vm.fileError)?[_c('span',{staticClass:"text-muted"},[_vm._v("There was an error generating the file")])]:[(_vm.fileExists)?[_c('span',{staticClass:"text-muted"},[_vm._v("Last Updated: "+_vm._s(_vm._f("parseDate")(_vm.internalCatalogue.last_updated,_vm.$userPreferences.dateFormat))+" "+_vm._s(_vm._f("parseDate")(_vm.internalCatalogue.last_updated,"HH:mm", null, true)))]),_c('info-icon',{staticClass:"ml-1",attrs:{"tip":"ftpCatalogueLastUpdated"}})]:_vm._e(),(!_vm.fileExists)?_c('span',{staticClass:"text-muted"},[_vm._v("File is being generated "),_c('i',{staticClass:"fa-solid fa-spinner fa-pulse"})]):_vm._e()]],2)])])]),_c('div',{staticClass:"row h-50"},[_c('div',{staticClass:"col"},[_c('b-input-group',{staticClass:"mt-2"},[_c('b-form-input',{ref:_vm.urlInputID,attrs:{"id":_vm.urlInputID,"type":"text","readonly":"","jest-displayed-url":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.manual",value:('Copied'),expression:"'Copied'",modifiers:{"manual":true}}],attrs:{"id":_vm.copyBtnId,"variant":"outline-primary"},on:{"click":function($event){return _vm.$copyToClipboard(_vm.urlInputID, _vm.copyBtnId, _vm.url)}}},[_c('i',{staticClass:"fa-solid fa-copy"}),_vm._v(" Copy to Clipboard")])],1)],1)],1)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }