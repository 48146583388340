<template>
  <div class="inlineedit" :class="className">
    <span
      ref="dispCtrl"
      class="inlineedit-disp"
      :class="{ editing: autoStart, defaultvalue: !currentValue }"
      @click="startEdit"
    >
      <template v-if="editOptions">
        <template v-for="(optionText, optionValue) in editOptions">
          <template v-if="optionValue == currentValue">
            {{ optionText }}
          </template>
        </template>
      </template>
      <template v-else-if="currentValue">{{ currentValue }}</template>
      <template v-else>{{ displayNoValue }}</template>
    </span>
    <div
      ref="editCtrlBox"
      class="inlineedit-ctrl"
      :class="autoStart ? 'editing' : null"
    >
      <select
        v-if="editType == 'select-one'"
        ref="editCtrl"
        size="1"
        @click.stop=""
        @change="saveEdit"
      >
        <option
          v-for="(optionText, optionValue) in editOptions"
          :key="optionValue"
          :value="optionValue"
          :selected="optionValue == currentValue"
        >
          {{ optionText }}
        </option>
      </select>
      <input
        v-else-if="editType"
        ref="editCtrl"
        :type="editType"
        :placeholder="editPlaceholder"
        :required="editRequired"
        :minlength="editMinLength"
        :maxlength="editMaxLength"
        :min="editMinValue"
        :max="editMaxValue"
        :pattern="editPattern"
        @click.stop=""
        @keydown.enter.prevent=""
        @keyup.enter.prevent="saveEdit"
        @keyup.esc.prevent="cancelEdit"
      />
      <textarea
        v-else
        ref="editCtrl"
        :placeholder="editPlaceholder"
        :required="editRequired"
        :minlength="editMinLength"
        :maxlength="editMaxLength"
        rows="1"
        @input="resizeEdit"
        @click.stop=""
        @keydown.enter.prevent=""
        @keyup.enter.prevent="saveEdit"
        @keyup.esc.prevent="cancelEdit"
      />
    </div>
    <button
      ref="okBtn"
      type="button"
      class="inlineedit-okbtn"
      :class="autoStart ? 'editing' : null"
      @click.stop="saveEdit"
    >
      <i class="fa fa-check"></i>
    </button>
    <button
      ref="cancelBtn"
      type="button"
      class="inlineedit-cancelbtn"
      :class="autoStart ? 'editing' : null"
      @click.stop="cancelEdit"
    >
      <i class="fa fa-xmark"></i>
    </button>
  </div>
</template>

<script>
/**
 * Inline edit control
 */
export default {
  name: "InlineEdit",

  props: {
    /**
     * The control's initial value
     */
    value: {
      type: [String, Number, null],
      default: "",
    },

    /**
     * The control's display value when the control has no value
     */
    displayNoValue: {
      type: String,
      default: "\u2014",
    },

    /**
     * The control's class name
     */
    className: {
      type: String,
      default: null,
    },

    /**
     * The default action (which clicking outside the edit box or buttons) is to save
     */
    defaultSave: {
      type: Boolean,
      default: true,
    },

    /**
     * The control's uses asynchronous saving; the save should start when this component
     * triggers the save event and finished when this component's saveComplete() or
     * cancelEdit() methods are called
     */
    asyncSaving: {
      type: Boolean,
      default: false,
    },

    /**
     * Automatically start with the control in edit mode
     */
    autoStart: {
      type: Boolean,
      default: false,
    },

    /**
     * The edit control's type; must be 'select-one' for a drop-down list or another edit type
     */
    editType: {
      type: String,
      default: null,
    },

    /**
     * The edit control's options (used when the type is 'select-one')
     * An object with each property being the option value and the property's value being the text)
     */
    editOptions: {
      type: Object,
      default: null,
    },

    /**
     * The edit control's placeholder
     */
    editPlaceholder: {
      type: String,
      default: null,
    },

    /**
     * The edit control requires a value
     */
    editRequired: {
      type: Boolean,
      default: false,
    },

    /**
     * The edit control's minimum length
     */
    editMinLength: {
      type: Number,
      default: null,
    },

    /**
     * The edit control's maximum length
     */
    editMaxLength: {
      type: Number,
      default: null,
    },

    /**
     * The edit control's minimum value (for numeric edit types)
     */
    editMinValue: {
      type: Number,
      default: null,
    },

    /**
     * The edit control's maximum value (for numeric edit types)
     */
    editMaxValue: {
      type: Number,
      default: null,
    },

    /**
     * The edit control's data pattern
     */
    editPattern: {
      type: String,
      default: null,
    },
  },

  data: function () {
    return {
      currentValue: this.value,
      inEdit: this.autoStart,
      asyncSaveValue: null,
      editPatternRE: this.editPattern
        ? new RegExp("^(" + this.editPattern + ")?$", "")
        : null,
    };
  },

  watch: {
    value: function (value) {
      this.currentValue = value;
    },
  },

  beforeDestroy: function () {
    if (this.inEdit) {
      document.removeEventListener("click", this.documentClick);
    }
  },

  methods: {
    /**
     * Start editing
     */
    startEdit() {
      if (!this.inEdit) {
        this.$refs.dispCtrl.className = "inlineedit-disp editing";

        this.$refs.editCtrlBox.className = "inlineedit-ctrl editing";
        this.$refs.editCtrl.value = this.currentValue;
        this.resizeEdit(null);
        this.$refs.editCtrl.disabled = false;
        this.$refs.editCtrl.focus();

        let okBtnTop =
          this.$refs.editCtrl.offsetTop + this.$refs.editCtrl.offsetHeight + 4;
        let okBtnLeft =
          this.$refs.editCtrl.offsetLeft + this.$refs.editCtrl.offsetWidth - 50;
        this.$refs.okBtn.className = "inlineedit-okbtn editing";
        this.$refs.okBtn.style.top = okBtnTop + "px";
        this.$refs.okBtn.style.left = okBtnLeft + "px";
        this.$refs.okBtn.disabled = false;

        let cancelBtnLeft = okBtnLeft + 26;
        this.$refs.cancelBtn.className = "inlineedit-cancelbtn editing";
        this.$refs.cancelBtn.style.top = okBtnTop + "px";
        this.$refs.cancelBtn.style.left = cancelBtnLeft + "px";
        this.$refs.cancelBtn.disabled = false;

        // If the user click outside the edit box but not on the OK or cancel
        // buttons then we need to cancel editing
        // We need to add this after a slight delay otherwise it immediately takes
        // effect and cancels the edit. This can prevented by stopping propagation
        // but this in turn prevents cancelling editing in any other in-line edit
        // components
        window.setTimeout(() => {
          document.addEventListener("click", this.documentClick);
        }, 50);

        this.inEdit = true;
        this.$emit("editing");
      }
    },

    /**
     * resize edit to fit data and validate input data
     */
    resizeEdit(event) {
      if (!this.editType) {
        // resize the control
        this.$refs.editCtrl.style.height = "";
        if (this.$refs.editCtrl.value.length != 0) {
          this.$refs.editCtrl.style.height =
            this.$refs.editCtrl.scrollHeight + 2 + "px";
        }

        // if called by an input event then re-position the buttons
        if (event) {
          let okBtnTop =
            this.$refs.editCtrl.offsetTop +
            this.$refs.editCtrl.offsetHeight +
            4;
          this.$refs.okBtn.style.top = okBtnTop + "px";
          this.$refs.cancelBtn.style.top = okBtnTop + "px";
        }

        // if we have an edit pattern and called by an input event, simulate
        // the validation (textarea controls don't support the pattern attribute)
        if (event && this.editPattern) {
          if (this.editPatternRE.test(this.$refs.editCtrl.value)) {
            this.$refs.editCtrl.setCustomValidity("");
          } else {
            this.$refs.editCtrl.setCustomValidity(
              "Please match the requested format"
            );
          }
        }
      }
    },

    /**
     * Save editing
     */
    saveEdit() {
      if (this.inEdit) {
        if (this.$refs.editCtrl.checkValidity()) {
          let newValue;
          if (this.$refs.editCtrl.type == "select-one") {
            if (this.$refs.editCtrl.selectedIndex >= 0) {
              newValue =
                this.$refs.editCtrl.options[this.$refs.editCtrl.selectedIndex]
                  .value;
            } else {
              newValue = "";
            }
          } else {
            newValue = this.$refs.editCtrl.value;
          }

          if (this.asyncSaving) {
            // setup asynchronous saving before emitting the save event
            // as its handler could call saveComplete immediately
            this.asyncSaveValue = newValue;
            this.$refs.editCtrl.disabled = true;
            this.$refs.okBtn.disabled = true;
            this.$refs.cancelBtn.disabled = true;
            this.$emit("save", newValue);
          } else {
            this.$emit("save", newValue);
            this.currentValue = newValue;
            this.cancelEdit();
          }
        } else {
          this.$refs.editCtrl.focus();
        }
      }
    },

    /**
     * Save complete - used with asynchronus editing
     */
    saveComplete() {
      if (this.inEdit && this.asyncSaveValue !== null) {
        this.currentValue = this.asyncSaveValue;
        this.cancelEdit();
      }
    },

    /**
     * Cancel editing
     */
    cancelEdit() {
      if (this.inEdit) {
        this.$refs.dispCtrl.className = this.currentValue
          ? "inlineedit-disp"
          : "inlineedit-disp defaultvalue";
        this.$refs.editCtrlBox.className = "inlineedit-ctrl";
        this.$refs.okBtn.className = "inlineedit-okbtn";
        this.$refs.cancelBtn.className = "inlineedit-cancelbtn";
        document.removeEventListener("click", this.documentClick);
        this.inEdit = false;
        this.asyncSaveValue = null;
        this.$emit("edit-cancel");
      }
    },

    /**
     * Document click handler; save/cancel editing if editing but not saving
     */
    documentClick() {
      if (this.inEdit && this.asyncSaveValue === null) {
        if (this.defaultSave) {
          this.saveEdit();
        } else {
          this.cancelEdit();
        }
      }
    },
  },
};
</script>

<style>
.inlineedit {
  display: inline-block;
}
.inlineedit-disp {
  padding: 5px 7px 8px 7px;
  display: block;
  background-color: transparent;
  max-width: 100%;
}
.inlineedit-disp:hover {
  background-color: #e8e8e8;
  border-radius: 0.25rem;
}
.inlineedit-disp.defaultvalue {
  color: silver;
}
.inlineedit-disp.editing {
  display: none;
}
.inlineedit-ctrl {
  display: none;
  width: 100%;
}
.inlineedit-ctrl.editing {
  display: inline-block;
}
.inlineedit-ctrl input,
.inlineedit-ctrl select {
  width: calc(100% - 5px);
}
.inlineedit-ctrl textarea {
  width: calc(100% - 5px);
  resize: none;
}
.inlineedit-ctrl input:invalid,
.inlineedit-ctrl select:invalid,
.inlineedit-ctrl textarea:invalid {
  border: red solid 3px;
}
.inlineedit-okbtn,
.inlineedit-cancelbtn {
  display: none;
  position: absolute;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.125rem 0.375rem;
  text-align: center;
  vertical-align: middle;
  z-index: 999;
}
.inlineedit-okbtn.editing,
.inlineedit-cancelbtn.editing {
  display: inline-block;
}
</style>
