<template>
  <div class="bibliorelatedproductsedit">
    <div class="bibliorelatedproductsedit-add">
      <v-select
        v-model="typeVSelectOption"
        label="name"
        :options="typesList"
        :clearable="false"
        placeholder="Select a related product type"
        @input="selectedType"
      >
        <template v-slot:no-options="{ search, searching }">
          <em v-if="searching">No related product type found</em>
          <em v-else>Start typing to search for a related product type</em>
        </template>
      </v-select>
    </div>
    <div class="bibliorelatedproductsedit-item-container">
      <template v-for="(type, typeIndex) in typesList">
        <div
          v-if="type.products"
          :key="typeIndex"
          class="row bibliorelatedproductsedit-item"
        >
          <div class="col-12">
            <hr />
            <div>{{ type.name }} Related Products</div>
            <biblio-identifiers-edit
              :component-id="'rp' + typeIndex"
              :identifiers="type.products"
              :types="[
                {
                  code: 'getGTIN13ISBN13',
                  name: 'GTIN-13 & ISBN-13',
                  lookup: '0315',
                  pattern: '(978[0-9]{10}|979[1-9][0-9]{9})',
                },
                {
                  code: 'getGTIN13',
                  name: 'GTIN-13',
                  lookup: '03',
                  pattern: '[0-9]{13}',
                },
                {
                  code: 'getISBN13',
                  name: 'ISBN-13',
                  lookup: '15',
                  pattern: '(978[0-9]{10}|979[1-9][0-9]{9})',
                },
                {
                  code: 'getISBN10',
                  name: 'ISBN-10',
                  lookup: '02',
                  pattern: '[0-9]{9}[0-9Xx]',
                },
                {
                  code: 'getUPC',
                  name: 'UPC',
                  lookup: '04',
                  pattern: '[0-9]{12}',
                },
                {
                  code: 'getLCCN',
                  name: 'LCCN',
                  lookup: '13',
                  pattern: null,
                },
                {
                  code: 'getDOI',
                  name: 'DOI',
                  lookup: '06',
                  pattern: '10\.[0-9]{4,9}/[-._;()/:A-Za-z0-9]+',
                },
                {
                  code: 'getASIN',
                  name: 'ASIN',
                  lookup: '01ASIN',
                  pattern: '[0-9A-Z]{10}',
                },
              ]"
              :multiple="true"
              :extra-identifier-properties="{ getRelatedType: type.code }"
              :lookup-url="$baseUrl + 'biblio-edit/find-products/@T?q=@S'"
              @changed="
                (identifiers) => {
                  changedIdentifiers(typeIndex, identifiers);
                }
              "
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/**
 * Bibliographic related products edit container
 *
 * Emits a 'changed' event including an array of related product objects (excluding empty entries)
 */
import vSelect from "vue-select";
import BiblioIdentifiersEdit from "./BiblioIdentifiersEdit.vue";

export default {
  name: "BiblioRelatedProductsEdit",

  components: {
    "v-select": vSelect,
    "biblio-identifiers-edit": BiblioIdentifiersEdit,
  },

  props: {
    /**
     * Related products to edit
     */
    relatedProducts: {
      type: Array,
      default: null,
    },

    /**
     * Related product types
     */
    types: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    let relatedProducts = this.relatedProducts ? this.relatedProducts : [];
    let typesList = [];
    for (let i = 0; i < this.types.length; i++) {
      let typeCode = this.types[i].code;
      let typeProducts = [];
      for (let j = 0; j < relatedProducts.length; j++) {
        if (relatedProducts[j].getRelatedType == typeCode) {
          let typeProduct = {
            getRelatedType: typeCode,
            getProduct: relatedProducts[j].getProduct,
          };
          if (
            relatedProducts[j].getGTIN13 !== null &&
            relatedProducts[j].getISBN13 === relatedProducts[j].getGTIN13
          ) {
            typeProduct.getGTIN13ISBN13 = relatedProducts[j].getGTIN13;
            typeProducts.push(typeProduct);
          } else {
            for (let propName in relatedProducts[j]) {
              if (
                propName != "getRelatedType" &&
                relatedProducts[j][propName] !== null
              ) {
                typeProduct[propName] = relatedProducts[j][propName];
                typeProducts.push(typeProduct);
                break;
              }
            }
          }
        }
      }
      typesList.push({
        ...this.types[i],
        products: typeProducts.length != 0 ? typeProducts : null,
      });
    }
    return {
      typesList: typesList,
      typeVSelectOption: null,
    };
  },

  methods: {
    /**
     * A type has been selected from the v-select control
     */
    selectedType: function (type) {
      if (type) {
        for (let i = 0; i < this.typesList.length; i++) {
          if (this.typesList[i].code == type.code) {
            if (this.typesList[i].products === null) {
              this.typesList[i].products = [];
            }
            break;
          }
        }
        this.typeVSelectOption = null;
      }
    },

    /**
     * The identifiers for a type have changed
     */
    changedIdentifiers: function (index, identifiers) {
      let products = [];
      for (let i = 0; i < identifiers.length; i++) {
        if ("getGTIN13ISBN13" in identifiers[i]) {
          products.push({
            getRelatedType: identifiers[i].getRelatedType,
            getGTIN13: identifiers[i].getGTIN13ISBN13,
            getISBN13: identifiers[i].getGTIN13ISBN13,
          });
        } else {
          products.push(identifiers[i]);
        }
      }
      this.typesList[index].products = products;
      this.notifyChanges();
    },

    /**
     * notify of changed related products
     */
    notifyChanges: function () {
      let relatedProducts = [];
      for (let i = 0; i < this.typesList.length; i++) {
        if (this.typesList[i].products) {
          for (let j = 0; j < this.typesList[i].products.length; j++) {
            relatedProducts.push(this.typesList[i].products[j]);
          }
        }
      }
      this.$emit("changed", relatedProducts);
    },
  },
};
</script>

<style>
.bibliorelatedproductsedit {
  min-height: 400px;
}
.bibliorelatedproductsedit-add {
  width: 220px;
  margin-left: auto;
  margin-right: 0;
}
.bibliorelatedproductsedit-item-container {
  margin-top: -15px;
}
.bibliorelatedproductsedit-item:first-child hr {
  display: none;
}
</style>
