<template>
  <b-card id="productCardSuppliersAndPrices">
    <template #header>
      <a id="suppliers-and-prices" />
      <h5 class="d-inline-block">Suppliers and Prices</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-supply/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <dl class="row">
      <template v-if="suppliers && suppliers.length > 0">
        <template v-for="(supplier, index) in suppliers">
          <dt
            :key="'suppplier-dt-' + index"
            class="col-sm-3 font-weight-bold text-secondary"
          >
            Supplier
          </dt>
          <dd :key="'suppplier-dd-' + index" class="col-sm-9">
            {{ supplier.getName }}
            <template v-if="supplier.getIdentifierSAN"
              >: SAN {{ supplier.getIdentifierSAN }}</template
            >
            <template v-if="supplier.getIdentifierGLN"
              >: GLN {{ supplier.getIdentifierGLN }}</template
            >
            <template v-if="supplier.getRoleName">
              ({{ supplier.getRoleName }})</template
            >
          </dd>
          <template v-if="supplier.getAvailabilityName">
            <dt
              :key="'suppplier-dt1-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Availability
            </dt>
            <dd :key="'suppplier-dd1-' + index" class="col-sm-9">
              {{ supplier.getAvailabilityName }}
            </dd>
          </template>
          <template v-if="supplier.getAvailabilityDate">
            <dt
              :key="'suppplier-dt2-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Availability Date
            </dt>
            <dd :key="'suppplier-dd2-' + index" class="col-sm-9">
              {{
                supplier.getAvailabilityDate
                  | parseDate(dateOptions.format, dateOptions)
              }}
            </dd>
          </template>
          <template v-if="supplier.getPrices">
            <dt
              :key="'suppplier-dt-3-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Price
            </dt>
            <dd :key="'suppplier-dd3-' + index" class="col-sm-9">
              <div
                v-for="(price, priceIndex) in supplier.getPrices"
                :key="priceIndex"
              >
                <template v-if="price.getCurrencyName">{{
                  price.getCurrencyName
                }}</template>
                <template v-if="price.getCurrency">
                  [{{ price.getCurrency }}]</template
                >
                <template v-if="price.getTotalAmount">
                  {{ price.getTotalAmount.toFixed(2) }}</template
                >
                -
                <template v-if="price.getTypeName">
                  {{ price.getTypeName }}</template
                >
                <template v-if="price.getTypeQualifierName"
                  >, {{ price.getTypeQualifierName }}</template
                >
                <template v-if="price.getTypeDescription"
                  >, {{ price.getTypeDescription }}</template
                >
                <template v-if="price.getStatus"
                  >, {{ price.getStatusName }} price</template
                >
                <template v-if="price.getUnitOfPricing == '01'"
                  >, Per Page</template
                >
                <div v-if="price.getRightsCountriesNames" class="pl-4">
                  Valid for:
                  <span
                    v-for="(
                      country, countryIndex
                    ) in price.getRightsCountriesNames"
                    :key="countryIndex"
                    >{{ country
                    }}<template
                      v-if="
                        countryIndex < price.getRightsCountriesNames.length - 1
                      "
                      >,
                    </template></span
                  >
                </div>
                <div v-if="price.getNoRightsCountriesNames" class="pl-4">
                  Valid for: Worldwide excluding
                  <span
                    v-for="(
                      country, countryIndex
                    ) in price.getNoRightsCountriesNames"
                    :key="countryIndex"
                    >{{ country
                    }}<template
                      v-if="
                        countryIndex <
                        price.getNoRightsCountriesNames.length - 1
                      "
                      >,
                    </template></span
                  >
                </div>
                <div
                  v-if="price.getEffectiveFromDate || price.getEffectiveToDate"
                  class="pl-4"
                >
                  Effective:
                  <template v-if="price.getEffectiveFromDate">{{
                    price.getEffectiveFromDate
                      | parseDate(dateOptions.format, dateOptions)
                  }}</template
                  ><template v-if="price.getEffectiveToDate">
                    until
                    {{
                      price.getEffectiveToDate
                        | parseDate(dateOptions.format, dateOptions)
                    }}</template
                  >
                </div>
                <div class="pl-4">
                  <template v-if="price.getTypeIncTax">
                    Tax:
                    <template v-if="price.getTaxTypeAName"
                      >{{ price.getTaxPercentA.toFixed(2) }}% ({{
                        price.getTaxTypeAName
                      }}) of {{ price.getTaxableAmountA.toFixed(2) }}</template
                    >
                    <template
                      v-if="price.getTaxTypeAName && price.getTaxTypeBName"
                      >,
                    </template>
                    <template v-if="price.getTaxTypeBName"
                      >{{ price.getTaxPercentB.toFixed(2) }}% ({{
                        price.getTaxTypeBName
                      }}) of {{ price.getTaxableAmountB.toFixed(2) }}</template
                    >
                  </template>
                  <template v-else> No tax </template>
                </div>
                <div v-if="price.getClassOfTrade" class="pl-4">
                  Class of Trade: {{ price.getClassOfTrade }}
                </div>
                <template
                  v-if="
                    price.getDiscountCodes && price.getDiscountCodes.length > 0
                  "
                >
                  <div
                    v-for="(discount, discountIndex) in price.getDiscountCodes"
                    :key="discountIndex"
                    class="pl-4"
                  >
                    Discount Code: {{ discount.getValue }} ({{
                      discount.getSchemeName
                    }})
                  </div>
                </template>
              </div>
            </dd>
          </template>
          <template v-if="supplier.getOrderTime">
            <dt
              :key="'suppplier-dt-4-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Order Time
            </dt>
            <dd :key="'suppplier-dd4-' + index" class="col-sm-9">
              {{ supplier.getOrderTime }}
            </dd>
          </template>
          <template v-if="supplier.getOnSaleDate">
            <dt
              :key="'suppplier-dt-5-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              On Sale Date
            </dt>
            <dd :key="'suppplier-dd5-' + index" class="col-sm-9">
              {{
                supplier.getOnSaleDate
                  | parseDate(dateOptions.format, dateOptions)
              }}
            </dd>
          </template>
          <template v-if="supplier.getPackQuantity">
            <dt
              :key="'suppplier-dt-6-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Pack Quantity
            </dt>
            <dd :key="'suppplier-dd6-' + index" class="col-sm-9">
              {{ supplier.getPackQuantity }}
            </dd>
          </template>
          <template v-if="supplier.getUnpricedReason">
            <dt
              :key="'suppplier-dt-7-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Unpriced Reason
            </dt>
            <dd :key="'suppplier-dd7-' + index" class="col-sm-9">
              {{ supplier.getUnpricedReasonName }}
            </dd>
          </template>
          <template v-if="supplier.getReturnsConditionValue == 'Y'">
            <dt
              :key="'suppplier-dt-8-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Returns Condition
            </dt>
            <dd :key="'suppplier-dd8-' + index" class="col-sm-9">
              {{ supplier.getReturnsConditionValueName }}
            </dd>
          </template>
          <template v-if="supplier.getLastReturnsDate">
            <dt
              :key="'suppplier-dt-9-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Last Returns Date
            </dt>
            <dd :key="'suppplier-dd9-' + index" class="col-sm-9">
              {{
                supplier.getLastReturnsDate
                  | parseDate(dateOptions.format, dateOptions)
              }}
            </dd>
          </template>
          <template
            v-if="
              supplier.getStockOnHand ||
              supplier.getStockOnOrder ||
              supplier.getStockOnOrderCommittedBackorders
            "
          >
            <dt
              :key="'suppplier-dt-stockheading-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Stock
            </dt>
            <dd
              :key="'suppplier-dd-stockheading-' + index"
              class="col-sm-9"
            ></dd>
          </template>
          <template v-if="supplier.getStockOnHand">
            <dt
              :key="'suppplier-dt-10-' + index"
              class="col-sm-3 pl-5 font-weight-bold text-secondary"
            >
              Available
            </dt>
            <dd :key="'suppplier-dd10-' + index" class="col-sm-9">
              {{ supplier.getStockOnHand }}
            </dd>
          </template>
          <template v-if="supplier.getStockOnOrder">
            <dt
              :key="'suppplier-dt-11-' + index"
              class="col-sm-3 pl-5 font-weight-bold text-secondary"
            >
              On Order
            </dt>
            <dd :key="'suppplier-dd11-' + index" class="col-sm-9">
              {{ supplier.getStockOnOrder }}
            </dd>
          </template>
          <template v-if="supplier.getStockOnOrderCommittedBackorders">
            <dt
              :key="'suppplier-dt-12-' + index"
              class="col-sm-3 pl-5 font-weight-bold text-secondary"
            >
              Commited Backorders
            </dt>
            <dd :key="'suppplier-dd12-' + index" class="col-sm-9">
              {{ supplier.getStockOnOrderCommittedBackorders }}
            </dd>
          </template>
          <template v-if="supplier.getExpectedWarehouseDate">
            <dt
              :key="'suppplier-dt-13-' + index"
              class="col-sm-3 pl-4 font-weight-bold text-secondary"
            >
              Expected Warehouse Date
            </dt>
            <dd :key="'suppplier-dd13-' + index" class="col-sm-9">
              {{
                supplier.getExpectedWarehouseDate
                  | parseDate(dateOptions.format, dateOptions)
              }}
            </dd>
          </template>
        </template>
      </template>
      <template v-else-if="showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">Supplier</dt>
        <dd class="col-sm-9">—</dd>
      </template>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list
 * of information relating to a products suppliers and
 * prices
 */
import ParseDate from "../../mixins/parseDate.js";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "SuppliersAndPrices",

  components: {
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [ParseDate, DisplaySetting],

  props: {
    /**
     * The id of the product
     */
    productId: {
      type: [Number, String],
      default: null,
    },

    /**
     * Product data
     *
     * The data passed in must be an assosiative array (object in JS) of product data.
     * The keys of the array must match those defined in Booksonixsuppliers.php
     * As returned from BooksonixProduct::getSuppliers
     */
    suppliers: {
      type: Array,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
