<template>
  <div>
    <div class="row">
      <div class="col-7 mt-2">
        <input
          :id="controlsIdPrefix"
          v-model="scheduleEnabled"
          type="checkbox"
          @change="calcNextRunDate"
        />
        &nbsp;
        <label :for="controlsIdPrefix">
          Automatically disseminate {{ which }} records and images at the
          following frequency
        </label>
      </div>
      <div v-if="useNextRunOrig" class="col-5 mt-2">
        Next Scheduled:
        <template v-if="scheduleEnabled">
          {{ nextRunDateOrigFmt }}
          &emsp;
          <a href="#" @click="doUseNextRunCalc">
            <i
              class="fa fa-calculator"
              title="Recalculate the next scheduled date"
            />
          </a>
        </template>
        <template v-else>&mdash;</template>
      </div>
      <div v-else class="col-5 mt-2">
        Next Scheduled:
        {{ nextRunDateCalcFmt ? nextRunDateCalcFmt : "\u2014" }}
        <i v-if="nextRunDateOrigFmt && nextRunDateCalcFmt">
          &emsp;(currently
          {{ nextRunDateOrigFmt }}
          <a href="#" @click="doUseNextRunOrig">
            <i
              class="fa fa-reply"
              title="Use the current next scheduled date"
            />
          </a>
          )
        </i>
      </div>
    </div>
    <div class="row">
      <div class="col-7">
        &emsp;&emsp;
        <select v-model="scheduleType" size="1" @change="calcNextRunDate">
          <option value="DAILY">Daily</option>
          <option value="WEEKLY">Weekly</option>
          <option value="MONTHLY">Monthly</option>
        </select>
        <template v-if="scheduleType == 'DAILY'">
          every
          <select
            v-model="scheduleDailyFreq"
            size="1"
            @change="calcNextRunDate"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
          day(s)
        </template>
        <template v-else-if="scheduleType == 'WEEKLY'">
          every
          <select
            v-model="scheduleWeeklyFreq"
            size="1"
            @change="calcNextRunDate"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="6">6</option>
            <option value="8">8</option>
          </select>
          week(s) on
          <select
            v-model="scheduleWeeklyWkday"
            size="1"
            @change="calcNextRunDate"
          >
            <option value="MON">Monday</option>
            <option value="TUE">Tuesday</option>
            <option value="WED">Wednesday</option>
            <option value="THU">Thurday</option>
            <option value="FRI">Friday</option>
            <option value="SAT">Saturday</option>
            <option value="SUN">Sunday</option>
          </select>
        </template>
        <template v-else-if="scheduleType == 'MONTHLY'">
          every
          <select
            v-model="scheduleMonthlyFreq"
            size="1"
            @change="calcNextRunDate"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="6">6</option>
            <option value="8">8</option>
            <option value="12">12</option>
          </select>
          month(s) on
          <select
            v-if="scheduleMonthlyWkday != ''"
            v-model="scheduleMonthlyWeek"
            size="1"
            @change="calcNextRunDate"
          >
            <option value="1">1st</option>
            <option value="2">2nd</option>
            <option value="3">3rd</option>
            <option value="4">4th</option>
            <option value="5">Last</option>
          </select>
          <select
            v-else
            v-model="scheduleMonthlyMday"
            size="1"
            @change="calcNextRunDate"
          >
            <option value="1">1st</option>
            <option value="2">2nd</option>
            <option value="3">3rd</option>
            <option value="4">4th</option>
            <option value="5">5th</option>
            <option value="6">6th</option>
            <option value="7">7th</option>
            <option value="8">8th</option>
            <option value="9">9th</option>
            <option value="10">10th</option>
            <option value="11">11th</option>
            <option value="12">12th</option>
            <option value="13">13th</option>
            <option value="14">14th</option>
            <option value="15">15th</option>
            <option value="16">16th</option>
            <option value="17">17th</option>
            <option value="18">18th</option>
            <option value="19">19th</option>
            <option value="20">20th</option>
            <option value="21">21st</option>
            <option value="22">22nd</option>
            <option value="23">23rf</option>
            <option value="24">24th</option>
            <option value="25">25th</option>
            <option value="26">26th</option>
            <option value="27">27th</option>
            <option value="28">28th</option>
            <option value="29">29th</option>
            <option value="30">30th</option>
            <option value="31">31st</option>
          </select>
          <select
            v-model="scheduleMonthlyWkday"
            size="1"
            @change="calcNextRunDate"
          >
            <option value="">Day</option>
            <option value="MON">Monday</option>
            <option value="TUE">Tuesday</option>
            <option value="WED">Wednesday</option>
            <option value="THU">Thurday</option>
            <option value="FRI">Friday</option>
            <option value="SAT">Saturday</option>
            <option value="SUN">Sunday</option>
          </select>
        </template>
      </div>
      <div class="col-5">Last Scheduled: {{ lastRunDateFmt }}</div>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings feed schedule component
 */
export default {
  name: "FeedSchedule",

  props: {
    /**
     * Controls id prefix
     */
    controlsIdPrefix: {
      type: String,
      required: true,
    },

    /**
     * Which records is this schedule for
     */
    which: {
      type: String,
      required: true,
    },

    /**
     * Schedule enabled
     */
    enabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Schedule settings
     */
    schedule: {
      type: Object,
      default: null,
    },

    /**
     * Next run date
     */
    nextRun: {
      type: String,
      default: null,
    },

    /**
     * Last run date
     */
    lastRun: {
      type: String,
      default: null,
    },
  },

  data: function () {
    let dataObject = {
      scheduleEnabled: this.enabled,
      scheduleType: this.schedule ? this.schedule.type : "WEEKLY",
      scheduleDailyFreq: "1",
      scheduleWeeklyFreq: "1",
      scheduleWeeklyWkday: "MON",
      scheduleMonthlyFreq: "1",
      scheduleMonthlyWkday: "MON",
      scheduleMonthlyWeek: "1",
      scheduleMonthlyMday: "1",
      nextRunDateCalcYMD: null,
      nextRunDateCalcFmt: null,
      nextRunDateOrigYMD: null,
      nextRunDateOrigFmt: null,
      useNextRunOrig: false,
      lastRunDateFmt: "\u2014",
      lastRunDateTS: null,
      tomorrowTS: 0,
      modified: false,
    };
    if (this.schedule) {
      switch (dataObject.scheduleType) {
        case "DAILY":
          if (this.schedule.freq) {
            dataObject.scheduleDailyFreq = this.schedule.freq;
          }
          break;
        case "WEEKLY":
          if (this.schedule.freq) {
            dataObject.scheduleWeeklyFreq = this.schedule.freq;
          }
          if (this.schedule.on) {
            dataObject.scheduleWeeklyWkday = this.schedule.on;
          }
          break;
        case "MONTHLY":
          if (this.schedule.freq) {
            dataObject.scheduleMonthlyFreq = this.schedule.freq;
          }
          if (this.schedule.on) {
            if (this.schedule.on.search(/^[A-Z]{3}-[1-5]$/) == 0) {
              dataObject.scheduleMonthlyWkday = this.schedule.on.substring(
                0,
                3
              );
              dataObject.scheduleMonthlyWeek = this.schedule.on.substring(4);
            } else {
              dataObject.scheduleMonthlyWkday = "";
              dataObject.scheduleMonthlyMday = this.schedule.on;
            }
          }
          break;
      }
    }
    if (this.nextRun) {
      let nextRunDate = new Date(this.nextRun + "T00:00:00Z");
      dataObject.nextRunDateOrigYMD = nextRunDate
        .toISOString()
        .substring(0, 10);
      dataObject.nextRunDateOrigFmt = nextRunDate.toLocaleDateString("en-GB", {
        dateStyle: "long",
      });
      dataObject.nextRunDateCalcYMD = dataObject.nextRunDateOrigYMD;
      dataObject.nextRunDateCalcFmt = dataObject.nextRunDateOrigFmt;
    }
    if (this.lastRun) {
      let lastRunDate = new Date(this.lastRun);
      dataObject.lastRunDateFmt = lastRunDate.toLocaleString("en-GB", {
        dateStyle: "long",
        timeStyle: "short",
      });
      dataObject.lastRunDateTS = Date.UTC(
        lastRunDate.getUTCFullYear(),
        lastRunDate.getUTCMonth(),
        lastRunDate.getUTCDate()
      );
    }

    let now = new Date();
    now.setUTCDate(now.getUTCDate() + 1);
    dataObject.tomorrowTS = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate()
    );

    return dataObject;
  },

  mounted: function () {
    this.calcNextRunDate(false);
  },

  methods: {
    /**
     * Calculate the next run date
     *
     * Code from BsnxExtSupply.dpr::Utils.pas::CalculateNextRun()
     */
    calcNextRunDate: function (callOnChange = true) {
      // calculate the new date
      let nextRun;

      // enabled?
      if (this.scheduleEnabled) {
        if (this.scheduleType == "DAILY") {
          // daily
          // calculate the next run date starting with the last run date,
          // or tomorrow if none
          if (this.lastRun) {
            let freq = parseInt(this.scheduleDailyFreq, 10);
            if (freq != 1) {
              // every n days
              nextRun = new Date(this.lastRunDateTS);
              while (nextRun.getTime() < this.tomorrowTS) {
                nextRun.setUTCDate(nextRun.getUTCDate() + freq);
              } // while
            } else {
              // daily
              nextRun = new Date(this.tomorrowTS);
            }
          } else {
            // use tomorrow as the next run date
            nextRun = new Date(this.tomorrowTS);
          }
        } else if (this.scheduleType == "WEEKLY") {
          // weekly
          // calculate the next run date starting with the last run date,
          // or tomorrow if none
          if (this.lastRun) {
            let freq = 7 * parseInt(this.scheduleWeeklyFreq, 10);
            nextRun = new Date(this.lastRunDateTS);
            while (nextRun.getTime() < this.tomorrowTS) {
              nextRun.setUTCDate(nextRun.getUTCDate() + freq);
            } // while
          } else {
            // use tomorrow as the next run date
            nextRun = new Date(this.tomorrowTS);
          }

          // adjust for the required day of the week
          let weekDay = this.parseWeekday(this.scheduleWeeklyWkday);
          let adjust = weekDay - nextRun.getUTCDay();
          if (adjust != 0) {
            if (adjust > 0) {
              adjust -= 7;
            }
            nextRun.setUTCDate(nextRun.getUTCDate() + adjust);
            if (nextRun.getTime() < this.tomorrowTS) {
              nextRun.setUTCDate(nextRun.getUTCDate() + 7);
            }
          }
        } else if (
          this.scheduleType == "MONTHLY" &&
          this.scheduleMonthlyWkday == ""
        ) {
          // monthly with month-day
          // calculate the next run date starting with the last run date,
          // or tomorrow if none
          if (this.lastRun) {
            let freq = parseInt(this.scheduleMonthlyFreq, 10);
            nextRun = new Date(this.lastRunDateTS);
            while (nextRun.getTime() < this.tomorrowTS) {
              nextRun.setUTCMonth(nextRun.getUTCMonth() + freq);
            } // while
          } else {
            // use tomorrow as the next run date
            nextRun = new Date(this.tomorrowTS);
          }

          // adjust for the required day of the month
          let monthDay = parseInt(this.scheduleMonthlyMday, 10);
          for (;;) {
            nextRun.setUTCDate(monthDay);
            if (nextRun.getUTCDate() < monthDay) {
              // beyound the end of the month so adjusted to next month,
              // re-adjust to last day of previous month
              nextRun.setUTCDate(0);
            }

            // do we have a future date?
            if (nextRun.getTime() >= this.tomorrowTS) {
              break;
            }

            // try with the next month
            nextRun.setUTCMonth(nextRun.getUTCMonth() + 1);
          } // for
        } else if (
          this.scheduleType == "MONTHLY" &&
          this.scheduleMonthlyWkday != ""
        ) {
          // monthly with week-day
          // calculate the next run date starting with the last run date,
          // or tomorrow if none
          if (this.lastRun) {
            let freq = parseInt(this.scheduleMonthlyFreq, 10);
            nextRun = new Date(this.lastRunDateTS);
            while (nextRun.getTime() < this.tomorrowTS) {
              nextRun.setUTCMonth(nextRun.getUTCMonth() + freq);
            } // while
          } else {
            // use tomorrow as the next run date
            nextRun = new Date(this.tomorrowTS);
          }

          // adjust for the required day of the week and week of the month
          let weekDay = this.parseWeekday(this.scheduleMonthlyWkday);
          let week = parseInt(this.scheduleMonthlyWeek, 10);

          for (let i = 0; i < 2; i++) {
            // get the weekday for the first day of the month
            let weekDay1 = new Date(
              Date.UTC(nextRun.getUTCFullYear(), nextRun.getUTCMonth(), 1)
            );
            weekDay1 = weekDay1.getDay();

            // calculate the month-day for the Nth weekday
            let monthDay;
            if (weekDay < weekDay1) {
              monthDay = 7 * week + 1 + weekDay - weekDay1;
            } else {
              monthDay = 7 * week - 6 + weekDay - weekDay1;
            }
            nextRun.setUTCDate(monthDay);
            if (nextRun.getUTCDate() < monthDay) {
              // beyound the end of the month so adjusted to next month,
              // re-adjust back by 1 week
              nextRun.setUTCDate(nextRun.getUTCDate() - 7);
            }

            // do we have a future date?
            if (nextRun.getTime() >= this.tomorrowTS) {
              break;
            }

            // try with the next month
            nextRun.setUTCMonth(nextRun.getUTCMonth() + 1);
          } // for
        } else {
          return;
        }

        // set the new formatted date
        this.nextRunDateCalcYMD = nextRun.toISOString().substring(0, 10);
        this.nextRunDateCalcFmt = nextRun.toLocaleDateString("en-GB", {
          dateStyle: "long",
        });
      } else {
        // schedule not enabled
        this.nextRunDateCalcYMD = null;
        this.nextRunDateCalcFmt = null;
      }

      // next run date has changed
      let newSchedule = {
        type: this.scheduleType,
        freq: null,
      };
      if (this.scheduleType == "DAILY") {
        newSchedule.freq = parseInt(this.scheduleDailyFreq, 10);
      } else if (this.scheduleType == "WEEKLY") {
        newSchedule.freq = parseInt(this.scheduleWeeklyFreq, 10);
        newSchedule.on = this.scheduleWeeklyWkday;
      } else if (this.scheduleType == "MONTHLY") {
        newSchedule.freq = parseInt(this.scheduleMonthlyFreq, 10);
        if (this.scheduleMonthlyWkday != "") {
          newSchedule.on =
            this.scheduleMonthlyWkday + "-" + this.scheduleMonthlyWeek;
        } else {
          newSchedule.on = this.scheduleMonthlyMday;
        }
      }

      if (callOnChange) {
        if (this.scheduleEnabled) {
          this.$emit(
            "change",
            true,
            newSchedule,
            this.useNextRunOrig
              ? this.nextRunDateOrigYMD
              : this.nextRunDateCalcYMD
          );
        } else {
          this.$emit("change", false, newSchedule, null);
        }
      }
    },

    /**
     * Parse weekday
     */
    parseWeekday: function (weekday) {
      switch (weekday) {
        case "SUN":
          return 0;
        case "MON":
          return 1;
        case "TUE":
          return 2;
        case "WED":
          return 3;
        case "THU":
          return 4;
        case "FRI":
          return 5;
        case "SAT":
          return 6;
        default:
          return NaN;
      }
    },

    /**
     * Use original next run date
     */
    doUseNextRunOrig: function () {
      this.useNextRunOrig = true;
    },

    /**
     * Use calculated next run date
     */
    doUseNextRunCalc: function () {
      this.useNextRunOrig = false;
    },
  },
};
</script>
