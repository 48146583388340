<template>
  <component
    :is="element"
    :key="tag.id"
    :name="'tag' + tag.id"
    :type="element == 'button' ? 'submit' : null"
    :href="element == 'a' ? href : null"
    class="quicksearchtag"
    :class="tagClasses"
    :style="tagStyles"
  >
    <i
      :key="tag.id + 'i'"
      :class="tag.level == 'GLOBAL' ? 'fa fa-globe' : 'fa fa-user'"
    ></i>
    {{ tag.name }}
  </component>
</template>

<script>
/**
 * Display a quick search tag button
 */

export default {
  name: "QuickSearchTag",

  props: {
    /**
     * The tag object which has the properties 'id' (integer), 'name' (string), 'level' (string), 'colour' (integer|null), 'bkcolour' (integer|null)
     */
    tag: {
      type: Object,
      required: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Position / Page number
     */
    position: {
      type: Number,
      default: null,
    },

    /**
     * The type of element to display
     *
     * @values button, a, div
     */
    element: {
      type: String,
      default: "button",
    },

    /**
     * The type of record for which the tags are displaying
     *
     * @options product, contact
     */
    recordType: {
      type: String,
      default: "product",
    },
  },

  computed: {
    tagClasses: function () {
      return {
        btn: this.element != "div" ? true : false,
        ["tags_colour" + this.tag.colour]:
          this.tag.colour !== null && this.tag.bkcolour === null ? true : false,
      };
    },
    tagStyles: function () {
      if (!this.tag.bkcolour) {
        return null;
      }
      let style = {
        "background-color":
          (!this.tag.bkcolour.toString(16).includes("#") ? "#" : "") +
          this.tag.bkcolour.toString(16).padStart(6, "0"),
      };
      if (this.tag.colour != null) {
        style.color =
          (!this.tag.colour.toString(16).includes("#") ? "#" : "") +
          this.tag.colour.toString(16).padStart(6, "0");
      }
      return style;
    },
    href: function () {
      if (this.searchId && this.position) {
        return (
          this.$baseUrl +
          "search/link/" +
          this.searchForm +
          "/" +
          this.searchId +
          "/" +
          this.position +
          "/detailed?TAGID=" +
          this.tag.id
        );
      }
      return (
        this.$baseUrl +
        "search/quick/" +
        this.searchForm +
        "?TAGID=" +
        this.tag.id
      );
    },
    searchForm: function () {
      if (this.recordType == "contact") {
        return "contacts";
      }
      return "biblio";
    },
  },
};
</script>

<style>
button.quicksearchtag {
  padding: 4px 8px;
  color: #666;
  background-color: #ddd;
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
