var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"contactCardRoyalties"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('a',{attrs:{"id":"royalties"}}),_c('h5',{staticClass:"d-inline-block"},[_vm._v("Royalties Payee Information")]),(_vm.$permitted('contacts/edit') && _vm.allowEdit)?_c('edit-icon',{staticClass:"ml-2 d-print-none",attrs:{"link":_vm.$baseUrl +
        '/contacts/edit/' +
        _vm.contactId +
        '?current_tab=7' +
        (_vm.returnTo ? '&return=' + _vm.returnTo : '')}}):_vm._e(),_c('contact-quick-jump-dropdown',{staticClass:"float-right m-0",attrs:{"contact-id":_vm.contactId}})]},proxy:true}])},[(_vm.contact)?_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4 font-weight-bold text-secondary"},[_vm._v(" Statement Currency ")]),_c('dd',{staticClass:"col-sm-8"},[_vm._v(" "+_vm._s(_vm._f("valueOrPlaceholder")(_vm.contact.PayeeStatementCurrency))+" ")]),_c('dt',{staticClass:"col-sm-4 font-weight-bold text-secondary"},[_vm._v("Minimum Payout")]),_c('dd',{staticClass:"col-sm-8"},[_vm._v(" "+_vm._s(_vm._f("valueOrPlaceholder")(_vm.contact.PayeeMinPayout))+" ")]),_c('dt',{staticClass:"col-sm-4 font-weight-bold text-secondary"},[_vm._v(" Minimum Payout in Statement Currency ")]),_c('dd',{staticClass:"col-sm-8"},[(_vm.contact.PayeeMinPayoutInStatementCurrency == 'Y')?[_vm._v("Yes")]:_vm._e(),(_vm.contact.PayeeMinPayoutInStatementCurrency == 'N')?[_vm._v("No")]:[_vm._v(" "+_vm._s(_vm._f("valueOrPlaceholder")(_vm.contact.PayeeMinPayoutInStatementCurrency))+" ")]],2),_c('dt',{staticClass:"col-sm-4 font-weight-bold text-secondary"},[_vm._v("Accounting Code")]),_c('dd',{staticClass:"col-sm-8"},[_vm._v(" "+_vm._s(_vm._f("valueOrPlaceholder")(_vm.contact.PayeeAccountingCode))+" ")]),_c('dt',{staticClass:"col-sm-4 font-weight-bold text-secondary"},[_vm._v("VAT Percentage")]),_c('dd',{staticClass:"col-sm-8"},[(
          _vm.contact.PayeeTaxPercent !== null && _vm.contact.PayeeTaxPercent >= 0
        )?[_vm._v(" "+_vm._s(_vm.contact.PayeeTaxPercent.toFixed(2))+"% ")]:[_vm._v("—")]],2),_c('dt',{staticClass:"col-sm-4 font-weight-bold text-secondary"},[_vm._v("VAT Number")]),_c('dd',{staticClass:"col-sm-8"},[_vm._v(" "+_vm._s(_vm._f("valueOrPlaceholder")(_vm.contact.PayeeVatNumber))+" ")])]):_vm._e(),_c('div',{staticClass:"row"},_vm._l((_vm.royaltiesData),function(royaltyProduct,index){return _c('div',{key:index,staticClass:"col-12"},[_c('royalties-product-descriptive-list-card',{attrs:{"product":royaltyProduct.product,"out-of-royalties":royaltyProduct.royalties_prod_contr &&
          royaltyProduct.royalties_prod_contr.out_of_royalties == 'Y',"payees":royaltyProduct.payees,"channels":royaltyProduct.channels}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }