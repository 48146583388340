var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.auditFirstYear != 0)?_c('info-box',[_vm._v(" Audit entries older than "+_vm._s(_vm.auditFirstYear)+" are automatically archived. Please contact BooksoniX support if you need to inspect history older than that. ")]):_vm._e(),_c('base-table',{ref:"auditTable",attrs:{"filters-enabled":true,"columns":_vm.columns,"data-source":_vm.dataSource,"rows-per-page":50},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var record = ref.record;
return [_c('span',{class:{ 'text-primary': record.hidden.hfonly }},[_vm._v(" "+_vm._s(record.type)+" ")])]}},{key:"description",fn:function(ref){
var record = ref.record;
return [_c('span',{class:{ 'text-primary': record.hidden.hfonly }},[_vm._v(" "+_vm._s(record.description)+" ")])]}},{key:"user",fn:function(ref){
var record = ref.record;
return [_c('span',{class:{ 'text-primary': record.hidden.hfonly }},[(record.user === 'BooksoniX')?_c('i',[_vm._v("BooksoniX")]):[_vm._v(_vm._s(record.user))]],2)]}},{key:"entry_date",fn:function(ref){
var record = ref.record;
return [_c('span',{class:{ 'text-primary': record.hidden.hfonly }},[_vm._v(_vm._s(_vm._f("parseDate")(record.entry_date,_vm.dateOptions.format, _vm.dateOptions)))])]}},{key:"time",fn:function(ref){
var record = ref.record;
return [_c('span',{class:{ 'text-primary': record.hidden.hfonly }},[_vm._v(_vm._s(_vm._f("parseDate")(record.entry_date,"HH:mm:ss", null, true)))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }