<template>
  <div class="row">
    <div class="col-12">
      <h6 style="display: inline">
        <slot name="title">{{ title }}</slot>
      </h6>
      <base-button
        v-if="hasDescription"
        class="ml-3"
        data-toggle="collapse"
        data-target="#collapseMoreInfo"
        aria-expanded="false"
        aria-controls="collapseMoreInfo"
        >More info</base-button
      >
    </div>
    <div v-if="hasDescription" class="col-12">
      <div id="collapseMoreInfo" class="collapse">
        <ui-well v-if="!!this.$slots.description || description" class="mt-2">
          <!-- @slot Description of the report, displayed in a well -->
          <slot name="description">
            {{ description }}
          </slot>
        </ui-well>
        <!-- @slot Additional description slot for displaying a description outside of the well -->
        <slot name="descriptionAdditional"></slot>
      </div>
    </div>
    <div class="col-12">
      <!-- @slot Content of the report -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Well from "../../ui/Well.vue";
/**
 * Contains the layout and shared logic
 * between report pages
 */

export default {
  name: "Report",

  components: {
    "ui-well": Well,
  },

  props: {
    /**
     * Title of the report
     */
    title: {
      type: String,
      default: null,
    },

    /**
     * Description of the report
     */
    description: {
      type: String,
      default: null,
    },
  },

  computed: {
    hasDescription() {
      return (
        !!this.$slots.description ||
        !!this.$slots.descriptionAdditional ||
        !!this.description
      );
    },
  },
};
</script>

<style scoped></style>
