<template>
  <b-badge
    v-show="visibility"
    class="ml-1 product-summary-badge"
    :variant="productVisibilityVariant"
    ><slot>{{ productVisibilityText }}</slot></b-badge
  >
</template>

<script>
/**
 * Displays a colour coded badge which contains the product status
 *
 */
export default {
  name: "ProductStatusBadge",

  props: {
    visibility: {
      type: String,
      default: null,
    },
  },

  computed: {
    /**
     * Sets the badge variant based on the product
     * visibility
     */
    productVisibilityVariant: function () {
      switch (this.visibility) {
        case "P":
          return "primary";
        case "I":
          return "secondary";
        case "W":
          return "info";
        default:
          return "secondary";
      }
    },
    /**
     * Sets the text in the visibility badge based
     * on the products visibility
     */
    productVisibilityText: function () {
      switch (this.visibility) {
        case "P":
          return "Public";
        case "I":
          return "Internal";
        case "W":
          return "Own Website";
        default:
          return "-";
      }
    },
  },
};
</script>

<style></style>
