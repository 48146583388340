<template>
  <b-card v-if="!deleted" class="p-2 product-summary-card">
    <div class="row">
      <div class="col card-icon text-center">
        <i class="fa fa-facebook-square fa-5x"></i>
      </div>
      <div class="col pl-0 pr-2">
        <div class="row h-50">
          <div class="col">
            <div class="d-flex justify-content-between">
              <h4 class="mb-0">
                <b-link
                  :href="
                    $baseUrl +
                    'ftp-catalogue-feed/edit/' +
                    catalogue.mode +
                    '/' +
                    catalogue.id
                  "
                  >{{ catalogue.name }}</b-link
                >
              </h4>
              <div>
                <template
                  v-if="
                    fileExists &&
                    internalCatalogue.hasOwnProperty('included_records')
                  "
                >
                  <b-badge pill variant="light"
                    >{{ internalCatalogue.included_records }} Record<template
                      v-if="internalCatalogue.included_records != 1"
                      >s</template
                    ></b-badge
                  >
                </template>
                <span class="mx-2">|</span>
                <b-link
                  :disabled="!fileExists"
                  :href="
                    $baseUrl +
                    'ftp-catalogue-feed/edit/' +
                    catalogue.mode +
                    '/' +
                    catalogue.id
                  "
                  >Edit Config</b-link
                >
                <span class="mx-2">|</span>
                <b-link
                  :disabled="!fileExists"
                  :href="
                    $baseUrl +
                    '/ftp-catalogue-feed/edit-search/' +
                    catalogue.mode +
                    '/' +
                    catalogue.id
                  "
                  >Edit / View Search</b-link
                >
                <span class="mx-2">|</span>
                <b-link
                  :disabled="!fileExists"
                  :href="
                    $baseUrl +
                    '/ftp-catalogue-feed/download/' +
                    catalogue.mode +
                    '/' +
                    catalogue.id
                  "
                  download
                  >Download File</b-link
                >
                <span class="mx-2">|</span>
                <b-link
                  href="#"
                  :disabled="!fileExists"
                  jest-catalogue-generate
                  @click="generateClicked"
                  >Regenerate File</b-link
                >
                <span class="mx-2">|</span>
                <b-link
                  href="#"
                  class="text-danger"
                  :disabled="!fileExists"
                  jest-catalogue-delete
                  @click="deleteClicked"
                  >Delete</b-link
                >
                <span class="mx-2">|</span>
                <template v-if="fileError">
                  <span class="text-muted"
                    >There was an error generating the file</span
                  >
                </template>
                <template v-else>
                  <template v-if="fileExists">
                    <span class="text-muted"
                      >Last Updated:
                      {{
                        internalCatalogue.last_updated
                          | parseDate($userPreferences.dateFormat)
                      }}
                      {{
                        internalCatalogue.last_updated
                          | parseDate("HH:mm", null, true)
                      }}</span
                    ><info-icon
                      class="ml-1"
                      tip="ftpCatalogueLastUpdated"
                    ></info-icon>
                  </template>
                  <span v-if="!fileExists" class="text-muted"
                    >File is being generated
                    <i class="fa-solid fa-spinner fa-pulse"></i
                  ></span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row h-50">
          <div class="col">
            <b-input-group class="mt-2">
              <b-form-input
                :id="urlInputID"
                :ref="urlInputID"
                v-model="url"
                type="text"
                readonly
                jest-displayed-url
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  :id="copyBtnId"
                  v-b-tooltip.manual="'Copied'"
                  variant="outline-primary"
                  @click="$copyToClipboard(urlInputID, copyBtnId, url)"
                  ><i class="fa-solid fa-copy"></i> Copy to Clipboard</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
/**
 * Displays a card for a FTP catalogue. The card provides links to view details
 * of the catalogue and provides a copiable FTP URL.
 */

import InfoIcon from "../icons/InfoIcon.vue";
import copyToClipboard from "../../mixins/copyToClipboard.js";
import parseDate from "../../mixins/parseDate.js";
import { HTTP } from "../../http-common.js";

export default {
  name: "FtpCatalogueCard",

  components: {
    "info-icon": InfoIcon,
  },

  mixins: [copyToClipboard, parseDate],

  props: {
    /**
     * The configuration object for the catalogue
     *
     * @property {integer} id Catalogue ID
     * @property {string} name Catalogue name
     * @property {string} mode Catalogue mode e.g. 'facebook'
     * @property {string} last_updated Date string for when the file was last updated
     */
    catalogue: {
      type: Object,
      required: true,
    },

    /**
     * URL from which the FTP catalogue file should be accessed
     * excluding the file path
     */
    ftpUrl: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      deleted: false,
      fileError: false,
      internalCatalogue: this.catalogue,
    };
  },

  computed: {
    /** Unique ID for the URl input and btn (required so the $copyToClipboard
     *  method knows which URL to copy) */
    urlInputID: function () {
      return "ftp-catalogue-url-" + this.internalCatalogue.id;
    },
    copyBtnId: function () {
      return "ftp-catalogue-copy-" + this.internalCatalogue.id;
    },
    url: function () {
      return encodeURI(this.ftpUrl + "/" + this.internalCatalogue.file_name);
    },
    fileExists: function () {
      return this.internalCatalogue.last_updated !== null;
    },
  },

  watch: {
    catalogue(value) {
      this.internalCatalogue = value;
    },
  },

  mounted: function () {
    this.checkProgress();
  },

  methods: {
    generateClicked: function () {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you wish to generate this Facebook FTP Catalogue? The file will be generated daily automatically, but this option allows you to manually re-generate if required. Facebook settings should be checked to identify when the file will be re-read by Facebook.",
          {
            title: "Please Confirm",
            okVariant: "success",
            okTitle: "Confirm",
            cancelVariant: "danger",
            cancelTitle: "Cancel",
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed == true) {
            this.internalCatalogue.last_updated = null;
            try {
              const response = await HTTP.post(
                this.$baseUrl +
                  "ftp-catalogue-feed/generate/" +
                  this.internalCatalogue.mode +
                  "/" +
                  this.internalCatalogue.id
              );
              this.internalCatalogue.background_task_id = response.data.task_id;
              this.checkProgress();
            } catch (error) {
              this.$bvModal.msgBoxOk(
                "Facebook FTP Catalogue generation failed.",
                {
                  title: "Error",
                  okVariant: "danger",
                  okTitle: "OK",
                  centered: true,
                }
              );
              this.fileError = true;
            }
          }
        });
    },
    deleteClicked: function () {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you wish to delete this Facebook FTP Catalogue? The catalogue should be deleted from Facebook before confirming.",
          {
            title: "Please Confirm",
            okVariant: "success",
            okTitle: "Confirm",
            cancelVariant: "danger",
            cancelTitle: "Cancel",
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed == true) {
            try {
              const response = await HTTP.delete(
                this.$baseUrl +
                  "ftp-catalogue-feed/delete/" +
                  this.internalCatalogue.mode +
                  "/" +
                  this.internalCatalogue.id
              );
              if (response.data == "OK") {
                this.$bvModal.msgBoxOk(
                  "Facebook FTP Catalogue deleted. The FTP file has been removed and the URL will no longer be accessible.",
                  {
                    title: "Confirmation",
                    okVariant: "success",
                  }
                );
                this.deleted = true;
              }
            } catch (error) {
              let message = "";
              message = error.response.data;
              this.$bvModal.msgBoxOk(message, {
                title: "Could not delete Facebook FTP Catalogue",
                okVariant: "danger",
              });
            }
          }
        });
    },

    /**
     * If a background task is running for this catalogue then check the status of the task
     * and update the catalogue.last_updated if the task has finished otherwise set a timeout
     * of 5 seconds and check again
     */
    checkProgress: function () {
      if (this.internalCatalogue.background_task_id && !this.fileExists) {
        HTTP.get(
          this.$baseUrl +
            "ftp-catalogue-feed/task/" +
            this.internalCatalogue.mode +
            "/" +
            this.internalCatalogue.id +
            "/" +
            this.internalCatalogue.background_task_id
        )
          .then((response) => {
            if (response.data.task.finished && response.data.configuration) {
              this.internalCatalogue = response.data.configuration;
              this.internalCatalogue.last_updated = response.data.task.finished;
            } else {
              if (response.data.status == "Failed") {
                this.fileError = true;
              } else {
                setTimeout(this.checkProgress, 5000);
              }
            }
          })
          .catch(() => {
            this.fileError = true;
          });
      }
    },
  },
};
</script>

<style scoped>
.card-icon {
  -ms-flex: 0 0 108px;
  flex: 0 0 108px;
}
.form-control[readonly] {
  background-color: #fff;
}
a.disabled {
  pointer-events: none;
  color: #6c757d !important;
}
</style>
