var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageCount > 1 || _vm.simple)?_c('nav',{staticClass:"mt-2",attrs:{"aria-label":"Page navigation"}},[(!_vm.backendPagination)?_c('ul',{staticClass:"pagination"},[(!_vm.simple)?_c('li',{staticClass:"page-item",class:{ disabled: _vm.prevPage < 1 }},[_c('span',_vm._g({staticClass:"page-btn page-link",attrs:{"aria-label":"First"}},
          _vm.prevPage >= 1
            ? { click: function () { return _vm.pageUpdated(1); } }
            : { click: function ($event) { return $event.preventDefault(); } }
        ),[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("First")])])]):_vm._e(),_c('li',{staticClass:"page-item",class:{ disabled: _vm.prevPage < 1 }},[_c('span',_vm._g({staticClass:"page-btn page-link",attrs:{"aria-label":"Previous"}},
          _vm.prevPage >= 1
            ? { click: function () { return _vm.pageUpdated(_vm.currentPage - 1); } }
            : { click: function ($event) { return $event.preventDefault(); } }
        ),[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("«")])])]),(!_vm.simple)?_vm._l((_vm.pages),function(page,index){return _c('li',{key:index,staticClass:"page-item",class:{ active: page == _vm.currentPage }},[_c('span',{staticClass:"page-btn page-link",on:{"click":function($event){return _vm.pageUpdated(page)}}},[_vm._v(_vm._s(page))])])}):_vm._e(),_c('li',{staticClass:"page-item",class:{
        disabled:
          (_vm.nextPage > _vm.pageCount && !_vm.simple) || (_vm.simple && !_vm.hasNextPage),
      }},[_c('span',_vm._g({staticClass:"page-btn page-link",attrs:{"aria-label":"Next"}},
          _vm.nextPage <= _vm.pageCount || _vm.simple
            ? { click: function () { return _vm.pageUpdated(_vm.currentPage + 1); } }
            : { click: function ($event) { return $event.preventDefault(); } }
        ),[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("»")])])]),(!_vm.simple)?_c('li',{class:{ disabled: _vm.nextPage > _vm.pageCount }},[_c('span',_vm._g({staticClass:"page-btn page-link",attrs:{"aria-label":"Last"}},
          _vm.nextPage <= _vm.pageCount
            ? { click: function () { return _vm.pageUpdated(_vm.pageCount); } }
            : { click: function ($event) { return $event.preventDefault(); } }
        ),[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("Last")])])]):_vm._e()],2):_vm._e(),(_vm.backendPagination)?_c('ul',{staticClass:"pagination"},[(!_vm.simple)?_c('li',{staticClass:"page-item",class:{ disabled: _vm.prevPage < 1 }},[_c('a',{staticClass:"page-link",attrs:{"href":'?page=1',"aria-label":"First"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("First")])])]):_vm._e(),_c('li',{staticClass:"page-item",class:{ disabled: _vm.prevPage < 1 }},[_c('a',{staticClass:"page-link",attrs:{"href":'?page=' + _vm.prevPage,"aria-label":"Previous"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("«")])])]),(!_vm.simple)?_vm._l((_vm.pages),function(page,index){return _c('li',{key:index,staticClass:"page-item",class:{ active: page == _vm.currentPage }},[_c('a',{staticClass:"page-link",attrs:{"href":'?page=' + page}},[_vm._v(_vm._s(page))])])}):_vm._e(),_c('li',{staticClass:"page-item",class:{ disabled: _vm.nextPage > _vm.pageCount && !_vm.simple }},[_c('a',{staticClass:"page-link",attrs:{"href":'?page=' + _vm.nextPage,"aria-label":"Next"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("»")])])]),(!_vm.simple)?_c('li',{staticClass:"page-item",class:{ disabled: _vm.nextPage > _vm.pageCount }},[_c('a',{staticClass:"page-link",attrs:{"href":'?page=' + _vm.pageCount,"aria-label":"Next"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("Last")])])]):_vm._e()],2):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }