<template>
  <div>
    <div class="row biblioresourcesedit-header">
      <div class="col-3">
        <label>
          {{ header }}
        </label>
        <button
          type="button"
          title="Add another image/media file"
          class="btn btn-outline-primary btn-sm biblioresourcesedit-add"
          @click="addResource"
        >
          <i class="fa fa-plus"></i>
          Add
        </button>
      </div>
    </div>
    <div
      v-for="(resource, resourceIndex) in currentResources"
      :key="resourceIndex"
      class="biblioresourcesedit-item"
    >
      <hr />
      <div class="row">
        <div class="col-3">
          <label>Type</label>
          <br />
          <select
            :id="'biblioresourcesedit-type-' + resourceIndex"
            size="1"
            class="biblioresourcesedit-type"
            :required="resource.getLink != ''"
            @change="selectedType(resourceIndex, $event.target)"
          >
            <option value="">(unknown)</option>
            <option
              v-for="(typeInfo, typeIndex) in types"
              :key="typeIndex"
              :value="typeInfo.code"
              :selected="resource.getType21 == typeInfo.code"
            >
              {{ typeInfo.name }} {{ typeInfo.listSuffix }}
            </option>
          </select>
        </div>
        <div class="col-1 pl-0">
          <label>Link</label>
          <br />
          <select
            size="1"
            class="biblioresourcesedit-linktype"
            @change="selectedLinkType(resourceIndex, $event.target)"
          >
            <option value="06" :selected="resource.getLinkType == '06'">
              filename
            </option>
            <option value="02" :selected="resource.getLinkType != '06'">
              URL
            </option>
          </select>
        </div>
        <div class="col-5 pl-0">
          <label>&nbsp;</label>
          <br />
          <input
            :id="'biblioresourcesedit-link-' + resourceIndex"
            type="text"
            :value="resource.getLink"
            maxlength="1000"
            class="biblioresourcesedit-link"
            :pattern="
              resource.getLinkType == '06'
                ? '[^:*?\\x22\\x3c>\\x7C]*'
                : '(https?|ftps?|sftp):\/\/([^/@]+@)?[a-z0-9.-]{3,}\/.+'
            "
            @change="changedLink(resourceIndex, $event.target)"
            @blur="setFormatFromLink(resourceIndex, false, true)"
          />
          <a href="#" class="btn btn-link" @click="selectMedia(resourceIndex)">
            Select
          </a>
        </div>
        <div class="col-3">
          <label>Format</label>
          <br />
          <select
            size="1"
            class="biblioresourcesedit-format"
            @change="selectedFormat(resourceIndex, $event.target)"
          >
            <option value="">(unknown)</option>
            <option
              v-for="(formatInfo, formatIndex) in formats"
              :key="formatIndex"
              :value="formatInfo.code"
              :selected="resource.getFormat21 == formatInfo.code"
            >
              {{ formatInfo.name }} {{ formatInfo.listSuffix }}
            </option>
          </select>
          <input
            :id="'biblioresourcesedit-internal-' + resourceIndex"
            type="checkbox"
            value="I"
            :checked="resource.getInternal"
            class="biblioresourcesedit-internal"
            @click="changedInternal(resourceIndex, $event.target)"
          />
          <label
            :for="'biblioresourcesedit-internal-' + resourceIndex"
            class="biblioresourcesedit-internal"
          >
            Internal
            <span
              class="note-star"
              title="This field is internal and will not be disseminated"
            >
              **
            </span>
          </label>
        </div>
      </div>
      <div v-if="resource.showDetails" class="row biblioresourcesedit-details">
        <div class="col-6">
          <label>Caption</label>
          <br />
          <input
            type="text"
            :value="resource.getCaption"
            maxlength="300"
            @change="changedDetails(resourceIndex, 'Caption', $event.target)"
          />
        </div>
        <div class="col-6">
          <label>Credit</label>
          <br />
          <input
            type="text"
            :value="resource.getCredit"
            maxlength="300"
            @change="changedDetails(resourceIndex, 'Credit', $event.target)"
          />
        </div>
      </div>
      <div v-if="resource.showDetails" class="row biblioresourcesedit-details">
        <div class="col-6">
          <label>Copyright Holder</label>
          <br />
          <input
            type="text"
            :value="resource.getCopyrightHolder"
            maxlength="300"
            @change="
              changedDetails(resourceIndex, 'CopyrightHolder', $event.target)
            "
          />
        </div>
        <div class="col-6">
          <label>Alternate Text</label>
          <br />
          <input
            type="text"
            :value="resource.getAlternateText"
            maxlength="300"
            @change="
              changedDetails(resourceIndex, 'AlternateText', $event.target)
            "
          />
        </div>
      </div>
      <div class="row biblioresourcesedit-btns">
        <div class="col-12">
          <a
            href="#"
            class="btn btn-link"
            @click="toggleDetails(resourceIndex)"
          >
            {{ resource.showDetails ? "Hide Details" : "Show Details" }}
          </a>
          <a
            href="#"
            class="btn btn-link"
            @click="moveResourceUp(resourceIndex)"
          >
            Move Up
          </a>
          <a
            href="#"
            class="btn btn-link"
            @click="moveResourceDn(resourceIndex)"
          >
            Move Down
          </a>
          <a
            href="#"
            class="btn btn-link"
            @click="deleteResource(resourceIndex)"
          >
            Delete
          </a>
        </div>
      </div>
    </div>
    <media-select-modal
      ref="MediaBrowse"
      :in-modal="true"
      @selected="selectedMedia"
    ></media-select-modal>
  </div>
</template>

<script>
/**
 * Bibliographic resources edit container to edit resources (images and media files)
 *
 * Emits a 'changed' event including an array of resource objects (excluding empty entries)
 */
import MediaSelectModal from "../../pages/modals/MediaSelectModal.vue";

export default {
  name: "BiblioResourcesEdit",

  components: {
    "media-select-modal": MediaSelectModal,
  },

  props: {
    /**
     * Header text
     */
    header: {
      type: String,
      required: true,
    },

    /**
     * Resources to edit
     */
    resources: {
      type: Array,
      default: null,
    },

    /**
     * List of all resource types
     */
    types: {
      type: Array,
      required: true,
    },

    /**
     * List of all resource formats
     */
    formats: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    let resources = this.resources ? this.resources : [];
    let currentResources = [];
    for (let i = 0; i < resources.length; i++) {
      let showDetails =
        resources[i].getCaption != null ||
        resources[i].getCredit != null ||
        resources[i].getCopyrightHolder != null ||
        resources[i].getAlternateText != null;
      currentResources.push({
        ...resources[i],
        showDetails: showDetails,
      });
    }
    return {
      currentResources: currentResources,
    };
  },

  methods: {
    /**
     * Add a resource
     * Note: we don't notify of changes as we have added a resource entry with no type selected or filename/url entered
     */
    addResource: function () {
      this.currentResources.push({
        getType21: "",
        getLinkType: "06",
        getLink: "",
        getInternal: "",
        getFormat21: "",
        getCaption: null,
        getCredit: null,
        getCopyrightHolder: null,
        getAlternateText: null,
        showDetails: false,
      });
    },

    /**
     * Move a resource up
     */
    moveResourceUp: function (index) {
      if (index >= 1) {
        let resource = this.currentResources.splice(index, 1);
        this.currentResources.splice(index - 1, 0, resource[0]);
        this.notifyChanges();
      }
    },

    /**
     * Move a resource down
     */
    moveResourceDn: function (index) {
      if (index < this.currentResources.length - 1) {
        let resource = this.currentResources.splice(index, 1);
        this.currentResources.splice(index + 1, 0, resource[0]);
        this.notifyChanges();
      }
    },

    /**
     * Delete a resource
     */
    deleteResource: function (index) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure that you want to delete this image/media file ?"
        )
        .then((result) => {
          if (result) {
            this.currentResources.splice(index, 1);
            this.notifyChanges();
          }
        });
    },

    /**
     * A type has been selected
     */
    selectedType: function (index, control) {
      this.currentResources[index].getType21 =
        control.options[control.selectedIndex].value;
      this.notifyChanges();
    },

    /**
     * A link type has been selected
     */
    selectedLinkType: function (index, control) {
      this.currentResources[index].getLinkType =
        control.options[control.selectedIndex].value;
      this.notifyChanges();
    },

    /**
     * The link has been changed
     */
    changedLink: function (index, control) {
      this.currentResources[index].getLink = control.value;
      this.notifyChanges();
    },

    /**
     * Select a media file
     */
    selectMedia: function (index) {
      this.$refs.MediaBrowse.browse(
        this.currentResources[index].getLink,
        index
      );
    },

    /**
     * A media file has been selected
     */
    selectedMedia: function (filename, index) {
      this.currentResources[index].getLink = filename;
      this.setFormatFromLink(index, true, false);
      this.notifyChanges();
    },

    /**
     * A format has been selected
     */
    selectedFormat: function (index, control) {
      this.currentResources[index].getFormat21 =
        control.options[control.selectedIndex].value;
      this.notifyChanges();
    },

    /**
     * The internal state has been changed
     */
    changedInternal: function (index, control) {
      this.currentResources[index].getInternal = control.checked
        ? control.value
        : "";
      this.notifyChanges();
    },

    /**
     * A details field has been changed
     */
    changedDetails: function (index, field, control) {
      this.currentResources[index]["get" + field] = control.value;
      this.notifyChanges();
    },

    /**
     * Toggle the details
     */
    toggleDetails: function (index) {
      this.currentResources[index].showDetails =
        !this.currentResources[index].showDetails;
    },

    /**
     * Set the format based on the filename
     */
    setFormatFromLink: function (index, clearIfUnknown, notify) {
      let link = this.currentResources[index].getLink;
      let namepos = link.lastIndexOf("/");
      let extpos = link.lastIndexOf(".");
      let ext =
        extpos > namepos ? link.substring(extpos + 1).toLowerCase() : "";
      for (let i = 0; i < this.formats.length; i++) {
        for (let j = 0; j < this.formats[i].extensions.length; j++) {
          if (this.formats[i].extensions[j] == ext) {
            this.currentResources[index].getFormat21 = this.formats[i].code;
            if (notify) {
              this.notifyChanges();
            }
            return;
          }
        }
      }
      if (clearIfUnknown) {
        this.currentResources[index].getFormat21 = null;
        if (notify) {
          this.notifyChanges();
        }
      }
    },

    /**
     * notify of changed resources
     */
    notifyChanges: function () {
      // select resource entries with a type and link
      let resources = [];
      for (let i = 0; i < this.currentResources.length; i++) {
        if (
          this.currentResources[i].getType21 != "" &&
          this.currentResources[i].getLink != ""
        ) {
          resources.push(this.currentResources[i]);
        }
      }
      this.$emit("changed", resources);
    },
  },
};
</script>

<style>
.biblioresourcesedit-header {
  margin-bottom: 0.5rem;
}
.biblioresourcesedit-add {
  margin-left: 1rem;
}
.biblioresourcesedit-item hr {
  margin: 2px 0px;
}
.biblioresourcesedit-type,
.biblioresourcesedit-linktype {
  width: 100%;
}
.biblioresourcesedit-link {
  width: 85%;
}
.biblioresourcesedit-link[type="text"]:invalid {
  border: red solid 2px;
}
.biblioresourcesedit-format {
  width: 50%;
}
input.biblioresourcesedit-internal {
  margin-left: 1rem;
}
label.biblioresourcesedit-internal {
  padding-left: 0.5rem;
}
.biblioresourcesedit-details .col-6 {
  padding-left: 4rem;
}
.biblioresourcesedit-details input {
  width: 95%;
}
.biblioresourcesedit-btns {
  text-align: right;
}
</style>
