<template>
  <div>
    <div v-if="loading">
      <loading-spinner text="Loading onix data..."></loading-spinner>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div v-else v-html="onixHtml"></div>
    <!--eslint-enable-->
  </div>
</template>

<script>
/**
 * Displays a products data in Onix format
 */
import { HTTP } from "../../http-common.js";

export default {
  name: "OnixView",

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * The current user logon ID
     *
     * This is required for requests to the media manager API
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * The version of Onix to retrieve
     */
    version: {
      type: String,
      default: "2.1",
    },

    /**
     * Flag to set whether the data should load
     * when the component is mounted. This can be
     * disabled and the components load methods can
     * instead be called manually. This can be useful
     * if the component is used inside a tab, if the data
     * should not load until the tab is displayed.
     */
    loadOnMount: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      loading: false,
      onixHtml: null,
    };
  },

  computed: {
    onixUrlLocation: function () {
      if (this.version == "2.1") return "onix-html";
      else return "onix3-html";
    },
    onixUrl: function () {
      return (
        "/scripts/" +
        this.$appId +
        "/export/" +
        this.onixUrlLocation +
        "?L=" +
        this.logonId +
        "&S=RN|" +
        this.productId
      );
    },
  },

  mounted: function () {
    if (this.loadOnMount) {
      this.loadData();
    }
  },

  methods: {
    loadData: function () {
      if (this.onixHtml == null) {
        this.loading = true;
        this.getOnixHtml().then((response) => {
          this.onixHtml = response.data;
          this.loading = false;
        });
      }
    },
    /**
     * Retrieves the dissemination errors and warnings and
     * converts the returned XML to an object
     */
    getOnixHtml: async function () {
      return HTTP.get(this.onixUrl);
    },
  },
};
</script>

<style scoped>
div.onixview-root {
  font-family: courier new, courier, monospace;
  font-size: 11pt;
  padding: 4px 1px;
  line-height: 1.1;
}
span.onixview-elemn {
  color: darkred;
  font-weight: bold;
}
span.onixview-attrn {
  color: purple;
  font-weight: bold;
}
span.onixview-attrv {
  color: blue;
}
div.onixview-child {
  padding-left: 12pt;
}
span.onixview-text {
  color: dimgray;
  font-style: italic;
}
</style>
