<template>
  <thead>
    <tr v-if="columnsHaveGroups" class="biblio_resultslist_header">
      <th
        v-for="(column, columnNo) in columnInfo"
        :key="columnNo"
        class="text-nowrap"
        :style="{ width: column.width, minWidth: column.minWidth }"
      >
        {{ column.group }}
      </th>
    </tr>
    <tr class="biblio_resultslist_header">
      <th
        v-for="(column, columnNo) in columnInfo"
        :key="columnNo"
        :style="'width: ' + column.width"
      >
        <input
          v-if="columnInfo[columnNo].marker"
          type="checkbox"
          :checked="allRecordsMarked"
          @click="$emit('markedChange', null, $event.target.checked)"
        />
        <a
          v-if="column.sort"
          :href="columnSortUrl(column.sort)"
          class="navigation"
          :data-v-step="'biblio-searchresults-column' + columnNo"
        >
          {{ column.caption }}
          <i v-if="column.sorticon == 'A'" class="fa fa-sort-up"></i>
          <i v-else-if="column.sorticon == 'D'" class="fa fa-sort-down"></i>
          <i v-else class="fa fa-sort"></i>
        </a>
        <template v-else>
          {{ column.caption }}
        </template>
      </th>
    </tr>
  </thead>
</template>

<script>
/**
 * Display the Biblio Results List Header line
 */

export default {
  name: "ResultsListHeader",

  props: {
    /**
     * Search id
     */
    searchId: {
      type: Number,
      required: true,
    },

    /**
     * All records (on page) are marked
     */
    allRecordsMarked: {
      type: Boolean,
      default: false,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      required: true,
    },

    /**
     * Number of pages
     */
    pages: {
      type: Number,
      required: true,
    },

    /**
     * Current format (needed for column sorting)
     */
    format: {
      type: String,
      default: "",
    },

    /**
     * Column information (each entry in an object with the properties 'caption', 'width', 'sort' (optional),
     * 'dlink' (bool, optional), 'tags' (bool, optional), 'marker' (bool, optional))
     */
    columnInfo: {
      type: Array,
      required: true,
    },
  },

  computed: {
    columnsHaveGroups: function () {
      let colWithGroup = this.columnInfo.find((col) => col.group != null);
      if (colWithGroup) {
        return true;
      }
      return false;
    },
  },

  methods: {
    /**
     * Column sort url
     */
    columnSortUrl: function (sortField) {
      let url =
        this.$baseUrl +
        "search/column-sort/" +
        this.searchId +
        "/" +
        sortField +
        "/" +
        this.page +
        "/" +
        this.format;
      return url;
    },
  },
};
</script>

<style>
tr.biblio_resultslist_header th {
  text-align: left;
}
</style>
