<template>
  <span>
    <template v-if="contributors.length > 0">
      <dl
        v-for="contributor in contributors"
        :key="contributor.getTableId"
        class="row"
      >
        <template v-if="contributor.getDisplayName != null">
          <dt class="col-sm-2 font-weight-bold text-secondary">
            {{ contributor.getRoleName }}
          </dt>
          <dd class="col-sm-10">
            <template v-if="contributor.getTitlesBefore">{{
              contributor.getTitlesBefore
            }}</template>
            {{ contributor.getDisplayName }}
            <template v-if="contributor.getQualificationsHonours"
              >, {{ contributor.getQualificationsHonours }}</template
            >
            <template v-if="contributor.getTitlesAfter"
              >, {{ contributor.getTitlesAfter }}</template
            >
            <span v-if="contributor.getISNI" class="text-muted">
              (ISNI {{ contributor.getISNI }})
            </span>
            <a
              v-if="contributor.getContactId"
              :href="
                $baseUrl +
                'contacts/detailed/' +
                contributor.getContactId +
                '/0/0' +
                (returnTo ? '?return=' + returnTo : '')
              "
              >(id {{ contributor.getContactId }})
            </a>
            <span v-if="contributor.getInternal" class="text-muted">
              (Internal)</span
            >
          </dd>
        </template>
        <template>
          <dt class="col-sm-2"></dt>
          <dd class="col-sm-10 pl-5">
            <template
              v-if="
                contributor.getProfessionalAffiliations &&
                contributor.getProfessionalAffiliations.length > 0
              "
              ><span
                v-for="affiliation in contributor.getProfessionalAffiliations"
                :key="'affiliation-' + affiliation.getTableId"
              >
                {{ affiliation.getPosition
                }}<template v-if="affiliation.getOrganisation"
                  >, {{ affiliation.getOrganisation }}</template
                ></span
              >;
            </template>
            <template v-if="contributor.getDateOfBirth"
              >Born
              {{
                contributor.getDateOfBirth
                  | parseDate(dateOptions.format, dateOptions)
              }};
            </template>
            <template v-if="contributor.getDateOfDeath"
              >Died
              {{
                contributor.getDateOfDeath
                  | parseDate(dateOptions.format, dateOptions)
              }};
            </template>
            <template v-if="contributor.getNationalityCountryName"
              >National of {{ contributor.getNationalityCountryName }};
            </template>
            <template
              v-if="
                contributor.getAllPlaces &&
                Object.keys(contributor.getAllPlaces).length > 0
              "
            >
              <span
                v-for="(place, index) in contributor.getAllPlaces"
                :key="'place-' + index"
              >
                <template v-if="place.getType !== '08'">
                  {{ place.getTypeName }}
                  <template v-if="place.getTown"
                    >{{ place.getTown }},
                  </template>
                  <template v-if="place.getRegionName"
                    >{{ place.getRegionName }},
                  </template>
                  {{ place.getCountryName }};
                </template>
              </span>
            </template>
          </dd></template
        >

        <template v-if="contributor.getCorporateBody != null">
          <dt class="col-sm-2"></dt>
          <dd class="col-sm-10 pl-5">
            <span v-if="contributor.getPlaceRegisteredIn">
              {{ contributor.getPlaceRegisteredIn.getTypeName }}
              {{ contributor.getPlaceRegisteredIn.getTown }},
              {{ contributor.getPlaceRegisteredIn.getCountryName }};
            </span>
            <span v-if="contributor.getPlaceOperatingFrom">
              {{ contributor.getPlaceOperatingFrom.getTypeName }}
              {{ contributor.getPlaceOperatingFrom.getTown }},
              {{ contributor.getPlaceOperatingFrom.getCountryName }};
            </span>
          </dd>
        </template>
        <template v-if="contributor.getBiographicalNote != null">
          <dt class="col-sm-2 pl-5 font-weight-bold text-secondary">
            Biography ({{
              getBiographicalNoteFormat(contributor.getBiographicalNoteFormat)
            }})
          </dt>
          <dd class="col-sm-10">
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="contributor.getBiographicalNoteFormat == '02'"
              v-html="contributor.getBiographicalNote"
            ></div>
            <!--eslint-enable-->
            <!-- prettier-ignore -->
            <div v-else class="preserve-white-space">{{ contributor.getBiographicalNote }}</div>
          </dd>
        </template>
        <template v-for="website in contributor.getWebsites">
          <dt
            :key="'website1-' + website.getTableId"
            class="col-sm-2 pl-5 font-weight-bold text-secondary"
          >
            Website
          </dt>
          <dd :key="'website2-' + website.getTableId" class="col-sm-10">
            <a :href="parseWebsiteUrl(website.getLinkUrl)" target="_blank">{{
              website.getLinkUrl
            }}</a>
            <span v-if="website.getRole == '06'"> (by Contributor)</span>
            <span v-else-if="website.getRole == '42'">
              (by Contributor - Social Networking)</span
            >
            <span v-else-if="website.getRole == '07'"> (by Publisher)</span>
            <span v-else-if="website.getRole == '08'">
              (By Other Publisher)</span
            >
            <span v-else-if="website.getRole == '09'"> (By Third-party)</span>
          </dd>
        </template>
      </dl>
      <dl v-if="contributorsStatement" class="row">
        <dt class="col-sm-2 font-weight-bold text-secondary">
          Contributor Statement
        </dt>
        <!-- prettier-ignore -->
        <dd class="col-sm-10 preserve-white-space">{{ contributorsStatement }}</dd>
      </dl>
    </template>
    <template v-else-if="showAllFields">
      <dl class="row">
        <dt class="col-sm-2 font-weight-bold text-secondary">Contributors</dt>
        <dd class="col-sm-10">—</dd>
        <dt class="col-sm-2 font-weight-bold text-secondary">
          Contributor Statement
        </dt>
        <dd class="col-sm-10">—</dd>
      </dl>
    </template>
  </span>
</template>

<script>
/**
 * Displays a product's contributor information
 */
import ParseUrl from "../../mixins/parseUrl.js";
import ParseDate from "../../mixins/parseDate.js";
import DisplaySetting from "../../mixins/productDisplaySetting.js";

export default {
  name: "ProductContributors",

  mixins: [ParseUrl, ParseDate, DisplaySetting],

  props: {
    /**
     * List of contributors
     */
    contributors: {
      type: Array,
      default: () => [],
    },

    /**
     * Contributor statement
     */
    contributorsStatement: {
      type: String,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },
  },

  methods: {
    /**
     * Gets the format of a biographical note based on the format
     *
     * @param {string} code The code for the biographical note
     *
     * @returns {string|null}
     */
    getBiographicalNoteFormat(code) {
      if (code == "02") {
        return "HTML";
      } else if (code == "06") {
        return "Text";
      }
      return null;
    },
  },
};
</script>

<style></style>
