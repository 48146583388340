<template>
  <div class="row searchformdatesline">
    <div class="col-1">
      <input :name="'bool' + lineNo" type="hidden" :value="bool" />
      <slot name="bool"></slot>
    </div>
    <div class="col-3 searchformdatesline_field">
      <searchform-field-dropdown
        v-if="fieldsList"
        :id="'FldCtrl' + lineNo"
        v-model="currentField"
        :name="'field' + lineNo"
        :fields-list="fieldsList"
        :initial-field="currentField"
      >
      </searchform-field-dropdown>
      <template v-else>
        <input :name="'field' + lineNo" type="hidden" :value="currentField" />
        <slot name="field"></slot>
      </template>
    </div>
    <div class="col-1"></div>
    <div class="col-7 searchformdatesline_term">
      <input
        :name="'type' + lineNo"
        type="hidden"
        :value="'dates' + (filter ? '/f' : '')"
      />
      <label>From:</label>
      <select
        v-if="dateFormat == 'dmy'"
        v-model="currentTerm.from.day"
        :name="'term' + lineNo + '_fd'"
        size="1"
      >
        <option value=""></option>
        <option v-for="day in 31" :key="day" :value="day">
          {{ day }}
        </option>
      </select>
      <select
        v-if="dateFormat == 'ymd'"
        v-model="currentTerm.from.year"
        :name="'term' + lineNo + '_fy'"
        size="1"
      >
        <option value=""></option>
        <option v-for="n in 15" :key="n" :value="thisYear - 11 + n">
          {{ thisYear - 11 + n }}
        </option>
      </select>
      <select
        v-model="currentTerm.from.month"
        :name="'term' + lineNo + '_fm'"
        size="1"
      >
        <option value=""></option>
        <option v-for="month in monthNames" :key="month" :value="month">
          {{ month }}
        </option>
      </select>
      <select
        v-if="dateFormat != 'dmy'"
        v-model="currentTerm.from.day"
        :name="'term' + lineNo + '_fd'"
        size="1"
      >
        <option value=""></option>
        <option v-for="day in 31" :key="day" :value="day">
          {{ day }}
        </option>
      </select>
      <select
        v-if="dateFormat != 'ymd'"
        v-model="currentTerm.from.year"
        :name="'term' + lineNo + '_fy'"
        size="1"
      >
        <option value=""></option>
        <option v-for="n in 15" :key="n" :value="thisYear - 11 + n">
          {{ thisYear - 11 + n }}
        </option>
      </select>
      &nbsp;&nbsp;
      <label>To:</label>
      <select
        v-if="dateFormat == 'dmy'"
        v-model="currentTerm.to.day"
        :name="'term' + lineNo + '_td'"
        size="1"
      >
        <option value=""></option>
        <option v-for="day in 31" :key="day" :value="day">
          {{ day }}
        </option>
      </select>
      <select
        v-if="dateFormat == 'ymd'"
        v-model="currentTerm.to.year"
        :name="'term' + lineNo + '_ty'"
        size="1"
      >
        <option value=""></option>
        <option v-for="n in 15" :key="n" :value="thisYear - 11 + n">
          {{ thisYear - 11 + n }}
        </option>
      </select>
      <select
        v-model="currentTerm.to.month"
        :name="'term' + lineNo + '_tm'"
        size="1"
      >
        <option value=""></option>
        <option v-for="month in monthNames" :key="month" :value="month">
          {{ month }}
        </option>
      </select>
      <select
        v-if="dateFormat != 'dmy'"
        v-model="currentTerm.to.day"
        :name="'term' + lineNo + '_td'"
        size="1"
      >
        <option value=""></option>
        <option v-for="day in 31" :key="day" :value="day">
          {{ day }}
        </option>
      </select>
      <select
        v-if="dateFormat != 'ymd'"
        v-model="currentTerm.to.year"
        :name="'term' + lineNo + '_ty'"
        size="1"
      >
        <option value=""></option>
        <option v-for="n in 15" :key="n" :value="thisYear - 11 + n">
          {{ thisYear - 11 + n }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
/**
 * Display a search form dates edit line
 *
 * The following form controls are output:
 *   'bool{lineNo}' (hidden), 'field{lineNo}' (select/hidden), 'type{lineNo}' = 'dates/f' (hidden, optional), 'term{lineNo}_[fd|fm|fy|td|tm|ty]' (select)
 */
import SearchFormFieldDropdown from "./SearchFormFieldDropdown.vue";

export default {
  name: "SearchFormDatesLine",

  components: {
    "searchform-field-dropdown": SearchFormFieldDropdown,
  },

  props: {
    /**
     * The line number
     */
    lineNo: {
      type: Number,
      required: true,
    },

    /**
     * The fields list. An array of field objects and field group objects, field objects have the properties 'tag' (string),
     * 'name' (string) and 'index' (bool), and field group objects have the properties 'group' (string) and 'fields' (array)
     * If not specified then the field is hidden
     */
    fieldsList: {
      type: Array,
      default: null,
    },

    /**
     * The boolean (fixed value)
     */
    bool: {
      type: String,
      default: "AND",
    },

    /**
     * The initial field
     */
    initialField: {
      type: String,
      required: true,
    },

    /**
     * The initial term
     */
    initialTerm: {
      type: String,
      default: "",
    },

    /**
     * This is a filter line
     */
    filter: {
      type: Boolean,
      default: false,
    },

    /**
     * The range symbols. An object with the properties 'range', 'gte' and 'lte' (all strings)
     */
    rangeSymbols: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      currentField: this.initialField,
      currentTerm: this.splitInitialTerm(this.$userPreferences.dateFormat),
      dateFormat: this.$userPreferences.dateFormat,
    };
  },

  computed: {
    thisYear: function () {
      let now = new Date();
      return now.getFullYear();
    },
    monthNames: function () {
      return [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    },
  },

  methods: {
    /**
     * Split the initial term into its parts
     */
    splitInitialTerm(dateFormat) {
      let termInfo = {
        from: {
          day: "",
          month: "",
          year: "",
        },
        to: {
          day: "",
          month: "",
          year: "",
        },
      };

      let term = this.initialTerm;
      if (
        term.substr(0, this.rangeSymbols.gte.length) == this.rangeSymbols.gte
      ) {
        term = term.substr(this.rangeSymbols.gte.length).trim();
        termInfo.from = this.splitDate(term.split(" "), dateFormat);
      } else if (
        term.substr(0, this.rangeSymbols.lte.length) == this.rangeSymbols.lte
      ) {
        term = term.substr(this.rangeSymbols.lte.length).trim();
        termInfo.to = this.splitDate(term.split(" "), dateFormat);
      } else if (term.indexOf(this.rangeSymbols.range) != -1) {
        let parts = term.split(this.rangeSymbols.range);
        termInfo.from = this.splitDate(parts[0].trim().split(" "), dateFormat);
        termInfo.to = this.splitDate(parts[1].trim().split(" "), dateFormat);
      } else {
        termInfo.from = this.splitDate(term.trim().split(" "), dateFormat);
        termInfo.to.day = termInfo.from.day;
        termInfo.to.month = termInfo.from.month;
        termInfo.to.year = termInfo.from.year;
        // do not use 'termInfo.to = termInfo.from' otherwise the from and to date controls are linked!
      }

      return termInfo;
    },

    /**
     * Split a date into its parts
     */
    splitDate(dateParts, dateFormat) {
      let dateInfo = {
        day: "",
        month: "",
        year: "",
      };

      if (dateParts.length == 1) {
        // year only date
        dateInfo.year = dateParts[0];
        return dateInfo;
      }

      if (dateParts.length == 2) {
        // year and month date
        if (dateFormat == "ymd") {
          dateInfo.year = dateParts[0];
          dateInfo.month = dateParts[1];
        } else {
          dateInfo.month = dateParts[0];
          dateInfo.year = dateParts[1];
        }
        return dateInfo;
      }

      // year, month and day date
      switch (dateFormat) {
        case "mdy":
          dateInfo.month = dateParts[0];
          dateInfo.day = dateParts[1];
          dateInfo.year = dateParts[2];
          break;
        case "ymd":
          dateInfo.year = dateParts[0];
          dateInfo.month = dateParts[1];
          dateInfo.day = dateParts[2];
          break;
        default:
          dateInfo.day = dateParts[0];
          dateInfo.month = dateParts[1];
          dateInfo.year = dateParts[2];
          break;
      }

      return dateInfo;
    },
  },
};
</script>

<style></style>
