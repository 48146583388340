<template>
  <div>
    <div class="row">
      <div class="col-3 mt-2">Formats</div>
      <div class="col-9 mt-1">
        <v-select
          v-model="selectedFormats"
          label="name"
          :options="vsFormats"
          :get-option-key="(o) => o.code"
          :reduce="(o) => o.code"
          :multiple="true"
          :close-on-select="false"
          :selectable="(o) => o.code"
          placeholder="Select format(s)"
          @input="formatsChanged"
        >
          <template #option="option">
            <div v-if="!option.code" class="format-list-groupitem">
              {{ option.name }}
            </div>
            <div v-else-if="option.code.search(/^(OL|MM)$/) == 0">
              {{ option.name }}
            </div>
            <div
              v-else-if="option.code.search(/^..(.+\?)?$/) == 0"
              class="format-list-indent format-list-italic"
            >
              – {{ option.name }} –
            </div>
            <div v-else class="format-list-indent">
              {{ option.name }}
            </div>
          </template>
          <template #selected-option="option">
            <i v-if="option.code.search(/^(BK|EB|AV|PS|XX)(.+\?)?$/) == 0">
              {{ option.name }}
            </i>
            <template v-else>
              {{ option.name }}
            </template>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings data requirements accepted formats component
 */
import vSelect from "vue-select";

export default {
  name: "DataRequirementsFormats",

  components: {
    "v-select": vSelect,
  },

  props: {
    /**
     * List of all formats
     */
    formats: {
      type: Array,
      required: true,
    },

    /**
     * Accepted formats
     */
    acceptedFormats: {
      type: Array,
      default: null,
    },
  },

  data: function () {
    let dataObject = {
      vsFormats: [],
      selectedFormats: [],
    };

    // formats
    dataObject.vsFormats.push({ code: null, name: "Book" });
    dataObject.vsFormats.push({ code: "BK", name: "All Book" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^B/) == 0)
        .map((item) => ({ code: "BK" + item.code, name: item.name }))
    );
    dataObject.vsFormats.push({ code: null, name: "E-Book" });
    dataObject.vsFormats.push({ code: "EB", name: "All E-Book" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^ED\./) == 0)
        .map((item) => ({
          code: "EB" + item.code.substring(3),
          name: item.name,
        }))
    );
    dataObject.vsFormats.push({ code: null, name: "Audio" });
    dataObject.vsFormats.push({ code: "AVA?", name: "All Audio" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^A/) == 0)
        .map((item) => ({ code: "AV" + item.code, name: item.name }))
    );
    dataObject.vsFormats.push({ code: null, name: "Video" });
    dataObject.vsFormats.push({ code: "AVV?", name: "All Video" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^V/) == 0)
        .map((item) => ({ code: "AV" + item.code, name: item.name }))
    );
    dataObject.vsFormats.push({ code: null, name: "Digital" });
    dataObject.vsFormats.push({ code: "AVD?", name: "All Digital" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^[DL]/) == 0)
        .map((item) => ({ code: "AV" + item.code, name: item.name }))
    );
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/EC/) == 0)
        .map((item) => ({ code: "OL", name: item.name }))
    );
    dataObject.vsFormats.push({ code: null, name: "Point of Sale" });
    dataObject.vsFormats.push({ code: "PS", name: "All Point of Sale" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^X/) == 0)
        .map((item) => ({ code: "PS" + item.code, name: item.name }))
    );
    dataObject.vsFormats.push({ code: "MM", name: "Mixed media/multi-item" });
    dataObject.vsFormats.push({ code: null, name: "Cartographic" });
    dataObject.vsFormats.push({ code: "XXC?", name: "All Cartographic" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^C/) == 0)
        .map((item) => ({ code: "XX" + item.code, name: item.name }))
    );
    dataObject.vsFormats.push({ code: null, name: "Film" });
    dataObject.vsFormats.push({ code: "XXF?", name: "All Film" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^F/) == 0)
        .map((item) => ({ code: "XX" + item.code, name: item.name }))
    );
    dataObject.vsFormats.push({ code: null, name: "Microform" });
    dataObject.vsFormats.push({ code: "XXM?", name: "All Microform" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^M/) == 0)
        .map((item) => ({ code: "XX" + item.code, name: item.name }))
    );
    dataObject.vsFormats.push({ code: null, name: "Printed" });
    dataObject.vsFormats.push({ code: "XXP?", name: "All Printed" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^P/) == 0)
        .map((item) => ({ code: "XX" + item.code, name: item.name }))
    );
    dataObject.vsFormats.push({ code: null, name: "Merchandise" });
    dataObject.vsFormats.push({ code: "XXZ?", name: "All Merchandise" });
    dataObject.vsFormats.push(
      ...this.formats
        .filter((item) => item.code.search(/^Z/) == 0)
        .map((item) => ({ code: "XX" + item.code, name: item.name }))
    );

    // accepted formats
    if (this.acceptedFormats) {
      for (const index in this.acceptedFormats) {
        let code = this.acceptedFormats[index];
        switch (code) {
          case "AV":
            dataObject.selectedFormats.push("AVA?", "AVV?", "AVD?");
            break;
          case "AVD?":
          case "AVL?":
            if (dataObject.selectedFormats.indexOf("AVD?") == -1) {
              dataObject.selectedFormats.push("AVD?");
            }
            break;
          case "XX":
            dataObject.selectedFormats.push(
              "XXC?",
              "XXF?",
              "XXM?",
              "XXP?",
              "XXZ?"
            );
            break;
          default:
            dataObject.selectedFormats.push(code);
            break;
        }
      }
    } else {
      dataObject.selectedFormats.push(
        "BK",
        "EB",
        "AVA?",
        "AVV?",
        "AVD?",
        "OL",
        "PS",
        "MM",
        "XXC?",
        "XXF?",
        "XXM?",
        "XXP?",
        "XXZ?"
      );
    }

    return dataObject;
  },

  methods: {
    /**
     * Selected formats have changed
     */
    formatsChanged: function () {
      let formats = [];
      let formatsAVgrp = [];
      let formatsXXgrp = [];

      for (const index in this.selectedFormats) {
        let code = this.selectedFormats[index];
        switch (code) {
          case "AVA?":
          case "AVV?":
            formatsAVgrp.push(code);
            break;
          case "AVD?":
            formatsAVgrp.push("AVD?", "AVL?");
            break;
          case "AVEC":
            formats.push("OL");
            break;
          case "XXC?":
          case "XXF?":
          case "XXM?":
          case "XXP?":
          case "XXZ?":
            formatsXXgrp.push(code);
            break;
          default:
            formats.push(code);
            break;
        }
      }

      if (formatsAVgrp.length == 4) {
        formats.push("AV");
      } else {
        formats.push(...formatsAVgrp);
      }
      if (formatsXXgrp.length == 5) {
        formats.push("XX");
      } else {
        formats.push(...formatsXXgrp);
      }

      this.$emit("change", formats);
    },
  },
};
</script>

<style scoped>
div.col-align-right {
  padding-right: 2rem;
  text-align: right;
}
.format-list-groupitem {
  font-weight: bold;
  font-style: italic;
  color: #000;
}
.format-list-indent {
  padding-left: 1rem;
}
.format-list-italic {
  font-style: italic;
}
</style>
