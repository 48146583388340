<template>
  <div>
    <!-- header -->
    <div
      v-if="currentContentItems.length != 0"
      class="row contentitemsedit-hdr"
    >
      <div class="col-2 pl-2">
        Item Type &amp; Number
        <info-icon tip="contentItemItemNumber" />
      </div>
      <div class="col-5 pl-2">Title and Subtitle</div>
      <div class="col-4 pl-2">
        <template v-if="editType == 'av'">
          Type, Times
          <info-icon tip="contentItemTimeRuns" />
          and Time Duration
          <info-icon tip="contentItemTimeDuration" />
        </template>
        <template v-else>
          Type, Pages
          <info-icon tip="contentItemPageRuns" />
          and Page Count
          <info-icon tip="contentItemPageCount" />
        </template>
      </div>
      <div class="col-1 pl-0 contentitemsedit-hdr-add">
        <a href="#" title="Create a new top-level item" @click="newItem('')">
          <i class="fa fa-plus"></i>
        </a>
      </div>
    </div>
    <!-- items -->
    <content-item-edit-box
      v-for="contentItem in currentContentItems"
      ref="contentItemEditBox"
      :key="contentItem.key"
      :edit-type="editType"
      :item-types="itemTypes"
      :content-item="contentItem"
      @edit-item="editItem"
      @new-item="newItem"
      @copy-item="copyItem"
      @delete-item="deleteItem"
      @move-item="moveItem"
      @field-editing="saveIndicatorStatus = null"
      @field-changed="fieldChanged"
    ></content-item-edit-box>
    <!-- footer -->
    <div class="row contentitemsedit-footer">
      <div class="col-2">
        <!-- new item button -->
        <button
          class="btn btn-primary"
          title="Create a new top-level item"
          @click="newItem('')"
        >
          <i class="fa fa-plus"></i>
          Add Content Item
        </button>
      </div>
    </div>
    <div class="row contentitemsedit-footer">
      <div class="col-5">
        Changes you have made on this form have already been saved.
      </div>
      <div class="col-5">
        <save-indicator
          :status="saveIndicatorStatus"
          complete-message="Saved changes"
        />
      </div>
    </div>
    <!-- buttons -->
    <div class="formcontrols contentitemsedit-footer">
      <a
        class="btn btn-success"
        :href="$baseUrl + returnUrl"
        @click="closingForm"
      >
        <span class="fa fa-reply"></span>
        Return to previous page
      </a>
      <a
        v-if="nextFormUrl"
        class="btn btn-success"
        :href="$baseUrl + nextFormUrl"
        @click="closingForm"
      >
        <span class="fa fa-arrow-right"></span>
        Continue to next form
      </a>
    </div>
    <!-- modal -->
    <content-item-edit-modal
      ref="editModal"
      :product-id="productId"
      :edit-type="editType"
      :item-types="itemTypes"
      :all-languages="allLanguages"
      :all-countries="allCountries"
      :contributor-roles="contributorRoles"
      :contributor-unnamed-types="contributorUnnamedTypes"
      :subject-schemes="subjectSchemes"
      :subject-browsers="subjectBrowsers"
      :subject-versions="subjectVersions"
      :description-types="descriptionTypes"
      :media-types="mediaTypes"
      :media-formats="mediaFormats"
      :related-types="relatedTypes"
      @changed="(contentItem) => editedItem(contentItem)"
    >
    </content-item-edit-modal>
  </div>
</template>

<script>
/**
 * Edit content items
 */
import ContentItemEditBox from "./ContentItemEditBox.vue";
import ContentItemEditModal from "./ContentItemEditModal.vue";
import SaveIndicator from "../../ui/SaveIndicator.vue";
import InfoIcon from "../../icons/InfoIcon.vue";
import { HTTP } from "../../../http-common.js";

export default {
  name: "ContentItemsEdit",

  components: {
    "content-item-edit-box": ContentItemEditBox,
    "content-item-edit-modal": ContentItemEditModal,
    "save-indicator": SaveIndicator,
    "info-icon": InfoIcon,
  },

  props: {
    /**
     * The product id
     */
    productId: {
      type: Number,
      required: true,
    },

    /**
     * The edit type (text or av)
     */
    editType: {
      type: String,
      default: "text",
    },

    /**
     * The content items
     */
    contentItems: {
      type: Array,
      required: true,
    },

    /**
     * The list of item types
     * An object whose properties are the type codes and their values are the type names
     */
    itemTypes: {
      type: Object,
      required: true,
    },

    /**
     * The list of all languages
     */
    allLanguages: {
      type: Array,
      required: true,
    },

    /**
     * The list of all countries grouped by continent and optionally with regions
     */
    allCountries: {
      type: Array,
      required: true,
    },

    /**
     * The grouped list of contributor roles
     */
    contributorRoles: {
      type: Array,
      required: true,
    },

    /**
     * The list of contributor unnamed types
     */
    contributorUnnamedTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all subject schemes
     */
    subjectSchemes: {
      type: Array,
      required: true,
    },

    /**
     * Subject Browsers
     */
    subjectBrowsers: {
      type: Array,
      required: true,
    },

    /**
     * Subject versions for BIC, BISAC and THEMA
     */
    subjectVersions: {
      type: Object,
      required: true,
    },

    /**
     * List of all description types
     */
    descriptionTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all media types
     */
    mediaTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all media formats
     */
    mediaFormats: {
      type: Array,
      required: true,
    },

    /**
     * List of all related product types
     */
    relatedTypes: {
      type: Array,
      required: true,
    },

    /**
     * The return url
     */
    returnUrl: {
      type: String,
      required: true,
    },

    /**
     * The next form url
     */
    nextFormUrl: {
      type: String,
      default: null,
    },
  },

  data: function () {
    let dataObject = {
      currentContentItems: this.contentItems,
      nextKey: 1,
      saveIndicatorStatus: null,
      checkOnClose: true,
    };

    for (let i = 0; i < dataObject.currentContentItems.length; i++) {
      dataObject.currentContentItems[i].level =
        dataObject.currentContentItems[i].getSequenceNumber.split(".").length;
      dataObject.currentContentItems[i].key = dataObject.nextKey++;
    }

    return dataObject;
  },

  methods: {
    /**
     * Edit a content item
     */
    editItem: function (sequenceNumber) {
      this.$refs.editModal.showModal(sequenceNumber);
      this.saveIndicatorStatus = null;
    },

    /**
     * Edited a content item
     */
    editedItem: function (contentItem) {
      for (let i = 0; i < this.currentContentItems.length; i++) {
        if (
          this.currentContentItems[i].getSequenceNumber ==
          contentItem.getSequenceNumber
        ) {
          contentItem.level = this.currentContentItems[i].level;
          contentItem.key = this.currentContentItems[i].key;
          this.currentContentItems[i] = contentItem;
          this.findEditBoxComponent(i).update(contentItem);
          break;
        }
      }
    },

    /**
     * Create a new text/av content item
     */
    newItem: function (parentSequence) {
      let url =
        this.$baseUrl + "biblio-edit/edit-contentitems/" + this.productId;
      let postData = new URLSearchParams();
      postData.append("type", this.editType);
      postData.append("parent", parentSequence);

      this.saveIndicatorStatus = "saving";
      HTTP.post(url, postData)
        .then((response) => {
          // create a new item
          let newItem = {
            getSequenceNumber: response.data.sequenceNumber,
            getComponentTypeName: response.data.componentTypeName,
            getComponentNumber: response.data.componentNumber,
            getMainTitle: response.data.mainTitle,
            getMainTitleSubtitle: response.data.mainTitleSubtitle,
            level: response.data.sequenceNumber.split(".").length,
            key: this.nextKey++,
          };
          if (this.editType == "av") {
            newItem.getAVItemType = response.data.avItemType;
            newItem.getTimeRuns = response.data.timeRuns;
            newItem.getTimeDuration = response.data.timeDuration;
          } else {
            newItem.getTextItemType = response.data.textItemType;
            newItem.getPageRuns = response.data.pageRuns;
            newItem.getPageCount = response.data.pageCount;
          }

          // insert the new item
          this.currentContentItems.splice(response.data.index, 0, newItem);

          // save indicator status
          this.saveIndicatorStatus = "saved";

          // after updating the page, scroll the new item into view
          setTimeout(() => {
            this.findEditBoxComponent(response.data.index).$el.scrollIntoView();
          }, 500);
        })
        .catch(() => {
          this.saveIndicatorStatus = null;
          this.$bvModal.msgBoxOk(
            "An error has occurred while creating a new content item"
          );
        });
    },

    /**
     * Create a new text/av content item by coping an existing item
     */
    copyItem: function (sequenceNumber) {
      let url =
        this.$baseUrl + "biblio-edit/edit-contentitems/" + this.productId;
      let postData = new URLSearchParams();
      postData.append("type", this.editType);
      postData.append("copysrc", sequenceNumber);

      this.saveIndicatorStatus = "saving";
      HTTP.post(url, postData)
        .then((response) => {
          // create a new item
          let newItem = {
            getSequenceNumber: response.data.sequenceNumber,
            getComponentTypeName: response.data.componentTypeName,
            getComponentNumber: response.data.componentNumber,
            getMainTitle: response.data.mainTitle,
            getMainTitleSubtitle: response.data.mainTitleSubtitle,
            level: response.data.sequenceNumber.split(".").length,
            key: this.nextKey++,
          };
          if (this.editType == "av") {
            newItem.getAVItemType = response.data.avItemType;
            newItem.getTimeRuns = response.data.timeRuns;
            newItem.getTimeDuration = response.data.timeDuration;
          } else {
            newItem.getTextItemType = response.data.textItemType;
            newItem.getPageRuns = response.data.pageRuns;
            newItem.getPageCount = response.data.pageCount;
          }

          // insert the new item
          this.currentContentItems.splice(response.data.index, 0, newItem);

          // save indicator status
          this.saveIndicatorStatus = "saved";

          // after updating the page, scroll the new item into view and
          // open the item in the edit modal
          setTimeout(() => {
            this.findEditBoxComponent(response.data.index).$el.scrollIntoView();
            this.editItem(response.data.sequenceNumber);
          }, 500);
        })
        .catch(() => {
          this.saveIndicatorStatus = null;
          this.$bvModal.msgBoxOk(
            "An error has occurred while creating a new content item"
          );
        });
    },

    /**
     * Delete a content item
     */
    deleteItem: function (sequenceNumber) {
      let url =
        this.$baseUrl +
        "biblio-edit/edit-contentitems/" +
        this.productId +
        "/" +
        sequenceNumber;

      this.saveIndicatorStatus = "saving";
      HTTP.delete(url)
        .then((response) => {
          for (let i = 0; i < this.currentContentItems.length; i++) {
            if (
              this.currentContentItems[i].getSequenceNumber in
              response.data.changes
            ) {
              let newSequenceNumber =
                response.data.changes[
                  this.currentContentItems[i].getSequenceNumber
                ];
              if (newSequenceNumber !== false) {
                this.currentContentItems[i].getSequenceNumber =
                  newSequenceNumber;
              } else {
                this.currentContentItems.splice(i, 1);
                i--;
              }
            }
          }

          // save indicator status
          this.saveIndicatorStatus = "saved";
        })
        .catch(() => {
          this.saveIndicatorStatus = null;
          this.$bvModal.msgBoxOk(
            "An error has occurred while deleting this content item"
          );
        });
    },

    /**
     * Move a content item
     */
    moveItem: function (sourceSequenceNumber, targetSequenceNumber, mode) {
      let url =
        this.$baseUrl +
        "biblio-edit/edit-contentitems/" +
        this.productId +
        "/" +
        sourceSequenceNumber +
        "/" +
        targetSequenceNumber +
        "/" +
        mode;

      this.saveIndicatorStatus = "saving";
      HTTP.patch(url)
        .then((response) => {
          for (let i = 0; i < this.currentContentItems.length; i++) {
            if (
              this.currentContentItems[i].getSequenceNumber in
              response.data.changes
            ) {
              let newSequenceNumber =
                response.data.changes[
                  this.currentContentItems[i].getSequenceNumber
                ];
              this.currentContentItems[i].getSequenceNumber = newSequenceNumber;
              this.currentContentItems[i].level =
                newSequenceNumber.split(".").length;
            }
          }
          this.currentContentItems.sort((item1, item2) => {
            let item1Seq = this.sequenceNumberSortKey(item1.getSequenceNumber);
            let item2Seq = this.sequenceNumberSortKey(item2.getSequenceNumber);
            // note: sequence numbers are unique so no two can be equal
            return item1Seq < item2Seq ? -1 : 1;
          });

          // save indicator status
          this.saveIndicatorStatus = "saved";
        })
        .catch(() => {
          this.saveIndicatorStatus = null;
          this.$bvModal.msgBoxOk(
            "An error has occurred while moving this content item"
          );
        });
    },

    /**
     * Convert a sequence number for sorting
     */
    sequenceNumberSortKey: function (sequenceNumber) {
      let sortKey = "";
      let parts = sequenceNumber.split(".");
      for (const i in parts) {
        let part = "000" + parts[i];
        sortKey += part.substring(part.length - 4); // last 4 characters
      }
      return sortKey;
    },

    /**
     * A field has changed
     */
    fieldChanged: function (sender, sequenceNumber, field, value) {
      let url =
        this.$baseUrl +
        "biblio-edit/edit-contentitems/" +
        this.productId +
        "/" +
        sequenceNumber;
      let postData = new URLSearchParams();
      postData.append(field, value);

      this.saveIndicatorStatus = "saving";
      HTTP.post(url, postData)
        .then(() => {
          sender.fieldSaved(field, value);
          this.saveIndicatorStatus = "saved";
        })
        .catch(() => {
          sender.fieldNotSaved(field);
          this.saveIndicatorStatus = null;
          this.$bvModal.msgBoxOk(
            "An error has occurred while saving the change to your content item"
          );
        });
    },

    /**
     * Closing the form
     */
    closingForm: function (event) {
      // check for any empty content items
      if (this.checkOnClose) {
        let invalidItems = false;
        for (let i = 0; i < this.$refs.contentItemEditBox.length; i++) {
          if (!this.$refs.contentItemEditBox[i].isValid) {
            invalidItems = true;
            break;
          }
        }

        if (invalidItems) {
          event.preventDefault();
          this.$bvModal
            .msgBoxConfirm(
              "The hightlighted content item(s) have been created but are invalid as they don't yet have either an item type name or a title. Are you sure you want to continue without adding this information ?"
            )
            .then((value) => {
              if (value) {
                this.checkOnClose = false;
                event.target.click();
              }
            });
        }
      }
    },

    /**
     * Find the content item edit box component
     */
    findEditBoxComponent: function (index) {
      let key = this.currentContentItems[index].key;
      for (let i = 0; i < this.$refs.contentItemEditBox.length; i++) {
        if (this.$refs.contentItemEditBox[i].$el.dataset.ciKey == key) {
          return this.$refs.contentItemEditBox[i];
        }
      }
      return null;
    },
  },
};
</script>

<style>
.contentitemsedit-hdr {
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.row.contentitemsedit-hdr {
  margin-left: 0rem;
  padding-right: 5rem;
}
div.row + div.row.contentitemsedit-footer {
  margin-top: 2rem;
}
</style>
