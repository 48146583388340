<template>
  <div>
    <v-select
      ref="vSelect"
      v-model="selected"
      v-bind="$attrs"
      :options="paginated"
      :clearable="false"
      :filterable="false"
      label="name"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
      @input="contactSelected"
      @search="search"
    >
      <!-- Passes named slots to the child base-select component. All slots available in base-select can be used in this component -->
      <template
        v-for="(index, slotName) in $scopedSlots"
        v-slot:[slotName]="data"
      >
        <slot :name="slotName" v-bind="data"></slot>
      </template>
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          v-bind="attributes"
          @input="searchInput"
          @compositionstart="events.compositionstart"
          @compositionend="events.compositionend"
          @keydown="events.keydown"
          @blur="events.blur"
          @focus="events.focus"
        />
      </template>
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
    <input v-if="name" :id="id" type="hidden" :name="name" :value="selected" />
  </div>
</template>

<script>
/**
 * Displays a select dropdown with a list of contacts for the bibliographic users selector
 * The component retrieves the data for the dropdown via ajax
 * An option at the end of the contacts list is provided to create a new
 * contact.
 */

import axios from "axios";
import vSelect from "vue-select";
import _debounce from "lodash/debounce";

export default {
  name: "UsersSelectorContactsDropdown",

  components: {
    "v-select": vSelect,
  },

  props: {
    /**
     * The base url of the application
     */
    baseUrl: {
      type: String,
      default: null,
    },

    /**
     * URL which provides data for the contacts dropdown
     */
    dataSourceOptions: {
      type: String,
      default: "/biblio-edit/users-selector-contacts/",
    },

    /**
     * The starting value of the form input
     * This will be updated when the select value changes
     */
    value: {
      type: [Number, String, Object],
      default: null,
    },
    /**
     * The id of the form input
     */
    id: {
      type: String,
      default: null,
    },

    /**
     * The name of the form input (used for form data)
     *
     * Must be included for the form input to be included
     */
    name: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      /**
       * The list of options for the dropdown
       */
      options: [],

      /**
       * The selected value
       */
      selected: null,

      /**
       * The filtered options
       */
      filteredOptions: this.options,

      /**
       * The limit to the items to display on a single scroll
       */
      limit: 10,

      /**
       * Observes when to increase the limit on scroll
       */
      observer: new IntersectionObserver(this.infiniteScroll),
    };
  },

  computed: {
    paginated() {
      if (!this.filteredOptions) {
        return [];
      }
      return this.filteredOptions.slice(0, this.limit);
    },

    hasNextPage() {
      return this.paginated.length < this.filteredOptions.length;
    },
  },

  watch: {
    value(value) {
      // update the selected property when the prop changes
      this.selected = value;
    },

    options(value) {
      this.filteredOptions = value;
    },
  },

  mounted() {
    /**
     * Retrieve the data on initial load of the component
     */
    this.getData().then((response) => {
      this.options = response.data;
      this.filteredOptions = this.options;
      this.selected = this.value;
    });
  },

  methods: {
    /**
     * Call to clear the selected contact
     */
    clearSelected() {
      this.selected = null;
    },

    /**
     * Called on input
     *
     * Retrieves detail data for the chosen contact and emits the
     * selected event
     */
    contactSelected() {
      if (this.selected) {
        this.$emit("selected", this.selected.email);
      }
    },

    /**
     * Gets and sets the options data for the dropdown
     */
    getData() {
      return axios.get(this.baseUrl + this.dataSourceOptions);
    },

    /**
     * Performs a search of the dropdown items
     */
    search(value) {
      if (!value) {
        this.filteredOptions = this.options;
        return;
      }
      this.filter().then((result) => {
        this.filteredOptions = result;
      });
    },

    /**
     * Adds a debounce to the search input of the dropdown
     */
    searchInput: _debounce(function (e) {
      this.$refs.vSelect.search = e.target.value;
    }, 250),

    /**
     * Custom filter to use on the v-select search
     */
    filter: function () {
      return new Promise((resolve) => {
        const data = this.options.filter((s) => {
          return s["name"]
            .toLowerCase()
            .includes(this.$refs.vSelect.search.toLowerCase());
        });
        resolve(data);
      });
    },

    /**
     * Triggers on dropdown open
     *
     * Watches the dropdown footer element to check when it's in view
     */
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },

    /**
     * Triggers on dropdown close
     *
     * Removes the observer
     */
    onClose() {
      this.observer.disconnect();
    },

    /**
     * Triggers on the observer
     *
     * Increases the limit of items currently available to be displayed in the dropdown
     */
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
  },
};
</script>
