<template>
  <base-select
    v-if="options"
    :id="id"
    :name="name"
    :options="options"
    :placeholder="placeholder"
    :value="initialValue"
  ></base-select>
</template>

<script>
/**
 * Renders a dropdown / select list of available reports
 */

import { HTTP } from "../../../http-common.js";

export default {
  name: "ReportsSelect",
  props: {
    /**
     * The id of the form input
     */
    id: {
      type: String,
      default: null,
    },

    /**
     * The name of the form input (used for post data)
     */
    name: {
      type: String,
      default: null,
    },

    /**
     * Placeholder text
     */
    placeholder: {
      type: String,
      default: null,
    },

    /**
     * The initial value of the select
     */
    initialValue: {
      type: [Number, String],
      default: null,
    },

    /**
     * The base url of the app
     */
    baseUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted: function () {
    this.getData().then((response) => {
      this.options = response.data.map(function (item) {
        return item.name;
      });
    });
  },
  methods: {
    getData() {
      return new Promise((resolve) => {
        // get the data using ajax
        HTTP.get(this.baseUrl + "api/reports").then((response) => {
          resolve(response.data);
        });
      });
    },
  },
};
</script>

<style></style>
