<template>
  <nav v-if="pageCount > 1 || simple" class="mt-2" aria-label="Page navigation">
    <ul v-if="!backendPagination" class="pagination">
      <li v-if="!simple" class="page-item" :class="{ disabled: prevPage < 1 }">
        <span
          class="page-btn page-link"
          aria-label="First"
          v-on="
            prevPage >= 1
              ? { click: () => pageUpdated(1) }
              : { click: ($event) => $event.preventDefault() }
          "
        >
          <span aria-hidden="true">First</span>
        </span>
      </li>
      <li class="page-item" :class="{ disabled: prevPage < 1 }">
        <span
          class="page-btn page-link"
          aria-label="Previous"
          v-on="
            prevPage >= 1
              ? { click: () => pageUpdated(currentPage - 1) }
              : { click: ($event) => $event.preventDefault() }
          "
        >
          <span aria-hidden="true">&laquo;</span>
        </span>
      </li>
      <template v-if="!simple">
        <li
          v-for="(page, index) in pages"
          :key="index"
          class="page-item"
          :class="{ active: page == currentPage }"
        >
          <span class="page-btn page-link" @click="pageUpdated(page)">{{
            page
          }}</span>
        </li>
      </template>
      <li
        class="page-item"
        :class="{
          disabled:
            (nextPage > pageCount && !simple) || (simple && !hasNextPage),
        }"
      >
        <span
          class="page-btn page-link"
          aria-label="Next"
          v-on="
            nextPage <= pageCount || simple
              ? { click: () => pageUpdated(currentPage + 1) }
              : { click: ($event) => $event.preventDefault() }
          "
        >
          <span aria-hidden="true">&raquo;</span>
        </span>
      </li>
      <li v-if="!simple" :class="{ disabled: nextPage > pageCount }">
        <span
          class="page-btn page-link"
          aria-label="Last"
          v-on="
            nextPage <= pageCount
              ? { click: () => pageUpdated(pageCount) }
              : { click: ($event) => $event.preventDefault() }
          "
        >
          <span aria-hidden="true">Last</span>
        </span>
      </li>
    </ul>
    <ul v-if="backendPagination" class="pagination">
      <li v-if="!simple" class="page-item" :class="{ disabled: prevPage < 1 }">
        <a class="page-link" :href="'?page=1'" aria-label="First">
          <span aria-hidden="true">First</span>
        </a>
      </li>
      <li class="page-item" :class="{ disabled: prevPage < 1 }">
        <a class="page-link" :href="'?page=' + prevPage" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <template v-if="!simple">
        <li
          v-for="(page, index) in pages"
          :key="index"
          class="page-item"
          :class="{ active: page == currentPage }"
        >
          <a class="page-link" :href="'?page=' + page">{{ page }}</a>
        </li>
      </template>
      <li
        class="page-item"
        :class="{ disabled: nextPage > pageCount && !simple }"
      >
        <a class="page-link" :href="'?page=' + nextPage" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
      <li
        v-if="!simple"
        class="page-item"
        :class="{ disabled: nextPage > pageCount }"
      >
        <a class="page-link" :href="'?page=' + pageCount" aria-label="Next">
          <span aria-hidden="true">Last</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import _range from "lodash/range";

export default {
  name: "Paginator",

  props: {
    initialPage: {
      type: Number,
      default: 1,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    rowsPerPage: {
      type: Number,
      default: 10,
    },
    backendPagination: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    /**
     * Used for simple pagination only
     * Indicates whether a next page is available
     */
    hasNextPage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currentPage: function () {
      let currentPage = this.initialPage;
      return currentPage;
    },
    prevPage: function () {
      let currentPage = this.currentPage;
      return (currentPage -= 1);
    },
    nextPage: function () {
      let currentPage = this.currentPage;
      return (currentPage += 1);
    },
    pageCount: function () {
      let total = this.totalRows;
      return Math.ceil(total / this.rowsPerPage);
    },
    pages: function () {
      if (this.pageCount + 1 <= 7) {
        return _range(1, this.pageCount + 1);
      }
      let firstPage = Math.max(1, this.currentPage - 2);
      let lastPage = Math.min(this.pageCount + 1, this.currentPage + 3);
      if (this.currentPage < 3) {
        lastPage = Math.min(
          this.pageCount + 1,
          this.currentPage + (this.currentPage == 2 ? 4 : 5)
        );
      } else if (
        this.currentPage == this.pageCount ||
        this.currentPage == this.pageCount - 1
      ) {
        firstPage = Math.max(
          1,
          this.currentPage - (this.currentPage == this.pageCount ? 4 : 3)
        );
      }
      return _range(firstPage, lastPage);
    },
  },

  watch: {
    /**
     * Watches the page counter and resets to the last page
     * if the current page no longer exists
     */
    pageCount(value) {
      if (this.currentPage > value && !this.simple) {
        this.pageUpdated(value > 0 ? value : 1);
      }
    },
  },

  methods: {
    pageUpdated: function (page) {
      /**
       * Triggers on page change
       *
       * @property {Number} value The new page number
       */
      this.$emit("page-change", page);
    },
  },
};
</script>

<style>
.page-btn {
  cursor: pointer;
}
</style>
