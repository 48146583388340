<template>
  <div data-v-step="biblio-search-page">
    <!-- search form table header line -->
    <searchform-header-line></searchform-header-line>
    <!-- search form table lines -->
    <template v-for="(line, lineNo) in lines">
      <searchform-text-line
        v-if="!line.type"
        :key="lineNo"
        :line-no="lineNo + 1"
        :fields-list="fieldsList"
        :initial-bool="line.bool"
        :initial-field="line.field"
        :initial-term="line.term"
        @show-index="showIndex"
        @enter-key-pressed="startSearch"
      ></searchform-text-line>
      <searchform-tickbox-line
        v-else-if="line.type == 'status'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :options="[
          { value: 'A', label: 'Approved records' },
          { value: 'P', label: 'Provisional records' },
          { value: 'D', label: 'Archived records' },
        ]"
        field="RECSTAT"
        :term="line.term"
        :default-term="line.defaultTerm ? line.defaultTerm : 'A/P'"
        :filter="true"
      ></searchform-tickbox-line>
      <template v-else-if="line.type == 'hsiuser?status:approved-only'">
        <searchform-tickbox-line
          v-if="hsiUser"
          :key="lineNo"
          :line-no="lineNo + 1"
          :options="[
            { value: 'A', label: 'Approved records', hsiInternal: true },
            { value: 'P', label: 'Provisional records', hsiInternal: true },
            { value: 'D', label: 'Archived records', hsiInternal: true },
          ]"
          field="RECSTAT"
          :term="line.term"
          :default-term="line.defaultTerm ? line.defaultTerm : 'A'"
          :filter="true"
        ></searchform-tickbox-line>
        <searchform-tickbox-line
          v-else
          :key="lineNo"
          :line-no="lineNo + 1"
          :options="[
            { value: 'A', label: 'Approved records only', disabled: true },
          ]"
          field="RECSTAT"
          term="A"
          default-term="A"
          :filter="true"
        ></searchform-tickbox-line>
      </template>
      <searchform-tickbox-line
        v-else-if="line.type == 'archived-only'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :options="[
          { value: 'D', label: 'Archived records only', disabled: true },
        ]"
        field="RECSTAT"
        :term="['D']"
        default-term="D"
        :filter="true"
      ></searchform-tickbox-line>
      <searchform-tickbox-line
        v-else-if="line.type == 'template-only'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :options="[
          { value: 'T', label: 'Templates records only', disabled: true },
        ]"
        field="RECSTAT"
        term="T"
        default-term="T"
        :filter="true"
      ></searchform-tickbox-line>
      <searchform-tickbox-line
        v-else-if="line.type == 'visibility'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :options="[
          { value: 'P', label: 'Public records' },
          { value: 'W', label: 'Own website records' },
          { value: 'I', label: 'Internal records' },
        ]"
        field="RECVIS"
        :term="line.term"
        :default-term="line.defaultTerm ? line.defaultTerm : 'P/W/I'"
        :filter="true"
      ></searchform-tickbox-line>
      <searchform-tickbox-line
        v-else-if="line.type == 'non-internal-only'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :options="[
          { value: 'P/W', label: 'Non-internal records only', disabled: true },
        ]"
        field="RECVIS"
        :term="['P/W']"
        default-term="P/W"
        :filter="true"
      ></searchform-tickbox-line>
      <searchform-tickbox-line
        v-else-if="line.type == 'workflow'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :options="[{ value: 'Y', label: 'Workflow records only' }]"
        field="WKFDEF"
        :term="line.term"
        :filter="true"
      ></searchform-tickbox-line>
      <template v-else-if="line.type == 'filter-header'">
        <div :key="lineNo" class="row searchformflthdrline">
          <div class="col-12">
            <hr />
            {{ line.caption }}
            <br />
            <br />
            <input :name="'type' + (lineNo + 1)" type="hidden" value="" />
          </div>
        </div>
      </template>
      <searchform-dropdown-line
        v-else-if="line.type == 'workflow-tasks'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :options="workflowTasks"
        empty-option="(All Tasks)"
        bool="WITH"
        field="WKFTASK"
        :initial-term="line.term"
        :filter="true"
      >
        <template v-slot:field>Task</template>
      </searchform-dropdown-line>
      <searchform-users-line
        v-else-if="line.type == 'workflow-users'"
        :key="lineNo"
        :line-no="lineNo + 1"
        bool="WITH"
        field="WKFUSER"
        :initial-term="line.term"
        :filter="true"
        :users="formExtraData.users"
      >
        <template v-slot:bool>With</template>
        <template v-slot:field>{{ formExtraData.WorkflowUsersLabel }}</template>
      </searchform-users-line>
      <searchform-dates-line
        v-else-if="line.type == 'workflow-dates'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :fields-list="[
          { tag: 'WKFTRGT', name: 'Task Target', index: false },
          { tag: 'WKFCMPL', name: 'Task Completed', index: false },
        ]"
        bool="WITH"
        :initial-field="line.field"
        :initial-term="line.term"
        :filter="true"
        :range-symbols="formExtraData.rangeSymbols"
      >
        <template v-slot:bool>With</template>
      </searchform-dates-line>
      <searchform-tickbox-line
        v-else-if="line.type == 'workflow-status'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :options="[
          { value: 'C', label: 'Completed' },
          { value: 'A', label: 'Active' },
          { value: 'D', label: 'Overdue' },
          { value: 'R', label: 'Overran' },
        ]"
        bool="WITH"
        field="WKFSTAT"
        :term="line.term"
        default-term=""
        :filter="true"
      >
        <template v-slot:bool>With</template>
        <template v-slot:field>Tasks which are</template>
      </searchform-tickbox-line>
      <searchform-tickbox-line
        v-else-if="line.type == 'foreignrights'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :options="[{ value: 'Y', label: 'Foreign Rights records only' }]"
        field="FRDEF"
        :term="line.term"
        :filter="true"
      ></searchform-tickbox-line>
      <searchform-hidden-line
        v-else-if="line.type == 'hidden'"
        :key="lineNo"
        :line-no="lineNo + 1"
        :bool="line.bool"
        :field="line.field"
        :term="line.term"
        :filter="true"
      ></searchform-hidden-line>
    </template>
    <br />
    <!-- controls line -->
    <div class="row">
      <div class="col-12 formcontrols">
        <button
          ref="searchBtn"
          name="search"
          class="btn btn-success"
          type="submit"
          data-v-step="ftp-catalogue-search-btn"
        >
          <span class="fa fa-search"></span> Search
        </button>
        <a :href="$baseUrl + 'search/form/' + formName" class="btn btn-primary">
          <span class="fa fa-undo"></span> Reset form
        </a>
        <a v-if="backUrl" :href="backUrl" class="btn btn-primary">
          <span class="fa fa-list-ul"></span> Back
        </a>
        <button
          class="btn btn-primary"
          type="button"
          data-v-step="biblio-search-load"
          @click="showLoad()"
        >
          <span class="fa fa-save"></span> Load search
        </button>
        <button
          class="btn btn-primary"
          type="button"
          @click="showSave($event.target.form)"
        >
          <span class="fa fa-save"></span> Save search
        </button>
      </div>
    </div>
    <!-- index modal -->
    <searchform-index-modal
      ref="indexModal"
      :index-options="indexOptions"
      :help-base-url="helpBaseUrl"
      @do-search="startSearch"
    ></searchform-index-modal>
    <!-- load modal -->
    <searchform-load-modal
      ref="loadModal"
      :form-name="formName"
      :help-base-url="helpBaseUrl"
    >
    </searchform-load-modal>
    <!-- save modal -->
    <searchform-save-modal
      ref="saveModal"
      :form-name="formName"
      :help-base-url="helpBaseUrl"
      @rename-saved-search="showRename"
    >
    </searchform-save-modal>
    <!-- rename modal -->
    <searchform-rename-modal
      ref="renameModal"
      :help-base-url="helpBaseUrl"
      @modal-closed="showSave"
    >
    </searchform-rename-modal>
    <!-- biblio tour -->
    <booksonix-tour
      v-if="formName == 'biblio'"
      :name="searchTour.name"
      :steps="searchTour.steps"
      :options="{ highlight: true }"
    ></booksonix-tour>
    <!-- FTP Catalogue tour -->
    <booksonix-tour
      v-if="formName == 'ftp-catalogue'"
      :name="ftpCatalogueTour.name"
      :steps="ftpCatalogueTour.steps"
      :options="{ highlight: false, labels: { buttonStop: 'Okay' } }"
      :display-to-headfast-users="true"
      :callbacks="{}"
    ></booksonix-tour>
  </div>
</template>

<script>
/**
 * Display a search form
 *
 * The following form controls are output:
 *   text: 'bool{lineNo}' (select/hidden), 'field{lineNo}' (select), 'type{lineNo}' = 'text/f' (hidden, optional), 'term{lineNo}' (text)
 *   tickboxes: 'bool{lineNo}' (hidden), 'field{lineNo}' (hidden), 'type{lineNo}' = 'tickbox:{count}[/f]' (hidden), 'defterm{lineNo}' (hidden), 'term{lineNo}_{n}' (checkbox)
 *   dropdown: 'bool{lineNo}' (hidden), 'field{lineNo}' (hidden), 'type{lineNo}' = 'select/f' (hidden, optional), 'term{lineNo}' (hidden)
 *   users: 'bool{lineNo}' (hidden), 'field{lineNo}' (hidden), 'type{lineNo}' = 'text/f' (hidden, optional), 'term{lineNo}' (text)
 *   dates: 'bool{lineNo}' (hidden), 'field{lineNo}' (select/hidden), 'type{lineNo}' = 'dates/f' (hidden, optional), 'term{lineNo}_[fd|fm|fy|td|tm|ty]' (select)
 *   hidden: 'bool{lineNo}' (hidden), 'field{lineNo}' (hidden), 'type{lineNo}' = 'hidden/f' (hidden, optional), 'term{lineNo}' (hidden)
 *   controls: 'search' (submit)
 */
import SearchFormHeaderLine from "./SearchFormHeaderLine.vue";
import SearchFormTextLine from "./SearchFormTextLine.vue";
import SearchFormTickboxLine from "./SearchFormTickboxLine.vue";
import SearchFormDropdownLine from "./SearchFormDropdownLine.vue";
import SearchFormUsersLine from "./SearchFormUsersLine.vue";
import SearchFormDatesLine from "./SearchFormDatesLine.vue";
import SearchFormHiddenLine from "./SearchFormHiddenLine.vue";
import SearchFormIndexModal from "./SearchFormIndexModal.vue";
import SearchFormLoadModal from "./SearchFormLoadModal.vue";
import SearchFormSaveModal from "./SearchFormSaveModal.vue";
import SearchFormRenameModal from "./SearchFormRenameModal.vue";

export default {
  name: "SearchForm",

  components: {
    "searchform-header-line": SearchFormHeaderLine,
    "searchform-text-line": SearchFormTextLine,
    "searchform-tickbox-line": SearchFormTickboxLine,
    "searchform-dropdown-line": SearchFormDropdownLine,
    "searchform-users-line": SearchFormUsersLine,
    "searchform-dates-line": SearchFormDatesLine,
    "searchform-hidden-line": SearchFormHiddenLine,
    "searchform-index-modal": SearchFormIndexModal,
    "searchform-load-modal": SearchFormLoadModal,
    "searchform-save-modal": SearchFormSaveModal,
    "searchform-rename-modal": SearchFormRenameModal,
  },

  props: {
    /**
     * The search form name
     */
    formName: {
      type: String,
      required: true,
    },

    /**
     * The fields list. An array of field objects and field group objects, field objects have the properties 'tag' (string),
     * 'name' (string) and 'index' (bool), and field group objects have the properties 'group' (string) and 'fields' (array)
     */
    fieldsList: {
      type: Array,
      required: true,
    },

    /**
     * The search form lines, each line is an object with the properties bool, field, term and type
     */
    lines: {
      type: Array,
      required: true,
    },

    /**
     * The index options. An object with the properties 'userId' (string), 'symbols' (regex-string), 'words' (regex-string),
     * 'or' (string), 'pageTerms' (int), 'dateFmt' (string)
     */
    indexOptions: {
      type: Object,
      required: true,
    },

    /**
     * The back button url
     */
    backUrl: {
      type: String,
      default: null,
    },

    /**
     * Form extra data. Object with the following properties: workflowTasks (array)
     */
    formExtraData: {
      type: Object,
      default: null,
    },

    /**
     * Is the user a HSI user
     */
    hsiUser: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      searchTour: {
        name: "biblio-search",
        steps: [
          {
            target: '[data-v-step="biblio-search-page"]',
            content:
              "Welcome to the updated search form, hopefully everything will still look familiar but this web tour will give you a quick overview of the new form should you need it.",
            offset: -400,
          },
          {
            target: '[data-v-step="biblio-search-index1"]',
            content:
              "These will bring up an index for the search field to the left of the button to help find values. If an index is not available for a given field, the button is faded out.",
            offset: -400,
          },
          {
            target: '[data-v-step="biblio-search-operator2"]',
            content:
              "Use these to perform complex searches. <a target='_blank' href='https://www.booksonix.com/help/searching.html'>Click here</a> to view this help page for more information.",
            offset: -400,
          },
          {
            target: '[data-v-step="biblio-search-load"]',
            content:
              "Use this button to load a search strategy that you have previously saved using the <b>Save search</b> button to the right. <a target='_blank' href='https://www.booksonix.com/help/search_load.html'>Click here</a> to view this help page for more information.",
            offset: -400,
          },
          {
            target: '[data-v-step="biblio-search-checkboxes9"]',
            content:
              "Use these checkboxes to select which types of records should be included in the search. You can now include archived records in your search.",
            offset: -400,
          },
          {
            target: '[data-v-step="biblio-search-tags"]',
            content:
              "You can still streamline your work by using tags to group titles together. Simply click on a tag in this section to search for all records with that tag. <a target='_blank' href='https://www.booksonix.com/help/tagging.html'>Click here</a> to view this help page for more information.",
            offset: -400,
          },
        ],
      },
      ftpCatalogueTour: {
        name: "ftp-catalogue-search",
        steps: [
          {
            target: '[data-v-step="ftp-catalogue-search-btn"]',
            content:
              "To start creating a Facebook Catalogue FTP Feed, first define the search for the feed. The search results will determine which records will be in the FTP file.",
            offset: -400,
            params: {
              placement: "top",
              enableScrolling: false,
            },
          },
        ],
      },
    };
  },

  computed: {
    /*
     * Get the workflow tasks as an options array
     */
    workflowTasks: function () {
      let tasks = [];
      for (let i = 0; i < this.formExtraData.workflowTasks.length; i++) {
        tasks[tasks.length] = {
          value: '"' + this.formExtraData.workflowTasks[i] + '"',
          label: this.formExtraData.workflowTasks[i],
        };
      }
      return tasks;
    },

    /**
     * The help base url
     */
    helpBaseUrl: function () {
      if (
        location.hostname.search(
          /^(www\.)?booksonix\.(com|co\.uk|uk|net|org|org\.uk)$/
        ) != -1
      ) {
        return "//www.booksonix.com";
      }

      return "";
    },
  },

  methods: {
    /**
     * Set the number of index terms to display per page, this overrides the value in indexOptions
     */
    setIndexTerms(terms) {
      this.$refs.indexModal.setIndexTerms(terms);
    },

    /**
     * The show index event
     */
    showIndex(lineComponent, fieldInfo) {
      this.$refs.indexModal.showModal(lineComponent, fieldInfo);
    },

    /**
     * Start the search
     */
    startSearch() {
      this.$refs.searchBtn.click();
    },

    /**
     * The show load event
     */
    showLoad() {
      this.$refs.loadModal.showModal();
    },

    /**
     * The show save event
     */
    showSave(htmlForm) {
      this.$refs.saveModal.showModal(htmlForm);
    },

    /**
     * The show rename event
     */
    showRename(htmlForm, name, descr, type) {
      this.$refs.renameModal.showModal(htmlForm, name, descr, type);
    },
  },
};
</script>

<style></style>
