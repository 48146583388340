var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',[_vm._v("Recipient:")]),_c('v-select',{attrs:{"label":"name","options":_vm.listRecipients,"selectable":function (option) { return !option.group; },"placeholder":"Start typing to search for a feed recipient","jest-feed-selector":""},on:{"input":_vm.recipientSelected},scopedSlots:_vm._u([{key:"option",fn:function(recipient){return [_c('div',{class:_vm.recipientListOptionClass(recipient)},[_vm._v(" "+_vm._s(recipient.name)+" ")])]}},{key:"selected-option",fn:function(recipient){return [_c('div',{class:_vm.recipientListOptionClass(recipient)},[_vm._v(" "+_vm._s(recipient.name)+" ")])]}},{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?_c('em',[_vm._v("No feed recipient found")]):_c('em',[_vm._v("Start typing to search for a feed recipient")])]}}]),model:{value:(_vm.selectedRecipient),callback:function ($$v) {_vm.selectedRecipient=$$v},expression:"selectedRecipient"}})],1),_c('div',{staticClass:"col-3"},[_c('v-select',{attrs:{"options":_vm.listCatgoryOptions,"searchable":false,"clearable":false},on:{"input":_vm.rebuildRecipientsList},model:{value:(_vm.listCatgory),callback:function ($$v) {_vm.listCatgory=$$v},expression:"listCatgory"}})],1),(_vm.canNavigate)?_c('div',{staticClass:"col-2"},[_c('a',{staticClass:"btn btn-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.navPrev.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-chevron-left"})]),_c('a',{staticClass:"btn btn-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.navNext.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-chevron-right"})])]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-2"},[(_vm.editRecipients.length != 0)?_c('b-tabs',{model:{value:(_vm.currentEditRecipient),callback:function ($$v) {_vm.currentEditRecipient=$$v},expression:"currentEditRecipient"}},_vm._l((_vm.editRecipients),function(recipient){return _c('b-tab',{key:recipient.id,attrs:{"jest-feed":recipient.id},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{style:({
                'font-style': recipient.fixed ? '' : 'italic',
              }),on:{"dblclick":function($event){$event.preventDefault();recipient.fixed = true}}},[(recipient.name.length > 15)?_c('span',{attrs:{"title":recipient.name}},[_vm._v(" "+_vm._s(recipient.name.substring(0, 15))+" … ")]):_c('span',[_vm._v(" "+_vm._s(recipient.name)+" ")])]),_vm._v("   "),_c('a',{staticClass:"tab-header-close-btn",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.closeRecipient(recipient.id, true)}}},[_vm._v(" × ")])]},proxy:true}],null,true)},[_c('dissemination-settings-feed',{attrs:{"feed-recipient-id":recipient.id,"feed-recipient-name":recipient.name,"feed-recipient-group":recipient.groupName,"data-formats":_vm.dataFormats,"return-condition-types":_vm.returnConditionTypes,"languages":_vm.languages,"countries":_vm.countries,"formats":_vm.formats,"currencies":_vm.currencies,"price-types":_vm.priceTypes,"discount-schemes":_vm.discountSchemes,"global-tags":_vm.globalTags,"default-tab":recipient.currentTab,"read-only":_vm.readOnly},on:{"name-change":function (feedId, feedName) {
                _vm.feedNameChange(feedId, feedName);
              },"change":function($event){recipient.fixed = true;
              recipient.modified = true;
              _vm.reportModified();},"save":function($event){recipient.modified = false;
              _vm.reportModified();},"close":function($event){return _vm.closeRecipient(recipient.id, false)},"tab-change":function (tabIndex) {
                recipient.currentTab = tabIndex;
              }}})],1)}),1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }