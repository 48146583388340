<template>
  <b-card v-if="!deleted" class="p-2">
    <div class="row">
      <div class="col pl-0 pr-2">
        <div class="row mb-3">
          <div class="col">
            <div class="d-flex justify-content-between">
              <h4 class="mb-0">
                {{ token.display_name
                }}<b-badge
                  v-if="headfastOnly"
                  variant="primary"
                  class="headfast-badge ml-2 mb-1"
                  >Headfast Only</b-badge
                >
              </h4>
              <h6></h6>
              <div>
                <template v-if="!hideButtons">
                  <a
                    :href="$baseUrl + 'api-token/edit/' + token.access_token_id"
                    >Edit</a
                  >
                  <span class="mx-2">|</span>
                  <a
                    class="text-danger"
                    jest-apitoken-delete
                    @click="deleteClicked"
                    >Delete / Revoke Access</a
                  >
                  <span class="mx-2">|</span>
                </template>
                <span class="text-muted"
                  >Created:
                  {{ token.created | parseDate($userPreferences.dateFormat) }}
                  {{ token.created | parseDate("HH:mm", null, true) }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-input-group prepend="API Key" class="mb-3">
              <b-form-input
                :id="token.api_key"
                :ref="token.api_key"
                v-model="token.api_key"
                type="text"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  :id="copyBtnId"
                  v-b-tooltip.manual="'Copied'"
                  variant="outline-primary"
                  @click="
                    $copyToClipboard(token.api_key, copyBtnId, token.api_key)
                  "
                  ><i class="fa-solid fa-copy"></i> Copy to Clipboard</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-input-group v-if="secret" prepend="API Token">
              <b-form-input
                :id="'secret-value-' + token.access_token_id"
                :ref="'secret-value-' + token.access_token_id"
                v-model="secret"
                type="text"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  :id="copyBtnId2"
                  v-b-tooltip.manual="'Copied'"
                  variant="outline-primary"
                  @click="
                    $copyToClipboard(
                      'secret-value-' + token.access_token_id,
                      copyBtnId2,
                      secret
                    )
                  "
                  ><i class="fa-solid fa-copy"></i> Copy to Clipboard</b-button
                >
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-else prepend="API Token">
              <b-form-input
                type="text"
                :value="displayedSecret"
                prepend="API Token"
                jest-displayed-secret
                readonly
              ></b-form-input>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
/**
 * Displays a card for a FTP token. The card provides links to view details
 * of the token and provides a copiable FTP URL.
 */

import copyToClipboard from "../../mixins/copyToClipboard.js";
import parseDate from "../../mixins/parseDate.js";
import { HTTP } from "../../http-common.js";

export default {
  name: "ApiTokenCard",

  mixins: [copyToClipboard, parseDate],

  props: {
    /**
     * The token object
     *
     * @property {integer} access_token_id token ID
     * @property {string} api_key token name
     * @property {object} configuration token options as JSON
     * @property {object} display_name name to be displayed for the token
     * @property {string} created Date string for when the token was created
     */
    token: {
      type: Object,
      required: true,
    },

    /**
     * Value of the secret
     */
    secret: {
      type: String,
      default: null,
    },

    /**
     * Hides the links to edit and delete a token
     */
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      deleted: false,
    };
  },

  computed: {
    copyBtnId: function () {
      return "btn-copy-" + this.token.api_key;
    },
    copyBtnId2: function () {
      return "btn-copy-" + this.token.api_key + "-value";
    },
    headfastOnly: function () {
      return (
        this.token.configuration &&
        this.token.configuration["headfast_only"] &&
        this.token.configuration["headfast_only"] == 1
      );
    },
    displayedSecret: function () {
      if (this.token.configuration && this.token.configuration["ends_with"]) {
        return (
          "••••••••••••••••••••••••" + this.token.configuration["ends_with"]
        );
      }
      return "•••••••••••••••••••••••••••••";
    },
  },

  methods: {
    deleteClicked: function () {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you wish to delete this API token? The token will no longer work when being used to authenticate the BooksoniX API.",
          {
            title: "Please Confirm",
            okVariant: "success",
            okTitle: "YES",
            cancelVariant: "danger",
            cancelTitle: "NO",
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed == true) {
            try {
              const response = await HTTP.delete(
                this.$baseUrl + "api-token/delete/" + this.token.access_token_id
              );
              if (response.data == "OK") {
                this.$bvModal.msgBoxOk(
                  "Api Token Deleted - Access to the BooksoniX API using this token has been revoked",
                  {
                    title: "Confirmation",
                    okVariant: "success",
                  }
                );
                this.deleted = true;
              }
            } catch (error) {
              let message = "";
              message = error.response.data;
              this.$bvModal.msgBoxOk(message, {
                title: "Could not delete API Token",
                okVariant: "danger",
              });
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.form-control[readonly] {
  background-color: #fff;
}
.headfast-badge {
  font-size: 55% !important;
  font-weight: 300 !important;
  line-height: 1.2;
}
</style>
