<template>
  <b-dropdown
    v-if="contactId"
    text="Jump To"
    variant="none"
    toggle-class="btn-link"
  >
    <b-dropdown-item @click="scrollToElement('contactCardGeneral')">
      General
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('contactCardContact')">
      Contact
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('contactCardMarketing')">
      Marketing
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('contactCardNotes')">
      Notes
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$permitted('royalties/access')"
      @click="scrollToElement('contactCardRoyalties')"
    >
      Royalties Payee Information
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('contactCardEdit')">
      Edit &amp; Status
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
/**
 * Displays a dropdown list of quick jump links
 * to navigate to different scroll positions on the
 * contact detail page
 */
export default {
  name: "ContactQuickJumpDropdown",

  props: {
    /**
     * The id of the contact
     */
    contactId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    /**
     * Scrolls to a HTML ID on a page
     */
    scrollToElement(id) {
      const el = document.getElementById(id);
      if (el != null) {
        setTimeout(function () {
          el.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    },
  },
};
</script>

<style></style>
