<template>
  <div
    id="sortresultsmodal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 ref="title" class="modal-title">Sort Results</h4>
          <div class="close">
            <a
              :href="helpBaseUrl + '/help/searchresults_sort'"
              target="help"
              class="sortresults_helpbtn"
              title="Help"
            >
              <i class="fa fa-question-circle"></i>
            </a>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              Please select which fields you would like to sort on and how they
              are sorted:
            </div>
          </div>
          <form
            id="sortresultsform"
            ref="sortform"
            class="formSimple"
            method="post"
            accept-charset="utf-8"
          >
            <div class="row">
              <div class="col-6">
                <label for="sortfield1">Sort by &nbsp;</label>
                <select
                  id="sortfield1"
                  ref="sortfield1"
                  name="field1"
                  size="1"
                ></select>
              </div>
              <div class="col-6">
                <input
                  id="sortorder1a"
                  ref="sortorder1a"
                  type="radio"
                  name="order1"
                  value="A"
                />
                <label for="sortorder1a">Ascending</label>
                <input
                  id="sortorder1d"
                  ref="sortorder1d"
                  type="radio"
                  name="order1"
                  value="D"
                />
                <label for="sortorder1d">Descending</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="sortfield2">then by &nbsp;</label>
                <select
                  id="sortfield2"
                  ref="sortfield2"
                  name="field2"
                  size="1"
                ></select>
              </div>
              <div class="col-6">
                <input
                  id="sortorder2a"
                  ref="sortorder2a"
                  type="radio"
                  name="order2"
                  value="A"
                />
                <label for="sortorder2a">Ascending</label>
                <input
                  id="sortorder2d"
                  ref="sortorder2d"
                  type="radio"
                  name="order2"
                  value="D"
                />
                <label for="sortorder2d">Descending</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="sortfield3">then by &nbsp;</label>
                <select
                  id="sortfield3"
                  ref="sortfield3"
                  name="field3"
                  size="1"
                ></select>
              </div>
              <div class="col-6">
                <input
                  id="sortorder3a"
                  ref="sortorder3a"
                  type="radio"
                  name="order3"
                  value="A"
                />
                <label for="sortorder3a">Ascending</label>
                <input
                  id="sortorder3d"
                  ref="sortorder3d"
                  type="radio"
                  name="order3"
                  value="D"
                />
                <label for="sortorder3d">Descending</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="sortfield4">then by &nbsp;</label>
                <select
                  id="sortfield4"
                  ref="sortfield4"
                  name="field4"
                  size="1"
                ></select>
              </div>
              <div class="col-6">
                <input
                  id="sortorder4a"
                  ref="sortorder4a"
                  type="radio"
                  name="order4"
                  value="A"
                />
                <label for="sortorder4a">Ascending</label>
                <input
                  id="sortorder4d"
                  ref="sortorder4d"
                  type="radio"
                  name="order4"
                  value="D"
                />
                <label for="sortorder4d">Descending</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="sortfield5">then by &nbsp;</label>
                <select
                  id="sortfield5"
                  ref="sortfield5"
                  name="field5"
                  size="1"
                ></select>
              </div>
              <div class="col-6">
                <input
                  id="sortorder5a"
                  ref="sortorder5a"
                  type="radio"
                  name="order5"
                  value="A"
                />
                <label for="sortorder5a">Ascending</label>
                <input
                  id="sortorder5d"
                  ref="sortorder5d"
                  type="radio"
                  name="order5"
                  value="D"
                />
                <label for="sortorder5d">Descending</label>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            style="margin-right: auto"
            @click="resetSort"
          >
            Reset
          </button>
          <button type="submit" form="sortresultsform" class="btn btn-success">
            Sort
          </button>
          <button type="button" class="btn btn-danger" data-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Sort results form modal
 */
import { HTTP } from "../../http-common.js";

export default {
  name: "SortResultsModal",

  props: {
    /**
     * Search id
     */
    searchId: {
      type: Number,
      required: true,
    },

    /**
     * Page number (results list only)
     */
    page: {
      type: Number,
      default: 1,
    },

    /**
     * Record number (detailed only)
     */
    record: {
      type: Number,
      default: null,
    },

    /**
     * Current format
     */
    format: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      defaultSort: null,
    };
  },

  computed: {
    /**
     * The help base url
     */
    helpBaseUrl: function () {
      if (
        location.hostname.search(
          /^(www\.)?booksonix\.(com|co\.uk|uk|net|org|org\.uk)$/
        ) != -1
      ) {
        return "//www.booksonix.com";
      }

      return "";
    },
  },

  methods: {
    /**
     * Show the sort modal
     */
    showModal() {
      // get the saved search forms
      let url =
        this.$baseUrl +
        "search/sortinfo/" +
        this.searchId +
        "/" +
        (this.record ? this.record : this.page) +
        "/" +
        this.format;
      HTTP.get(url).then((response) => {
        this.displaySortModal(response.data);
      });
    },

    /**
     * Display the sort modal
     */
    displaySortModal(sortInfo) {
      // set the form's url
      let url =
        this.$baseUrl +
        "search/sort/" +
        this.searchId +
        "/" +
        (this.record ? "d/" + this.record : "r/" + this.page) +
        "/" +
        this.format;
      this.$refs.sortform.action = url;

      // store the default sort
      this.defaultSort = sortInfo.default;

      // populate the sort fields
      let fields = sortInfo.fields;
      for (let lineNo = 1; lineNo <= 5; lineNo++) {
        let fieldsElem = this.$refs["sortfield" + lineNo];

        // remove old sort fields
        while (fieldsElem.hasChildNodes()) {
          fieldsElem.removeChild(fieldsElem.firstChild);
        } // while

        // add new sort fields
        fieldsElem.appendChild(document.createElement("option"));
        for (let i = 0; i < fields.length; i++) {
          if (fields[i].group) {
            // add group
            let groupElem = document.createElement("optgroup");
            groupElem.label = fields[i].group;
            fieldsElem.appendChild(groupElem);

            // add
            let groupFields = fields[i].fields;
            for (let j = 0; j < groupFields.length; j++) {
              // add grouped field
              let optionElem = document.createElement("option");
              optionElem.value = groupFields[j].tag;
              optionElem.appendChild(
                document.createTextNode(groupFields[j].name)
              );
              if (
                lineNo <= sortInfo.current.length &&
                sortInfo.current[lineNo - 1].field == groupFields[j].tag
              ) {
                optionElem.selected = true;
              }
              groupElem.appendChild(optionElem);
            } // for
          } else {
            // add non-grouped field
            let optionElem = document.createElement("option");
            optionElem.value = fields[i].tag;
            optionElem.appendChild(document.createTextNode(fields[i].name));
            if (
              lineNo <= sortInfo.current.length &&
              sortInfo.current[lineNo - 1].field == fields[i].tag
            ) {
              optionElem.selected = true;
            }
            fieldsElem.appendChild(optionElem);
          }
        } // for
      } // for

      // set the ascending/descending order radios
      for (
        let lineNo = 0;
        lineNo < sortInfo.current.length && lineNo < 5;
        lineNo++
      ) {
        if (sortInfo.current[lineNo].order == "DESC") {
          this.$refs["sortorder" + (lineNo + 1) + "d"].checked = true;
        } else {
          this.$refs["sortorder" + (lineNo + 1) + "a"].checked = true;
        }
      } // for
      for (let lineNo = sortInfo.current.length; lineNo < 5; lineNo++) {
        this.$refs["sortorder" + (lineNo + 1) + "a"].checked = true;
      } // for

      // show the modal
      window.$("#sortresultsmodal").modal("show");
    },

    /**
     * Reset the sort back to the default values
     */
    resetSort() {
      for (let lineNo = 1; lineNo <= 5; lineNo++) {
        // reset sort line to default
        let fieldsElem = this.$refs["sortfield" + lineNo];
        fieldsElem.selectedIndex = 0;
        this.$refs["sortorder" + lineNo + "a"].checked = true;

        if (lineNo - 1 < this.defaultSort.length) {
          // find the sort field
          let lineField = this.defaultSort[lineNo - 1].field;
          for (let i = 1; i < fieldsElem.length; i++) {
            if (fieldsElem.options[i].value == lineField) {
              // use this sort field
              fieldsElem.selectedIndex = i;
              if (this.defaultSort[lineNo - 1].order == "DESC") {
                this.$refs["sortorder" + lineNo + "d"].checked = true;
              }
              break;
            }
          }
        }
      } // for lineNo
    },
  },
};
</script>

<style>
.sortresults_helpbtn {
  margin-right: 0.5em;
}
#sortresultsmodal select {
  width: 80%;
}
#sortresultsmodal input[type="radio"] + label {
  padding-left: 0.5em;
  padding-right: 2em;
}
</style>
