<template>
  <div>
    <data-requirements-formats
      :formats="formats"
      :accepted-formats="acceptedFormats"
      @change="
        (newFormats) => {
          acceptedFormats = newFormats;
          requirementsChanged();
        }
      "
    />
    <accepted-items-code-list
      caption="Adult Ratings"
      :items="acceptedAdultRatings"
      :all-items="[
        { code: 'S', name: 'Sex' },
        { code: 'V', name: 'Violence' },
        { code: 'D', name: 'Drug-taking' },
        { code: 'L', name: 'Language' },
        { code: 'I', name: 'Intolerance' },
        { code: '0', name: 'Unrated' },
      ]"
      :allow-except="false"
      @change="
        (list) => {
          acceptedAdultRatings = list;
          requirementsChangedCS();
        }
      "
    />
    <accepted-items-auth-dyn-list
      caption="Publishers"
      item-type="publisher"
      :items="acceptedPublishers"
      :except="acceptedPublishersExcept"
      all-items-url="customisation/dissemination-settings/publishers"
      @change="
        (list, except) => {
          acceptedPublishers = list;
          acceptedPublishersExcept = except;
          requirementsChanged();
        }
      "
    />
    <accepted-items-auth-dyn-list
      caption="Imprints"
      item-type="imprint"
      :items="acceptedImprints"
      :except="acceptedImprintsExcept"
      all-items-url="customisation/dissemination-settings/imprints"
      @change="
        (list, except) => {
          acceptedImprints = list;
          acceptedImprintsExcept = except;
          requirementsChanged();
        }
      "
    />
    <accepted-items-auth-dyn-list
      caption="Suppliers"
      item-type="supplier"
      :items="acceptedSuppliers"
      :except="acceptedSuppliersExcept"
      all-items-url="customisation/dissemination-settings/suppliers"
      @change="
        (list, except) => {
          acceptedSuppliers = list;
          acceptedSuppliersExcept = except;
          requirementsChanged();
        }
      "
    />
    <accepted-items-auth-dyn-list
      caption="Series"
      item-type="serie"
      :items="acceptedSeries"
      :except="acceptedSeriesExcept"
      all-items-url="customisation/dissemination-settings/series"
      @change="
        (list, except) => {
          acceptedSeries = list;
          acceptedSeriesExcept = except;
          requirementsChanged();
        }
      "
    />
    <accepted-items-code-list
      caption="Languages"
      :items="acceptedLanguages"
      :except="acceptedLanguagesExcept"
      :all-items="languages"
      @change="
        (list, except) => {
          acceptedLanguages = list;
          acceptedLanguagesExcept = except;
          requirementsChanged();
        }
      "
    />
    <accepted-items-auth-list
      caption="Global Tags"
      :items="acceptedTags"
      :except="acceptedTagsExcept"
      :all-items="globalTags"
      @change="
        (list, except) => {
          acceptedTags = list;
          acceptedTagsExcept = except;
          requirementsChanged();
        }
      "
    />
    <div class="row">
      <div class="col-3">Publication Date</div>
      <div class="col-1 mt-2">
        <input
          :id="'feed' + feedRecipientId + '-requirements-publdate-a'"
          v-model="acceptedpublicationDateMode"
          type="radio"
          value="A"
          @change="requirementsChanged"
        />
        <label :for="'feed' + feedRecipientId + '-requirements-publdate-a'">
          &nbsp;Any
        </label>
      </div>
      <div class="col-3">
        <input
          :id="'feed' + feedRecipientId + '-requirements-publdate-f'"
          v-model="acceptedpublicationDateMode"
          type="radio"
          value="F"
          @change="requirementsChanged"
        />
        <label :for="'feed' + feedRecipientId + '-requirements-publdate-f'">
          &nbsp;Since&nbsp;
        </label>
        <div class="datepicker-container">
          <v-datepicker
            v-model="acceptedpublicationDateFixed"
            format="dd/MM/yyyy"
            :monday-first="true"
            placeholder="dd/mm/yyyy"
            :disabled="acceptedpublicationDateMode != 'F'"
            @input="requirementsChanged"
          />
        </div>
      </div>
      <div class="col-5">
        <input
          :id="'feed' + feedRecipientId + '-requirements-publdate-r'"
          v-model="acceptedpublicationDateMode"
          type="radio"
          value="R"
          @change="requirementsChanged"
        />
        <label :for="'feed' + feedRecipientId + '-requirements-publdate-r'">
          &nbsp;From
          <select
            v-model="acceptedpublicationDateRelativeValue"
            size="1"
            :disabled="acceptedpublicationDateMode != 'R'"
            @change="requirementsChanged"
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
          </select>
          <select
            v-model="acceptedpublicationDateRelativeUnits"
            size="1"
            :disabled="acceptedpublicationDateMode != 'R'"
            @change="requirementsChanged"
          >
            <option value="D">day(s)</option>
            <option value="W">week(s)</option>
            <option value="M">month(s)</option>
          </select>
          <select
            v-model="acceptedpublicationDateRelativeSign"
            size="1"
            :disabled="acceptedpublicationDateMode != 'R'"
            @change="requirementsChanged"
          >
            <option value="+">before publication</option>
            <option value="-">after publication</option>
          </select>
        </label>
      </div>
    </div>
    <accepted-items-code-list
      caption="Publisher Rights"
      :items="acceptedPublisherRights"
      :all-items="countries"
      :allow-except="false"
      @change="
        (list) => {
          acceptedPublisherRights = list;
          requirementsChanged();
        }
      "
    />
    <accepted-items-code-list
      caption="Supplier Rights"
      :items="acceptedSupplierRights"
      :all-items="countries"
      :allow-except="false"
      @change="
        (list) => {
          acceptedSupplierRights = list;
          requirementsChangedCS();
        }
      "
    />
    <div class="row">
      <div class="col-3">Requirement codes</div>
      <div class="col-9">
        <div class="requirementsinfo">
          <a
            href="https://docs.google.com/document/d/1vM0XOQtpHnbsBeVzIiH6aVG-7gKpN3wbhKuVPWmYzoE/preview#heading=h.1vib2g8rj7x7"
            target="help"
            title="Click here for more information"
          >
            <i class="fa-regular fa-circle-question" />
          </a>
        </div>
        <input
          :id="'feed' + feedRecipientId + '-requirements-requirements'"
          v-model="requirementCodes"
          type="text"
          class="requirements-edit"
          pattern="((@[a-z_0-9.]+)?([+\-]([C-FRc-fr][A-Za-z0-9][0-9])+)*|([C-FRc-fr][A-Za-z0-9][0-9])*)"
          @input="requirementCodesChanged"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">&emsp;Resolves to</div>
      <div class="col-9">
        <input type="text" :value="resolvedRequirementCodes" disabled />
      </div>
    </div>
    <div class="row">
      <div class="col-3">&emsp;Default Requirement codes</div>
      <div class="col-9">
        <input type="text" :value="defaultRequirementCodes" disabled />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings data requirements component
 */
import DataRequirementsFormats from "./DataRequirementsFormats.vue";
import AcceptedItemsAuthList from "./AcceptedItemsAuthList.vue";
import AcceptedItemsAuthDynList from "./AcceptedItemsAuthDynList.vue";
import AcceptedItemsCodeList from "./AcceptedItemsCodeList.vue";
import Datepicker from "vuejs-datepicker";
import { HTTP } from "../../../http-common.js";
import { DateTime } from "luxon";

export default {
  name: "DataRequirements",

  components: {
    "data-requirements-formats": DataRequirementsFormats,
    "accepted-items-auth-list": AcceptedItemsAuthList,
    "accepted-items-auth-dyn-list": AcceptedItemsAuthDynList,
    "accepted-items-code-list": AcceptedItemsCodeList,
    "v-datepicker": Datepicker,
  },

  props: {
    /**
     * Feed recipient id
     */
    feedRecipientId: {
      type: Number,
      required: true,
    },

    /**
     * Data requirements object
     */
    dataRequirements: {
      type: Object,
      default: null,
    },

    /**
     * Recipient default requirements
     */
    defaultRequirementCodes: {
      type: String,
      default: "",
    },

    /**
     * List of all return condition types
     */
    returnConditionTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all languages
     */
    languages: {
      type: Array,
      required: true,
    },

    /**
     * List of all countries
     */
    countries: {
      type: Array,
      required: true,
    },

    /**
     * List of all formats
     */
    formats: {
      type: Array,
      required: true,
    },

    /**
     * List of all global tags
     */
    globalTags: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    let dataRequirements = this.dataRequirements ? this.dataRequirements : {};
    let dataObject = {
      acceptedFormats: dataRequirements.formats
        ? dataRequirements.formats
        : null,
      acceptedAdultRatings: [],
      acceptedPublishers: dataRequirements.publishers
        ? dataRequirements.publishers
        : [],
      acceptedPublishersExcept: dataRequirements.publishers_except ?? false,
      acceptedImprints: dataRequirements.imprints
        ? dataRequirements.imprints
        : [],
      acceptedImprintsExcept: dataRequirements.imprints_except ?? false,
      acceptedSuppliers: dataRequirements.suppliers
        ? dataRequirements.suppliers
        : [],
      acceptedSuppliersExcept: dataRequirements.suppliers_except ?? false,
      acceptedSeries: dataRequirements.series ? dataRequirements.series : [],
      acceptedSeriesExcept: dataRequirements.series_except ?? false,
      acceptedLanguages: dataRequirements.languages
        ? dataRequirements.languages
        : [],
      acceptedLanguagesExcept: dataRequirements.languages_except ?? false,
      acceptedTags: dataRequirements.tags ? dataRequirements.tags : [],
      acceptedTagsExcept: dataRequirements.tags_except ?? false,
      requirementCodes: dataRequirements.requirements
        ? dataRequirements.requirements != this.defaultRequirementCodes
          ? dataRequirements.requirements
          : ""
        : "",
      requirementCodes_invalid: false,
      acceptedpublicationDateMode: "A",
      acceptedpublicationDateFixed: "",
      acceptedpublicationDateRelativeValue: 1,
      acceptedpublicationDateRelativeUnits: "M",
      acceptedpublicationDateRelativeSign: "+",
      acceptedPublisherRights: dataRequirements.publisherRights
        ? dataRequirements.publisherRights
        : [],
      acceptedSupplierRights: dataRequirements.supplierRights
        ? dataRequirements.supplierRights
        : [],
    };

    if (dataRequirements.publicationDate) {
      if ("fromDate" in dataRequirements.publicationDate) {
        dataObject.acceptedpublicationDateMode = "F";
        dataObject.acceptedpublicationDateFixed =
          dataRequirements.publicationDate.fromDate;
      } else if ("fromDays" in dataRequirements.publicationDate) {
        dataObject.acceptedpublicationDateMode = "R";
        dataObject.acceptedpublicationDateRelativeValue = Math.abs(
          dataRequirements.publicationDate.fromDays
        );
        dataObject.acceptedpublicationDateRelativeUnits = "D";
        dataObject.acceptedpublicationDateRelativeSign =
          dataRequirements.publicationDate.fromDays >= 0 ? "+" : "-";
      } else if ("fromWeeks" in dataRequirements.publicationDate) {
        dataObject.acceptedpublicationDateMode = "R";
        dataObject.acceptedpublicationDateRelativeValue = Math.abs(
          dataRequirements.publicationDate.fromWeeks
        );
        dataObject.acceptedpublicationDateRelativeUnits = "W";
        dataObject.acceptedpublicationDateRelativeSign =
          dataRequirements.publicationDate.fromWeeks >= 0 ? "+" : "-";
      } else if ("fromMonths" in dataRequirements.publicationDate) {
        dataObject.acceptedpublicationDateMode = "R";
        dataObject.acceptedpublicationDateRelativeValue = Math.abs(
          dataRequirements.publicationDate.fromMonths
        );
        dataObject.acceptedpublicationDateRelativeUnits = "M";
        dataObject.acceptedpublicationDateRelativeSign =
          dataRequirements.publicationDate.fromMonths >= 0 ? "+" : "-";
      }
    }

    if (dataRequirements.adultRatings) {
      for (let i = 0; i < dataRequirements.adultRatings.length; i++) {
        dataObject.acceptedAdultRatings.push(dataRequirements.adultRatings[i]);
      }
    } else {
      dataObject.acceptedAdultRatings.push("S", "V", "D", "L", "I", "0");
    }

    dataObject.resolvedRequirementCodes = this.resolveRequirementCodes(
      dataObject.requirementCodes,
      this.defaultRequirementCodes,
      true
    );

    return dataObject;
  },

  methods: {
    /**
     * Requirements have changed
     */
    requirementsChanged: function () {
      if (!this.requirementCodes_invalid) {
        let newRequirements = {};
        if (
          this.requirementCodes.length != 0 &&
          this.requirementCodes != this.defaultRequirementCodes
        ) {
          newRequirements.requirements = this.requirementCodes;
        }
        if (this.acceptedFormats) {
          newRequirements.formats = this.acceptedFormats;
        }
        if (this.acceptedAdultRatings.length != 6) {
          newRequirements.adultRatings = this.acceptedAdultRatings.join("");
        }
        if (this.acceptedPublishers.length != 0) {
          newRequirements.publishers = this.acceptedPublishers;
          newRequirements.publishers_except = this.acceptedPublishersExcept;
        }
        if (this.acceptedImprints.length != 0) {
          newRequirements.imprints = this.acceptedImprints;
          newRequirements.imprints_except = this.acceptedImprintsExcept;
        }
        if (this.acceptedSuppliers.length != 0) {
          newRequirements.suppliers = this.acceptedSuppliers;
          newRequirements.suppliers_except = this.acceptedSuppliersExcept;
        }
        if (this.acceptedSeries.length != 0) {
          newRequirements.series = this.acceptedSeries;
          newRequirements.series_except = this.acceptedSeriesExcept;
        }
        if (this.acceptedLanguages.length != 0) {
          newRequirements.languages = this.acceptedLanguages;
          newRequirements.languages_except = this.acceptedLanguagesExcept;
        }
        if (this.acceptedTags.length != 0) {
          newRequirements.tags = this.acceptedTags;
          newRequirements.tags_except = this.acceptedTagsExcept;
        }

        if (this.acceptedpublicationDateMode == "F") {
          let dt = DateTime.fromJSDate(this.acceptedpublicationDateFixed);
          newRequirements.publicationDate = {
            fromDate: dt.toFormat("yyyy-MM-dd"),
          };
        } else if (this.acceptedpublicationDateMode == "R") {
          if (this.acceptedpublicationDateRelativeUnits == "D") {
            newRequirements.publicationDate = {
              fromDays:
                this.acceptedpublicationDateRelativeSign == "+"
                  ? parseInt(this.acceptedpublicationDateRelativeValue, 10)
                  : -parseInt(this.acceptedpublicationDateRelativeValue, 10),
            };
          } else if (this.acceptedpublicationDateRelativeUnits == "W") {
            newRequirements.publicationDate = {
              fromWeeks:
                this.acceptedpublicationDateRelativeSign == "+"
                  ? parseInt(this.acceptedpublicationDateRelativeValue, 10)
                  : -parseInt(this.acceptedpublicationDateRelativeValue, 10),
            };
          } else if (this.acceptedpublicationDateRelativeUnits == "M") {
            newRequirements.publicationDate = {
              fromMonths:
                this.acceptedpublicationDateRelativeSign == "+"
                  ? parseInt(this.acceptedpublicationDateRelativeValue, 10)
                  : -parseInt(this.acceptedpublicationDateRelativeValue, 10),
            };
          }
        }

        if (this.acceptedPublisherRights.length != 0) {
          newRequirements.publisherRights = this.acceptedPublisherRights;
        }
        if (this.acceptedSupplierRights.length != 0) {
          newRequirements.supplierRights = this.acceptedSupplierRights;
        }
        this.$emit("change", newRequirements);
      } else {
        this.$emit("data-errors");
      }
    },
    /**
     * Requirements have changed with the clear store option
     */
    requirementsChangedCS: function () {
      this.requirementsChanged();
      if (!this.requirementCodes_invalid) {
        this.$emit("change-clearstore");
      }
    },

    /**
     * Requirement codes have changed
     */
    requirementCodesChanged: function () {
      // check that the requirements codes are valid
      this.requirementCodes = this.requirementCodes.toUpperCase();
      this.requirementCodes_invalid =
        this.requirementCodes.search(
          /^((@[a-z_0-9.]+)?([+-]([C-FR][A-Z0-9][0-9])+)*|([C-FR][A-Z0-9][0-9])*)$/
        ) == -1;
      if (!this.requirementCodes_invalid) {
        // calculate the resolved
        this.resolveRequirementCodes(
          this.requirementCodes,
          this.defaultRequirementCodes,
          false
        );
      }
      this.requirementsChangedCS();
    },

    /**
     * Resolve requirement codes
     */
    resolveRequirementCodes: function (codes, defaultCodes, fromDataSetup) {
      // application defined requirements
      if (codes.search(/^[A-Z]/) == 0) {
        // use application requirement codes
        if (fromDataSetup) {
          return codes;
        }
        this.resolvedRequirementCodes = codes;
        return;
      }

      // application defined feed recipient with optional changes
      if (codes.search(/^@/) == 0) {
        let separator = codes.search(/[+-]/);
        if (separator == -1) {
          separator = codes.length;
        }

        let url =
          this.$baseUrl +
          "customisation/dissemination-settings/requirements/" +
          encodeURIComponent(codes.substring(1, separator));
        HTTP.get(url).then((response) => {
          this.resolveRequirementCodes(
            codes.substring(separator), // changes
            response.data, // feed recipient requirements
            false
          );
        });
        return "";
      }

      // globally defined feed recipient with optional global and/or
      // application defined changes
      if (defaultCodes !== null && defaultCodes.search(/^@/) == 0) {
        let separator = defaultCodes.search(/[+-]/);
        if (separator == -1) {
          separator = defaultCodes.length;
        }

        let url =
          this.$baseUrl +
          "customisation/dissemination-settings/requirements/" +
          encodeURIComponent(defaultCodes.substring(1, separator));
        HTTP.get(url).then((response) => {
          this.resolveRequirementCodes(
            defaultCodes.substring(separator) + codes, // changes
            response.data, // feed recipient requirements
            false
          );
        });
        return "";
      }

      // application defined changes
      if (codes.length != 0) {
        let mergedCodes = defaultCodes ?? "";
        let mode = codes[0];

        for (let i = 1; i < codes.length; i += 3) {
          if (codes[i] == "+" || codes[i] == "-") {
            mode = codes[i];
            i -= 2; // adjust so the for-loop only skips this character
          } else {
            let code = codes.substring(i, i + 3);
            let codepos = mergedCodes.indexOf(code);
            if (mode == "+" && codepos == -1) {
              mergedCodes += code;
            } else if (mode == "-" && codepos != -1) {
              mergedCodes =
                mergedCodes.substring(0, codepos) +
                mergedCodes.substring(codepos + 3);
            }
          }
        } // for

        if (fromDataSetup) {
          return mergedCodes;
        }
        this.resolvedRequirementCodes = mergedCodes;
        return;
      }

      // no application defined changes - use global requirements
      if (fromDataSetup) {
        return defaultCodes ?? "";
      }
      this.resolvedRequirementCodes = defaultCodes ?? "";
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  width: 100%;
}
input.requirements-edit {
  text-transform: uppercase;
}
input.requirements-edit:invalid {
  border: red solid 3px;
}
div.requirementsinfo {
  position: absolute;
  left: -1rem;
  bottom: 0.25rem;
}
div.datepicker-container {
  display: inline-block;
  width: 50%;
}
</style>
