var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"productCardProductFormats"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('a',{attrs:{"id":"formats"}}),_c('h5',{staticClass:"d-inline-block"},[_vm._v("Format")]),(_vm.$permitted('biblio/edit') && _vm.allowEdit)?_c('edit-icon',{staticClass:"ml-2 d-print-none",attrs:{"link":_vm.$baseUrl +
        '/biblio-edit/edit-format/' +
        _vm.productId +
        (_vm.returnTo ? '?return=' + _vm.returnTo : '')}}):_vm._e(),_c('product-quick-jump-dropdown',{ref:"formatQuickJump",staticClass:"float-right",attrs:{"product-id":_vm.productId,"display":_vm.display,"has-custom-fields":_vm.hasCustomFields}})]},proxy:true}])},[_c('info-box',{staticClass:"mx-5"},[_vm._v("Alternate formats are found in the "),_c('b-link',{on:{"click":function($event){return _vm.$refs.formatQuickJump.scrollToElement('productCardRelatedProducts')}}},[_vm._v("Related Products")]),_vm._v(" section")],1),_c('dl',{staticClass:"row"},[(_vm.multipleParts || _vm.product.getFormatName || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("Product Format")]),(_vm.multipleParts || _vm.product.getFormatName)?_c('dd',{staticClass:"col-sm-9"},[(_vm.multipleParts)?[_vm._v(_vm._s(_vm.formatCategoryText))]:[(_vm.product.getNumberOfPieces || _vm.product.getNumberOfCopies)?[_vm._v(_vm._s(_vm.product.getNumberOfPieces ? _vm.product.getNumberOfPieces : _vm.product.getNumberOfCopies)+" x ")]:_vm._e(),_vm._v(_vm._s(_vm.product.getFormatName))],(
            _vm.product.getFormatDetailsNames &&
            _vm.product.getFormatDetailsNames.length > 0
          )?[_vm._v(" – "),_vm._l((_vm.product.getFormatDetailsNames),function(detail,index){return _c('span',{key:index},[_vm._v(_vm._s(detail)),(
                index + 1 < _vm.product.getFormatDetailsNames.length ||
                _vm.product.getProductPackagingName
              )?[_vm._v(", ")]:_vm._e()],2)})]:_vm._e(),(_vm.product.getProductPackagingName)?[_vm._v(" "+_vm._s(_vm.product.getProductPackagingName)+" ")]:_vm._e()],2):_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e(),(_vm.product.getFormatDescription || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"}),_c('dd',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.product.getFormatDescription))])]:_vm._e(),(_vm.product.getCountryOfManufactureName)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"}),_c('dd',{staticClass:"col-sm-9"},[_vm._v(" Manufactured in "+_vm._s(_vm.product.getCountryOfManufactureName)+" ")])]:_vm._e(),(_vm.product.getPaperCertificateTypeName)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"}),(_vm.product.getPaperCertificateTypeName)?_c('dd',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.product.getPaperCertificateTypeName)),(_vm.product.getPaperCertificateNumber)?[_vm._v(", Cert no. "+_vm._s(_vm.product.getPaperCertificateNumber))]:_vm._e(),(_vm.product.getPaperPrePostConsumerWastePercent)?[_vm._v(", "+_vm._s(_vm.product.getPaperPrePostConsumerWastePercent)+"% Claimed Pre- and Post-Consumer Waste")]:_vm._e()],2):_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e(),_vm._l((_vm.product.getProductParts),function(part,index){return [_c('dt',{key:'prod-part-dt-' + index,staticClass:"col-sm-3 font-weight-bold text-secondary pl-5"},[_vm._v(" Contains ")]),_c('dd',{key:'prod-part-dd-' + index,staticClass:"col-sm-9"},[_c('div',[(part.getNumberOfPieces > 0 || part.getNumberOfCopies > 0)?[_vm._v(" "+_vm._s(part.getNumberOfPieces ? part.getNumberOfPieces : part.getNumberOfCopies)+" x ")]:_vm._e(),_vm._v(_vm._s(part.getFormatName)),(part.getFormatDetailsNames.length > 0)?[_vm._v(" - ")]:_vm._e(),_vm._l((part.getFormatDetailsNames),function(partDetail,partIndex){return _c('span',{key:partIndex},[_vm._v(_vm._s(partDetail)),(
                index + 1 < part.getFormatDetailsNames.length ||
                part.getProductPackagingName
              )?[_vm._v(", ")]:_vm._e()],2)}),(part.getProductPackagingName)?[_vm._v(" "+_vm._s(part.getProductPackagingName)+" ")]:_vm._e()],2),(part.getFormatDescription)?_c('div',[_vm._v(" "+_vm._s(part.getFormatDescription)+" ")]):_vm._e(),(part.getCountryOfManufactureName)?_c('div',[_vm._v(" Manufactured in "+_vm._s(part.getCountryOfManufactureName)+" ")]):_vm._e(),(part.getPaperCertificateTypeName)?_c('div',[_vm._v(" "+_vm._s(part.getPaperCertificateTypeName)),(part.getPaperCertificateNumber)?[_vm._v(", Cert no. "+_vm._s(part.getPaperCertificateNumber))]:_vm._e(),(part.getPaperPrePostConsumerWastePercent)?[_vm._v(", "+_vm._s(part.getPaperPrePostConsumerWastePercent)+"% Claimed Pre- and Post-Consumer Waste")]:_vm._e()],2):_vm._e(),_vm._l((part.getAllProductIdentifiers),function(identifier,idIndex){return _c('div',{key:'partIdentifier-' + idIndex},[_vm._v(" "+_vm._s(identifier.typeName)+" "),_c('a',{attrs:{"href":_vm.$baseUrl +
              'search/link/biblio/' +
              _vm.searchId +
              '/' +
              _vm.page +
              '/biblio?ALLID=' +
              identifier.value,"target":"_blank"}},[_vm._v(_vm._s(identifier.value))]),(identifier.value)?_c('barcode-link',{staticClass:"pl-2",attrs:{"id":'barcode-part-' + index + '-' + idIndex,"type":"ISBN","value":identifier.value,"logon-id":_vm.logonId}}):_vm._e()],1)})],2)]}),(_vm.product.getTradeCategoryName || _vm.showAllFields)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("Trade Category")]),(_vm.product.getTradeCategoryName)?_c('dd',{staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(_vm.product.getTradeCategoryName)+" ")]):_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e(),(_vm.$featureEnabled('productUKTaxBand') && _vm.product.getUKTaxBand)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("UK Tax Band")]),_c('dd',{staticClass:"col-9"},[_vm._v(" "+_vm._s(_vm.product.getUKTaxBand)+" ")])]:_vm._e(),(
        (_vm.product.getClassifications.getAll &&
          Object.keys(_vm.product.getClassifications.getAll).length > 0) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Product Classification ")]),(
          _vm.product.getClassifications.getAll &&
          Object.keys(_vm.product.getClassifications.getAll).length > 0
        )?_c('dd',{staticClass:"col-sm-9"},_vm._l((_vm.product.getClassifications.getAll),function(classification,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(classification.getTypeName)+" : "+_vm._s(classification.getValue)+" "),(classification.getPercentage)?_c('span',[_vm._v("("+_vm._s(classification.getPercentage)+"%)")]):_vm._e()])}),0):_c('dd',{staticClass:"col-sm-9"},[_vm._v("—")])]:_vm._e(),(
        (_vm.product.getMeasurements.getAll &&
          Object.keys(_vm.product.getMeasurements.getAll).length > 0) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Dimensions and Measurements ")]),_c('dd',{staticClass:"col-sm-9"},[(
            !(
              _vm.product.getMeasurements.getAll &&
              Object.keys(_vm.product.getMeasurements.getAll).length > 0
            )
          )?[_vm._v("—")]:_vm._e()],2)]:_vm._e(),(
        _vm.product.getMeasurements.getAll &&
        Object.keys(_vm.product.getMeasurements.getAll).length > 0
      )?[_vm._l((_vm.product.getMeasurements.getAll),function(measurement,index){return [_c('dt',{key:'measurement-dt-' + index,staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" "+_vm._s(_vm.getTypeNameString(measurement))+" ")]),_c('dd',{key:'measurement-dd-' + index,staticClass:"col-sm-9"},[_vm._v(" "+_vm._s(measurement.getValue)+" "),(measurement.getUnit == 'gr')?[_vm._v("g")]:[_vm._v(_vm._s(measurement.getUnit))]],2)]})]:_vm._e(),(
        (_vm.product.getMapScales && _vm.product.getMapScales.length > 0) ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v("Map Scales")]),_c('dd',{staticClass:"col-sm-9"},[(_vm.product.getMapScales && _vm.product.getMapScales.length > 0)?_vm._l((_vm.product.getMapScales),function(scale,index){return _c('span',{key:'mapscale-' + index},[_vm._v(" 1:"+_vm._s(scale)),(index < _vm.product.getMapScales.length - 1)?[_vm._v(", ")]:_vm._e()],2)}):_vm._e(),(!(_vm.product.getMapScales && _vm.product.getMapScales.length > 0))?[_vm._v("—")]:_vm._e()],2)]:_vm._e(),(_vm.product.getExtents.getAll)?[_vm._l((_vm.product.getExtents.getAll),function(extent,index){return [_c('dt',{key:'extent-dt-' + index,staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" "+_vm._s(_vm.getExtentTypeNameString(extent))+" ")]),_c('dd',{key:'extent-dd-' + index,staticClass:"col-sm-9"},[(extent.getType == '09')?[(extent.getUnit == '11')?[_vm._v(_vm._s(extent.getValue))]:[_vm._v(" "+_vm._s(_vm._f("formatHHmm")(extent.getValue))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("formatHHmmUnits")(extent.getValue)))])]]:[_vm._v(" "+_vm._s(extent.getValue)+" "),(extent.getType == '22')?[_vm._v(_vm._s(extent.getUnitName))]:_vm._e()]],2)]})]:_vm._e(),(
        (_vm.product.getDigitalConstraints &&
          Object.keys(_vm.product.getDigitalConstraints.getAll).length > 0) ||
        _vm.product.getDigitalLicenseType ||
        _vm.product.getDigitalTechnicalProtection
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Digital Publication Information ")]),_c('dd',{staticClass:"col-9"}),(_vm.product.getDigitalTechnicalProtection)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" Technical Protection ")]),_c('dd',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.product.getDigitalTechnicalProtectionName))])]:_vm._e(),(
          _vm.product.getDigitalConstraints &&
          _vm.product.getDigitalConstraints.getAll &&
          Object.keys(_vm.product.getDigitalConstraints.getAll).length > 0
        )?[_vm._l((_vm.product.getDigitalConstraints.getAll),function(constraint,index){return [_c('dt',{key:'dt-constraint-' + index,staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v(" "+_vm._s(constraint.getTypeName)+" ")]),_c('dd',{key:'dd-constraint-' + index,staticClass:"col-9"},[_vm._v(" "+_vm._s(constraint.getStatusName)),(
                typeof constraint.getAllLimits == 'object' &&
                Object.keys(constraint.getAllLimits).length > 0
              )?[(constraint.getLimitPageRange)?_c('span',[_vm._v(" , Pages "+_vm._s(constraint.getLimitPageRange[0])+" to "+_vm._s(constraint.getLimitPageRange[1])+" ")]):_vm._e(),_vm._l((constraint.getAllLimits),function(limit,limitIndex){return _c('span',{key:limitIndex},[(limitIndex != 11 && limitIndex != 12)?[_vm._v(" , Maximum "+_vm._s(limit.amount)+" "+_vm._s(limit.type)+" ")]:_vm._e()],2)})]:_vm._e()],2)]})]:_vm._e(),(_vm.product.getDigitalLicenseType)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Digital Product License ")]),_c('dd',{staticClass:"col-9"}),(_vm.product.getDigitalLicenseName)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v("Name")]),_c('dd',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.product.getDigitalLicenseName))])]:_vm._e(),(_vm.product.getDigitalLicenseTypeName)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v("Type")]),_c('dd',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.product.getDigitalLicenseTypeName))])]:_vm._e(),(_vm.product.getDigitalLicenseLink)?[_c('dt',{staticClass:"col-sm-3 pl-5 font-weight-bold text-secondary"},[_vm._v("Link")]),_c('dd',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.product.getDigitalLicenseLink))])]:_vm._e()]:_vm._e(),(
          _vm.product.getAllEbookAccessibility &&
          typeof _vm.product.getAllEbookAccessibility == 'object' &&
          Object.keys(_vm.product.getAllEbookAccessibility).length > 0
        )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" eBook Acessibility ")]),_c('dd',{staticClass:"col-9"},_vm._l((_vm.product.getAllEbookAccessibility),function(accessibility,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(accessibility.type)),(accessibility.value)?[_vm._v(" : "+_vm._s(accessibility.value))]:_vm._e()],2)}),0)]:_vm._e()]:_vm._e(),(
        (_vm.product.getAllBatteryTypes &&
          Object.keys(_vm.product.getAllBatteryTypes).length > 0) ||
        _vm.product.getBatteryCapacity ||
        _vm.showAllFields
      )?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Battery Information ")]),_c('dd',{staticClass:"col-sm-9"},[(
            _vm.product.getAllBatteryTypes &&
            Object.keys(_vm.product.getAllBatteryTypes).length > 0
          )?_vm._l((_vm.product.getAllBatteryTypes),function(battery,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(battery.type)+" "),(battery.value)?_c('span',[_vm._v(": "+_vm._s(battery.value))]):_vm._e()])}):_vm._e(),(_vm.product.getBatteryCapacity)?[_vm._v("Battery capacity (Watt-hours) : "+_vm._s(_vm.product.getBatteryCapacity))]:_vm._e(),(
            !(
              (_vm.product.getAllBatteryTypes &&
                Object.keys(_vm.product.getAllBatteryTypes).length > 0) ||
              _vm.product.getBatteryCapacity
            )
          )?[_vm._v("—")]:_vm._e()],2)]:_vm._e(),(_vm.$featureEnabled('lightningSourceBooktype'))?[(_vm.product.getLightningSourceBooktypeName)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Lightning Source Booktype ")]),_c('dd',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.product.getLightningSourceBooktypeName))])]:_vm._e(),(_vm.product.getLightningSourceOrderTypeName)?[_c('dt',{staticClass:"col-sm-3 font-weight-bold text-secondary"},[_vm._v(" Lightning Source Order Type ")]),_c('dd',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.product.getLightningSourceOrderTypeName))])]:_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }