<template>
  <div>
    <base-header class="mt-3">Facebook Catalogue FTP Feeds</base-header>
    <p>
      Use this page to manage Facebook Catalogue FTP feeds. Each feed creates a
      file containing record information, this file is accessible via FTP which
      Facebook Commerce / Shop will use to populate the catalogue.
    </p>
    <p>
      Each catalogue can contain a different set of records, as determined by
      the search. The catalogue FTP file will be re-generated daily using the
      same search, so results are always kept up to date. Click on
      <strong>new catalogue</strong> to start creating an FTP catalogue file.
    </p>
    <ftp-details :ftp-credentials="ftpCredentials">
      The following login details are required to access the FTP catalogue
      files. The same credentials are used for all FTP catalogue feeds.
      <template #no-credentials-text>
        No FTP credentials have been created yet. These must be set up to be
        able to create Facebook Catalogue FTP Feeds. Click the button below to
        create credentials for FTP catalogue files.<br />
        <b-button
          class="mx-auto my-4"
          variant="success"
          size="lg"
          :href="$baseUrl + 'ftp-catalogue-feed/create-credentials'"
          >Create FTP Credentials</b-button
        >
      </template>
    </ftp-details>
    <div v-if="catalogues && ftpCredentials">
      <ftp-catalogue-card
        v-for="(catalogue, index) in catalogues"
        :key="index"
        :catalogue="catalogue"
        :ftp-url="ftpCredentials.url"
        class="my-3"
      ></ftp-catalogue-card>
    </div>
    <div>
      <b-button
        v-if="ftpCredentials !== null"
        variant="success"
        :href="createLink"
      >
        <i class="fa-solid fa-plus"></i> New Catalogue
      </b-button>
      <span v-else id="disabled-wrapper" class="d-inline-block" tabindex="0">
        <b-button variant="success" disabled style="pointer-events: none">
          <i class="fa-solid fa-plus"></i> New Catalogue
        </b-button>
      </span>
      <b-tooltip target="disabled-wrapper"
        >FTP Credentials must be created before Facebook Catalogue FTP Feeds can
        be created.</b-tooltip
      >
      <b-button class="ml-2" variant="outline-secondary" :href="menuLink">
        <i class="fa-solid fa-arrow-left"></i> Return to menu
      </b-button>
    </div>
  </div>
</template>

<script>
/**
 * Displays a list of FTP catalogue cards
 */
import FtpCatalogueCard from "../../ftpCatalogues/FtpCatalogueCard.vue";
import FtpDetails from "../../ftpCatalogues/FtpDetails.vue";

export default {
  name: "FtpCatalogueIndexPage",

  components: {
    "ftp-catalogue-card": FtpCatalogueCard,
    "ftp-details": FtpDetails,
  },

  props: {
    /**
     * The list of FTP catalogues to display.
     * Array of objects, the properties of each object
     * is determined by the catalogue type. These definitions
     * are in the comments for ConfigurationsTable::CF_FTP_CATALOGUE_FEEDS
     */
    catalogues: {
      type: Array,
      default: null,
    },

    /**
     * The FTP credentials to display to the user
     * Definition for the credentials object can be seen in the
     * comments for ConfigurationsTable::CF_APPFTP_DETAILS
     */
    ftpCredentials: {
      type: Object,
      default: null,
    },
  },

  computed: {
    menuLink: function () {
      return this.$baseUrl + "menus/export";
    },
    createLink: function () {
      return this.$baseUrl + "search/form/ftp-catalogue";
    },
  },
};
</script>

<style></style>
