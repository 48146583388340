<template>
  <b-card id="contactCardGeneral">
    <template #header>
      <a id="general" />
      <h5 class="d-inline-block">General</h5>
      <edit-icon
        v-if="$permitted('contacts/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/contacts/edit/' +
          contactId +
          '?current_tab=1' +
          (returnTo ? '&return=' + returnTo : '')
        "
        data-v-step="contact-detail-edit"
      ></edit-icon>
      <contact-quick-jump-dropdown
        class="float-right m-0"
        :contact-id="contactId"
      ></contact-quick-jump-dropdown>
    </template>
    <dl v-if="contact" class="row">
      <dt class="col-sm-4 font-weight-bold text-secondary">Identifier</dt>
      <dd class="col-sm-8">
        {{ contactId | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">
        Contact Type<template v-if="contact.Types.length > 1">s</template>
      </dt>
      <dd class="col-sm-8">
        <template v-if="contact.Types.length > 0">
          <span v-for="(type, index) in contact.Types" :key="index"
            >{{ type.Name
            }}<template v-if="index != contact.Types.length - 1"
              >,
            </template></span
          >
        </template>
        <template v-else>—</template>
        <template v-if="isContributor">
          <div>
            <a :href="titleSearch + '?CCID=' + contactId">View Titles</a>
            which have this Contact as a Contributor
          </div>
          <div>
            <a
              :href="
                titleSearch +
                '?CONTRIB=' +
                contact.FirstNames +
                (contact.Surname ? ' ' + contact.Surname : '')
              "
              >View Titles</a
            >
            which have a Contributor with the same name as this Contact
          </div>
        </template>
        <div v-if="isPublisher && contact.CorporateBody">
          <a :href="titleSearch + '?PUBL=' + contact.CorporateBody"
            >View Titles</a
          >
          which have a Publisher with the same name as this Contact's Corporate
          Name
        </div>
        <div v-if="isDistributor && contact.CorporateBody">
          <a :href="titleSearch + '?SUPL=' + contact.CorporateBody"
            >View Titles</a
          >
          which have a Supplier with the same name as this Contact's Corporate
          Name
        </div>
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Name</dt>
      <dd class="col-sm-8">
        {{ contact.PersonName | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">
        Quals. &amp; Honours
      </dt>
      <dd class="col-sm-8">
        {{ contact.QualificationsHonours | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">ISNI</dt>
      <dd class="col-sm-8">
        {{ contact.ISNI | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">ORCID</dt>
      <dd class="col-sm-8">
        {{ contact.ORCID | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Corporate Name</dt>
      <dd class="col-sm-8">
        {{ contact.CorporateBody | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Date of Birth</dt>
      <dd class="col-sm-8">
        {{
          contact.DateOfBirth
            | parseDate($userPreferences.dateFormat, dateOptions)
            | valueOrPlaceholder
        }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Date of Death</dt>
      <dd class="col-sm-8">
        {{
          contact.DateOfDeath
            | parseDate($userPreferences.dateFormat, dateOptions)
            | valueOrPlaceholder
        }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Nationality</dt>
      <dd class="col-sm-8">
        {{ contact.NationalityCountry | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Place of Birth</dt>
      <dd class="col-sm-8">
        <template v-if="contact.BornTown">
          {{ contact.BornTown
          }}<template v-if="contact.BornCountry">, </template>
        </template>
        <template v-if="contact.BornRegion">
          {{ contact.BornRegion }},
        </template>
        <template v-if="contact.BornCountry">{{
          contact.BornCountry
        }}</template>
        <template v-if="!contact.BornTown && !contact.BornCountry">—</template>
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Place of Death</dt>
      <dd class="col-sm-8">
        <template v-if="contact.DiedTown">
          {{ contact.DiedTown
          }}<template v-if="contact.DiedCountry">, </template>
        </template>
        <template v-if="contact.DiedRegion">
          {{ contact.DiedRegion }},
        </template>
        <template v-if="contact.DiedCountry">{{
          contact.DiedCountry
        }}</template>
        <template v-if="!contact.DiedTown && !contact.DiedCountry">—</template>
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Resides</dt>
      <dd class="col-sm-8">
        <template v-if="contact.ResidesTown">
          {{ contact.ResidesTown
          }}<template v-if="contact.ResidesCountry">, </template>
        </template>
        <template v-if="contact.ResidesRegion">
          {{ contact.ResidesRegion }},
        </template>
        <template v-if="contact.ResidesCountry">{{
          contact.ResidesCountry
        }}</template>
        <template v-if="!contact.ResidesTown && !contact.ResidesCountry"
          >—</template
        >
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Formerly Resided</dt>
      <dd class="col-sm-8">
        <template v-if="contact.FormerlyResidedTown">
          {{ contact.FormerlyResidedTown
          }}<template v-if="contact.FormerlyResidedCountry">, </template>
        </template>
        <template v-if="contact.FormerlyResidedRegion">
          {{ contact.FormerlyResidedRegion }},
        </template>
        <template v-if="contact.FormerlyResidedCountry">{{
          contact.FormerlyResidedCountry
        }}</template>
        <template
          v-if="!contact.FormerlyResidedTown && !contact.FormerlyResidedCountry"
          >—</template
        >
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Educated</dt>
      <dd class="col-sm-8">
        <template v-if="contact.EducatedTown">
          {{ contact.EducatedTown
          }}<template v-if="contact.EducatedCountry">, </template>
        </template>
        <template v-if="contact.EducatedRegion">
          {{ contact.EducatedRegion }},
        </template>
        <template v-if="contact.EducatedCountry">{{
          contact.EducatedCountry
        }}</template>
        <template v-if="!contact.EducatedTown && !contact.EducatedCountry"
          >—</template
        >
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Worked</dt>
      <dd class="col-sm-8">
        <template v-if="contact.WorkedTown">
          {{ contact.WorkedTown
          }}<template v-if="contact.WorkedCountry">, </template>
        </template>
        <template v-if="contact.WorkedRegion">
          {{ contact.WorkedRegion }},
        </template>
        <template v-if="contact.WorkedCountry">{{
          contact.WorkedCountry
        }}</template>
        <template v-if="!contact.WorkedTown && !contact.WorkedCountry"
          >—</template
        >
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Flourished</dt>
      <dd class="col-sm-8">
        <template v-if="contact.FlourishedTown">
          {{ contact.FlourishedTown
          }}<template v-if="contact.FlourishedCountry">, </template>
        </template>
        <template v-if="contact.FlourishedRegion">
          {{ contact.FlourishedRegion }},
        </template>
        <template v-if="contact.FlourishedCountry">{{
          contact.FlourishedCountry
        }}</template>
        <template v-if="!contact.FlourishedTown && !contact.FlourishedCountry"
          >—</template
        >
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Languages Spoken</dt>
      <dd class="col-sm-8">{{ languagesSpoken | valueOrPlaceholder }}</dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">
        Self Identification
      </dt>
      <dd class="col-sm-8">
        {{ contact.SelfIdentification | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">
        Professional Position &amp; Affiliation
      </dt>
      <dd class="col-sm-8">
        {{ professionalPosition | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Biography</dt>
      <dd class="col-sm-8">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="contact.BiographicalNote"></div>
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">
        Professional Position &amp; Affiliation
      </dt>
      <dd class="col-sm-8">
        {{ professionalPosition | valueOrPlaceholder }}
      </dd>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list of contact data
 */
import ContactQuickJumpDropdown from "./ContactQuickJumpDropdown.vue";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ParseDate from "../../mixins/parseDate.js";

export default {
  name: "ContactDescriptiveListGeneral",

  components: {
    "contact-quick-jump-dropdown": ContactQuickJumpDropdown,
  },

  mixins: [DisplaySetting, ParseDate],

  props: {
    /**
     * The ID of the contact
     */
    contactId: {
      type: Number,
      default: null,
    },

    /**
     * Contact object
     */
    contact: {
      type: Object,
      default: () => {},
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Enable or disable edit links
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },
  },

  computed: {
    languagesSpoken: function () {
      if (
        !this.contact.LanguagesSpoken ||
        this.contact.LanguagesSpoken.length == 0
      ) {
        return null;
      }
      let languagesSpoken = "";
      for (const [index, language] of this.contact.LanguagesSpoken.entries()) {
        languagesSpoken += language;
        if (index !== this.contact.LanguagesSpoken.length - 1) {
          languagesSpoken += ", ";
        }
      }
      return languagesSpoken;
    },
    professionalPosition: function () {
      let position = "";
      if (this.contact.ProfessionalAffiliationsPosition) {
        position += this.contact.ProfessionalAffiliationsPosition;
        if (this.contact.ProfessionalAffiliationsOrganisation) {
          position += ", ";
        }
      }
      if (this.contact.ProfessionalAffiliationsOrganisation) {
        position += this.contact.ProfessionalAffiliationsOrganisation;
      }
      return position;
    },

    /**
     * Checks contact types to determine if contact has
     * a contributor role
     */
    isContributor: function () {
      for (let type of this.contact.Types) {
        if (type.Role != null) {
          return true;
        }
      }
      return false;
    },
    /**
     * Checks contact types to determine has a Distributor type
     */
    isDistributor: function () {
      for (let type of this.contact.Types) {
        if (type.Name == "Distributor") {
          return true;
        }
      }
      return false;
    },
    /**
     * Checks contact types to determine has a Publisher type
     */
    isPublisher: function () {
      for (let type of this.contact.Types) {
        if (type.Name == "Publisher") {
          return true;
        }
      }
      return false;
    },
    /**
     * URL for searching biblio titles
     */
    titleSearch: function () {
      return (
        this.$baseUrl +
        "search/link/biblio/" +
        this.searchId +
        "/" +
        this.page +
        "/contact"
      );
    },
  },
};
</script>

<style></style>
