<template>
  <b-card id="productCardRelatedProducts">
    <template #header>
      <a id="related-products" />
      <h5 class="d-inline-block">Related Products</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-related/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <div class="row product-cards-container">
      <div
        v-for="item in uniqueProductList"
        :key="item.getTableId"
        class="col-12 col-lg-6"
      >
        <product-summary-card
          :product="item.getProduct"
          :identifiers="item.getAllProductIdentifiers"
          :same-twi="checkSameTwi(item.getProduct)"
          :search-id="searchId"
          :page="page"
          :date-options="dateOptions"
        ></product-summary-card>
      </div>
    </div>
  </b-card>
</template>

<script>
/**
 * Displays a list of product summary cards for
 * all related products for a product
 */
import ProductSummaryCard from "./ProductSummaryCard.vue";
import ParseDate from "../../mixins/parseDate.js";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "RelatedProducts",
  components: {
    "product-summary-card": ProductSummaryCard,
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },
  mixins: [ParseDate, DisplaySetting],
  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * Product object used to determine
     * which related products share a textual work
     * identifier.
     *
     * The properties set on this object must be:
     * - getTextualWorkDOI
     * - getTextualWorkISBN13
     * - getTextualWorkISTC
     */
    product: {
      type: Object,
      default: null,
    },

    /**
     * The list of related products as returned by
     * BooksonixProduct::getRelatedProducts
     */
    products: {
      type: Array,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Groups together related products that share the
     * same productId
     */
    uniqueProductList: function () {
      let uniqueProducts = [];
      let uniqueProductIds = [];
      for (let product of this.products) {
        if (!product.getProductId) {
          uniqueProducts.push(product);
          continue;
        }
        if (!uniqueProductIds.includes(product.getProductId)) {
          uniqueProducts.push(product);
          uniqueProductIds.push(product.getProductId);
        } else {
          let existingProduct = uniqueProducts.find(
            (obj) => obj.getProductId === product.getProductId
          );
          existingProduct.getProduct.getRelatedTypeName +=
            ", " + product.getProduct.getRelatedTypeName;
        }
      }
      return uniqueProducts;
    },
  },
  methods: {
    /**
     * Checks a product to see if it
     * shares the same TWI as the parent
     * product
     */
    checkSameTwi(comparisonProduct) {
      if (
        (comparisonProduct.getTextualWorkDOI &&
          comparisonProduct.getTextualWorkDOI ==
            this.product.getTextualWorkDOI) ||
        (comparisonProduct.getTextualWorkISBN13 &&
          comparisonProduct.getTextualWorkISBN13 ==
            this.product.getTextualWorkISBN13) ||
        (comparisonProduct.getTextualWorkISTC &&
          comparisonProduct.getTextualWorkISTC ==
            this.product.getTextualWorkISTC)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.product-cards-container {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
}
</style>
