<template>
  <b-modal
    :id="'BiblioSubjectBrowser' + index"
    class="BiblioSubjectBrowser"
    size="xl"
    no-close-on-backdrop
    static
    :hide-backdrop="inModal"
    @shown="showing"
  >
    <template #modal-title>Subject Browser</template>
    <template #modal-footer>
      <form
        ref="SearchForm"
        class="BiblioSubjectBrowserSearchForm"
        @submit="search"
      >
        <input type="text" name="q" value="" />
        <button type="button" class="btn btn-default" @click="search">
          Find
        </button>
      </form>
      <button
        ref="OkBtn"
        type="button"
        class="btn btn-success"
        style="display: none"
        @click="selectTerm('', '')"
      >
        OK
      </button>
      <button type="button" class="btn btn-danger" @click="close">Close</button>
    </template>
    <div ref="BodyInner" class="modal-body-inner" @click="termClick">
      <div
        :id="schemeElemIdPrefix + '21_1'"
        style="display: none"
        @click.stop=""
      >
        <!-- BIC CBMC -->
        <form ref="CBMCform" :name="schemeElemIdPrefix + '21_1_form'">
          <div style="float: left; width: 50%">
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">INTEREST LEVEL</div>
              <!-- category in position 1 of a CBMC code (options are in radio group C1) -->
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_A'"
                  name="C1"
                  type="radio"
                  value="A0-5 years"
                />
                <label :for="'SubjBr' + index + 'TS21_A'">
                  A &ndash; <a>0-5 years</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_B'"
                  name="C1"
                  type="radio"
                  value="B5-7 years"
                />
                <label :for="'SubjBr' + index + 'TS21_B'">
                  B &ndash; <a>5-7 years</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_C'"
                  name="C1"
                  type="radio"
                  value="C7-9 years"
                />
                <label :for="'SubjBr' + index + 'TS21_C'">
                  C &ndash; <a>7-9 years</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_D'"
                  name="C1"
                  type="radio"
                  value="D9-11 years"
                />
                <label :for="'SubjBr' + index + 'TS21_D'">
                  D &ndash; <a>9-11 years</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_E'"
                  name="C1"
                  type="radio"
                  value="E12+ years"
                />
                <label :for="'SubjBr' + index + 'TS21_E'">
                  E &ndash; <a>12+ years</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">&nbsp;</div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">TYPE / FORMAT</div>
              <!-- category in position 3 of a CBMC code (options are in radio group C3) -->
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_F'"
                  name="C3"
                  type="radio"
                  value="FElectronic Format"
                />
                <label :for="'SubjBr' + index + 'TS21_F'">
                  F &ndash; <a>Electronic Format</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_G'"
                  name="C3"
                  type="radio"
                  value="GAnnual"
                />
                <label :for="'SubjBr' + index + 'TS21_G'">
                  G &ndash; <a>Annual</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_H'"
                  name="C3"
                  type="radio"
                  value="HTreasury / Gift Anthology"
                />
                <label :for="'SubjBr' + index + 'TS21_H'">
                  H &ndash; <a>Treasury / Gift Anthology</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_J'"
                  name="C3"
                  type="radio"
                  value="JNovelty Book"
                />
                <label :for="'SubjBr' + index + 'TS21_J'">
                  J &ndash; <a>Novelty Book</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_K'"
                  name="C3"
                  type="radio"
                  value="KBoard / Bath / Rag Book"
                />
                <label :for="'SubjBr' + index + 'TS21_K'">
                  K &ndash; <a>Board / Bath / Rag Book</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_L'"
                  name="C3"
                  type="radio"
                  value="LActivity Book"
                />
                <label :for="'SubjBr' + index + 'TS21_L'">
                  L &ndash; <a>Activity Book</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_M'"
                  name="C3"
                  type="radio"
                  value="MPicture Book"
                />
                <label :for="'SubjBr' + index + 'TS21_M'">
                  M &ndash; <a>Picture Book</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_N'"
                  name="C3"
                  type="radio"
                  value="NOrdinary Printed Book Format"
                />
                <label :for="'SubjBr' + index + 'TS21_N'">
                  N &ndash; <a>Ordinary Printed Book Format</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_P'"
                  name="C3"
                  type="radio"
                  value="PStationery &amp; Other Merchandise"
                />
                <label :for="'SubjBr' + index + 'TS21_P'">
                  P &ndash; <a>Stationery &amp; Other Merchandise</a>
                </label>
              </div>
            </div>
          </div>
          <div style="float: left; width: 50%">
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">BROAD SUBJECT</div>
              <!-- category in position 2 of a CBMC code (options are in radio group C2) -->
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_1'"
                  name="C2"
                  type="radio"
                  value="1Poetry &amp; Plays / Songs &amp; Music"
                />
                <label :for="'SubjBr' + index + 'TS21_1'">
                  1 &ndash; <a>Poetry &amp; Plays / Songs &amp; Music</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_2'"
                  name="C2"
                  type="radio"
                  value="2Home / Early Learning"
                />
                <label :for="'SubjBr' + index + 'TS21_2'">
                  2 &ndash; <a>Home / Early Learning</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_3'"
                  name="C2"
                  type="radio"
                  value="3Fiction"
                />
                <label :for="'SubjBr' + index + 'TS21_3'">
                  3 &ndash; <a>Fiction</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_4'"
                  name="C2"
                  type="radio"
                  value="4Reference"
                />
                <label :for="'SubjBr' + index + 'TS21_4'">
                  4 &ndash; <a>Reference</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_5'"
                  name="C2"
                  type="radio"
                  value="5Non-fiction"
                />
                <label :for="'SubjBr' + index + 'TS21_5'">
                  5 &ndash; <a>Non-fiction</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">&nbsp;</div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">CHARACTER</div>
              <!-- category in position 4 of a CBMC code (options are in radio group C4) -->
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_6'"
                  name="C4"
                  type="radio"
                  value="6Character"
                />
                <label :for="'SubjBr' + index + 'TS21_6'">
                  6 &ndash; <a>Character</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_7'"
                  name="C4"
                  type="radio"
                  value="7Non-character"
                />
                <label :for="'SubjBr' + index + 'TS21_7'">
                  7 &ndash; <a>Non-character</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">&nbsp;</div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">TIE-IN</div>
              <!-- category in position 5 of a CBMC code (options are in radio group C5) -->
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_8'"
                  name="C5"
                  type="radio"
                  value="8TV / Film Tie-in"
                />
                <label :for="'SubjBr' + index + 'TS21_8'">
                  8 &ndash; <a>TV / Film Tie-in</a>
                </label>
              </div>
            </div>
            <div class="SubjBrTerm">
              <div class="SubjBrTermText">
                <input
                  :id="'SubjBr' + index + 'TS21_9'"
                  name="C5"
                  type="radio"
                  value="9Non Tie-in"
                />
                <label :for="'SubjBr' + index + 'TS21_9'">
                  9 &ndash; <a>Non Tie-in</a>
                </label>
              </div>
            </div>
          </div>
        </form>
        <br style="clear: both" />
      </div>
    </div>
    <div
      ref="SearchResults"
      class="BiblioSubjectBrowserSearchResults"
      style="display: none"
    >
      <div class="header">
        Search results
        <button type="button" class="close" @click="hideSearchResults">
          &times;
        </button>
      </div>
      <div ref="SearchResultsBody" @click="termClick"></div>
    </div>
    <div
      :id="'BiblioSubjectBrowser' + index + 'Loading'"
      class="BiblioSubjectBrowserLoading"
      style="display: none"
    >
      <div><i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i></div>
    </div>
  </b-modal>
</template>

<script>
/**
 * Bibliographic subject browser
 *
 * Show browser by calling 'show(scheme,version,[term, [selectParam]])'
 * selected term is emitted using the 'selected' event with the parameters scheme, version, code, heading and selectParam
 */
import { HTTP } from "../../../http-common.js";

export default {
  name: "BiblioSubjectBrowser",

  props: {
    /**
     * Component index
     */
    index: {
      type: Number,
      default: 1,
    },

    /**
     * Is this component displayed within another modal
     */
    inModal: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      schemeElemIdPrefix: "BiblioSubjectBrowser" + this.index + "Scheme_",
      currentScheme: null,
      currentVersion: null,
      isAppScheme: false,
      initialTerm: null,
      currentTermsUrl: null,
      currentSearchUrl: null,
      showTermCodes: false,
      selectParam: null,
      hightlightedTerm: null,
      autoExpandTerms: null,
      autoExpandTermsNC: false,
      infoCache: {},
      loadingLevel: 0,
    };
  },

  methods: {
    /**
     * Show the subject browser
     */
    show: function (scheme, version, term = "", selectParam = null) {
      switch (scheme) {
        case "10":
          this.showTermCodes = false;
          break;
        case "11":
          this.version = "1.1";
          this.showTermCodes = false;
          break;
        case "22":
          version = "2.4";
          this.showTermCodes = false;
          break;
        case "32":
          this.version = "2009";
          this.showTermCodes = false;
          break;
        case "XA":
          this.showTermCodes = false;
          break;
        default:
          this.showTermCodes = true;
          break;
      }
      if (version == "") version = "0";
      this.autoExpandTerms = null;
      this.currentTermsUrl = null;
      this.currentSearchUrl = null;
      this.selectParam = selectParam;

      if (scheme == "21") {
        this.showBICcbmc(term);
        return;
      }

      if (scheme != this.currentScheme || version != this.currentVersion) {
        if (this.currentScheme) {
          document.getElementById(
            this.schemeElemIdPrefix +
              this.currentScheme +
              "_" +
              this.currentVersion
          ).style.display = "none";
          this.$refs.OkBtn.style.display = "none";
          this.$refs.SearchForm.style.display = "";
        }
        this.currentScheme = scheme;
        this.currentVersion = version;

        document.querySelector(
          "#BiblioSubjectBrowser" + this.index + " h5.modal-title"
        ).innerHTML = "";
        let termsDiv = document.getElementById(
          this.schemeElemIdPrefix + scheme + "_" + version
        );
        if (!termsDiv) {
          termsDiv = document.createElement("DIV");
          termsDiv.id = this.schemeElemIdPrefix + scheme + "_" + version;
          termsDiv.dataset.needterms = "Y";
          this.$refs.BodyInner.appendChild(termsDiv);
        } else {
          termsDiv.style.display = "";
          this.collapseAll();
        }
      } else {
        this.collapseAll();
      }
      this.hideSearchResults();
      this.$refs.SearchForm.q.value = "";

      this.initialTerm = term;

      this.$bvModal.show("BiblioSubjectBrowser" + this.index);
    },

    /**
     * Show BIC CBMC subject browser
     */
    showBICcbmc: function (term) {
      if (this.currentScheme && this.currentScheme != "21") {
        document.getElementById(
          this.schemeElemIdPrefix +
            this.currentScheme +
            "_" +
            this.currentVersion
        ).style.display = "none";
        this.hideSearchResults();
      }

      this.currentScheme = "21";
      this.currentVersion = "1";
      document.querySelector(
        "#BiblioSubjectBrowser" + this.index + " h5.modal-title"
      ).innerHTML = "BIC Children's Book Marketing Category";
      document.getElementById(this.schemeElemIdPrefix + "21_1").style.display =
        "";
      this.$refs.OkBtn.style.display = "";
      this.$refs.SearchForm.style.display = "none";

      let form = this.$refs.CBMCform;
      for (let i = 0; i < form.length; i++) {
        form[i].checked = term.indexOf(form[i].value.charAt(0)) != -1;
      }

      this.$bvModal.show("BiblioSubjectBrowser" + this.index);
    },

    /**
     * The modal is shown
     */
    showing: function () {
      if (!this.currentScheme || this.currentScheme == "21") {
        return;
      }

      let infoCacheKey =
        this.currentScheme +
        "\t" +
        this.currentVersion +
        "\t" +
        this.initialTerm;
      if (infoCacheKey in this.infoCache) {
        this.ajaxTermInfo(this.infoCache[infoCacheKey]);
      } else {
        this.showLoading(true);

        let url =
          this.$baseUrl +
          "biblio-edit/subject-browser-info/" +
          encodeURIComponent(this.currentScheme) +
          "/" +
          encodeURIComponent(this.currentVersion) +
          "/" +
          encodeURIComponent(this.initialTerm);

        HTTP.get(url).then((response) => {
          this.ajaxTermInfo(response.data);
        });
      }
    },

    /**
     * Collapse all branches
     */
    collapseAll: function () {
      let termsDiv = document.getElementById(
        this.schemeElemIdPrefix + this.currentScheme + "_" + this.currentVersion
      );
      if (termsDiv) {
        let nodes = termsDiv.querySelectorAll(
          "div.SubjBrTermChild[data-expanded='Y']"
        );
        for (let i = 0; i < nodes.length; i++) {
          nodes[i].dataset.expanded = "N";
          nodes[i].style.display = "none";
          nodes[i].previousElementSibling.querySelector("span.fa").className =
            "fa fa-plus-square-o";
        }
      }
    },

    /**
     * Show the loading wheel
     */
    showLoading: function (bShow) {
      if (bShow) {
        this.loadingLevel++;
      } else if (this.loadingLevel > 0) {
        this.loadingLevel--;
      }
      let loadingDiv = document.getElementById(
        "BiblioSubjectBrowser" + this.index + "Loading"
      );
      loadingDiv.style.display = this.loadingLevel >= 1 ? "flex" : "none";
    },

    /**
     * Close the browser
     */
    close: function () {
      this.$bvModal.hide("BiblioSubjectBrowser" + this.index);
    },

    /**
     * Have received AJAX term information
     */
    ajaxTermInfo: function (infoData) {
      this.showLoading(false);
      this.isAppScheme = infoData.appScheme;
      this.infoCache[
        infoData.schemeCode +
          "\t" +
          infoData.schemeVersion +
          "\t" +
          (infoData.code ? infoData.code : "")
      ] = infoData;

      this.currentTermsUrl =
        this.$baseUrl +
        "biblio-edit/subject-browser-terms/" +
        (infoData.appScheme ? "A" : "C") +
        "/" +
        encodeURIComponent(this.currentScheme) +
        "/" +
        encodeURIComponent(this.currentVersion) +
        "/";

      this.currentSearchUrl =
        this.$baseUrl +
        "biblio-edit/subject-browser-search/" +
        (infoData.appScheme ? "A" : "C") +
        "/" +
        encodeURIComponent(this.currentScheme) +
        "/" +
        encodeURIComponent(this.currentVersion) +
        "?q=";

      document.querySelector(
        "#BiblioSubjectBrowser" + this.index + " h5.modal-title"
      ).innerHTML = infoData.schemeName;

      let termsDiv = document.getElementById(
        this.schemeElemIdPrefix + this.currentScheme + "_" + this.currentVersion
      );
      if (termsDiv && termsDiv.dataset.needterms == "Y") {
        this.showLoading(true);
        HTTP.get(this.currentTermsUrl).then((response) => {
          this.ajaxTerms(response.data);
        });
      }

      if (infoData.parents && infoData.parents.length != 0) {
        infoData.parents[infoData.parents.length] = infoData.id;
        this.expandTerms(infoData.parents);
      }
    },

    /**
     * Term click handler
     */
    termClick: function (bvModalEvent) {
      bvModalEvent.preventDefault();

      let target = bvModalEvent.target;
      if (target.nodeName == "SPAN") {
        target = target.parentElement;
      }

      if ("clickExpand" in target.dataset) {
        if (target.dataset.clickExpand.indexOf(",") != -1) {
          this.expandTerms(target.dataset.clickExpand.split(","));
        } else {
          this.expandTerms(target.dataset.clickExpand);
        }
      } else if ("clickSelectC" in target.dataset) {
        this.selectTerm(
          target.dataset.clickSelectC,
          target.dataset.clickSelectH
        );
      }
    },

    /**
     * Selected a term
     */
    selectTerm: function (code, heading) {
      if (this.currentScheme == "21") {
        // the order that the CBMC category values are combined to form a CBMC code is important.
        // the options for the first category (in the first position) are in radio group C1,
        // the options for the second category are in radio group C2, and so on up to C5.
        // get the selected value for each category in the correct order reporting an error if
        // nothing is selected in a category
        let form = this.$refs.CBMCform;
        for (let i = 1; i <= 5; i++) {
          // category loop
          let radioCtrl = form["C" + i];
          let haveItem = false;
          for (let j = 0; j < radioCtrl.length; j++) {
            // options in category loop
            if (radioCtrl[j].checked) {
              code += radioCtrl[j].value.charAt(0);
              heading += ", " + radioCtrl[j].value.substr(1);
              haveItem = true;
              break;
            }
          }
          if (!haveItem) {
            switch (i) {
              case 1:
                this.$bvModal.msgBoxOk(
                  "You must select an option from the 'INTEREST LEVEL' category"
                );
                break;
              case 2:
                this.$bvModal.msgBoxOk(
                  "You must select an option from the 'BROAD SUBJECT' category"
                );
                break;
              case 3:
                this.$bvModal.msgBoxOk(
                  "You must select an option from the 'TYPE / FORMAT' category"
                );
                break;
              case 4:
                this.$bvModal.msgBoxOk(
                  "You must select an option from the 'CHARACTER' category"
                );
                break;
              case 5:
                this.$bvModal.msgBoxOk(
                  "You must select an option from the 'TIE-IN' category"
                );
                break;
            }
            return;
          }
        }
        heading = heading.substr(2);
      }
      this.$emit(
        "selected",
        this.currentScheme,
        this.currentVersion != "0" ? this.currentVersion : "",
        code,
        heading,
        this.selectParam
      );
      this.close();
    },

    /**
     * Have received AJAX terms
     */
    ajaxTerms: function (termsData) {
      this.showLoading(false);
      let parentNode;
      if (termsData.parentId != 0) {
        parentNode = document.getElementById(
          "SubjBr" + this.index + "C" + termsData.parentId
        );
      } else {
        parentNode = document.getElementById(
          this.schemeElemIdPrefix +
            this.currentScheme +
            "_" +
            this.currentVersion
        );
      }
      parentNode.dataset.needterms = "N";

      if (termsData.terms.length == 0) {
        let newNode = document.createElement("DIV");
        let newNodeHtml =
          "<div>No terms available for this scheme version</div>";
        newNode.innerHTML = newNodeHtml;
        parentNode.appendChild(newNode);
      } else {
        for (let i = 0; i < termsData.terms.length; i++) {
          let newNode = document.createElement("DIV");
          newNode.className = "SubjBrTerm";
          let newNodeHtml =
            '<div id="SubjBr' +
            this.index +
            "T" +
            termsData.terms[i].id +
            '" class="SubjBrTermText">';
          if (termsData.terms[i].children) {
            newNodeHtml +=
              '<a href="#" data-click-expand="' +
              termsData.terms[i].id +
              '"><span class="fa fa-plus-square-o"></span></a>';
          } else {
            newNodeHtml += '<span class="fa-noicon">&nbsp;</span>';
          }
          if (this.showTermCodes && termsData.terms[i].code) {
            newNodeHtml +=
              this.htmlEscape(termsData.terms[i].code) + " &ndash; ";
          }
          if (
            termsData.terms[i].code ||
            (this.isAppScheme && termsData.terms[i].code !== null)
          ) {
            newNodeHtml +=
              '<a href="#" data-click-select-c="' +
              this.htmlEscape(termsData.terms[i].code.replace(/'/g, "\\'")) +
              '" data-click-select-h="' +
              this.htmlEscape(termsData.terms[i].heading.replace(/'/g, "\\'")) +
              '">' +
              this.htmlEscape(termsData.terms[i].caption) +
              "</a>";
          } else if (termsData.terms[i].children) {
            newNodeHtml +=
              '<a href="#" data-click-expand="' +
              termsData.terms[i].id +
              '">' +
              this.htmlEscape(termsData.terms[i].caption) +
              "</a>";
          } else {
            newNodeHtml += this.htmlEscape(termsData.terms[i].caption);
          }
          newNodeHtml += "</div>";

          if (termsData.terms[i].children) {
            newNodeHtml +=
              '<div id="SubjBr' +
              this.index +
              "C" +
              termsData.terms[i].id +
              '" class="SubjBrTermChild" style="display:none" data-needterms="Y" data-expanded="N"></div>';
          }
          newNode.innerHTML = newNodeHtml;
          parentNode.appendChild(newNode);
        } // for
      }

      if (this.autoExpandTerms) {
        let expandTerms = this.autoExpandTerms;
        this.autoExpandTerms = null;
        this.expandTerms(expandTerms, this.autoExpandTermsNC);
      } else if (termsData.parentId != 0) {
        parentNode.dataset.expanded = "Y";
        parentNode.style.display = "";
        parentNode.previousElementSibling.querySelector("span.fa").className =
          "fa fa-minus-square-o";
        this.makeVisible(parentNode.previousElementSibling, true);
      }
    },

    /**
     * Expand terms
     */
    expandTerms: function (id, noCollapse = false) {
      let idList = Array.isArray(id) ? id : [id];
      for (let i = 0; i < idList.length; i++) {
        let parentNode = document.getElementById(
          "SubjBr" + this.index + "C" + idList[i]
        );
        if (!parentNode) {
          let termNode = document.getElementById(
            "SubjBr" + this.index + "T" + idList[i]
          );
          if (termNode) {
            this.makeVisible(termNode, true);
            break;
          }
          this.autoExpandTerms = idList;
          this.autoExpandTermsNC = noCollapse;
          break;
        }

        if (parentNode.dataset.needterms == "Y") {
          this.showLoading(true);
          this.autoExpandTerms = i < idList.length - 1 ? idList.slice(i) : null;
          this.autoExpandTermsNC = noCollapse;

          HTTP.get(this.currentTermsUrl + idList[i]).then((response) => {
            this.ajaxTerms(response.data);
          });
          break;
        }

        if (parentNode.dataset.expanded == "N") {
          parentNode.dataset.expanded = "Y";
          parentNode.style.display = "";
          parentNode.previousElementSibling.querySelector("span.fa").className =
            "fa fa-minus-square-o";
        } else if (!noCollapse) {
          parentNode.dataset.expanded = "N";
          parentNode.style.display = "none";
          parentNode.previousElementSibling.querySelector("span.fa").className =
            "fa fa-plus-square-o";
        }
        this.makeVisible(
          parentNode.previousElementSibling,
          i == idList.length - 1
        );
      } // for
    },

    /**
     * Search
     */
    search: function () {
      let searchTerm = this.$refs.SearchForm.q.value;
      if (searchTerm != "") {
        this.showLoading(true);

        HTTP.get(this.currentSearchUrl + encodeURIComponent(searchTerm)).then(
          (response) => {
            this.ajaxSearchResults(response.data);
          }
        );
      } else {
        this.hideSearchResults();
      }
    },

    /**
     * Have received AJAX search results
     */
    ajaxSearchResults: function (searchResults) {
      this.showLoading(false);
      if (searchResults.length == 0) {
        this.hideSearchResults();
        this.$bvModal.msgBoxOk("No subject terms found");
        return;
      }

      let resultsHtml = "";
      for (let i = 0; i < searchResults.length; i++) {
        let termIds = searchResults[i].parents ? searchResults[i].parents : [];
        termIds[termIds.length] = searchResults[i].id;
        if (this.showTermCodes && searchResults[i].code) {
          resultsHtml += this.htmlEscape(searchResults[i].code) + " &ndash; ";
        }
        resultsHtml +=
          '<a href="#" data-click-expand="' + termIds.join(",") + '"';
        if (searchResults[i].heading != searchResults[i].caption) {
          resultsHtml +=
            ' title="' + this.htmlEscape(searchResults[i].heading) + '"';
        }
        resultsHtml +=
          ">" + this.htmlEscape(searchResults[i].caption) + "</a><br>";
      } // foreach

      this.$refs.SearchResultsBody.innerHTML = resultsHtml;
      this.$refs.BodyInner.className = "modal-body-inner with-search-results";
      this.$refs.SearchResults.style.display = "block";
    },

    /**
     * Hide search results
     */
    hideSearchResults: function () {
      this.$refs.SearchResults.style.display = "none";
      this.$refs.BodyInner.className = "modal-body-inner";
    },

    /**
     * HTML escape a string
     */
    htmlEscape: function (string) {
      return string
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/"/g, "&quot;");
    },

    /**
     * Make an element visible
     */
    makeVisible: function (element, highlight = false) {
      if (element.offsetParent) {
        let container = element.offsetParent.firstElementChild;
        if (
          element.offsetTop < container.scrollTop ||
          element.offsetTop + element.clientHeight >
            container.scrollTop + container.clientHeight
        ) {
          element.scrollIntoView();
        }
      }
      if (highlight) {
        if (this.hightlightedTerm) {
          this.hightlightedTerm.style.fontWeight = "";
        }
        this.hightlightedTerm = element;
        element.style.fontWeight = "bold";
      }
    },
  },
};
</script>

<style>
.BiblioSubjectBrowser div.modal-body {
  padding: 0px;
  height: 500px;
}

.BiblioSubjectBrowser div.modal-body-inner {
  float: left;
  padding: 15px;
  height: 500px;
  width: 100%;
  overflow: auto;
}
.BiblioSubjectBrowser div.modal-body-inner.with-search-results {
  width: calc(100% - 300px);
}
.BiblioSubjectBrowserSearchResults {
  width: 300px;
  height: 480px;
  overflow: auto;
  border-left: 1px solid #e5e5e5;
  padding-left: 10px;
  margin-top: 20px;
  float: right;
}

.BiblioSubjectBrowserSearchResults .header {
  position: fixed;
  top: 58px;
  width: 285px;
  background-color: white;
}

.BiblioSubjectBrowserLoading {
  display: none;
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.BiblioSubjectBrowserSearchForm {
  width: 300px;
  float: left;
  text-align: left;
}
.BiblioSubjectBrowserSearchForm input[type="text"] {
  width: 200px;
}
.BiblioSubjectBrowser div.SubjBrTerm {
  clear: both;
  margin-left: 12px;
}
.BiblioSubjectBrowser div.SubjBrTermText,
.BiblioSubjectBrowser div.SubjBrTermChild {
  padding-left: 4px;
}
.BiblioSubjectBrowser div.SubjBrTermText input[type="radio"] {
  margin-right: 4px;
}
.BiblioSubjectBrowser div.SubjBrTermText span.fa {
  padding-right: 4px;
  color: black;
}
.BiblioSubjectBrowser div.SubjBrTermText span.fa-noicon {
  display: inline-block;
  width: 15px;
}
</style>
