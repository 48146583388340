<template>
  <b-card id="productCardPrizesAndPromotion">
    <template #header>
      <a id="prizes-and-promotions" />
      <h5 class="d-inline-block">Prizes & Promotion</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-prizes/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <dl class="row">
      <template v-if="prizes.length > 0">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Prizes / Awards
        </dt>
        <dd class="col-sm-9">
          <div v-for="(prize, index) in prizes" :key="index" class="row mb-2">
            <div class="col">
              <div>
                {{ prize.getName }}, {{ prize.getYear }},
                {{ prize.getCountryName }} - {{ prize.getAchievementName }}
              </div>
              <!-- prettier-ignore -->
              <div class="pl-1 preserve-white-space">{{ prize.getJury }}</div>
            </div>
            <div class="col">
              <div
                v-if="prize.getImage"
                class="d-flex flex-wrap justify-content-start"
              >
                <product-media-img
                  style="min-width: 150px; height: 200px"
                  thumbnail
                  :url="'/' + $appId + '/' + prize.getImage"
                  :share-media-modal-id="shareMediaModalId"
                  :logon-id="logonId"
                  :date-options="dateOptions"
                  :img-height="200"
                ></product-media-img>
              </div>
            </div>
          </div>
        </dd>
      </template>
      <template v-else-if="showAllFields"
        ><dt class="col-sm-3 font-weight-bold text-secondary">
          Prizes / Awards
        </dt>
        <dd class="col-sm-9">—</dd></template
      >
      <template
        v-if="
          promotion &&
          (promotion.getCampaignInformation ||
            promotion.getContactDetails ||
            promotion.getInitialPrintRun ||
            promotion.getCopiesSold)
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Promotional Information
        </dt>
        <dd class="col-sm-9"></dd>
        <template v-if="promotion.getCampaignInformation">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
            Campaign Information ({{
              promotion.getCampaignInformationFormat == "02" ? "HTML" : "Text"
            }})<template v-if="promotion.getCampaignInformationInternal">
              (Internal)</template
            >
          </dt>
          <dd class="col-sm-9">
            <template v-if="promotion.getCampaignInformationFormat == '02'">
              <!-- eslint-disable vue/no-v-html -->
              <div v-html="promotion.getCampaignInformation"></div>
              <!--eslint-enable-->
            </template>
            <!-- prettier-ignore -->
            <template v-else class="preserve-white-space">{{ promotion.getCampaignInformation }}</template>
          </dd>
        </template>
        <template v-if="promotion.getContactDetails">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
            Contact Details<template v-if="promotion.getContactDetailsInternal">
              (Internal)</template
            >
          </dt>
          <dd class="col-sm-9">
            {{ promotion.getContactDetails }}
          </dd>
        </template>
        <template v-if="promotion.getInitialPrintRun">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
            Initial Print Run
          </dt>
          <dd class="col-sm-9">
            {{ promotion.getInitialPrintRun }}
          </dd>
        </template>
        <template v-if="promotion.getCopiesSold">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
            Copies Sold
          </dt>
          <dd class="col-sm-9">
            {{ promotion.getCopiesSold }}
          </dd>
        </template>
      </template>
      <template v-else-if="showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Promotional Information
        </dt>
        <dd class="col-sm-9">—</dd>
      </template>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card that contains a descriptive list
 * of product prize and promotion information
 */
import ProductMediaImg from "./ProductMediaImg.vue";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ParseDate from "../../mixins/parseDate.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "PrizesAndPromotions",

  components: {
    "product-media-img": ProductMediaImg,
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [DisplaySetting, ParseDate],

  props: {
    /**
     * The Id of the product
     */
    productId: {
      type: [Number, String],
      default: null,
    },

    /**
     * List of prizes/awards
     *
     * Dump of BooksonixProduct::getPrizes
     */
    prizes: {
      type: Array,
      default: () => [],
    },

    /**
     * Promotion information
     *
     * Dump of BooksonixProduct::getPromotion
     */
    promotion: {
      type: Object,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * The logon (session) ID of the current user
     */
    logonId: {
      type: String,
      default: null,
    },

    /**
     * The ID and Ref value of the share media modal
     */
    shareMediaModalId: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
