<template>
  <div class="row searchformdropdownline">
    <div class="col-1">
      <input :name="'bool' + lineNo" type="hidden" :value="bool" />
      <slot name="bool"></slot>
    </div>
    <div class="col-4">
      <input :name="'field' + lineNo" type="hidden" :value="field" />
      <slot name="field"></slot>
    </div>
    <div class="col-7 searchformdropdownline_term">
      <input
        v-if="filter"
        :name="'type' + lineNo"
        type="hidden"
        :value="'text' + (filter ? '/f' : '')"
      />
      <select
        :id="'TermCtrl' + lineNo"
        v-model="currentTerm"
        :name="'term' + lineNo"
        size="1"
      >
        <option v-if="emptyOption" :key="lineNo + '.eo'" value="">
          {{ emptyOption }}
        </option>
        <option
          v-for="(option, optionIndex) in options"
          :key="lineNo + '.' + (optionIndex + 1) + 'o'"
          :value="option.value"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
/**
 * Display a search form dropdown line (fixed field)
 *
 * The following form controls are output:
 *   'bool{lineNo}' (hidden), 'field{lineNo}' (hidden), 'type{lineNo}' = 'select/f' (hidden, optional), 'term{lineNo}' (select)
 */
export default {
  name: "SearchFormDropdownLine",

  props: {
    /**
     * The line number
     */
    lineNo: {
      type: Number,
      required: true,
    },

    /**
     * The dropdown options. An array of objects each with the properties 'value' (string), 'label' (string) and 'disabled' (boolean, optional)
     */
    options: {
      type: Array,
      required: true,
    },

    /**
     * The empty option caption. If null then no empty option.
     */
    emptyOption: {
      type: String,
      default: null,
    },

    /**
     * The boolean (fixed value)
     */
    bool: {
      type: String,
      default: "AND",
    },

    /**
     * The field (fixed value)
     */
    field: {
      type: String,
      required: true,
    },

    /**
     * The initial term
     */
    initialTerm: {
      type: String,
      default: "",
    },

    /**
     * Is a filter line?
     */
    filter: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      currentTerm: this.initialTerm,
    };
  },
};
</script>

<style>
.searchformdropdownline_term select {
  width: 100%;
}
</style>
