<template>
  <b-modal :id="id" static lazy hide-header hide-footer @shown="modalShowing">
    <iframe
      name="TagsAssignFrame"
      :src="!usePostRequest ? tagsAssignUrl : null"
      frameborder="0"
      style="width: 100%; height: 280px; display: block"
    ></iframe>
    <form
      v-if="usePostRequest"
      ref="tagsAssignFrameForm"
      :action="tagsAssignUrl"
      method="post"
      target="TagsAssignFrame"
    >
      <input name="ids" type="hidden" :value="recordIdsString" />
    </form>
  </b-modal>
</template>

<script>
/**
 * Displays a modal that will provide an edit form for a records tags.
 *
 * Tags can be edited for a single product or contact, or multiple marked records.
 *
 * When used the component must be given an id:
 * <edit-tags-modal id="myComponent"></edit-tags-modal>
 * It can then be displayed using:
 * this.$bvModal.show("myComponent");
 *
 * As a quirk of interacting between a Vue component and an iframe
 * a ref MUST be given to this component which has the same value
 * as the id prop:
 * <edit-tags-modal id="myComponent" ref="myComponent"></edit-tags-modal>
 * And this ref must then be added to Vues $root, this is best done in the
 * mounted method of the parent component:
 *
 * mounted: function () {
 *   this.$root.$refs['myComponent'] = this.$refs['myComponent'];
 * }
 */
export default {
  name: "EditTagsModal",

  props: {
    /**
     * The modals elements ID.
     *
     * This is required and must be unique if multiple
     * modals are used on a single page
     */
    id: {
      type: String,
      required: true,
    },

    /**
     * List of marked record numbers to be edited
     */
    recordIds: {
      type: Array,
      default: null,
    },

    /**
     * The marked ids list is a list of marked records
     */
    marked: {
      type: Boolean,
      default: false,
    },

    /**
     * The type of record being edited
     *
     * @options product, contact
     */
    recordType: {
      type: String,
      default: "product",
    },
  },

  computed: {
    /**
     * Do we need to use a POST request?
     */
    usePostRequest: function () {
      return this.recordIdsString.length > 1000;
    },

    /**
     * Tags assign iframe url
     */
    tagsAssignUrl: function () {
      return (
        this.$baseUrl +
        "tags/assign/" +
        this.recordType +
        "?" +
        (!this.usePostRequest
          ? "ids=" + encodeURIComponent(this.recordIdsString) + "&"
          : "") +
        "fnpfx=" +
        encodeURIComponent("window.vueApp.$root.$refs['" + this.id + "'].") +
        "&closefn=closeModal" +
        "&applyfn=applyTags" +
        "&createfn=tagCreated" +
        (this.marked ? "&marked=1" : "")
      );
    },

    /**
     * Record ids string
     */
    recordIdsString: function () {
      return this.recordIds.join(",");
    },
  },

  methods: {
    /**
     * Close the modal
     */
    closeModal: function () {
      this.$bvModal.hide(this.id);
    },

    /**
     * The modal is being shown
     */
    modalShowing: function () {
      if (this.usePostRequest) {
        this.$refs.tagsAssignFrameForm.submit();
      }
    },

    /**
     * Called from the iframe when tags are applied
     */
    applyTags: function (tagsToRemove, tagsToAdd) {
      /**
       * Tags applied event
       *
       * @event
       *
       * @property {array} tagsToRemove List of tagIds to remove from the record
       * @property {array} tagsToAdd List of tagIds to add to the record
       */
      this.$emit("tagsApplied", tagsToRemove, tagsToAdd);
    },
    /**
     * Called from the iframe when a tag is created
     */
    tagCreated: function (id, name, level) {
      /**
       * Tags created event
       *
       * @event
       *
       * @property {number} id The ID of the created tag
       * @property {string} name The name of the tag
       * @property {string} level The level of the tag 'USER' or 'GLOBAL'
       */
      this.$emit("tagCreated", id, name, level);
    },
  },
};
</script>

<style scoped>
/deep/ .modal-body {
  padding: 0px;
}
</style>
