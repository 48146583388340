var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"productCardFullText"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('a',{attrs:{"id":"full-text"}}),_c('h5',{staticClass:"d-inline-block"},[_vm._v("Full Text")]),(_vm.$permitted('biblio/edit') && _vm.allowEdit)?_c('edit-icon',{staticClass:"ml-2 d-print-none",attrs:{"link":_vm.$baseUrl +
        '/biblio-edit/edit-fulltext/' +
        _vm.productId +
        (_vm.returnTo ? '?return=' + _vm.returnTo : '')}}):_vm._e(),_c('product-quick-jump-dropdown',{staticClass:"float-right",attrs:{"product-id":_vm.productId,"display":_vm.display,"has-custom-fields":_vm.hasCustomFields}})]},proxy:true}])},[(_vm.descriptions.length > 0)?_c('dl',{staticClass:"row"},[_vm._l((_vm.descriptions),function(description,index){return [_c('dt',{key:'description-dt-' + index,staticClass:"col-sm-11 font-weight-bold text-secondary"},[_vm._v(" Full Text"),(description.getInternal)?[_vm._v(" (Internal)")]:_vm._e(),_vm._v(" – "+_vm._s(description.getTypeName)+" ("+_vm._s(description.getFormat == "02" ? "HTML" : "Text")+") "),(description.getLanguage)?[_vm._v(" – "+_vm._s(description.getLanguageName)+" "),(description.getLanguageCountry)?[_vm._v(" ("+_vm._s(description.getLanguageCountryName)+") ")]:_vm._e()]:_vm._e()],2),_c('dd',{key:'description-dd2-' + index,staticClass:"col-sm-1"}),_c('dt',{key:'description-dt2-' + index,staticClass:"col-sm-1"}),_c('dd',{key:'description-dd-' + index,staticClass:"col-sm-11"},[(description.getFormat == '02')?_c('div',{domProps:{"innerHTML":_vm._s(description.getText)}}):_c('div',{staticClass:"preserve-white-space"},[_vm._v(_vm._s(description.getText))]),(
            description.getAuthor ||
            description.getCorporateBody ||
            description.getSourceDescription ||
            description.getTitleOfSource ||
            description.getPublicationDate
          )?_c('div',{staticClass:"font-italic text-right"},[(description.getAuthor)?[_vm._v(" "+_vm._s(description.getAuthor)+", ")]:_vm._e(),(description.getCorporateBody)?[_vm._v(" "+_vm._s(description.getCorporateBody)+", ")]:_vm._e(),(description.getSourceDescription)?[_vm._v(" "+_vm._s(description.getSourceDescription)+", ")]:_vm._e(),(description.getTitleOfSource)?[_vm._v(" "+_vm._s(description.getTitleOfSource)+", ")]:_vm._e(),(description.getPublicationDate)?[_vm._v(" "+_vm._s(_vm._f("parseDate")(description.getPublicationDate,_vm.dateOptions.format, _vm.dateOptions))+" ")]:_vm._e()],2):_vm._e()])]})],2):(_vm.showAllFields)?_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-2 font-weight-bold text-secondary"},[_vm._v("Full Text")]),_c('dd',{staticClass:"col-sm-10"},[_vm._v("—")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }