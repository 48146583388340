<template>
  <div>
    <div class="row">
      <div class="col-12 mt-2">
        Use '<i>Save for Live</i>' to save these scripts for use with live
        feeds. This will also commit the changes to subversion and remove any
        testing scripts.
        <br />
        Use '<i>Save for Testing</i>' to save these scripts for use with testing
        (internal, test and record check) feeds. Live feeds will continue to use
        the live scripts.
        <br />
        Use '<i>Discard Testing</i>' to discard any testing scripts so that
        testing feeds revert to using the live scripts.
        <br />
        Use '<i>Discard changes</i>' to discard any un-saved local changes.
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              href="#tab_scripts_fmt"
              aria-controls="tab_scripts_fmt"
              role="tab"
              data-toggle="tab"
            >
              Pre-checks formatting script
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              href="#tab_scripts_chk"
              aria-controls="tab_scripts_chk"
              role="tab"
              data-toggle="tab"
            >
              Record checks script
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="tab_scripts_fmt" role="tabpanel" class="tab-pane active">
            <textarea
              v-model="xsltFormat"
              :class="xsltFormatInvalid ? 'invalid-xslt' : ''"
              @input="dataModified"
            ></textarea>
          </div>
          <div id="tab_scripts_chk" role="tabpanel" class="tab-pane">
            <textarea
              v-model="xsltCheckRecord"
              :class="xsltCheckRecordInvalid ? 'invalid-xslt' : ''"
              @input="dataModified"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div>
          Clear stored dissemination record checks results for the following
          feed(s)
          <info-icon
            title="Clear stored dissemination checks results"
            text="Dissemination stores the results of feed record checks to allow dissemination to run faster. The changes you've made might mean that different results could be generated. If this is the case then the stored record checks results for the affected feed(s) must be cleared. However clearing them unnecessarily will probably slow down dissemination."
          />
        </div>
        <v-select
          v-model="selectedRecipients"
          label="name"
          :options="listRecipients"
          :selectable="(option) => !option.group"
          multiple
          placeholder="Select recipient(s) or start typing"
        >
          <template #option="recipient">
            <div :class="recipientListOptionClass(recipient, false)">
              {{ recipient.name }}
            </div>
          </template>
          <template #selected-option="recipient">
            <div :class="recipientListOptionClass(recipient, true)">
              {{ recipient.name }}
            </div>
          </template>
          <template v-slot:no-options="{ searching }">
            <em v-if="searching">No feed recipient found</em>
            <em v-else>Start typing to search for a feed recipient</em>
          </template>
        </v-select>
      </div>
    </div>
    <hr />
    <div class="row">
      <div v-if="liveServer" class="col-12 mt-2">
        <label class="xslt-save-live-msg" for="xslt-save-live-msg">
          Save for Live SVN message:
        </label>
        <input
          id="xslt-save-live-msg"
          v-model="saveLiveMessage"
          type="text"
          value=""
          class="xslt-save-live-msg"
          :disabled="!modified && !isTest"
        />
      </div>
      <div v-else class="col-12 mt-2">
        <label class="xslt-save-live-msg">Save for Live SVN message: </label>
        <input
          type="text"
          value="(Scripts are only saved to SVN on live servers)"
          class="xslt-save-live-msg"
          disabled
        />
      </div>
    </div>
    <div class="row">
      <div class="col-8 mt-2">
        <button
          class="btn"
          :class="{
            'btn-success': modified || isTest,
            'btn-secondary': !modified && !isTest,
            disabled: readOnly || (!modified && !isTest),
          }"
          jest-save-live-btn
          @click="saveLive"
        >
          Save for Live
        </button>
        <button
          class="btn btn-padleft"
          :class="{
            'btn-success': modified,
            'btn-secondary': !modified,
            disabled: readOnly || !modified,
          }"
          jest-save-test-btn
          @click="saveTest"
        >
          Save for Testing
        </button>
        <button
          class="btn btn-padleft"
          :class="{
            'btn-danger': isTest,
            'btn-secondary': !isTest,
            disabled: readOnly || !isTest,
          }"
          jest-discard-testing-btn
          @click="discardTesting"
        >
          Discard Testing
        </button>
        <button
          class="btn btn-padleft"
          :class="{
            'btn-danger': modified,
            'btn-secondary': !modified,
            disabled: !modified,
          }"
          @click="discardChanges"
        >
          Discard changes
        </button>
        <a
          jest-xslt-scripts-load-btn
          style="display: none"
          @click="loadData"
        ></a>
      </div>
      <div class="col-4 mt-2">
        <save-indicator
          :status="saveStatus"
          complete-message="Saved changes"
          @status-cleared="saveStatus = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings xslt scripts component
 */
import vSelect from "vue-select";
import SaveIndicator from "../../ui/SaveIndicator.vue";
import InfoIcon from "../../icons/InfoIcon.vue";
import { HTTP } from "../../../http-common.js";

export default {
  name: "DisseminationSettingsXsltScripts",

  components: {
    "v-select": vSelect,
    "save-indicator": SaveIndicator,
    "info-icon": InfoIcon,
  },

  props: {
    /**
     * Component is visible
     */
    visible: {
      type: Boolean,
      default: false,
    },

    /**
     * Live server
     */
    liveServer: {
      type: Boolean,
      default: false,
    },

    /**
     * Read-only
     */
    readOnly: {
      type: Boolean,
      default: false,
    },

    /**
     * List of all feed recipients
     */
    recipients: {
      type: Array,
      default: null,
    },
  },

  data: function () {
    return {
      xsltScripts: null,
      xsltFormat: null,
      xsltFormatInvalid: false,
      xsltCheckRecord: null,
      xsltCheckRecordInvalid: false,
      isTest: false,
      modified: false,
      listRecipients: [],
      selectedRecipients: [],
      saveStatus: null,
      saveLiveMessage: "",
    };
  },

  watch: {
    visible: function (isVisible) {
      if (isVisible && !this.xsltScripts) {
        this.loadData();
      }
    },
    recipients: function (newRecipients) {
      this.listRecipients = [
        {
          id: 0,
          name: "(all feeds)",
        },
      ];
      for (const index in newRecipients) {
        if (newRecipients[index].group || newRecipients[index].active) {
          this.listRecipients.push(newRecipients[index]);
        }
      }
      this.selectedRecipients = [this.listRecipients[0]];
    },
  },

  methods: {
    /**
     * Load data
     */
    loadData: function () {
      if (!this.xsltScripts) {
        let url =
          this.$baseUrl + "customisation/dissemination-settings/xslt-scripts";

        HTTP.get(url)
          .then((response) => {
            this.xsltScripts = response.data;
            if (response.data.test) {
              this.xsltFormat = this.xsltScripts.test.format ?? "";
              this.xsltCheckRecord = this.xsltScripts.test.checkRecord ?? "";
              this.isTest = true;
            } else if (response.data.live) {
              this.xsltFormat = this.xsltScripts.live.format ?? "";
              this.xsltCheckRecord = this.xsltScripts.live.checkRecord ?? "";
              this.isTest = false;
            } else {
              this.xsltFormat = "";
              this.xsltCheckRecord = "";
              this.isTest = false;
            }
            this.xsltFormatInvalid = false;
            this.xsltCheckRecordInvalid = false;
          })
          .catch(() => {
            this.$bvModal.msgBoxOk("Unable to retrieve the XSLT scripts");
          });
      }
    },

    /**
     * Data has been modified
     */
    dataModified: function () {
      this.modified = true;
      this.$emit("change");
    },

    /**
     * Recipients list option classes
     */
    recipientListOptionClass(recipient, selected) {
      if (recipient.group) {
        return "recipient-list-groupitem";
      }
      if (recipient.id == 0) {
        return "recipient-list-allitem";
      }
      if (selected) {
        return "recipient-list-selitem";
      }
      return "recipient-list-item";
    },

    /**
     * Save for live (includes removing testing scripts)
     */
    saveLive: function () {
      if (!this.readOnly && (this.modified || this.isTest)) {
        // check if the XSLT scripts are valid
        if (this.checkXslt()) {
          // we need a save for live message on a live server
          if (!this.liveServer || this.saveLiveMessage.length != 0) {
            // build the new XSLT scripts object
            let newXsltScripts = {};
            if (
              this.xsltFormat.length != 0 ||
              this.xsltCheckRecord.length != 0
            ) {
              newXsltScripts.live = {};
              if (this.xsltFormat.length != 0) {
                newXsltScripts.live.format = this.xsltFormat;
              }
              if (this.xsltCheckRecord.length != 0) {
                newXsltScripts.live.checkRecord = this.xsltCheckRecord;
              }
            }

            // get the feeds whose stored record checks must be cleared
            let clearStoredChecksAllFeeds = false;
            let clearStoredChecksSelFeeds = [];
            for (const recipient of this.selectedRecipients) {
              if (recipient.id == 0) {
                clearStoredChecksAllFeeds = true;
              } else {
                clearStoredChecksSelFeeds.push(recipient.id);
              }
            }

            // save the changes
            let url = this.$baseUrl + "customisation/dissemination-settings";
            let postData = new URLSearchParams();
            postData.append("xsltScripts", JSON.stringify(newXsltScripts));
            if (this.liveServer) {
              postData.append("xsltScriptsSvn", this.saveLiveMessage);
            }
            if (clearStoredChecksAllFeeds) {
              postData.append("xsltScriptsClearStore", "*");
            } else if (clearStoredChecksSelFeeds.length != 0) {
              postData.append(
                "xsltScriptsClearStore",
                clearStoredChecksSelFeeds.join(",")
              );
            }

            this.saveStatus = "saving";
            HTTP.post(url, postData)
              .then(() => {
                // changes have been saved
                this.xsltScripts = newXsltScripts;
                this.isTest = false;
                this.modified = false;
                this.saveStatus = "saved";
                this.saveLiveMessage = "";
                this.$emit("save");
              })
              .catch(() => {
                this.saveStatus = null;
                this.$bvModal.msgBoxOk(
                  "An error has occurred while saving your custom scripts"
                );
              });
          } else {
            this.$bvModal.msgBoxOk("Saving for Live requires a SVN message");
          }
        }
      }
    },

    /**
     * Save for testing
     */
    saveTest: function () {
      if (!this.readOnly && this.modified) {
        // check if the XSLT scripts are valid
        if (this.checkXslt()) {
          // build the new XSLT scripts object
          let newXsltScripts = {};
          if (this.xsltScripts && this.xsltScripts.live) {
            newXsltScripts.live = this.xsltScripts.live;
          }
          if (this.xsltFormat.length != 0 || this.xsltCheckRecord.length != 0) {
            newXsltScripts.test = {};
            if (this.xsltFormat.length != 0) {
              newXsltScripts.test.format = this.xsltFormat;
            }
            if (this.xsltCheckRecord.length != 0) {
              newXsltScripts.test.checkRecord = this.xsltCheckRecord;
            }
          }

          // get the feeds whose stored record checks must be cleared
          let clearStoredChecksAllFeeds = false;
          let clearStoredChecksSelFeeds = [];
          for (const recipient of this.selectedRecipients) {
            if (recipient.id == 0) {
              clearStoredChecksAllFeeds = true;
            } else {
              clearStoredChecksSelFeeds.push(recipient.id);
            }
          }

          // save the changes
          let url = this.$baseUrl + "customisation/dissemination-settings";
          let postData = new URLSearchParams();
          postData.append("xsltScripts", JSON.stringify(newXsltScripts));
          if (clearStoredChecksAllFeeds) {
            postData.append("xsltScriptsClearStore", "*");
          } else if (clearStoredChecksSelFeeds.length != 0) {
            postData.append(
              "xsltScriptsClearStore",
              clearStoredChecksSelFeeds.join(",")
            );
          }

          this.saveStatus = "saving";
          HTTP.post(url, postData)
            .then(() => {
              // changes have been saved
              this.xsltScripts = newXsltScripts;
              this.isTest = true;
              this.modified = false;
              this.saveStatus = "saved";
              this.$emit("save");
            })
            .catch(() => {
              this.saveStatus = null;
              this.$bvModal.msgBoxOk(
                "An error has occurred while saving your custom scripts"
              );
            });
        }
      }
    },

    /**
     * Discard testing scripts
     */
    discardTesting: function () {
      if (!this.readOnly && this.isTest) {
        // save the changes
        let newXsltScripts = {
          live: this.xsltScripts.live,
        };
        let url = this.$baseUrl + "customisation/dissemination-settings";
        let postData = new URLSearchParams();
        postData.append("xsltScripts", JSON.stringify(newXsltScripts));

        this.saveStatus = "saving";
        HTTP.post(url, postData)
          .then(() => {
            // changes have been saved, check of the current (test) xslt scripts
            // are different from the live scripts
            delete this.xsltScripts.test;
            let liveXsltFormat = this.xsltScripts.live.format ?? "";
            let liveXsltCheckRecord = this.xsltScripts.live.checkRecord ?? "";
            this.modified =
              this.xsltFormat != liveXsltFormat ||
              this.xsltCheckRecord != liveXsltCheckRecord;
            this.isTest = false;
            this.saveStatus = "saved";
            if (this.modified) {
              this.$emit("change");
            } else {
              this.$emit("discard");
            }
          })
          .catch(() => {
            this.saveStatus = null;
            this.$bvModal.msgBoxOk(
              "An error has occurred while saving your custom scripts"
            );
          });
      }
    },

    /**
     * Discard local changes
     */
    discardChanges: function () {
      if (this.modified) {
        if (this.xsltScripts.test) {
          this.xsltFormat = this.xsltScripts.test.format ?? "";
          this.xsltCheckRecord = this.xsltScripts.test.checkRecord ?? "";
          this.isTest = true;
        } else {
          this.xsltFormat = this.xsltScripts.live.format ?? "";
          this.xsltCheckRecord = this.xsltScripts.live.checkRecord ?? "";
          this.isTest = false;
        }
        this.xsltFormatInvalid = false;
        this.xsltCheckRecordInvalid = false;
        this.modified = false;
        this.saveLiveMessage = "";
        this.$emit("discard");
      }
    },

    /**
     * Check XSLT
     */
    checkXslt: function () {
      let parser = new DOMParser();

      if (this.xsltFormat.length != 0) {
        let doc = parser.parseFromString(
          (this.xsltFormat.search(/^<?xml /) == -1
            ? '<?xml version="1.0"?>'
            : "") + this.xsltFormat,
          "application/xml"
        );
        if (doc.querySelector("parsererror")) {
          this.xsltFormatInvalid = true;
          this.$bvModal.msgBoxOk("The pre-checks formatting script is invalid");
          return false;
        }
      }
      this.xsltFormatInvalid = false;

      if (this.xsltCheckRecord.length != 0) {
        let doc = parser.parseFromString(
          (this.xsltCheckRecord.search(/^<?xml /) == -1
            ? '<?xml version="1.0"?>'
            : "") + this.xsltCheckRecord,
          "application/xml"
        );
        if (doc.querySelector("parsererror")) {
          this.xsltCheckRecordInvalid = true;
          this.$bvModal.msgBoxOk("The record checks script is invalid");
          return false;
        }
      }
      this.xsltCheckRecordInvalid = false;

      return true;
    },
  },
};
</script>

<style scoped>
textarea {
  width: 98%;
  resize: none;
  height: 30rem;
  font-family: monospace;
}
textarea.invalid-xslt {
  border: red solid 3px;
}
.btn-padleft {
  margin-left: 1rem;
}
.recipient-list-groupitem {
  font-weight: bold;
  font-style: italic;
  color: #000;
}
.recipient-list-allitem {
  font-style: italic;
}
.recipient-list-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
div.recipient-list-item {
  margin-left: 0.5rem;
}
label.xslt-save-live-msg {
  padding-right: 0.5rem;
}
input.xslt-save-live-msg {
  width: 80%;
}
</style>
