<template>
  <a :href="link" class="fa fa-edit blue tableButton"></a>
</template>

<script>
/**
 * Small button displaying an edit icon that
 * links to a URL.
 */
export default {
  name: "EditIcon",
  props: {
    /**
     * The URL destination the button will link to.
     */
    link: {
      type: String,
      default: null,
    },
  },
};
</script>
