<template>
  <dl class="row">
    <template v-for="(field, index) in fieldsToDisplay">
      <template v-if="Array.isArray(field.value) && field.value.length > 0">
        <dt
          :key="'dt-custom-field-' + index"
          class="font-weight-bold text-secondary"
          :class="labelColClass"
        >
          {{ field.caption }}
        </dt>
        <dd :key="'dd-custom-field-' + index" :class="valueColClass">
          <div v-for="(value, valIndex) in field.value" :key="valIndex">
            <template v-if="field.type == 'date'">{{
              value | parseDate(dateOptions.format, dateOptions)
            }}</template>
            <template v-else-if="field.type == 'rawhtml'">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="value"></span>
            </template>
            <template v-else>{{ value }}</template>
          </div>
        </dd>
      </template>
      <template v-else-if="!Array.isArray(field.value)">
        <dt
          :key="'dt-custom-field-' + index"
          class="font-weight-bold text-secondary col-3"
        >
          {{ field.label }}
        </dt>
        <dd :key="'dd-custom-field-' + index" class="col-9">
          <template v-if="field.type == 'edit-date'">{{
            field.value | parseDate(dateOptions.format, dateOptions)
          }}</template>
          <template v-else-if="field.type == 'rawhtml'">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="field.value"></span>
          </template>
          <template v-else>
            {{ field.value }}
          </template>
        </dd>
      </template>
    </template>
  </dl>
</template>

<script>
/**
 * Displays a list of custom fields for a product
 *
 * These fields can be filtered to a particular form
 */
import ParseDate from "../../mixins/parseDate.js";

export default {
  name: "CustomFields",

  mixins: [ParseDate],

  props: {
    /**
     * The list of custom field definitions
     */
    customFields: {
      type: [Object, Array],
      default: null,
    },

    /**
     * The form for wich the custom fields
     * should display for
     *
     * @values title, biblio, format, contrib, subject, text,
     *  media, rights, supply, market, prizes, related, notes, foreignrights
     */
    form: {
      type: String,
      default: null,
    },

    labelWidth: {
      type: String,
      default: "3",
    },
  },

  computed: {
    fieldsToDisplay: function () {
      let fields = [];
      if (!this.customFields) {
        return fields;
      }
      for (let field in this.customFields) {
        // if no form is specified then always display
        if (
          (!this.customFields[field].editform && !this.form) ||
          this.customFields[field].editform == this.form
        ) {
          fields.push(this.customFields[field]);
        }
      }
      return fields;
    },
    valueWidth: function () {
      return 12 - parseInt(this.labelWidth);
    },
    labelColClass: function () {
      return "col-sm-" + this.labelWidth;
    },
    valueColClass: function () {
      return "col-sm-" + this.valueWidth;
    },
  },
};
</script>

<style></style>
