import axios from "axios";

let instance = axios.create();

instance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const handleHttpError = function (error) {
  if (403 === error.response.status) {
    /**
     * Redirect unauthenticated users to the logon page
     */
    let location = window.location.pathname.split("application")[0];
    window.confirm(
      "Your user session has timed out, you will now be redirected to the login page"
    );
    window.location.replace(location + "application/users/logon");
  } else {
    return Promise.reject(error);
  }
};

instance.interceptors.response.use(
  /* istanbul ignore next */ function (response) {
    return response;
  },
  /* istanbul ignore next */ function (error) {
    return handleHttpError(error);
  }
);

const HTTP = instance;

export { HTTP, handleHttpError };
