<template>
  <b-card id="productCardContentItems">
    <template #header>
      <a id="content-items" />
      <h5 class="d-inline-block">Content Items</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-contentitems/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <template v-if="contentItems.length > 0">
      <dl
        v-for="contentItem in contentItems"
        :key="contentItem.getTableId"
        class="row"
      >
        <dt class="col-sm-2 font-weight-bold text-secondary">
          Item {{ contentItem.getSequenceNumber }}
        </dt>
        <dd class="col-sm-10">
          <template
            v-if="
              contentItem.getComponentTypeName || contentItem.getComponentNumber
            "
          >
            {{
              (contentItem.getComponentTypeName
                ? contentItem.getComponentTypeName
                : "") +
              " " +
              (contentItem.getComponentNumber
                ? contentItem.getComponentNumber
                : "") +
              (contentItem.getMainTitle ? " : " : "")
            }}
          </template>
          <template v-if="contentItem.getMainTitle">
            {{
              contentItem.getMainTitle +
              (contentItem.getMainTitleSubtitle
                ? " : " + contentItem.getMainTitleSubtitle
                : "")
            }}
          </template>
        </dd>

        <!-- text/av item types and pages/times -->
        <template v-if="contentItem.getTextItemType">
          <dt class="col-sm-2 pl-5 text-secondary">Type and Pages</dt>
          <dd class="col-sm-10">
            Type: {{ contentItem.getTextItemTypeName }}
            <template v-if="contentItem.getPageRuns">
              | Pages: {{ formatPageRuns(contentItem.getPageRuns) }}
            </template>
            <template v-if="contentItem.getPageCount">
              | Page Count: {{ contentItem.getPageCount }}
            </template>
          </dd>
        </template>
        <template v-else-if="contentItem.getAVItemType">
          <dt class="col-sm-2 pl-5 text-secondary">Type and Times</dt>
          <dd class="col-sm-10">
            Type: {{ contentItem.getAVItemTypeName }}
            <template v-if="contentItem.getTimeRuns">
              | Times: {{ formatTimeRuns(contentItem.getTimeRuns) }}
            </template>
            <template v-if="contentItem.getTimeDuration">
              | Duration:
              {{ formatTime(contentItem.getTimeDuration, "h ", "m ", "s") }}
            </template>
          </dd>
        </template>

        <!-- product identifiers -->
        <template
          v-if="Object.keys(contentItem.getAllProductIdentifiers).length != 0"
        >
          <dt class="col-sm-2 pl-5 text-secondary">Product Identifiers</dt>
          <dd class="col-sm-10">
            <template
              v-for="(
                identifier, identifierKey, identifierIndex
              ) in contentItem.getAllProductIdentifiers"
            >
              <template v-if="identifierIndex != 0">|</template>
              {{ identifier.typeName + ": " + identifier.value }}
            </template>
          </dd>
        </template>
        <template v-else-if="showAllFields">
          <dt class="col-sm-2 pl-5 text-secondary">Product Identifiers</dt>
          <dd class="col-sm-10">—</dd>
        </template>

        <!-- textual work identifiers -->
        <template
          v-if="
            Object.keys(contentItem.getAllTextualWorkIdentifiers).length != 0
          "
        >
          <dt class="col-sm-2 pl-5 text-secondary">Textual Work Ids</dt>
          <dd class="col-sm-10">
            <template
              v-for="(
                identifier, identifierKey, identifierIndex
              ) in contentItem.getAllTextualWorkIdentifiers"
            >
              <template v-if="identifierIndex != 0">|</template>
              {{ identifier.typeName + ": " + identifier.value }}
            </template>
          </dd>
        </template>
        <template v-else-if="showAllFields">
          <dt class="col-sm-2 pl-5 text-secondary">Textual Work Ids</dt>
          <dd class="col-sm-10">—</dd>
        </template>

        <!-- languages -->
        <template v-if="contentItem.getUseProductLanguages">
          <dt class="col-sm-2 pl-5 text-secondary">Languages</dt>
          <dd class="col-sm-10"><i>Uses product's languages</i></dd>
        </template>
        <template
          v-else-if="
            contentItem.getLanguages.getText ||
            contentItem.getLanguages.getOriginalOfATranslatedText
          "
        >
          <template v-if="contentItem.getLanguages.getText">
            <dt class="col-sm-2 pl-5 text-secondary">Languages of Text</dt>
            <dd class="col-sm-10">
              <div
                v-for="(language, langIndex) in contentItem.getLanguages
                  .getText"
                :key="langIndex"
                class="d-inline-block"
              >
                <template v-if="langIndex != 0">, </template>
                {{ language.getLanguageName }}
                <template v-if="language.getCountryName">
                  ({{ language.getCountryName }})
                </template>
              </div>
            </dd>
          </template>
          <template v-else-if="showAllFields">
            <dt class="col-sm-2 pl-5 text-secondary">Languages of Text</dt>
            <dd class="col-sm-10">—</dd>
          </template>

          <template
            v-if="contentItem.getLanguages.getOriginalOfATranslatedText"
          >
            <dt class="col-sm-2 pl-5 text-secondary">
              Original Text Languages
            </dt>
            <dd class="col-sm-10">
              <div
                v-for="(language, langIndex) in contentItem.getLanguages
                  .getOriginalOfATranslatedText"
                :key="langIndex"
                class="d-inline-block"
              >
                <template v-if="langIndex != 0">, </template>
                {{ language.getLanguageName }}
                <template v-if="language.getCountryName">
                  ({{ language.getCountryName }})
                </template>
              </div>
            </dd>
          </template>
          <template v-else-if="showAllFields">
            <dt class="col-sm-2 pl-5 text-secondary">
              Original Text Languages
            </dt>
            <dd class="col-sm-10">—</dd>
          </template>
        </template>
        <template v-else-if="showAllFields">
          <dt class="col-sm-2 pl-5 text-secondary">Languages</dt>
          <dd class="col-sm-10">—</dd>
        </template>

        <!-- contributors -->
        <template v-if="contentItem.getUseProductContributors">
          <dt class="col-sm-2 pl-5 text-secondary">Contributors</dt>
          <dd class="col-sm-10"><i>Uses product's contributors</i></dd>
        </template>
        <template v-else-if="contentItem.getContributors.get.length != 0">
          <dt class="col-sm-2 pl-5 text-secondary">Contributors</dt>
          <dd class="col-sm-10"></dd>
          <dd class="col-sm-12" style="padding-left: 4.5rem">
            <product-contributors
              :contributors="contentItem.getContributors.get"
              :contributors-statement="contentItem.getContributors.getStatement"
              :return-to="returnTo"
            >
            </product-contributors>
          </dd>
        </template>
        <template v-else-if="contentItem.getContributors.getNoContributors">
          <dt class="col-sm-2 pl-5 text-secondary">Contributors</dt>
          <dd class="col-sm-10">No</dd>
        </template>
        <template v-else-if="showAllFields">
          <dt class="col-sm-2 pl-5 text-secondary">Contributors</dt>
          <dd class="col-sm-10">—</dd>
        </template>

        <!-- subjects -->
        <template v-if="contentItem.getUseProductSubjects">
          <dt class="col-sm-2 pl-5 text-secondary">Subjects</dt>
          <dd class="col-sm-10"><i>Uses product's subjects</i></dd>
        </template>
        <template
          v-else-if="
            contentItem.getSubjects.getMainSubjects ||
            contentItem.getSubjects.getAdditionalSubjects
          "
        >
          <dt class="col-sm-2 pl-5 font-weight-bold text-secondary">
            Subjects
          </dt>
          <dd class="col-sm-10">
            <div
              v-for="(subject, subjectIndex) in contentItem.getSubjects
                .getMainSubjects"
              :key="subjectIndex"
            >
              {{ subject.getSchemeName }}
              <span v-if="subject.getVersion">
                (ver {{ subject.getVersion }})
              </span>
              : <span v-if="subject.getCode">{{ subject.getCode }} –</span>
              {{ subject.getHeading }}
            </div>
            <div
              v-for="(subject, subjectIndex) in contentItem.getSubjects
                .getAdditionalSubjects"
              :key="subjectIndex"
            >
              {{ subject.getSchemeName }}
              <span v-if="subject.getVersion">
                (ver {{ subject.getVersion }})
              </span>
              : <span v-if="subject.getCode">{{ subject.getCode }} –</span>
              {{ subject.getHeading }}
            </div>
          </dd>
        </template>
        <template v-else-if="showAllFields">
          <dt class="col-sm-2 pl-5 text-secondary">Subjects</dt>
          <dd class="col-sm-10">—</dd>
        </template>

        <!-- full text -->
        <template v-if="contentItem.getUseProductDescriptions">
          <dt class="col-sm-2 pl-5 text-secondary">Full Text</dt>
          <dd class="col-sm-10"><i>Uses product's full text</i></dd>
        </template>
        <template v-else-if="contentItem.getDescriptions.get.length != 0">
          <template
            v-for="(description, descIndex) in contentItem.getDescriptions.get"
          >
            <dt
              :key="'description-dt-' + descIndex"
              class="col-sm-12 pl-5 font-weight-bold text-secondary"
            >
              Full Text
              <template v-if="description.getInternal">(Internal)</template>
              - {{ description.getTypeName }} ({{
                description.getFormat == "02" ? "HTML" : "Text"
              }})
            </dt>
            <dt :key="'description-dt2-' + descIndex" class="col-sm-2"></dt>
            <dd :key="'description-dd-' + descIndex" class="col-sm-10">
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="description.getFormat == '02'"
                v-html="description.getText"
              ></div>
              <!--eslint-enable-->
              <!-- prettier-ignore -->
              <div v-else class="preserve-white-space">{{ description.getText }}</div>
              <div
                v-if="
                  description.getAuthor ||
                  description.getCorporateBody ||
                  description.getTitleOfSource ||
                  description.getPublicationDate
                "
                class="font-italic text-right"
              >
                <template v-if="description.getAuthor">
                  {{ description.getAuthor }},
                </template>
                <template v-if="description.getCorporateBody">
                  {{ description.getCorporateBody }},
                </template>
                <template v-if="description.getTitleOfSource">
                  {{ description.getTitleOfSource }},
                </template>
                <template v-if="description.getPublicationDate">
                  {{
                    description.getPublicationDate
                      | parseDate(dateOptions.format, dateOptions)
                  }}
                </template>
              </div>
            </dd>
          </template>
        </template>
        <template v-else-if="showAllFields">
          <dt class="col-sm-2 pl-5 text-secondary">Full Text</dt>
          <dd class="col-sm-10">—</dd>
        </template>

        <!-- images & media -->
        <template v-if="contentItem.getUseProductResources">
          <dt class="col-sm-2 pl-5 text-secondary">Images &amp; Media</dt>
          <dd class="col-sm-10"><i>Uses product's images &amp; media</i></dd>
        </template>
        <template v-else-if="contentItem.getResources.get.length != 0">
          <dt class="col-sm-2 pl-5 text-secondary">Images &amp; Media</dt>
          <dd class="col-sm-10">
            <template v-for="resource in contentItem.getResources.get">
              <div :key="resource.getTableId + '-l'">
                {{ resource.getType21Name }} :
                <a
                  :href="
                    urlSafeFilename(resource.getLink, resource.getLinkType)
                  "
                  target="_blank"
                >
                  {{ resource.getLink }}
                </a>
                ({{ resource.getFormatName }})
              </div>
              <div v-if="resource.getCaption" :key="resource.getTableId + '-c'">
                &emsp;Caption: {{ resource.getCaption }}
              </div>
              <div v-if="resource.getCredit" :key="resource.getTableId + '-r'">
                &emsp;Credit: {{ resource.getCredit }}
              </div>
              <div
                v-if="resource.getCopyrightHolder"
                :key="resource.getTableId + '-h'"
              >
                &emsp;Copyright Holder: {{ resource.getCopyrightHolder }}
              </div>
              <div
                v-if="resource.getAlternateText"
                :key="resource.getTableId + '-a'"
              >
                &emsp;Alternate Text: {{ resource.getAlternateText }}
              </div>
            </template>
          </dd>
        </template>
        <template v-else-if="showAllFields">
          <dt class="col-sm-2 pl-5 text-secondary">Images &amp; Media</dt>
          <dd class="col-sm-10">—</dd>
        </template>

        <!-- related products -->
        <template v-if="contentItem.getRelatedProducts.getAll.length != 0">
          <dt class="col-sm-2 pl-5 text-secondary">Related Products</dt>
          <dd class="col-sm-10">
            <div
              v-for="related in contentItem.getRelatedProducts.getAll"
              :key="related.getTableId"
            >
              {{ related.getProduct.getRelatedTypeName }} :
              <template
                v-for="(
                  identifier, identifierKey
                ) in getRelatedProductIdentifiers(related)"
              >
                {{ identifier.typeName }} :
                <a
                  :key="related.getTableId + '-' + identifierKey + '-a'"
                  :href="
                    $baseUrl +
                    'search/link/biblio/' +
                    searchId +
                    '/' +
                    page +
                    '?' +
                    identifierSearchField(identifierKey) +
                    '=' +
                    encodeURIComponent(identifier.value)
                  "
                >
                  {{ identifier.value }}
                </a>
                <template v-if="related.getProduct.getMainTitle">
                  &mdash;
                  <em :key="related.getTableId + '-' + identifierKey + '-i'">
                    {{ related.getProduct.getMainTitle }}
                  </em>
                  <template v-if="related.getProduct.getFormatName">
                    ({{ related.getProduct.getFormatName }})
                  </template>
                </template>
              </template>
            </div>
          </dd>
        </template>
        <template v-else-if="showAllFields">
          <dt class="col-sm-2 pl-5 text-secondary">Related Products</dt>
          <dd class="col-sm-10">—</dd>
        </template>
      </dl>
    </template>
    <template v-else-if="showAllFields">
      <dl class="row">
        <dt class="col-sm-2 font-weight-bold text-secondary">Content Items</dt>
        <dd class="col-sm-10">—</dd>
      </dl>
    </template>
  </b-card>
</template>

<script>
/**
 * Displays a description list of a products
 * content item information
 */
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ParseDate from "../../mixins/parseDate.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";
import ProductContributors from "./ProductContributors.vue";

export default {
  name: "ProductContentItems",

  components: {
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
    "product-contributors": ProductContributors,
  },

  mixins: [DisplaySetting, ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * List of content items
     */
    contentItems: {
      type: Array,
      default: () => [],
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * format page runs
     */
    formatPageRuns(pageRuns) {
      let result = "";

      for (let i = 0; i < pageRuns.length; i++) {
        if (i != 0) {
          result += ", ";
        }
        result += pageRuns[i].getFirstPage;
        if (pageRuns[i].getLastPage) {
          result += "-" + pageRuns[i].getLastPage;
        }
      } // for

      return result;
    },

    /**
     * format a time
     */
    formatTime(time, hoursSuffix, minutesSuffix, secondsSuffix) {
      let hours = time.substr(0, 3);
      let minutes = time.substr(3, 2);
      let seconds = time.substr(5, 2);

      if (hours != "000") {
        return (
          parseInt(hours, 10) +
          hoursSuffix +
          minutes +
          minutesSuffix +
          seconds +
          secondsSuffix
        );
      }

      return parseInt(minutes, 10) + minutesSuffix + seconds + secondsSuffix;
    },

    /**
     * format time runs
     */
    formatTimeRuns(timeRuns) {
      let result = "";

      for (let i = 0; i < timeRuns.length; i++) {
        if (i != 0) {
          result += ", ";
        }
        result += this.formatTime(timeRuns[i].getStartTime, ":", ":", "");
        if (timeRuns[i].getEndTime) {
          result += "-" + this.formatTime(timeRuns[i].getEndTime, ":", ":", "");
        }
      } // for
      return result;
    },

    /**
     * Converts filename media types to be URL safe
     */
    urlSafeFilename(url, linkType) {
      if (linkType == "06") {
        return encodeURI("/" + this.$appId + "/" + url);
      }
      return url;
    },

    /**
     * get identifier search field tag from identifier type code
     */
    identifierSearchField(identifierType) {
      switch (identifierType) {
        case "02":
          return "isbn10";
        case "03":
          return "ean";
        case "04":
          return "upc";
        case "06":
          return "doi";
        case "13":
          return "lccn";
        case "0315":
        case "15":
          return "isbn13";
        default:
          return null;
      }
    },

    /**
     * Get related product identifiers
     */
    getRelatedProductIdentifiers: function (relatedProduct) {
      let identifiers = relatedProduct.getAllProductIdentifiers;

      // check for GTIN-13 and ISBN-13 with the same identifier value
      if (
        Object.keys(identifiers).length == 2 &&
        "03" in identifiers &&
        "15" in identifiers &&
        identifiers["03"].value == identifiers["15"].value
      ) {
        // return a single GTIN-13 & ISBN-13 identifier
        return {
          "0315": {
            typeName:
              identifiers["03"].typeName + " & " + identifiers["15"].typeName,
            value: identifiers["03"].value,
          },
        };
      }

      // return the first identifier
      for (const idType in identifiers) {
        return {
          idType: identifiers[idType],
        };
      }

      // no identifiers
      return {};
    },
  },
};
</script>

<style></style>
