<template>
  <ol class="breadcrumb">
    <!-- @slot Contents of the breadcrumbs list. Don't use if links have been provided as a prop -->
    <slot>
      <li
        v-for="(link, index) in links"
        :key="index"
        class="breadcrumb-item"
        :class="{ active: index == links.length - 1 }"
      >
        <a v-if="index !== links.length - 1" :href="link.url">
          {{ link.text }}
        </a>
        <template v-else>{{ link.text }}</template>
      </li>
    </slot>
  </ol>
</template>

<script>
export default {
  name: "Breadcrumbs",

  props: {
    /**
     * Lists the links displayed in the breadcrumbs
     * The last element of the array will be the active page
     * and this does not require a url property
     *
     * @property {String} text The text which will display for the link
     * @property {String} url The destination of the link
     *
     * @example [{text: 'Biblio', url: '/biblio'}, {text: 'Edit', url: '/biblio/edit'}]
     */
    links: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style scoped>
ol {
  margin-top: 10px;
}
</style>
