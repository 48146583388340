<template>
  <ul class="biblio_navigationbar">
    <li>
      <a
        :href="navBaseUrl + '1' + navUrlSuffix"
        class="btn btn-link"
        :class="{ disabled: page == 1 }"
      >
        <i class="fa fa-angles-left"></i>
        First
      </a>
    </li>
    <li>
      <a
        :href="navBaseUrl + (page - 1) + navUrlSuffix"
        class="btn btn-link"
        :class="{ disabled: page == 1 }"
        jest-previous-btn
      >
        <i class="fa fa-chevron-left"></i>
        Previous
      </a>
    </li>
    <li>
      <a
        :href="navBaseUrl + (page + 1) + navUrlSuffix"
        class="btn btn-link"
        :class="{ disabled: page == pages }"
        jest-next-btn
      >
        Next
        <i class="fa fa-chevron-right"></i>
      </a>
    </li>
    <li>
      <a
        :href="navBaseUrl + pages + navUrlSuffix"
        class="btn btn-link"
        :class="{ disabled: page == pages }"
      >
        Last
        <i class="fa fa-angles-right"></i>
      </a>
    </li>
    <li>
      <span class="separation">{{ currentPage }}</span>
    </li>
    <li class="biblio_navigationbar_pad5">
      <label>
        <span class="separation">Go to {{ pageLabel.toLowerCase() }}:</span>
        &nbsp;
      </label>
      <div style="display: inline" data-v-step="biblio-detail-navigating">
        <input
          v-model="gotoPage"
          type="number"
          min="1"
          :max="pages"
          @keyup.enter="gotoClick(true)"
        />
      </div>
    </li>
    <li>
      <a
        ref="gotoLink"
        class="btn btn-link"
        :href="navBaseUrl + page + navUrlSuffix"
        @click="gotoClick(false)"
      >
        Go
      </a>
    </li>
  </ul>
</template>

<script>
/**
 * Display the Biblio Navigation Bar line
 */

export default {
  name: "NavigationBar",

  props: {
    /**
     * Search id
     */
    searchId: {
      type: Number,
      required: true,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      required: true,
    },

    /**
     * Number of pages
     */
    pages: {
      type: Number,
      required: true,
    },

    /**
     * Number of records; if set then output's records in addition to pages
     */
    records: {
      type: Number,
      default: null,
    },

    /**
     * Page size
     */
    pageSize: {
      type: Number,
      default: 1,
    },

    /**
     * Display format
     */
    format: {
      type: String,
      default: "",
    },

    /**
     * Page label
     */
    pageLabel: {
      type: String,
      default: "Page",
    },

    /**
     * navigation action
     */
    action: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      gotoPage: this.page,
    };
  },

  computed: {
    /**
     * current page
     */
    currentPage: function () {
      let output =
        this.pageLabel +
        " " +
        this.fmtNum(this.page) +
        " of " +
        this.fmtNum(this.pages);
      if (this.records !== null) {
        let firstPageRecord = Math.min(
          (this.page - 1) * this.pageSize + 1,
          this.records
        );
        let lastPageRecord = Math.min(this.page * this.pageSize, this.records);
        if (firstPageRecord != lastPageRecord) {
          output +=
            " \u00A0 (Records " +
            this.fmtNum(firstPageRecord) +
            " to " +
            this.fmtNum(lastPageRecord) +
            " of " +
            this.fmtNum(this.records) +
            ")";
        } else {
          output +=
            " \u00A0 (Record " +
            this.fmtNum(firstPageRecord) +
            " of " +
            this.fmtNum(this.records) +
            ")";
        }
      }
      return output;
    },

    /**
     * Navigation base url
     */
    navBaseUrl: function () {
      return (
        this.$baseUrl + "biblio/" + this.action + "/" + this.searchId + "/"
      );
    },

    /**
     * Navigation url suffix
     */
    navUrlSuffix: function () {
      return this.format != "" ? "/" + this.format : "";
    },
  },

  methods: {
    /**
     * Format a number
     */
    fmtNum: function (value) {
      return value >= 10000 ? value.toLocaleString("en-US") : value.toString();
    },

    /**
     * Goto button clicked or enter pressed in goto edit, update the url
     */
    gotoClick(fromEdit) {
      let page = parseInt(this.gotoPage);
      if (!isNaN(page) && page >= 1 && page <= this.pages) {
        this.$refs.gotoLink.href = this.navBaseUrl + page + this.navUrlSuffix;
        if (fromEdit) {
          this.$refs.gotoLink.click();
        }
      }
    },
  },
};
</script>

<style>
ul.biblio_navigationbar {
  margin: 2px 0px;
  padding-left: 5px;
}
ul.biblio_navigationbar li {
  display: inline;
  list-style-type: none;
  padding-right: 20px;
}
ul.biblio_navigationbar li.biblio_navigationbar_pad5 {
  padding-right: 5px;
}
ul.biblio_navigationbar input {
  width: 6em;
}
</style>
