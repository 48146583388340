import { render, staticRenderFns } from "./ForeignRights.vue?vue&type=template&id=769ffd26&scoped=true&"
import script from "./ForeignRights.vue?vue&type=script&lang=js&"
export * from "./ForeignRights.vue?vue&type=script&lang=js&"
import style0 from "./ForeignRights.vue?vue&type=style&index=0&id=769ffd26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "769ffd26",
  null
  
)

export default component.exports