var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.automaticFeedsSuspended)?_c('div',{staticClass:"alert alert-warning message error"},[_c('b',[_vm._v("Warning:")]),_vm._v(" All automatic feeds for this application are temporarily suspended. Use the "),_c('i',[_vm._v("Application Options")]),_vm._v(" tab to resume. ")]):_vm._e(),_c('b-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('b-tab',{attrs:{"title":"Notification","active":"","jest-notification-tab":""}},[_c('dissemination-settings-client-notify',{attrs:{"client-notify":_vm.clientNotify,"read-only":_vm.readOnly},on:{"change":function($event){_vm.clientNotifyModified = true},"save":function($event){_vm.clientNotifyModified = false},"discard":function($event){_vm.clientNotifyModified = false}}})],1),_c('b-tab',{attrs:{"title":"Application Options","jest-application-options-tab":""}},[_c('dissemination-settings-application-options',{attrs:{"return-condition-types":_vm.returnConditionTypes,"application-options":_vm.applicationOptions,"read-only":_vm.readOnly},on:{"change":function($event){_vm.applicationOptionsModified = true},"save":function($event){_vm.applicationOptionsModified = false},"discard":function($event){_vm.applicationOptionsModified = false},"automatic-feeds-suspended":function (state) {
            _vm.automaticFeedsSuspended = state;
          }}})],1),_c('b-tab',{attrs:{"title":"Feeds","jest-feeds-tab":""}},[_c('dissemination-settings-feeds',{attrs:{"recipient-groups":_vm.recipientGroups,"data-formats":_vm.dataFormats,"return-condition-types":_vm.returnConditionTypes,"languages":_vm.languages,"countries":_vm.countries,"formats":_vm.formats,"currencies":_vm.currencies,"price-types":_vm.priceTypes,"discount-schemes":_vm.discountSchemes,"global-tags":_vm.globalTags,"read-only":_vm.readOnly},on:{"recipients":function (recipients) {
            _vm.recipientsList = recipients;
          },"state-change":function (modified) {
            _vm.feedsModified = modified;
          }}})],1),_c('b-tab',{attrs:{"title":"Notes","jest-notes-tab":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._v(" Use this tab to store notes/comments about disseminations for this BooksoniX application. ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-2"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentNotes),expression:"currentNotes"}],staticClass:"notes",domProps:{"value":(_vm.currentNotes)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.currentNotes=$event.target.value},_vm.notesChanged]}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 mt-2"},[_c('button',{staticClass:"btn",class:{
              'btn-success': _vm.notesModified,
              'btn-secondary': !_vm.notesModified,
              disabled: _vm.readOnly || !_vm.notesModified,
            },on:{"click":_vm.saveNotesChanges}},[_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-padleft",class:{
              'btn-danger': _vm.notesModified,
              'btn-secondary': !_vm.notesModified,
              disabled: !_vm.notesModified,
            },on:{"click":_vm.discardNotesChanges}},[_vm._v(" Discard ")])]),_c('div',{staticClass:"col-5"},[_c('save-indicator',{attrs:{"status":_vm.notesSaveStatus,"complete-message":"Saved changes"},on:{"status-cleared":function($event){_vm.notesSaveStatus = null}}})],1)])]),_c('b-tab',{attrs:{"title":"Custom Scripts","jest-xslt-scripts-tab":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._v(" Use this tab to edit the custom dissemination scripts used by this application. "),_c('b',[_vm._v("Do not change these unless you know what you are doing.")])])]),_c('dissemination-settings-xslt-scripts',{attrs:{"visible":_vm.currentTab == 4,"live-server":_vm.liveServer,"read-only":_vm.readOnly,"recipients":_vm.recipientsList},on:{"change":function($event){_vm.xsltModified = true},"save":function($event){_vm.xsltModified = false},"discard":function($event){_vm.xsltModified = false}}})],1)],1),_c('br'),_c('div',{staticClass:"formcontrols"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":_vm.$baseUrl + 'customisation/dissemination-settings/close/customisation'},on:{"click":_vm.canClose}},[_c('span',{staticClass:"fa fa-close"}),_vm._v(" Close ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }