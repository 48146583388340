/**
 * This file imports and includes all base (global) componenets
 *
 * This file should always be included in the entry file
 *
 * To import this file (and all gobal componenets) add:
 * import "./components/base";
 * to the entry file
 */

import Vue from "vue";

import BaseHeader from "./BaseHeader.vue";
import BaseTable from "./BaseTable/BaseTable.vue";
import BaseSearchInput from "./BaseSearchInput.vue";
import BaseHelpText from "./BaseHelpText.vue";
import BaseSelect from "./BaseSelect.vue";
import BaseButton from "./BaseButton.vue";
import BaseDatePicker from "./BaseDatePicker.vue";

Vue.component("base-header", BaseHeader);
Vue.component("base-table", BaseTable);
Vue.component("base-search-input", BaseSearchInput);
Vue.component("base-help-text", BaseHelpText);
Vue.component("base-select", BaseSelect);
Vue.component("base-button", BaseButton);
Vue.component("base-date-picker", BaseDatePicker);
