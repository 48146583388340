<template>
  <div>
    <v-select
      ref="vSelect"
      v-model="selected"
      v-bind="$attrs"
      :options="paginated"
      :clearable="false"
      :filterable="false"
      :disabled="(disableCreate && options.length < 1) || disable"
      label="name"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
      @input="contactSelected"
      @search="search"
    >
      <!-- Passes named slots to the child base-select component. All slots available in base-select can be used in this component -->
      <template
        v-for="(index, slotName) in $scopedSlots"
        v-slot:[slotName]="data"
      >
        <slot :name="slotName" v-bind="data"></slot>
      </template>
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          v-bind="attributes"
          @input="searchInput"
          @compositionstart="events.compositionstart"
          @compositionend="events.compositionend"
          @keydown="events.keydown"
          @blur="events.blur"
          @focus="events.focus"
        />
      </template>
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader pl-3">
          Loading more options...
        </li>
        <li v-if="!disableCreate" v-show="!hasNextPage">
          <a class="createNewListItem" @click="createContactClicked"
            ><span class="fa fa-plus green">&nbsp;</span
            ><b>Create new contact...</b></a
          >
        </li>
      </template>
    </v-select>
    <input
      v-if="name"
      :id="id"
      type="hidden"
      :name="name"
      :value="selectedObjectKeyValue ? selectedObjectKeyValue : selected"
    />
  </div>
</template>

<script>
/**
 * Displays a select dropdown with a list of contacts
 * The component retrieves the data for the dropdown via ajax
 * An option at the end of the contacts list is provided to create a new
 * contact.
 */

import { HTTP } from "../../http-common.js";
import vSelect from "vue-select";
import _debounce from "lodash/debounce";

export default {
  name: "ContactsDropdown",

  components: {
    "v-select": vSelect,
  },

  props: {
    /**
     * The base url of the application
     */
    baseUrl: {
      type: String,
      default: null,
    },

    /**
     * Indicates whether the dropdown should contain Person or Corporate Contacts
     */
    corporate: {
      type: Boolean,
      default: false,
    },

    /**
     * URL which provides data for the contacts dropdown
     */
    dataSourceOptions: {
      type: String,
      default: "/biblio-edit/contact-selector-terms/",
    },

    /**
     * URL which provides detail data for a single contact
     */
    dataSourceDetail: {
      type: String,
      default: "/biblio-edit/contact-selector-info/",
    },

    /**
     * The starting value of the form input
     * This will be updated when the select value changes
     */
    value: {
      type: [Number, String, Object],
      default: null,
    },
    /**
     * The id of the form input
     */
    id: {
      type: String,
      default: null,
    },

    /**
     * The name of the form input (used for form data)
     *
     * Must be included for the form input to be included
     */
    name: {
      type: String,
      default: null,
    },

    /**
     * When set, if the selected value is an object,
     * The value in the input field will instead be set
     * to the provided key of the object.
     *
     * Example:
     * default selected value: {id: 1, name: 'example'}
     *
     * selected value when selectedValueKey = 'id': 1
     */
    selectedValueKey: {
      type: String,
      default: null,
    },

    /**
     * Set to true to disable the input
     */
    disable: {
      type: Boolean,
      default: false,
    },

    /**
     * Set to true to remove the option to create a new
     * contact
     */
    disableCreate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      /**
       * The list of options for the dropdown
       */
      options: [],

      /**
       * The selected value
       */
      selected: null,

      /**
       * The filtered options
       */
      filteredOptions: this.options,

      /**
       * The limit to the items to display on a single scroll
       */
      limit: 100,

      /**
       * Pagination object
       */
      pagination: {
        page: null,
      },

      /**
       * Observes when to increase the limit on scroll
       */
      observer: new IntersectionObserver(this.infiniteScroll),

      /**
       * Current value of the search
       */
      searchValue: "",

      loadingData: false,
    };
  },

  computed: {
    /**
     * Complete options URL built using the provided data source
     */
    dataSourceOptionsUrlWithoutPage: function () {
      let url = this.baseUrl + this.dataSourceOptions;
      if (!this.$options.propsData.dataSourceOptions) {
        url += this.corporate ? "c" : "p";
      }
      return url;
    },
    dataSourceOptionsUrl: function () {
      let url = this.dataSourceOptionsUrlWithoutPage;
      if (this.pagination.page) {
        const nextPage = this.pagination.page + 1;
        url += "?page=" + nextPage;
      } else {
        url += "?page=" + 1;
      }
      if (this.searchValue) {
        url += "&search=" + encodeURIComponent(this.searchValue);
      }
      return url;
    },

    /**
     * Complete detail URL built using the provided data source
     */
    dataSourceDetailUrl: function () {
      if (!this.selected) {
        return null;
      }
      return this.baseUrl + this.dataSourceDetail + this.selected.id;
    },

    /**
     * Checks whether there is a listener on the selected event
     */
    hasSelectedListener() {
      return this.$listeners && this.$listeners.selected;
    },

    paginated() {
      if (!this.filteredOptions) {
        return [];
      }
      return this.filteredOptions.slice(0, this.limit);
    },

    hasNextPage() {
      return (
        this.paginated.length < this.filteredOptions.length ||
        (this.pagination && this.pagination.nextPage)
      );
    },

    /**
     * Gets the selected value of the key provided
     * by this.selectedValueKey
     */
    selectedObjectKeyValue() {
      if (
        typeof this.selected === "object" &&
        this.selected !== null &&
        this.selectedValueKey !== null &&
        this.selected[this.selectedValueKey]
      ) {
        return this.selected[this.selectedValueKey];
      }
      return null;
    },
  },

  watch: {
    value(value) {
      // update the selected property when the prop changes
      this.selected = value;
    },

    options(value) {
      this.filteredOptions = value;
    },
  },

  mounted() {
    /**
     * Retrieve the data on initial load of the component
     */
    this.getData().then((response) => {
      if (response.data.pagination) {
        this.options = [...this.options, ...response.data.data];
        this.filteredOptions = this.options;
        this.selected = this.value;
        this.pagination = response.data.pagination;
      } else {
        this.options = response.data;
        this.filteredOptions = this.options;
        this.selected = this.value;
      }
    });
  },

  methods: {
    createContactClicked() {
      /**
       * Create contact clicked event
       *
       * @param {String} id The id of this element which triggered the event
       */
      this.$emit("create", this.$attrs.id ? this.$attrs.id : null);
    },

    /**
     * Called on input
     *
     * Retrieves detail data for the chosen contact and emits the
     * selected event
     */
    contactSelected() {
      if (!this.hasSelectedListener) {
        // the detail only needs to be retrieved if there is a listener for the selcted event
        return;
      }
      HTTP.get(this.dataSourceDetailUrl).then((response) => {
        if (response.data) {
          this.$emit("selected", response.data);
        }
      });
    },

    /**
     * Gets and sets the options data for the dropdown
     */
    getData() {
      return HTTP.get(this.dataSourceOptionsUrl);
    },

    /**
     * Loads additional data and updated the available options
     */
    loadAdditionalData: async function () {
      await this.$nextTick();
      if (!this.loadingData) {
        this.loadingData = true;
        const results = await HTTP.get(this.dataSourceOptionsUrl);
        if (this.pagination.page !== results.data.pagination.page) {
          let newOptions = [...this.options, ...results.data.data];
          newOptions.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          this.options = newOptions;
          this.pagination = results.data.pagination;
          this.observer.unobserve(this.$refs.load);
          this.onOpen();
        }
        this.loadingData = false;
      }
    },

    /**
     * Performs a search of the dropdown items
     */
    search: async function (value) {
      this.searchValue = value;
      if (this.pagination.page) {
        // re-fetch the results
        const response = await HTTP.get(
          this.dataSourceOptionsUrlWithoutPage +
            "?page=1&search=" +
            encodeURIComponent(value)
        );
        this.options = response.data.data;
        this.filteredOptions = this.options;
        this.pagination = response.data.pagination;
      } else {
        if (!value) {
          this.filteredOptions = this.options;
          return;
        }
        this.filter().then((result) => {
          this.filteredOptions = result;
        });
      }
    },

    /**
     * Adds a debounce to the search input of the dropdown
     */
    searchInput: _debounce(function (e) {
      this.$refs.vSelect.search = e.target.value;
    }, 500),

    /**
     * Custom filter to use on the v-select search
     */
    filter: function () {
      return new Promise((resolve) => {
        const data = this.options.filter((s) => {
          return s["name"]
            .toLowerCase()
            .includes(this.$refs.vSelect.search.toLowerCase());
        });
        resolve(data);
      });
    },

    /**
     * Triggers on dropdown open
     *
     * Watches the dropdown footer element to check when it's in view
     */
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },

    /**
     * Triggers on dropdown close
     *
     * Removes the observer
     */
    onClose() {
      this.observer.disconnect();
    },

    /**
     * Triggers on the observer
     *
     * Increases the limit of items currently available to be displayed in the dropdown
     */
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 100;
        // check if additional data needs to be loaded
        if (this.pagination && this.options.length < this.pagination.count) {
          await this.loadAdditionalData();
        }
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
  },
};
</script>

<style>
.createNewListItem {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
}
</style>
