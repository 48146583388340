<template>
  <div class="d-inline-block">
    <a :id="id">
      <img src="/common/images/barcode.png" alt="Open Barcode" />
    </a>
    <b-modal
      :id="barcodeModalId"
      size="lg"
      :title="title"
      :format="format"
      modal-footer
    >
      <b-img
        :src="barcodeImageSource"
        fluid
        center
        :alt="type + ' barcode'"
      ></b-img>
      <template #modal-footer="{ ok }">
        <b-button
          class="save-icon-btn m-2"
          size="lg"
          variant="primary"
          :href="barcodeImageSource"
          :download="title + '.' + format"
          target="_blank"
          title="Click here to download this image"
        >
          <i class="fa-solid fa-save" /> Save
        </b-button>
        <b-button size="lg" variant="light" @click="ok()">Close</b-button>
      </template>
    </b-modal>
    <b-popover :target="id" triggers="hover" placement="top">
      <b-list-group flush>
        <b-list-group-item
          button
          @click="showBarcodeModal(regularBarcodeUrl(), type, 'png')"
          >{{ type }}</b-list-group-item
        >
        <b-list-group-item
          v-for="(price, index) in priceBarcodeUrls()"
          :key="index"
          button
          @click="showBarcodeModal(price.url, price.text, 'png')"
          >{{ price.text }}</b-list-group-item
        >
        <b-list-group-item
          button
          @click="
            showBarcodeModal(
              barcodeUrlWithCurrencyPlaceholder(),
              type + ' with 90000 code',
              'png'
            )
          "
          >{{ type }} with 90000 code</b-list-group-item
        >
        <b-list-group-item
          button
          @click="
            showBarcodeModal(
              regularBarcodeUrl('svg'),
              type + ' (hi-res)',
              'png'
            )
          "
          >{{ type }} (hi-res)</b-list-group-item
        >
        <b-list-group-item
          v-for="(price, index) in priceBarcodeUrls('svg')"
          :key="'hi-res-' + index"
          button
          @click="showBarcodeModal(price.url, price.text + ' (hi-res)', 'svg')"
          >{{ price.text }} (hi-res)</b-list-group-item
        >
        <b-list-group-item
          button
          @click="
            showBarcodeModal(
              barcodeUrlWithCurrencyPlaceholder('svg'),
              type + ' with 90000 code (hi-res)',
              'svg'
            )
          "
          >{{ type }} with 90000 code (hi-res)</b-list-group-item
        >
      </b-list-group>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "BarcodeLink",

  props: {
    /**
     * Unique ID string given to this popover
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The type of the barcode
     *
     * @values ISBN, EAN, UPC
     */
    type: {
      type: String,
      required: true,
    },

    /**
     * The value of the barcodes ISBN, EAN or UPC
     *
     * ISBNs should be hyphenated
     */
    value: {
      type: String,
      required: true,
    },

    /**
     * The current user logon ID
     *
     * This is required for the barcode image to display
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * List of objects with the properties currency and amount
     * e.g. [{currency: 'GBP', amount: 12.99}]
     */
    prices: {
      type: Array,
      default: null,
    },
  },

  data: function () {
    return {
      barcodeImageSource: null,
      title: null,
      format: null,
    };
  },

  computed: {
    baseBarcodeUrl: function () {
      return "/common/barcodeimage.php/" + this.$appId + "/";
    },
    barcodeModalId: function () {
      return "modal-barcode-" + this.id;
    },
  },

  methods: {
    showBarcodeModal: function (imgSource, title, format) {
      this.barcodeImageSource = imgSource;
      this.title = title;
      this.format = format;
      this.$bvModal.show(this.barcodeModalId);
    },
    regularBarcodeUrl: function (format = "png") {
      return (
        this.baseBarcodeUrl +
        this.type +
        "." +
        this.value +
        ".barcode." +
        format +
        "?L=" +
        this.logonId
      );
    },
    barcodeUrlWithCurrencyPlaceholder: function (format = "png") {
      return (
        this.baseBarcodeUrl +
        this.type +
        "." +
        this.value +
        "90000" +
        ".barcode." +
        format +
        "?L=" +
        this.logonId
      );
    },
    priceBarcodeUrls: function (format = "png") {
      let priceUrls = [];
      if (!this.prices || this.prices.length == 0) {
        return priceUrls;
      }
      for (let price of this.prices) {
        let barcodeCurrencyCode = this.getPriceCurrencyCode(price.currency);
        if (barcodeCurrencyCode === null) {
          continue;
        }
        // barcodes can only be generated for prices under 100
        if (price.amount > 0 && price.amount <= 99) {
          priceUrls.push({
            text: this.type + " with " + price.currency + " " + price.amount,
            url:
              this.baseBarcodeUrl +
              this.type +
              "." +
              this.value +
              barcodeCurrencyCode +
              String(price.amount.toFixed(2))
                .replace(".", "")
                .padStart(4, "0") +
              ".barcode." +
              format +
              "?L=" +
              this.logonId,
          });
        }
      }
      return priceUrls;
    },
    getPriceCurrencyCode(currency) {
      switch (currency) {
        case "GBP":
          return 0;
        case "USD":
          return 5;
        case "CAD":
          return 6;
        case "AUD":
          return 3;
        case "NZD":
          return 4;
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>
/deep/ .popover-body {
  padding: 1px !important;
}
</style>
