<template>
  <div :class="rowClass">
    <div v-if="caption" class="col-3 mt-2 pr-0 caption">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <label v-html="captionHtml" />
      <select
        v-if="allowExcept"
        v-model="currentExcept"
        size="1"
        @change="listModified"
      >
        <option value="N">(these only)</option>
        <option value="Y">(all except)</option>
      </select>
    </div>
    <div :class="selectorClass">
      <v-select
        v-model="currentItems"
        label="name"
        :options="allItemsList"
        :multiple="multiple"
        :close-on-select="!multiple"
        :placeholder="'Select ' + itemType + '(s) or start typing'"
        @input="listModified"
      >
        <template #option="templateItem">
          <div
            class="list-item"
            :class="typeof templateItem.id == 'string' ? 'invalid-item' : null"
          >
            {{ templateItem.name }}
          </div>
        </template>
        <template #selected-option="templateItem">
          <div
            class="list-item"
            :class="typeof templateItem.id == 'string' ? 'invalid-item' : null"
          >
            {{ templateItem.name }}
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings accepted items authority list component
 */
import vSelect from "vue-select";

export default {
  name: "AcceptedItemsAuthList",

  components: {
    "v-select": vSelect,
  },

  props: {
    /**
     * Caption
     */
    caption: {
      type: String,
      default: null,
    },

    /**
     * Item type
     */
    itemType: {
      type: String,
      default: "item",
    },

    /**
     * Current items list (multiple items mode)
     */
    items: {
      type: Array,
      default: null,
    },

    /**
     * Current item (single item mode)
     */
    item: {
      type: [Number, String],
      default: null,
    },

    /**
     * Except items
     */
    except: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow multiple items
     */
    multiple: {
      type: Boolean,
      default: true,
    },

    /**
     * List of all items; each item is an object with the properties id and name
     */
    allItems: {
      type: Array,
      required: true,
    },

    /**
     * Allow except
     */
    allowExcept: {
      type: Boolean,
      default: true,
    },

    /**
     * Row class
     */
    rowClass: {
      type: String,
      default: "row",
    },

    /**
     * Selector Class
     */
    selectorClass: {
      type: String,
      default: "col-9 mt-2",
    },
  },

  data: function () {
    // convert list of ids to list of objects with an id, this is for use
    // with v-for
    let dataObject = {
      allItemsList: [],
      currentItems: this.multiple ? [] : null,
      currentExcept: this.except ? "Y" : "N",
    };

    for (const allItemIndex in this.allItems) {
      let allItem = this.allItems[allItemIndex];
      dataObject.allItemsList.push({
        id: allItem.id,
        name: allItem.name,
      });
    }

    if (this.multiple ? this.items : this.item) {
      let itemsList = this.multiple ? this.items : [this.item];
      for (const itemIndex in itemsList) {
        let item = itemsList[itemIndex];
        if (typeof item == "string") {
          // find this item in the allItemsList list
          let found = false;
          for (const allItemIndex in dataObject.allItemsList) {
            let allItem = dataObject.allItemsList[allItemIndex];
            if (allItem.name == item) {
              if (this.multiple) {
                dataObject.currentItems.push(allItem);
              } else {
                dataObject.currentItems = allItem;
              }
              found = true;
              break;
            }
          }
          if (!found) {
            // not found, add an item to allItemsList
            let newItem = {};
            newItem.id = item;
            newItem.name = item;
            dataObject.allItemsList.push(newItem);
            if (this.multiple) {
              dataObject.currentItems.push(newItem);
            } else {
              dataObject.currentItems = newItem;
            }
          }
        } else {
          // find this item in the allItemsList list
          for (const allItemIndex in dataObject.allItemsList) {
            let allItem = dataObject.allItemsList[allItemIndex];
            if (allItem.id == item) {
              if (this.multiple) {
                dataObject.currentItems.push(allItem);
              } else {
                dataObject.currentItems = allItem;
              }
              break;
            }
          }
        }
      }
    }
    return dataObject;
  },

  computed: {
    captionHtml: function () {
      return this.caption.replace(/\|/g, "<br />&emsp;");
    },
  },

  methods: {
    /**
     * The list has been modified
     */
    listModified: async function () {
      await this.$nextTick();
      if (this.multiple) {
        // convert list of objects with an ids to a list of ids
        let list = [];
        for (const itemIndex in this.currentItems) {
          let item = this.currentItems[itemIndex];
          if (item.id != 0) {
            list.push(item.id);
          }
        }
        this.$emit("change", list, this.currentExcept == "Y");
      } else if (this.currentItems && this.currentItems.id != 0) {
        this.$emit("change", this.currentItems.id, this.currentExcept == "Y");
      } else {
        this.$emit("change", null, this.currentExcept == "Y");
      }
    },
  },
};
</script>

<style scoped>
div.caption {
  padding-top: 5px;
}
div.caption select {
  float: right;
}
div.list-item {
  padding: 0px 2px;
}
div.invalid-item {
  background-color: #ff9999;
}
</style>
