<template>
  <b-card id="productCardSubjectsAndAudience">
    <template #header>
      <a id="subjects-and-audience" />
      <h5 class="d-inline-block">Subjects &amp; Audience</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-subject/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <dl class="row">
      <template v-if="subjects.getMainSubjects || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">Main Subject</dt>
        <dd v-if="subjects.getMainSubjects" class="col-sm-9">
          <div
            v-for="(subject, index) in subjects.getMainSubjects"
            :key="index"
          >
            {{ subject.getSchemeName }}
            <span v-if="subject.getVersion"
              >(ver {{ subject.getVersion }})</span
            >
            : <span v-if="subject.getCode">{{ subject.getCode }} -</span>
            {{ subject.getHeading }}
          </div>
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="subjects.getAdditionalSubjects || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Additional Subject(s)
        </dt>
        <dd v-if="subjects.getAdditionalSubjects" class="col-sm-9">
          <div
            v-for="(subject, index) in subjects.getAdditionalSubjects"
            :key="index"
          >
            {{ subject.getSchemeName }}
            <span v-if="subject.getVersion"
              >(ver {{ subject.getVersion }})</span
            >
            : <span v-if="subject.getCode">{{ subject.getCode }} -</span>
            {{ subject.getHeading }}
          </div>
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
    </dl>
    <dl class="row">
      <template v-if="audiences.getOnix || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Audience(s) (ONIX)
        </dt>
        <dd v-if="audiences.getOnix" class="col-sm-9">
          <div v-for="(audience, index) in audiences.getOnix" :key="index">
            {{ audience.getAudienceName }}
          </div>
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="audiences.getBTLF && audiences.getBTLF.length > 0">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Audience(s) (BTLF)
        </dt>
        <dd v-if="audiences.getBTLF" class="col-sm-9">
          <div v-for="(audience, index) in audiences.getBTLF" :key="index">
            {{ audience.getAudienceName }}
          </div>
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="audienceDescription != null || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Audience Description
        </dt>
        <dd v-if="audienceDescription != null" class="col-sm-9">
          {{ audienceDescription }}
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template
        v-if="
          complexities.getAll && Object.keys(complexities.getAll).length > 0
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Reading Level/Complexity
        </dt>
        <dd
          v-if="
            complexities.getAll && Object.keys(complexities.getAll).length > 0
          "
          class="col-sm-9"
        >
          <div v-for="(complexity, index) in complexities.getAll" :key="index">
            {{ complexity.getTypeName }} : {{ complexity.getValue }}
          </div>
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="interestAgeText">
        <dt class="col-sm-3 font-weight-bold text-secondary">Interest Age</dt>
        <dd class="col-sm-9">
          {{ interestAgeText }}
        </dd>
      </template>
      <template v-if="readingAgeText">
        <dt class="col-sm-3 font-weight-bold text-secondary">Reading Age</dt>
        <dd class="col-sm-9">
          {{ readingAgeText }}
        </dd>
      </template>
      <template v-if="audienceRanges && audienceRanges.getUSSchoolGrade">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          US/Canadian School Grade
        </dt>
        <dd class="col-sm-9">
          {{ audienceRanges.getUSSchoolGrade.getFromName }} to
          {{ audienceRanges.getUSSchoolGrade.getToName }}
        </dd>
      </template>
      <template v-if="safety.getUSChokingHazardType || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Choking Hazard (US CPSIA)
        </dt>
        <dd class="col-sm-9">
          {{ safety.getUSChokingHazardTypeName | valueOrPlaceholder }}
        </dd>
      </template>
      <template v-if="safety.getUSMagnetHazard !== null || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Magnet Hazard (US CPSIA)
        </dt>
        <dd v-if="safety.getUSMagnetHazard" class="col-sm-9">
          Product contains (a) small magnet(s)
        </dd>
        <dd v-else-if="safety.getUSMagnetHazard === false" class="col-sm-9">
          No magnet hazard warning necessary
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="safety.getUSFlashingHazard !== null || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Flashing Hazard (US CPSIA)
        </dt>
        <dd v-if="safety.getUSFlashingHazard" class="col-sm-9">
          Flashing hazard
        </dd>
        <dd v-else-if="safety.getUSFlashingHazard === false" class="col-sm-9">
          No flashing hazard warning necessary
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="safety.getUSSoundHazard !== null || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Sound Hazard (US CPSIA)
        </dt>
        <dd v-if="safety.getUSSoundHazard" class="col-sm-9">Sound hazard</dd>
        <dd v-else-if="safety.getUSSoundHazard === false" class="col-sm-9">
          No sound hazard warning necessary
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template
        v-if="safety.getUSMotionSimulationHazard !== null || showAllFields"
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Motion Simulation Hazard (US CPSIA)
        </dt>
        <dd v-if="safety.getUSMotionSimulationHazard" class="col-sm-9">
          Motion simulation hazard
        </dd>
        <dd
          v-else-if="safety.getUSMotionSimulationHazard === false"
          class="col-sm-9"
        >
          No motion simulation hazard warning necessary
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="safety.getUSTrackingNumber || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          US CPSIA Tracking Number
        </dt>
        <dd class="col-sm-9">
          {{ safety.getUSTrackingNumber | valueOrPlaceholder }}
        </dd>
      </template>
      <template
        v-if="safety.getUSProposition65Hazard !== null || showAllFields"
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          California Proposition 65 Hazard
        </dt>
        <dd v-if="safety.getUSProposition65Hazard" class="col-sm-9">
          California Proposition 65 carcinogenic, teratogenic or other
          reproductively harmful chemical hazard
        </dd>
        <dd
          v-else-if="safety.getUSProposition65Hazard === false"
          class="col-sm-9"
        >
          No California Proposition 65 hazard warning necessary
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template
        v-if="
          (safety.getUSProposition65Hazard &&
            safety.getUSProposition65HazardText) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
          Warning Text
        </dt>
        <dd class="col-sm-9">
          {{ safety.getUSProposition65HazardText | valueOrPlaceholder }}
        </dd>
      </template>
      <template
        v-if="
          (safety.getUSProposition65Hazard &&
            safety.getUSProposition65ChemicalName) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
          Chemical Name
        </dt>
        <dd class="col-sm-9">
          {{ safety.getUSProposition65ChemicalName | valueOrPlaceholder }}
        </dd>
      </template>
      <template v-if="safety.getEUToySafetyWarningType || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          EU Toy Safety Warning
        </dt>
        <dd v-if="safety.getEUToySafetyWarningType" class="col-sm-9">
          <template v-if="safety.getEUToySafetyWarningType == '00'"
            >No Warnings</template
          >
          <template v-if="safety.getEUToySafetyWarningType == '03'"
            >Unsuitable for children aged 0-3 warning logo</template
          >
          <template v-if="safety.getEUToySafetyWarningType == '0304'"
            >Unsuitable for children aged 0-3 warning logo with Hazard
            Warning</template
          >
          <template v-if="safety.getEUToySafetyWarningType == '02'"
            >Minimum age warning</template
          >
          <template v-if="safety.getEUToySafetyWarningType == '0204'"
            >Minimum age warning with Hazard warning</template
          >
          <template v-if="safety.getEUToySafetyWarningType == '04'"
            >Hazard warning</template
          >
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template
        v-if="
          (safety.getEUToySafetyWarningType &&
            safety.getEUToySafetyWarningAlternateText) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
          Alternate warning text
        </dt>
        <dd class="col-sm-9">
          {{ safety.getEUToySafetyWarningAlternateText | valueOrPlaceholder }}
        </dd>
      </template>
      <template
        v-if="safety.getEUToySafetyMaterialSafetyDataSheetLink || showAllFields"
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Material Safety Data Sheet
        </dt>
        <dd
          v-if="safety.getEUToySafetyMaterialSafetyDataSheetLink"
          class="col-sm-9"
        >
          <a
            :href="
              parseWebsiteUrl(safety.getEUToySafetyMaterialSafetyDataSheetLink)
            "
            target="_blank"
            >{{ safety.getEUToySafetyMaterialSafetyDataSheetLink }}</a
          >
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="safety.getCEMarked || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Carries 'CE' mark
        </dt>
        <dd v-if="safety.getCEMarked" class="col-sm-9">Yes</dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template
        v-if="
          (safety.getCEMarked && safety.getCEMarkDeclarationOfConformityLink) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
          Declaration of Conformity
        </dt>
        <dd
          v-if="
            safety.getCEMarked && safety.getCEMarkDeclarationOfConformityLink
          "
          class="col-sm-9"
        >
          <a
            :href="parseWebsiteUrl(safety.getCEMarkDeclarationOfConformityLink)"
            target="_blank"
            >{{ safety.getCEMarkDeclarationOfConformityLink }}</a
          >
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="safety.getEN71ConformityStatement || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">
          EN71 Conformity Statement
        </dt>
        <dd class="col-sm-9">
          {{ safety.getEN71ConformityStatement | valueOrPlaceholder }}
        </dd>
      </template>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays the Subject and Audience information
 * for the detailed display of a product
 */

import ParseUrl from "../../mixins/parseUrl.js";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "SubjectsAndAudience",

  components: {
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [ParseUrl, DisplaySetting],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * List of subjects
     */
    subjects: {
      type: Object,
      default: () => {},
    },

    /**
     * List of audiences
     */
    audiences: {
      type: Object,
      default: () => {},
    },

    /**
     * Audience description
     */
    audienceDescription: {
      type: String,
      default: null,
    },

    /**
     * List of complexities
     */
    complexities: {
      type: Object,
      default: () => {},
    },

    /**
     * List of safety warnings
     */
    safety: {
      type: Object,
      default: () => {},
    },

    /**
     * List of audience ranges
     */
    audienceRanges: {
      type: Object,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    interestAgeText: function () {
      if (!(this.audienceRanges && this.audienceRanges.getInterestAge)) {
        return null;
      }
      let text = "";
      if (
        !this.audienceRanges.getInterestAge.getFrom &&
        !this.audienceRanges.getInterestAge.getTo
      ) {
        return null;
      } else if (
        this.audienceRanges.getInterestAge.getFrom ==
        this.audienceRanges.getInterestAge.getTo
      ) {
        text = this.audienceRanges.getInterestAge.getFrom;
      } else if (
        this.audienceRanges.getInterestAge.getFrom &&
        !this.audienceRanges.getInterestAge.getTo
      ) {
        text = "From " + this.audienceRanges.getInterestAge.getFrom;
      } else if (
        !this.audienceRanges.getInterestAge.getFrom &&
        this.audienceRanges.getInterestAge.getTo
      ) {
        text = "Up to " + this.audienceRanges.getInterestAge.getTo;
      } else {
        text =
          "From " +
          this.audienceRanges.getInterestAge.getFrom +
          " to " +
          this.audienceRanges.getInterestAge.getTo;
      }
      text += " " + this.audienceRanges.getInterestAge.getTypeName;
      return text;
    },
    readingAgeText: function () {
      if (!(this.audienceRanges && this.audienceRanges.getReadingAge)) {
        return null;
      }
      let text = "";
      if (
        !this.audienceRanges.getReadingAge.getFrom &&
        !this.audienceRanges.getReadingAge.getTo
      ) {
        return null;
      } else if (
        this.audienceRanges.getReadingAge.getFrom ==
        this.audienceRanges.getReadingAge.getTo
      ) {
        text = this.audienceRanges.getReadingAge.getFrom;
      } else if (
        this.audienceRanges.getReadingAge.getFrom &&
        !this.audienceRanges.getReadingAge.getTo
      ) {
        text = "From " + this.audienceRanges.getReadingAge.getFrom;
      } else if (
        !this.audienceRanges.getReadingAge.getFrom &&
        this.audienceRanges.getReadingAge.getTo
      ) {
        text = "Up to " + this.audienceRanges.getReadingAge.getTo;
      } else {
        text =
          "From " +
          this.audienceRanges.getReadingAge.getFrom +
          " to " +
          this.audienceRanges.getReadingAge.getTo;
      }
      text += " years";
      return text;
    },
  },
};
</script>
