<template>
  <div>
    <div>
      <info-box v-if="auditFirstYear != 0">
        Audit entries older than {{ auditFirstYear }} are automatically
        archived. Please contact BooksoniX support if you need to inspect
        history older than that.
      </info-box>
      <base-table
        ref="auditTable"
        :filters-enabled="true"
        :columns="columns"
        :data-source="dataSource"
        :rows-per-page="50"
      >
        <template v-slot:type="{ record }">
          <span :class="{ 'text-primary': record.hidden.hfonly }">
            {{ record.type }}
          </span>
        </template>
        <template v-slot:description="{ record }">
          <span :class="{ 'text-primary': record.hidden.hfonly }">
            {{ record.description }}
          </span>
        </template>
        <template v-slot:user="{ record }">
          <span :class="{ 'text-primary': record.hidden.hfonly }">
            <i v-if="record.user === 'BooksoniX'">BooksoniX</i>
            <template v-else>{{ record.user }}</template>
          </span>
        </template>
        <template v-slot:entry_date="{ record }">
          <span :class="{ 'text-primary': record.hidden.hfonly }">{{
            record.entry_date | parseDate(dateOptions.format, dateOptions)
          }}</span>
        </template>
        <template v-slot:time="{ record }">
          <span :class="{ 'text-primary': record.hidden.hfonly }">{{
            record.entry_date | parseDate("HH:mm:ss", null, true)
          }}</span>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
/**
 * Displays a table of dissemination data for a product
 */
import ParseDate from "../../mixins/parseDate.js";
import InfoBox from "../ui/InfoBox.vue";

export default {
  name: "ProductAuditTable",

  components: {
    "info-box": InfoBox,
  },

  mixins: [ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      required: true,
    },

    /**
     * The audit log for the product
     */
    auditLog: {
      type: Array,
      default: null,
    },

    /**
     * First year that audit information is available for
     */
    auditFirstYear: {
      type: Number,
      default: 0,
    },
  },

  data: function () {
    return {
      dataSource:
        this.$baseUrl +
        "api/products/" +
        this.productId +
        "/product-auditentry",
    };
  },

  computed: {
    columns: function () {
      let cols = [
        { text: "Type", key: "type" },
        { text: "Details", key: "description" },
        { text: "User", key: "user" },
        { text: "Date", key: "entry_date", type: "date" },
        { text: "Time", key: "time" },
      ];
      return cols;
    },
  },
};
</script>

<style></style>
