/**
 * This file imports and includes all search form componenets
 *
 * This file should always be included in the entry file
 */

import Vue from "vue";

import SearchForm from "./SearchForm.vue";
import QuickSearchTags from "./QuickSearchTags.vue";

Vue.component("searchform", SearchForm);
Vue.component("quicksearch-tags", QuickSearchTags);
