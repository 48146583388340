<template>
  <div>
    <biblio-navigationbar
      :search-id="searchId"
      :page="page"
      :pages="pages"
      :records="records"
      :page-size="pageSize"
      :format="format"
      page-label="Page"
      action="results-list"
    >
    </biblio-navigationbar>
    <biblio-menubar
      location="t"
      :search-form="searchForm"
      :search-form-type="searchFormType"
      :has-search-form="hasSearchForm"
      :search-id="searchId"
      :all-active="allActive"
      :page="page"
      :format="format"
      :marked-records-actions-enabled="hasMarkedRecords"
      :marked-records="markedRecords"
      :ftp-catalogue-details="ftpCatalogueDetails"
      data-v-step="biblio-searchresults-menubar"
      @sortBtnClick="sortBtnClick"
      @markclick="markClick"
      @redisplayPage="redisplayPage"
    >
    </biblio-menubar>
    <div :style="containerStyle">
      <table
        id="main-table"
        class="table table-striped table-sm biblio_resultslist mt-2"
      >
        <resultslist-header
          :search-id="searchId"
          :all-records-marked="allMarked"
          :page="page"
          :pages="pages"
          :column-info="columnInfo"
          @markedChange="markedChange"
        >
        </resultslist-header>

        <tbody>
          <resultslist-line
            v-for="(productData, rowNo) in productsData"
            ref="productLines"
            :key="productData.id"
            :logon-id="logonId"
            :search-id="searchId"
            :record-pos="(page - 1) * pageSize + rowNo + 1"
            :record-id="productData.id"
            :record-marked="marked[rowNo]"
            :column-info="columnInfo"
            :column-data="productData.columns"
            :tags="productData.tags"
            :class-name="productData.class ? productData.class : null"
            :search-form="searchForm"
            :ftp-catalogue-details="ftpCatalogueDetails"
            @markedChange="markedChange"
          >
          </resultslist-line>
        </tbody>
      </table>
    </div>
    <hr />
    <biblio-navigationbar
      :search-id="searchId"
      :page="page"
      :pages="pages"
      :records="records"
      :page-size="pageSize"
      :format="format"
      page-label="Page"
      action="results-list"
    >
    </biblio-navigationbar>
    <biblio-menubar
      location="b"
      :search-form="searchForm"
      :search-form-type="searchFormType"
      :has-search-form="hasSearchForm"
      :search-id="searchId"
      :all-active="allActive"
      :page="page"
      :format="format"
      :marked-records-actions-enabled="hasMarkedRecords"
      :marked-records="markedRecords"
      :ftp-catalogue-details="ftpCatalogueDetails"
      @sortBtnClick="sortBtnClick"
      @markclick="markClick"
      @redisplayPage="redisplayPage"
    >
    </biblio-menubar>
    <sort-results-modal
      ref="sortModal"
      :search-id="searchId"
      :page="page"
      :format="format"
    >
    </sort-results-modal>
    <!-- tour -->
    <booksonix-tour
      ref="searchResultsTour"
      :name="tour.name"
      :steps="tour.steps"
      :options="{ highlight: true }"
    ></booksonix-tour>
    <!-- FTP Catalogue tour -->
    <booksonix-tour
      v-if="searchForm == 'ftp-catalogue'"
      :name="ftpCatalogueTour.name"
      :steps="ftpCatalogueTour.steps"
      :options="{ highlight: false, labels: { buttonStop: 'Okay' } }"
      :display-to-headfast-users="true"
    ></booksonix-tour>
  </div>
</template>

<script>
/**
 * Display the Biblio Results List page
 */
import ResultsListHeader from "../../biblio/ResultsListHeader.vue";
import ResultsListLine from "../../biblio/ResultsListLine.vue";
import NavigationBar from "../../biblio/NavigationBar.vue";
import MenuBar from "../../biblio/MenuBar.vue";
import SortResultsModal from "../../searchform/SortResultsModal.vue";
import { MarkedRecords } from "../../../MarkedRecords.js";

export default {
  name: "BiblioResultsListPage",

  components: {
    "resultslist-header": ResultsListHeader,
    "resultslist-line": ResultsListLine,
    "biblio-navigationbar": NavigationBar,
    "biblio-menubar": MenuBar,
    "sort-results-modal": SortResultsModal,
  },

  props: {
    /**
     * Logon id
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * Search form name
     */
    searchForm: {
      type: String,
      required: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      required: true,
    },

    /**
     * Are all records active?
     */
    allActive: {
      type: Boolean,
      default: false,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      required: true,
    },

    /**
     * Page size
     */
    pageSize: {
      type: Number,
      required: true,
    },

    /**
     * Number of pages
     */
    pages: {
      type: Number,
      required: true,
    },

    /**
     * Number of records
     */
    records: {
      type: Number,
      required: true,
    },

    /**
     * Current format
     */
    format: {
      type: String,
      default: "",
    },

    /**
     * Column information (each entry in an object with the properties 'caption', 'width', 'sort' (optional),
     * 'dlink' (bool, optional), 'tags' (bool, optional), 'marker' (bool, optional))
     */
    columnInfo: {
      type: Array,
      required: true,
    },

    /**
     * Products data (each entry in an object with the properties 'id', 'columns', 'class')
     */
    productsData: {
      type: Array,
      required: true,
    },

    /**
     *  Search type ('form', 'form-tag', 'quick', 'link')
     */
    searchFormType: {
      type: String,
      default: "form",
    },

    /**
     * Object containing details of the FTP catalogue. If this is present
     * then the search form and results are being used to update the search
     * configuration on an existing catalogue.
     *
     * @property {string} mode The mode of the catalogue
     * @property {int} id The ID of the catalogue
     */
    ftpCatalogueDetails: {
      type: Object,
      default: null,
    },
  },

  data: function () {
    let dataObj = {};
    dataObj.markedRecords = new MarkedRecords(
      this.$baseUrl,
      this.searchId,
      this.records
    );
    dataObj.marked = [];
    let markedCount = 0;
    for (let i = 0; i < this.productsData.length; i++) {
      dataObj.marked[i] = dataObj.markedRecords.isMarked(
        this.productsData[i].id
      );
      if (dataObj.marked[i]) markedCount++;
    } // for
    dataObj.allMarked = markedCount == this.productsData.length;
    dataObj.hasMarkedRecords = dataObj.markedRecords.getNumMarked() != 0;
    dataObj.tour = {
      name: "biblio-searchresults",
      steps: [
        {
          target: '[data-v-step="biblio-searchresults-header"]',
          content:
            'This display is now called a "Results list" rather than "Summary Display"',
          offset: -400,
        },
        {
          target: '[data-v-step="biblio-searchresults-tag"]',
          content: 'Apply and manage your custom tags using the "Tag" button.',
          offset: -400,
        },
        {
          target: '[data-v-step="biblio-searchresults-column3"]',
          content:
            "Click on column headers to sort by the values in that column, click again to reverse the sort ordering.",
          offset: -400,
        },
        {
          target: '[data-v-step="biblio-searchresults-menubar"]',
          content:
            'The links above this section retain all of the functionality  in the previous display but you can now access data export and dissemination functionality from all results lists using the "Export" link.<br><br>If your subscription includes the marketing material module for generating AI sheets, tip sheets and catalogues, you can now also access this from all results lists to avoid having to navigate to the specific module to generate documents.',
          offset: -400,
        },
        {
          target: '[data-v-step="biblio-searchresults-title1"]',
          content:
            "As before, click on the title to go to the detailed display.",
          offset: -400,
        },
      ],
    };
    dataObj.ftpCatalogueTour = {
      name: "ftp-catalogue-search",
      steps: [
        {
          target: '[data-v-step="export-btn"]',
          content:
            "Use the <strong>Facebook Catalogue FTP Feed</strong> option of <strong>Exports and Data Feeds</strong> to use this search to create an FTP file for the Facebook catalogue.",
          offset: -400,
          params: {
            placement: "top",
            enableScrolling: false,
          },
        },
      ],
    };
    return dataObj;
  },

  computed: {
    hasSearchForm: function () {
      return this.searchFormType !== "quick";
    },
  },

  mounted: function () {
    window.addEventListener("beforeprint", this.splitTableForPrint);
    window.addEventListener("afterprint", this.revertTableAfterPrint);
  },

  destroyed: function () {
    window.removeEventListener("beforeprint", this.splitTableForPrint);
    window.removeEventListener("afterprint", this.revertTableAfterPrint);
  },

  methods: {
    // disable overflow when tour is running as it interferes with the highlighting
    containerStyle: function () {
      if (!this.$tours) {
        return false;
      }
      return {
        "overflow-x": this.$tours["biblio-searchresults"].isRunning
          ? false
          : "auto",
      };
    },
    /**
     * A marked record check box has been clicked
     * @param int|null productId  The id of the record whose marked tick box was clicked;
     *                            null if the header row marked tick box was clicked
     */
    markedChange: function (productId, checked) {
      if (productId) {
        // mark/unmark the specified record
        this.markedRecords.setMarked(productId, checked);
      } else {
        // mark/unmark all records on the page
        for (let i = 0; i < this.productsData.length; i++) {
          this.markedRecords.setMarked(this.productsData[i].id, checked);
        } // for
      }
      this.updateMarkState(false);
    },

    /**
     * The sort menu item has been clicked
     */
    sortBtnClick(event) {
      event.preventDefault();
      this.$refs.sortModal.showModal();
    },

    /**
     * A mark menu item has been clicked
     */
    markClick(event, action) {
      event.preventDefault();
      switch (action) {
        case "all":
          this.markedRecords.markAll(() => {
            this.updateMarkState(true);
          });
          break;
        case "none":
          this.markedRecords.clearAll(() => {
            this.updateMarkState(true);
          });
          break;
        case "reverse":
          this.markedRecords.invertAll(() => {
            this.updateMarkState(true);
          });
          break;
      }
    },

    /**
     * Update the mark state held in local data
     */
    updateMarkState(updateLineControls) {
      let markedCount = 0;
      for (let i = 0; i < this.productsData.length; i++) {
        this.marked[i] = this.markedRecords.isMarked(this.productsData[i].id);
        if (this.marked[i]) markedCount++;
      } // for
      this.allMarked = markedCount == this.productsData.length;
      this.hasMarkedRecords = this.markedRecords.getNumMarked() != 0;

      if (updateLineControls) {
        for (let i = 0; i < this.productsData.length; i++) {
          this.$refs.productLines[i].recordMarked = this.markedRecords.isMarked(
            this.$refs.productLines[i].recordId
          );
        } // for
      }
    },

    /**
     * Redisplay the current page
     */
    redisplayPage() {
      location.href =
        this.$baseUrl +
        "biblio/results-list/" +
        this.searchId +
        "/" +
        this.page +
        (this.format != "" ? "/" + this.format : "");
    },

    /**
     * Splitting table to make output printer friendly
     */
    splitTableForPrint() {
      const table = document.getElementById("main-table");
      const columnsPerPage = 5; // Adjust this value based on your paper size
      const headerRow = table.querySelector("thead tr:last-child");
      const rows = table.querySelectorAll("tbody tr");
      const columnCount = headerRow.children.length;

      table.classList.add("no-print");

      // Remove any existing print tables
      document
        .querySelectorAll(".print-table")
        .forEach((table) => table.remove());

      // remove banner and menu bars from output
      document
        .querySelectorAll(".logo")
        .forEach((element) => element.classList.add("no-print"));
      document
        .querySelectorAll(".menubar")
        .forEach((element) => element.classList.add("no-print"));
      document
        .querySelectorAll(".menubar-testsite")
        .forEach((element) => element.classList.add("no-print"));
      document
        .querySelectorAll(".biblio_resultslist_menubar")
        .forEach((element) => element.classList.add("no-print"));
      document
        .querySelectorAll(".biblio_navigationbar")
        .forEach((element) => element.classList.add("no-print"));

      for (
        let startCol = 1;
        startCol < columnCount;
        startCol += columnsPerPage
      ) {
        const clonedTable = document.createElement("table");
        clonedTable.classList.add("print-table", "print-only");
        clonedTable.style.borderCollapse = "collapse";
        clonedTable.style.width = "auto";

        const clonedThead = document.createElement("thead");
        const clonedTbody = document.createElement("tbody");

        // Clone the header row with necessary columns
        const clonedHeaderRow = document.createElement("tr");
        for (let i = 1; i < columnCount; i++) {
          if (i === 1 || (i >= startCol && i < startCol + columnsPerPage)) {
            const clonedHeaderCell = headerRow.children[i].cloneNode(true);
            clonedHeaderRow.appendChild(clonedHeaderCell);
          }
        }
        clonedThead.appendChild(clonedHeaderRow);
        clonedTable.appendChild(clonedThead);

        // Clone each row with necessary columns
        rows.forEach((row) => {
          const clonedRow = document.createElement("tr");
          for (let i = 1; i < columnCount; i++) {
            if (i === 1 || (i >= startCol && i < startCol + columnsPerPage)) {
              const clonedCell = row.children[i].cloneNode(true);
              clonedRow.appendChild(clonedCell);
            }
          }
          clonedTbody.appendChild(clonedRow);
        });
        clonedTable.appendChild(clonedTbody);

        document.body.appendChild(clonedTable);
      }
    },

    /**
     * Revert table back to normal after print
     */
    revertTableAfterPrint() {
      // Show the original table after print
      const table = document.getElementById("main-table");
      table.classList.remove("no-print");

      // Remove dynamically created print tables
      document
        .querySelectorAll(".print-table")
        .forEach((table) => table.remove());
      document
        .querySelectorAll(".no-print")
        .forEach((element) => element.classList.remove("no-print"));
    },
  },
};
</script>

<style>
@media print {
  body {
    margin: 0;
    font-family: system-ui;
  }
  h2 {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    padding: 10px 0;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 8px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  th {
    background-color: #fff;
    color: black;
    font-weight: 600;
  }
  tr:nth-child(odd) {
    background-color: #efefef;
  }
  .print-only {
    display: table;
  }
  .print-only:not(:first-of-type) {
    page-break-before: always;
  }
  .no-print {
    display: none;
  }
}
</style>
