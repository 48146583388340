/**
 * Marked records manager
 *
 * This uses session storage where each page/tab is a different session therefore we don't need to worry about
 * what application this is. This class work with different searches in the same session (which can happen if
 * a link search is performed)
 */
import { HTTP } from "./http-common.js";

export default class MarkedRecords {
  /**
   * Setup the MarkedRecords class
   * @param string baseUrl  Base url
   * @param int searchId    Search id
   * @param int records     Number of records (optional); this is used as a hint in markAll() and invertAll()
   *                        but it doesn't matter if you don't know it
   */
  constructor(baseUrl, searchId, records = -1) {
    this.getRecordIdsUrl = baseUrl + "search/get-search-record-ids/" + searchId;
    this.searchId = searchId;
    this.numRecords = records;
    let numMarked = sessionStorage.getItem(this.searchId);
    if (numMarked !== null) {
      this.numMarked = parseInt(numMarked);
    } else {
      sessionStorage.setItem(this.searchId, 0);
      this.numMarked = 0;
    }
  }

  /**
   * Is a record marked?
   * @param int recordId  The record's identifier
   * @return bool
   */
  isMarked(recordId) {
    return !!sessionStorage.getItem(this.searchId + "." + recordId);
  }

  /**
   * Set/clear a record's marked status
   * @param int recordId  The record's identifier
   * @param bool marked   Marked or not
   * @return void
   */
  setMarked(recordId, marked) {
    if (this.isMarked(recordId) != marked) {
      // mark/unmark this record
      if (marked) {
        sessionStorage.setItem(this.searchId + "." + recordId, "Y");
        this.numMarked++;
      } else {
        sessionStorage.removeItem(this.searchId + "." + recordId);
        this.numMarked--;
      }
      // update the count
      sessionStorage.setItem(this.searchId, this.numMarked);
    }
  }

  /**
   * Get the number of marked records
   * @return int
   */
  getNumMarked() {
    return this.numMarked;
  }

  /**
   * Get all the marked record ids
   * @return array
   */
  getAll() {
    let recordIds = [];
    let keyPrefix = this.searchId + ".";
    for (
      let i = 0;
      i < sessionStorage.length && recordIds.length < this.numMarked;
      i++
    ) {
      let thisKey = sessionStorage.key(i);
      if (thisKey.substring(0, keyPrefix.length) == keyPrefix) {
        recordIds[recordIds.length] = parseInt(
          thisKey.substring(keyPrefix.length)
        );
      }
    } // for
    return recordIds;
  }

  /**
   * Set all records' marked status
   * @param function onComplete   Completion function
   */
  markAll(onComplete) {
    if (this.numMarked != this.numRecords) {
      // get all record ids
      HTTP.get(this.getRecordIdsUrl)
        .then((response) => {
          // mark all records
          let recordIds = response.data;
          for (let i = 0; i < recordIds.length; i++) {
            this.setMarked(recordIds[i], true);
          } // for

          // update the record count (which may not be known); we don't need to update the marked count
          // as setMarked() has done that
          this.numRecords = recordIds.length;

          // call the completion handler
          if (onComplete) {
            onComplete();
          }
        })
        .catch(() => {});
    } else {
      // nothing to do, call the completion handler
      if (onComplete) {
        onComplete();
      }
    }
  }

  /**
   * Clear all records' marked status
   * @param function onComplete   Completion function
   */
  clearAll(onComplete) {
    if (this.numMarked != 0) {
      if (sessionStorage.length == this.numMarked + 1) {
        // the number of keys equals number of marked records (1 key per marked record) plus 1 (the count key)
        sessionStorage.clear();
      } else {
        // get the keys to remove
        let keyPrefix = this.searchId + ".";
        let count = this.numMarked;
        let removeKeys = [];
        for (let i = sessionStorage.length - 1; i >= 0 && count > 0; i--) {
          let thisKey = sessionStorage.key(i);
          if (thisKey.substring(0, keyPrefix.length) == keyPrefix) {
            removeKeys[removeKeys.length] = thisKey;
            count--;
          }
        } // for

        // remove the keys
        for (let i = 0; i < removeKeys.length; i++) {
          sessionStorage.removeItem(removeKeys[i]);
        } // for
      }

      // update the count
      this.numMarked = 0;
      sessionStorage.setItem(this.searchId, 0);
    }

    // call the completion handler
    onComplete();
  }

  /**
   * Invert all records' marked status
   * @param function onComplete   Completion function
   */
  invertAll(onComplete) {
    if (this.numMarked == 0) {
      // no records marked, mark all
      this.markAll(onComplete);
    } else if (this.numMarked == this.numRecords) {
      // all records marked, clear all
      this.clearAll(onComplete);
    } else {
      // get all record ids
      HTTP.get(this.getRecordIdsUrl)
        .then((response) => {
          // invert the marks
          let recordIds = response.data;
          for (let i = 0; i < recordIds.length; i++) {
            this.setMarked(recordIds[i], !this.isMarked(recordIds[i]));
          } // for

          // update the record count (which may not be known); we don't need to update the marked count
          // as setMarked() has done that
          this.numRecords = recordIds.length;

          // call the completion handler
          onComplete();
        })
        .catch(() => {});
    }
  }
}

export { MarkedRecords };
