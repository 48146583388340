<template>
  <b-card no-body class="p-2 product-summary-card">
    <div class="row">
      <div class="col product-card-img mt-2">
        <product-media-img
          class="m-auto"
          style="height: 130px"
          thumbnail
          :product-id="product.getRecordNumber"
          type="IMG_FRONT"
          :disable-overlay="true"
          :date-options="dateOptions"
          :img-height="130"
        ></product-media-img>
      </div>
      <div class="col pl-0 pr-2">
        <div class="row h-50">
          <div class="col">
            <h5 class="mt-2 mb-0 product-summary-title">
              <a :href="productLink">{{
                product.getMainTitle | truncate(120)
              }}</a>
              <product-status-badge
                v-if="
                  product.getProductStatus &&
                  product.getProductStatus.getProductStatus
                "
                :status="product.getProductStatus.getProductStatus"
              ></product-status-badge>
              <product-visibility-badge
                v-if="
                  product.getProductStatus &&
                  product.getProductStatus.getProductVisibility
                "
                :visibility="product.getProductStatus.getProductVisibility"
                class="ml-1 product-summary-badge"
              >
              </product-visibility-badge>
              <b-badge
                v-if="sameTwi"
                class="ml-1 product-summary-badge"
                variant="secondary"
                >Same TWI</b-badge
              >
            </h5>
            <div
              class="text-muted pt-1 font-weight-bold related-type-text d-flex align-items-center"
            >
              {{ product.getRelatedTypeName }}
            </div>
          </div>
        </div>
        <div class="row h-50 align-items-end">
          <div class="col-sm-4">
            <label class="mr-sm-2 text-muted">{{
              displayedIdentifier !== null
                ? displayedIdentifier.typeName
                : "ISBN-13"
            }}</label>
            <div class="font-weight-bold text-nowrap">
              {{
                displayedIdentifier !== null
                  ? displayedIdentifier.value
                  : product.getISBN13 || "-"
              }}
            </div>
          </div>
          <div class="col-sm-4">
            <label class="mr-sm-2 text-muted">Format</label>
            <div class="font-weight-bold text-truncate">
              {{ productFormatText || "-" }}
            </div>
          </div>
          <div class="col-sm-4">
            <label class="mr-sm-2 text-muted">Published</label>
            <div class="font-weight-bold text-truncate">
              <template
                v-if="
                  product.getPublishingDates &&
                  product.getPublishingDates.getPublicationDate
                "
              >
                {{
                  product.getPublishingDates.getPublicationDate
                    | parseDate(dateOptions.format, cardDateOptions)
                }}
              </template>
              <span v-else>-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
/**
 * Displays a summary card for a product
 * This will display basic information as well
 * as the front cover and status
 */
import ProductMediaImg from "./ProductMediaImg.vue";
import ParseDate from "../../mixins/parseDate.js";
import ProductStatusBadge from "./ProductStatusBadge.vue";
import ProductVisibilityBadge from "./ProductVisibilityBadge.vue";

export default {
  name: "ProductSummaryCard",
  components: {
    "product-media-img": ProductMediaImg,
    "product-status-badge": ProductStatusBadge,
    "product-visibility-badge": ProductVisibilityBadge,
  },
  filters: {
    truncate: function (value, maxChars) {
      if (!value) {
        return "";
      }
      return (
        value.slice(0, maxChars).trim() + (maxChars < value.length ? "..." : "")
      );
    },
  },
  mixins: [ParseDate],
  props: {
    /**
     * Product data
     *
     * The data passed in must be an assosiative array (object in JS) of product data.
     * The keys of the array must match those defined in BooksonixProduct.php
     * Use the dump method to get an appropriate aray of product data
     */
    product: {
      type: Object,
      required: true,
    },

    /**
     * Set to true to add an additional badge to highlight
     * this product card as having the same TWI as the product
     * it's displayed on
     */
    sameTwi: {
      type: Boolean,
      default: false,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * An alternative to using the identifiers in
     * the product object. Identifers can be passed
     * to this component, allowing for identifiers other
     * than ISBN-13 to be shown in the summary card.
     */
    identifiers: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      cardDateOptions: {
        day: "dd",
        month: "MMM",
        year: "yyyy",
        separator: " ",
      },
    };
  },
  computed: {
    /**
     * Calculates the appropriate link for the product which could be
     * a search link
     */
    productLink: function () {
      if (this.searchId !== null && this.page !== null) {
        return (
          this.$baseUrl +
          "search/link/biblio/" +
          this.searchId +
          "/" +
          this.page +
          "/biblio?recno=" +
          this.product.getRecordNumber
        );
      } else {
        return (
          this.$baseUrl +
          "/search/quick/biblio?recno=" +
          this.product.getRecordNumber
        );
      }
    },

    displayedIdentifier: function () {
      if (!this.identifiers) {
        return null;
      }
      // display ISBN-13 if available, otherwise use the first identifier
      if ("15" in this.identifiers) {
        return this.identifiers["15"];
      } else {
        return this.identifiers[Object.keys(this.identifiers)[0]];
      }
    },

    productFormatText: function () {
      if (!this.product.getFormatName) {
        return null;
      }
      if (this.product.getFormatName == "Electronic book text") {
        let formatText = "E-book";
        if (this.product.getFormatDetailsNames) {
          formatText += " – " + this.product.getFormatDetailsNames;
        }
        return formatText;
      }
      return this.product.getFormatName;
    },
  },
};
</script>

<style scoped>
.product-summary-card {
  height: 155px;
}
@media (max-width: 575.98px) {
  .product-summary-card {
    height: 277px;
  }
}
.product-card-img {
  -ms-flex: 0 0 108px;
  flex: 0 0 108px;
}
.product-summary-title {
  max-height: 60px;
  overflow: hidden;
}
.product-summary-badge {
  font-size: 0.9rem;
}
.related-type-text {
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
