var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"columns":_vm.columns,"initial-data":_vm.rules,"search-enabled":false},scopedSlots:_vm._u([{key:"rule_type",fn:function(ref){
var record = ref.record;
return [(record.rule_type == 'D')?[_vm._v("Discount")]:(record.rule_type == 'U')?[_vm._v("Units")]:(record.rule_type == 'C')?[_vm._v("Combi")]:[_vm._v(_vm._s(record.rule_type))]]}},{key:"discount_start",fn:function(ref){
var record = ref.record;
return [(record.discount_start !== null && record.discount_start >= 0)?[_vm._v(_vm._s(record.discount_start.toFixed(2))+"%")]:_vm._e()]}},{key:"discount_end",fn:function(ref){
var record = ref.record;
return [(record.discount_end !== null && record.discount_end >= 0)?[_vm._v(_vm._s(record.discount_end.toFixed(2))+"%")]:_vm._e()]}},{key:"units_start",fn:function(ref){
var record = ref.record;
return [(record.units_start !== null && record.units_start >= 0)?[_vm._v(_vm._s(record.units_start.toFixed(0)))]:_vm._e()]}},{key:"units_end",fn:function(ref){
var record = ref.record;
return [(record.units_end !== null && record.units_end >= 0)?[_vm._v(_vm._s(record.units_end.toFixed(0)))]:_vm._e()]}},{key:"rate_perc",fn:function(ref){
var record = ref.record;
return [(record.rate_perc !== null && record.rate_perc >= 0)?[_vm._v(_vm._s(record.rate_perc.toFixed(2))+"%")]:_vm._e()]}},{key:"payment_type",fn:function(ref){
var record = ref.record;
return [(record.payment_type == 'G')?[_vm._v("Gross")]:(record.payment_type == 'N')?[_vm._v("Net")]:(record.payment_type == 'F')?[_vm._v("Fixed")]:[_vm._v("Unknown")]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }