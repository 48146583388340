/**
 * Mixin to provide a display setting to
 * components used on the detailed display screen.
 *
 * Provides a display prop which contains the display
 * setting a computed function for when all fields
 * should be displayed.
 *
 * @author George Betts
 */

var DisplaySetting = {
  filters: {
    valueOrPlaceholder(value, placeholder = "\u2014") {
      if (!value) return placeholder;
      return value;
    },
  },
  props: {
    /**
     * Display setting sets which fields should be displayed
     *
     * @values full, brief, all
     */
    display: {
      type: String,
      default: "full",
    },
  },
  computed: {
    showAllFields: function () {
      return this.display === "all";
    },
  },
};

export default DisplaySetting;
