<template>
  <div>
    <div
      v-if="automaticFeedsSuspended"
      class="alert alert-warning message error"
    >
      <b>Warning:</b> All automatic feeds for this application are temporarily
      suspended. Use the <i>Application Options</i> tab to resume.
    </div>
    <b-tabs v-model="currentTab">
      <b-tab title="Notification" active jest-notification-tab>
        <!-- notify tab -->
        <dissemination-settings-client-notify
          :client-notify="clientNotify"
          :read-only="readOnly"
          @change="clientNotifyModified = true"
          @save="clientNotifyModified = false"
          @discard="clientNotifyModified = false"
        />
      </b-tab>
      <b-tab title="Application Options" jest-application-options-tab>
        <!-- application options tab -->
        <dissemination-settings-application-options
          :return-condition-types="returnConditionTypes"
          :application-options="applicationOptions"
          :read-only="readOnly"
          @change="applicationOptionsModified = true"
          @save="applicationOptionsModified = false"
          @discard="applicationOptionsModified = false"
          @automatic-feeds-suspended="
            (state) => {
              automaticFeedsSuspended = state;
            }
          "
        />
      </b-tab>
      <b-tab title="Feeds" jest-feeds-tab>
        <!-- feeds tab -->
        <dissemination-settings-feeds
          :recipient-groups="recipientGroups"
          :data-formats="dataFormats"
          :return-condition-types="returnConditionTypes"
          :languages="languages"
          :countries="countries"
          :formats="formats"
          :currencies="currencies"
          :price-types="priceTypes"
          :discount-schemes="discountSchemes"
          :global-tags="globalTags"
          :read-only="readOnly"
          @recipients="
            (recipients) => {
              recipientsList = recipients;
            }
          "
          @state-change="
            (modified) => {
              feedsModified = modified;
            }
          "
        />
      </b-tab>
      <b-tab title="Notes" jest-notes-tab>
        <!-- notes tab -->
        <div class="row">
          <div class="col-12">
            Use this tab to store notes/comments about disseminations for this
            BooksoniX application.
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-2">
            <textarea
              v-model="currentNotes"
              class="notes"
              @input="notesChanged"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-2 mt-2">
            <button
              class="btn"
              :class="{
                'btn-success': notesModified,
                'btn-secondary': !notesModified,
                disabled: readOnly || !notesModified,
              }"
              @click="saveNotesChanges"
            >
              Save
            </button>
            <button
              class="btn btn-padleft"
              :class="{
                'btn-danger': notesModified,
                'btn-secondary': !notesModified,
                disabled: !notesModified,
              }"
              @click="discardNotesChanges"
            >
              Discard
            </button>
          </div>
          <div class="col-5">
            <save-indicator
              :status="notesSaveStatus"
              complete-message="Saved changes"
              @status-cleared="notesSaveStatus = null"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Custom Scripts" jest-xslt-scripts-tab>
        <!-- scripts tab -->
        <div class="row">
          <div class="col-12">
            Use this tab to edit the custom dissemination scripts used by this
            application.
            <b>Do not change these unless you know what you are doing.</b>
          </div>
        </div>
        <dissemination-settings-xslt-scripts
          :visible="currentTab == 4"
          :live-server="liveServer"
          :read-only="readOnly"
          :recipients="recipientsList"
          @change="xsltModified = true"
          @save="xsltModified = false"
          @discard="xsltModified = false"
        />
      </b-tab>
    </b-tabs>

    <br />
    <div class="formcontrols">
      <a
        :href="
          $baseUrl + 'customisation/dissemination-settings/close/customisation'
        "
        class="btn btn-primary"
        @click="canClose"
      >
        <span class="fa fa-close"></span> Close
      </a>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings form
 */
import DisseminationSettingsClientNotify from "../../customisation/disseminationSettings/ClientNotify.vue";
import DisseminationSettingsApplicationOptions from "../../customisation/disseminationSettings/ApplicationOptions.vue";
import DisseminationSettingsFeeds from "../../customisation/disseminationSettings/Feeds.vue";
import DisseminationSettingsXsltScripts from "../../customisation/disseminationSettings/XsltScripts.vue";
import SaveIndicator from "../../ui/SaveIndicator.vue";
import { HTTP } from "../../../http-common.js";

export default {
  name: "DisseminationSettingsPage",

  components: {
    "dissemination-settings-client-notify": DisseminationSettingsClientNotify,
    "dissemination-settings-application-options":
      DisseminationSettingsApplicationOptions,
    "dissemination-settings-feeds": DisseminationSettingsFeeds,
    "dissemination-settings-xslt-scripts": DisseminationSettingsXsltScripts,
    "save-indicator": SaveIndicator,
  },

  props: {
    /**
     * List of all feed recipient groups
     */
    recipientGroups: {
      type: Array,
      required: true,
    },

    /**
     * List of all return condition types
     */
    returnConditionTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all languages
     */
    languages: {
      type: Array,
      required: true,
    },

    /**
     * List of all countries
     */
    countries: {
      type: Array,
      required: true,
    },

    /**
     * List of all formats
     */
    formats: {
      type: Array,
      required: true,
    },

    /**
     * List of all currencies
     */
    currencies: {
      type: Array,
      required: true,
    },

    /**
     * List of all price types
     */
    priceTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all discount schemes
     */
    discountSchemes: {
      type: Array,
      required: true,
    },

    /**
     * List of all global tags
     */
    globalTags: {
      type: Array,
      required: true,
    },

    /**
     * Client notification settings
     */
    clientNotify: {
      type: Object,
      default: null,
    },

    /**
     * Application options
     */
    applicationOptions: {
      type: Object,
      default: null,
    },

    /**
     * Data formats
     */
    dataFormats: {
      type: Object,
      default: null,
    },

    /**
     * Notes
     */
    notes: {
      type: String,
      default: "",
    },

    /**
     * Live server
     */
    liveServer: {
      type: Boolean,
      default: false,
    },

    /**
     * read-only
     */
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    let applicationOptions = this.applicationOptions ?? {};
    let notes = this.unescapeData(this.notes);
    return {
      currentTab: 0,
      clientNotifyModified: false,
      applicationOptionsModified: false,
      automaticFeedsSuspended:
        applicationOptions.automaticFeedsSuspended ?? false,
      feedsModified: false,
      currentNotes: notes,
      lastSavedNotes: notes,
      notesModified: false,
      notesSaveStatus: null,
      xsltModified: false,
      recipientsList: null,
    };
  },

  mounted: function () {
    // hook into the main menu banner link
    let mainMenuBannerLink = document.getElementById("mainmenulink1");
    if (mainMenuBannerLink) {
      mainMenuBannerLink.href =
        this.$baseUrl + "customisation/dissemination-settings/close/main";
      mainMenuBannerLink.addEventListener("click", this.canClose);
    }
  },

  unmounted: function () {
    // unhook from the main menu banner link
    let mainMenuBannerLink = document.getElementById("mainmenulink1");
    if (mainMenuBannerLink) {
      mainMenuBannerLink.removeEventListener("click", this.canClose);
    }
  },

  methods: {
    /**
     * Unescape backslash-escaped data
     */
    unescapeData: function (data) {
      return data
        .replace(/\\t/g, "\t")
        .replace(/\\n/g, "\n")
        .replace(/\\r/g, "\r")
        .replace(/\\\\/g, "\\");
    },

    /**
     * Notes have been changed
     */
    notesChanged: function () {
      this.notesModified = true;
    },

    /**
     * Save notes changes
     */
    saveNotesChanges: function () {
      if (this.readOnly || !this.notesModified) {
        return;
      }

      // save the changes
      let url = this.$baseUrl + "customisation/dissemination-settings";
      let postData = new URLSearchParams();
      postData.append("notes", this.currentNotes);

      this.notesSaveStatus = "saving";
      HTTP.post(url, postData)
        .then(() => {
          // changes have been saved
          this.lastSavedNotes = this.currentNotes;
          this.notesModified = false;
          this.notesSaveStatus = "saved";
        })
        .catch(() => {
          this.notesSaveStatus = null;
          this.$bvModal.msgBoxOk(
            "An error has occurred while saving your notes"
          );
        });
    },

    /**
     * Discard notes changes
     */
    discardNotesChanges: function () {
      if (this.notesModified) {
        this.currentNotes = this.lastSavedNotes;
        this.notesModified = false;
      }
    },

    /**
     * Can we close the page?
     */
    canClose: function (event) {
      if (
        this.clientNotifyModified ||
        this.applicationOptionsModified ||
        this.feedsModified ||
        this.notesModified ||
        this.xsltModified
      ) {
        event.preventDefault();
        this.$bvModal
          .msgBoxConfirm(
            "There are unsaved changes. Are you sure you want to exit without saving them first?"
          )
          .then((value) => {
            if (value) {
              window.location.href = event.target.href;
            } else {
              if (this.clientNotifyModified) {
                this.currentTab = 0;
              } else if (this.applicationOptionsModified) {
                this.currentTab = 1;
              } else if (this.feedsModified) {
                this.currentTab = 2;
              } else if (this.notesModified) {
                this.currentTab = 3;
              } else {
                this.currentTab = 4;
              }
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.btn-padleft {
  margin-left: 1rem;
}
textarea.notes {
  width: 98%;
  resize: none;
  height: 30rem;
}
span.applicationoptions-allfeedssuspended {
  font-weight: bold;
  color: red;
}
</style>
