<template>
  <b-card id="contactCardMarketing">
    <template #header>
      <a id="marketing" />
      <h5 class="d-inline-block">Marketing</h5>
      <edit-icon
        v-if="$permitted('contacts/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/contacts/edit/' +
          contactId +
          '?current_tab=3' +
          (returnTo ? '&return=' + returnTo : '')
        "
      ></edit-icon>
      <contact-quick-jump-dropdown
        class="float-right m-0"
        :contact-id="contactId"
      ></contact-quick-jump-dropdown>
    </template>
    <dl v-if="contact" class="row">
      <dt class="col-sm-3 font-weight-bold text-secondary">Website</dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.WebsitesUrl"
          :href="parseWebsiteUrl(contact.WebsitesUrl)"
          target="_blank"
          >{{ contact.WebsitesUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Facebook</dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.FacebookUrl"
          :href="parseWebsiteUrl(contact.FacebookUrl)"
          target="_blank"
          >{{ contact.FacebookUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Twitter</dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.TwitterUrl"
          :href="parseWebsiteUrl(contact.TwitterUrl)"
          target="_blank"
          >{{ contact.TwitterUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Blogs</dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.BlogsUrl"
          :href="parseWebsiteUrl(contact.BlogsUrl)"
          target="_blank"
          >{{ contact.BlogsUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Goodreads</dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.GoodreadsUrl"
          :href="parseWebsiteUrl(contact.GoodreadsUrl)"
          target="_blank"
          >{{ contact.GoodreadsUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Instagram</dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.InstagramUrl"
          :href="parseWebsiteUrl(contact.InstagramUrl)"
          target="_blank"
          >{{ contact.InstagramUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Pinterest</dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.PinterestUrl"
          :href="parseWebsiteUrl(contact.PinterestUrl)"
          target="_blank"
          >{{ contact.PinterestUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Youtube</dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.YouTubeUrl"
          :href="parseWebsiteUrl(contact.YouTubeUrl)"
          target="_blank"
          >{{ contact.YouTubeUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">LinkedIn</dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.LinkedInUrl"
          :href="parseWebsiteUrl(contact.LinkedInUrl)"
          target="_blank"
          >{{ contact.LinkedInUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Amazon Author Page
      </dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.AmazonUrl"
          :href="parseWebsiteUrl(contact.AmazonUrl)"
          target="_blank"
          >{{ contact.AmazonUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">TikTok</dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.TikTokUrl"
          :href="parseWebsiteUrl(contact.TikTokUrl)"
          target="_blank"
          >{{ contact.TikTokUrl }}</a
        >
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Subjects</dt>
      <dd class="col-sm-9">
        <template
          v-if="
            bicSubjects.length != 0 ||
            bisacSubjects.length != 0 ||
            themaSubjects.length != 0 ||
            keywordSubjects.length != 0
          "
        >
          <a :href="titleSearch + '?MAINSUBJ=' + subjectSearchString"
            >View Titles</a
          >
          with any of these BIC, BISAC or THEMA codes, or keywords
        </template>
      </dd>
      <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">BIC Codes</dt>
      <dd class="col-sm-9">
        <div v-for="(subject, index) in bicSubjects" :key="index">
          {{ subject.Code
          }}<template v-if="subject.Version">
            (ver {{ subject.Version }})</template
          ><template v-if="subject.Heading"> - {{ subject.Heading }}</template>
        </div>
        <template v-if="bicSubjects.length == 0">—</template>
      </dd>
      <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">BISAC Codes</dt>
      <dd class="col-sm-9">
        <div v-for="(subject, index) in bisacSubjects" :key="index">
          {{ subject.Code
          }}<template v-if="subject.Version">
            (ver {{ subject.Version }})</template
          ><template v-if="subject.Heading"> - {{ subject.Heading }}</template>
        </div>
        <template v-if="bisacSubjects.length == 0">—</template>
      </dd>
      <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">THEMA Codes</dt>
      <dd class="col-sm-9">
        <div v-for="(subject, index) in themaSubjects" :key="index">
          {{ subject.Code
          }}<template v-if="subject.Version">
            (ver {{ subject.Version }})</template
          ><template v-if="subject.Heading"> - {{ subject.Heading }}</template>
        </div>
        <template v-if="themaSubjects.length == 0">—</template>
      </dd>
      <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">Keywords</dt>
      <dd class="col-sm-9">
        <div v-for="(subject, index) in keywordSubjects" :key="index">
          {{ subject.Code
          }}<template v-if="subject.Version">
            (ver {{ subject.Version }})</template
          ><template v-if="subject.Heading"> - {{ subject.Heading }}</template>
        </div>
        <template v-if="keywordSubjects.length == 0">—</template>
      </dd>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list of contact data
 */
import ContactQuickJumpDropdown from "./ContactQuickJumpDropdown.vue";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ParseDate from "../../mixins/parseDate.js";
import ParseUrl from "../../mixins/parseUrl.js";

export default {
  name: "ContactDescriptiveListMarketing",

  components: {
    "contact-quick-jump-dropdown": ContactQuickJumpDropdown,
  },

  mixins: [DisplaySetting, ParseDate, ParseUrl],

  props: {
    /**
     * The ID of the contact
     */
    contactId: {
      type: Number,
      default: null,
    },

    /**
     * Contact object
     */
    contact: {
      type: Object,
      default: () => {},
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Enable or disable edit links
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },
  },

  computed: {
    bicSubjects: function () {
      return this.contact.Subjects.filter((subject) => {
        return subject.Scheme == 12;
      });
    },
    bisacSubjects: function () {
      return this.contact.Subjects.filter((subject) => {
        return subject.Scheme == 10;
      });
    },
    themaSubjects: function () {
      return this.contact.Subjects.filter((subject) => {
        return subject.Scheme == 93;
      });
    },
    keywordSubjects: function () {
      return this.contact.Subjects.filter((subject) => {
        return subject.Scheme == 20;
      });
    },

    /**
     * URL for searching biblio titles
     */
    titleSearch: function () {
      return (
        this.$baseUrl +
        "search/link/biblio/" +
        this.searchId +
        "/" +
        this.page +
        "/contact"
      );
    },

    /**
     * Turns subject codes into a comma seperated search string
     */
    subjectSearchString: function () {
      if (!this.contact.Subjects) {
        return "";
      }
      return this.contact.Subjects.map((subject) => {
        return subject.Code;
      }).join(",");
    },
  },
};
</script>

<style></style>
