<template>
  <b-card id="productCardProductStatus">
    <template #header>
      <h5 class="d-inline-block">Status</h5>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <dl v-if="statusObject" class="row">
      <template v-if="productStatusText != null">
        <dt class="col-sm-3 font-weight-bold text-secondary">Status</dt>
        <dd class="col-sm-9">{{ productStatusText }}</dd>
      </template>
      <template v-if="statusObject.getCreatedOn != null">
        <dt class="col-sm-3 font-weight-bold text-secondary">Created</dt>
        <dd class="col-sm-9">
          {{
            statusObject.getCreatedOn
              | parseDate(dateOptions.format, dateOptions)
          }}
          <template v-if="statusObject.getCreatedBy.getUserId"
            >by {{ statusObject.getCreatedBy.getUserId }}</template
          >
        </dd>
      </template>
      <template v-if="statusObject.getLastModifiedOn != null">
        <dt class="col-sm-3 font-weight-bold text-secondary">Last Updated</dt>
        <dd class="col-sm-9">
          {{
            statusObject.getLastModifiedOn
              | parseDate(dateOptions.format, dateOptions)
          }}
          <template v-if="statusObject.getLastModifiedBy.getUserId"
            >by {{ statusObject.getLastModifiedBy.getUserId }}</template
          >
        </dd>
      </template>
      <template v-if="statusObject.getApprovedOn != null">
        <dt class="col-sm-3 font-weight-bold text-secondary">Approved</dt>
        <dd class="col-sm-9">
          {{
            statusObject.getApprovedOn
              | parseDate(dateOptions.format, dateOptions)
          }}
          <template v-if="statusObject.getApprovedBy.getUserId"
            >by {{ statusObject.getApprovedBy.getUserId }}</template
          >
        </dd>
      </template>
      <template v-if="statusObject.getArchivedOn != null">
        <dt class="col-sm-3 font-weight-bold text-secondary">Approved</dt>
        <dd class="col-sm-9">
          {{
            statusObject.getArchivedOn
              | parseDate(dateOptions.format, dateOptions)
          }}
          <template v-if="statusObject.getArchivedBy.getUserId"
            >by {{ statusObject.getArchivedBy.getUserId }}</template
          >
        </dd>
      </template>
      <template
        v-if="legalDepositReceipts != null && legalDepositReceipts.length != 0"
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">Legal deposit</dt>
        <dd class="col-sm-9"></dd>
        <template v-for="(legalDepositReceipt, index) in legalDepositReceipts">
          <dt
            :key="'receipt-dt-' + index"
            class="col-sm-3 pl-5 font-weight-bold text-secondary"
          >
            {{ legalDepositReceipt.getDepositName }}
          </dt>
          <dd :key="'receipt-dd-' + index" class="col-sm-9">
            {{
              legalDepositReceipt.getReceiptDate
                | parseDate(dateOptions.format, dateOptions)
            }}
          </dd>
        </template>
      </template>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list of
 * product status information
 */

import ParseDate from "../../mixins/parseDate.js";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "ProductStatus",

  components: {
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [ParseDate, DisplaySetting],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * getProductStatus data
     *
     * The data passed in must be an assosiative array (object in JS) of product data.
     * The keys of the array must match those defined in BooksonixProduct::getProductStatus
     * Use the dump method to get an appropriate aray of product data
     */
    statusObject: {
      type: Object,
      default: () => {
        return {};
      },
    },

    /** Legal deposit receipts data */
    legalDepositReceipts: {
      type: Array,
      default: null,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    /**
     * Sets the text in the status badge based
     * on the products status
     */
    productStatusText: function () {
      switch (this.statusObject.getProductStatus) {
        case "A":
          return "Approved";
        case "P":
          return "Provisional";
        case "D":
          return "Archived";
        default:
          return null;
      }
    },
  },
};
</script>

<style></style>
