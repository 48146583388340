<template>
  <popper
    trigger="clickToToggle"
    :options="{
      placement: 'bottom',
      modifiers: { offset: { offset: '0,10px' } },
    }"
  >
    <div class="popper">
      <h3 class="popover-header">Load filter</h3>
      <div class="popover-body panel">
        <div class="list-group">
          <button
            v-for="(filter, index) in filters"
            :key="index"
            type="button"
            class="list-group-item text-left"
            @click="filterSelected(filter)"
          >
            {{ filter.name }}
          </button>
        </div>
      </div>
    </div>
    <base-button slot="reference" ref="btnLoadFilter" :disabled="disabled"
      >Load Filters</base-button
    >
  </popper>
</template>

<script>
/**
 * Provides a popup dialog for loading
 * a saved filter
 */

import Popper from "vue-popperjs";

export default {
  name: "LoadFilter",

  components: {
    popper: Popper,
  },

  props: {
    /**
     * List of saved filters available to the user
     */
    savedFilters: {
      type: Array,
      default: () => [],
    },

    /**
     * Sets whether the button to open the popover should be disabled
     */
    disabled: Boolean,
  },

  data: () => {
    return {
      filters: [],
    };
  },

  watch: {
    savedFilters(value) {
      this.filters = value;
    },
  },

  methods: {
    filterSelected(value) {
      /**
       * Filter selected event
       */
      this.$emit("filter-selected", value);

      // close the popover
      this.$refs.btnLoadFilter.$el.click();
    },
  },
};
</script>

<style scoped>
.popover-body {
  min-width: 300px;
  padding: 0px;
  margin: 0px;
}

.popper {
  text-align: left;
}
</style>
