<template>
  <i v-if="disabled" class="fa fa-trash info tableButton"></i>
  <a
    v-else
    :href="link"
    class="fa fa-trash red tableButton"
    @click.prevent="open($event)"
  ></a>
</template>

<script>
/**
 * Small button displaying a delete icon that
 * links to a URL.
 */
export default {
  name: "DeleteIcon",
  props: {
    /**
     * The URL destination the button will link to.
     */
    link: {
      type: String,
      default: null,
    },

    /**
     * Disables the button
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * The message that should display in an alert
     * to confirm deletion.
     */
    msg: {
      type: String,
      default: "Are you sure you would like to delete this record?",
    },
  },

  methods: {
    open: function () {
      if (confirm(this.msg)) {
        window.open(this.link, "_self");
      }
    },
  },
};
</script>
