var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-block"},[_c('a',{attrs:{"id":_vm.id}},[_c('img',{attrs:{"src":"/common/images/barcode.png","alt":"Open Barcode"}})]),_c('b-modal',{attrs:{"id":_vm.barcodeModalId,"size":"lg","title":_vm.title,"format":_vm.format,"modal-footer":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
return [_c('b-button',{staticClass:"save-icon-btn m-2",attrs:{"size":"lg","variant":"primary","href":_vm.barcodeImageSource,"download":_vm.title + '.' + _vm.format,"target":"_blank","title":"Click here to download this image"}},[_c('i',{staticClass:"fa-solid fa-save"}),_vm._v(" Save ")]),_c('b-button',{attrs:{"size":"lg","variant":"light"},on:{"click":function($event){return ok()}}},[_vm._v("Close")])]}}])},[_c('b-img',{attrs:{"src":_vm.barcodeImageSource,"fluid":"","center":"","alt":_vm.type + ' barcode'}})],1),_c('b-popover',{attrs:{"target":_vm.id,"triggers":"hover","placement":"top"}},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{attrs:{"button":""},on:{"click":function($event){_vm.showBarcodeModal(_vm.regularBarcodeUrl(), _vm.type, 'png')}}},[_vm._v(_vm._s(_vm.type))]),_vm._l((_vm.priceBarcodeUrls()),function(price,index){return _c('b-list-group-item',{key:index,attrs:{"button":""},on:{"click":function($event){return _vm.showBarcodeModal(price.url, price.text, 'png')}}},[_vm._v(_vm._s(price.text))])}),_c('b-list-group-item',{attrs:{"button":""},on:{"click":function($event){_vm.showBarcodeModal(
            _vm.barcodeUrlWithCurrencyPlaceholder(),
            _vm.type + ' with 90000 code',
            'png'
          )}}},[_vm._v(_vm._s(_vm.type)+" with 90000 code")]),_c('b-list-group-item',{attrs:{"button":""},on:{"click":function($event){_vm.showBarcodeModal(
            _vm.regularBarcodeUrl('svg'),
            _vm.type + ' (hi-res)',
            'png'
          )}}},[_vm._v(_vm._s(_vm.type)+" (hi-res)")]),_vm._l((_vm.priceBarcodeUrls('svg')),function(price,index){return _c('b-list-group-item',{key:'hi-res-' + index,attrs:{"button":""},on:{"click":function($event){_vm.showBarcodeModal(price.url, price.text + ' (hi-res)', 'svg')}}},[_vm._v(_vm._s(price.text)+" (hi-res)")])}),_c('b-list-group-item',{attrs:{"button":""},on:{"click":function($event){_vm.showBarcodeModal(
            _vm.barcodeUrlWithCurrencyPlaceholder('svg'),
            _vm.type + ' with 90000 code (hi-res)',
            'svg'
          )}}},[_vm._v(_vm._s(_vm.type)+" with 90000 code (hi-res)")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }