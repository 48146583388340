<template>
  <b-modal :id="id" size="lg" static lazy hide-footer title="Share Media">
    <div v-if="loading">
      <loading-spinner></loading-spinner>
    </div>
    <div v-if="loading == false" class="p-5">
      <b-form @submit.prevent="onSubmit">
        <b-form-group
          label="Email address:"
          label-for="input-email"
          description="Enter multiple emails by separating them with a comma."
        >
          <b-form-input
            id="input-email"
            v-model="email"
            placeholder="Enter email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Subject:" label-for="input-subject">
          <b-form-input
            id="input-subject"
            v-model="subject"
            placeholder="Enter Subject"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Message:" label-for="input-message">
          <b-form-textarea
            id="input-message"
            v-model="message"
            placeholder="Enter Message"
            rows="3"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Attachment:" label-for="input-attachment">
          <b-form-input
            id="input-atachment"
            :value="file"
            disabled
            required
          ></b-form-input>
        </b-form-group>

        <div class="mt-2">
          <b-button type="button" variant="light" @click="closeModal"
            >Cancel</b-button
          >
          <b-button class="ml-2" type="submit" variant="success"
            >Submit</b-button
          >
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
/**
 * Displays a modal that will provide a form to email media
 */

import { HTTP } from "../../http-common.js";

export default {
  name: "ShareMediaModal",

  props: {
    /**
     * The modals elements ID.
     *
     * This is required and must be unique if multiple
     * modals are used on a single page
     */
    id: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      loading: false,
      type: null,
      file: null,
      subject: null,
      message: null,
      email: null,
    };
  },

  computed: {
    filename: function () {
      if (!this.file) {
        return null;
      }
      const parts = this.file.split("/");
      return encodeURIComponent(parts[parts.length - 1]);
    },
    filePath: function () {
      if (!this.file) {
        return null;
      }
      const parts = this.file.split("/");
      parts.splice(parts.length - 1, 1);
      // URI encode on each part of the url
      for (let i = 0; i < parts.length; i++) {
        parts[i] = encodeURIComponent(parts[i]);
      }
      return "/" + parts.join("/");
    },
  },

  methods: {
    /**
     * Displays the share media modal
     *
     * @param {string} type The media type code
     * @param {string} file The file name and path
     * @param {string} subject The default subject of the email form
     */
    displayShareMediaModal: function (type, file, subject = null) {
      // reset the form
      this.resetForm();
      this.type = type;
      this.file = file;
      this.subject = subject;
      this.$bvModal.show(this.id);
    },

    /**
     * resets form data to default
     */
    resetForm: function () {
      this.type = null;
      this.file = null;
      this.subject = null;
      this.message = null;
      this.email = null;
    },

    /**
     * Closes the modal
     */
    closeModal: function () {
      this.$bvModal.hide(this.id);
    },

    /**
     * Handles submitting the form
     */
    onSubmit: async function () {
      let postData = new FormData();
      if (this.type) {
        postData.append("type", this.type);
      }
      if (this.email) {
        postData.append("email", this.email);
      }
      if (this.subject) {
        postData.append("subject", this.subject);
      }
      if (this.message) {
        postData.append("message", this.message);
      }
      postData.append("filename", this.filename);
      postData.append("filePath", this.filePath);
      this.loading = true;
      try {
        await HTTP.post(this.$baseUrl + "api/media-links/share", postData);
        this.closeModal();
        this.loading = false;
        this.$bvModal.msgBoxOk("Email sent", {
          title: "Confirmation",
          okVariant: "success",
        });
      } catch (Error) {
        this.loading = false;
        this.$bvModal.msgBoxOk("There was an error sending the email", {
          title: "Error",
          okVariant: "danger",
        });
      }
    },
  },
};
</script>

<style scoped></style>
