<template>
  <div
    id="searchformindexmodal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 ref="title" class="modal-title">
            Index Browse and Term Selection
          </h4>
          <div class="close">
            <a
              :href="helpBaseUrl + '/help/search_indexbrowse'"
              target="help"
              class="searchformindex_helpbtn"
              title="Help"
            >
              <i class="fa fa-question-circle"></i>
            </a>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="modal-body-inner">
            <!-- selected terms line -->
            <div class="row">
              <div class="col-12 searchformindex_termsline">
                <div class="searchformindex_termslabel">
                  <span class="separation">Selected Terms:&nbsp;</span>
                </div>
                <textarea
                  ref="selectedTermsCtrl"
                  rows="2"
                  @input="selectedTermsChange($event.target.value)"
                >
                </textarea>
              </div>
            </div>
            <!-- navigation controls line -->
            <div class="row">
              <div class="col-12 formcontrols searchformindex_navigation">
                <button
                  class="btn btn-light"
                  type="button"
                  @click="indexNavigate('F')"
                >
                  <span class="fa fa-backward-step"></span> First
                </button>
                <button
                  class="btn btn-light"
                  type="button"
                  @click="indexNavigate('P')"
                >
                  <span class="fa fa-caret-left"></span> Previous
                </button>
                <button
                  class="btn btn-light"
                  type="button"
                  @click="indexNavigate('N')"
                >
                  Next <span class="fa fa-caret-right"></span>
                </button>
                <button
                  class="btn btn-light"
                  type="button"
                  @click="indexNavigate('L')"
                >
                  Last <span class="fa fa-forward-step"></span>
                </button>
                <span class="separation">Index Stem:</span>
                <input ref="stemCtrl" type="text" value="" />
              </div>
            </div>
            <!-- index terms -->
            <hr />
            <div
              id="searchindex_terms"
              ref="indexTerms"
              :class="'searchindexterms' + indexOptions.pageTerms"
            ></div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="doSearch">
            Do Search
          </button>
          <button type="button" class="btn btn-default" @click="clearTerms">
            Clear Terms
          </button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Search form index modal
 */
import { HTTP } from "../../http-common.js";

export default {
  name: "SearchFormIndexModal",

  props: {
    /**
     * The index options. An object with the properties 'userId' (string), 'symbols' (regex-string), 'words' (regex-string),
     * 'or' (string), 'pageTerms' (int), 'dateFmt' (string)
     */
    indexOptions: {
      type: Object,
      required: true,
    },

    /**
     * The help base url
     */
    helpBaseUrl: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      indexTerms: this.indexOptions.pageTerms,
      lineComponent: null,
      baseUrl: null,
      timer: null,
      stemValue: null,
      stemEncoded: null,
      navigateCmd: null,
    };
  },

  methods: {
    /**
     * Set the number of index terms to display per page, this overrides the value in indexOptions
     */
    setIndexTerms(terms) {
      this.indexTerms = terms;
      this.$refs.indexTerms.className = "searchindexterms" + terms;
    },

    /**
     * Show the index modal
     */
    showModal(lineComponent, fieldInfo) {
      this.lineComponent = lineComponent;
      this.baseUrl =
        document.location.protocol +
        "//" +
        document.location.host +
        "/scripts/" +
        this.$appId +
        "/ixbrowse.isa?tag=" +
        fieldInfo.indexTag +
        "&user=" +
        encodeURIComponent(this.indexOptions.userId) +
        "&terms=" +
        this.indexTerms +
        "&datefmt=" +
        this.indexOptions.dateFmt +
        "&stem=";
      this.stemEncoded = "";
      this.navigateCmd = "";

      this.$refs.title.innerHTML =
        "Index Browse and Term Selection for " +
        fieldInfo.name.replace(/&/g, "&amp;").replace(/</g, "&lt;");
      this.$refs.selectedTermsCtrl.value = lineComponent.currentTerm;
      this.$refs.stemCtrl.value = fieldInfo.indexStem;
      this.$refs.indexTerms.innerHTML = "";

      this.stemValue = "\t"; // different from the stem to get terms
      this.timerEvent();

      window.$("#searchformindexmodal").modal("show");

      this.timer = setInterval(this.timerEvent, 50);
      window.$("#searchformindexmodal").on("hide.bs.modal", () => {
        clearInterval(this.timer);
      });
    },

    /**
     * Index navigate
     */
    indexNavigate(command) {
      this.navigateCmd = command;
    },

    /**
     * Clear terms
     */
    clearTerms() {
      this.$refs.selectedTermsCtrl.value = "";
      this.lineComponent.currentTerm = "";
    },

    /**
     * Do a search
     */
    doSearch() {
      window.$("#searchformindexmodal").modal("hide");
      this.$emit("do-search");
    },

    /**
     * Selected terms change event
     */
    selectedTermsChange(selectedTerms) {
      this.lineComponent.currentTerm = selectedTerms.replace(/\r?\n/g, " ");
    },

    /**
     * Timer call
     */
    timerEvent() {
      // check for a new navigation command or a change in stem
      if (this.navigateCmd != "") {
        let url =
          this.baseUrl + this.stemEncoded + "&navigate=" + this.navigateCmd;
        this.navigateCmd = "";
        HTTP.get(url)
          .then((response) => {
            this.displayTerms(response, true);
          })
          .catch(() => {});
      } else if (this.stemValue != this.$refs.stemCtrl.value) {
        this.stemValue = this.$refs.stemCtrl.value;
        this.stemEncoded = encodeURIComponent(this.stemValue);
        let url = this.baseUrl + this.stemEncoded;
        HTTP.get(url)
          .then((response) => {
            this.displayTerms(response, false);
          })
          .catch(() => {});
      }
    },

    /**
     * Display index terms retrieved from the databse
     */
    displayTerms(response, updateStem) {
      let terms = JSON.parse("[" + response.data.replace(/\t/g, "\\t") + "]");
      if (terms.length != 0) {
        // build the HTML
        let html = "<table>";
        for (var i = 0; i < terms.length; i++) {
          let term = terms[i].split("\t")[0];
          html +=
            '<tr><td><a href="#" data-term="' +
            term
              .replace(/&/g, "&amp;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(/"/g, "&quot;") +
            '">' +
            term.replace(/&/g, "&amp;").replace(/</g, "&lt;") +
            "</a></td></tr>";
        }
        this.$refs.indexTerms.innerHTML = html + "</table>";

        // add click handlers to the links
        let links = this.$refs.indexTerms.getElementsByTagName("a");
        for (let i = 0; i < links.length; i++) {
          links[i].addEventListener("click", this.addTerm);
        } // for

        // update the stem box?
        if (updateStem) {
          this.stemValue = terms[0].split("\t")[0];
          this.$refs.stemCtrl.value = this.stemValue;
          this.stemEncoded = encodeURIComponent(this.stemValue);
        }
      } else {
        this.$refs.indexTerms.innerHTML =
          "<p>There are no terms in this index</p>";
      }
    },

    /**
     * Add a term to the selected terms
     */
    addTerm(event) {
      event.preventDefault();
      let term = event.target.dataset.term;
      let quoteTerm = false;
      if (
        term.indexOf(" ") != -1 ||
        term.indexOf("'") != -1 ||
        term.indexOf('"') != -1 ||
        term.indexOf("`") != -1 ||
        term.search(this.indexOptions.symbols) != -1 ||
        term.search(this.indexOptions.words) != -1
      ) {
        // need to quote term, get character to quote with
        if (term.indexOf('"') != -1) {
          if (term.indexOf("'") != -1) {
            // double quote and single quote in term, use back quote
            quoteTerm = "`";
          } else {
            // double quote in term but no single quote, use single quote
            quoteTerm = "'";
          }
        } else {
          // no double quote in term, use it
          quoteTerm = '"';
        }
      }

      // add term in selected terms
      if (this.$refs.selectedTermsCtrl.value.length != 0) {
        this.$refs.selectedTermsCtrl.value +=
          " " +
          this.indexOptions.or +
          " " +
          (quoteTerm ? quoteTerm + term + quoteTerm : term);
      } else {
        this.$refs.selectedTermsCtrl.value = quoteTerm
          ? quoteTerm + term + quoteTerm
          : term;
      }
      this.lineComponent.currentTerm =
        this.$refs.selectedTermsCtrl.value.replace(/\r?\n/g, " ");
    },
  },
};
</script>

<style>
.searchformindex_helpbtn {
  margin-right: 0.5em;
}
.searchformindex_termsline .searchformindex_termslabel {
  float: left;
  margin: 1em 0.5em 0 0;
}
.searchformindex_termsline textarea {
  width: 84%;
}
#searchindex_terms {
  height: 400px;
  overflow: auto;
}
#searchindex_terms.searchindexterms10 {
  height: 200px;
}
#searchindex_terms table {
  border: 0;
}
.searchformindex_navigation span.separation {
  margin-right: 1em;
}
.searchformindex_navigation input {
  width: 39%;
}
</style>
