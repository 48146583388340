<template>
  <div
    id="searchformrenamemodal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 ref="title" class="modal-title">Rename Saved Search Form</h4>
          <div class="close">
            <a
              :href="helpBaseUrl + '/help/search_save'"
              target="help"
              class="searchformrename_helpbtn"
              title="Help"
            >
              <i class="fa fa-question-circle"></i>
            </a>
            <button type="button" class="close" @click="hideModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="modal-body-inner">
            <div class="row searchformrename_header">
              <div class="col-5"><span>Name</span></div>
              <div class="col-7"><span>Description</span></div>
            </div>
            <div class="row searchformrename_newname">
              <div class="col-5">
                <input ref="nameCtrl" type="text" value="" />
              </div>
              <div class="col-7">
                <input ref="descrCtrl" type="text" value="" />
              </div>
            </div>
            <div class="row searchformrename_type">
              <div class="col-5">
                <span>Please select where this search should be saved:</span>
              </div>
              <div class="col-7">
                <input
                  id="renameTypeUser"
                  ref="typeUserCtrl"
                  name="type"
                  form="renamesavedsearchform"
                  type="radio"
                  value="user"
                />
                <label for="renameTypeUser">My Searches</label>
                <input
                  id="renameTypeShared"
                  ref="typeSharedCtrl"
                  name="type"
                  form="renamesavedsearchform"
                  type="radio"
                  value="shared"
                />
                <label for="renameTypeShared">Shared Searches</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="rename(false)">
            OK
          </button>
          <button type="button" class="btn btn-danger" @click="hideModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Rename saved search form modal
 */
import { HTTP } from "../../http-common.js";

export default {
  name: "SearchFormRenameModal",

  props: {
    /**
     * The help base url
     */
    helpBaseUrl: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      htmlForm: null,
      currentName: null,
      currentType: null,
    };
  },

  methods: {
    /**
     * Show the save search form modal
     */
    showModal(htmlForm, name, descr, type) {
      this.htmlForm = htmlForm;
      this.currentName = name;
      this.currentType = type;

      // reset the form fields
      this.$refs.nameCtrl.value = name;
      this.$refs.descrCtrl.value = descr;
      this.$refs.typeUserCtrl.checked = type == "user";
      this.$refs.typeSharedCtrl.checked = type == "shared";

      // show the modal
      window.$("#searchformrenamemodal").modal("show");
    },

    /**
     * Rename the saved search form
     */
    rename(overwrite) {
      // check we have a name
      if (this.$refs.nameCtrl.value.search(/\S/) == -1) {
        alert("You must enter a name");
        return;
      }

      // build POST data
      let postData = new URLSearchParams();
      postData.append("name", this.currentName);
      postData.append("type", this.currentType);
      postData.append("newname", this.$refs.nameCtrl.value);
      postData.append("newdescr", this.$refs.descrCtrl.value);
      postData.append(
        "newtype",
        this.$refs.typeSharedCtrl.checked ? "shared" : "user"
      );
      if (overwrite) {
        postData.append("overwrite", "Y");
      }

      // POST the request to the server
      HTTP.post(this.$baseUrl + "search/rename-saved-search-form", postData)
        .then(() => {
          this.hideModal();
        })
        .catch((error) => {
          if (
            error.response.status == 409 &&
            error.response.data == "CANNOT_OVERWRITE"
          ) {
            alert(
              "You cannot overwrite a shared saved search with this name as you did not create it"
            );
          } else if (
            !overwrite &&
            error.response.status == 409 &&
            error.response.data == "CHECK_OVERWRITE"
          ) {
            if (
              window.confirm(
                "A saved search with this name already exists, are you sure you want to overwrite it?"
              )
            ) {
              this.rename(true);
            }
          } else {
            alert("An error occurred while renaming your search");
          }
        });
    },

    /**
     * Hide the save search form modal
     */
    hideModal() {
      // hide the modal
      window.$("#searchformrenamemodal").modal("hide");
      // tell the owner
      this.$emit("modal-closed", this.htmlForm);
    },
  },
};
</script>

<style>
.searchformrename_helpbtn {
  margin-right: 0.5em;
}
.searchformrename_header span {
  padding-left: 5px;
}
.searchformrename_newname {
  margin-bottom: 5px;
}
.searchformrename_newname input {
  width: 100%;
}
.searchformrename_type span {
  padding-left: 5px;
}
.searchformrename_type input[type="radio"] {
  margin-left: 5px;
}
.searchformrename_type label {
  padding-left: 0.5em;
  padding-right: 1em;
}
</style>
