<template>
  <image-with-overlay
    v-if="contact.ImageUrl"
    :url="'/' + $appId + '/' + contact.ImageUrl + '?L=' + logonId"
    :file-name="contact.Image"
    :logon-id="logonId"
    :share-media-modal-id="shareMediaModalId"
    class="img-fluid mt-4"
  />
  <div v-else class="mt-4 text-center">
    <i class="fa-solid fa-image fa-10x text-dark"></i><br />
    No image set on this contact
  </div>
</template>

<script>
/**
 * Displays a responsive contact image
 * or an icon if no image is found
 */

import ImageWithOverlay from "../utility/ImageWithOverlay.vue";

export default {
  name: "ContactImage",

  components: {
    "image-with-overlay": ImageWithOverlay,
  },

  props: {
    /**
     * ID of the contact
     */
    contactId: {
      type: Number,
      default: null,
    },

    /**
     * Contact object
     */
    contact: {
      type: Object,
      default: () => {},
    },

    /**
     * The logon (session) ID of the current user
     */
    logonId: {
      type: String,
      default: null,
    },

    /**
     * The ID and Ref value of the share media modal
     */
    shareMediaModalId: {
      type: String,
      default: null,
    },
  },

  computed: {
    isCorporation: function () {
      return this.contact.CorporateBody != null;
    },
  },
};
</script>

<style></style>
