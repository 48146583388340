<template>
  <h6>
    <slot>{{ msg }}</slot>
  </h6>
</template>

<script>
/**
 * Header
 */
export default {
  name: "BaseHeader",
  props: {
    /**
     * The message to display in the header
     */
    msg: {
      type: String,
      default: null,
    },
  },
};
</script>
