<template>
  <div>
    <base-header class="mt-2">Reports &amp; Tools</base-header>
    <div class="card-columns">
      <div v-for="(category, key, index) in reports" :key="index">
        <list-panel :title="key">
          <list-panel-item
            v-for="(report, catIndex) in category"
            :key="catIndex"
            :icon="report.icon ? report.icon : 'file-o'"
            :href="baseUrl + 'reports/' + report.url"
          >
            {{ report.name }}
          </list-panel-item>
        </list-panel>
      </div>
    </div>
    <div class="mb-2">
      <b-button variant="outline-secondary" :href="$baseUrl + 'menus/main'"
        ><i class="fa-solid fa-arrow-left"></i> Return to menu</b-button
      >
    </div>
  </div>
</template>

<script>
/**
 * Reports page index
 *
 * Lists available reports in BooksoniX
 */

import _groupBy from "lodash/groupBy";
import ListPanel from "../../ui/list-panel/ListPanel.vue";
import ListPanelItem from "../../ui/list-panel/ListPanelItem.vue";

export default {
  name: "ReportsMenuPage",

  components: {
    "list-panel": ListPanel,
    "list-panel-item": ListPanelItem,
  },

  props: {
    /**
     * The base url of the app
     */
    baseUrl: {
      type: String,
      default: null,
    },

    /**
     * The list of available reports
     */
    reportConfigs: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    reports: function () {
      if (!this.reportConfigs) {
        return [];
      }
      return _groupBy(this.reportConfigs, "category");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bootstrap";
.card-columns {
  @include media-breakpoint-down(xl) {
    column-count: 3;
  }
  @include media-breakpoint-down(md) {
    column-count: 2;
  }
  @include media-breakpoint-down(sm) {
    column-count: 1;
  }
}
</style>
