<template>
  <b-modal
    id="MediaBrowseModal"
    static
    lazy
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    size="xl"
    :hide-backdrop="inModal"
  >
    <iframe
      name="MediaBrowseModal"
      :src="browseUrl"
      frameborder="0"
      style="width: 100%; height: 85vh"
    ></iframe>
  </b-modal>
</template>

<script>
/**
 * Displays a modal that will allow a media file to be selected
 *
 * To display call this component's browser method which has 2 parameters: the current file/folder and a
 * user-parameter to pass through if a file is selected
 * When a file has been selected the 'selected' event is called with the parameters: filename, user-parameter
 */
export default {
  name: "MediaSelectModal",

  props: {
    /**
     * Is this component displayed within another modal
     */
    inModal: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      currentFile: null,
      selectedParam: null,
    };
  },

  computed: {
    browseUrl: function () {
      return (
        "/common/mediamanager.php/" +
        this.$appId +
        (this.currentFile != ""
          ? "/" + encodeURIComponent(this.currentFile)
          : "") +
        "?L=" +
        this.$logonId +
        "&S=vueApp.$bsnxMediaBrowseModal.selected(*)" +
        "&C=parent.vueApp.$bsnxMediaBrowseModal.close()"
      );
    },
  },

  methods: {
    /**
     * Browse for a media file
     */
    browse: function (currentFile = "", selectedParam = null) {
      this.currentFile = currentFile;
      this.selectedParam = selectedParam;
      window.vueApp.$bsnxMediaBrowseModal = this;
      this.$bvModal.show("MediaBrowseModal");
    },

    /**
     * A file has been selected
     */
    selected: function (filename) {
      this.$emit("selected", filename, this.selectedParam);
    },

    /**
     * Close the browser
     */
    close: function () {
      this.$bvModal.hide("MediaBrowseModal");
      delete window.vueApp.$bsnxMediaBrowseModal;
    },
  },
};
</script>

<style></style>
