<template>
  <p class="text-muted">
    <slot>{{ msg }}</slot>
  </p>
</template>

<script>
/**
 * Small and muted text for contextual help
 */
export default {
  name: "HelpText",
  props: {
    /**
     * The message to display in the help text
     */
    msg: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
p {
  margin: 5px 0px;
}
small {
  font-size: 90%;
}
.text-muted.is-invalid {
  color: #a94442;
}
</style>
