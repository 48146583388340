<template>
  <b-card :sub-title="channel.royalties_sales_channel.descr">
    <dl class="row">
      <dt class="col-sm-4 font-weight-bold text-secondary">
        Withhold Percentage
      </dt>
      <dd class="col-sm-8">
        {{ channel.withhold_perc | valueOrPlaceholder("0") }}%
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Withhold once</dt>
      <dd class="col-sm-8">
        <template v-if="channel.withhold_once_only == 'Y'">Yes</template>
        <template v-else>No</template>
      </dd>
      <dt class="col-sm-4 font-weight-bold text-secondary">Steps on</dt>
      <dd class="col-sm-8">
        <template v-if="channel.royalties_sales_channel_group">
          {{ channel.royalties_sales_channel_group.descr | valueOrPlaceholder }}
        </template>
        <template v-else>-</template>
      </dd>
    </dl>
    <div v-if="channel.royalties_prod_contr_channel_rules" class="row">
      <div class="col-12">
        <royalties-channel-rules-table
          :rules="channel.royalties_prod_contr_channel_rules"
        ></royalties-channel-rules-table>
      </div>
    </div>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list of
 * royalty channel data for a product
 */

import DisplaySetting from "../../mixins/productDisplaySetting.js";
import RoyaltiesChannelRulesTable from "./RoyaltiesChannelRulesTable.vue";

export default {
  name: "RoyaltiesChannelDescriptiveListCard",

  components: { "royalties-channel-rules-table": RoyaltiesChannelRulesTable },

  mixins: [DisplaySetting],

  props: {
    /**
     * Royalty channel
     */
    channel: {
      type: Object,
      required: true,
    },
  },

  computed: {
    rules: function () {
      if (!this.channel.royalties_prod_contr_channel_rules) {
        return null;
      }
      let rules = [];
      for (const rule of this.channel.royalties_prod_contr_channel_rules) {
        rules.push({
          seq_num: rule.seq_num,
          rule_type: () => {
            switch (rule.rule_type) {
              case "D":
                return "Discount";
              case "U":
                return "Units";
              case "C":
                return "Combi";
              default:
                return rule.rule_type;
            }
          },
          discount_start: rule.discount_start,
          discount_end: rule.discount_end,
          units_start: rule.units_start,
          units_end: rule.units_end,
          rate_perc: rule.rate_perc,
          payment_type: () => {
            switch (rule.payment_type) {
              case "G":
                return "Gross";
              case "N":
                return "Net";
              case "F":
                return "Fixed";
              default:
                return "Unknown";
            }
          },
          descr: rule.descr,
        });
      }
      return rules;
    },
  },
};
</script>

<style></style>
