<template>
  <b-overlay :show="hover && overlayDisabled == false && !imgError" no-center>
    <template #overlay>
      <div
        style="height: 100%; width: 100%"
        class="d-flex"
        @mouseleave.prevent="onMouseLeave"
      >
        <div class="m-auto">
          <dl>
            <template v-if="fileName">
              <dt>File:</dt>
              <dd>{{ fileName }}</dd>
            </template>
          </dl>
        </div>
        <b-button
          v-if="shareMediaModalId"
          class="email-icon-btn m-2"
          size="sm"
          variant="primary"
          @click="shareMedia"
        >
          <i class="fa fa-envelope"></i>
        </b-button>
        <b-button
          v-if="logonId"
          class="save-icon-btn m-2"
          size="sm"
          variant="primary"
          :href="imgUrl + '?L=' + logonId + '&D'"
          target="_blank"
          title="Click here to download this image"
        >
          <i class="fa-solid fa-save"></i>
        </b-button>
        <b-button
          v-if="logonId"
          class="open-icon-btn m-2"
          size="sm"
          variant="primary"
          :href="imgUrl + '?L=' + logonId"
          target="_blank"
          title="Click here to view this image"
        >
          <i class="fa-solid fa-up-right-from-square"></i>
        </b-button>
      </div>
    </template>
    <b-img
      :src="!imgError && imgUrl ? imgUrl : '/common/images/noimage.png'"
      v-bind="$attrs"
      fluid
      :alt="alt"
      @error="imgError = true"
      @mouseover="onMouseOver"
    ></b-img>
  </b-overlay>
</template>

<script>
/**
 * Displays a responsive image. The img will scale to fit the
 * parent container but will be no larger than the
 * image size to prevent blurring.
 *
 * An overlay will also be shown when hovering the image which
 * allows for downloading and sharing the image.
 *
 * Use alongside the shareMediaModal component to provide a share
 * option. Pass in the ID of the modal to enable.
 */

export default {
  name: "ImageWithOverlay",

  props: {
    /**
     * The logon (session) ID of the current user
     */
    logonId: {
      type: String,
      default: null,
    },

    /**
     * The URL of the media img to display
     */
    url: {
      type: String,
      default: null,
    },

    /**
     * The filename of the image
     */
    fileName: {
      type: String,
      default: null,
    },

    /**
     * The Alt text to display for the image
     */
    alt: {
      type: String,
      default: "Image",
    },

    /**
     * Disables the overlay on hover
     * which displays detailed information
     * about the image.
     */
    disableOverlay: {
      type: Boolean,
      default: false,
    },

    /**
     * The ID and Ref value of the share media modal
     */
    shareMediaModalId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      imgUrl: this.url,
      imgError: false,
      hover: false,
      inactiveTimeout: null,
      overlayDisabled: this.disableOverlay,
    };
  },

  watch: {
    disableOverlay: function (value) {
      this.overlayDisabled = value;
    },
  },

  methods: {
    /**
     * Called when a user starts hovering over the component
     */
    onMouseOver() {
      this.hover = true;
      clearTimeout(this.inactiveTimeout);
    },

    /**
     * Called when a user stops hovering over the component
     */
    onMouseLeave: function () {
      this.inactiveTimeout = setTimeout(() => {
        this.hover = false;
      }, 50);
    },

    shareMedia: function () {
      if (!this.shareMediaModalId) return false;
      if (!this.$root.$refs[this.shareMediaModalId]) return false;
      this.$root.$refs[this.shareMediaModalId].displayShareMediaModal(
        null,
        this.$appId + "/" + this.fileName
      );
    },
  },
};
</script>

<style scoped>
img {
  max-height: 100%;
  max-width: 100%;
}
.email-icon-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}
.save-icon-btn {
  position: absolute;
  bottom: 0;
  right: 34px;
}
.open-icon-btn {
  position: absolute;
  bottom: 0;
  right: 68px;
}
</style>
