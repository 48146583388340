<template>
  <div>
    <div
      class="d-flex justify-content-between mt-1 flex-wrap"
      style="min-height: 28px"
    >
      <div class="my-auto text-nowrap">
        <label class="font-weight-bold mb-0">Record:</label>
        {{ productId ? productId : "—" }}
      </div>
      <div class="my-auto text-nowrap">
        <label class="font-weight-bold mb-0">Unique ID:</label>
        {{ uniqueId ? uniqueId : "—" }}
      </div>
      <div class="my-auto text-nowrap">
        <label class="font-weight-bold mb-0">Pub Date:</label>
        <template v-if="pubDate">
          {{ pubDate | parseDate(dateOptions.format, dateOptions) }}
        </template>
        <template v-else>-</template>
      </div>
      <div class="my-auto text-nowrap">
        <label class="font-weight-bold mb-0">Title:</label>
        {{ title ? title : "—" }}
      </div>
      <div class="my-auto text-nowrap">
        <label class="font-weight-bold mb-0">Format:</label>
        {{ format ? format : "—" }}
      </div>
    </div>
    <hr class="my-1" />
  </div>
</template>

<script>
/**
 * Displays a summary of product information
 * - Record Number
 * - Unique ID
 * - Pub Date
 * - Title
 * - Format
 *
 * The display will sit horizontally across the top of a page
 */
import ParseDate from "../../mixins/parseDate.js";

export default {
  name: "ProductSummaryFormHeader",

  mixins: [ParseDate],

  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
    uniqueId: {
      type: [Number, String],
      default: null,
    },
    pubDate: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: null,
    },
  },
};
</script>

<style></style>
