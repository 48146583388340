<template>
  <div class="card bg-light mb-3">
    <div class="card-body">
      <p class="card-text">
        <!-- @slot Content of the well -->
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script>
/**
 * Well UI component
 *
 * Displays text in a uniform grey box
 */

export default {
  name: "Well",
};
</script>

<style></style>
