<template>
  <button
    :type="type"
    class="btn"
    :class="'btn-' + variant"
    @click="handleClick"
  >
    <!-- @slot Button contents -->
    <slot></slot>
  </button>
</template>

<script>
/**
 * Reuseable button component
 */

export default {
  name: "BaseButton",

  props: {
    /**
     * The type of the button
     *
     * @values button, submit
     */
    type: {
      type: String,
      default: "button",
    },

    /**
     * The style of the button
     *
     * @values default, primary, success, info, warning, danger
     */
    variant: {
      type: String,
      default: "default",
    },
  },

  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style></style>
