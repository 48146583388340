<template>
  <div v-if="workflowObject">
    <div>
      This title is using the
      <strong>{{ workflowObject.getTemplate.getName }}</strong> workflow.
    </div>
    <div v-if="workflowObject.getOwners" class="mt-3 row">
      <div class="col-sm-2"><strong>Workflow Owner(s)</strong></div>
      <div class="col-sm-10">
        <span v-for="(owner, index) in workflowObject.getOwners" :key="index"
          >{{ owner
          }}<template v-if="index != workflowObject.getOwners.length - 1"
            >,
          </template></span
        >
      </div>
    </div>

    <div class="p-1 mt-2">
      <p>
        Use the table below to update the completion status of workflow tasks on
        this product. Use the checkbox to change the completion of a task then
        save using the 'Save Completion Status' button.
      </p>
      <p>
        Workflow settings on this product can be copied to all other marked
        records by clicking the 'Copy Workflow' button.
      </p>
    </div>
    <base-table
      class="mt-3"
      :filters-enabled="false"
      :columns="columns"
      :initial-data="workflowTasks"
      :date-format="getDateFormatFromUserFormat($userPreferences.dateFormat)"
      :row-class="highlightOverdueTask"
    >
      <template v-slot:completed="{ record }">
        <b-form-checkbox
          v-model="record.completed"
          :disabled="record.completed && !$permitted('workflow/uncomplete')"
          @input="updateChecked(record.hidden.task_id, ...arguments)"
        ></b-form-checkbox>
      </template>
    </base-table>
    <div class="mt3">
      <div class="row">
        <div class="col">
          <b-button
            v-if="$permitted('workflow/edit')"
            id="btn-edit-workflow"
            variant="outline-primary"
            :href="
              $baseUrl +
              'biblio-edit/edit-workflow/' +
              productId +
              (returnTo ? '?return=' + returnTo : '')
            "
            >Edit Workfow</b-button
          >
          <b-button
            v-if="$permitted('workflow/edit')"
            id="btn-copy-workflow"
            v-b-tooltip.hover
            title="Click to copy this products workflow settings to all other marked records."
            class="ml-2"
            variant="outline-primary"
            @click="copyWorkflow"
            >Copy Workflow</b-button
          >
          <b-button
            v-if="$permitted('workflow/edit')"
            id="btn-delete-workflow"
            class="ml-2"
            variant="outline-danger"
            @click="deleteWorkflow"
            >Delete Workflow</b-button
          >
        </div>
        <div class="col float-right">
          <b-button
            v-if="$permitted('workflow/edit')"
            id="btn-workflow-update-completed"
            v-b-tooltip.hover
            title="Click to save the completion status of the workflow tasks. Use the checkboxes to mark each task as completed."
            class="float-right"
            variant="outline-primary"
            :disabled="!checkboxClicked"
            @click="updateCompleted"
            >Save Completion Status</b-button
          >
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div>
      There is no workflow for this title<template
        v-if="$permitted('workflow/edit')"
        >, please click on Create Workflow to create one.</template
      >
    </div>
    <div class="mt-2">
      <b-button
        v-if="$permitted('workflow/edit')"
        variant="outline-primary"
        :href="
          $baseUrl +
          'biblio-edit/edit-workflow/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
        >Create Workfow</b-button
      >
    </div>
  </div>
</template>

<script>
/**
 * Displays a tab containing product workflow tasks
 * and workflow overview.
 *
 * Allows for some editing options such as copying and deleting
 * as well as being able to mark tasks as completed.
 */

import { HTTP } from "../../http-common.js";
import { DateTime } from "luxon";
import ParseDate from "../../mixins/parseDate.js";

export default {
  name: "ProductWorkflow",

  mixins: [ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      required: true,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * List of products workflow tasks
     * formatted for use in the base-table component
     */
    workflowTasks: {
      type: Array,
      default: null,
    },

    /**
     * Dump of BooksonixProduct::getWorkflow()
     */
    workflowObject: {
      type: Object,
      default: null,
    },

    /**
     * Marked Records object
     */
    markedRecords: {
      type: Object,
      default: null,
    },

    /**
     * Custom workflow label for users
     */
    usersLabel: {
      type: String,
      default: null,
    },

    /**
     * Custom workflow label for notes
     */
    notesLabel: {
      type: String,
      default: null,
    },

    /**
     * Sets whether Workfow notes are enabled or not
     */
    workflowNotesEnabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      tasks: this.workflowTasks,
      checkboxClicked: false,
    };
  },

  computed: {
    columns: function () {
      return [
        { text: "Task", key: "task" },
        { text: this.usersLabel, key: "users" },
        {
          text: this.notesLabel,
          key: "notes",
          hidden: this.workflowNotesEnabled == false,
        },
        { text: "Target", key: "target_date", type: "date" },
        { text: "Completed", key: "completed_date", type: "date" },
        { text: "", key: "completed" },
      ];
    },
    markedRecordIds: function () {
      if (this.markedRecords == null) {
        return null;
      }
      return this.markedRecords.getAll();
    },
  },

  watch: {
    workflowTasks(value) {
      this.tasks = value;
    },
  },

  methods: {
    /**
     * Copies workflow to marked records
     */
    copyWorkflow: async function () {
      if (this.markedRecordIds.length == 0) {
        this.$bvModal.msgBoxOk(
          "There are no marked records to copy this workflow data to.",
          {
            title: "No marked records",
            okVariant: "success",
          }
        );
        return false;
      }
      if (
        confirm(
          "Are you sure you want to copy the workflow data to all marked records overwriting any existing workflow data?"
        ) == true
      ) {
        try {
          let postData = new URLSearchParams();
          postData.append("recordIds", this.markedRecordIds);
          const response = await HTTP.post(
            this.$baseUrl + "product-workflow/copy/" + this.productId,
            postData
          );
          if (response) {
            this.$bvModal.msgBoxOk(
              "Workflow data has been copied to all marked records.",
              {
                title: "Confirmation",
                okVariant: "success",
              }
            );
          }
        } catch (error) {
          this.$bvModal.msgBoxOk("There was an error copying the workflow.", {
            title: "Error",
            okVariant: "danger",
          });
        }
      }
    },

    /**
     * Handled deletion of a products workflow
     */
    deleteWorkflow: async function () {
      if (
        confirm("Are you sure you want to delete the workflow data?") == true
      ) {
        try {
          const response = await HTTP.delete(
            this.$baseUrl + "product-workflow/delete/" + this.productId
          );
          if (response.data == "OK") {
            this.$bvModal.msgBoxOk("Workflow deleted", {
              title: "Confirmation",
              okVariant: "success",
            });
            /**
             * Deletion event
             */
            this.$emit("workflowDeleted");
          }
        } catch (error) {
          this.$bvModal.msgBoxOk("There was an error deleting the workflow", {
            title: "Error",
            okVariant: "danger",
          });
        }
      }
    },

    /**
     * Toggles the completion status of product workflow
     * tasks
     */
    updateCompleted: async function () {
      if (
        confirm(
          "Are you sure you want to update completed tasks for this record?"
        ) == true
      ) {
        try {
          let postData = new URLSearchParams();
          postData.append("workflowTasks", JSON.stringify(this.workflowTasks));
          const response = await HTTP.post(
            this.$baseUrl +
              "biblio-edit/update-workflow-completed/" +
              this.productId,
            postData
          );
          if (response.data == "OK") {
            this.$bvModal.msgBoxOk("Updated workflow task completion.", {
              title: "Confirmation",
              okVariant: "success",
            });
          }
        } catch (error) {
          this.$bvModal.msgBoxOk(
            "There was an error updating workflow completion",
            {
              title: "Error",
              okVariant: "danger",
            }
          );
        }
      }
    },

    /**
     * Keeps track of checked and unchecked ids
     */
    updateChecked: function (taskId, value) {
      let task = this.tasks.find((item) => item.task_id === taskId);
      if (task) {
        this.checkboxClicked = true;
        task.completed = value;
        if (value == true && task.completed_date == null) {
          task.completed_date = DateTime.now().toISO();
        }
        if (value == false) {
          task.completed_date = null;
        }
      }
    },

    /**
     * Checks if a task is overdue and returns
     * the appropriate class name to highlight
     * the task row in the table
     */
    highlightOverdueTask: function (task) {
      if (!task.target_date) {
        return "text-muted";
      }
      if (!task.completed && DateTime.now().toISODate() > task.target_date) {
        return "text-danger";
      }
      // no class needs to be applied
      return null;
    },
  },
};
</script>

<style></style>
