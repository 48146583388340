<template>
  <b-modal
    :id="id"
    static
    lazy
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    ok-only
    ok-variant="primary"
    ok-title="Close"
    size="xl"
    title="Create Marketing Materials"
  >
    <iframe
      name="MarketingMaterialsiFrame"
      :src="marketingMaterialsUrl"
      frameborder="0"
      style="width: 100%; height: 80vh; display: block"
    ></iframe>
  </b-modal>
</template>

<script>
/**
 * Displays a modal that will provide an edit form for generating
 * marketing materials
 */
import { HTTP } from "../../../http-common.js";

export default {
  name: "MarketingMaterialsModal",

  props: {
    /**
     * The modals elements ID.
     *
     * This is required and must be unique if multiple
     * modals are used on a single page
     */
    id: {
      type: String,
      required: true,
    },

    /**
     * ID of the current search
     *
     * Required to know the current marked records
     */
    searchId: {
      type: Number,
      required: true,
    },

    /**
     * What type of position do we have (record number or page number)
     */
    positionType: {
      type: String,
      default: "",
    },

    /**
     * Current position of the search
     *
     * Used to identify the productId for 'current'
     * record selection
     */
    position: {
      type: Number,
      required: true,
    },

    /**
     * Display format
     *
     * Passed to the export screen
     */
    displayFormat: {
      type: String,
      default: "",
    },
  },

  computed: {
    marketingMaterialsUrl: function () {
      return (
        this.$baseUrl +
        "marketing-materials/select/" +
        this.searchId +
        "/" +
        this.positionType +
        this.position +
        "/" +
        this.displayFormat +
        "?modal=" +
        this.id
      );
    },
  },

  methods: {
    /**
     * Use this method to show the modal to allow for
     * checking that there is still an active user session
     * when displaying the modal.
     */
    showModal: async function (checkSession) {
      if (checkSession) {
        const response = await this.checkUserSession();
        if (!response) {
          return false;
        }
      }
      this.$bvModal.show(this.id);
    },
    /**
     * Called before the modal is shown
     *
     * Checks a users session is still active before displaying
     * to prevent the iframe / modal displaying a login prompt
     */
    checkUserSession: async function () {
      return await HTTP.get(this.$baseUrl + "preferences/get");
    },
  },
};
</script>

<style scoped>
/deep/ .modal-body {
  padding: 0px;
}
</style>
