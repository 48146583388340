<template>
  <popper
    trigger="clickToToggle"
    :options="{
      placement: 'bottom',
      modifiers: { offset: { offset: '0,10px' } },
    }"
  >
    <div class="popper">
      <h3 class="popover-header">Save filter</h3>
      <div class="popover-body">
        <div class="form-group mt-1" :class="{ 'is-invalid': !!errors.name }">
          <label class="col-form-label">Name</label>
          <input
            id="filterName"
            v-model="saveFilterName"
            type="text"
            class="form-control"
            placeholder="Enter a name for the filter"
            @input="nameUpdated"
          />
          <span v-if="!!errors.name" class="form-text">{{ errors.name }}</span>
        </div>
        <div class="mt-2">
          <div class="form-check">
            <input
              id="globalCheckbox"
              v-model="globalFlag"
              class="form-check-input"
              type="checkbox"
              @input="globalUpdated"
            />
            <label class="form-check-label" for="globalCheckbox">
              Global
            </label>
          </div>
          <base-help-text
            >Check the global box if you wish to share your filter with other
            users</base-help-text
          >
        </div>
        <div class="actions">
          <base-button
            v-if="hasExistingFilter"
            id="saveFilterBtn"
            variant="success"
            class="float-right my-2 ml-2"
            @click="saveFilters($event, false)"
            >Save</base-button
          >
          <base-button
            id="filterSaveAs"
            class="float-right my-2"
            variant="success"
            @click="saveFilters($event, true)"
          >
            Save As
          </base-button>
        </div>
      </div>
    </div>
    <base-button
      ref="btnSaveFilter"
      slot="reference"
      variant="success"
      :disabled="disabled"
      >Save Filters</base-button
    >
  </popper>
</template>

<script>
/**
 * Provides a popup dialog for adding
 * a new filter field
 */

import Popper from "vue-popperjs";

export default {
  name: "SaveFilter",

  components: {
    popper: Popper,
  },

  props: {
    /**
     * The name of the filter to save
     */
    name: {
      type: String,
      default: null,
    },

    /**
     * Flag to set whether the filter is global
     */
    global: Boolean,

    /**
     * Sets whether the button to open the popover should be disabled
     */
    disabled: Boolean,

    /**
     * List of already saved filters, used to check for matching names
     */
    savedFilters: {
      type: Array,
      default: () => [],
    },

    /**
     * The current active filter
     */
    activeFilter: {
      type: Array,
      default: null,
    },

    /**
     * Sets whether there is an existing filter, used to enable the 'Save' option
     * instead of 'Save As'
     */
    hasExistingFilter: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      saveFilterName: this.name,
      globalFlag: this.global,
      errors: {
        name: "",
      },
    };
  },

  watch: {
    /**
     * Watch the name prop and set
     * the saveFilterName to be the same
     * (name prob will be updated by the parent when
     * a filter is loaded, therefore it needs to reflect
     * the new name here)
     */
    name(value) {
      this.saveFilterName = value;
    },

    global(value) {
      this.globalFlag = value;
    },

    /**
     * Watch the name input for validation
     *
     * Removes errors when the name is updated
     */
    saveFilterName(value) {
      if (value && this.errors.name) {
        this.errors.name = "";
      }
    },
  },

  methods: {
    saveFilters(event, saveAs) {
      // validate the name field
      if (!this.saveFilterName) {
        this.errors.name = "You must enter a name to save your filters.";
        return;
      }
      let existingFilter = this.savedFilters.find(
        (filter) => filter.name == this.saveFilterName
      );
      if (
        existingFilter &&
        (existingFilter.report_filter_id !=
          this.activeFilter.report_filter_id ||
          saveAs)
      ) {
        this.errors.name = "A filter with this name already exists.";
        return;
      }
      /**
       * Save button clicked
       */
      this.$emit("save-clicked", saveAs);
      // close the popover
      this.$refs.btnSaveFilter.$el.click();
    },

    nameUpdated() {
      /**
       * Name input updated
       */
      this.$emit("name-updated", this.saveFilterName);
    },

    globalUpdated() {
      /**
       * Global input updated
       */
      this.$emit("global-updated", this.globalFlag);
    },
  },
};
</script>

<style scoped>
.popover-body {
  width: 300px;
}

.popper {
  text-align: left;
}
</style>
