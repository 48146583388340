<template>
  <div class="row">
    <div class="col">
      <b-button
        v-if="$permitted('foreignrights/edit')"
        id="btn-edit-foreignrights"
        variant="outline-primary"
        :href="editLink"
        >Edit Rights</b-button
      >
      <b-button
        v-if="$permitted('foreignrights/edit')"
        id="btn-copy-foreignrights"
        class="ml-2"
        variant="outline-primary"
        @click="$emit('copyClicked')"
        >Copy Rights</b-button
      >
      <b-button
        v-if="$permitted('foreignrights/edit')"
        id="btn-delete-foreignrights"
        class="ml-2"
        variant="outline-danger"
        @click="$emit('deleteClicked')"
        >Delete Rights</b-button
      >
    </div>
  </div>
</template>

<script>
/**
 * Action buttons for foreign rights
 */
export default {
  name: "ForeignRightsActions",

  props: {
    /**
     * Href link to the products foreign rights edit page
     */
    editLink: {
      type: String,
      default: null,
    },
  },
};
</script>

<style></style>
