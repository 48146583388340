<template>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-3"><span class="separation">Field:</span></div>
    <div class="col-1"></div>
    <div class="col-7"><span class="separation">Search Terms:</span></div>
  </div>
</template>

<script>
/**
 * Display a search form header line
 */
export default {
  name: "SearchFormHeaderLine",
};
</script>

<style></style>
